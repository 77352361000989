import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Theme,
  Toolbar,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ReactElement, useState } from 'react';

import { TabPanel } from 'common/components/tabs';
import EmojiTextField from 'common/components/input-fields/EmojiTextField';
import { Survey } from 'components/survey/models';
import { SurveyLocation } from 'components/survey/models/surveyLocation';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '65vh',
    height: '65vh',
    overflow: 'auto',
  },
}));

export default function SurveyThankYouPageSetting({
  survey,
  disabled,
  getLocationName,
  onChange,
}: {
  survey: Survey;
  disabled: boolean;
  getLocationName(dealerLocationId: number): string | undefined;
  onChange(id: number, name: string, value: string): void;
}): ReactElement | null {
  const classes: any = useStyles();
  const [value, setValue] = useState(0);
  const tabLabels = [
    { index: 0, label: 'Passive & Promoter Thank-you Page' },
    { index: 1, label: 'Detractor Thank-you Page' },
  ];
  if (!survey) return null;
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };
  const tabs = tabLabels.map((tabLabel) => (
    <Tab id={`${tabLabel.label} Tab`} key={tabLabel.index} style={{ maxWidth: 'unset' }} label={tabLabel.label} />
  ));
  return (
    <Paper elevation={2}>
      <Toolbar variant="dense">
        <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          {tabs}
        </Tabs>
      </Toolbar>
      <Divider />
      <Container maxWidth="md" className={classes.container}>
        <TabPanel value={value} index={0}>
          {survey.locations.map((location, idx) => (
            <PnPLocationSettingPanel
              key={location.id}
              location={location}
              expanded={idx === 0}
              disabled={disabled}
              getLocationName={getLocationName}
              onChange={(name: string, val: string): void => onChange(location.id, name, val)}
            />
          ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {survey.locations.map((location, idx) => (
            <DetractorLocationSettingPanel
              disabled={disabled}
              key={location.id}
              location={location}
              getLocationName={getLocationName}
              expanded={idx === 0}
              onChange={(name: string, val: string): void => onChange(location.id, name, val)}
            />
          ))}
        </TabPanel>
      </Container>
    </Paper>
  );
}

const useStylesLocationSetting = makeStyles((theme: Theme): any => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  column: {
    flexBasis: '100%',
  },
}));

function PnPLocationSettingPanel({
  location,
  expanded,
  disabled,
  getLocationName,
  onChange,
}: {
  disabled: boolean;
  location: SurveyLocation;
  expanded: boolean;
  getLocationName(dealerLocationId: number): string | undefined;
  onChange(name: string, value: string): void;
}): ReactElement {
  const classes: any = useStylesLocationSetting();
  const handleChange = (event: any): void => {
    switch (event.target.name) {
      case 'passiveNPromotersReviewMessage':
      case 'googleReviewUrl':
      case 'facebookReviewUrl':
        onChange(event.target.name, event.target.value);

        break;
      case 'isFacebookReviewActive':
      case 'isGoogleReviewActive':
        onChange(event.target.name, event.target.checked);

        break;

      default:
    }
  };
  const handlePnPReviewMessageChange = (value: string): void => {
    onChange('passiveNPromotersReviewMessage', value);
  };

  const FacebookSwitch = (
    <Switch
      name="isFacebookReviewActive"
      disabled={disabled}
      onChange={handleChange}
      checked={location.isFacebookReviewActive}
      color="primary"
    />
  );
  const GoogleSwitch = (
    <Switch
      name="isGoogleReviewActive"
      disabled={disabled}
      onChange={handleChange}
      checked={location.isGoogleReviewActive}
      color="primary"
    />
  );
  return (
    <div className={classes.root}>
      <Accordion elevation={3} defaultExpanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box flexDirection="column">
            <div className={classes.column}>
              <Typography className={classes.heading}>{getLocationName(location.dealerLocationId)}</Typography>
            </div>
            <div
              className={classes.column}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}>
              <Typography component="div" className={classes.secondaryHeading}>
                <Grid container spacing={2}>
                  <Grid item xs="auto">
                    <FormGroup>
                      <FormControlLabel control={FacebookSwitch} label="Facebook review button" />
                    </FormGroup>
                  </Grid>
                  <Grid item xs="auto">
                    <FormGroup>
                      <FormControlLabel control={GoogleSwitch} label="Google review button" />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <TextField
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            value={location.googleReviewUrl}
            margin="dense"
            variant="outlined"
            name="googleReviewUrl"
            id={`googleReviewUrl_${location.id}`}
            label="Google review URL"
          />
          <TextField
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            value={location.facebookReviewUrl}
            margin="dense"
            variant="outlined"
            name="facebookReviewUrl"
            id={`facebookReviewUrl_${location.id}`}
            label="Facebook review URL"
          />
          <EmojiTextField
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            onTextChange={handlePnPReviewMessageChange}
            value={location.passiveNPromotersReviewMessage}
            autoFocus
            multiline
            rows={4}
            margin="dense"
            variant="outlined"
            name="passiveNPromotersReviewMessage"
            id="passiveNPromotersReviewMessage"
            label="Thank You Message"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function DetractorLocationSettingPanel({
  location,
  expanded,
  disabled,
  onChange,
  getLocationName,
}: {
  location: SurveyLocation;
  expanded: boolean;
  disabled: boolean;
  getLocationName(dealerLocationId: number): string | undefined;
  onChange(name: string, value: string): void;
}): ReactElement {
  const classes: any = useStylesLocationSetting();
  const handleDetractorMessageChange = (text: string): void => {
    onChange('detractorsReviewMessage', text);
  };
  return (
    <div className={classes.root}>
      <Accordion elevation={3} defaultExpanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box flexDirection="column">
            <div className={classes.column}>
              <Typography className={classes.heading}>{getLocationName(location.dealerLocationId)}</Typography>
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <EmojiTextField
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            value={location.detractorsReviewMessage}
            onTextChange={handleDetractorMessageChange}
            autoFocus
            multiline
            rows={4}
            margin="dense"
            variant="outlined"
            name="detractorsReviewMessage"
            id="detractorsReviewMessage"
            label="Thank You Message"
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
