import { Container, Drawer, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'app';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';

import { hideCustomerDrawer, UpdateSelectedCustomer } from '../Customers/customersSlice';
import CustomerEditDrawer from './CustomerEditDrawer';
import { hideTaskDrawer } from 'components/lead/components/task-board/taskboardSlice';
import Swal from 'sweetalert2';
import { CustomerAddDrawerProps } from './CustomerAddDrawer';
import TaskRootDrawer from 'components/lead/components/task-board/TaskDrawer/TaskRootDrawer';

const drawerWidth = 700;

const useStyles = makeStyles((theme: Theme): any => ({
  drawer: {
    //marginTop: theme.navBar.height,
    width: `${drawerWidth}px`,
    zIndex: 1070,
    //height: `calc(100% - ${theme.navBar.height})`,
  },
}));

export function CustomerRootPageDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  // const [icon, setIcon] = useState(false);
  const classes: any = useStyles();
  const open = useAppSelector((state) => !!state.customers.selectedCustomer, shallowEqual);
  const childRef = useRef<CustomerAddDrawerProps>(null);
  const handleClose = (): void => {
    console.log("handled on close called");
    if (childRef && childRef.current) {
      console.log(childRef.current);
      childRef.current.CompareChanges((r: boolean) => {
        if (!r) {
          console.log("nothing has changed");
          dispatch(UpdateSelectedCustomer(undefined));
          dispatch(hideCustomerDrawer());
        } else {
          console.log("something has changed");
          Swal.fire({
            // icon: 'warning',
            // icon
            // title: 'Unsaved Changes in Customer',
            showClass: {
              popup: 'animate__animated animate__fadeIn animate__faster'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOut animate__faster'
            },
            showCancelButton: true,
            width:560,
            showDenyButton: true,
            confirmButtonColor: '#3F51B5',
            cancelButtonColor: '#FF1744',
            denyButtonColor : "grey",
            showCloseButton: true,
            reverseButtons: true,
            cancelButtonText: "Cancel",
            confirmButtonText: 'Save changes',
            denyButtonText: `Close but don't save`,
            html: '<p style="font-size:1rem;margin-top:10px;">There are unsaved changes, do you want to save your changes?</p>',
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              if (childRef && childRef.current) {
                childRef.current.SubmitForm();
              }
            } else if (result.isDenied) {
              dispatch(UpdateSelectedCustomer(undefined));
              dispatch(hideCustomerDrawer());
            }
          });

        }
      });
    }
    else {
      console.log("handle Close called");
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(hideCustomerDrawer());
    }
  };

  return (
    <Drawer
      // style={icon ? { zIndex: 1060 } : {}}
      anchor="right"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawer }}
      variant="temporary"
      // onClose={() => handleClose()}
      onClose={async () => {
        // setIcon(true),
        handleClose();
      }}
      SlideProps={{ timeout: { enter: 400, exit: 500 } }}>
      <DrawerToolbar
        title="Customer Details"
        onClose={async () => {
          // if (window.onauxclick) {
          //   // setIcon(true);
          //   handleClose();
          // } else {
          //   // setIcon(false);
          dispatch(UpdateSelectedCustomer(undefined));
          dispatch(hideCustomerDrawer());
          // }
        }}
      />
      <Container>{open && <CustomerEditDrawer ref={childRef} />}</Container>
      <TaskRootDrawer />
    </Drawer>
  );
}

export default CustomerRootPageDrawer;
