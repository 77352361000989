import { makeStyles, Theme } from '@material-ui/core';
import { pink, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  centerAlign: {
    flexGrow: 1,
    textAlign: 'center',
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 320,
  },
  dashboardPaper: {
    backgroundColor: pink[300],
  },
  unHappyCustomerCard: {
    backgroundColor: pink[200],
  },
  happyCustomerCard: {
    backgroundColor: teal[200],
  },
  cardButton: {
    height: '100%',
    minWidth: '50px',
  },
}));

export default useStyles;
