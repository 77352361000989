import { PipelineStageList } from './../model/deal-edit-drawer';
import { DealAge, DealStatus, PipelineListResponse, PipelineUsersRequest } from './../model/dealsInterfaces';
import {
  DealType,
  DealLocation,
  DealItem,
  UpdateDealPosition,
  NewDealApi,
  FilterDeals,
} from '../model/dealsInterfaces';
import { DealsResponse } from '../model/dealsInterfaces';
import config from 'config';
import { get, put, post } from 'api';
import User from 'components/setting/models/user';

const dealPipelineUrl = config.get('api.lead');
const dealerUrl = config.get('api.dealer');

const PipelineDeals = {
  async getDeals(dmsDealerIdCrm: number, pipelineId: number, searchModel: FilterDeals): Promise<DealsResponse[]> {
    const url = `${dealPipelineUrl}/lead/deal/pipelineDeals/${dmsDealerIdCrm}/${pipelineId}`;
    const { data } = await post(url, searchModel);
    return data;
  },
  async updateDealPosition(requestModel: UpdateDealPosition) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealPositionV2`;

    await put(url, requestModel);
  },
  async AssignDealToUser(assignDealRequest: DealItem[]) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealAssignUser`;
    const { data } = await put(url, assignDealRequest);
    return data;
  },
  async addNewDeal(dmsDealerIdCrm: number, requestModel: NewDealApi) {
    const url = `${dealPipelineUrl}/lead/deal/addNewDeal/${dmsDealerIdCrm}`;
    await post(url, requestModel);
  },
  async UpdateDealsLostStatus(lostDealsRequest: DealItem[]) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealsLostStatus`;
    let { data } = await put(url, lostDealsRequest);
    return data;
  },
  async updateDealWon(updateDealWon: DealItem[]) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealWon`;
    const { data } = await put(url, updateDealWon);
    return data;
  },
  async updateDealArchive(updateDealArchive: DealItem[]) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealArchive`;
    const { data } = await put(url, updateDealArchive);
    return data;
  },
  async updateDealIsFavorite(dealId: number, isFavorite: boolean){
    const url = `${dealPipelineUrl}/lead/deal/updateDealIsFavorite/${dealId}/${isFavorite}`;
    const { data } = await put(url);
    return data;
  },
  async reopenDeal(dealIds: number[]): Promise<string> {
    const url = `${dealPipelineUrl}/lead/deal/reopenDeal`;
    const { data } = await put(url, dealIds);
    return data;
  },
  async updateDealsPipeline(req: any) {
    const url = `${dealPipelineUrl}/lead/deal/updateDealsPipeline`;
    const { data } = await put(url, req);
    return data;
  },
  async getDealsPipelinesDD(dmsDealerIdCrm: number): Promise<PipelineListResponse[]> {
    const url = `${dealPipelineUrl}/lead/deal/pipelinesDD/${dmsDealerIdCrm}`;
    const { data } = await get(url);
    return data;
  },
  async getPipelineUsers(model: PipelineUsersRequest): Promise<User[] | undefined> {
    const url = `${dealerUrl}/pipelineUsers`;
    const { data } = await post(url, model);
    return data;
  },
  async getPipelineStagesList(pipelineId: number): Promise<PipelineStageList[]> {
    const url = `${dealPipelineUrl}/lead/deal/pipelineStagesDD/${pipelineId}`;
    const { data } = await get(url);
    return data;
  },
};

const filterLocationData = async (): Promise<DealLocation[]> => {
  return [{ location: 'LHR' }, { location: 'ISL' }, { location: 'KHI' }];
};
const filterAgeData = async (): Promise<DealAge[]> => {
  return [{ age: 'Past 30 days', ageValue: 30 }, { age: 'Past 90 days', ageValue: 90 }, { age: 'Past 6 months', ageValue: 180 }, { age: 'Past year', ageValue: 365 }, { age: 'All time', ageValue: 0 }];
};
const filterStatusData = async (): Promise<DealStatus[]> => {
  return [{ status: 'Open deals', statusId: 1 }, { status: 'Won deals', statusId: 2 }, { status: 'Lost deals', statusId: 3 }, { status: 'Archived deals', statusId: 4 }];
};
const getDealsType = async (): Promise<DealType[]> => {
  return [{ value: 'In-Store', typeId: 1 }, { value: 'Phone', typeId: 2 }, { value: 'Web', typeId: 3 }, { value: 'Text', typeId: 4 }, { value: 'Event', typeId: 5 }, { value: 'OEM', typeId: 6 }];
};

export default {
  PipelineDeals,
  getDealsType,
  filterLocationData,
  filterAgeData,
  filterStatusData,
};
