import { get } from 'api';
import config from 'config';

const customerUrl = config.get('api.customer');

export const DashboardAPI = {
  getAllReports: async (dealerId: number): Promise<any | null> => {
    const { data } = await get(`${customerUrl}/customers/stats?dealerId=${dealerId}`);
    return data;
  },
};

export default DashboardAPI;
