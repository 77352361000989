import { Box, Container, Dialog, Paper, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { ReactElement, useEffect, useState } from 'react';

import { SimpleBackdrop } from 'common/components/loaders';
import { Survey } from 'components/survey/models';
import SurveyAPI from 'components/survey/api/survey-api';

import { SurveyFormMessage } from '../published-survey/SurveyFormMessage';
import SurveyForm from '../survey-form/survey-form';
import { QuestionCategory } from '../survey-page/survey-questions/new-question-block/new-question-block';
import { useDealerSettings } from './useDealerSetting';

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    appBar: {
      position: 'relative',
      background: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    title: {
      marginLeft: theme.spacing(2),
      flexGrow: 1,
      textAlign: 'center',
    },
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
        padding: theme.spacing(2, 0),
      },
    },
    innerContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      justifyContent: 'space-around',
    },
    paper: {
      height: '80%',
      width: '50vw',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: `calc(100% - ${theme.spacing(2) * 2}px)`,
      },
      '@media (max-height: 500px)': {
        height: 'fit-content',
        padding: theme.spacing(5, 0),
      },
    },
  }),
);

const useDealerLogoStyles = makeStyles((theme: Theme): any => ({
  root: {
    maxHeight: '100px',
    maxWidth: '300px',
    height: 'auto',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '80px',
      maxWidth: '200px',
      height: 'auto',
      width: 'auto',
    },
  },
}));
function DealerLogo({ url }: { url: string }): ReactElement {
  const classes: any = useDealerLogoStyles();
  return <img src={url} className={classes.root} alt="Dealer logo" />;
}

function SurveyHeader({ dealerLogoUrl }: { dealerLogoUrl?: string }): ReactElement {
  return <Box textAlign="center">{dealerLogoUrl ? <DealerLogo url={dealerLogoUrl} /> : null}</Box>;
}

function SurveyFormLoader({ loading }: { loading: boolean }): ReactElement | null {
  if (loading) {
    return <SimpleBackdrop />;
  }
  return null;
}

export const SurveyPreview = ({
  open,
  onClose,
  surveyId,
}: {
  open: boolean;
  onClose(): void;
  surveyId?: number;
}): ReactElement => {
  const classes: any = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [survey, setSurvey] = React.useState<Survey | undefined>(undefined);
  const [isSubmitted, setSubmitted] = useState(false);
  const [submittedCategory, setSubmittedCategory] = useState<QuestionCategory>(QuestionCategory.Detractors);

  useEffect(() => {
    async function fetchSurvey(): Promise<void> {
      if (surveyId && open) {
        try {
          setLoading(true);
          const response = await SurveyAPI.getSurvey(surveyId);
          setSurvey(response);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
    fetchSurvey();
    return function removeSurvey(): void {
      setSurvey(undefined);
    };
  }, [open, surveyId]);

  const settingDealerId = survey?.isAllLocations ? survey?.dealerId : survey?.dealerLocationId;
  const [dealerLogo] = useDealerSettings(settingDealerId);
  let selectSurveyLocationSetting;
  if (survey?.locations) {
    selectSurveyLocationSetting = survey.locations[0];
  }
  const handleSurveyPreviewForm = (values: any): void => {
    setSubmittedCategory(values.ratingCategory);
    setSubmitted(true);
  };
  function handleClose(): void {
    setSurvey(undefined);
    setSubmitted(false);
    setLoading(false);
    onClose();
  }
  const surveyFormMessage = submittedCategory === QuestionCategory.Detractors ? 'thankYou' : 'review';
  return (
    <Dialog
      fullScreen
      disableEnforceFocus
      className={classes.root}
      classes={{ paper: classes.root }}
      open={open}
      onClose={handleClose}>
      <AppBar elevation={1} className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton id="goBackBtn" edge="start" color="default" onClick={handleClose} aria-label="close">
            <KeyboardBackspaceIcon />
          </IconButton>
          <Box className={classes.title}>
            <Typography variant="h4" component="h4">
              Survey Preview
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <Container className={classes.innerContainer}>
            <SurveyHeader dealerLogoUrl={dealerLogo} />
            {isSubmitted ? (
              <SurveyFormMessage
                googleUrl={
                  selectSurveyLocationSetting?.isGoogleReviewActive
                    ? selectSurveyLocationSetting.googleReviewUrl
                    : undefined
                }
                facebookUrl={
                  selectSurveyLocationSetting?.isFacebookReviewActive
                    ? selectSurveyLocationSetting.facebookReviewUrl
                    : undefined
                }
                pnpMessage={selectSurveyLocationSetting?.passiveNPromotersReviewMessage || ''}
                detractorMessage={selectSurveyLocationSetting?.detractorsReviewMessage || ''}
                type={surveyFormMessage}
              />
            ) : (
              survey && <SurveyForm loading={loading}   survey={survey} onSubmit={handleSurveyPreviewForm} />
            )}
            <SurveyFormLoader loading={loading} />
          </Container>
        </Paper>
      </Container>
    </Dialog>
  );
};

export default SurveyPreview;
