import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export const LoadingButton = ({ onClick, disabled, children, ...other }: any): ReactElement => {
  const classes: any = useStyles();

  const success = false;
  const timer = React.useRef<number>();

  const buttonClassName = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    [],
  );

  const handleButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={classes.wrapper}>
      <Button className={buttonClassName} disabled={disabled} onClick={handleButtonClick} {...other}>
        {children}
      </Button>
      {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default LoadingButton;
