import Menu from '@material-ui/icons/Menu';
import { Box, Button, Tab, Tabs ,Paper} from "@material-ui/core";
import React from "react";
import InventoryPart from './InventoryPart';
import InventoryWG from './InventoryWG';
// import InventoryManufacturer from './InventoryManufacturer';



function Inventory() {
    const [queryType, SetQueryType] = React.useState("WG");
    //const [queryType, SetQueryType] = React.useState("P");
    // const [value, SetValue] = React.useState(1);

    const handleChange = (event:any, newValue:any) => {
        SetQueryType(newValue);
    };

    return (
        // <Box style={{ margin: "10px", marginTop: "5px" }}>
        <Box display="flex" justifyContent="center" alignItems="center" >
            
            <Box style={{marginTop:"10px"}}>
            <Paper square style={{ marginBottom: 10 }}>

                {/* <Tabs value={value} onChange={(e: any) => { SetValue(e.target.value) }} aria-label="basic tabs example"></Tabs> */}
                <Tabs 
                    indicatorColor="primary" 
                    textColor="primary"
                    value={queryType} 
                    onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Wholegoods" value={"WG"} />
                    <Tab label="Parts" value={"P"} />

                </Tabs>
                </Paper>

                {/* <Box display="flex" justifyContent="center" alignItems="center" >
                <Box style={{ display: "table" }}>
                    <Button color="primary" style={{ borderRadius: "0px 12px 0px 0px", padding: "5px 20px" }} variant={queryType == "WG" ? "contained" : "outlined"} size="small" onClick={(e: any) => { SetQueryType("WG") }} >Wholegoods</Button>
                    <Button color="primary" style={{ borderRadius: "0px 12px 0px 0px", marginLeft: "5px", padding: "5px 20px" }} variant={queryType == "P" ? "contained" : "outlined"} size="small" onClick={(e: any) => { SetQueryType("P") }} >Parts</Button>
                </Box>
            </Box> */}

                <Box style={{border:"0px solid red"}}>
                     {queryType == "WG" && (<InventoryWG></InventoryWG>)}
                    {queryType == "P" && (<InventoryPart></InventoryPart>)}
                    {/* {queryType == "M" && (<InventoryManufacturer></InventoryManufacturer>)} */}
                </Box>
                {/* <InventoryManufacturer></InventoryManufacturer> */}
            </Box>
        </Box>
    )

}
export default Inventory;