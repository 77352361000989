import { Container } from '@material-ui/core';
import React from 'react';
import { useAppDispatch } from 'app';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';

import { hideCustomerDrawer } from '../Customers/customersSlice';
import CustomerValidatePhone from './CustomerValidatePhone';

function CustomerValidatePhoneDrawer(): React.ReactElement {
  const dispatch = useAppDispatch();

  const handleOnClose = (): void => {
    dispatch(hideCustomerDrawer());
  };
  return (
    <>
      <DrawerToolbar title="Validate Phone Numbers" onClose={handleOnClose} />
      <Container maxWidth="sm">
        <CustomerValidatePhone onClose={handleOnClose} />
      </Container>
    </>
  );
}

export default CustomerValidatePhoneDrawer;
