import { Box, Card, CardActions, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useAppDispatch } from 'app';
import { useLocation } from 'react-router-dom';

import LoadingButton from 'common/components/buttons/LoadingButton';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { showSnackbar } from 'components/root-snackbar';

const useStyles = makeStyles((theme) => ({
  // container: {
  //     height: '100%'
  // },
  grid: {
    height: '100%',
    textAlign: 'center',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    minWidth: 500,
    minHeight: 300,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const Unsubscribe: React.FC = () => {
  const query = useQuery();
  const s: string = query.get('s') ?? '';
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const handleUnsubscribe = async (token: string) => {
    await BroadCastAPI.unsubscribeEmail(s);
    setDisabled(false);
    dispatch(showSnackbar({ type: 'success', message: 'Successfully unsubscribed. Please close this window.' }));
  };

  const handleCancel = async () => {
    dispatch(
      showSnackbar({ type: 'success', message: 'Thankyou!. Please close this window. We wish you all the best.' }),
    );
  };

  const classes: any = useStyles();
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" className={classes.grid}>
        <Grid item>
          <Box component="div" mt={1}>
            <Card className={classes.root}>
              <CardContent>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography variant="h3" color="textSecondary" gutterBottom>
                        Unsubscribe from Email Notifications
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography variant="h5" align="center" component="h2">
                        We're sorry to see you go.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Typography variant="body2" align="center" component="p">
                        By unsubscribing, you will miss out on all email notifications from us. Please use the button
                        below to unsubscribe youself from all emails.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={6}>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Box mt={3} mr={1}>
                          <LoadingButton
                            fullWidth
                            id="login_btn"
                            color="primary"
                            disabled={disabled}
                            variant="contained"
                            size="medium"
                            type="submit"
                            onClick={() => {
                              setDisabled(true);
                              handleUnsubscribe(s);
                            }}>
                            Click here to unsubscribe
                          </LoadingButton>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box mt={3} ml={1}>
                          <LoadingButton
                            fullWidth
                            id="login_btn"
                            color="secondary"
                            variant="contained"
                            size="medium"
                            type="submit"
                            onClick={() => {
                              handleCancel();
                            }}>
                            Stay Subscribed
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Unsubscribe;
