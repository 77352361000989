import {
  Box,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DomainIcon from '@material-ui/icons/Domain';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import SendIcon from '@material-ui/icons/Send';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import MergeIcon from '@mui/icons-material/Merge';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { SmallCircularProgress } from 'common/components/loaders';
import { EmojiPickerButton } from 'common/components/buttons';
import { Notification } from 'common/models/notification';
import { formatPhoneNumber } from 'common/utils';
import MessageSnippetWidget from 'components/broadcast/components/broadcast-page/message-templates';
import SocialLinkWidget from 'components/broadcast/components/broadcast-page/social-link-widget';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import ImageLibraryButton from 'components/image-library/components/image-library-modal';
import { SignalRContext } from 'components/layouts/DashboardLayout/signalr-context';
import { isEqual } from 'lodash';
// import { fetchCustomers, hideCustomerDrawer, saveCustomer, setEditCustomerLoading, SetSelectedCustomers, showCustomerDrawer, updateCustomerSearchString } from '../../../customer/components/Customers/customersSlice';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  Ref,
} from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from 'app';
import { showSnackbar } from 'components/root-snackbar';
import messagingApi from '../../api/messaging-api';
import { Conversation } from '../../models/conversation';
import { Message } from '../../models/message';
import MessageReceived from '../../models/MessageReceived';
import MessagesList, { messageListRef } from './messages-list';
import { UploadedImages } from './uploaded-images';
import MarkAsUnreadIcon from '@mui/icons-material/Markunread';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import VideoCallWidget from 'components/broadcast/components/broadcast-page/video-call-widget';
import TextToPay from 'components/customer/components/TextToPay';
import UrlShortenerWidget from 'components/broadcast/components/broadcast-page/url-shortener-widget';
import { GetAttachments } from 'dmsAppsCallbacks';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { AxiosResponse } from 'axios';
import config from 'config';
import { post } from 'api';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Alert } from '@mui/material';
import { InfoMessages } from 'common/utils/shared/shared-messages';
import MergeMessengerDrawer from '../merge-conversation-drawer';
import ArchiveDialog from '../archive/archive-dialog';
import { ArchiveRequest } from 'components/messaging/models/request/archiveRequest';
import { MessageStatusType } from 'common/utils/shared/shared-constants';

import { Warning } from '@material-ui/icons';
// import CustomerRootDrawer from 'components/customer/components/CustomerDrawer/CustomerRootDrawer';
// import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import { IsPaymentMessage } from 'common/utils/shared/shared-functions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function MessagesLoader({ loading }: { loading: boolean }): ReactElement | null {
  return loading ? (
    <Box position="absolute" left="0" zIndex={2} top={2} width="100%" textAlign="center">
      <SmallCircularProgress />
    </Box>
  ) : null;
}

function NoConversationMessage(): ReactElement {
  return (
    <Box my={2} textAlign="center">
      <Typography variant="caption">Select conversation to display</Typography>
    </Box>
  );
}

const useConversationPanelStyles = makeStyles((theme: Theme): any => ({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '48px',
    zIndex: 1,
    flexShrink: 0,
    padding: theme.spacing(1, 2),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  customerDetailsText: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  conversationHeaderChips: {},
  conversationHeaderChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexBasis: '50%',
    marginRight: '5px',
    marginBottom: '5px',
    fontSize: '0.7rem',
  },
  conversationContent: {
    overflowY: 'scroll',
    height: '100%',
    position: 'relative',
  },
  conversationAction: {
    minHeight: '50px',
    flexShrink: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1, 1, 1),
  },
  actionButtons: {
    position: 'relative',
    display: 'flex',
  },
  fileUploadOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    width: '100%',
    height: 'calc(100% - 48px)',
    top: '48px',
    position: 'absolute',
  },
  iconButtonLabel: {
    flexDirection: 'column',
  },
}));

interface MessageState {
  messageBody: string;
  messageType: string;
  loading: boolean;
  error: string;
}
export interface ConversationPanelRef {
  fetchConversationMessage: () => void;
}

type ConversationPanelProps = {
  conversation: Conversation;
  latestMessageSid: string;
  markConversationRead(conversation: Conversation): void;
  openCustomerEditDrawer(selectedCustomerId: number): void;
  onLatestMessageReceived(conversation: Conversation): void;
  markConversationUnRead(conversationId: number): void;
  assignConversation(conversationId: number): void;
  markConversationArchive(archiveRequest:ArchiveRequest): void;
  onMessageSentUpdateConversations(conversationId: number): void;
  onMessageRecievedUpdateConversations(conversationId: number): void;
};

const ConversationPanel = forwardRef(
  (
    {
      conversation,
      markConversationRead,
      markConversationArchive,
      latestMessageSid,
      openCustomerEditDrawer,
      onLatestMessageReceived,
      markConversationUnRead,
      assignConversation,
      onMessageSentUpdateConversations,
      onMessageRecievedUpdateConversations
    }: ConversationPanelProps,
    ref: Ref<ConversationPanelRef>,
  ): ReactElement => {
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      async fetchConversationMessage(): Promise<void> {
        // console.log("clicked");
        // pageNumberRef.current = -1;
        // await fetchConversationMessages(true);
        // if(childRef.current){
        //   childRef.current.scrollToSelectedMessage();
        // }
      },
    }));
    const classes: any = useConversationPanelStyles();
    const signalRConnection = useContext(SignalRContext);
    const dispatch = useAppDispatch();
    const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
    const dealerId = useAppSelector((state) => state.user.dealerId);
    const dmsdealerId = useAppSelector((state) => state.user.dmsDealerId);
    const isHostedDealer = useAppSelector((state) => state.user.isHosted);
    const textToPay = useAppSelector((state) => state.user?.isTextToPayAllow);
    const [scrollIndex, setScrollIndex] = useState(0);
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isMessageRemaining, setIsMessageRemaining] = useState<boolean>(true);
    const pageNumberRef = useRef(-1);
    const minPageNumberRef = useRef(0);
    const maxPageNumberRef = useRef(0);
    const scrollTriggerRef = useRef(false);

    const pageCountRef = useRef(0);
    const loadingRef = useRef(loading);
    const isMessageRemainingRef = useRef(isMessageRemaining);

    const [galleryImageUploading, setGalleryImageUploading] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
    const [messageState, setMessage] = useState<MessageState>({
      messageBody: '',
      messageType: 'Text',
      loading: false,
      error: '',
    });
    const [snackbarVisible, setSnackbarVisible] = useState(false);

    const [warning, setWarning] = useState(false);
    const textareaRef = useRef<HTMLInputElement | undefined>(undefined);
    const childRef = useRef<messageListRef>(null);
    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isCustomerPermission = 0;
    if (!userPermissions) {
      userPermissions = [];
      isCustomerPermission = 2;
    }
   if (isCustomerPermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
      isCustomerPermission = 1;
   } 
 }

    const personName = conversation.customer.name?.includes(':')
      ? conversation.customer.name?.split(':')[1]?.trim() || 'N/A'
      : conversation.customer.name?.trim() || 'N/A';

    const hidePersonName = conversation.isOrganization &&
      ((dmsTypeId === 0 && !conversation.customer.name?.includes(':')) ||
        (dmsTypeId === 9 && conversation.isAspenPrimaryContact));

    useEffect(() => {
      const listenStorageChange = () => {
        const fileName = dmsTypeId==0 ? localStorage.getItem('IdealFileUpload'):localStorage.getItem('InfinityFileUpload');
        if (fileName === 'CancelAttachment') {
          setGalleryImageUploading(false);
        } else if (fileName != null) {
          const response = localStorage.getItem(fileName);
          const addImageResponse: GalleryImage[] = JSON.parse(response!);
          setGalleryImageUploading(false);
          setGalleryImages(addImageResponse);
          localStorage.removeItem(fileName);
          dmsTypeId==0 ? localStorage.removeItem('IdealFileUpload'):localStorage.removeItem('InfinityFileUpload');
        }
      };
      window.addEventListener('storage', listenStorageChange);
      return () => window.removeEventListener('storage', listenStorageChange);
    }, []);

    async function GetImageSize(url: string) {
      let data = await fetch(url, {
        method: 'GET',
        headers: { 'X-HTTP-Method-Override': 'HEAD' },
      })
        .then((resp) => resp.blob())
        .then((data) => data.size);
      return data;
    }

    const onDropAccepted = useCallback(
      async (acceptedFiles: File[]) => {
        const messagingUrl = config.get('api.messaging');
        const url = `${messagingUrl}/AddImages`;

        setGalleryImageUploading(true);
        const requestForm = new FormData();
        acceptedFiles.forEach((file) => {
          requestForm.append('files', file);
        });

        post(url, requestForm, {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadingProgress(percentCompleted);
          },
        })
          .then((res: AxiosResponse<GalleryImage[]>) => {
            setGalleryImageUploading(false);
            setGalleryImages(res.data);
          })
          .catch((err) => {
            setGalleryImageUploading(false);
            dispatch(showSnackbar({ type: 'error', message: 'Unable to upload image' }));
          });
      },
      [dispatch],
    );

    const [modalOpen, setModalOpen] = React.useState(false);
    const [openMergeMessengerDrawer, SetOpenMergeMessengerDrawer] = React.useState<boolean>(false);
    const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);

    const onDropRejected = (error: FileRejection[], event: DropEvent): void => {
      console.log(error);
      console.log(event);
      if (error[0].errors[0].code === 'file-too-large') {
        dispatch(showSnackbar({ type: 'error', message: 'File size must be less than 25 MB' }));
      } else {
        dispatch(showSnackbar({ type: 'error', message: 'File type must be .jpeg, .jpg, .png, .gif, .pdf' }));
      }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDropAccepted,
      onDropRejected,
      accept: '.jpeg,.jpg,.png,.gif,.pdf',
      maxSize: 26214400, //25MB Max Limit
    });

    function onRemoveFile(id: number): void {
      const filteredFiles = galleryImages.filter((file) => file.id !== id);
      setGalleryImages(filteredFiles);
    }

    useEffect(() => {
      fetchConversationMessages(true);
      scrollTriggerRef.current = false;
      // if(childRef.current){
      //   childRef.current.scrollToSelectedMessage();
      // }
      setTimeout(() => {
        scrollTriggerRef.current = true;
      }, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation.id, latestMessageSid]);

    useEffect(() => {
      if (messages.length <= 10) {
        setScrollIndex(messages.length - 1);
      }
    }, [messages, scrollIndex]);

    useEffect(() => {
      function onMessageReceivedConversation(notification: Notification): void {
        const eventData: MessageReceived = JSON.parse(notification.EventData);
        console.log('onMessageReceivedConversation 2 : ', eventData);
        if (
          eventData.conversation.id === conversation.id &&
          messages.map((x) => x.id).indexOf(eventData.message.id) === -1
        ) {
          const newMessages = [...messages, eventData.message];
          setScrollIndex(newMessages.length - 1);
          setMessages(newMessages);          
        }     
      }
      function onMessageSent(notification: Notification): void {
        const eventData: MessageReceived = JSON.parse(notification.EventData);
        console.log('onMessageSent 2: ', eventData);
        if (eventData.conversation.id === conversation.id) {
          if (messages.map((x) => x.id).indexOf(eventData.message.id) === -1) {
            const newMessages = [...messages, eventData.message];
            setScrollIndex(newMessages.length - 1);
            setMessages(newMessages);
            onLatestMessageReceived(eventData.conversation);
          }
        }
      }      
      if (signalRConnection) {
        signalRConnection.on('MessageReceivedConversation', onMessageReceivedConversation);
        signalRConnection.on('MessageSent', onMessageSent);
      }
      return (): void => {
        if (signalRConnection) {
          signalRConnection.off('MessageReceivedConversation', onMessageReceivedConversation);
          signalRConnection.off('MessageSent', onMessageSent);
        }
      };
    }, [conversation.id, messages, onLatestMessageReceived, signalRConnection]);
    
    useEffect(() => {
      function onMessageStatusChange(notification: Notification): void {
          const updatedMessage: Message = JSON.parse(notification.EventData);

        if (updatedMessage.conversationId === conversation.id) {

          // if (messages.map((x) => x.id).indexOf(updatedMessage.id) === -1)
          // {
          //     console.log('setMissedMsgStatusNotify 2: ', {updatedMessage});
          //     setMessages(prevMessages => [...prevMessages, updatedMessage]);
          //     return;
          // }
          const newMessages = messages.map((m) => {
            if (m.id !== updatedMessage.id) return m;

            if(IsPaymentMessage(m.messageType)) return updatedMessage;
            
            //if status is delivered then don't change the status
            if(m.status == MessageStatusType.delivered) return m;
            
            return updatedMessage;
          });
          
          setMessages(newMessages);
        }
      }
      if (signalRConnection) {
        signalRConnection.on('MessageStatusChange', onMessageStatusChange);
      }
      return (): void => {
        if (signalRConnection) {
          signalRConnection.off('MessageStatusChange', onMessageStatusChange);
        }
      };
    }, [conversation.id, messages, signalRConnection]);

    useEffect(() => {
      //pageNumberRef.current = pageNumber;
      loadingRef.current = loading;
    });

    if (!conversation) {
      return <NoConversationMessage />;
    }

    function focusInputTextField(): void {
      setTimeout(() => {
        if (textareaRef && textareaRef.current) {
          textareaRef.current?.focus();
          textareaRef.current.selectionStart = textareaRef.current.value.length;
          textareaRef.current.selectionEnd = textareaRef.current.value.length;
        }
      }, 0);
    }

    async function handleSingleMessageSent(
      messageBody: string,
      messageType: string,
      mediaResult: string[],
      defaultMessageType?: string,
      messageBodyRef?: any,
      refid?: any,
    ) {
      const messageResponse = await messagingApi.sendMessage(
        conversation?.id,
        messageBody,
        defaultMessageType ? defaultMessageType : messageType,
        mediaResult,
        messageBodyRef,
        refid,
      );
      return messageResponse;
    }

    async function handleMessageSent(
      message?: any,
      defaultMessageType?: string,
      messageBodyRef?: any,
      refid?: any,
    ): Promise<void> {
      if (conversation) {
        const invalidUrlRegex = /^(?!.*(bit\.ly|tinyurl\.com)).*$/s;
        // Check if the message body contains an invalid URL
        if (!invalidUrlRegex.test(messageState.messageBody)) {
            dispatch(showSnackbar({ type: 'error', message: 'Please avoid shortened URLs (e.g., bit.ly); messages with them won\'t be sent\—Use our URL shortener instead.' }));
        
      
        //  event.preventDefault();
          return;
        }



        let { messageBody, messageType } = messageState;
        if (message && typeof message === 'string') {
          messageBody = message;
        }
        try {
          setMessage({
            error: messageState.error,
            messageType: messageType,
            messageBody,
            loading: true,
          });
          const media = galleryImages.map((galleryImage) => galleryImage.mediaUrl);

          let latestMessageResponses: { message: Message; conversation: Conversation }[] = [];
          if (media.length > 0) {
            await Promise.all(
              media.map(async (imageSrc) => {
                let mediaResult: string[] = [];
                if ((await GetImageSize(imageSrc)) < 614400 ) {
                  mediaResult.push(imageSrc);
                } else {
                  let shortenedUrl: string = await BroadCastAPI.shortenUrl(imageSrc);
                  messageBody = '';
                  messageBody = 'Tap this link to open attachment:' + ' ' + shortenedUrl + ' ' + messageBody + ' ';
                }

                const messageResponse = await handleSingleMessageSent(
                  messageBody,
                  messageType,
                  mediaResult,
                  defaultMessageType,
                  messageBodyRef,
                  refid,
                );
                latestMessageResponses.push(messageResponse);
              }),
            );
          } else {
            // Get the current time before calling the function
        const startTime = performance.now();

            const messageResponse = await handleSingleMessageSent(
              messageBody,
              messageType,
              [],
              defaultMessageType,
              messageBodyRef,
              refid,
            );
            // Get the current time after calling the function
          const endTime = performance.now();

          // Calculate the elapsed time
          const elapsedTime = endTime - startTime;

          // Output the elapsed time in milliseconds
          console.log("Time taken to execute the function handleSingleMessageSent: " + elapsedTime + " milliseconds");
            latestMessageResponses.push(messageResponse);
            }

          const updatedMessages = [...messages];
          latestMessageResponses.forEach((messageResponse) => {

            //if the message is already added by message status change web hook then don't add it again
           // if (updatedMessages.map((x) => x.id).indexOf(messageResponse.message.id) === -1)
              updatedMessages.push(messageResponse.message);
            
            if (
              messageResponse &&
              messageResponse.conversation.customer === null &&
              messageResponse.message.id === null
            ) {
              setMessage({
                messageBody: '',
                messageType: 'Text',
                loading: false,
                error: '',
              });
              dispatch(
                showSnackbar({
                  type: 'error',
                  message: 'You cannot send message anymore because customer has opted out of texts.',
                }),
              );
              return;
            } else {
              setMessage({
                messageBody: '',
                messageType: 'Text',
                loading: false,
                error: '',
              });
              onLatestMessageReceived(messageResponse.conversation);
            }
          });

          setMessages(updatedMessages);
          setScrollIndex(updatedMessages.length - 1);
          setGalleryImages([]);
          focusInputTextField();
        } catch (error: any) {
          let err = error.message ? error.message : 'Unable to send message';
          setMessage({
            ...messageState,
            loading: false,
            error: err,
          });
          await OpenPopup(err);
        }
      }
    }

    async function OpenPopup(err: string) {
      let phoneNumbers = await CustomerAPI.getPhoneNumbers(conversation.customer.crmCustomerId);
      let phoneTypes = await CustomerAPI.getPhoneTypes(dealerId);
      console.log(phoneNumbers);
      if (!phoneNumbers) {
        phoneNumbers = [];
      }
      let myArrayOfThings = [];
      if (phoneNumbers.length > 0) {
        myArrayOfThings = phoneNumbers.map((x: any) => {
          return {
            id: x.id,
            name: x.phoneNumber,
            type: phoneTypes && phoneTypes.find((y) => y.customerPhoneTypeId === x.phoneType)?.name,
          };
        });
      }
      let options: any = [];
      myArrayOfThings?.map((o: any) => {
        options[o.id] = o.name + ' : ' + '(' + `${o.type}` + ')';
      });

      if (err.toLowerCase().indexOf('customer cell phone not found') > -1 && options.length > 0) {
        //let timerInterval: any = null;
        Swal.fire({
          // icon: 'warning',
          // icon
          title: 'No Cell Phone Found',
          input: 'select',
          showClass: {
            popup: 'animate__animated animate__fadeIn animate__faster',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOut animate__faster',
          },
          inputOptions: options,
          showCancelButton: true,
          confirmButtonColor: '#3F51B5',
          cancelButtonColor: '#FF1744',
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: 'Save',
          html: 'Please select a primary cell phone for this customer.',
          didOpen: () => {
            // const b = Swal.getHtmlContainer()?.querySelector('b');
            // timerInterval = setInterval(() => {
            //   if (b) {
            //     b.textContent = Swal.getTimerLeft() ? Swal.getTimerLeft.toString() : "";
            //   }
            // }, 100)
          },
          willClose: () => {
            //clearInterval(timerInterval)
          },
        }).then(async (result) => {
          console.log(result);
          if (result.isConfirmed) {
            console.log('confirm');
            // update PhoneNumber to be primary
            console.log(result.value);
            const response: boolean | any = await CustomerAPI.updatePhoneNumberToPrimary(
              conversation.customer.crmCustomerId,
              result.value,
            );
            if (response) {
              //dispatch snackbar with success
              dispatch(showSnackbar({ type: 'success', message: 'Phone number updated' }));
            } else {
              dispatch(showSnackbar({ type: 'error', message: 'Unable to convert this phone number to primary' }));
            }
          } else {
            console.log('cancel');
          }
        });
      } else {
        dispatch(showSnackbar({ type: 'error', message: err.toString() }));
      }
    }
    const urlRegex = /https?:\/\/(www\.)?[^\s]+/g;
    const validUrl = /^(https?:\/\/(?:trgt\.io|tinyurl\.com|www\.tinyurl\.com|bit\.ly|www\.bit\.ly)\/?.*)$/;   
  

    const handleTextChange = (value: string) => {
      const foundUrls = value.match(urlRegex); // Find URLs in the text
      if (foundUrls && !foundUrls.every(url => validUrl.test(url))) {
        // dispatch(showSnackbar({ type: 'warning', message: 'Invalid URL format. Please use TargetCRM URL shortener.' }));
             setWarning(true);
       // setWarning('Warning: Invalid URL format. Please use TargetCRM URL shortener. Tracking analytics will not be available if the shortener is not used');
      } else {
        setWarning(false);
       // setWarning(''); // Clear the warning if URLs are valid or no URLs are found
      }
    };

    function handleMessageChange(event: any): void {
      setMessage({
        ...messageState,
        messageBody: event.target.value,
      });
    }

    function handleMessageKeyDown(event: any): void {
      const invalidUrlRegex = /^(?!.*(bit\.ly|tinyurl\.com)).*$/s;

  // Check if the message body contains an invalid URL
  
      if (event.key === 'Enter' && (messageState.messageBody.trim() == '') && !event.shiftKey ) {
        setMessage({
          ...messageState,
          messageBody: '',
        });
        event.preventDefault()
      }
      else 
      if (event.key === 'Enter' && !event.shiftKey) {
        if (!invalidUrlRegex.test(messageState.messageBody)) {
            dispatch(showSnackbar({ type: 'error', message: 'Please avoid shortened URLs (e.g., bit.ly); messages with them won\'t be sent\—Use our URL shortener instead.' }));
          event.preventDefault();
          return;
        }
        handleMessageSent();
      }
    }

    async function handleScrollTop(): Promise<void> {
      if (loadingRef.current === false && maxPageNumberRef.current < pageCountRef.current && scrollTriggerRef.current) {
        // pageNumberRef.current < pageCount
        loadingRef.current = true;
        if (pageNumberRef.current != maxPageNumberRef.current) {
          pageNumberRef.current = maxPageNumberRef.current;
        }
        pageNumberRef.current += 1;
        maxPageNumberRef.current += 1;
        console.log('scroll to top ');
        await fetchConversationMessages();
      }
    }
   
    async function handleScrollBottom(): Promise<void> {
      if (conversation.unreadMessageCount && minPageNumberRef.current === 0) {
        markConversationRead(conversation);
      } else if (minPageNumberRef.current > 0 && scrollTriggerRef.current == true) {
        if (loadingRef.current === false) {
          loadingRef.current = true;

          if (pageNumberRef.current != minPageNumberRef.current) {
            pageNumberRef.current = minPageNumberRef.current;
          }
          pageNumberRef.current -= 1;
          minPageNumberRef.current -= 1;
          console.log('scroll to bottom ');
          await fetchConversationMessages(false, false);
        }
      }
    }

    async function fetchConversationMessages(clicked: boolean = false, pageUp: boolean = true): Promise<void> {
      try {
        setLoading(true);
        loadingRef.current = true;
        if (conversation.id && !conversation.latestMessageSid) {
          conversation.latestMessageSid = '';
        }
        if (conversation && conversation.latestMessageSid) {
          //setSelectedMessageSid(conversation?.latestMessageSid);
        }
        if (conversation && (!conversation.latestMessageSid || conversation.latestMessageSid == '')) {
          if (pageNumberRef.current == -1) {
            pageNumberRef.current = 0;
          }
        }

        const response = await messagingApi.getSearchConversationMessages(
          conversation.id.toString(),
          latestMessageSid,
          pageNumberRef.current,
          20,
        );
        if (clicked) {
          minPageNumberRef.current = response.pageNumber;
          maxPageNumberRef.current = response.pageNumber;
        }
        pageNumberRef.current = response.pageNumber;
        pageCountRef.current = response.pageCount;

        if (clicked) {
          setMessages(response.messages);
        } else {
          const oldMessages = [...messages];
          const newMessages = [...response.messages];

          if (pageUp) {
            const newMessageList = [...newMessages, ...oldMessages];
            setMessages(newMessageList);
          } else {
            const newMessageList = [...oldMessages, ...newMessages];
            setMessages(newMessageList);
          }
        }

        setLoading(false);
      } catch (error) {
        loadingRef.current = false;
        setLoading(false);
      }
    }

    function insertInMessage(data: string, type = 'Text'): void {
      let textFieldIndex: number | null = null;
      if (textareaRef !== undefined && textareaRef.current !== undefined) {
        textFieldIndex = textareaRef.current.selectionStart;
      }
      if (textFieldIndex !== null) {
        const newMessage =
          messageState.messageBody.slice(0, textFieldIndex) + data + messageState.messageBody.slice(textFieldIndex);
        setMessage({
          messageBody: newMessage,
          messageType: type,
          loading: false,
          error: '',
        });
      }
    }

    function handleGalleryImageAdd(galleryImage: GalleryImage): void {
      const isPresent = galleryImages.find((x) => x.id === galleryImage.id);
      if (!isPresent) {
        setGalleryImages([...galleryImages, galleryImage]);
      }
      focusInputTextField();
    }

    function handleCustomerEdit(): void {
      if (isCustomerPermission == 1) {
        const customerId = conversation.customer.crmCustomerId;
        openCustomerEditDrawer(customerId);
      }      
    }
    function handleOnClickUnRead(): void {
      markConversationUnRead(conversation.id);
    }
    function handleOnClickReAssign(): void {
      const conversationId = conversation.id;
      assignConversation(conversation.id);
    }
    // function handleOnClickCreateNew(): void {
    //   const conversationId = conversation.id;
    //   assignConversation(conversation.id);
    // }
    function handleOnClickMerge(): void {
      const conversationId = conversation.id;
      SetOpenMergeMessengerDrawer(true);
    }
    async function RefreshConversationMessages():Promise<void>{
     
      await fetchConversationMessages(true);
    }
    function handleArchive(title:string)
    {
      const archiveRequest:ArchiveRequest = {
        ConversationId:conversation.id,
        IsArchive:!conversation.isArchive,
        Title:title
      }
      markConversationArchive(archiveRequest);
    }
    function handleOnClickArchive(): void {
        setOpenArchiveDialog(true);
    }
    function handleEmojiSelect(emoji: string): void {
      insertInMessage(emoji);
      focusInputTextField();
    }

    function handleAddMessageSnippet(messageSnippet: string): void {
      insertInMessage(messageSnippet);
      focusInputTextField();
      1;
    }

    function handleAddReviewLink(link: string): void {
      insertInMessage(link);
      focusInputTextField();
    }
    function handleAddVideoLink(link: string): void {
      link = 'Please join the video call at this link ' + link + '. It will open in a web browser.';
      insertInMessage(link, 'VideoCall');
      focusInputTextField();
    }
    // const onAddCustomerBtn = () => dispatch(showCustomerDrawer({ drawerType: 'CUSTOMER_ADD', drawerProps: {} }));
    function handleAddUrlShortner(shortner: string): void {
      insertInMessage(shortner);
      focusInputTextField();
    }

    async function handleThumbsUp(): Promise<void> {
      const thumbsUp = '👍'; // Use the correct thumbs-up emoji
      try {
        setMessage({ ...messageState, loading: true });
        const messageResponse = await messagingApi.sendMessage(conversation?.id, thumbsUp, 'Text', undefined);
        if (messageResponse && messageResponse.conversation.customer === null && messageResponse.message.id === null) {
          setMessage({
            messageBody: '',
            messageType: 'Text',
            loading: false,
            error: '',
          });
          setGalleryImages([]);
          focusInputTextField();
          dispatch(
            showSnackbar({
              type: 'error',
              message: 'You cannot send message anymore because customer has opted out of texts',
            }),
          );
        } else {
          
          //if the message is already added by message status change web hook then don't add it again
         // if (messages.map((x) => x.id).indexOf(messageResponse.message.id) === -1) 
          {
            const newMessages = [...messages, messageResponse.message];
            setMessages(newMessages);
            setScrollIndex(newMessages.length - 1);
          }
         
          setMessage({ ...messageState, loading: false });
          onLatestMessageReceived(messageResponse.conversation);
          focusInputTextField();
        }
      } catch (error: any) {
        let err = error.message ? error.message : 'Unable to send message';
        setMessage({
          ...messageState,
          loading: false,
          error: err,
        });
        await OpenPopup(err);
        // dispatch(showSnackbar({ type: 'error', message: error.response.data.message }));
      }
    }
    return (
      <> 
      
      <Box
        id="conversationPanel"
        className={classes.panel}
        style={{ outline: 'none' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
        onClick={(e) => e.preventDefault()}>
        <Modal
          open={modalOpen}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <>
            <Box sx={style}>
              <Grid container>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setModalOpen(false);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Typography variant="h5" component="h2">
                This Customer does not have a cellphone.
              </Typography>
              <Typography variant="h5" component="h2">
                This Role is assigned to users. If you want to delete this role, please select a new role which should
                be assigned to these users
              </Typography>
              {/* <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Select
                    required
                    disabled={editMode || roles?.length === 1}
                    label="Role"
                    style={{ width: "100%" }}
                    id="demo-simple-select"
                    value={deleteModalRoleId}
                    onChange={((e: any) => { setDelteModalRoleId(e.target.value) })}
                  >
                    <MenuItem key={0} value={0}>
                      Select Role
                    </MenuItem>
                    {roles?.filter(x => x.roleId != selectedDeleteRoleId).map((role: Role) => (
                      <MenuItem key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained" data-testid="handleAddWord" color="primary" onClick={() => { handleDeleteRole() }}>Delete Role</Button>
                </Grid>

              </Grid> */}

              {/* <TextField
                  fullWidth
                  select
                  margin="dense"
                  variant="outlined"
                  name="roleId"
                  inputProps={{ id: 'roleId' }}
                  label="Role"
                  
                  // value={formik.values.selectedRoleId}
                  // error={!!formik.errors.selectedRoleId}
                  // helperText={formik.errors.selectedRoleId}
                  >
                 
                </TextField> */}
            </Box>
          </>
        </Modal>
        <Box id="conversationToolbar" className={classes.toolbar}>
          <Box flexDirection="row" display="flex">
            <Box flexGrow={2} maxWidth={'530px'}>
              <Grid container>
                <Grid xs={7}>
                  {hidePersonName ? (
                    <></>
                  ) : (
                    <Grid container>
                      <Grid item>
                        <PersonIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="button" id="conversationTitle" title={personName?.toUpperCase()} style={{ marginLeft: 2 }}>
                          <b>{personName?.length > 22 ? personName?.slice(0, 22).concat('...') : personName}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    {/* Below show Organization Name */}
                    {(dmsTypeId === 0 || dmsTypeId === 9) && conversation.isOrganization &&
                      conversation.organizationName && (
                        <>
                          <Grid item>
                            <BusinessIcon />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="button"
                              title={conversation.organizationName?.toUpperCase()}
                              style={{ fontWeight: hidePersonName ? 'bold' : 'initial' }}>
                              &nbsp;
                              {conversation.organizationName?.length > 22 ? conversation.organizationName?.slice(0, 22).concat('...') : conversation.organizationName}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    {/* Below show Group Name */}
                    {(dmsTypeId === 1 || ((dmsTypeId === 0 || dmsTypeId === 9) && !conversation.isOrganization)) &&
                      !conversation.isAspenPrimaryContact && conversation.customer.name?.includes(':') && (
                        <>
                          <Grid item>
                            <GroupIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="button" title={conversation.customer.name.split(':')[0]?.toUpperCase()}>
                              &nbsp;
                              {conversation.customer.name.split(':')[0]?.length > 25 ? conversation.customer.name.split(':')[0]?.slice(0, 25).concat('...') : conversation.customer.name.split(':')[0]}
                            </Typography>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
                <Grid container xs={5}>
                  <Grid item onClick={handleCustomerEdit} style={{ display: isCustomerPermission == 1 ? "block" : "none" }}>
                    <AccountBoxIcon className={classes.customerDetailsText} />
                  </Grid>
                  <Grid item onClick={handleCustomerEdit} style={{ display: isCustomerPermission == 1 ? "block" : "none", padding: 1 }}>
                    <Typography className={classes.customerDetailsText}>Customer Details</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ float: 'right', display:'flex', flexGrow:1, justifyContent:'right' }}>
              <Tooltip title="Mark Unread">
                <IconButton disabled={conversation.isArchive} onClick={handleOnClickUnRead} color="primary" >
                  <Box sx={{ flexDirection: 'column', alignItems: 'center'  }}>
                      <MarkAsUnreadIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px'}}>Mark Unread</Typography>
                    </Box>  
                </IconButton>
              </Tooltip>
              {conversation.IsFacebookConversation?<>
                {/* <Tooltip title="Create New customer">
                <IconButton disabled = {conversation.isArchive} onClick={onAddCustomerBtn} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      <PersonAddAltRoundedIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Create New</Typography>
                    </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="Connect to Contact">
                <IconButton disabled = {conversation.isArchive} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      <GroupIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Connect to <br/>Contact</Typography>
                    </Box>
                </IconButton>
              </Tooltip> */}
              </>
              :
              <>
              {/* <Tooltip title="Create New customer">
                <IconButton disabled = {conversation.isArchive} onClick={onAddCustomerBtn} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      <PersonAddAltRoundedIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Create New customer</Typography>
                    </Box>
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Re-Assign">
                <IconButton disabled = {conversation.isArchive} onClick={handleOnClickReAssign} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      <PersonAddAltRoundedIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Re-Assign</Typography>
                    </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title={conversation.isArchive ? 'Unarchive' :'Archive'}>
                <IconButton disabled={conversation.isArchive} onClick={handleOnClickArchive} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                   {conversation.isArchive ?
                 
                   (  
                   <>
                    <UnarchiveIcon />
                    <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Unarchive</Typography>
                   </>
                   )
                   :(
                   <>
                    <ArchiveIcon />
                    <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Archive</Typography>
                   </>
                   )} 
                      
                    </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="Merge">
                <IconButton disabled = {conversation.isArchive} onClick={handleOnClickMerge} color="primary">
                    <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                      <MergeIcon />
                      <Typography style={{ fontSize: '12px', marginTop: '-10px' }}>Merge</Typography>
                    </Box>
                </IconButton>
              </Tooltip>
              </>
              }
              
            </Box>
          </Box>
          <Box className={classes.conversationHeaderChips}>
          {conversation.IsFacebookConversation?<></>:
          <>
          <Chip
              color="primary"
              icon={<PhoneIcon />}
              size="small"
              className={classes.conversationHeaderChip}
              label={`From: ${formatPhoneNumber(conversation.departmentPhoneNumber)}`}
            />
            <Chip
              color="primary"
              icon={<PhoneIcon />}
              size="small"
              className={classes.conversationHeaderChip}
              label={`To: ${formatPhoneNumber(conversation.customer.cellPhone) || 'N/A'}`}
            />
          </>}
            
            <Chip
              color="primary"
              icon={<DomainIcon />}
              size="small"
              className={classes.conversationHeaderChip}
              label={conversation.departmentName}
            />
            <Chip
              color="primary"
              icon={<LocationOnIcon />}
              size="small"
              className={classes.conversationHeaderChip}
              label={conversation.locationName}
            />
          </Box>
        </Box>
        <Box id="conversationContent" className={classes.conversationContent}>
          <MessagesLoader loading={loading} />
          {messages.length ? (
            <MessagesList
              ref={childRef}
              messagesList={messages}
              pageNumber={pageNumberRef.current}
              scrollIndex={scrollIndex}
              messageSid={latestMessageSid}
              totalPages={pageCountRef.current}
              onScrollTop={handleScrollTop}
              onScrollBottom={handleScrollBottom}
            />
          ) : null}
        </Box>
        {conversation.isArchive?(
        <Alert variant="filled" severity="info">
        {InfoMessages.ArchiveConversation}
        </Alert>
        ):( 
        <Box id="conversationAction" className={classes.conversationAction}>
          <UploadedImages
            galleryImages={galleryImages}
            loading={galleryImageUploading}
            onRemove={(galleryImage: GalleryImage): void => onRemoveFile(galleryImage.id)}
            progressCompleted = {uploadingProgress}
          />
          <input {...getInputProps()} hidden />
          <TextField
            inputRef={textareaRef}
            multiline
            rowsMax={10}
            id="messengerTextField"
            fullWidth
            autoFocus
            autoComplete="off"
            placeholder="Type a new message. Shift + ENTER to add a line break."
            variant="filled"
            margin="dense"
            size="small"

            disabled={messageState.loading}
            value={messageState.messageBody}
            onKeyDown={handleMessageKeyDown}
            onChange={(e: any) => {
              handleMessageChange(e) // Update form value
              handleTextChange(e.target.value); // Check for URLs and show warnings
            }}
           /// onChange={handleMessageChange}
          />
          <Box id="actionButtons" className={classes.actionButtons}>
          {conversation.IsFacebookConversation?<></>:
            <Tooltip title="Select Attachment">
              <IconButton
                style={{ padding: 0, marginRight: '-.2rem' }}
                onClick={(): void => {
                    if ((localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL') || (isHostedDealer && localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY')) {  
                      setGalleryImageUploading(true);
                      GetAttachments(26214400,'jpeg,jpg,png,gif,pdf');
                  } else {
                    open();
                  }
                }}
                size="small">
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
            }
            <UrlShortenerWidget onClick={handleAddUrlShortner} disabled={false} />
            <EmojiPickerButton onSelect={handleEmojiSelect} />
            {conversation.IsFacebookConversation?<></>:<ImageLibraryButton onSelect={handleGalleryImageAdd} />}
            <MessageSnippetWidget onClick={handleAddMessageSnippet} disabled={false} />
            {conversation.IsFacebookConversation?<></>:<SocialLinkWidget onClick={handleAddReviewLink} disabled={false} disableAllLocations={true} />}
            
            <VideoCallWidget onClick={handleAddVideoLink} conversationId={conversation.id} disabled={false} />
            {textToPay?<TextToPay Conversation={conversation} SendPayment={handleMessageSent}></TextToPay>:<></>}
            

            { warning &&<Alert  severity="warning">Invalid URL format. Please use TargetCRM URL shortener.</Alert>} 
            <Box flexGrow={1} />
            {messageState.loading ? (
              <SmallCircularProgress />
            ) : (
              <>
                {messageState.messageBody.length || galleryImages.length ? (
                  <Tooltip title="Send">
                    <IconButton
                      size="small"
                      classes={{ label: classes.iconButtonLabel }}
                      disabled={messageState.loading}
                      onClick={handleMessageSent}>
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Thumbs Up">
                    <IconButton
                      size="small"
                      disabled={messageState.loading}
                      classes={{ label: classes.iconButtonLabel }}
                      onClick={handleThumbsUp}>
                      <ThumbUpIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
        </Box>
        )}

        {isDragActive && <div className={classes.fileUploadOverlay} />}
       
      </Box>
      {openMergeMessengerDrawer && (
        <Box> 
           <MergeMessengerDrawer
        conversation={conversation}
        open={openMergeMessengerDrawer}
        refreshMessages={RefreshConversationMessages}
        onClose={() => { 
          SetOpenMergeMessengerDrawer(false);
        }}></MergeMessengerDrawer>
        </Box>
        
      )}
      {
        openArchiveDialog && ( <Box> 
          <ArchiveDialog
          openArchiveDialog = {openArchiveDialog}
          onClose = {() => { 
            setOpenArchiveDialog(false);
          }}
          onSubmit={(title:string) => { 
            handleArchive(title);
          }}
          ></ArchiveDialog>
         
          </Box>)
      }
        {/* {<CustomerRootPageDrawer />}
        {<CustomerRootDrawer />} */}
        </>
     
    );
  },
);

function areEqual(prevProps: ConversationPanelProps, nextProps: ConversationPanelProps): boolean {
  if (isEqual(prevProps.conversation, nextProps.conversation) && isEqual(prevProps.latestMessageSid, nextProps.latestMessageSid)) return true;
  return false;
}
const MemConversationPanel = React.memo(ConversationPanel, areEqual);
export default MemConversationPanel;
