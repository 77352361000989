import {
  Box,
  Divider,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { QuestionType, questionTypes } from 'components/survey/components/question-field';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  popover: {},
  paper: {
    padding: theme.spacing(1),
  },
}));
export enum QuestionCategory {
  Rating = 'Rating',
  PromotersAndPassive = 'PromotersAndPassive',
  Detractors = 'Detractors',
}
export default function NewQuestionBlock({ onAdd }: { onAdd(type: QuestionType): void }): React.ReactElement {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => setAnchorEl(null);
  const open = Boolean(anchorEl);

  function handleItemClick(type: QuestionType): void {
    onAdd(type);
    handlePopoverClose();
  }

  const questionTypeList = Object.entries(questionTypes).map(([type, { icon, title }]: any): React.ReactElement => {
    const Icon = icon;
    return (
      <ListItem id={`${type}QuestionBtn`} key={type} button onClick={(): void => handleItemClick(type)}>
        <ListItemIcon>
          <Icon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  });

  return (
    <Box mt={1} mb={3} textAlign="right">
      <Tooltip title="Add new question">
        <Fab id="addQuestionBtn" onClick={handlePopoverOpen} size="small" color="primary">
          <AddIcon />
        </Fab>
      </Tooltip>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography variant="subtitle1" style={{ textAlign: 'left', paddingBottom: '2px' }}>
          <b>Choose question type</b>
        </Typography>
        <Divider />
        {questionTypeList}
      </Popover>
    </Box>
  );
}
