import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Emoji, Picker } from 'emoji-mart';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import 'emoji-mart/css/emoji-mart.css';

function EmojiPicker({ onSelect }: { onSelect(value: string): void }): ReactElement {
  const [open, setOpen] = useState(false);
  const handleOnSelect = (emoji: any) => {
    setOpen(false);
    onSelect(emoji.native);
  };
  const handleOnClick = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Add Emoji">
        <IconButton id="emojiBtn" style={{ padding: 0 }} onClick={handleOnClick}>
          <Emoji emoji="slightly_smiling_face" size={24} />
        </IconButton>
      </Tooltip>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Picker
            autoFocus
            title="Emoji"
            style={{
              zIndex: 9999999,
              position: 'absolute',
              top: '35px',
              right: '13px',
            }}
            onSelect={handleOnSelect}
          />
        </ClickAwayListener>
      )}
    </>
  );
}

export default function EmojiTextField(
  props: TextFieldProps & { onTextChange: (value: string) => void }
): ReactElement {
  const { value, onTextChange, ...textFieldProps } = props;
  const [inputValue, setInputValue] = useState(value);
  const textareaRef = useRef<HTMLInputElement | undefined>(undefined);

  function handleEmojiSelected(emoji: string): void {
    let textFieldIndex: number | null = null;
    if (textareaRef !== undefined && textareaRef.current !== undefined) {
      textFieldIndex = textareaRef.current.selectionStart;
    }
    if (textFieldIndex !== null && typeof inputValue === 'string') {
      const newMessage = inputValue.slice(0, textFieldIndex) + emoji + inputValue.slice(textFieldIndex);
      onTextChange(newMessage);
    }
  }
  const handleOnChange = (event: any): void => {
    setInputValue(event.target.value);
    if (onTextChange) {
      onTextChange(event.target.value);
    }
  };
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      {...textFieldProps}
      inputRef={textareaRef}
      value={inputValue}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ alignItems: 'end', marginTop: '15px', marginRight: '-5px' }}>
            <EmojiPicker onSelect={handleEmojiSelected} />
          </InputAdornment>
        ),
      }}
    />
  );
}
