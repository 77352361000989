import { get, put } from 'api';
import config from 'config';
import LeadTextingSetting from '../models/lead-texting-setting';

const dealerUrl = config.get('api.dealer');

const LeadTextingSettingAPI = {
  async getLeadTextingSetting(dmsDealerIdCrm: number): Promise<LeadTextingSetting> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/lead-texting`;
    const { data } = await get(url);
    return data;
  },
  async updateLeadTextingSetting(dmsDealerIdCrm: number, leadTextingSetting: LeadTextingSetting): Promise<void> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/lead-texting`;
    const { data } = await put(url, leadTextingSetting);
    return data;
  },
};

export default LeadTextingSettingAPI;
