import { makeStyles, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  icon: {
    color: theme.palette.success.main,
    fontSize: 12,
  },
}));

function SuccessCheck(): ReactElement {
  const classes: any = useStyles();
  return <CheckCircleIcon className={classes.icon} />;
}

export default SuccessCheck;
