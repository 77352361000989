import { makeStyles, Theme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  icon: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));

function ErrorCancel(): ReactElement {
  const classes: any = useStyles();
  return <CancelIcon className={classes.icon} />;
}

export default ErrorCancel;
