import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import CustomerGroupAPI from 'components/customer-groups/api/customer-group-api';
import { store , useAppSelector } from 'app';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HelpIcon from '@mui/icons-material/Help';
import { Box,Tooltip, Checkbox, Drawer, Fab, Grid, IconButton, Link, makeStyles, Paper, Popover, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useTheme } from "@material-ui/core";
import Button from '@mui/material/Button';
import React, { useEffect } from "react";
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import { GetInvetoryByParts } from './apis/Inventory-api';
import { sanitizeString } from '../../common/utils/inventory-sanitizeSearch'
import config from 'config';
import { LocationSearching } from '@material-ui/icons';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    mySelect: {
        fontSize: "small",
        borderBottom: "1px solid #EAEDED"
    },
    myInput: {
        border: "1px solid #BDC3C7",
        padding: "4px 7px",
        width: "100%",
        '&:focus': {
            border: "1px solid #5DADE2",
            outline: "none",
            color: "#5DADE2",
        },
    },
    myTable: {
        '& th': {
        },
        '& td': {
            borderBottom: "1px solid #EAEDED",
            // whiteSpace: "nowrap",
            height: "25px"
        },
        '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
    },
}));

const headCells = [
    { id: 'MfrName', numeric: false, disablePadding: false, label: 'Line' },
    { id: 'PartNumber', numeric: false, disablePadding: false, label: 'Part Number' },
    { id: 'Description', numeric: false, disablePadding: false, label: 'Part Description' },
    { id: 'AvailableQty', numeric: true, disablePadding: false, label: 'On Hand' },
    // { id: 'SuggestedRetailPrice', numeric: true, disablePadding: false, label: 'MSRP' },
    { id: 'Price', numeric: true, disablePadding: false, label: 'Price' },
    { id: 'DealerName', numeric: false, disablePadding: false, label: 'Location' },
];


function EnhancedTableHead(props: any) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, dmsTypeId } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: "#EAECEE" }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {headCell.id=="Price"?
                            <>
                                <Tooltip title={"Price may vary due to taxes and fees calculated in DMS "+DmsTypeEnum[dmsTypeId]} placement="top">
                                    <span><InfoOutlinedIcon></InfoOutlinedIcon></span>
                                </Tooltip>
                            </>
                            :<></>}
                            
                            
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>

                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    dmsTypeId: PropTypes.number.isRequired
};

let mySearchTimeOut: any;
let lastPLSearch = '';
function InventoryPart() {
    const [openLocatorDrawer, SetOpenLocatorDrawer] = React.useState<any>(false);
    const classes = useStyles1();
    const [companyId, SetCompanyId] = React.useState(store.getState().user.dealerId);
    const [dealerId, SetDealerId] = React.useState(-1);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('PartNumber');
    const [searchText, SetSearchText] = React.useState('');
    const [advSearch, SetAdvSearch] = React.useState<any>(false);
    const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

    const [data, SetData] = React.useState([] as any);
    const [dealerData, SetDealerData] = React.useState([] as any);
    const [totalRows, SetTotalRows] = React.useState(-1);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    // const [dmsDealerId, SetDmsDealerId] = React.useState(store.getState().user.dmsDealerId);
    const [page, setPage] = React.useState(0);
    const [openDrawer, SetOpenDrawer] = React.useState<any>(false);
    const [partnumber, SetPartNumber] = React.useState('');
    const [partDescription, SetPartDescription] = React.useState('');
    const [serialNumber, SetSerialNumber] = React.useState('');
    const [lineCode, setLineCode] = React.useState('');
    const [wgStatus, SetWGStatus] = React.useState(4);
    const [Availability, SetAvailability] = React.useState(-1);
    const [line, SetLine] = React.useState("-1");
    const [LocationId, SetLocationId] = React.useState(-1);
    const [refreshTag, SetRefreshTag] = React.useState('');
    const [anchorModelHelper, SetAnchorModelHelper] = React.useState(null);
    const [openModelHelper, SetOpenModelHelper] = React.useState<any>(false);
    const [showWorking, SetShowWorking] = React.useState(true);
    const [showFilter, SetshowFilter] = React.useState(true);
    const [dealerLocations, SetDealerLocations] = React.useState<any>([]);
    const [plCount, setPLCount] = React.useState(0);
    const [plOptIn, setPlOptIn] = React.useState<any>(false);
    const dealerUrl = config.get('api.dealerExternal');
    const env = config.get('env');

    useEffect(() => {
        GetData(-1);

    }, [wgStatus, line, LocationId, refreshTag, rowsPerPage,Availability]);

    useEffect(() => {
        if (dealerId == -1) {
            let search = window.location.search.toLowerCase();
            let params = new URLSearchParams(search);
            let _dealerId = params.get('dealerid');
            if (_dealerId == null) _dealerId = store.getState().user.dealerId.toString();
            SetDealerId(Number(_dealerId));
            SetAvailability(-1);
            return;
        }
        GetDealerData();
        //if (LocationId == -99) { SetLocationId(dealerId) }


    }, [dealerId]);

    useEffect(() => {
        GetDealerData();
    }, [companyId]);

    useEffect(() => {
        GetData(totalRows);

    }, [page, order, orderBy, wgStatus]);

    useEffect(() => {
        GetPLOptIn();
    }, []);

    // useEffect(() => {
    //     let id=store.getState().user.dmsDealerId;
    //     if(store.getState().user.dmsTypeId==0) id= parseInt('1' + store.getState().user.dmsDealerId.toString() + '001') ;
    //     //alert(id);
    //     SetDmsDealerId(id);
    //     let urlPartlocator=`https://locator.csidealer.com/?dealer=${id}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}`;
    //     console.log('urlPartlocator: ' + urlPartlocator);
    //     //alert(urlPartlocator);


    //     //if(store.getState().user.dmsTypeId==0) SetDmsDealerId(parseInt('1' + store.getState().user.dealerId.toString() + '001')) ;
    // }, []);

    function GetDmsDealerId() {
        let id = store.getState().user.dmsDealerId;
        if (store.getState().user.dmsTypeId == 0) id = parseInt('1' + store.getState().user.dmsDealerId.toString() + '001');
        return id;
    }


    async function GetDealerLocations() {
        const data: any = await CustomerGroupAPI.GetDealerLocations();
        if (typeof (data) == 'undefined') { return ''; }
        SetDealerLocations(data);
        console.log(data);
    }

    function GetDealerData() {
        GetDealerLocations();
        //let url = "https://azurefunctionkg.azurewebsites.net/api/GetDealerInfo/" + store.getState().user.dealerId + "?code=8e7e0AOUnSE/s1UxPxyOCZHN0nrOh81eZAOdvEBvsCf9bj1efIldMQ=="
        //alert(companyId);
        //let url = "https://azurefunctionkg.azurewebsites.net/api/GetDealerInfo/" + companyId + "?code=8e7e0AOUnSE/s1UxPxyOCZHN0nrOh81eZAOdvEBvsCf9bj1efIldMQ=="
        let url = dealerUrl + "/dealers/inventory/" + dealerId;
        
        fetch(url)
            .then(response => response.json())
            .then(data =>
            (
                SetDealerData(data.DealerData[0])
            )

            ).catch(
                error => alert('Error: ' + error)
            );
    }


    function GetData(totalRows: any) {
        if (openDrawer == true && totalRows > -99) { return }
        if (LocationId == -99) { return }
        SetShowWorking(true);
        let _totalRows = totalRows;
        if (_totalRows < -1) _totalRows = -1;
        let locations :string = companyId.toString();
        GetLocationData().map((row: any) => {
            if(row.DealerId!=companyId){
                locations+=","+row.DealerId;
            }
        })
        
        let objData: any = new Object();
        objData.DealerId = LocationId==-1?locations:LocationId //companyId;//companyId==LocationId?companyId:companyId+","+LocationId;
        objData.PageNumber = page;
        objData.PageSize = rowsPerPage;
        objData.SearchPartNumber = partnumber;
        objData.SearchPartDescription = partDescription;
        objData.SearchManufacturerId = line=="-1"?"":line;
        objData.SearchManufacturerCode = lineCode;
        objData.StockingOnly = 0;
        objData.GroupByPart = 0;
        objData.SearchPartId = "";
        objData.MinAvailableQty = Availability;
        objData.SortBy=`[${orderBy}]${order}`
        
        
        if (searchText == 'advancesearch' || searchText == 'advsearch') {
            SetAdvSearch(true);
        }
        else {
            SetAdvSearch(false);
            objData.Search = searchText;
        }
        let obj: any = new Object();
        obj.query = "kartic.GetInventoryPartv3";
        obj.queryx = "GetInventoryPart";
        obj.queryBody = objData;
           
        GetInvetoryByParts(obj)   
            .then(data =>
            (
                SetData(data.Data?data.Data:[]),                
                SetTotalRows(data.Count==0?(data.Data?totalRows:0):data.Count),
                SetShowWorking(false),
                SetshowFilter(false),
                PLSearchCount()

            )
            ).catch(
                error => (alert('Error: ' + error),
                    SetShowWorking(false)
                )

            );
    }

    function ApplyFilter() {
        GetData(-1);

    }

    function OpenCloseFilter(value: number = 0) {
        SetOpenDrawer(value == 0 ? false : true);
    }


    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value: any) => {
        setRowsPerPage(value);
        setPage(0);
    };

    function TablePaginationActions(props: any) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (even: any) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event: any) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event: any) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event: any) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const handleSelectAllClick = (event: any) => {
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function OnStatusChage(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetWGStatus(e.target.value);
    }
    function OnLineChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetLine(e.target.value);
    }
    function OnLocationChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetLocationId(e.target.value);
    }
    function OnAvailabilityChange(e: any) {        
        if (typeof (e.target.value) == 'undefined') return;
        SetAvailability(e.target.value);
    }
    function HandleOnKeyUp(e: any) {
        if (e.keyCode == 13) {
            GetData(-1);
        }
    }

    function ApplyChanges() {
        clearTimeout(mySearchTimeOut);
        mySearchTimeOut = setTimeout(SearchData, 1000);
        setPage(0);
    }

    function SearchData() {
        SetRefreshTag(new Date().toString());
    }
    const handlerModelHelper = (event: any) => {
        if (anchorModelHelper == null) { SetAnchorModelHelper(event.currentTarget); }
        SetOpenModelHelper(true);
    };


    const handlePopoverClose = () => {
        SetOpenModelHelper(false);
    };

    function GetCompanyData() {
        let data_filter = dealerLocations.filter((x: any) => x.DealerId === x.CompanyDealerId);
        return data_filter;
    }

    function GetLocationData() {
        if (typeof (dealerLocations) == 'undefined') { return ''; }
        let data_filter = dealerLocations.filter((x: any) => x.CompanyDealerId === companyId);
        return data_filter;
    }



    function GetDealerNickName(row: any) {
        let item = dealerLocations.filter(function (item: any) { return item.DealerId === row.DealerId; });
        if (item != null && item.length > 0) {
            return item[0].DealerLocationNickName;
            //return 'found';
        }
        return row.DealerName;
    }

    function OnCompanyChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetCompanyId(e.target.value);
        SetLocationId(e.target.value);
        //GetDealerData();
    }

    function GetPLOptIn() {
        //let url=`http://localhost:7071/api/PLOptIn/${store.getState().user.dealerId.toString()}`;
        let url = `https://azurefunctionkg.azurewebsites.net/api/PLOptIn/${store.getState().user.dealerId.toString()}?code=EGOORJsYCZQf22qUU1fQfWi/EiAXK61o2kXuvjAAGox5qRamMQ1fTw==`;
        fetch(url)
            .then(response => response.json())
            .then(data =>
            (
                setPlOptIn(data[0].OptInPL)
            )

            ).catch(
                error =>
                    setPlOptIn(false)
            );
    }


    function PLSearchCount() {
        if (lastPLSearch == searchText) return;
        if (searchText == '') {
            lastPLSearch = '';
            setPLCount(0);
            return;
        }
        //let  url=`https://equipmentlocatorfunctionsprod.azurewebsites.net/api/CheckModel/12072/1/ms260/0?code=EdIvSIpgiTrjrKbmEeuLVW/m7aRsOqdxCt999SURsbaHAEKSRP/uXg==`;
        //let url=`https://equipmentlocatorfunctionsprod.azurewebsites.net/api/EquipmentLocator/10469/ms260/---ALL---/0/Any/1/false/Both?code=DD2Xt0Pz4cf9b8mEM1Lh5z//aySx1ke2dZMc7zIABNbR6Oiw4AU/9g==`;
        let url = `https://partslocatorfunctions.azurewebsites.net/api/LocatePart/${store.getState().user.dealerId}/${searchText}/---ALL---/0/Any/1/false?code=lB1YG8ar/9KhHEQq6vNyQmpkccIqx9XapKBkUTa7ijaRR/Vt3UQuow==`;
        lastPLSearch = searchText;
        fetch(url)
            .then(response => response.json())
            .then(data =>
            (
                setPLCount(data.length)
            )
            ).catch(
                error =>
                    //alert('Error DealerInfo: ' + error
                    setPLCount(0)
            );
    }


    function GetPLButtonColor(): any {
        if (plCount == 0) return 'primary';
        if (plCount > 0) return 'success';
    }


    return (

        <Box>
            <table>
                <tr>
                    <td>
                        <Box style={{ border: "0px solid gold", position: "relative" }}>
                            {plOptIn && (
                                <div style={{ position: "absolute", right: "-65px", top: "-54px" }} >
                                    <Badge badgeContent={plCount} color="primary">
                                        <Button color={GetPLButtonColor()} variant="contained" onClick={(event) => { SetOpenLocatorDrawer(true) }}>Parts Locator</Button>
                                    </Badge>
                                </div>
                            )}

                            <Grid>
                                <Grid item >
                                    <TextField
                                        fullWidth
                                        autoFocus
                                        style={{ backgroundColor: 'white' }}
                                        margin="dense"
                                        variant="outlined"
                                        name="searchText"
                                        id="search-text"
                                        placeholder="Search parts"
                                        value={searchText}
                                        InputProps={{
                                            startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
                                        }}
                                        onChange={(e: any) => {
                                            const newValue = sanitizeString(e.target.value.toString())
                                            SetSearchText(newValue);
                                            ApplyChanges(); 
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{ position: "absolute", marginTop: "-45px", marginLeft: "5px" }} >
                            {(showWorking) && (<CircularProgress />)}
                        </div>

                        <Box>
                            <table>
                                <tr>
                                    <td>
                                        <Box style={{ display: "table", border: "0px solid silver" }}>
                                            <Paper>
                                                <Table
                                                    size={'small'}
                                                    className={classes.myTable}
                                                >
                                                    <EnhancedTableHead
                                                        classes={classes}
                                                        numSelected={0}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={0}
                                                        dmsTypeId = {dmsTypeId}
                                                    />
                                                    <TableBody>
                                                        <TableRow style={{ display: `${advSearch ? "" : "none"}` }}>
                                                            <TableCell>
                                                                {typeof (dealerData.Line) !== 'undefined' && dealerData.Line != null && (
                                                                    <Select onClick={OnLineChange} defaultValue={line} style={{ fontSize: "small" }}
                                                                    >
                                                                        <MenuItem className={classes.mySelect} value={-1}>{"All Lines"}</MenuItem>
                                                                        {dealerData.Line.map((row: any) => {
                                                                            return (<MenuItem
                                                                                className={classes.mySelect}
                                                                                value={row.ManufacturerId}>{row.Name}</MenuItem>)
                                                                        })}
                                                                    </Select>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <table><tr><td>
                                                                    <input className={classes.myInput} type="text" style={{ maxWidth: "150px" }} value={partnumber} onChange={(e: any) => { SetPartNumber(e.target.value); ApplyChanges() }} ></input>
                                                                </td><td>
                                                                        <Link id='linkModelHelper' style={{ cursor: "help", padding: "0px", margin: "0px", border: "0px solid blue" }} onClick={handlerModelHelper}><HelpIcon color="primary" fontSize="small" style={{ fontSize: "15px" }}  ></HelpIcon></Link>
                                                                    </td></tr>
                                                                </table>
                                                            </TableCell>
                                                            <TableCell><input className={classes.myInput} type="text" style={{ maxWidth: "200px" }} value={partDescription} onChange={(e: any) => { SetPartDescription(e.target.value); ApplyChanges() }} ></input></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                {typeof (dealerData.Location) !== 'undefined' && dealerData.Location != null && (
                                                                    <Select onClick={OnLocationChange} defaultValue={LocationId} style={{ fontSize: "small" }} >
                                                                        <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                                                                        {dealerLocations.map((row: any) => {
                                                                            return (<MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>)
                                                                        })}

                                                                    </Select>
                                                                )}


                                                            </TableCell>

                                                        </TableRow>
                                                        {data.map((row: any) => {
                                                            let year = row.year;
                                                            if (year == 0) year = '';
                                                            let dealernickName = GetDealerNickName(row);
                                                            return (
                                                                <TableRow hover>
                                                                    <TableCell>{row.MfrName}</TableCell>
                                                                    <TableCell>{row.PartNumber}</TableCell>
                                                                    <TableCell>{row.Description}</TableCell>
                                                                    <TableCell align="right" >{row.AvailableQty}</TableCell>
                                                                    {/* <TableCell align="right">${row.SuggestedRetailPrice.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell> */}
                                                                    <TableCell align="right">${row.Price.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                                                                    <TableCell>{row.DealerName}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                                <TablePagination
                                                    size="small"
                                                    component="div"
                                                    rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                                    count={totalRows}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={(e) => { handleChangeRowsPerPage(e.target.value) }}


                                                />
                                            </Paper>
                                        </Box>
                                    </td>
                                </tr>
                            </table>
                            <Popover
                                id={"modelHelper"}
                                open={openModelHelper}
                                anchorEl={anchorModelHelper}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Typography style={{}}>
                                    <div style={{ padding: "5px 10px", fontSize: "small" }}>
                                        *607 = Model number ending with 607<br></br>
                                        607* = Model number starting with 607<br></br>
                                        607 = Model number contains 607
                                    </div>
                                </Typography>
                            </Popover>

                        </Box>
                    </td>
                    <td valign="top">
                        <Fab disabled={showFilter}  color="primary" onClick={() => OpenCloseFilter(1)} style={{ marginLeft: "10px", marginTop: "10px" }} title='Filter data'>
                            <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                                <FilterAltIcon />
                                <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                            </Box>
                        </Fab>
                    </td>
                </tr>
            </table>
            <Drawer
                PaperProps={{ style: { width: 'auto', minWidth: "20%" } }}
                anchor="right"
                open={openDrawer}
                onClose={() => OpenCloseFilter(0)}
                variant={'temporary'}
            >
                <DrawerToolbar title="Parts Filter" onClose={() => OpenCloseFilter(0)} />

                <Box style={{ padding: "5px 20px" }}>
                    <Box>
                        {/* <TextField
                                    fullWidth
                                    select
                                    type="select"
                                    id="dealerCompanyId"
                                    name="dealerCompanyId"
                                    margin="dense"
                                    value={companyId}
                                    onChange={OnCompanyChange}
                                    variant="outlined">
                                    {
                                        GetCompanyData().map((row: any) => (
                                            <MenuItem className={classes.mySelect} value={row.DealerId}>{row.Name}</MenuItem>
                                        ))
                                    }
                                </TextField> */}
                        {GetCompanyData().length > 1 && (
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Company</InputLabel>
                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={companyId}
                                    label="Company"
                                    onChange={OnCompanyChange}
                                >
                                    {
                                        GetCompanyData().map((row: any) => (
                                            <MenuItem className={classes.mySelect} value={row.DealerId}>{row.Name}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box style={{ marginTop: "15px" }}>
                        {typeof (dealerData.Location) !== 'undefined' && dealerData.Location != null && (
                            // <Select onClick={OnLocationChange} value={LocationId} style={{ fontSize: "small" }} >
                            //     <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                            //     {GetLocationData().map((row: any) => {
                            //         return (<MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>)
                            //     })}
                            // </Select>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={GetLocationData().length == 1 ? GetLocationData()[0].DealerId : LocationId}
                                    label="Location"
                                    onChange={OnLocationChange}
                                    disabled={GetLocationData().length == 1 ? true : false}
                                >
                                    <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                                    {
                                        GetLocationData().map((row: any) => (
                                            <MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                        )}
                    </Box>
                    <Box style={{ marginTop: "15px" }}>
                        {typeof (dealerData.Line) !== 'undefined' && dealerData.Line != null && (
                            // <Select onClick={OnLineChange} defaultValue={line} >
                            //     <MenuItem className={classes.mySelect} value={-1}>{"All Lines"}</MenuItem>
                            //     {dealerData.Line.map((row: any) => {
                            //         return (<MenuItem
                            //             className={classes.mySelect}
                            //             value={row.ManufacturerId}>{row.Name}</MenuItem>)
                            //     })}
                            // </Select>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Lines</InputLabel>
                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={line}
                                    label="Lines"
                                    onChange={OnLineChange}
                                >
                                    <MenuItem className={classes.mySelect} value={-1}>{"All Lines"}</MenuItem>
                                    {
                                        dealerData.Line.filter((x:any)=>x.IsPartManufacturer === true ).map((row: any) => (
                                            <MenuItem
                                                className={classes.mySelect}
                                                value={row.ManufacturerId}>{row.Name}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        )}

                    </Box>
                    <Box style={{ marginTop: "15px" }}>
                        {typeof (dealerData.Location) !== 'undefined' && dealerData.Location != null && (                          
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Availability</InputLabel>
                                <Select

                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={Availability}
                                    label="Location"
                                    onChange={OnAvailabilityChange}
                                >
                                    <MenuItem className={classes.mySelect} value={-1}>{"All"}</MenuItem>
                                    <MenuItem className={classes.mySelect} value={1}>{"Quantity 1+"}</MenuItem>                                   
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box>

                        <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "15px" }} >
                            <Button color="primary" variant="contained" onClick={() => { SetOpenDrawer(false); GetData(-99) }}>Apply Filter</Button>
                        </Box>

                    </Box>
                </Box>
            </Drawer>
            <Drawer
                PaperProps={{ style: { width: '80%', minWidth: "25%" } }}
                anchor="right"
                open={openLocatorDrawer}
                onClose={() => SetOpenLocatorDrawer(false)}
                variant={'temporary'}
            >
                <Box>
                { env === 'staging' ? 
                    <iframe src={`https://partlocatortest.azurewebsites.net/?dealer=${GetDmsDealerId()}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}&istestmachine=true&isadminuser=true`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe>
                    :
                    <iframe src={`https://locator.csidealer.com/?dealer=${GetDmsDealerId()}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe>
                }
                </Box>
            </Drawer>

        </Box>
    )

}
export default InventoryPart;