import { destroy, get, post } from 'api';
import config from 'config';

import { GalleryImage } from '../models/gallery-image';

const messagingUrl = config.get('api.messaging');

export const MediaGalleryAPI = {
  getMediaCount: async (): Promise<number> => {
    const url = `${messagingUrl}/GetMediaCount`;
    const { data } = await get<number>(url);
    return data;
  },
  getImageGallery: async (page: number | null = null, rowsPerPage: number | null = null): Promise<GalleryImage[]> => {
    const url = `${messagingUrl}/GetMediaGallery?pageNumber=${page}&pageSize=${rowsPerPage}`;
    const { data } = await get<GalleryImage[]>(url);
    return data;
  },
  addImage: async (files: File[]): Promise<GalleryImage[]> => {
    const url = `${messagingUrl}/AddImages`;
    const requestForm = new FormData();
    files.forEach((file) => {
      requestForm.append('files', file);
    });
    const { data } = await post<GalleryImage[]>(url, requestForm);
    return data;
  },
  deleteMedia: async (imageId: number): Promise<boolean> => {
    const url = `${messagingUrl}/deleteMedia/${imageId}`;
    const { data } = await destroy(url);
    return data;
  },
};

export default MediaGalleryAPI;
