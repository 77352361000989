import { useAppDispatch, useAppSelector } from 'app';
import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import appLocalStorage from 'app/local-storage';
import { logoutUser } from 'components/authentication/components/authentication-slice';
import AppSnackbar from 'components/snackbar/snackbar';
import ValidateLogin from 'components/authentication/components/login/validateLogin';

function PrivateRoute(props: RouteProps): React.ReactElement | null {
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const session = useAppSelector((state) => state.user);
  const isAuthenticated: boolean = session.loggedIn;
  const dispatch = useAppDispatch();
  const textToPay = useAppSelector((state) => state.user?.isTextToPayAllow);  
  const { component: Component, ...restProps } = props;
  if (!Component) return null;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('token');
  if (token != null && token != '') {
    return <ValidateLogin token={token}></ValidateLogin>;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
          search: `?redirectUrl=${(props.location?.pathname || '') + (props.location?.search || '')}`,
          state: { from: props.location },
        }}
      />
    );
  }

  let userPermissions = useAppSelector((state) => state.user.userPermissions);
  if (!userPermissions || userPermissions.length === 0) {
    appLocalStorage.remove();
    dispatch(logoutUser());
    showSnackbar({ type: 'error', message: "you don't have permission to login" });
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  }

  let arr = props.path?.toString().split('/');
  let filtered = arr?.filter((el: string) => {
    return el != null && el != '' && el != undefined;
  });
  let pageName = filtered ? filtered[0] : ''; //.replace('/', '');

  if (props.path?.toString() == '/') pageName = 'dashboard';

  let permission = userPermissions.findIndex(
    (x) =>
      x.featureName.toLowerCase() == pageName?.toLowerCase() ||
      pageName?.toLowerCase() === 'user-profile' ||
      pageName?.toLowerCase() === 'dealer' ||
      pageName?.toLowerCase() === 'messages-customer' ||
      pageName?.toLowerCase() === 'review-hub',
  );
  if(!textToPay && userPermissions[permission]?.featureName.toLowerCase() === 'payments'){
    permission = -1;
  }
  let pageToRoute = userPermissions[0].featureName.toLowerCase();

  if (pageToRoute === 'dashboard') pageToRoute = '/';
  if (permission == -1)
    return (
      <Redirect
        to={{
          pathname: pageToRoute,
          state: { from: props.location },
        }}
      />
    );

  return (
    <Route
      {...restProps}
      render={(routeRenderProps): ReactElement =>
        isAuthenticated ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
