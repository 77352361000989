export interface LocationOption {
  name: string;
  dealerLocationId: number;
}

export function getAllLocationOption(): LocationOption {
  return {
    name: 'All Locations',
    dealerLocationId: 0,
  };
}
