import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { SmallCircularProgress } from 'common/components/loaders';
import LeadTextingSettingAPI from 'components/setting/api/lead-texting-setting';
import LeadTextingSetting from 'components/setting/models/lead-texting-setting';
import AppSnackbar from 'components/snackbar/snackbar';
import { useFormik } from 'formik';
import parse from 'html-react-parser';
import React from 'react';
import { useAppSelector } from 'app';

import useDepartments from '../department/useDepartments';
import useStyles from './widget-styles';

function useLeadTextingSetting(
  dmsDealerIdCrm: number,
): [boolean, LeadTextingSetting | null, (leadTextingSetting: LeadTextingSetting) => Promise<void>] {
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [leadTextingSetting, setLeadTextingSetting] = React.useState<LeadTextingSetting | null>(null);

  const fetchLeadTextingSetting = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await LeadTextingSettingAPI.getLeadTextingSetting(dmsDealerIdCrm);
      setLoading(false);
      if (response) {
        response.pagePosition = response.pagePosition.toLowerCase();
      }
      setLeadTextingSetting(response);
      console.log('The data is', leadTextingSetting);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'unable to fetch setting' });
    }
  }, [dmsDealerIdCrm, showSnackBar]);

  const updateLeadTextingSetting = React.useCallback(
    async (newLeadTextingSetting: LeadTextingSetting): Promise<void> => {
      try {
        await LeadTextingSettingAPI.updateLeadTextingSetting(dmsDealerIdCrm, newLeadTextingSetting);
        showSnackBar({ type: 'success', message: 'updated successfully' });
      } catch (error) {
        showSnackBar({ type: 'error', message: 'unable to update setting' });
      }
    },
    [dmsDealerIdCrm, showSnackBar],
  );

  React.useEffect(() => {
    fetchLeadTextingSetting();
  }, [fetchLeadTextingSetting]);

  return [loading, leadTextingSetting, updateLeadTextingSetting];
}

const Widget: React.FC = () => {
  const classes: any = useStyles();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [loading, leadTextingSetting, handleSave] = useLeadTextingSetting(dmsDealerIdCrm);
  const departments = useDepartments(dmsDealerIdCrm, 0);
  const [copied, setCopied] = React.useState<boolean>(false);

  const initialValues: LeadTextingSetting = {
    buttonText: leadTextingSetting?.buttonText || '',
    greetingText: leadTextingSetting?.greetingText || '',
    showGreeting: leadTextingSetting?.showGreeting || false,
    fontColor: leadTextingSetting?.fontColor || '#ffffff',
    backgroundColor: leadTextingSetting?.backgroundColor || '#000000',
    scriptTag: leadTextingSetting?.scriptTag || '',
    pagePosition: leadTextingSetting?.pagePosition ? leadTextingSetting.pagePosition : 'right',
    optInStatement : leadTextingSetting?.optInStatement ? leadTextingSetting.optInStatement :  '',
    dealers: leadTextingSetting?.dealers || [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
    enableReinitialize: true,
  });

  async function handlePreview(): Promise<void> {
    if (formik.values.scriptTag) {
      const scriptElement: any = parse(formik.values.scriptTag);
      window.open(
        `/preview.html?src=${scriptElement.props.src}&id=${scriptElement.props.id}&data-client-id=${scriptElement.props['data-client-id']}`,
        '_blank',
      );
    }
  }

  function copyScriptToClipBoard(): void {
    if (leadTextingSetting) {
      const el = document.createElement('textarea');
      el.value = leadTextingSetting?.scriptTag;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  }

  function renderLoading(): React.ReactElement {
    return (
      <Box justifyContent="center" display="flex">
        <SmallCircularProgress />
      </Box>
    );
  }
  console.log('This an other data', leadTextingSetting);
  function renderForm(): React.ReactElement {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box mb={1} display="flex">
          <Box flex={1} />
          <Box>
            <Button color="primary" variant="contained" size="small" onClick={handlePreview}>
              Preview
            </Button>
          </Box>
          <Box mx={1}>
            <Button color="primary" variant="contained" size="small" type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </Box>
        </Box>
        <Box>
          <Grid spacing={2} container>
            <Grid item md={7} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextLimitField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    label="Button Text"
                    name="buttonText"
                    id="buttonText"
                    value={formik.values.buttonText}
                    onChange={formik.handleChange}
                    error={!!formik.errors.buttonText}
                    helperText={formik.errors.buttonText}
                    characters={20}
                  />
                </Grid>
                <Grid item xs={7} style={{ display: 'flex', alignItems: 'baseline' }}>
                  <TextLimitField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    disabled={!formik.values.showGreeting}
                    label="Greeting"
                    name="greetingText"
                    id="greetingText"
                    value={formik.values.greetingText}
                    onChange={formik.handleChange}
                    error={!!formik.errors.greetingText}
                    helperText={formik.errors.greetingText}
                    characters={50}
                  />
                  <Switch
                    id="showGreeting"
                    name="showGreeting"
                    value={formik.values.showGreeting}
                    checked={formik.values.showGreeting}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="color"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    label="Background Color"
                    name="backgroundColor"
                    id="backgroundColor"
                    value={formik.values.backgroundColor}
                    onChange={formik.handleChange}
                    error={!!formik.errors.backgroundColor}
                    helperText={formik.errors.backgroundColor}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="color"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    label="Font Color"
                    name="fontColor"
                    id="fontColor"
                    value={formik.values.fontColor}
                    onChange={formik.handleChange}
                    error={!!formik.errors.fontColor}
                    helperText={formik.errors.fontColor}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    name="pagePosition"
                    label="Page Position"
                    id="pagePosition"
                    placeholder={formik.values.pagePosition}
                    value={formik.values.pagePosition}
                    onChange={formik.handleChange}
                    error={!!formik.errors.pagePosition}
                    helperText={formik.errors.pagePosition}>
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="right">Right</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'baseline' }}>
                  <TextLimitField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    label="OptIn Statement"
                    rows={3}
                    name="optInStatement"
                    id="optInStatement"
                    value={formik.values.optInStatement}
                    onChange={formik.handleChange}
                    error={!!formik.errors.optInStatement}
                    helperText={formik.errors.optInStatement}
                    characters={300}
                  />
                </Grid>
              </Grid>
              <Box id="lead-texting-script-content" mt={2}>
                <Box>
                  <Typography variant="h5">LeadTexting Widget</Typography>
                </Box>
                <Box display="flex" my={1}>
                  <Box flex={1} mr={1} id="lead-texting-instructions">
                    <Typography variant="body1">
                      Send this to your website administrator. If you are the site administrator, insert this before the
                      &lt;/body&gt; tag on every page.
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column-reverse">
                    <Button
                      id="copy-lead-texting-script"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={copied ? classes.copiedBtn : ''}
                      onClick={copyScriptToClipBoard}>
                      {copied ? 'Copied' : 'Copy'}
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="scriptTag"
                    id="lead-texting-script"
                    size="small"
                    label="Script"
                    margin="dense"
                    multiline
                    rows={3}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={leadTextingSetting?.scriptTag}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box>
                <Box>
                  <Typography variant="h5">Department Direct Lines</Typography>
                </Box>
                <Box>Select departments that can receive website lead texts</Box>
                <Box>
                  {formik.values.dealers.map((location, idx) => (
                    <Accordion square key={location.dealerId} style={{ margin: '5px 0px' }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <TextField
                          fullWidth
                          name={`dealers.${idx}.locationName`}
                          id="locationName"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          onChange={formik.handleChange}
                          label="Location Name"
                          value={location.locationName}
                        />
                      </AccordionSummary>
                      <AccordionDetails style={{ flexDirection: 'column' }}>
                        {location.departments.map((department, id) => (
                          <Grid container spacing={1} key={department.departmentId}>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                name={`dealers.${idx}.departments.${id}.displayName`}
                                id="displayName"
                                label="Display name"
                                size="small"
                                margin="dense"
                                onChange={formik.handleChange}
                                value={formik.values.dealers[idx].departments[id].displayName}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                name={`department.${department.departmentId}.originalName`}
                                size="small"
                                margin="dense"
                                label="Original Department name"
                                disabled
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={
                                  departments?.find(
                                    (x) => x.departmentId === formik.values.dealers[idx].departments[id].departmentId,
                                  )?.departmentName || ''
                                }
                              />
                            </Grid>
                            <Grid item xs={2} style={{ margin: 'auto' }}>
                              <Switch
                                name={`dealers.${idx}.departments.${id}.isSelected`}
                                id="isSelected"
                                checked={formik.values.dealers[idx].departments[id].isSelected}
                                onChange={formik.handleChange}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }

  return (
    <Paper square style={{ padding: '10px', minHeight: '500px' }}>
      {loading ? renderLoading() : renderForm()}
    </Paper>
  );
};

export default Widget;
