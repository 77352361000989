import { makeStyles, Snackbar, Theme } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { hidesnackbar } from './snackbar-slice';

function Alert(props: AlertProps): ReactElement {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const RootSnackbar = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { message, type } = useAppSelector((state) => state.snackbar);

  let open = true;
  const handleOnClose = (): void => {
    dispatch(hidesnackbar());
  };

  if (type === undefined) open = false;

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleOnClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert onClose={handleOnClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default RootSnackbar;
