import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  tag: (props: { color: string }): any => ({
    transform: 'rotate3d(1, 1, 10, 90deg) translate(-25px, 10px)',
    transformOrigin: 'left top',
    position: 'absolute',
    background: props.color,
    borderRadius: '3px 0 0 3px',
    color: 'white',
    display: 'inline-block',
    height: '20px',
    fontSize: '10px',
    lineHeight: '20px',
    padding: '0 15px 0 18px',
    textDecoration: 'none',
    marginRight: '2px',
    '&::before': {
      background: '#fff',
      borderRadius: '10px',
      boxShadow: 'inset 0 1px rgba(0, 0, 0, 0.25)',
      content: '""',
      height: '6px',
      left: '7px',
      position: 'absolute',
      width: '5px',
      top: '7px',
    },
    '&::after': {
      background: 'white',
      borderBottom: '10px solid white',
      borderLeft: `10px solid ${props.color}`,
      borderTop: '10px solid white',
      content: '""',
      position: 'absolute',
      right: -10,
      top: 0,
    },
  }),
}));

export function Tag({ label, color, style }: { label: string; color: string; style?:CSSProperties }): ReactElement {
  const classes: any = useStyles({ color });
  return <div style={style} className={classes.tag}>{label}</div>;
}

export default Tag;
