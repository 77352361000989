import { Box, Container, makeStyles, Paper, Tab, Theme } from '@material-ui/core';
import { TabContext, TabPanel } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import React from 'react';
import AnalyticsTab from './analytics-tab';
import BroadCastsTab from './broadcasts-tab';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function BroadCastPage(): React.ReactElement {
  const classes: any = useStyles();

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, tabValue: string) => {
    setValue(tabValue);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <TabContext value={value}>
        <Paper square style={{ marginBottom: 10 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="broadcast tabs" style={{ fontSize: 'small' }}>
              <Tab label="Broadcasts" value="1" />
              <Tab label="Analytics" value="2" />
            </TabList>
          </Box>
        </Paper>

        <TabPanel value="1" style={{ padding: 0 }}>
          <BroadCastsTab />
        </TabPanel>

        <TabPanel value="2" style={{ padding: 0 }}>
          <AnalyticsTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}

export default BroadCastPage;
