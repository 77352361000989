import React, { ReactElement } from 'react'
import { Button, Typography, TextField, Paper, MenuItem } from "@mui/material";
import { Link } from '@material-ui/core';

interface EmailLinkProps {
    email?:string | undefined;
    classStyle?: any;
  }

function EmailLink({email,classStyle}: EmailLinkProps): ReactElement{
    const src=`mailto: ${email}?subject= &body= `;
    return (
        <Link className={classStyle} href={src}>{email}</Link>
    )

}

export default EmailLink