import { createTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import palette from './palette';
import typography from './typography';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    status: {
      danger: string;
    };
    navBar: {
      height: string;
    };
    dashboardDrawer: {
      width: number;
    };
    notificationDrawer: {
      width: number;
    };
    messenger: { surveyMessageBg: string; broadcastMessageBg: string; leadTextingMessageBg: string; highlightedMessageBg: string };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    navBar: {
      height: string;
    };
    dashboardDrawer: {
      width: number;
    };
    notificationDrawer: {
      width: number;
    };
    messenger: { surveyMessageBg: string; broadcastMessageBg: string; leadTextingMessageBg: string; highlightedMessageBg: string };
  }
}
export const CustomTheme = createTheme({
  palette,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  status: {
    danger: 'red',
  },
  navBar: {
    height: '48px',
  },
  dashboardDrawer: {
    width: 200,
  },
  notificationDrawer: {
    width: 0,
  },
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: green[500],
        },
        '&$checked + $track': {
          backgroundColor: green[500],
        },
      },
      colorPrimary: {
        '&$checked': {
          color: green[500],
        },
        '&$checked + $track': {
          backgroundColor: green[500],
        },
      },
      switchBase: {
        '&$checked': {
          color: green[500],
        },
        '&$checked + $track': {
          backgroundColor: green[500],
        },
      },
    },
  },
  messenger: {
    surveyMessageBg: '#42a9bf',
    broadcastMessageBg: '#5742bf',
    leadTextingMessageBg: '#67AE3F',
    highlightedMessageBg : '#3f51b5c4'
  },
});

export default CustomTheme;
