import { Box, Card, CardContent, CardHeader, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import CustomerGroup from 'components/customer-groups/models/customer-group';
import moment from 'moment';
import React from 'react';

const useCardStyles = makeStyles((theme: Theme): any => ({
  card: {
    height: '198px',
    cursor: 'pointer',
    maxWidth: '296px',
  },
  header: {
    height: '60px',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  content: {
    height: '65px',
    overflow: 'hidden',
  },
  actions: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  actions2: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  menuBtn: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  cardPaperSelected: {
    border: `2px solid ${teal[500]}`,
  },
}));

interface Props {
  customerGroup: CustomerGroup;
  onClick(segmentId: number): Promise<void>;
}

export default function CustomerGroupCard({ customerGroup, onClick }: Props): React.ReactElement {
  const classes: any = useCardStyles();
  let segmentDate = null;

  async function onCardClick(): Promise<void> {
    onClick(customerGroup.segmentId);
  }

  const formatDate = (date: string): string => moment(date).format('M-DD-YYYY');

  const forDate = `${formatDate(customerGroup.calculatedFromDate || '')}`;
  const toDate = `${formatDate(customerGroup.calculatedToDate || '')}`;
  segmentDate = (
    <>
      <Box>
        <Grid container style={{ fontSize: 'small' }}>
          <Grid xs={6} item>
            From:{' '}
          </Grid>
          <Grid xs={6} item>
            <Box>{forDate}</Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={1}>
        <Grid container style={{ fontSize: 'small' }}>
          <Grid xs={6} item>
            To:{' '}
          </Grid>
          <Grid xs={6} item>
            <Box>{toDate}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  const title = customerGroup.segmentName;
  const subHeader =
    customerGroup?.period && customerGroup?.periodValue
      ? `${customerGroup?.periodValue} ${customerGroup?.period === 1 ? 'Month' : 'Day'}${
          customerGroup.periodValue && customerGroup?.periodValue > 1 ? 's' : ''
        }`
      : null;

  return (
    <Card className={classes.card} onClick={onCardClick}>
      <CardHeader classes={{ title: classes.headerTitle }} title={title} subheader={subHeader} />
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Box flexGrow={1}>{segmentDate}</Box>
          <Box mt={1} className={title && title.length > 34 ? classes.actions2 : classes.actions}>
            <Typography variant="caption">{customerGroup?.segmentDescription}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
