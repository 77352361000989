import {
  hideTaskDrawer,
  setDealerLocationId,
  setTaskTypeId,
  resetFilter,
  setTaskStatusId,
  setAssignedUsers,
  fetchTasks,
  setTaskIsArchived,
} from '../taskboardSlice';
import TaskboardAPI from '../taskboard-api';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useAppDispatch, useAppSelector } from 'app';
import React, { ReactElement, useCallback, useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { UserTaskStatus } from 'components/lead/models/task';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import useUsersList from 'components/setting/components/department/userUsersList';
import User from 'components/setting/models/user';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import useLocations from 'common/hooks/useLocations';
import DealerAPI from 'components/authentication/api/dealer-api';
import { PersistentAppData } from 'common/models';

function TaskFilterDrawer(): ReactElement {
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [taskTypes, setTaskTypes] = React.useState([]);
  const assignedUserIds = useAppSelector((state: any) => state.tasks.assignedUserIds || state.user.crmUserId);
  const taskTypeId = useAppSelector((state: any) => state.tasks.taskTypeId);
  const taskIsArchived = useAppSelector((state: any) => state.tasks.isArchived);
  const taskStatusId = useAppSelector((state: any) => state.tasks.taskStatusId);
  const dealerLocationId = useAppSelector((state: any) => state.tasks.dealerId);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { loading: locationLoading, locationOptions } = useLocations(dmsDealerIdCrm);
  const dispatch = useAppDispatch();

  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };

  const getTaskTypeList = useCallback(async (): Promise<void> => {
    let data = await TaskboardAPI.getTaskTypes();
    setTaskTypes(data);
  }, [1]);

  React.useEffect(() => {
    getTaskTypeList();
  }, [getTaskTypeList]);

  const dealerId = useAppSelector((state) => state.user.dealerId);
  const crmUserId = useAppSelector((state) => state.user.crmUserId);
  const dmsDealerId = useAppSelector((state) => state.user.dmsDealerId);
  const users = useUsersList(dealerId);

  async function SetFilterLocally(assignedUser:any,statusId:any,typeId:any,archieve:boolean, location:any){
    const taskfilerApiData: PersistentAppData = {
      crmUserId: crmUserId,
      dmsDealerId:dmsDealerId,
      type: `TaskFilter_${dmsDealerId}_${crmUserId}`,
      data: JSON.stringify(
        {
          assignedUserIds:assignedUser ?? assignedUserIds,
          taskStatusId:statusId ?? taskStatusId,
          taskTypeId:typeId ?? taskTypeId,
          isArchived:archieve ?? taskIsArchived,
          location: location ?? dealerLocationId
        })
    }
    localStorage.setItem(`TaskFilter_${dmsDealerId}_${crmUserId}`,taskfilerApiData.data);
    await DealerAPI.updatePersistentAppData(taskfilerApiData);
  }

  const handleUserChange = (val: User[]) => {
    let selectedUsers = val.map((x) => x.crmUserId);
    SetFilterLocally(selectedUsers,taskStatusId,taskTypeId,taskIsArchived,dealerLocationId);
    dispatch(setAssignedUsers(selectedUsers));
    dispatch(fetchTasks());
  };

  const handleResetFilter = async () => {
    const taskFilterRemove:PersistentAppData = {
      crmUserId:crmUserId,
      dmsDealerId:dmsDealerIdCrm,
      type:`TaskFilter_${dmsDealerId}_${crmUserId}`,
      data:JSON.stringify(
        {
          assignedUserIds:[],
          taskStatusId:UserTaskStatus.New,
          taskTypeId:null,
          isArchived:false,
          location: dealerId
        })
    }
    await DealerAPI.updatePersistentAppData(taskFilterRemove);
    localStorage.removeItem(`TaskFilter_${dmsDealerId}_${crmUserId}`);
    dispatch(setDealerLocationId(dealerId));
    dispatch(resetFilter());
    dispatch(fetchTasks());
  };

  const assignedUser: User[] | undefined = users?.filter(
    (x: User) => assignedUserIds && assignedUserIds.indexOf(x.crmUserId) > -1,
  );

  return (
    <>
      <DrawerToolbar title={'Filters'} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <form noValidate autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <Box ml={1}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    margin="dense"
                    variant="outlined"
                    name="location"
                    label="Location"
                    value={
                    (locationOptions && locationOptions.length === 1) 
                    ? locationOptions[0].dealerLocationId 
                    : dealerLocationId
                     }
                     disabled={locationLoading}
                    // inputProps={{ id: 'location' }}
                    onChange={(e) => {
                      SetFilterLocally(assignedUserIds,taskStatusId,taskTypeId,taskIsArchived,parseInt(e.target.value));
                      dispatch(setDealerLocationId(parseInt(e.target.value)));
                    }}>
                    {locationOptions &&
                      locationOptions.map((loc) => (
                        <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                          {loc.name === 'All Locations' ? 'Select Location' : loc.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>
                <Grid item xs={12}>
                  <Box ml={1}>
                    <Autocomplete
                      id="usersDropdown"
                      value={assignedUser || []}
                      multiple={true}
                      options={users || []}
                      onChange={(event: any, values: any): void => handleUserChange(values)}
                      getOptionLabel={(option: any) => option.username}
                      loading={true}
                      disableCloseOnSelect
                      placeholder={assignedUser && assignedUser.length > 0 ? assignedUser[0].username : ''}
                      getOptionSelected={(option: User, value: User): boolean => {
                        return option.crmUserId === value.crmUserId;
                      }}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                          {option.username}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="users"
                          id="users"
                          variant="outlined"
                          margin="dense"
                          label="Assignment"
                          placeholder="Select Users"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} ml={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="taskType" variant="outlined" margin="dense">
                        Task Type
                      </InputLabel>
                      <Select
                        labelId="taskType"
                        id="taskType"
                        value={taskTypeId}
                        label="Task Type"
                        margin="dense"
                        onChange={(e: any, opt: any) => {
                          SetFilterLocally(assignedUserIds,taskStatusId,opt.props.value,taskIsArchived,dealerLocationId);
                          dispatch(setTaskTypeId(opt.props.value));
                        }}>
                        {taskTypes?.map((x: any) => (
                          <MenuItem value={x.id}>{x.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={1} ml={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="taskStatus" variant="outlined" margin="dense">
                        Task Status
                      </InputLabel>
                      <Select
                        labelId="taskStatus"
                        id="taskStatus"
                        value={taskStatusId ? taskStatusId : ''}
                        label="Task Status"
                        margin="dense"
                        onChange={(e: any, opt: any) => {
                          if (!e.target.value) {
                            dispatch(setTaskStatusId(null));
                          } else {
                            SetFilterLocally(assignedUserIds,parseInt(opt.props.value),taskTypeId,taskIsArchived,dealerLocationId);
                            dispatch(setTaskStatusId(parseInt(opt.props.value)));
                          }
                        }}>
                        <MenuItem key={1} value={UserTaskStatus.New}>
                          TODO
                        </MenuItem>
                        <MenuItem key={2} value={UserTaskStatus.Overdue}>
                          OVERDUE
                        </MenuItem>
                        <MenuItem key={3} value={UserTaskStatus.Completed}>
                          COMPLETED
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} ml={1}>
                    <Checkbox
                      checked={taskIsArchived}
                      onChange={(e: any, opt: any) => {
                        SetFilterLocally(assignedUserIds,taskStatusId,taskTypeId,opt,dealerLocationId);
                        dispatch(setTaskIsArchived(opt));

                      }}
                    />{' '}
                    <span>Archived Tasks</span>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={2}>
                    <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          id="cancelTaskFormBtn"
                          variant="contained"
                          onClick={() => {
                            handleResetFilter();
                          }}>
                          Reset Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TaskFilterDrawer;
