import { DealAttachment } from './../model/deal-edit-drawer';
import { get, put, post } from 'api';
import config from 'config';

import { DealDetails, PipelineStageList, ProductCondition } from '../model/deal-edit-drawer';

const dealDetailUrl = config.get('api.lead');

const DealDetailsAPI = {
  async getProductConditions(): Promise<ProductCondition[]> {
    return [
      { id: 0, name: 'None' },
      { id: 1, name: 'New' },
      { id: 2, name: 'Used' },
    ];
  },
  async getPipelinesStagesDD(pipelineId: number): Promise<PipelineStageList[]> {
    const url = `${dealDetailUrl}/lead/deal/pipelineStagesDD/${pipelineId}`;
    const { data } = await get(url);
    return data;
  },
  async getDealDetails(dealId: number): Promise<DealDetails> {
    const { data } = await get(`${dealDetailUrl}/lead/deal/${dealId}`);
    return data;
  },
  async updateDeal(dealerId: number, dealId: number, requestModel: DealDetails, removedDealAttachments: DealAttachment[]): Promise<string> {
    const { data } = await put(`${dealDetailUrl}/lead/deal/${dealId}/updateDeal/${dealerId}`, requestModel);
    if (removedDealAttachments?.length > 0) {
        await post(`${dealDetailUrl}/lead/deal/deleteDealAttachments/${dealId}`,
        removedDealAttachments
        ).then((res: any) => {
        }).catch((err) => {
          console.log(err)
        });
    }
    if (requestModel.dealAttachmentsToUpload?.length > 0) {
      await post(`${dealDetailUrl}/lead/deal/uploadAttachmentToDeal/${dealId}`, 
      requestModel.dealAttachmentsToUpload
      ).then((res: any) => {
      }).catch((err) => {
        console.log(err)
      });
  }
    return data;
  },
};

export default DealDetailsAPI;
