import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { loginUser } from '../authentication-slice';
import appLocalStorage from 'app/local-storage';
import { logoutUser } from 'components/authentication/components/authentication-slice';
export default function ValidateLogin({ token }: { token: string }) {
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.user);
    React.useEffect(() => {
        //alert('validateLogin-1');
        appLocalStorage.remove();
        dispatch(logoutUser());
        localStorage.clear();
        //alert('validateLogin-2');
        //alert(window.location.search);
        dispatch(loginUser({ dealerId: '0', username: '', password: '', token: token }))
            .then(response => {
                let url = new URL(window.location.href);
                url.searchParams.delete('token');
                let u = url.toString();
                window.location.href = u;
            })
            ;
    }, []);

    return (<div style={{ margin: "20px" }}><h2>Authorizing. Please Wait......</h2></div>)
}
