export class EventTypes {
  static readonly CustomerEvents = {
    CustomerCreated: 'CustomerCreated',
    CustomerUpdated: 'CustomerUpdated',
    CustomerDeleted: 'CustomerDeleted',
  };

  static readonly UserEvents = {
    UserCreated: 'UserCreated',
    UserUpdated: 'UserUpdated',
    UserDeleted: 'UserDeleted',
  };

  static readonly CustomerPhoneEvents = {
    ValidatePhones: 'ValidatePhones',
    PhoneValidated: 'PhoneValidated',
    CustomerPhoneCreated: 'CustomerPhoneCreated',
    CustomerPhoneUpdated: 'CustomerPhoneUpdated',
    CustomerPhoneDeleted: 'CustomerPhoneDeleted',
    MessageSent: 'MessageSent',
    MessageReceived: 'MessageReceived',
    MessageConfirmationReceived: 'MessageConfirmationReceived',
  };

  static readonly Messaging = {
    MessageSent: 'MessageSent',
    MessageReceived: 'MessageReceived',
  };

  static readonly NotificationEvents = {
    NotificationCreated: 'NotificationCreated',
    TaskNotification:'TaskNotification'
  };

}

export default EventTypes;
