import { makeStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  content: {
    height: '100vh',

  },
}));

export const PublishLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes: any = useStyles();
  return <main className={classes.content}>{children}</main>;
};

export default PublishLayout;
