import { Box, IconButton, Popover, Tooltip } from '@material-ui/core';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { Picker } from 'emoji-mart';
import React, { ReactElement } from 'react';

export function EmojiPickerButton({
  onSelect,
  disabled,
}: {
  onSelect(value: string): void;
  disabled?: boolean;
}): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOnSelect = (emoji: any): void => {
    setAnchorEl(null);
    onSelect(emoji.native);
  };
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box position="relative">
      <Tooltip title="Insert Emoji">
        <IconButton
          id="emojiBtn"
          aria-describedby={id}
          style={{ paddingLeft: 4 , paddingRight: 4 }}
          disabled={disabled}
          onClick={handleOnClick}>
          <InsertEmoticonIcon />
        </IconButton>
      </Tooltip>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Picker autoFocus title="Emoji" onSelect={handleOnSelect} />
      </Popover>
    </Box>
  );
}
export default EmojiPickerButton;
