import { Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { hideTaskDrawer } from '../taskboardSlice';
import AddTaskDrawer from './TaskAddDrawer';
import TaskEditDrawer from './TaskEditDrawer';
import TaskDoneDrawer from './TaskDoneDrawer';
import TaskRescheduleDrawer from './TaskRescheduleDrawer';
import TaskListRescheduleDrawer from './TaskListRescheduleDrawer';
import TaskFilterDrawer from './TaskFilterDrawer';
import TaskListDoneDrawer from './TaskListDoneDrawer';

const DRAWER_COMPONENTS: { [id: string]: React.FC } = {
  TASK_ADD: AddTaskDrawer,
  TASK_EDIT: TaskEditDrawer,
  TASK_RESCHEDULE: TaskRescheduleDrawer,
  TASKLIST_RESCHEDULE: TaskListRescheduleDrawer,
  TASK_DONE: TaskDoneDrawer,
  TASKLIST_DONE: TaskListDoneDrawer,
  TASK_FILTER: TaskFilterDrawer,
};

export function TaskRootDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  const { drawerType, drawerProps } = useAppSelector((state) => ({
    drawerType: state.tasks.drawerType,
    drawerProps: state.tasks.drawerProps,
  }));
  let open = true;
  const handleClose = () => dispatch(hideTaskDrawer());

  if (!drawerType) open = false;
  const SpecificDrawer: React.FC = DRAWER_COMPONENTS[drawerType || 'null'];
  return (
    <Drawer
      style={{ width: '450px' }}
      anchor="right"
      variant="temporary"
      open={open}
      PaperProps={{ style: { width: '450px' } }}
      onClose={handleClose}
      SlideProps={{ timeout: { enter: 300, exit: 300 } }}>
      {open && <SpecificDrawer {...drawerProps} />}
    </Drawer>
  );
}
export default TaskRootDrawer;
