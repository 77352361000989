import { Box, Button, Grid, Slider, Typography, useTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Rating, { IconContainerProps } from '@material-ui/lab/Rating';
import React, { ReactElement, useState } from 'react';

import useDebouncedCallback from 'common/hooks/useDebouncedCallback';

const useNPSButtonStyles = makeStyles((theme: Theme): any => ({
  root: {
    width: '100%',
  },
  button: {
    width: '100% !important',
    minWidth: 'inherit',
  },
}));

export function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  const theme = useTheme();
  const classes: any = useNPSButtonStyles();
  let backgroundColor = theme.palette.primary.main;
  let color = 'white';
  if (!other.className) return null;
  if (other.className?.indexOf('MuiRating-iconEmpty') > 0) {
    backgroundColor = theme.palette.primary.main;
  }
  if (other.className?.indexOf('MuiRating-iconFilled') > 0) {
    backgroundColor = green[900];
  }

  return (
    <span className={classes.root} {...other}>
      <Button
        id="opinionScaleBtn"
        variant="contained"
        style={{ background: backgroundColor, color }}
        size="small"
        className={classes.button}>
        {value - 1}
      </Button>
    </span>
  );
}

export const useNpsRatingStyles = makeStyles((theme: Theme): any => ({
  rating: {
    width: '100%',
    alignContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  label: {
    flex: '2 1 auto',
    padding: '4px',
  },
  'rating.MuiRating-root': {
    width: '100%',
  },
  slider: {
    marginTop: theme.spacing(2),
  },
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export function OpinionScaleField({ id, name, onChange, value, ...other }: any): ReactElement {
  const theme = useTheme();
  const classes: any = useNpsRatingStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const notMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [myValue, setMyValue] = useState(value);
  const handleRatingChange = (event: React.ChangeEvent<{}>, value: number | null): void => {
    const newValue = value === null ? undefined : value - 1;
    onChange(newValue, true);
  };
  const [debouncedcallback] = useDebouncedCallback((value) => {
    onChange(value, true);
  }, 400);
  const handleSliderRatingChange = (event: React.ChangeEvent<{}>, newValue: number | number[]): void => {
    const value = newValue === null ? undefined : newValue;
    setMyValue(value);
    debouncedcallback(value);
  };
  const LeftLabel = (
    <Typography style={{ fontWeight: 'bold' }} variant="caption">
      Very unlikely
    </Typography>
  );
  const rightLabel = (
    <Typography style={{ fontWeight: 'bold' }} variant="caption">
      Very likely
    </Typography>
  );
  const labels = (
    <Box mt={1}>
      <Grid container>
        <Grid item xs={6} style={{ textAlign: 'left', fontWeight: 'bold' }}>
          {LeftLabel}
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>
          {rightLabel}
        </Grid>
      </Grid>
    </Box>
  );
  const ratingDefaultValue = value !== undefined ? value + 1 : undefined;
  const sliderDefaultValue = myValue !== undefined ? myValue : 0;
  let ratingField = null;
  if (mobile) {
    ratingField = (
      <Slider
        className={classes.slider}
        key={id}
        value={sliderDefaultValue}
        valueLabelDisplay="on"
        step={1}
        onChange={handleSliderRatingChange}
        marks={marks}
        min={0}
        max={10}
      />
    );
  } else if (notMobile) {
    ratingField = (
      <Rating
        max={11}
        name={name}
        key={id}
        className={classes.rating}
        classes={{ label: classes.label }}
        defaultValue={ratingDefaultValue}
        IconContainerComponent={IconContainer}
        onChange={handleRatingChange}
      />
    );
  }
  return (
    <Box width="100%">
      {ratingField}
      {labels}
    </Box>
  );
}
