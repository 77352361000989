export function TriggerMessageCount(count){
  console.log('TriggerMessageCount: ' + count);
  window.chrome.webview.postMessage({Count: count});
}

export function GetAttachments(size, allowedTypes='*') {
  window.chrome.webview.postMessage({EVENT_TYPE: 'ATTACHMENT', SIZE_LIMIT: size, ALLOWED_TYPES:allowedTypes});
}

export function GetVideoStream(res) {
   window.chrome.webview.postMessage({EVENT_TYPE: 'CAMERA', url: res});
}

export function GetLink(src){
  window.chrome.webview.postMessage({EVENT_TYPE: 'URL', url: src});
}