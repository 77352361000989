import { AppBar, Box, Theme, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';

import TargetCRMLogo from 'common/components/icons/targetCRM';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme): any => ({
  content: {
    paddingTop: theme.navBar.height,
    height: `calc(100vh - ${theme.navBar.height})`,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    height: theme.navBar.height,
  },
}));

export const Public: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes: any = useStyles();
  const history = useHistory();
  return (
    <>
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar variant="dense">
          <TargetCRMLogo />
          <Box flexGrow={1} />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{children}</main>
    </>
  );
};

export default Public;

export const VideoCallLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes: any = useStyles();
  return (
    <>
      <main style={{ background: '#1F1F1F', height: '100vh', paddingTop: '0px !important' }}>{children}</main>
    </>
  );
};
