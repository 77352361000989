import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import useStyles from './deal-pipelines-styles';
import Stack from '@mui/material/Stack';
import { Department, PipeLine, PipeLineStage } from '../../models/deal-pipelines';
import AppSnackBar from 'components/snackbar/snackbar';
import { Autocomplete } from '@material-ui/lab';
import DepartmentAPI from 'components/setting/api/department-api';
import DealPipeLinesAPI from 'components/setting/api/deal-pipelines-api';
import { useAppSelector } from 'app';
import LostDealDrawer from './lost-deal-drawer';
import useLocations from 'common/hooks/useLocations';
import { LocationOption } from 'common/models/location-option';
import { ErrorMessages } from 'common/utils/shared/shared-messages';
import { checkStringIsEmpty } from 'common/utils/shared/shared-functions';
import { ManageLeadSource } from './manage-lead-source';

function useFocus(): any {
  const htmlRef = React.useRef<any>(undefined);
  function setFocus(): void {
    if (htmlRef !== undefined && htmlRef.current !== undefined) {
      htmlRef.current.focus();
    }
  }
  return [htmlRef, setFocus];
}

const DealPipeLines: React.FC = () => {
  const classes: any = useStyles();
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [departments, setDepartments] = React.useState<Department[] | undefined>();
  const [pipeLinesList, setNewPipeLineStage] = React.useState<PipeLine[]>([]);
  const [showLostDealDrawer, SetLostDealDrawer] = React.useState(false);
  const [showLeadSourceDrawer, SetLeadSourceDrawer] = React.useState(false);
  const [inputRef, setInputRef] = useFocus();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { loading: locationLoading, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);
  const dmsDealerIdLocation = 0;
  const AgingAlertThresholdDays:number[] =[1,2,3,7]
  const fetchDepartments = React.useCallback(async (): Promise<void> => {
    try {
      const response = await DepartmentAPI.getDepartments(dmsDealerIdLocation, dmsDealerIdCrm);
      setDepartments(response);
    } catch (error) {
      console.error(error);
    }
  }, [dmsDealerIdLocation, dmsDealerIdCrm]);

  const fetchPipelines = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await DealPipeLinesAPI.getPipelines(dmsDealerIdCrm);
      setNewPipeLineStage(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Error while fetching pipelines.' });
    }
  }, [dmsDealerIdCrm]);

  useEffect(() => {
    fetchDepartments();
    fetchPipelines();
  }, [fetchDepartments, fetchPipelines]);

  function handlePipeLineDepartmentsChange(values: Department[], index1: number): void {
    let pipeLines = [...pipeLinesList];
    const changedDepartmentIds = values.map((x) => x.departmentId);
    const removedPipeline = pipeLines[index1].pipelineDepartments?.find(
      (x) => !changedDepartmentIds.includes(x.departmentId),
    );
    if (removedPipeline && removedPipeline.containDeals) {
      return;
    }

    pipeLines[index1].updated = true;
    pipeLines[index1].pipelineDepartments = values;
    setNewPipeLineStage(pipeLines);
  }

  function handlePipeLineLocationsChange(values: LocationOption[], index1: number): void {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].updated = true;
    pipeLines[index1].pipelineLocations = values;
    setNewPipeLineStage(pipeLines);
  }

  function addPipeLine() {
    const newStage: PipeLineStage = {
      name: 'New Stage',
      position: 1,
      containDeals: false,
    };
    const newPipeline: PipeLine = {
      id: 0,
      name: 'New Pipeline',
      generateEmail: false,
      email: '',
      updated: false,
      containDeals: false,
      enableEditName: false,
      targetWebLeads: false,
      isAging: false,
      agingAlertThreshold: 0,
      pipelineDepartments: [],
      pipelineStages: [newStage],
      pipelineLocations: [],
    };

    let pipeLines = [...pipeLinesList];
    pipeLines.push(newPipeline);
    setNewPipeLineStage(pipeLines);

    setTimeout(() => {
      //Move scroll to bottom
      let pipelineBottom = document.getElementById('pipelineBottom') as HTMLElement;
      pipelineBottom.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function addPipeLineStage(index1: number) {
    let pipeLines = [...pipeLinesList];
    const pipelineStageLength = pipeLines[index1].pipelineStages.length;
    const newStage: PipeLineStage = {
      name: 'New Stage',
      position: pipelineStageLength + 1,
      containDeals: false,
    };

    if (pipeLines[index1] && pipelineStageLength >= 15) {
        showSnackBar({ type: 'error', message: ErrorMessages.PipeLineStageMaxLimit });
      return;
    }
    pipeLines[index1].updated = true;
    pipeLines[index1].pipelineStages.push(newStage);
    setNewPipeLineStage(pipeLines);
  }

  function updatePipeLineName(name: string, index1: number) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].updated = true;
    pipeLines[index1].name = name.trimStart();
    setNewPipeLineStage(pipeLines);
  }

  function updatePipeLineStageName(name: string, index1: number, index2: number) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].updated = true;
    pipeLines[index1].pipelineStages[index2].name = name;
    setNewPipeLineStage(pipeLines);
  }

  function setGeneratePipelineEmail(index1: number, value: boolean) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].updated = true;
    pipeLines[index1].generateEmail = value;
    setNewPipeLineStage(pipeLines);
  }

  function setAgingAlertToggle(index1: number, value: boolean) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].isAging = value;
    if(pipeLines[index1].isAging && pipeLines[index1].agingAlertThreshold == 0){
      pipeLines[index1].agingAlertThreshold = 1;
    }else{
      pipeLines[index1].agingAlertThreshold = 0;
      
    }
    pipeLines[index1].updated = true;
    setNewPipeLineStage(pipeLines);
  }

  function setEnableTargetWebLeadsToggle(index1: number, value: boolean) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].updated = true;
    pipeLines[index1].targetWebLeads = value;

    if (!value) pipeLines[index1].pipelineLocations = [];
    else if (value && locationOptions?.filter((x) => x.dealerLocationId != 0).length === 1) {
      pipeLines[index1].pipelineLocations = locationOptions?.filter((x) => x.dealerLocationId != 0);
    }
    setNewPipeLineStage(pipeLines);
  }

  function deletePipeLineStage(index1: number, index2: number, containDeals: boolean) {
    let pipeLines = [...pipeLinesList];
    if (pipeLines[index1].pipelineStages.length == 1) {
      showSnackBar({ type: 'error', message: 'Pipeline at least contains a stage.' });
      return;
    }

    if (containDeals) {
      showSnackBar({ type: 'error', message: 'This stage has associated deals.' });
      return;
    }

    pipeLines[index1].updated = true;
    pipeLines[index1].pipelineStages.splice(index2, 1);
    //Update stage positions
    pipeLines[index1].pipelineStages.forEach(function (item, index2) {
      pipeLines[index1].pipelineStages[index2].position = index2 + 1;
    });
    setNewPipeLineStage(pipeLines);
  }

  async function DeletePipeline(pipelineId: number, pipelineName?: string) {
    const dealsExist = pipeLinesList.find((x) => x.id === pipelineId)?.containDeals;
    Swal.fire({
      title: 'Are you sure?',
      text: dealsExist
        ? `Pipeline (${pipelineName}) contain deals, you still want to delete?`
        : `You want to delete this pipline (${pipelineName})`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await DealPipeLinesAPI.DeletePipeline(pipelineId);
        if (response) {
          Swal.fire('Deleted!', `Your Pipeline (${pipelineName}) has been deleted.`, 'success').then(() =>
            fetchPipelines(),
          );
        } else {
          Swal.fire('Some thing went wrong!', `Pipeline (${pipelineName}) not deleted.`, 'error');
        }
      }
    });
  }

  function setEnableEditPipelineName(index1: number, enableEdit: boolean) {
    let pipeLines = [...pipeLinesList];
    pipeLines[index1].enableEditName = enableEdit;
    setNewPipeLineStage(pipeLines);
  }

  async function handleAddUpdatePipeline(): Promise<void> {
    try {
       
      // Task 130179: Stage name field allows empty values.
      //Trim Spaces at the start and end of the pipeLine stage name
      setNewPipeLineStage(pipeLinesList.map(x=>
        { 
          x.pipelineStages.forEach(y=>y.name = y.name.trim())
           x.name = x.name.trim(); 
           return x;
        }));

      let pipeLines = [...pipeLinesList];
      if (!validatePipelines(pipeLines)) {
        return;
      }

      setLoading(true);
      pipeLines = pipeLines.filter((x) => x.id == 0 || x.updated); //Filter only new and updated pipelines
      const response = await DealPipeLinesAPI.AddUpdatePipelines(dmsDealerIdCrm, pipeLines);

      if (response) {
        fetchPipelines();
        showSnackBar({ type: 'success', message: 'Pipelines saved successfully.' });
      } else {
        showSnackBar({ type: 'error', message: 'Unable to save pipelines.' });
        throw new Error('Unable to save pipelines.');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Unable to save pipelines.' });
      throw new Error('Unable to save pipelines.');
    }
  }

  function validatePipelines(pipeLines: PipeLine[]) {
    let result:boolean = true;
   
    const indexWithEmptyName = pipeLines.findIndex((x) => !x.name.trim());
    if (indexWithEmptyName != -1) {
      showSnackBar({
        type: 'error',
        message: `Name field of pipeline no. ${indexWithEmptyName + 1} is empty.`,
      });
      return false;
    }

    const _pipelineName = pipeLines.find((x) => x.pipelineDepartments && x.pipelineDepartments?.length <= 0)?.name;
    if (_pipelineName != undefined) {
      showSnackBar({
        type: 'error',
        message: `Pipeline "${_pipelineName}" has no assigned department.`,
      });
      return false;
    }

    const pipelineName = pipeLines.find(
      (x) => x.targetWebLeads && x.pipelineLocations && x.pipelineLocations?.length <= 0,
    )?.name;
    if (pipelineName != undefined) {
      showSnackBar({
        type: 'error',
        message: `Pipeline "${pipelineName}" has no assigned location.`,
      });
      return false;
    }
    
    result = ValidatePipeLineStages(pipeLines)

    return result;
  }

  function ValidatePipeLineStages(pipeLines: PipeLine[]):boolean
  {
    const emptyStagePipeline = pipeLines.find(x=>x.pipelineStages.find((y)=> checkStringIsEmpty(y.name) == true))
    
    //validation passed successfully
    if( !emptyStagePipeline)
      return true

    const emptyStage = emptyStagePipeline.pipelineStages.find((y)=> checkStringIsEmpty(y.name) == true)
     
    showSnackBar({
      type: 'error',
      message: `Pipeline "${emptyStagePipeline.name}" has empty value in stage ${emptyStage?.position}.`,
    });

    //validation failed
    return false;
  }

  function LostDealDrawerClick() {
    SetLostDealDrawer(true);
  }

  function LeadSourceDrawerClick() {
    SetLeadSourceDrawer(true);
  }

  function CloseLostDealDrawer(value = 0) {
    SetLostDealDrawer(value == 0 ? false : true);
  }

  function CloseLeadSourceDrawer(value = 0) {
    SetLeadSourceDrawer(value == 0 ? false : true);
  }

  const DepartmentChip = (props: any) => {
    return <Chip {...props} disabled={props.containDeals} />;
  };

  return (
    <Paper square className={classes.dealPiplineWindow}>
      <Button variant="contained" color="primary" onClick={addPipeLine} disabled={loading}>
        Add Pipeline
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={LeadSourceDrawerClick}
        style={{ float: 'right', marginLeft: '5px' }}
        disabled={loading}>
        Lead Source Tag
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ float: 'right' }}
        onClick={LostDealDrawerClick}
        disabled={loading}>
        Manage Lost Reasons
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddUpdatePipeline}
        style={{ float: 'right', marginRight: '5px' }}
        disabled={loading}>
        Save
      </Button>
      <LostDealDrawer open={showLostDealDrawer} onClose={CloseLostDealDrawer} />
      <ManageLeadSource open={showLeadSourceDrawer} onClose={CloseLeadSourceDrawer} />
      {loading ? (
        <Box display="flex" justifyContent="center" marginTop={15}>
          <CircularProgress />
        </Box>
      ) : (
        pipeLinesList.map((pipeLine, index1) => (
          <Box key={index1} className={classes.pipleLineBox}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="h5" style={{ marginTop: '8px' }}>
                  {!pipeLine.enableEditName ? (
                    pipeLine.name
                  ) : (
                    <>
                      <TextField
                        required
                        autoFocus
                        error={pipeLine.name.trim().length === 0}
                        helperText={pipeLine.name.trim().length === 0 && 'Pipeline name required'}
                        name="piplineName"
                        size="small"
                        variant="outlined"
                        value={pipeLine.name}
                        onChange={(e: any) => {
                          updatePipeLineName(e.target.value, index1);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.key === 'Enter' && pipeLine.name.length > 0) {
                            setEnableEditPipelineName(index1, false);
                          }
                        }}
                        onBlur={() => {
                          if (pipeLine.name.length > 0) {
                            setEnableEditPipelineName(index1, false);
                          }
                        }}
                      />
                      <ClearIcon
                        className={classes.pipeLineClearIcon}
                        onClick={() => {
                          if (pipeLine.name.length > 0) {
                            setEnableEditPipelineName(index1, false);
                          }
                        }}
                      />
                    </>
                  )}
                  {!pipeLine.enableEditName && (
                    <EditIcon
                      className={classes.pipeLineEditIcon}
                      onClick={() => {
                        setEnableEditPipelineName(index1, true);
                      }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Autocomplete
                  multiple
                  openOnFocus
                  options={departments || []}
                  id="departments"
                  disableCloseOnSelect
                  groupBy={(option: Department): string => option.dealerLocationName}
                  onChange={(event: any, values: any): void => handlePipeLineDepartmentsChange(values, index1)}
                  getOptionLabel={(option: any): string => option.departmentName}
                  value={pipeLine.pipelineDepartments}
                  getOptionSelected={(option: Department, value: Department): boolean =>
                    option.departmentId === value.departmentId
                  }
                  renderOption={(option, { selected }): React.ReactElement => (
                    <FormControlLabel
                      disabled={
                        pipeLine.pipelineDepartments?.find((x) => x.departmentId === option.departmentId)?.containDeals
                      }
                      control={<Checkbox checked={selected} />}
                      label={option.departmentName}
                    />
                  )}
                  renderInput={(params): React.ReactElement => (
                    <TextField
                      {...params}
                      error={pipeLine.pipelineDepartments?.length === 0}
                      helperText={pipeLine.pipelineDepartments?.length === 0 && 'Atleast one department is required'}
                      inputRef={inputRef}
                      name="departments"
                      id="departments"
                      variant="outlined"
                      margin="dense"
                      label="Departments"
                      placeholder="Select Departments"
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                      <DepartmentChip
                        {...getTagProps({ index })}
                        departmentId={option.departmentId}
                        label={option.departmentName}
                        containDeals={option.containDeals}
                      />
                    ));
                  }}
                />
              </Grid>
              <Grid container item xs={4} spacing={2}>
                {pipeLine.email != '' && (
                  <Grid item xs={10} style={{ marginTop: 8 }}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="piplineEmail"
                      size="small"
                      variant="outlined"
                      value={pipeLine.email}
                      disabled
                    />
                  </Grid>
                )}
                {pipeLine.email == '' && (
                  <Grid item xs={10} style={{ marginTop: 8 }}>
                    <FormControlLabel
                      label="Generate Email"
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={pipeLine.generateEmail}
                          name="generateEmail"
                          color="primary"
                          onChange={(e: any) => {
                            setGeneratePipelineEmail(index1, e.target.checked);
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={2} style={{ marginTop: 8 }}>
                  <Fab
                    className={classes.deletePipelineBtn}
                    variant="round"
                    size="small"
                    onClick={() => DeletePipeline(pipeLine.id!, pipeLine.name)}>
                    <DeleteForeverIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <FormControlLabel
                  label="Target Web Leads"
                  labelPlacement="start"
                  style={{ marginLeft: 'unset' }}
                  control={
                    <Switch
                      checked={pipeLine.targetWebLeads}
                      name="targetWebLead"
                      color="primary"
                      onChange={(e: any) => {
                        setEnableTargetWebLeadsToggle(index1, e.target.checked);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="Aging Alert"
                  labelPlacement="start"
                  style={{ marginLeft: 'unset' }}
                  control={
                    <Switch
                      checked={pipeLine.isAging}
                      name="isAging"
                      color="primary"
                      onChange={(e: any) => {
                        setAgingAlertToggle(index1, e.target.checked);
                      }}
                    />
                  }
                />
                {pipeLine.isAging && (
                  <FormControl fullWidth size='small'>
                    <Select
                      fullWidth
                      variant="outlined"
                      name="agingAlertThreshold"
                      value={pipeLine.agingAlertThreshold}
                      onChange={(e: any) => {
                        if (!e.target.value) return;
                        let pipeLines = [...pipeLinesList];
                        pipeLines[index1].agingAlertThreshold = e.target.value;
                        pipeLines[index1].updated = true;
                        setNewPipeLineStage(pipeLines);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      >
                      {AgingAlertThresholdDays.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option == 1 ? `${option} Day` : `${option} Days`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={9}>
                {pipeLine.targetWebLeads && (
                  <Autocomplete
                    multiple
                    id="locations"
                    options={locationOptions?.filter((x) => x.dealerLocationId != 0) || []}
                    value={pipeLine.pipelineLocations}
                    onChange={(event: any, values: any): void => handlePipeLineLocationsChange(values, index1)}
                    getOptionLabel={(option: any) => option.name}
                    disableCloseOnSelect
                    getOptionSelected={(option: LocationOption, value: LocationOption): boolean => {
                      return option.dealerLocationId === value.dealerLocationId;
                    }}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox checked={selected} />
                        {option.name}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={pipeLine.targetWebLeads && pipeLine.pipelineLocations?.length === 0}
                        helperText={
                          pipeLine.targetWebLeads &&
                          pipeLine.pipelineLocations?.length === 0 &&
                          'Atleast one location is required'
                        }
                        name="locations"
                        id="locations"
                        variant="outlined"
                        label="Locations"
                        size="small"
                        placeholder="Select Locations"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem className={classes.stackDivider} />}>
              {pipeLine.pipelineStages.map((stage, index2) => (
                <Card key={index2} variant="outlined" className={classes.stageCard}>
                  <DeleteIcon
                    className={classes.stageCardDeleteIcon}
                    fontSize="small"
                    onClick={() => {
                      deletePipeLineStage(index1, index2, stage.containDeals);
                    }}
                  />
                  <TextField
                    hiddenLabel
                    variant="standard"
                    error={stage.name.trim().length === 0}
                    helperText={stage.name.trim().length === 0 && 'Required'}
                    multiline
                    rows={2}
                    size="small"
                    value={stage.name}
                    style={{ marginTop: '10px' }}
                    onChange={(e: any) => {
                       // Task 130179: Stage name field allows empty values.
                        //Spaces are not allowed at the start of the pipeLine stage name
                        updatePipeLineStageName(e.target.value.trimStart(), index1, index2);
                    }}
                  />
                </Card>
              ))}
              <Fab
                color="primary"
                aria-label="add"
                style={{ marginTop: '3em' }}
                onClick={() => {
                  addPipeLineStage(index1);
                }}>
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <AddIcon />
                  <Typography style={{ fontSize: '8px', marginTop: '-10px' }}>New</Typography>
                </Box>
              </Fab>
            </Stack>
          </Box>
        ))
      )}
      <Box id="pipelineBottom"></Box>
    </Paper>
  );
};

export default DealPipeLines;
