import { Box, Button, Checkbox, Container, Divider, Drawer, makeStyles, MenuItem, TextField, Toolbar, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import * as Yup from 'yup';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';
import DepartmentAPI from 'components/setting/api/department-api';
import Department from 'components/setting/models/department';
import User, { Role } from 'components/setting/models/user';
import AppSnackbar from 'components/snackbar/snackbar';
import UserAPI from 'components/setting/api/user-api';

interface DrawerRolePage {
  selectedRoleId: string | undefined;
}

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditUserRoleDrawer({
  roleId,
  open,
  onClose,
  onSave
}: {
  roleId: string | undefined;
  open: boolean;
  onClose(): void;
  onSave(value: any): void;
}): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dispatch = useAppDispatch();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const classes = useStyles();
 
  
  const [Roles, setRoles] = React.useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = React.useState<Role | undefined>(undefined);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  async function handleOnSave(): Promise<void> {
    if (onSave) {
      setSaving(true);
      await onSave(selectedRole);
      setSaving(false);
    }
  }

  let defaultDealerLocation = 0;
  // if (roles.length === 1) {
  //   defaultDealerLocation = roles[0].dealerLocationId;
  // }
  const initialValues: DrawerRolePage = {
    selectedRoleId: roleId
  };

  const validationSchema = Yup.object<any>().shape<any>({
    roles: Yup.array().min(1, 'Select atleast one role')
  });

  async function handleSubmit(values: any): Promise<void> {
    console.log(values);

    setSaving(true);
    await onSave(values);
    setSaving(false);
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });


  // function updateRole(){
  //   DealerAPI.

  // }

  function handleUserChange(values: any): void {
    console.log(values);
    formik.setValues({
        selectedRoleId: values
      },
      false,
    );
  }

  const fetchRoles = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await UserAPI.getRoles(dmsDealerIdCrm);
    setRoles(response);
    setLoading(false);
  }, [dmsDealerIdCrm, showSnackbar]);

  React.useEffect(() => {
    async function fetchSelectedRole(): Promise<void> {
      if (roleId !== undefined) {
        const response = await UserAPI.getRoleById(dmsDealerIdCrm, roleId);
        if (!response) throw new Error('Invalid response');
        setSelectedRole(response);
      } else {
        setSelectedRole(undefined);
      }
    }
    fetchSelectedRole();
  }, [roleId]);

  React.useEffect(() => {
    fetchRoles();
  },[fetchRoles, open]);

  return (
    <Drawer PaperProps={{ style: { width: '20%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>

      <Toolbar variant="dense">
        <Box flexGrow={1}>
          <Typography className={classes.title} variant="h4">
            Assign New Role
          </Typography>
        </Box>
        <Button variant="contained" size="small" onClick={() => { handleSubmit(formik.values.selectedRoleId) }} disabled={saving} color="primary">
          Save
        </Button>
      </Toolbar>
      <Divider />


      <Box mb={1} />
      <Container>
        {open && (
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              select
              margin="dense"
              variant="outlined"
              name="roleId"
              // disabled={editMode || Roles.length === 1}
              inputProps={{ id: 'roleId' }}
              label="Role"
              onChange={formik.handleChange('selectedRoleId')}
              value={formik.values.selectedRoleId}
              error={!!formik.errors.selectedRoleId}
              helperText={formik.errors.selectedRoleId}
              required>
              <MenuItem key={0} value={0}>
                Select Role
              </MenuItem>
              {Roles.map((role: Role) => (
                <MenuItem key={role.roleId} value={role.roleId}>
                  {role.roleName}
                </MenuItem>
              ))}
            </TextField>
          </form>
        )}
      </Container>
    </Drawer>
  );
}
