import { Box, Container, Divider, List, makeStyles, Paper, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import { TabPanel } from 'common/components/tabs';
import { Question } from 'components/survey/models';
import React, { ReactElement, useState } from 'react';
import { QuestionType } from '../../question-field';
import { NewQuestionBlock } from './new-question-block';
import { QuestionCategory } from './new-question-block/new-question-block';
import { QuestionBlock } from './question-block';

function QuestionPanelSubTitle({ title }: { title: string }): ReactElement {
  return (
    <Box my={1}>
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '65vh',
    height: '65vh',
    overflow: 'auto',
  },
}));

export default function SurveyQuestions({
  questions,
  disabled,
  onAdd,
  onDelete,
  onChange,
}: {
  questions: Question[];
  disabled: boolean;
  onAdd(type: QuestionType, category: QuestionCategory): Promise<void>;
  onDelete(question: Question): void;
  onChange(event: any): void;
}): ReactElement {
  const classes: any = useStyles();
  const [value, setValue] = useState(0);
  const tabLabels = [
    { index: 0, label: 'Nps question', category: QuestionCategory.Rating },
    { index: 1, label: 'Passive & Promoter Questions', category: QuestionCategory.PromotersAndPassive },
    { index: 2, label: 'Detractor Questions', category: QuestionCategory.Detractors },
  ];

  const selectedQuestions = questions.filter((x) => x.category === tabLabels[value].category.toString());

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };
  function handelAddQuestion(type: QuestionType): void {
    onAdd(type, tabLabels[value].category);
  }
  const tabs = tabLabels.map((tabLabel) => (
    <Tab id={`${tabLabel.label}Tab`} key={tabLabel.index} label={tabLabel.label} />
  ));

  return (
    <Box my={1}>
      <Paper elevation={2}>
        <Toolbar variant="dense">
          <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            {tabs}
          </Tabs>
        </Toolbar>
        <Divider />
        <Container className={classes.container}>
          <TabPanel value={value} index={0}>
            <QuestionPanelSubTitle title="Question for satisfaction scale" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <QuestionPanelSubTitle title="Question(s) displayed for rating between 7 and 10" />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <QuestionPanelSubTitle title="Question(s) displayed for rating between 0 and 6" />
          </TabPanel>
          <List>
            {selectedQuestions.map((question, idx: number) => (
              <QuestionBlock
                key={question.id}
                index={idx}
                question={question}
                onChange={onChange}
                disabled={disabled}
                onDelete={onDelete}
              />
            ))}
          </List>
          {tabLabels[value].category !== QuestionCategory.Rating && !disabled && (
            <NewQuestionBlock onAdd={handelAddQuestion} />
          )}
        </Container>
      </Paper>
    </Box>
  );
}
