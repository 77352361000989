import { Box, Container, Grid, makeStyles, MenuItem, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { pink, teal } from '@material-ui/core/colors';
import React, { ReactElement, useEffect } from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import { useRouter } from 'common/hooks';
import useLocations from 'common/hooks/useLocations';
import AppSnackbar from 'components/snackbar/snackbar';
import SurveyAPI, { SurveySummaryResponseObject } from 'components/survey/api/survey-api';

import CustomerDetailsDrawer from './customer-detail-drawer';
import { SurveyFeedback } from './survey-feedback';

function DataCard({
  title,
  backgroundColor,
  loading,
  value,
  postFix,
}: {
  title: string;
  loading: boolean;
  backgroundColor: string;
  value?: number;
  postFix?: string;
}): ReactElement {
  return (
    <Paper style={{ backgroundColor }}>
      <Box p={2}>
        <Typography variant="overline" component="h3" gutterBottom>
          {title}
        </Typography>
        <Box>
          <Typography variant="h3">
            {loading ? <SmallCircularProgress /> : `${value === undefined ? 'N/A' : value}${postFix}`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  container: {
    marginBottom: theme.spacing(3),
  },
}));

export function SurveySummary(): ReactElement {
  const classes: any = useStyles();
  const router = useRouter();
  const { query }: any = router;
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState<undefined | SurveySummaryResponseObject>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { locationOptions, defaultLocationOption, getLocationName } = useLocations(dmsDealerIdCrm);
  const [selectedCrmCustomerId, setSelectCrmCustomerId] = React.useState<number | undefined>(undefined);
  const [dealerLocationId, setDealerLocationId] = React.useState<number | undefined>(
    defaultLocationOption?.dealerLocationId,
  );

  React.useEffect(() => {
    setDealerLocationId(defaultLocationOption?.dealerLocationId);
  }, [defaultLocationOption]);

  const fetchSurveyFeedbackSummary = React.useCallback(async (): Promise<void> => {
    try {
      const surveyId = parseInt(query.id, 10);
      setLoading(true);
      const response = await SurveyAPI.getFeedbackSummary(surveyId, dealerLocationId);
      setSummary(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Unable to survey feedback summary' });
    }
  }, [dealerLocationId, query.id, showSnackBar]);

  useEffect(() => {
    if (dealerLocationId !== undefined && dealerLocationId >= 0) {
      fetchSurveyFeedbackSummary();
    }
  }, [dealerLocationId, fetchSurveyFeedbackSummary]);

  function handleCustomerDrawerClose(isUpdated: boolean): void {
    if (isUpdated) {
      fetchSurveyFeedbackSummary();
    }
    setSelectCrmCustomerId(undefined);
  }

  const noResponse = summary && summary?.responseCount === 0;

  const Content = (
    <Box pb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DataCard
                loading={loading}
                title="Loyalty Score"
                postFix=""
                value={summary?.loyaltyScore}
                backgroundColor="white"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DataCard
                loading={loading}
                title="Total Responses"
                postFix=""
                value={summary?.responseCount}
                backgroundColor="white"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <DataCard
                loading={loading}
                title="Total Survey Sent"
                postFix=""
                value={summary?.totalSurveySent}
                backgroundColor="white"
              />
            </Grid> 
            <Grid item xs={12} md={6}>
              <DataCard
                loading={loading}
                title="Promoters"
                postFix="%"
                value={summary?.promotersPercentage}
                backgroundColor={teal[200]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataCard
                loading={loading}
                title="Detractors"
                postFix="%"
                value={summary?.detractorsPercentage}
                backgroundColor={pink[300]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SurveyFeedback
        loading={loading}
        feedback={summary?.feedback}
        onCustomerClick={(crmCustomerId: number): void => setSelectCrmCustomerId(crmCustomerId)}
        locationFilter={dealerLocationId}
        getLocationName={getLocationName}
      />
    </Box>
  );
  const NoResponse = (
    <Box textAlign="center">
      <Typography variant="subtitle1">No responses yet</Typography>
    </Box>
  );
  const Loading = (
    <Box textAlign="center">
      <SmallCircularProgress />
    </Box>
  );
  let renderItem = Content;
  if (noResponse) {
    renderItem = NoResponse;
  }
  if (summary === undefined) {
    renderItem = <div />;
  }
  if (loading) {
    renderItem = Loading;
  }
  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Box mb={2} display="flex">
          <Box flexGrow={1}>
            <Typography variant="h3">Results</Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              select
              size="small"
              margin="dense"
              variant="outlined"
              name="location"
              label="Location"
              value={dealerLocationId}
              inputProps={{ id: 'location' }}
              onChange={({ target: { value } }): void => setDealerLocationId(parseInt(value, 10))}>
              {locationOptions &&
                locationOptions.map((loc) => (
                  <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                    {loc.name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
        </Box>
        {renderItem}
        <CustomerDetailsDrawer
          open={!!selectedCrmCustomerId}
          onClose={handleCustomerDrawerClose}
          crmCustomerId={selectedCrmCustomerId}
        />
      </Container>
    </Box>
  );
}

export default SurveySummary;
