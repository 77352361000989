import { Box, Card, CardActions, CardContent, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoadingButton } from 'common/components/buttons/LoadingButton';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import useStyles from './login-form-style';

export interface LoginFormValues {
  dealerId: string;
  username: string;
  password: string;
  token: string;
}

const Schema = Yup.object<any>().shape<any>({
   //dealerId: Yup.number().max(9999999999, 'dealer Number can only be upto 10 characters'),
  username: Yup.string().max(50, 'Username can only be upto 50 characters').required('Username is required'),
  password: Yup.string().max(100, 'Password can only be upto 100 characters').required('Password is required'),
});

type Props = {
  onSubmit: (values: LoginFormValues) => void;
  disabled: boolean;
};

const LoginForm: React.FC<Props> = ({ onSubmit, disabled }) => {
  const formik = useFormik({
    initialValues: {
      dealerId: '' ,
      username: '',
      password: '',
      token: '',
    },
    onSubmit,
    validationSchema: Schema,
  });

  const classes: any = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordChange = () => setShowPassword(!showPassword);
  const [rememberMe, setRememberMe]= useState<boolean>(false);

  
  const handleCheckboxChange = (isCheck: boolean) => {
    if(isCheck === true){
      if(formik.values.dealerId.length > 0){
        window.localStorage.setItem('X_Login_RememberMe_info_X', JSON.stringify({isCheck:isCheck, dealerNumber:formik.values.dealerId}));
        setRememberMe(true);
      }else{
        window.localStorage.removeItem('X_Login_RememberMe_info_X');
        setRememberMe(false);
      }
    }
    else if(!isCheck){
      window.localStorage.removeItem('X_Login_RememberMe_info_X');
      setRememberMe(false);
    }
  };

  useEffect(() => {
    const loginInfo = window.localStorage.getItem('X_Login_RememberMe_info_X');
    if (loginInfo != null) {
      const values = JSON.parse(loginInfo);
      if(values.isCheck){
        setRememberMe(values.isCheck);
        formik.setFieldValue('dealerId',values.dealerNumber);
      }
    }
  }, []);

  useEffect(()=>{
    if(rememberMe){
      handleCheckboxChange(true);
    }
  },[formik.values.dealerId])

  // const handleKeyDown = (
  //   event: React.KeyboardEvent<HTMLDivElement>,
  // ) => {
  //   if (/^[0-9\b]*$/.test(event.key)  || event.keyCode === 8 ||  event.keyCode === 13 )
  //   {
  //   }
  //   else
  //   {
  //     event.preventDefault();
  //   }
  // };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Card variant="outlined">
        <CardContent>
        <TextField
  fullWidth
  autoFocus
  margin="dense"
  variant="outlined"
  type="text"
  name="dealerId"
  id="dealerId"
  label="Dealer Number"
  required
  onChange={formik.handleChange}
  value={formik.values.dealerId}
  error={!!formik.errors.dealerId}
  helperText={formik.errors.dealerId}
   inputProps={{
     inputMode: 'numeric',     // Use numeric input mode to restrict to numbers
     pattern: '[0-9]*',        // Use pattern to enforce numeric input
     onKeyPress: (event) => {  // Restrict non-numeric characters manually
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);
      if (!/^\d+$/.test(keyValue)) {
        event.preventDefault();
       }
     },
  }}
  //onKeyDown={(e) => handleKeyDown(e)}
  
/>


          <TextField
            fullWidth
            type="text"
            variant="outlined"
            margin="dense"
            name="username"
            id="username"
            label="Username"
            required
            onChange={formik.handleChange}
            value={formik.values.username}
            error={!!(formik.touched.username && formik.errors.username)}
            helperText={formik.touched.username ? formik.errors.username : ''}
          />
          <TextField
            fullWidth
            variant="outlined"
            name="password"
            margin="dense"
            id="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            onChange={formik.handleChange}
            value={formik.values.password}
            error={!!(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password ? formik.errors.password : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton id="passwordVisibilityBtn" onClick={handleShowPasswordChange}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            style={{ display: 'flex' }}
            label="Remember dealer number"
            control={<Checkbox
              size='medium'
              color='primary'

              checked={rememberMe} 
              onChange={(e) => {handleCheckboxChange(e.target.checked)}} />}
          />
          <Box>
            <LoadingButton
              fullWidth
              id="login_btn"
              color="primary"
              disabled={disabled}
              variant="contained"
              size="medium"
              type="submit"
              onClick={formik.handleSubmit}>
              Login
            </LoadingButton>
          </Box>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </form>
  );
};

export default LoginForm;
