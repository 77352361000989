import React from 'react';
import Avatar, { ReactAvatarProps } from 'react-avatar';

function InitialsAvatar(props: ReactAvatarProps): React.ReactElement {
  const { name } = props;
  let initialValue = name;
  if (name) {
    if (/^(?=.*[a-zA-Z])[a-zA-Z\d\s_\W]+$/g.test(name)) {
      if (name.includes('(') || name.includes(')') || name.includes('&') || name.includes('-') || name.includes('"')  ){
        initialValue = name.replaceAll('(','').replaceAll(')','').replaceAll('&','').replaceAll('-','').replaceAll('"','');
      }

    } else if(/^[\d+()><#!*_.:;'"?`~*,\[\]{}|\\& -]*\d[\d+()><#!*_.:;'"?`~*,\[\]{}|\\& -]*$/.test(name)) {
      initialValue = '#';
    }
  }
  return <Avatar {...props} name={initialValue} />;
}

export default InitialsAvatar;
