import { Box, Button, Grid, IconButton, Popover, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import BusinessIcon from '@mui/icons-material/Business';
import { useStyles } from './CustomerForm';
import TextLimitField from 'common/components/input-fields/TextLimitField';

export function CompanyName({
  formik,
  formikErrors,
  editMode,
}: {
  formik: any;
  formikErrors: any;
  editMode: boolean;
}): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes: any = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item style={{ marginRight: '5px', marginTop: '-2px' }}>
        <Typography variant="body1">
          {' '}
          <BusinessIcon />{' '}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={formik.values.organizationName}>
          <Typography
            variant="body1"
            style={{ maxWidth: 260 , wordWrap :"break-word" }}>
            {formik.values.organizationName ? formik.values.organizationName : 'Organization:'}
            &nbsp;
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      {!!formikErrors.organizationName && <span className={classes.errorMsg}>{formikErrors.organizationName}</span>}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          <TextLimitField
            fullWidth
            characters={50}
            variant="outlined"
            autoFocus
            margin="dense"
            id="organizationName"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            name="organizationName"
            label={'Organization Name'}
            value={formik.values.organizationName}
            onChange={(e: any) => {
              formik.setFieldValue('organizationName', e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerAddressBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
}
