import { Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { useStyles } from './CustomerForm';

export function SurveyRating({
  surveyRating, surveyRatingDate,
}: {
  surveyRating?: number | null;
  surveyRatingDate?: Date | null;
}): ReactElement {
  let text = '';
  let timeStamp = '';
  if (surveyRating) {
    text += surveyRating.toString();
  }
  if (surveyRatingDate) {
    let dateTime = moment.utc(surveyRatingDate).local();
    if (dateTime !== null) {
      if (moment().local().isSame(dateTime, 'day')) {
        timeStamp = dateTime.format('h:mm a');
      } else {
        timeStamp = dateTime.format('M/D');
      }
    }
    text += ' ' + timeStamp;
  }

  if (!text) {
    text = '';
  }

  const classes: any = useStyles();
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item style={{ marginRight: '5px' }}>
        {' '}
        <FontAwesomeIcon icon={faStar} />{' '}
      </Grid>
      <Grid item>
        <Typography style={{ marginTop: '0px' }} variant="body1">
          Last Survey Rating <Chip className={classes.smallChip} label={text} color="success" variant="filled" />
        </Typography>
      </Grid>
    </Grid>
  );
}
