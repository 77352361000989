import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TabPanel } from 'common/components/tabs';
import AngryIcon from 'common/components/icons/angry';
import SmileIcon from 'common/components/icons/smile';
import NeutralIcon from 'common/components/icons/neutral';
import { Question } from 'components/survey/models';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { $enum } from 'ts-enum-util';
import { QuestionType, questionTypes } from '../../../question-field';
import { useAppSelector } from 'app';

const useFeedbackResponseListStyles = makeStyles((theme: Theme): any => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '350px',
    minHeight: '350px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '30%',
    maxWidth: '30%',
    minWidth: '30%',
  },
  tab: {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    maxWidth: 'none',
  },
  tabpanel: {
    height: '350px',
    minHeight: '350px',
    maxHeight: '350px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

function QuestionFeedbackDisplayBlock({
  index,
  question,
  answer,
}: {
  index: number;
  question: Question | undefined;
  answer: any;
}): ReactElement | null {
  if (!question) return null;
  const questionType = $enum(QuestionType).getKeyOrThrow(question.type);
  const ButtonIcon = questionTypes[questionType].icon;
  let formattedAnswer = answer;
  if (question.type === 'date') {
    formattedAnswer = moment(formattedAnswer).format('MM-DD-YYYY');
  }
  return (
    <Box my={1}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Button id="survey_question" size="small" variant="text" color="primary" startIcon={<ButtonIcon />}>
            {index + 1}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5" color="textSecondary">
            {question.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Box m={1}>
            <Typography variant="subtitle1" component="span">
              {formattedAnswer}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function CustomerReactionIcon({ type }: { type: string }): ReactElement | null {
  switch (type) {
    case 'Promoter':
      return <SmileIcon />;
    case 'Detractor':
      return <AngryIcon />;
    case 'Passive':
      return <NeutralIcon />;
    default:
      return null;
  }
}

export default function SurveyFeedback({
  loading,
  feedback,
  locationFilter,
  getLocationName,
  onCustomerClick,
}: {
  onCustomerClick(crmCustomerId: number): void;
  loading: boolean;
  feedback?: any;
  locationFilter?: number;
  getLocationName(dealerLocationId: number): string | undefined;
}): ReactElement {
  const classes: any = useFeedbackResponseListStyles();
  const [verticalTabIndex, setVerticalTabIndex] = useState<number>(0);
  const [customerNpsGroupFilter, setCustomerNpsGroupFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  let isCustomerPermission = 0;
  if (!userPermissions) {
    userPermissions = [];
    isCustomerPermission = 2;
  }
 if (isCustomerPermission == 0) {
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
    isCustomerPermission = 1;
 } 
}
  const handleVerticalTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setVerticalTabIndex(newValue);
  };

  if (loading) {
    return (
      <Box style={{ margin: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }
  const feedbackFilteredList = feedback
    ?.filter((f: any): boolean => {
      if (customerNpsGroupFilter === 'all') return true;
      if (f.customerNpsGroup === customerNpsGroupFilter) return true;
      return false;
    })
    .filter((f: any): boolean => {
      if (timeFilter === 'all') return true;
      const feedbackDate = moment(f.submissionTime);
      const currentDate = moment();
      const days = currentDate.diff(feedbackDate, 'days', true);
      switch (timeFilter) {
        case '1':
          if (days < 1) {
            return true;
          }
          break;
        case '2':
          if (days <= 2) {
            return true;
          }
          break;
        case '7':
          if (days <= 7) {
            return true;
          }
          break;
        case '15':
          if (days <= 15) {
            return true;
          }
          break;
        case '30':
          if (days <= 30) {
            return true;
          }
          break;
        default:
          return false;
      }
      return false;
    })
    .filter((f: any): boolean => {
      if (locationFilter !== 0) {
        if (f.dealerLocationId === locationFilter) {
          return true;
        }
        return false;
      }
      return true;
    });

  return (
    <Box mt={2}>
      <Paper>
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item xs style={{ textAlign: 'left', margin: 'auto 0' }}>
              <Typography variant="subtitle1">All Responses</Typography>
            </Grid>
            <Grid item xs="auto" style={{ textAlign: 'right' }}>
              <Select
                value={customerNpsGroupFilter}
                onChange={(e: any): void => setCustomerNpsGroupFilter(e.target.value)}
                margin="dense"
                variant="outlined">
                <MenuItem dense value="all">
                  <Typography variant="body2">All Responses</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="Promoter">
                  <Typography variant="body2">Promoter Responses</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="Passive">
                  <Typography variant="body2">Passive Responses</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="Detractor">
                  <Typography variant="body2">Detractor Responses</Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs="auto" style={{ textAlign: 'right' }}>
              <Select
                value={timeFilter}
                onChange={(e: any): void => setTimeFilter(e.target.value)}
                margin="dense"
                variant="outlined">
                <MenuItem dense value="all">
                  <Typography variant="body2">All Time</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="1">
                  <Typography variant="body2">Today</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="2">
                  <Typography variant="body2">Since Yesterday</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="7">
                  <Typography variant="body2">Past 7 days</Typography>
                </MenuItem>
                <Divider />
                <MenuItem dense value="30">
                  <Typography variant="body2">Past 30 days</Typography>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <Box className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={verticalTabIndex}
            className={classes.tabs}
            onChange={handleVerticalTabChange}>
            {feedbackFilteredList &&
              feedbackFilteredList.map((f: any, idx: number) => {
                const tabLabel = (
                  <ListItem dense disableGutters>
                    <ListItemAvatar>
                      <Avatar style={{ background: 'white' }}>
                        <CustomerReactionIcon type={f.customerNpsGroup} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
// eslint-disable-next-line jsx-a11y/anchor-is-valid
primary={
                        isCustomerPermission == 1 ? 
                          <Link
                          href="#"
                          onClick={(e: any): void => {
                            e.preventDefault();
                            onCustomerClick(f.customerId);
                          }}>
                          {f.customerName}
                        </Link>
                        : f.customerName
                      }
                      secondaryTypographyProps={{ variant: 'caption' }}
                      secondary={
                        <>
                          <Box>
                            <Typography variant="caption">
                              {moment(moment.utc(f.submissionTime)).local().format('M-DD-YYYY h:mm a')}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="overline" style={{ wordBreak: 'break-all' }}>
                              {getLocationName(f.dealerLocationId)}
                            </Typography>
                          </Box>
                        </>
                      }
                    />
                  </ListItem>
                );
                return <Tab key={f.id} className={classes.tab} label={tabLabel} />;
              })}
          </Tabs>
          {feedbackFilteredList &&
            feedbackFilteredList.map((feedbackItem: any, idx: number) => (
              <TabPanel key={feedbackItem.id} value={verticalTabIndex} index={idx}>
                <PerfectScrollbar>
                  <Box className={classes.tabpanel}>
                    {feedbackItem.responses.map((response: any, index: number) => (
                      <QuestionFeedbackDisplayBlock
                        key={index}
                        index={index}
                        question={response.question}
                        answer={response.answer}
                      />
                    ))}
                  </Box>
                </PerfectScrollbar>
              </TabPanel>
            ))}
        </Box>
      </Paper>
    </Box>
  );
}
