import { get, post } from 'api';
import config from 'config';

import { PipeLine } from '../models/deal-pipelines';

const dealPipelineUrl = config.get('api.lead');

const DealPipeLinesAPI = {
  async getPipelines(dmsDealerIdCrm: number): Promise<PipeLine[]> {
    const url = `${dealPipelineUrl}/lead/deal/pipelines/${dmsDealerIdCrm}`;
    const { data } = await get(url);
    return data;
  },
  async AddUpdatePipelines(dmsDealerIdCrm: number, pipelines: PipeLine[]): Promise<boolean> {
    const url = `${dealPipelineUrl}/lead/deal/pipeline/addupdate/${dmsDealerIdCrm}`;
    const { data } = await post(url, pipelines);
    return data;
  },
  async DeletePipeline(pipelineId: number) {
    const url = `${dealPipelineUrl}/lead/deal/pipeline/deletePipeline/${pipelineId}`;
    const { data } = await post(url, pipelineId);
    return data;
  },
};

export default DealPipeLinesAPI;
