import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import React, { useRef, useState } from 'react';
import { Box, makeStyles,Button, Container, Drawer, Grid, MenuItem, TextField, Paper, Typography, Theme } from '@material-ui/core';
import ConversationPanel, { ConversationPanelRef } from "./conversation-panel/conversation-panel";
import { Conversation } from "../models/conversation";
import { fetchSelectedCustomer } from "components/customer/components/Customers/customersSlice";
import { useAppDispatch } from "app";
import { ArchiveRequest } from "../models/request/archiveRequest";

const useArchiveConversationPanelStyles = makeStyles((theme: Theme): any => ({
   
    root: {
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      height: '80vh',
      minHeight: '80vh',
    },
    
  }));

export default function ArchiveConversationPanelDrawer({conversation,  open, onClose }: 
    { conversation:Conversation,  open: boolean, onClose(): void }
): React.ReactElement {
    const classes: any = useArchiveConversationPanelStyles();
    const childRef = useRef<ConversationPanelRef>(null);
    const dispatch = useAppDispatch();

    function handleLatestMessageReceived(conversation: Conversation): void {
      
      }

      async function handleMarkConversationRead(conversation: Conversation): Promise<void> {
        
      }

      function handleMarkSingleConversationUnRead(conversationId: number): void {
        
      }

      function handleAssignSingleConversation(conversationId: number): void {  
       
      }

      function handleMarkSingleConversationArchive(archiveRequest:ArchiveRequest): void {
      
      }
      const onMessageSentUpdateConversations = async (conversationId: number) => {
        
      }

    return (
        <Paper>
            <Drawer PaperProps={{ style: { width: "auto", minWidth: "850px" } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
            <DrawerToolbar title="Archive Conversation" onClose={onClose} />
                {open && (
                    <Box className={classes.root}>
                         <ConversationPanel
                            ref={childRef}
                            latestMessageSid={conversation?.latestMessageSid??""}
                            key={conversation?.id}
                            conversation={conversation}
                            openCustomerEditDrawer={(selectedCustomerId: number) => {
                            dispatch(fetchSelectedCustomer(selectedCustomerId));
                            }}
                            onLatestMessageReceived={handleLatestMessageReceived}
                            markConversationRead={handleMarkConversationRead}
                            markConversationUnRead={handleMarkSingleConversationUnRead}
                            assignConversation={handleAssignSingleConversation}
                            markConversationArchive={handleMarkSingleConversationArchive}
                            onMessageSentUpdateConversations={onMessageSentUpdateConversations}
                            onMessageRecievedUpdateConversations={() => {}}
                        />
                    </Box>
                )}
            </Drawer>
        </Paper>
    );

}