import { Box, Container, Grid, TextField } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { hideTaskDrawer, rescheduleTask } from '../taskboardSlice';
import TaskForm from './TaskForm';
import { Task, TaskListObject } from 'components/lead/models/task';
import TaskboardAPI from '../taskboard-api';
import moment from 'moment';

function TaskRescheduleDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  const handleTaskFormSubmit = (task: TaskListObject): void => {
    const date = moment.utc(task.dueDate).format().split('T')[0];
    task.dueDate = moment.utc(new Date(date + 'T' + task.dueTime)).format();
    dispatch(rescheduleTask(task));
  };
  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };

  //const [selectedTask, setSelectedTask] = useState<TaskListObject | undefined>(undefined);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const selectedTask = useAppSelector((state) => state.tasks.selectedTask);

  // const getTask = async () => {
  //   if (selectedTaskId) {
  //     let task = await TaskboardAPI.getTask(dealerId, selectedTaskId);
  //     setSelectedTask(task);
  //   }
  // }

  return (
    <>
      <DrawerToolbar title={'Edit Task: ' + selectedTask?.taskSubject} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <TaskForm
              task={selectedTask}
              disabled={false}
              btnDisabled={false}
              onFormSubmit={handleTaskFormSubmit}
              onClose={handleOnClose}
              onMarkAlreadyCompleted={() => {}}
              validate={true}
              reschedule={true}
              done={null}
              edit={false}
              hideButtons={false}
              previousCustomer={null}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TaskRescheduleDrawer;
