import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { SmallCircularProgress } from 'common/components/loaders';

import { MessageTemplate } from 'components/broadcast/models/message-template';
import DepartmentFilterContext from 'components/layouts/DashboardLayout/department-filter-context';
import AppSnackbar from 'components/snackbar/snackbar';
import React from 'react';
import { useAppSelector } from 'app';

import useDepartments from '../department/useDepartments';
import EditTaskTypeDrawer from './edit-task-type-drawer';
import NewTaskTypeDrawer from './new-task-type-drawer';

import EditTaskOutcomeDrawer from './edit-task-outcome-drawer';
import NewTaskOutcomeDrawer from './new-task-outcome-drawer';

import useStyles from './task-type-styles';
import { TaskType, TaskOutcome } from 'components/lead/models/task';
import taskboardAPI from 'components/lead/components/task-board/taskboard-api';

const TaskboardSetting: React.FC = () => {
  const classes: any = useStyles();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [taskTypes, setTaskTypes] = React.useState<TaskType[]>([]);
  const [selectedTaskType, setSelectedTaskType] = React.useState<TaskType | null>(null);
  const [taskOutcomes, setTaskOutcomes] = React.useState<TaskOutcome[]>([]);
  const [selectedTaskOutcome, setSelectedTaskOutcome] = React.useState<TaskOutcome | null>(null);


  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);
  const [showCreateTaskOutcomeDrawer, setShowCreateTaskOutcomeDrawer] = React.useState(false);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const departments = useDepartments(dmsDealerIdCrm, 0);

  const fetchTaskTypes = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await taskboardAPI.getTaskTypes();
      setTaskTypes(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar({ type: 'error', message: 'Unable to fetch quick messages' });
    }
  }, [showSnackbar]);

  React.useEffect(() => {
    fetchTaskTypes();
  }, [ fetchTaskTypes]);

  const fetchTaskOutcomes = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await taskboardAPI.getTaskOutcomes();
      setTaskOutcomes(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar({ type: 'error', message: 'Unable to fetch quick messages' });
    }
  }, [showSnackbar]);

  React.useEffect(() => {
    fetchTaskOutcomes();
  }, [fetchTaskOutcomes]);


  function openAddTaskType(event: any): void {
    setShowCreateDrawer(true);
  }

  function openAddTaskOutcome(event: any): void {
    setShowCreateTaskOutcomeDrawer(true);
  }

  async function handleTaskTypeCreate(value: any, createMore: boolean): Promise<void> {
    try {
      const response = await taskboardAPI.createTaskType(value);
      // if (!response.) throw new Error('Unable to quick message');
      showSnackbar({ type: 'success', message: 'Task type created' });
      if (!createMore) {
        setShowCreateDrawer(false);
      }
      fetchTaskTypes();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to create task type' });
      throw new Error('Unable to create task type');
    }
  }
  async function handleTaskOutcomeCreate(value: any, createMore: boolean): Promise<void> {
    try {
      const response = await taskboardAPI.createTaskOutcome(value);
      // if (!response.) throw new Error('Unable to quick message');
      showSnackbar({ type: 'success', message: 'Task outcome created' });
      if (!createMore) {
        setShowCreateDrawer(false);
      }
      fetchTaskOutcomes();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to create task outcome' });
      throw new Error('Unable to create task outcome');
    }
  }

  async function handleTaskTypeSave(value: TaskType): Promise<void> {
    try {
      await taskboardAPI.updateTaskType(value);
      showSnackbar({ type: 'success', message: 'Task type updated' });
      setSelectedTaskType(null);
      fetchTaskTypes();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to save quick message' });
    }
  }

  async function handleTaskOutcomeSave(value: TaskType): Promise<void> {
    try {
      await taskboardAPI.updateTaskOutcome(value);
      showSnackbar({ type: 'success', message: 'Task type updated' });
      setSelectedTaskOutcome(null);
      fetchTaskOutcomes();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to save quick message' });
    }
  }

  function onEditTaskType(taskType: TaskType): void {
    taskType.taskCategoryId = taskType.taskCategory.id;
    setSelectedTaskType(taskType);
  }

  function onEditTaskOutcome(taskOutcome: TaskOutcome): void {
    setSelectedTaskOutcome(taskOutcome);
  }

  async function deleteTaskType(id: number): Promise<void> {
    try {
      await taskboardAPI.deleteTaskType(id);
      showSnackbar({ type: 'success', message: 'Task type deleted' });
      fetchTaskTypes();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'unable to delete quick message' });
    }
  }

 
  return (
    <Paper square style={{ padding: '10px' }}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Box mb={1} display="flex">
            <Box>
              <Button onClick={openAddTaskType} startIcon={<AddCircleIcon />} color="primary">
                Add Task Type
              </Button>
            </Box>
            <Box flexGrow={1} />
          </Box>
          <Box>
            {loading ? (
              <Box height="500px" display="flex" justifyContent="center">
                <SmallCircularProgress />
              </Box>
            ) : (
              <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
                <Table stickyHeader id="quick-messages-table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ height: '100%', overflowY: 'auto' }}>Title &nbsp;&nbsp;</TableCell>
                      <TableCell style={{ height: '100%', overflowY: 'auto' }}>Category &nbsp;&nbsp;</TableCell>
                      <TableCell style={{ height: '100%', overflowY: 'auto' }}>Department</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskTypes.map((taskType) => (
                      <TableRow key={taskType.id}>
                        <TableCell
                          className={classes.quickMessage}
                          onClick={(): void => onEditTaskType(taskType)}>
                          {taskType.value}
                        </TableCell>
                        <TableCell>
                          {taskType.taskCategory.value}
                        </TableCell>
                        <TableCell>{taskType.departments.map((x) => (x.departmentName)).join(', ')}</TableCell>
                        {/* <TableCell>
                          <Tooltip title="Delete quick message">
                            <IconButton onClick={(): Promise<void> => deleteTaskType(taskType.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <NewTaskTypeDrawer
            departments={departments || []}
            open={showCreateDrawer}
            onClose={(): void => setShowCreateDrawer(false)}
            onCreate={handleTaskTypeCreate}
          />
          <EditTaskTypeDrawer
            departments={departments || []}
            open={Boolean(selectedTaskType)}
            taskType={selectedTaskType}
            onClose={(): void => setSelectedTaskType(null)}
            onSave={handleTaskTypeSave}
          />

        </Grid>


        <Grid item xs={6}>
          <Box mb={1} display="flex">
            <Box>
              <Button onClick={openAddTaskOutcome} startIcon={<AddCircleIcon />} color="primary">
                Add Task Outcome
              </Button>
            </Box>
            <Box flexGrow={1} />
          </Box>
          <Box>
            {loading ? (
              <Box height="500px" display="flex" justifyContent="center">
                <SmallCircularProgress />
              </Box>
            ) : (
              <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
                <Table stickyHeader id="quick-messages-table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ height: '100%', overflowY: 'auto' }}>Title &nbsp;&nbsp;</TableCell>
                      <TableCell style={{ height: '100%', overflowY: 'auto' }}>Department</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskOutcomes.map((taskOutcome) => (
                      <TableRow key={taskOutcome.id}>
                        <TableCell
                          className={classes.quickMessage}
                          onClick={(): void => onEditTaskOutcome(taskOutcome)}>
                          {taskOutcome.value}
                        </TableCell>
                        <TableCell>{taskOutcome?.departments?.map((x) => (x.departmentName)).join(', ')}</TableCell>
                        {/* <TableCell>
                          <Tooltip title="Delete quick message">
                            <IconButton onClick={(): Promise<void> => deleteTaskType(taskType.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <NewTaskOutcomeDrawer
            departments={departments || []}
            open={showCreateTaskOutcomeDrawer}
            onClose={(): void => setShowCreateTaskOutcomeDrawer(false)}
            onCreate={handleTaskOutcomeCreate}
          />
          <EditTaskOutcomeDrawer
            departments={departments || []}
            open={Boolean(selectedTaskOutcome)}
            taskOutcome={selectedTaskOutcome}
            onClose={(): void => setSelectedTaskOutcome(null)}
            onSave={handleTaskOutcomeSave}
          />

        </Grid>

      </Grid>

    </Paper>
  );
};

export default TaskboardSetting;
