import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, makeStyles, Button, FormHelperText } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { ReactElement, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { hideTaskDrawer, markTasksAsDone } from '../taskboardSlice';
import { TaskListObject } from 'components/lead/models/task';
import TaskboardAPI from '../taskboard-api';
import { LoadingButton } from 'common/components/buttons';
import { useFormik } from 'formik';
import * as Yup from 'yup';


function TaskListDoneDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  const handleTaskFormSubmit = (task: TaskListObject | null): void => {
    if (formik.isValid) {
      if (selectedTasks) {
        dispatch(markTasksAsDone(selectedTasks, formik.values.taskOutcomeId, formik.values.notes));
      }
    }
  };

  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };

  const selectedTasks = useAppSelector((state) => state.tasks.selectedTasks);
  const [outcomes, setOutcomes] = useState([]);

  const getOutcomeList = useCallback(async (): Promise<void> => {
    let data = await TaskboardAPI.getTaskOutcomes();
    setOutcomes(data);
  }, [1]);

  React.useEffect(() => {
    getOutcomeList();
  }, [getOutcomeList]);

  let initialValues: any = {
    notes: '',
    taskOutcomeId: 0
  };

  const schema = Yup.object<any>().shape<any>({
    // notes: Yup.string().required('Required').max(1500, 'Max length 1500'),
    taskOutcomeId: Yup.number().nullable().required('Required').min(1, 'this field is required')
  });


  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (!formik.isValid) {
        return;
      }
      const sanitizedValues = { ...values };
      handleTaskFormSubmit(null);
    }
  });





  return (
    <>
      <DrawerToolbar title={"Completed Tasks : " + selectedTasks.length} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <form>
          <Box mt={1}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="Outcome" variant="outlined" margin="dense">Outcome</InputLabel>
                  <Select
                    labelId="Outcome"
                    id="Outcome"
                    value={formik.values.taskOutcomeId}
                    error={!!formik.errors.taskOutcomeId}
                    label="Task Outcome"
                    margin="dense"
                    onChange={(e: any, opt: any) => {
                      formik.handleChange(e);
                      formik.setFieldValue('taskOutcomeId', opt.props.value);
                    }}
                  >
                    {outcomes?.map((x: any) => (
                      <MenuItem value={x.id}>{x.value}</MenuItem>
                    ))}
                  </Select>
                  {!!formik.errors.taskOutcomeId && <FormHelperText style={{ color: "#e53935" }}>{formik.errors.taskOutcomeId}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows={4}
                  id="notes"
                  name="notes"
                  label="Notes"
                  value={formik.values.notes}
                  error={!!formik.errors.notes}
                  helperText={formik.errors.notes}
                  onChange={(e: any) => { formik.handleChange(e) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button id="cancelTaskFormBtn" variant="contained" onClick={handleOnClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  fullWidth
                  id="saveTaskFormBtn"
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={(e: any) => { formik.handleSubmit(); }}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default TaskListDoneDrawer;
