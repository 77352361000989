import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableRow: {},
    pagination: {
      width: 'fit-content',
      marginLeft: 'auto',
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 12,
      paddingRight: 5,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingRight: 5,
    },
    nameCell: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export default useStyles;
