const localStorageName = 'PROJECT_X_STATE';

const get = (): any => {
  try {
    const serializedState = localStorage.getItem(localStorageName);
    if (serializedState === null) {
      return undefined;
    }
    const systemState = JSON.parse(serializedState);
    return systemState;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

const save = (state: any): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(localStorageName, serializedState);
  } catch (error) {
    console.error(error);
  }
};

const remove = (): void => {
  try {
    localStorage.removeItem(localStorageName);
  } catch (error) {
    console.error(error);
  }
};

export default { get, save, remove };
