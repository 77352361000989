import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useSplitPanelStyles = makeStyles((theme: Theme): any => ({
  splitPanel: {
    width: '100%',
    display: 'flex',
  },
  splitPanelLeft: {
    flexBasis: '40%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '40%',
    },
    overflowX: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  splitPanelRight: {
    flexBasis: '60%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '60%',
    },
    overflowX: 'auto',
  },
}));
export function SplitPanel({ left, right }: { left: ReactElement | null; right: ReactElement | null }): ReactElement {
  const classes: any = useSplitPanelStyles();
  return (
    <Box id="splitPanel" className={classes.splitPanel}>
      <Box id="splitPanelLeft" className={classes.splitPanelLeft}>
        {left}
      </Box>
      <Box id="splitPanelRight" className={classes.splitPanelRight}>
        {right}
      </Box>
    </Box>
  );
}

export default SplitPanel;
