import { Box, Theme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import NewCard from 'common/components/cards/new-card';
import { PageHeader } from 'common/components/layout';
import { useRouter } from 'common/hooks';
import CustomerGroup from 'components/customer-groups/models/customer-group';
import CustomerSegmentAPI from 'components/customer-groups/api/customer-group-api';
import React from 'react';
import CustomerGroupCard from './customer-group-card';
import { SmallCircularProgress } from 'common/components/loaders';

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function CustomerGroups(): React.ReactElement {
  const classes: any = useStyles();
  const router = useRouter();
  const [customerGroups, setCustomerGroups] = React.useState<CustomerGroup[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchCustomerSegments(): Promise<void> {
      try {
        const response = await CustomerSegmentAPI.getCustomerSegments(undefined);
        setCustomerGroups(response);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCustomerSegments();
  }, []);

  async function handleNewCustomerGroup(): Promise<void> {
    router.push('/customer-groups/new');
  }

  async function handleCustomerGroupClick(customerSegmentId: number): Promise<void> {
    router.push(`/customer-groups/${customerSegmentId}`);
  }
  function handleSearchTextChange(event: any): void {
    setSearchText(event.target.value);
  }
  const filteredCustomerGroups =
    searchText.length > 0
      ? customerGroups.filter((x) => x.segmentName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
      : customerGroups;

  return (
    <Container className={classes.root}>
      <PageHeader title="Customer Groups" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            style={{ backgroundColor: 'white' }}
            margin="dense"
            variant="outlined"
            name="search"
            id="search"
            onChange={handleSearchTextChange}
            placeholder="Search customer groups"
            InputProps={{
              startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
            }}
          />
        </Grid>
      </Grid>
      <Box>
        {isLoading && (
          <div style={{ margin: "5px" }}>
            <table>
              <tr>
                <td>
                  <h2>Loading...</h2>
                </td>
                <td>
                  <SmallCircularProgress></SmallCircularProgress>

                </td>
              </tr>

            </table>
          </div>
        )}

      </Box>
      <Box mt={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <NewCard title="New Customer Group" id="customer-groups-card" onClick={handleNewCustomerGroup} />
          </Grid>
          {filteredCustomerGroups.map((customerGroup: CustomerGroup) => {
            if (customerGroup.segmentName != "") {
              return (<Grid key={customerGroup.segmentId} item xs={12} sm={6} md={4} lg={3}>
                <CustomerGroupCard customerGroup={customerGroup} onClick={handleCustomerGroupClick} />
              </Grid>
              )
            }
          })
          }

          {/* {filteredCustomerGroups.map((customerGroup: CustomerGroup) => (
            <Grid key={customerGroup.segmentId} item xs={12} sm={6} md={4} lg={3}>
              <CustomerGroupCard customerGroup={customerGroup} onClick={handleCustomerGroupClick} />
            </Grid>
          ))} */}
        </Grid>
      </Box>


    </Container>
  );
}
