import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton, MenuItem,
  Popover,
  Switch,
  TextField, Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  PhoneVerificationStatusEnum,
  CustomerPhone
} from 'common/models/customer';
import React, { ReactElement, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { CustomerPhoneType } from 'components/customer/api/CustomerAPI';
import { useStyles, TextMaskCustom } from './CustomerForm';
import { formatPhoneNumber } from "./formatPhoneNumber";
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';
import AppSnackBar from 'components/snackbar/snackbar';

export function CustomerPhoneList({
  formik,
  phoneTypes,
  dmsTypeId,
  customer,
  convPhoneIds,
  loading,
}: {
  formik: any;
  phoneTypes: CustomerPhoneType[];
  dmsTypeId: number;
  customer: any;
  convPhoneIds: number[] | undefined;
  loading: boolean;
}): ReactElement {
  const [popoverState, setPopoverState] = useState<any>({ openedPopoverId: null, anchorEl: null });
  const classes: any = useStyles();
  const [showSnackBar] = AppSnackBar.useSnackBar();

  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopoverState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  };
  const handlePopoverClose = () => {
    setPopoverState({
      openedPopoverId: null,
      anchorEl: null,
    });
    const data = formik.values.customerPhones.length - 1;
    if (formik.values.customerPhones[data].phoneNumber === '' && !formik.values.customerPhones[data].isDefault) {
      let phone = [...formik.values.customerPhones];
      phone.splice(data, 1);
      formik.setFieldValue('customerPhones', phone);
    }
  };
  const addPhoneRow = (event: any) => {
    let customerPhones: any = [];
    if (formik.values.customerPhones) {
      customerPhones = [...formik.values.customerPhones];
    }

    let allPhoneTypes: number[] = phoneTypes.map((x: CustomerPhoneType) => x.customerPhoneTypeId);
    let addedPhoneTypes: number[] = customerPhones.map((x: CustomerPhone)=>x.phoneType);

    let notAddedPhoneTypes : number[] = [];
    for (const num of allPhoneTypes) {
      if (!addedPhoneTypes.includes(num)) {
        notAddedPhoneTypes.push(num);
      }
    }
    
    if (notAddedPhoneTypes.length > 0) {
      const customerPhone: CustomerPhone = {
        id: 0,
        customerId: 0,
        contactId: customer && (customer.primaryContactId ?? 0),
        dealerId: 0,
        lastUpdated: '',
        phoneNumber: '',
        extension: '',
        phoneType: notAddedPhoneTypes[0],
        isPrimary: false,
        verificationStatus: 0,
        isDefault: dmsTypeId === 9 && customerPhones?.length === 0 ? true : false,
      };
      customerPhones.push(customerPhone);
      formik.setFieldValue('customerPhones', customerPhones);
      //////////////////////////////////////////////////////////////////////////////
      const _index = customerPhones.indexOf(customerPhone);
      handlePopoverOpen(event, _index);
    }
    
  };
  const removePhoneRow = (index: number) => {
    let customerPhones = [...formik.values.customerPhones];
    customerPhones.splice(index, 1);
    formik.setFieldValue('customerPhones', customerPhones);
  };

  const removePhoneRowHandler = (index: number, phoneId: number) => {
    if (!convPhoneIds?.includes(phoneId)) {
      removePhoneRow(index);
    } else {
      showSnackBar({
        type: 'error',
        message: 'Cannot delete a phone number that is associated with a conversation',
      });
    }
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <LocalPhoneIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">Phone</Typography>
            </Grid>
            {/* <span className={classes.errorMsg}>
              {!!formik.errors.customerPhones ? formik.errors.customerPhones : ''}
            </span> */}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item style={{ marginTop: '-10px' }}>
              <Typography variant="h5">
                Opt In{' '}
                <Switch
                  checked={formik.values.optIn === 1}
                  onChange={(e: any) => {
                    formik.setFieldValue('optIn', e.target.checked ? 1 : 0);
                  }} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <AddIcon
                  onClick={(e: any) => {
                    addPhoneRow(e);
                  }}
                  style={{ cursor: 'pointer' }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          {formik.values.customerPhones &&
            formik.values.customerPhones.map((row: any, index: number) => {
              
              if (dmsTypeId != DmsTypeEnum.Ideal && customer && customer.primaryContactId && row.contactId != customer.primaryContactId) {
                return;
              }
              // if(dmsTypeId == DmsTypeEnum.Ideal && row.contactId != 0){
              //   return;
              // }
              return (
                <>
                  <Box mt={1} key={index}>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography variant="body1">
                          {formik.values.customerPhones[index].phoneNumber ? (
                            formatPhoneNumber(formik.values.customerPhones[index].phoneNumber) +
                            ' ' +
                            (formik.values.customerPhones[index].extension != null &&
                              formik.values.customerPhones[index].extension != '' &&
                              (dmsTypeId === 9 || dmsTypeId === 0)
                              ? '(' + formik.values.customerPhones[index].extension + ')'
                              : '')
                          ) : (
                            <span style={{ color: 'rgb(152, 152, 152)' }}>(000) 000-0000</span>
                          )}
                          &nbsp;
                          <Chip
                            className={classes.smallChip}
                            label={ !loading? phoneTypes.filter(
                              (x) => formik.values.customerPhones[index].phoneType == x.customerPhoneTypeId
                            )[0]?.name:<CircularProgress size='1rem' style={{color:'white',display:'flex'}}/>}
                            color="primary"
                            variant="filled" />
                          {formik.values.customerPhones[index].verificationStatus &&
                            formik.values.customerPhones[index].verificationStatus ===
                            PhoneVerificationStatusEnum.Verified ? (
                            <CheckCircleIcon className={classes.successIcon + ' successIcon'} />
                          ) : (
                            ''
                          )}
                          {formik.values.customerPhones[index].verificationStatus &&
                            formik.values.customerPhones[index].verificationStatus ===
                            PhoneVerificationStatusEnum.Failed ? (
                            <CancelIcon className={classes.errorIcon + ' errorIcon'} />
                          ) : (
                            ''
                          )}
                          {/* {row.isPrimary && dmsTypeId === 9 && <>&nbsp;</>}
                            {dmsTypeId === 9 && row.isPrimary && (
                              <Chip className={classes.smallChip} label={'Primary'} color="success" variant="filled" />
                            )} */}
                          {row.isDefault && dmsTypeId === 9 && <>&nbsp;</>}
                          {row.isDefault && dmsTypeId === 9 && (
                            <Chip className={classes.smallChip} label={'Default'} color="info" variant="filled" />
                          )}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <IconButton
                          style={{ marginTop: '-10px' }}
                          aria-describedby={index.toString()}
                          onClick={(e: any) => handlePopoverOpen(e, index)}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={index.toString()} onClick={(e: any) => (handlePopoverOpen(e, index))} fontSize='small' /> */}
                        {/* {((dmsTypeId === 9 && !row.isDefault && row.contactId < 0) || dmsTypeId !== 9) && ( */}
                        <ClearIcon
                          fontSize="small"
                          aria-describedby={index.toString()}
                          onClick={(e: any) => {
                            const phoneId = Number(formik.values?.customerPhones[index]?.id);
                            removePhoneRowHandler(index, phoneId);
                          }}
                          style={{ cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Popover
                    id={'pop_' + index}
                    open={popoverState.openedPopoverId === index}
                    anchorEl={popoverState.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box p={2} style={{ width: '500px' }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={8}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            name={`customerPhones[${index}].phoneNumber`}
                            label="Phone Number"
                            value={formik.values.customerPhones[index].phoneNumber}
                            onChange={(e) => {
                              formik.setFieldValue(`customerPhones[${index}].phoneNumber`, e.target.value);
                            }}
                            InputProps={{
                              inputComponent: TextMaskCustom as any,
                            }} />
                        </Grid>
                        <Grid item xs={3}>
                          {dmsTypeId === 9 && (
                            <Box ml={1}>
                              <FormControlLabel
                                control={<Checkbox
                                  disabled={row.isDefault}
                                  onChange={(e) => {
                                    formik.values.customerPhones.forEach((e: any, i: number) => {
                                      if (i !== index) {
                                        formik.setFieldValue(`customerPhones[${i}].isDefault`, false);
                                      }
                                    });
                                    formik.setFieldValue(`customerPhones[${index}].isDefault`, e.target.checked);
                                  }}
                                  checked={row.isDefault}
                                  inputProps={{ 'aria-label': 'controlled' }} />}
                                label="Default" />
                            </Box>
                          )}
                        </Grid>
                        {/* Remove "style display none" to show primary check box for multiple cellphone in case of aspen*/}
                        <Grid item xs={3} style={{display:'none'}}>
                          {dmsTypeId === 9 &&
                            formik.values.customerPhones[index].phoneType === formik.values.defaultPhoneType && (
                              <Box ml={1}>
                                <FormControlLabel
                                  control={<Checkbox
                                    onChange={(e) => {
                                      formik.values.customerPhones.forEach((e: any, i: number) => {
                                        if (i !== index) {
                                          formik.setFieldValue(`customerPhones[${i}].isPrimary`, false);
                                        }
                                      });
                                      formik.setFieldValue(`customerPhones[${index}].isPrimary`, e.target.checked);
                                    }}
                                    checked={row.isPrimary}
                                    inputProps={{ 'aria-label': 'controlled' }} />}
                                  label="Primary" />
                              </Box>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                          {(dmsTypeId === 9 || dmsTypeId === 0) && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              name={`customerPhones[${index}].extension`}
                              label="Extension"
                              value={formik.values.customerPhones[index].extension}
                              onChange={(e) => {
                                formik.setFieldValue(`customerPhones[${index}].extension`, e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (event?.key === '-' ||
                                  event?.key === '+' ||
                                  formik.values.customerPhones[index].extension.length > 4) {
                                  event.preventDefault();
                                }
                              }}
                              InputProps={{
                                type: 'number',
                              }} />
                          )}
                        </Grid>


                        <Grid item xs={8}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            margin="dense"
                            name={`customerPhones[${index}].phoneType`}
                            label={`Type`}
                            value={formik.values.customerPhones[index].phoneType}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerPhones[${index}].phoneType`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}>
                            {phoneTypes.map((option: CustomerPhoneType) => {
                              return (
                                <MenuItem
                                  disabled={
                                    //disable when primary contact contains phone Type AND is not DmsType 9
                                    formik.values.customerPhones.filter((x: any) => {
                                      if (customer && customer.customerContact) {
                                        return (
                                          x.phoneType === option.customerPhoneTypeId &&
                                          x.contactId === customer.customerContact.contactId
                                        );
                                      } else {
                                        return x.phoneType === option.customerPhoneTypeId;
                                      }
                                    })?.length > 0 && dmsTypeId !== 9}
                                  key={option.customerPhoneTypeId}
                                  value={option.customerPhoneTypeId}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerAddressBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}
