import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Zoom from '@material-ui/core/Zoom';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default function ImageModal({
  open,
  onClose,
  imageSrc,
}: {
  open: boolean;
  imageSrc?: string;
  onClose(event: any): void;
}): React.ReactElement {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogActions style={{ textAlign: 'right' }}>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <img src={imageSrc} alt={imageSrc} width="100%" />
      </DialogContent>
    </Dialog>
  );
}
