import {
  Box,
  Button,
  Checkbox,
  CircularProgress, Grid,
  IconButton, MenuItem,
  Popover, TextField, Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import { CustomerCategory } from 'components/customer/api/CustomerAPI';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useStyles } from './CustomerForm';

export function CustomerCategoryComponent({
  formik, categories, catLoader, dmsType,
}: {
  formik: any;
  categories: CustomerCategory[];
  catLoader: boolean;

  dmsType: number;
}): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (values: CustomerCategory[]) => {
    formik.setFieldValue('customerCategories', values);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formikValue = formik.values?.customerCategories ? formik.values.customerCategories : [];
  const label = formikValue.map((x: CustomerCategory) => {
    return x.name;
  });

  const classes: any = useStyles();
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item style={{ marginRight: '5px' }}>
        {' '}
        <PersonIcon />{' '}
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {dmsType === 9 && (
            <>
              <span>Categories&nbsp;</span>
              {label.length ? (
                <Chip className={classes.smallChip} label={label.join(',')} title={label.join(',')} color="primary" variant="filled"
                  style={{ maxWidth: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipses" }}></Chip>
              ) : catLoader ? (
                <CircularProgress size={'1rem'} />
              ) : (
                ''
              )}
            </>
          )}
          {dmsType !== 9 && (
            <>
              <span>Category&nbsp;</span>

              {label.length ? (
                <Chip className={classes.smallChip} label={label.join(',')} title={label.join(',')} color="primary" variant="filled"
                  style={{ maxWidth: 100, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipses" }}></Chip>
              ) : catLoader ? (
                <CircularProgress size={'1rem'} />
              ) : (
                ''
              )}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          {dmsType === 9 && (
            <Autocomplete
              multiple
              id="tags-standard"
              value={formik.values.customerCategories}
              options={categories}
              onChange={(event: any, values: any): void => handleCategoryChange(values)}
              getOptionLabel={(option: CustomerCategory) => option.name}
              disableCloseOnSelect
              getOptionSelected={(option: CustomerCategory, value: CustomerCategory): boolean => option.customerCategoryId === value.customerCategoryId}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={selected} />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Categories"
                  id="categories"
                  variant="outlined"
                  margin="dense"
                  label="Categories"
                  placeholder="Select Categories" />
              )} />
          )}
          {dmsType !== 9 && (
            <TextField
              fullWidth
              select
              variant="outlined"
              margin="dense"
              id={`customerCategories[0]`}
              name={`customerCategories[0]`}
              label={`Type`}
              value={formikValue[0]?.customerCategoryId ?? []}
              onChange={(e: any) => {
                formik.setFieldValue(
                  `customerCategories`,
                  categories.filter((x) => x.customerCategoryId === e.target.value)
                );
              }}
              InputLabelProps={{
                shrink: true,
              }}>
              {categories.map((option: CustomerCategory) => {
                return (
                  <MenuItem key={option.customerCategoryId} value={option.customerCategoryId}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
          <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerNameBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
}
