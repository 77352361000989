import { Modal, Box, Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from '../styles/deal-edit-drawer-styles';

const PdfPreviewModal = ({ openModal, openPdf, fileName, selectedPdf } : {openModal: boolean, openPdf: any, fileName: string, selectedPdf: string}) => {
const classes: any  = useStyles();
  return (
    <Modal
      open={openModal}
      onClose={() => openPdf(false, null, null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box style={{ width: '90%', height: '90%', position: 'relative', backgroundColor: 'rgb(51, 51, 51)', borderRadius: '8px' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body1"
            className = {classes.pdfName}
          >
            {fileName}
          </Typography>
          <Button
          className= {classes.arrowBackButton}
            onClick={() => openPdf(false, null, null)}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgb(61, 61, 61)'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'rgb(51, 51, 51)'}
          >
            <ArrowBackIcon style={{ fontSize: '19px' }} />
          </Button>
          <Button
          className= {classes.newTabButton}
            onClick={() => window.open(selectedPdf, '_blank')}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgb(61, 61, 61)'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'rgb(51, 51, 51)'}
          >
            <Typography variant="body2">
              Open in new tab
            </Typography>
          </Button>
        </Box>
        <iframe
          title="PDF Preview"
          src={selectedPdf}
          width="100%"
          height="100%"
          style={{ border: 'none', marginTop: '10px' }}
        ></iframe>
      </Box>
    </Modal>
  );
};

export default PdfPreviewModal;
