import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { useAppDispatch, useAppSelector } from 'app';
import React, { ReactElement, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import { TaskListObject, TaskListRescheduleRequest } from 'components/lead/models/task';
import { forEach, initial } from 'lodash';
import { rescheduleTaskList, SetAllSelectedTasks, SetSelectedTasks } from '../taskboardSlice';
const init = {
  height: 200,
  convert_urls: true,
  plugins: 'lists',
  toolbar: `bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify`,
  menubar: false,
  branding: false,
};
const fontColor: any = {
  style: { color: 'rgb(50, 50, 50)' },
};
const useStyles = makeStyles((theme: Theme) => ({
  textFieldPadding: {
    marginTop: '5%',
  },

  archive: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
  },
}));
export function TaskArchiveDrawer({
  open,
  onClose,
  selectedTasks,
}: {
  open: boolean;
  onClose(val: boolean): void;
  selectedTasks: TaskListObject[];
}): ReactElement {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [editorLoader, setEditorLoader] = useState(true);
  const formik = useFormik({
    initialValues: {
      taskArchiveReason: '',
    },
    onSubmit: (values) => {
      let taskArchiveList: TaskListRescheduleRequest = {
        taskIds: selectedTasks.map((x) => x.taskId),
        isArchived: true,
        taskArchiveReason: values.taskArchiveReason,
      };
      dispatch(rescheduleTaskList(taskArchiveList));
      dispatch(SetSelectedTasks([]));
      dispatch(SetAllSelectedTasks(false));
      formik.resetForm();
      onClose(false);
    },
  });
  return (
    <Drawer variant="temporary" anchor="right" open={open} onClose={() => onClose(false)}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.archive} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Archive {selectedTasks.length} {selectedTasks.length > 1 ? 'Tasks' : 'Task'}</Typography>
          </Box>
          <IconButton id="closeBtn" onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Container maxWidth="sm">
          <Box mt={1}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  //disabled={formik.values.deals.filter((x) => x.dealStateId == DealsState.Lost).length > 0}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => formik.handleSubmit()}>
                  Save
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid item xs={12}>
                <Box className={classes.textFieldPadding}>
                  {editorLoader && (
                    <div style={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size="2rem" />
                    </div>
                  )}
                  <Editor
                    value={formik.values.taskLostReason}
                    //disabled={formik.values.deals.filter((x) => x.dealStateId == DealsState.Lost).length > 0}
                    onEditorChange={(e) => {
                      formik.handleChange({
                        target: {
                          name: `taskLostReason`,
                          value: e,
                        },
                      });
                    }}
                    onInit={() => {
                      setEditorLoader(false);
                    }}
                     apiKey={textEditorApiKey ||''}
                    init={init}></Editor>
                </Box>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows={4}
                    name="taskArchiveReason"
                    label="Archive Reason"
                    value={formik.values.taskArchiveReason}
                    onChange={(e:any) => formik.handleChange(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
