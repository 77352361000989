import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
  Container,
  MenuItem,
  Checkbox,
  Switch,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactElement, useContext, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DealAge, DealStatus, FilterDeals, TransientFilterDeals } from '../model/dealsInterfaces';
import dealsApi from '../apis/deals-api';
import { useAppSelector } from 'app';
import User from 'components/setting/models/user';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
import useStyles from '../styles/deals-styles';
import DealContext from '../contexts/deal-context';
import { DealStatusEnum } from '../model/DealStatusEnum';
import DatePicker from '@mui/lab/DatePicker';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField as MaterialTextField } from '@mui/material';
import { DealLeadTags } from 'components/setting/api/manage-lead-source-api';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export function FilterDealDrawer({
  open,
  loadingDeals,
  filterUserIds,
  leadTags,
  defaultFilterValues,
  setFilter,
  onClose,
  exportDeals
}: {
  open: boolean;
  loadingDeals: boolean;
  filterUserIds: string[];
  leadTags: DealLeadTags[];
  defaultFilterValues: FilterDeals;
  setFilter: (req: FilterDeals) => void;
  onClose(val: boolean): void;
  exportDeals(): void;
}): ReactElement {
  const dealsContext = useContext(DealContext);
  const classes = useStyles();
  const dealerLocationId = useAppSelector((state: any) => state.tasks.dealerId);

  const [enableFilter, setEnableFilter] = useState(defaultFilterValues.isFilter ?? (false as boolean));
  const [filterLocation, setLocation] = useState(0 as number);
  const [ages, setAges] = useState<DealAge[]>([]);
  const [statuses, setStatus] = useState<DealStatus[]>([]);
  const [filterAge, SetAge] = useState(defaultFilterValues.ageValue ?? (0 as number));
  const [filterStatus, SetStatus] = useState(defaultFilterValues.statusId ?? (DealStatusEnum.Open as number));
  const [filterUsers, setUsers] = useState<string[]>(defaultFilterValues.userIds ?? filterUserIds);
  const users = dealsContext.user;

  const [creationDateStart, setCreationDateStart] = useState<Date | undefined>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const [creationDateEnd, setCreationDateEnd] = useState<Date | undefined>(new Date());

  const [closureDateStart, setClosureDateStart] = useState<Date | undefined>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const [closureDateEnd, setClosureDateEnd] = useState<Date | undefined>(new Date());

  const [isAged, setIsAged] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const [leadTagId, setLeadTagId] = useState<number | null>(null);

  useEffect(() => {
    dealsApi.filterAgeData().then((data) => setAges(data));
    dealsApi.filterStatusData().then((data) => setStatus(data));
  }, []);

  useEffect(() => {
    const request: FilterDeals = {
      ageValue: filterAge,
      statusId: filterStatus,
      locationId: filterLocation,
      userIds: filterUsers,
      isFilter: enableFilter,
      transientFilter: {
        creationDateStart,
        creationDateEnd,
        closureDateStart,
        closureDateEnd,
        leadTagId,
        isAged,
        isFavorite,
      },
    };
    setFilter(request);
  }, [filterAge, filterStatus, filterLocation, filterUsers,creationDateStart, creationDateEnd, closureDateStart, closureDateEnd, isAged, isFavorite, leadTagId]);

  const handleUserChange = (values: User[]) => {
    setEnableFilter(true);
    let userIds = values.map((x) => x.crmUserId);
    setUsers(userIds);
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={() => onClose(false)}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.filterIconBtn} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Filter Deals</Typography>
          </Box>
          <IconButton id="closeBtn" style={{ color: 'white' }} onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <div style={{ marginBottom: 50 }}></div>
        <Container>
          <Box mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="usersDropdown"
                  options={users || []}
                  onChange={(event: any, values: any): void => handleUserChange(values)}
                  getOptionLabel={(option: any) => option.username}
                  disableCloseOnSelect
                  value={users?.filter((x) => dealsContext.filterRequest?.userIds?.includes(x.crmUserId))}
                  getOptionSelected={(option: User, value: User): boolean => {
                    return option.crmUserId === value.crmUserId;
                  }}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      {option.username}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="users"
                      id="users"
                      variant="outlined"
                      margin="dense"
                      label="Assigned To"
                      placeholder="Select Users"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  value={dealsContext.filterRequest?.ageValue}
                  fullWidth
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  onChange={(e: any) => {
                    setEnableFilter(true);
                    SetAge(e.target.value);
                  }}
                  label="Age">
                  {ages.map((item) => (
                    <MenuItem key={item.ageValue} value={item.ageValue}>
                      {item.age}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  value={leadTagId}
                  fullWidth
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: leadTagId && (
                      <InputAdornment position="end" style={{marginRight:10}}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the select dropdown from opening
                            setLeadTagId(null); // Clear the selected value
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !leadTagId ? false:true, // Shrink the label when the TextField is cleared
                  }}
                  onChange={(e: any) => {
                    setLeadTagId(e.target.value);
                  }}
                  
                  label="Lead Source">
                  {leadTags.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.leadTagName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  value={!dealsContext.filterRequest?.statusId ? 1 : dealsContext.filterRequest?.statusId}
                  variant="outlined"
                  size="small"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  onChange={(e: any) => {
                    setEnableFilter(true);
                    SetStatus(e.target.value);
                  }}
                  label="Status">
                  {statuses.map((item) => (
                    <MenuItem key={item.statusId} value={item.statusId}>
                      {item.status}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box bgcolor="#d1d1d18c" p={2} borderRadius={4}>
                    <Typography variant="h5" style={{ marginBottom: 15, fontWeight: 'bold' }}>
                      Deal Creation Date (Range)
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DatePicker
                          renderInput={(params) => (
                            <MaterialTextField variant="outlined" margin="dense" size="small" {...params} />
                          )}
                          label="Start Date"
                          value={creationDateStart}
                          disableFuture
                          onChange={(newValue) => {
                            setCreationDateStart(newValue as Date);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          renderInput={(params) => (
                            <MaterialTextField variant="outlined" margin="dense" size="small" {...params} />
                          )}
                          label="End Date"
                          value={creationDateEnd}
                          disableFuture
                          onChange={(newValue) => {
                            setCreationDateEnd(newValue as Date);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {(filterStatus == DealStatusEnum.Lost || filterStatus == DealStatusEnum.Won) && (
                      <Box mt={2} bgcolor="#d1d1d18c" p={2} borderRadius={4}>
                        <Typography variant="h5" style={{ marginBottom: 15, fontWeight: 'bold' }}>
                          Deal Closure Date (Range)
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <DatePicker
                              renderInput={(params) => (
                                <MaterialTextField variant="outlined" margin="dense" size="small" {...params} />
                              )}
                              label="Start Date"
                              value={closureDateStart}
                              disableFuture
                              onChange={(newValue) => {
                                setClosureDateStart(newValue as Date);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              renderInput={(params) => (
                                <MaterialTextField variant="outlined" margin="dense" size="small" {...params} />
                              )}
                              label="End Date"
                              value={closureDateEnd}
                              disableFuture
                              onChange={(newValue) => {
                                setClosureDateEnd(newValue as Date);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">Display Favorites Only</Typography>
                  </Grid>
                  <Grid item>
                    <Switch checked={isFavorite} onChange={(event) => setIsFavorite(event.target.checked)} />
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">Display Aged Only</Typography>
                  </Grid>
                  <Grid item>
                    <Switch checked={isAged} onChange={(event) => setIsAged(event.target.checked)} />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button disabled = {loadingDeals} endIcon={<FileDownloadIcon/>} variant="contained" color="primary" onClick={exportDeals} fullWidth>Export to Excel</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
