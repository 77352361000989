import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { SmallCircularProgress } from 'common/components/loaders';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import useLocations from 'common/hooks/useLocations';
import { DealerSetting } from 'common/models/dealer-setting';
import DealerAPI from 'components/authentication/api/dealer-api';
import AppSnackbar from 'components/snackbar/snackbar';
import React, { useState } from 'react';
import { useAppSelector } from 'app';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';
import MessageSignatureAPI from '../../api/message-signature-api';
import { MessageSignature } from '../../models/message-signature';
import moment from 'moment';
import CustomerAPI, { CustomerCategory, CustomerPhoneType } from 'components/customer/api/CustomerAPI';
import { useEffect } from 'react';

const General: React.FC = () => {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [categories, setCategories] = useState<CustomerCategory[]>([]);
  const [phoneType, setCustomerPhoneType] = useState<CustomerPhoneType[]>([]);
  const { getLocationName } = useLocations(dmsDealerIdCrm);
  const [messageSignatures, setMessageSignatures] = React.useState<MessageSignature[]>([]);
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [loadingDealerSetting, setLoadingDealerSetting] = React.useState(false);
  const [dealerSettings, setDealerSettings] = React.useState<DealerSetting | undefined>();
  const [custCategory, setCustCategory] = useState<string>();
  const [custPhoneType, setCustPhoneType] = useState<string>();

  let dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  React.useEffect(() => {
    async function getMessageSignatures(): Promise<void> {
      try {
        setLoading(true);
        const response = await MessageSignatureAPI.getMessageSignatures(dmsDealerIdCrm);
        setMessageSignatures(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showSnackbar({ type: 'error', message: 'Unable to fetch message signatures' });
      }
    }
    async function getDealerSettings(): Promise<void> {
      try {
        //alert(dmsTypeId);
        setLoadingDealerSetting(true);
        const dealerSettingsResponse = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
        setDealerSettings(dealerSettingsResponse);
        const defaultData = dealerSettingsResponse.dealerLocationSettings.find(
          (x) => x.locationId === 1,
        )?.defaultCustomerCategoryId;
        setCustCategory(defaultData);
        
        const defaultPhoneTypeData = dealerSettingsResponse.dealerLocationSettings.find(
          (x) => x.locationId === 1,
        )?.defaultCustomerPhoneTypeId;
        setCustPhoneType(defaultPhoneTypeData);
        
        setLoadingDealerSetting(false);
      } catch (error) {
        setLoadingDealerSetting(false);
      }
    }
    async function fetchCategories() {
      let categories = await CustomerAPI.getCategories(dealerId);
      setCategories(categories);
    }
    async function fetchCustomerPhoneType() {
      let phoneType = await CustomerAPI.getPhoneTypes(dealerId);
      //alert(phoneType.length);
      setCustomerPhoneType(phoneType);
    }
    fetchCategories();
    fetchCustomerPhoneType();
    getDealerSettings();
    getMessageSignatures();
  }, []);

  function handleMessageSignatureChange(dealerId: number, messageSignature: string): void {
    const updatedMessageSignatures = messageSignatures.map((ms) => {
      if (ms.dealerId !== dealerId) return ms;
      return { dealerId, messageSignature };
    });
    setMessageSignatures(updatedMessageSignatures);
  }

  async function handleSettingSave(): Promise<void> {
    try {
        setSaving(true);
      await MessageSignatureAPI.updateMessageSignatures(dmsDealerIdCrm, messageSignatures);
      if (dealerSettings) {
        dealerSettings.defaultCustomerCategoryId = custCategory || '';
        dealerSettings.defaultCustomerPhoneTypeId = custPhoneType || '';       
        await DealerAPI.updateDealerSettings(dmsDealerIdCrm, dealerSettings);
      }
      setSaving(false);
      showSnackbar({ type: 'success', message: 'Setting saved' });
    } catch (error) {
      setSaving(false);
      showSnackbar({ type: 'error', message: 'Unable to save setting' });
    }
  }

  function updateAutomaticSurveyDelay(delayInDays: number): void {
    if (dealerSettings) {
      setDealerSettings({
        ...dealerSettings,
        dealerLocationSettings: dealerSettings.dealerLocationSettings.map((x) => {
          const isNotDefaultLocation = x.locationId !== 1;
          if (isNotDefaultLocation) return x;
          return {
            ...x,
            automaticSurveyDelay: delayInDays,
          };
        }),
      });
    }
  }
  function updateSurveyDoNotDisturb(delayInDays: number): void {
    if (dealerSettings) {
      setDealerSettings({
        ...dealerSettings,
        dealerLocationSettings: dealerSettings.dealerLocationSettings.map((x) => {
          const isNotDefaultLocation = x.locationId !== 1;
          if (isNotDefaultLocation) return x;
          return {
            ...x,
            doNotDisturbSurvey: delayInDays,
          };
        }),
      });
    }
  }
  function updateBroadcastDoNotDisturb(delayInDays: number): void {
    if (dealerSettings) {
      setDealerSettings({
        ...dealerSettings,
        dealerLocationSettings: dealerSettings.dealerLocationSettings.map((x) => {
          const isNotDefaultLocation = x.locationId !== 1;
          if (isNotDefaultLocation) return x;
          return {
            ...x,
            doNotDisturbBroadCast: delayInDays,
          };
        }),
      });
    }
  }
  const defaultLocationSetting = dealerSettings?.dealerLocationSettings.find((x) => x.locationId === 1);
  return (
    <Paper style={{ minHeight: '600px', overflow: 'scroll' }}>
      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box pt={2} mb={2}>
                <Typography variant="h4">
                  Auto-Survey Delay
                  <Tooltip
                    title="This delays sending the survey after a sales or work order is checked out."
                    aria-label="add">
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={dmsTypeId == DmsTypeEnum.Aspen ? 7 : 9 }>
                    <TextField
                      select
                      type="select"
                      id="auto-survey-delay"
                      name="auto-survey-delay"
                      margin="dense"
                      disabled={loadingDealerSetting}
                      value={defaultLocationSetting?.automaticSurveyDelay || 0}
                      onChange={({ target: { value } }): any => updateAutomaticSurveyDelay(parseInt(value, 10))}
                      variant="outlined">
                      <MenuItem value={0}>None</MenuItem>
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={2}>2 Days</MenuItem>
                      <MenuItem value={7}>7 Days</MenuItem>
                      <MenuItem value={14}>14 Days</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs = {3}>
                    <TextField
                      select
                      label="Default Customer Category"
                      fullWidth
                      margin="dense"
                      value={custCategory || ''}
                      onChange={(e) => {
                        setDealerSettings({ ...dealerSettings!, defaultCustomerCategoryId: e.target.value });
                        setCustCategory(e.target.value);
                      }}
                      variant="outlined">
                      {categories.map((item) => {
                        return (
                          <MenuItem key={item.customerCategoryId} value={item.customerCategoryId}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  {(dmsTypeId == DmsTypeEnum.Aspen) && (
                    <Grid item xs={2}>
                      <TextField
                        select
                        label="Cell Phone Type"
                        fullWidth
                        margin="dense"
                        value={custPhoneType || ''}
                        onChange={(e) => {
                          setDealerSettings({ ...dealerSettings!, defaultCustomerPhoneTypeId: e.target.value });
                          setCustPhoneType(e.target.value);
                        }}
                        variant="outlined">
                        {phoneType.map((item) => {
                          return (
                            <MenuItem key={item.customerPhoneTypeId} value={item.customerPhoneTypeId}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container>
            <Grid item xs={12}>
              <Box pt={2} mb={2}>
                <Typography variant="h4">
                  Oversending Control
                  <Tooltip
                    title="Prevent sending multiple Broadcasts or Surveys in a time period to a customer based on these settings."
                    aria-label="add">
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>                   
                    <TextField
                      select
                      type="select"
                      label="Broadcasts"
                      id="broadcast-do-not-disturb"
                      name="broadcast-do-not-disturb"
                      margin="dense"
                      disabled={loadingDealerSetting}
                      value={defaultLocationSetting?.doNotDisturbBroadCast || 0}
                      onChange={({ target: { value } }): any => updateBroadcastDoNotDisturb(parseInt(value, 10))}
                      variant="outlined">
                      <MenuItem value={-1}>None</MenuItem>
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={2}>2 Days</MenuItem>
                      <MenuItem value={7}>7 Days</MenuItem>
                      <MenuItem value={14}>14 Days</MenuItem>
                      <MenuItem value={30}>30 Days</MenuItem>
                      <MenuItem value={60}>60 Days</MenuItem>
                      <MenuItem value={90}>90 Days</MenuItem>
                    </TextField>                    
                    <TextField
                      select                      
                      type="select"
                      style={{marginLeft:"20px"}}
                      label="Surveys"
                      id="Survey-Do-Not-Disturb"
                      name="survey-do-not-disturb"
                      margin="dense"
                      disabled={loadingDealerSetting}
                      value={defaultLocationSetting?.doNotDisturbSurvey || 0}
                      onChange={({ target: { value } }): any => updateSurveyDoNotDisturb(parseInt(value, 10))}
                      variant="outlined">
                      <MenuItem value={-1}>None</MenuItem>
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={2}>2 Days</MenuItem>
                      <MenuItem value={7}>7 Days</MenuItem>
                      <MenuItem value={14}>14 Days</MenuItem>
                      <MenuItem value={30}>30 Days</MenuItem>
                      <MenuItem value={60}>60 Days</MenuItem>
                      <MenuItem value={90}>90 Days</MenuItem>
                    </TextField>
                  </Grid> 
                </Grid>                
              </Box>
            </Grid>
          </Grid>
        <Box>
          <Box pt={2} mb={2}>
            <Typography variant="h4">Message signature</Typography>
          </Box>
          <Box>
            <Typography>
              This signature will append to every message you send. Typically, dealers place their business name in the
              signature
            </Typography>
          </Box>
        </Box>
        <Box my={1}>
          {loading ? (
            <Box style={{ margin: 'auto' }} display="flex" justifyContent="center">
              <SmallCircularProgress />
            </Box>
          ) : (
            messageSignatures.map((messageSignature: MessageSignature, idx: number) => (
              <Accordion
                key={messageSignature.dealerId}
                style={{ marginBottom: '10px' }}
                elevation={3}
                defaultExpanded={idx === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {getLocationName(messageSignature.dealerId)}
                </AccordionSummary>
                <AccordionDetails>
                  <TextLimitField
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={messageSignature.messageSignature}
                    variant="outlined"
                    characters={160}
                    onChange={(event: any): void =>
                      handleMessageSignatureChange(messageSignature.dealerId, event.target.value)
                    }
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>
        {messageSignatures.length ? (
          <Box mb={1} display="flex" flexDirection="row-reverse">
            <Button disabled={saving} variant="contained" color="primary" onClick={handleSettingSave}>
              Save
            </Button>
          </Box>
        ) : null}
      </Container>
    </Paper>
  );
};

export default General;
