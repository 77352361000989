import { Notification } from './notificationSlice';
import * as SignalR from '@microsoft/signalr';
import { post } from 'api';
import { EventTypes } from 'common/models/eventTypes';
import {
  fetchCustomers,
  SetSelectedCustomers,
  UpdateSelectedCustomer,
} from 'components/customer/components/Customers/customersSlice';
import { addNotification } from 'components/layouts/DashboardLayout/notificationSlice';
import config from 'config';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { updateUserCredits } from 'components/authentication/components/authentication-slice';
import { v1 as uuidv1 } from 'uuid';

import { showSnackbar } from '../../root-snackbar/snackbar-slice';

const notificationUrl = config.get('api.notification');
const negotiateUrl = `${notificationUrl}/negotiate`;

export function useSignalRConnection(): SignalR.HubConnection | null {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.Id);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [signalRHubConnection, setSignalRHubConnection] = useState<SignalR.HubConnection | null>(null);

  const makeHubConnection = useCallback(async (url: string, accessToken: any): Promise<void> => {
    try {
      const signalRConnection = new SignalR.HubConnectionBuilder().withUrl(url, {
        accessTokenFactory: () => accessToken,
      })
        .build();
      setSignalRHubConnection(signalRConnection);
    } catch (error) {
      console.error('Error: SignalR client connectionHub build: ', error);
      setTimeout(makeHubConnection, 5000);
    }
  }, []);

  const makeSignalRConnection = useCallback(async (): Promise<void> => {
    if (userId && dealerId) {
      const uid = uuidv1();
      const negotiateRequestBody = { userId, dealerId };
      const negotiateRequestHeader = { headers: { 'x-ms-signalr-userid': uid } };
      try {
        const negotiateResponse = await post(negotiateUrl, negotiateRequestBody, negotiateRequestHeader);
        const { data } = negotiateResponse;
        if (data) {
          makeHubConnection(data.url, data.accessToken);
        } else {
          throw Error('Invalid response');
        }
      } catch (error) {
        console.error('Error: SignalR negotiation failed: ', error);
        setTimeout(makeSignalRConnection, 5000);
      }
    }
  }, [dealerId, makeHubConnection, userId]);
  useEffect(() => {
    makeSignalRConnection();
  }, [makeSignalRConnection]);

  useEffect(() => {
    async function startSignalRConnection2(): Promise<void> {
      async function start(): Promise<void> {
        if (signalRHubConnection) {
          try {
            await signalRHubConnection.start();
          } catch (error) {
            console.error('Error: Star signalRHub connection:', error);
            setTimeout(start, 5000);
          }
        }
      }
      if (signalRHubConnection) {
        start();

        signalRHubConnection.on('notification', (data: any) => {
          console.log("notification received :  ", data);
          const notify: Notification = {
            id: data.Id,
            guid: data.Guid,
            title: data.Title,
            message: data.Message,
            type: data.Type,
            userName: data.Username,
            isRead: data.IsRead,
            dealerId: data.DealerId,
            eventType: data.EventType,
            eventData: data.EventData,
          };
          dispatch(addNotification(notify));
          // if (data.EventType === EventTypes.NotificationEvents.TaskNotification) {
          
          // } else {
          //   dispatch(addNotification(data));
          // }
          if (data.Type === 'InProgress') {
          } else {
            dispatch(showSnackbar({ type: data.Type.toLowerCase(), message: data.Message }));

            if (data.Type === 'Success') {
              if (
                data.EventType === EventTypes.CustomerEvents.CustomerCreated ||
                data.EventType === EventTypes.CustomerEvents.CustomerUpdated ||
                data.EventType === EventTypes.CustomerEvents.CustomerDeleted
              ) {
                dispatch(SetSelectedCustomers([]));
                dispatch(UpdateSelectedCustomer(undefined));
                dispatch(fetchCustomers());
              } else if (data.EventType === EventTypes.CustomerPhoneEvents.PhoneValidated) {
                dispatch(SetSelectedCustomers([]));
                dispatch(UpdateSelectedCustomer(undefined));
                dispatch(fetchCustomers());
              }
            }
          }
        });

        signalRHubConnection.on('creditChange', (data: any) => {
          const messageCredits: number = data.EventData;
          dispatch(updateUserCredits(messageCredits));
        });

        signalRHubConnection.on('MessageReceived', (data: any) => {
          console.log(data);
        });

        signalRHubConnection.on('MessageSent', (data: any) => {
          console.log(data);
        });

        signalRHubConnection.on('ConversationRead', (data: any) => {
          console.log('ConversationRead', data);
        });

        signalRHubConnection.on('ConversationCreated', (data: any) => {
          console.log('ConversationCreated', data);
        });

        signalRHubConnection.on('ConversationUnread', (data: any) => {
          console.log('ConversationUnread', data);
        });

        signalRHubConnection.on('disconnected', () => console.log('disconnect'));

        signalRHubConnection.onclose(makeSignalRConnection);
      }
    }
    startSignalRConnection2();
  }, [dispatch, makeSignalRConnection, signalRHubConnection]);

  return signalRHubConnection;
}

export default useSignalRConnection;
