import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { SmallCircularProgress } from 'common/components/loaders';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { MessageTemplate } from 'components/broadcast/models/message-template';
import DepartmentFilterContext from 'components/layouts/DashboardLayout/department-filter-context';
import AppSnackbar from 'components/snackbar/snackbar';
import React, { useContext } from 'react';
import { useAppSelector } from 'app';

import useDepartments from '../department/useDepartments';
import EditQuickMessageDrawer from './edit-quick-message-drawer';
import NewQuickMessageDrawer from './new-quick-message-drawer';
import useStyles from './quick-messages-styles';

const QuickMessages: React.FC = () => {
  const classes: any = useStyles();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [quickMessages, setQuickMessages] = React.useState<MessageTemplate[]>([]);
  const [selectedQuickMessageId, setSelectedQuickMessageId] = React.useState(0);
  const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const departments = useDepartments(dmsDealerIdCrm, 0);
  const { selectedDepartmentIds } = useContext(DepartmentFilterContext);


  const fetchQuickMessages = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await BroadCastAPI.getMessageTemplates();
      setQuickMessages(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar({ type: 'error', message: 'Unable to fetch quick messages' });
    }
  }, [showSnackbar]);

  React.useEffect(() => {
    fetchQuickMessages();
  }, [fetchQuickMessages, selectedDepartmentIds]);

  function openAddQuickMessage(event: any): void {
    setShowCreateDrawer(true);
  }

  async function handleQuickMessageCreate(value: any, createMore: boolean): Promise<void> {
    try {
      const response = await BroadCastAPI.createMessageTemplate(value, value.departmentId);
      if (!response.id) throw new Error('Unable to quick message');
      showSnackbar({ type: 'success', message: 'Quick message created' });
      if (!createMore) {
        setShowCreateDrawer(false);
      }
      fetchQuickMessages();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to create quick message' });
      throw new Error('Unable to create quick message');
    }
  }
  async function handleQuickMessageSave(value: any): Promise<void> {
    try {
      await BroadCastAPI.updateMessageTemplate(value);
      showSnackbar({ type: 'success', message: 'Quick message updated' });
      setSelectedQuickMessageId(0);
      fetchQuickMessages();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to save quick message' });
    }
  }

  function onEditQuickMessage(id: number): void {
    setSelectedQuickMessageId(id);
  }

  async function deleteQuickMessage(id: number): Promise<void> {
    try {
      await BroadCastAPI.deleteMessageTemplate(id);
      showSnackbar({ type: 'success', message: 'Quick message deleted' });
      fetchQuickMessages();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'unable to delete quick message' });
    }
  }

  // const selectedDepartments = departments?.filter((x) => selectedDepartmentIds.indexOf(x.departmentId) >= 0) || [];

  return (
    <Paper square style={{ padding: '10px' }}>
      <Box mb={1} display="flex">
        <Box>
          <Button onClick={openAddQuickMessage} startIcon={<AddCircleIcon />} color="primary">
            Add Quick Message
          </Button>
        </Box>
        <Box flexGrow={1} />
      </Box>
      <Box>
        {loading ? (
          <Box height="500px" display="flex" justifyContent="center">
            <SmallCircularProgress />
          </Box>
        ) : (
          <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
            <Table stickyHeader id="quick-messages-table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Quick Message</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {quickMessages.map((quickMessage) => (
                  <TableRow key={quickMessage.id}>
                    <TableCell
                      className={classes.quickMessage}
                      onClick={(): void => onEditQuickMessage(quickMessage.id)}>
                      {quickMessage.title}
                    </TableCell>
                    <TableCell
                      className={classes.quickMessage}
                      onClick={(): void => onEditQuickMessage(quickMessage.id)}>
                      {quickMessage.body}
                    </TableCell>
                    <TableCell>{quickMessage.departmentName}</TableCell>
                    <TableCell>{quickMessage.departmentLocationName}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete quick message">
                        <IconButton onClick={(): Promise<void> => deleteQuickMessage(quickMessage.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <NewQuickMessageDrawer
        departments={departments || []}
        open={showCreateDrawer}
        onClose={(): void => setShowCreateDrawer(false)}
        onCreate={handleQuickMessageCreate}
      />
      <EditQuickMessageDrawer
        departments={departments || []}
        open={Boolean(selectedQuickMessageId)}
        quickMessageId={selectedQuickMessageId}
        onClose={(): void => setSelectedQuickMessageId(0)}
        onSave={handleQuickMessageSave}
      />
    </Paper>
  );
};

export default QuickMessages;
