import { Box, IconButton, makeStyles, Menu, Theme, Tooltip, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import MediaGalleryAPI from 'components/broadcast/api/mediaGallery-api';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import React, { ReactElement, useEffect, useState } from 'react';

const useImageLibraryModalStyle = makeStyles((theme: Theme): any => ({
  imageLibrary: {
    width: '350px',
    height: '350px',
    padding: theme.spacing(1, 2),
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'scroll',
  },
  thumb: {
    display: 'inline-flex',
    position: 'relative',
    marginBottom: 8,
    width: 100,
    height: 100,
    boxSizing: 'border-box',
    '&:hover': {
      '& $addOverlay': {
        opacity: 1,
      },
    },
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    borderRadius: 2,
    width: '100%',
    height: '100%',
  },
  addOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    height: '100%',
    width: '100%',
    transition: '.3s ease',
    background: 'rgba(0,0,0,0.8)',
  },
  deleteOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    height: '100%',
    width: '100%',
    transition: '.3s ease',
    background: 'rgba(0,0,0,0.8)',
  },
  crossBtn: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    backgroundColor: 'white',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
  },
  icon: {
    color: 'white',
    position: 'absolute',
    zIndex: 1000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

export default function ImageLibraryButton({
  onSelect,
}: {
  onSelect: (galleryImage: GalleryImage) => void;
}): ReactElement {
  const classes: any = useImageLibraryModalStyle();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  function handleOnImageSelect(galleryImage: GalleryImage): void {
    onSelect(galleryImage);
    handleClose();
  }

  useEffect(() => {
    async function fetchGalleryImages(): Promise<void> {
      if (anchorEl) {
        try {
          const galleryImageResponse = await MediaGalleryAPI.getImageGallery();
          setGalleryImages(galleryImageResponse);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchGalleryImages();
  }, [anchorEl]);

  return (
    <>
      <Tooltip title="Select From My Files">
        <IconButton style={{padding:0}} size="small" onClick={handleClick}>
          <InsertPhotoIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box px={1}>
          <Typography variant="h5">Recent Files</Typography>
        </Box>
        <Box className={classes.imageLibrary}>
          {galleryImages.map((galleryImage) => (
            <div key={galleryImage.id} className={classes.thumb}>
              <div className={classes.thumbInner}>
              {galleryImage.mimeType ==="application/pdf" ? 
               <img src="/pdf.png"  className={classes.img} alt={galleryImage.fileName} height="40px" /> 
               : 
               <img  className={classes.img} src={galleryImage.mediaUrl} alt={galleryImage.fileName} /> }
                <Box className={classes.addOverlay}>
          {galleryImage.mimeType ==="application/pdf" && <Typography variant="h5" style={{color:"white"}}>{galleryImage.fileName}</Typography>}
                  <IconButton className={classes.icon} onClick={(): void => handleOnImageSelect(galleryImage)}>
                    <AddCircleIcon />
                  </IconButton>
                </Box>
                <div className={classes.deleteOverlay}>
                  Confirm delete ?
                  <div>
                    <CheckIcon />
                    <ClearIcon />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Box>
      </Menu>
    </>
  );
}
