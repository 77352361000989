import { get, post} from 'api';
import config from 'config';

const BASE_URL = config.get('api.paymentSettings');
export interface IGravityPaymentResponse{
    dealerId:number;
    oid: string;
    authToken: string;
    isSandbox: boolean;
    allowTextToPay: boolean;
    secretPhrase:string;
    allowGiftCards : boolean;
    merchantName: string;
    merchantKey: string;
    locationName:string;
}
export interface IGravitySettings{
    paymentsData:IGravityPaymentResponse[];
}
export const GetPaymentSettings ={
     fetchPaymentSettings: async(dealerId : number ) : Promise<any> =>{
        try {
            let url = `${BASE_URL}/GetDealerCredential/${dealerId}`
            const { data } = await get<IGravityPaymentResponse>(url);
            return data;
        } catch (error) {
            return error
        } 
    },
    updatePaymentSettings: async(paymentSettings : any ) : Promise<any> =>{
        try {
            let url = `${BASE_URL}/DealerEmergePay`
            const { data } : any = await post(url, paymentSettings);
         return data;
        } catch (error) {
            return error
        } 
    }
}
export default GetPaymentSettings;

