/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app';
import DealerAPI from 'components/authentication/api/dealer-api';

interface DealerPhoneState {
  dealerId: number;
  autoRecharge: boolean;
  rechargeBalanceTo: number;
  messageCount: number;
  rechargeWhenBalanceLessThan: number;
  loading: boolean;
}

const initialState: DealerPhoneState = {
  dealerId: 0,
  messageCount: 0,
  autoRecharge: false,
  rechargeBalanceTo: 250,
  rechargeWhenBalanceLessThan: 250,
  loading: false,
};

const dealerPhone = createSlice({
  name: 'dealerPhone',
  initialState,
  reducers: {
    getDealerPhone(state, action: PayloadAction<DealerPhoneState>): void {
      if (action.payload) {
        state.autoRecharge = action.payload.autoRecharge;
        state.messageCount = action.payload.messageCount;
        state.dealerId = action.payload.dealerId;
        state.rechargeBalanceTo = action.payload.rechargeBalanceTo;
        state.rechargeWhenBalanceLessThan = action.payload.rechargeWhenBalanceLessThan;
      }
    },
    setDealerPhoneLoading(state, action: PayloadAction<boolean>): void {
      state.loading = action.payload;
    },
    setDealerCredits(state, action: PayloadAction<number>): void {
      state.messageCount = action.payload;
    },
  },
});

export const { getDealerPhone, setDealerCredits, setDealerPhoneLoading } = dealerPhone.actions;
export default dealerPhone.reducer;

export const fetchDealerPhone =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { dmsDealerId } = getState().user;
      const dealerPhone = await DealerAPI.getDealerPhoneSetting(dmsDealerId);
      dispatch(getDealerPhone({ ...dealerPhone, loading: false }));
    } catch (error) {}
  };
