import DateRangeIcon from '@material-ui/icons/DateRange';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PollIcon from '@material-ui/icons/Poll';
import ShortTextIcon from '@material-ui/icons/ShortText';
import SubjectIcon from '@material-ui/icons/Subject';

export enum QuestionType {
  shortText = 'shortText',
  longText = 'longText',
  email = 'email',
  phoneNumber = 'phoneNumber',
  nps = 'nps',
  date = 'date',
}
export const questionTypes: { [key in keyof typeof QuestionType]: { title: string; icon: any } } = {
  shortText: {
    title: 'Short text',
    icon: ShortTextIcon,
  },
  longText: {
    title: 'Long text',
    icon: SubjectIcon,
  },
  email: {
    title: 'Email',
    icon: EmailIcon,
  },
  phoneNumber: {
    title: 'Phone number',
    icon: PhoneIcon,
  },
  nps: {
    title: 'Opinion scale',
    icon: PollIcon,
  },
  date: {
    title: 'Date',
    icon: DateRangeIcon,
  },
};

export default questionTypes;
