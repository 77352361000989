import config from "config";

const CUSTOMER_BASE_URL = config.get('api.customer');
const MESSAGING_EXTERNAL_BASE_URL = config.get('api.messagingExternal');
const MESSAGING_BASE_URL = config.get('api.messaging');

export const RouteConstants = {
    GetCustomerPartAndModelNumber: `${CUSTOMER_BASE_URL}/GetCustomerPartAndModelNumber`,
    Messaging:{
        GetConversationDepartmentIds:`${MESSAGING_BASE_URL}/GetConversationDepartmentIds/{crmUserId}`
    }
 };