import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  MenuItem,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DealItem } from '../model/dealsInterfaces';
import { useAppSelector } from 'app';
import { useFormik } from 'formik';
import { LostReason } from '../../setting/models/deal-pipelines';
import AppSnackbar from 'components/snackbar/snackbar';
import LostDealAPI from 'components/setting/api/lost-deal-api';
import PipelineDeals from 'components/deals/apis/deals-api';
import useStyles from '../styles/deals-styles';
import { Editor } from '@tinymce/tinymce-react';
import { DealStatusEnum } from '../model/DealStatusEnum';
import config from 'config';
		
const textEditorApiKey = config.get('api.textEditor');

const init = {
  height: 200,
  convert_urls: true,
  plugins: 'lists',
  toolbar: `bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify`,
  menubar: false,
  branding: false,
  force_br_newlines: true,
  force_p_newlines: false,
  forced_root_block: '', // Needed for 3.x
};
export function LostDealDrawer({
  open,
  editCount,
  selectedDeals,
  renderDeals,
  onClose,
}: {
  open: boolean;
  selectedDeals: DealItem[];
  editCount: number;
  renderDeals: () => void;
  onClose(val: boolean): void;
}): ReactElement {
  const classes = useStyles();
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [lostReasons, SetLostReasons] = React.useState<LostReason[]>([]);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [editorLoader, setEditorLoader] = useState(true);

  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  useEffect(() => {
    GetLostReasons();
  }, []);

  async function GetLostReasons() {
    const response = await LostDealAPI.getLostReasons(dmsDealerIdCrm);
    SetLostReasons(response);
  }

  const formik = useFormik({
    initialValues: {
      deals: selectedDeals,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const lostDeals = [...selectedDeals];
      if (values.deals.length > 0) {
        lostDeals.map((data, _index) => {
          values.deals.map((val, index) => {
            if (_index === index) {
              data.lostNote = val.lostNote;
              data.isArchiveTask = val.isArchiveTask === null ? false : val.isArchiveTask;
              data.lostReasonId = val.lostReasonId;
            }
          });
        });
      }
      if (lostDeals && lostDeals.length > 0) {
        const unselectedLostReason = lostDeals.find((x) => !x.lostReasonId);
        if (unselectedLostReason) {
          showSnackBar({
            type: 'error',
            message: `Select lost reason for seleted ${lostDeals.length == 1 ? 'deal' : 'deals'}`,
          });
          return;
        }

        await PipelineDeals.PipelineDeals.UpdateDealsLostStatus(lostDeals).then((response) => {
          if (response === 'Success') {
            onClose(false);
            renderDeals();
            showSnackBar({ type: 'success', message: `${editCount > 1 ? 'Deals' : 'Deal'} Updated Successfully` });
          } else {
            onClose(false);
            showSnackBar({ type: 'error', message: 'Something Went Wrong' });
          }
        });
      }
    },
  });

  return (
    <Drawer anchor={'right'} open={open} onClose={() => onClose(false)}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.danger} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Edit {editCount + (editCount > 1 ? ' Deals' : ' Deal')}: Mark All Lost</Typography>
          </Box>
          <IconButton id="closeBtn" onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />

        <Container maxWidth="sm">
          <Box mt={1}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Lost).length > 0}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => formik.handleSubmit()}>
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                {selectedDeals.map((deal, index) => {
                  return (
                    <>
                      <form onSubmit={formik.handleSubmit}>
                        <Box key={deal.id} mt={2} mb={2}>
                          <Typography variant="h4"> {deal.title}</Typography>
                          <Typography variant="body1">{deal.customer}</Typography>

                          <TextField
                            fullWidth
                            select
                            margin="dense"
                            variant="outlined"
                            name={`deals[${index}].lostReasonId`}
                            onChange={formik.handleChange}
                            inputProps={{ id: 'lostReasonId' }}
                            label="Lost Reason"
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  className: classes.muiPopoverPaper,
                                },
                              },
                            }}
                            disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Lost).length > 0}
                            value={formik.values.deals[index] && formik.values.deals[index].lostReasonId}
                            required>
                            {lostReasons.map((row) => {
                              return (
                                <MenuItem key={row.id} value={row.id}>
                                  {row.reason}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Box className={classes.textFieldPadding}>
                            {editorLoader && (
                              <div
                                style={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress size="2rem" />
                              </div>
                            )}
                            <Editor
                              value={formik.values.deals[index] && formik.values.deals[index].lostNote}
                              disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Lost).length > 0}
                              onEditorChange={(e) => {
                                formik.handleChange({
                                  target: {
                                    name: `deals[${index}].lostNote`,
                                    value: e,
                                  },
                                });
                              }}
                              onInit={() => {
                                setEditorLoader(false);
                              }}
                              apiKey={textEditorApiKey ||''}
                              init={init}></Editor>
                          </Box>
                          <Typography>
                            Archive scheduled tasks{' '}
                            <Switch
                              id={`deals[${index}].id`}
                              name={`deals[${index}].isArchiveTask`}
                              disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Lost).length > 0}
                              checked={formik.values.deals[index] && formik.values.deals[index].isArchiveTask}
                              onChange={formik.handleChange}
                            />
                          </Typography>
                        </Box>
                        <Divider style={{ height: '3px', background: '#a9a9a9' }} />
                      </form>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
