import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StarIcon from '@material-ui/icons/Star';
import React, { ReactElement, useState } from 'react';
import { useAppSelector } from 'app';
import config from 'config';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';

import useLocations from 'common/hooks/useLocations';
import { Videocam } from '@material-ui/icons';
import MessagingAPI from 'components/messaging/api/messaging-api';


export function VideoCallWidget({
  disabled,
  conversationId,
  onClick,
}: {
  disabled: boolean;
  conversationId: number;
  onClick: (shortenUrl: string) => void;
}): ReactElement {

  const [loadingState, setLoadingState] = React.useState<boolean>(false);
  const appUrl = config.get('app.url');
  const user = useAppSelector((state) => state.user);
  async function handleShortenUrl(): Promise<void> {
    setLoadingState(true);
    MessagingAPI.getVideoCallUrl(conversationId, user.crmUserId, "Customer").then(async res => {
        const url = res;
      setLoadingState(false);
      onClick(url);
    }).catch((err: any) => {
      setLoadingState(false);
      console.log(err);
    });
  }
  return (
    <>
      <Tooltip title="Add Video Call">
        <IconButton
          style={{ padding: 0 }}
          id="addVideoUrlBtn"
          size="small"
          onClick={() => handleShortenUrl()}
          disabled={disabled}>
          {loadingState ? <CircularProgress size={15} /> : <Videocam />}
        </IconButton>
      </Tooltip>
    </>
  );
}

export default VideoCallWidget;
