import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { Survey } from 'components/survey/models';
import { LocationOption } from 'common/models/location-option';
import { ReactElement} from 'react';
import { FormControl,InputLabel, Select } from '@mui/material';

const useSurveySettings = makeStyles(() => ({
  paper: {
    minHeight: '500px',
    paddingBottom: '10px',
  },
  customSelect: {
    '& .MuiSelect-select': {
      fontSize: '14px !important',
      fontFamily: 'Quicksand, sans-serif !important',
      fontWeight: '400 !important',
      lineHeight: '21px !important',
      letterSpacing: '-0.05px !important'
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const surveyHourSeedData = [
  { label: '12 AM', value: '00' },
  { label: '1 AM', value: '01' },
  { label: '2 AM', value: '02' },
  { label: '3 AM', value: '03' },
  { label: '4 AM', value: '04' },
  { label: '5 AM', value: '05' },
  { label: '6 AM', value: '06' },
  { label: '7 AM', value: '07' },
  { label: '8 AM', value: '08' },
  { label: '9 AM', value: '09' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '1 PM', value: '13' },
  { label: '2 PM', value: '14' },
  { label: '3 PM', value: '15' },
  { label: '4 PM', value: '16' },
  { label: '5 PM', value: '17' },
  { label: '6 PM', value: '18' },
  { label: '7 PM', value: '19' },
  { label: '8 PM', value: '20' },
  { label: '9 PM', value: '21' },
  { label: '10 PM', value: '22' },
  { label: '11 PM', value: '23' },
];

export default function SurveySettings({
  survey,
  locationOptions,
  onChange,
}: {
  survey: Survey;
  locationOptions?: LocationOption[];
  onChange(event: any): void;
}): ReactElement | null {
  const classes: any = useSurveySettings();
  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar variant="dense">Settings</Toolbar>
        <Divider />
        <Container maxWidth="md">
          <Box mt={2}>
            <TextLimitField
              disabled={survey.type === 'Auto' ? !survey.isActive : survey.isSent}
              characters={100}
              fullWidth
              autoFocus
              margin="dense"
              variant="outlined"
              name="title"
              id="title"
              label="Title"
              onChange={onChange}
              placeholder="Survey Title"
              value={survey.title}
              required
            />
            <TextLimitField
              disabled={survey.type === 'Auto' ? !survey.isActive : survey.isSent}
              fullWidth
              characters={500}
              margin="dense"
              variant="outlined"
              name="description"
              id="description"
              label="Description"
              multiline
              rows={4}
              onChange={onChange}
              value={survey.description}
            />
            <TextField
              disabled={survey.isSent}
              fullWidth
              select
              margin="dense"
              variant="outlined"
              name="type"
              id="type"
              label="Type"
              onChange={onChange}
              value={survey.type}
              required>
              <MenuItem value="Nps">Broadcast Loyalty Survey</MenuItem>
              <MenuItem value="Auto">Automated Loyalty Survey</MenuItem>
            </TextField>

            <TextField
              fullWidth
              select
              margin="dense"
              variant="outlined"
              name="dealerLocationId"
              inputProps={{ id: 'dealerLocationId' }}
              label="Location"
              onChange={onChange}
              required
              disabled={survey.isSent}
              value={survey.dealerLocationId}>
              {locationOptions &&
                locationOptions.map((location) => (
                  <MenuItem
                    style={{ textTransform: 'capitalize' }}
                    key={location.dealerLocationId}
                    value={location.dealerLocationId}>
                    {location.name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          {survey.type === 'Auto' && (
            <Box mt={2}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h5">Automation Setting</Typography>
                </Grid>
              </Grid>

              <Divider />
              <br />
              <Grid direction="row">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={survey.scheduleSetting.queryParts}
                        onChange={onChange}
                        name="queryParts"
                        color="primary"
                        disabled={!survey.isAutomaticSurvey}
                      />
                    }
                    label="Send to Part customers"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={survey.scheduleSetting.queryEquipment}
                        onChange={onChange}
                        name="queryEquipment"
                        color="primary"
                        disabled={!survey.isAutomaticSurvey}
                      />
                    }
                    label="Send to Equipment customers"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={survey.scheduleSetting.queryServices}
                        onChange={onChange}
                        name="queryServices"
                        color="primary"
                        disabled={!survey.isAutomaticSurvey}
                      />
                    }
                    label="Send to Service customers"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row">
                <Grid item xs={4}>
                  <Box pr={2}>
                  <FormControl 
                    fullWidth 
                    size="small" 
                    disabled={!survey.isAutomaticSurvey || survey.scheduleSetting.useDaylightTime}
                  >
                    <InputLabel>Start Hour</InputLabel>
                    <Select
                      value={survey.scheduleSetting.startingHour}
                      onChange={onChange}
                      label="Start Hour"
                      name="startingHour"
                      id="startingHour"
                      className={classes.customSelect}
                      MenuProps={MenuProps}
                    >
                      {surveyHourSeedData.map(option => (
                         <MenuItem 
                         key={option.value} 
                         value={option.value}
                         disabled={survey.scheduleSetting.endingHour !== '' && option.value >= survey.scheduleSetting.endingHour}
                         >
                         {option.label}
                       </MenuItem>
                      ))}
                      
                    </Select>
                  </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box pl={2} pr={2}>
                  <FormControl 
                    fullWidth 
                    size="small"
                    disabled={!survey.isAutomaticSurvey || survey.scheduleSetting.useDaylightTime}
                  >
                    <InputLabel>End Hour</InputLabel>
                    <Select
                      value={survey.scheduleSetting.endingHour}
                      onChange={onChange}
                      label="End Hour"
                      name="endingHour"
                      id="endingHour"
                      className={classes.customSelect}
                      MenuProps={MenuProps}
                    >
                      {surveyHourSeedData.map(option => (
                         <MenuItem 
                         key={option.value} 
                         value={option.value}
                         disabled={survey.scheduleSetting.startingHour !== '' && option.value <= survey.scheduleSetting.startingHour}
                         >
                         {option.label}
                       </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} >

                {/* Commenting for future use, in case they want to control the survey flow from UI */}
                {/* <FormControlLabel
                    control={
                      <Switch
                        checked={survey.scheduleSetting.useDaylightTime}
                        onChange={onChange}
                        name="useDaylightTime"
                        color="primary"
                      />
                    }
                   label="Use Daylight Time"
                  /> */}

                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Paper>
    </>
  );
}
