import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useAppSelector } from 'app';
import clsx from 'clsx';
import { SmallCircularProgress } from 'common/components/loaders';
import DealerPhoneSetting from 'common/models/dealer-phone-setting';
import { parseErrorMessage } from 'common/utils/error';
import DealerAPI from 'components/authentication/api/dealer-api';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import useStyles from './account-styles';
import AddCreditsDrawer from './add-credits-drawer';
import CreditHistoryTable from './credit-history-table';

const Account: React.FC = () => {
  const classes: any = useStyles();
  const dmsDealerId = useAppSelector((state) => state.user.dmsDealerId);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [isCreditDrawerOpen, setIsCreditDrawerOpen] = React.useState(false);

  const {
    isLoading: isDealerPhoneSettingLoading,
    data: dealerPhoneSetting,
    refetch: refetchDealerPhoneSetting,
    isError: isDealerPhoneSettingError,
  } = useQuery(['dealer-phone-setting', dmsDealerId], () => DealerAPI.getDealerPhoneSetting(dmsDealerId));

  const {
    isLoading: isBracketsLoading,
    data: brackets,
    isError: isBracketsError,
  } = useQuery('brackets', DealerAPI.getBrackets, { initialData: [] });

  const {
    isLoading: isUsageHistoryLoading,
    data: usageHistory,
    isError: isUsageHistoryError,
    error: usageHistoryError,
  } = useQuery(['usage-history', dmsDealerId], () => DealerAPI.getUsageHistory(dmsDealerId), {
    initialData: [],
  });

  const { isLoading: isUpdatingDealerCredits, mutateAsync: updateDealerCredits } = useMutation(
    async (credits: number) => await DealerAPI.updateCredits(credits, dmsDealerId),
  );

  const { isLoading: isUpdatingDealerPhoneSetting, mutate: updateDealerPhoneSetting } = useMutation(
    async ({ dmsDealerId, values }: { dmsDealerId: number; values: DealerPhoneSetting }) =>
      DealerAPI.updateDealerPhoneSetting(dmsDealerId, values),
  );

  const formik = useFormik<DealerPhoneSetting>({
    initialValues: {
      dealerId: dealerPhoneSetting?.dealerId || 0,
      autoValidate: dealerPhoneSetting?.autoValidate || false,
      messageCount: dealerPhoneSetting?.messageCount || 0,
      dealerLocationNickName: dealerPhoneSetting?.dealerLocationNickName || '',
      autoRecharge: dealerPhoneSetting?.autoRecharge || false,
      rechargeBalanceTo: dealerPhoneSetting?.rechargeBalanceTo || 0,
      rechargeWhenBalanceLessThan: dealerPhoneSetting?.rechargeWhenBalanceLessThan || 0,
    },
    onSubmit(values: DealerPhoneSetting) {
      updateDealerPhoneSetting({ dmsDealerId, values });
    },
    enableReinitialize: true,
  });

  const handleUpdateDealerCredits = async ({ credits }: { credits: number }) => {
    setIsCreditDrawerOpen(false);
    await updateDealerCredits(credits);
    await refetchDealerPhoneSetting();
  };

  const handleCreditDrawerOpen = (): void => {
    setIsCreditDrawerOpen(true);
  };

  const handleCloseCreditDrawer = (): void => {
    setIsCreditDrawerOpen(false);
  };

  React.useEffect(() => {
    updateDealerPhoneSetting({ dmsDealerId, values: formik.values });
  }, [formik.values, updateDealerPhoneSetting, dmsDealerId]);

  return (
    <Box>
      <Paper className={fixedHeightPaper}>
        {isBracketsLoading || isDealerPhoneSettingLoading ? (
          <Box display="flex" mt={1} justifyContent="center">
            <SmallCircularProgress />
          </Box>
        ) : (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box display="flex" justifyContent="center" my={2} flex={1}>
                <Typography variant="h5">Message Credits Remaining</Typography>
              </Box>
              <Box display="flex" justifyContent="center" my={1}>
                <Typography variant="h3" id="remainingCredits">
                  {isUpdatingDealerCredits ? <SmallCircularProgress /> : formik.values.messageCount}
                  <Tooltip
                    title="Message credits are used for SMS and MMS messages, as well as contact validation."
                    aria-label="add">
                    <HelpOutlineIcon />
                  </Tooltip>
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" my={1}>
                <Button
                  id="addCreditsBtn"
                  color="primary"
                  variant="contained"
                  size="medium"
                  style={{ width: '150px' }}
                  onClick={handleCreditDrawerOpen}>
                  Add Credits
                </Button>
              </Box>
              <Box display="flex" justifyContent="center" my={1}>
                <Typography variant="h3">
                  <FormControlLabel
                    control={
                      <Switch
                        id="autoValidateToggle"
                        checked={formik.values.autoValidate}
                        onChange={formik.handleChange}
                        name="autoValidate"
                        color="primary"
                      />
                    }
                    label="Auto Validate Phone Numbers"
                    labelPlacement="start"
                  />
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" my={1}>
                <Typography variant="h3">
                  <FormControlLabel
                    control={
                      <Switch
                        id="autoRechargeToggle"
                        checked={formik.values.autoRecharge}
                        onChange={formik.handleChange}
                        name="autoRecharge"
                        color="primary"
                      />
                    }
                    label="Auto Recharge"
                    labelPlacement="start"
                  />
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={5} lg={4}>
                    <Box display="flex">
                      <Box mr={1} display="flex" alignItems="center">
                        <Typography variant="subtitle1">Recharge Balance to :</Typography>
                      </Box>
                      <Box>
                        <TextField
                          select
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          disabled={!formik.values.autoRecharge}
                          id="rechargeBalanceTo"
                          value={formik.values.rechargeBalanceTo}
                          name="rechargeBalanceTo"
                          onChange={formik.handleChange}
                          error={!!(formik.touched.rechargeBalanceTo && formik.errors.rechargeBalanceTo)}
                          helperText={formik.touched.rechargeBalanceTo ? formik.errors.rechargeBalanceTo : ''}>
                          {brackets?.map((x) => {
                            return (
                              <MenuItem key={x.id} value={x.creditsToRecharge}>
                                {x.creditsToRecharge}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5} lg={4}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <Typography variant="subtitle1">When Balance falls below :</Typography>
                      </Box>
                      <Box>
                        <TextField
                          select
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          disabled={!formik.values.autoRecharge}
                          id="rechargeWhenBalanceLessThan"
                          name="rechargeWhenBalanceLessThan"
                          onChange={formik.handleChange}
                          value={formik.values.rechargeWhenBalanceLessThan}
                          label="RechargeValue">
                          <MenuItem value={250}>250</MenuItem>
                          <MenuItem value={1250}>1250</MenuItem>
                          <MenuItem value={2000}>2000</MenuItem>
                          <MenuItem value={3000}>3000</MenuItem>
                          <MenuItem value={5000}>5000</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2} lg={1}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Button
                        fullWidth
                        id="saveBtn"
                        color="primary"
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={isUpdatingDealerPhoneSetting}>
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Paper>
      <Box mt={1} />
      <Paper className={fixedHeightPaper}>
        <CreditHistoryTable
          usageHistory={usageHistory}
          isLoading={isUsageHistoryLoading}
          isError={isUsageHistoryError}
          errorMessage={parseErrorMessage(usageHistoryError)}
        />
      </Paper>
      {isCreditDrawerOpen && (
        <AddCreditsDrawer
          open={isCreditDrawerOpen}
          brackets={brackets || []}
          onClose={handleCloseCreditDrawer}
          onSubmit={handleUpdateDealerCredits}
        />
      )}
    </Box>
  );
};

export default Account;
