import config from "config/convict-config";
import { get, post} from 'api';


const BASE_URL = config.get('api.customer');

export interface IRemotePaymentRequest{
    dealerId:number | undefined;
    fromDate:Date;
    toDate:Date;
    Page:number;
    PageSize:number;
}
export interface IGravityResultData {
  accountCardType: string
  accountEntryMethod: string
  accountExpiryDate: string
  amount: string
  amountBalance: string
  amountProcessed: string
  amountTaxed: string
  amountTipped: string
  approvalNumberResult: string
  avsResponseCode: string
  avsResponseText: string
  batchNumber: string
  billingName: string
  cashier: string
  consumerSurchargeFee: string
  createdOn: string
  customerId: string
  cvvResponseCode: string
  cvvResponseText: string
  externalTransactionId: string
  isPartialApproval: boolean
  maskedAccount: string
  resultMessage: string
  resultStatus: string
  transactionReference: string
  transactionType: string
  uniqueTransId: string
}
export interface IGravityPayMoreInfo{
  department:string;
  organization:string;
  paymentLink:string;
  invoiceId?:number;
}
export interface IGravityPaymentData{
    dealerId: number;
    memo:string;
    externalTransId: string;
    transactionReference: string;
    amount: number;
    cashierId: string;
    customerID: number;
    customerName: string;
    requestDate: Date;
    moreInfo: IGravityPayMoreInfo;
    paymentPageId: string;
    paymentPageUrl: string;
    status: string;
    transactionDate: Date;
    amountProcessed: number;
    result: IGravityResultData;
    isCancelled: boolean
    cancelledDate: Date;
    lastUpdated: Date;
  }
export interface IRemotePaymentResponse{
    totalApprovedTransactions: number;
    totalDeclinedTransactions: number;
    totalAmountProcessed: number;
    remotePayments: {
      data: [
        IGravityPaymentData
      ],
      currentPage: number;
      pageCount: number;
      rowCount: number;
      pageSize: number;
      firstRowOnPage: number;
      lastRowOnPage: number;
    }
}
export const PaymentApi ={
     fetchRemotePayments: async(remotePaymentRequest: IRemotePaymentRequest ) : Promise<any> =>{
        try {
            let url = `${BASE_URL}/fetchRemotePayments`
            const { data } = await post<IRemotePaymentResponse>(url,remotePaymentRequest);
            return data;
        } catch (error) {
            return error
        } 
    },
}
export default PaymentApi;

