import { get, put, post, patch } from 'api';
import axios, { Canceler } from 'axios';
import config from 'config';
import { Conversation } from '../models/conversation';
import { Message } from '../models/message';
import { ArchiveRequest } from '../models/request/archiveRequest';
import { RouteConstants } from 'common/utils/shared/routes-constants';

const messagingUrl = config.get('api.messaging');
const messagingUrlExternal = config.get('api.messagingExternal');
const messaging2Url = config.get('api.messaging2');


let messagingApiCancel: Canceler | undefined;

interface GetConversationResponse {
  isMessageRemaining: boolean;
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  continuationToken: string | null;
  messages: Message[];
}

interface SendMessageRequest {
  conversationId: number;
  messageBody: string;
  messageType: string;
  media?: string[];
  messageBodyRef?: string;
  refid?: string;
  version?: string; 
}

interface VideoCallResponse
{
  token : string;
  name : string;
  type : string;
  crmUserId : string;
  crmCustomerId : number;

}

interface GetConversationsResponse {
  pageNumber: number;
  pageSize: number;
  isRemaining: boolean;
  conversations: Conversation[];
}

interface ConversationMessageRequest {
  continuationToken: string | null;
  pageSize: number;
  pageNumber: number;
}

interface SearchConversationMessageRequest {
  messageSid: string;
  pageSize: number;
  pageNumber: number;
  conversationId: string;
}
export interface GravityPaymentMessageBody{
  memo:string;
  Id:string;
  amount:string;
  urlShortner:string;
  paymentPageId:string;
  paymentStatus:string;
}
export interface GravityPaymentDetails{
  id: string;
  conversationId:number;
  status:string;
  dateCreated:string;
  messageSid:string;
  messageBody:GravityPaymentMessageBody;
}

export interface GravityPaymentCancelOrVoid{
  paymentPageId: string,
  conversationMessageId?:string,
  conversationId?:number,
  transactionReferenceId:string,
  messageSid?:string,
  uniqueTransId?:string,
  dealerId?: number,
  paymentType?:'canceled'|'voided'
}
export interface BaseResponse<T>{
  data:T;
  isSuccess:Boolean;
  message:string;
  error:string;
}
const MessagingAPI = {
  assignConversation: async (
    conversationIds: number[],
    departmentId: number,
    userId: string | null
  ): Promise<string> => {
    const url = `${messagingUrl}/messaging/assign`;
    const { data } = await patch(url, { conversationIds, departmentId, crmUserId: userId });
    return data;
  },
  unAssignConversation: async (
    conversationIds: number[],
    departmentId: number,
    userId: string | null
  ): Promise<string> => {
    const url = `${messagingUrl}/messaging/unAssign`;
    const { data } = await patch(url, { conversationIds, departmentId, crmUserId: userId });
    return data;
  },
  getConversations: async (searchText: string, pageNumber: number, pageSize: number, conversationTypes: string, conversationFilters: string[], sortingOrder:string): Promise<GetConversationsResponse> => {
    const url = `${messagingUrl}/v2/messaging/searchV2?IsCallFromMobile=false`;
    let reqObj = { SearchString: searchText, pageNumber: pageNumber, pageSize: pageSize, ConvType: conversationTypes, Filters: conversationFilters, SortingOrder: sortingOrder };
    const { data } = await post<GetConversationsResponse>(url, reqObj);
    return data;
  },
  getConversationsForMerge: async (searchText: string, pageNumber: number, pageSize: number,
    conversationTypes: string,sortingOrder:string,phoneNumberId: number,departmentIds:string): Promise<GetConversationsResponse> => {
    const url = `${messagingUrl}/GetConversationsForMerge?IsCallFromMobile=false`;
    let reqObj = { 
      SearchString: searchText,
      pageNumber: pageNumber,
      pageSize: pageSize,
      ConvType:conversationTypes,
      SortingOrder: sortingOrder,
      PhoneNumberId:phoneNumberId,
      DepartmentIds:departmentIds };
    const { data } = await post<GetConversationsResponse>(url, reqObj);
    return data;
  },
  mergeConversations: async (
    newConversationId:number,
    fromNumber:string,
    toNumber:string,
     conversationIdsToMerge: number[]
  ): Promise<BaseResponse<Boolean>> => {
   const url = `${messagingUrl}/MergeConversation?IsCallFromMobile=false`;
   let reqObj = { 
    NewConversationId:newConversationId,
    FromNumber:fromNumber,
    ToNumber:toNumber,
    ConversationIdsToMerge: conversationIdsToMerge
  };
   const { data } = await post<BaseResponse<Boolean>>(url, reqObj);
   return data;
 },
 getArchiveConversation: async (conversationId: number): Promise<BaseResponse<Conversation>> => {
  const url = `${messagingUrl}/GetArchiveConversation/${conversationId}?IsCallFromMobile=false`;
  const { data } = await get(url);
  return data;
},
  getConversation: async (conversationId: number): Promise<Conversation> => {
    const url = `${messagingUrl}/GetConversation/${conversationId}?IsCallFromMobile=false`;
    const { data } = await get(url);
    return data;
  },
  getConversationsByPhoneNumberId: async (phoneNumberId: number): Promise<Conversation[]> => {
    const url = `${messagingUrl}/conversationByPhoneNumberId/${phoneNumberId}?IsCallFromMobile=false`;
    const { data } = await get(url);
    return data;
  },
  createConversation: async (
    phoneNumber: string,
    departmentId: number,
    customerName: string,
    phoneNumberId: number
  ): Promise<Conversation> => {
    const url = `${messagingUrl}/createConversationV2`;
    const { data } = await post<Conversation>(url, {
      phoneNumber,
      departmentId,
      customerName,
      phoneNumberId,
    });
    return data;
  }, 
  sendMessage: async (
    conversationId: number,
    messageBody: string,
    messageType: string,
    media?: string[],
    messageBodyRef?: string,
    refid?: string
  ): Promise<{ message: Message; conversation: Conversation }> => {
    const url = `${messagingUrl}/SendMessage?IsCallFromMobile=false`;
    const requestBody: SendMessageRequest = {
      conversationId,
      messageBody,
      messageType,
      media,
      messageBodyRef,
      refid,
      version: 'v2',
    };
    const data = post(url, requestBody).then(x => {
      console.log("err", x);
      if (x && x.data && x.data.toString().toLowerCase().indexOf("customer cell phone not found") > -1) {
        console.log("throwing error", x);
        throw new Error(x.data.toString());
      }
      return x;
    })
      .catch(error => {
        console.log("throwing error", error);
        if (error.message == 'Attempt to send to unsubscribed recipient')   
          error.message = "Unable to send to unsubscribed customer."

        throw new Error(error ? error.message : "Unable to send message");
        });
    return (await data).data
  },
  markConversationRead: async (conversationId: number): Promise<Conversation> => {
    const url = `${messagingUrl}/MarkConversationReadV2?IsCallFromMobile=false`;
    const requestBody = {
      conversationId,
    };
    const { data } = await put<Conversation>(url, requestBody);
    return data;
  },
  markConversationUnRead: async (conversationIds: number[]): Promise<Conversation> => {
    const url = `${messagingUrl}/MarkConversationUnRead`;
    const requestBody = {
      conversationIds,
    };
    const { data } = await put<Conversation>(url, requestBody);
    return data;
  },
  markConversationArchive: async (archiveRequest:ArchiveRequest): Promise<BaseResponse<Boolean>> => {
    const url = `${messagingUrl}/MarkConversationArchive`;
    const requestBody = {
      ...archiveRequest
      };
    const { data } = await put<BaseResponse<Boolean>>(url, requestBody);
    return data;
  },
  getUnreadConversationCount: async (dmsTypeId: number): Promise<{ count: number }> => {
    let apiRoute = 'unreadConversationsCountInfinity';
    if (dmsTypeId === 0) apiRoute = 'unreadConversationsCountIdeal';
    else if (dmsTypeId === 9) apiRoute = 'unreadConversationsCountAspen';

    const url = `${messaging2Url}/${apiRoute}`;
    const { data } = await get(url);
    return data;
  },
  getSearchConversationMessages: async (
    conversationId: string,
    messageSid: string | undefined,
    pageNumber: number,
    pageSize: number
  ): Promise<GetConversationResponse> => {
    const url = `${messagingUrl}/GetSearchConversationMessage?IsCallFromMobile=false`;
    let msid: string = (messageSid === undefined ? '' : messageSid);
    const requestBody: SearchConversationMessageRequest = {
      messageSid: msid,
      conversationId: conversationId,
      pageNumber: pageNumber,
      pageSize: pageSize
    };
    const { data } = await post(url, requestBody);
    return data;
  },
  cancelRequest: (): void => {
    if (messagingApiCancel) {
      messagingApiCancel();
    }
  },
  getVideoCallToken: async (s: string): Promise<VideoCallResponse> => {
    let body = { s: s };
    const url = `${messagingUrlExternal}/VideoCall`;
    const { data } = await post(url, body);
    return data;
  },
  getVideoCallUrl: async (conversationId: number, crmUserId: string, type = "dealer"): Promise<string> => {
    const url = `${messagingUrl}/GetVideoCallUrl?IsCallFromMobile=false`;
    let body = { conversationId: conversationId, type: type, crmUserId: crmUserId };
    const { data } = await post(url, body);
    return data;
  },
  cancelGravityPayment: async (request:GravityPaymentCancelOrVoid): Promise<Boolean> => {
    const url = `${messagingUrl}/TextToPayCancelPayment`;
    const { data } = await post(url,request);
    return data;
  },
  voidGravityPayment: async (request:GravityPaymentCancelOrVoid): Promise<Boolean> => {
    const url = `${messagingUrl}/GravityPaymentVoidPayment`;
    const { data } = await post(url,request);
    return data;
  },
  getDepartmentIds: async (crmUserId:string): Promise<BaseResponse<number[]>> => {
    const { data } = await get<BaseResponse<number[]>>(RouteConstants.Messaging.GetConversationDepartmentIds.replace("{crmUserId}",crmUserId));
    return data;
  }
  
};

export default MessagingAPI;
