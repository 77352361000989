import { get, post, put } from 'api';
import config from 'config';
import { CustomerTagTitle } from '../models/customer-tag-title';

const dealerUrl = config.get('api.dealer');
const CustomerSettingsApi = {
   async getCustomerTags(): Promise<CustomerTagTitle[]>{
     const url = `${dealerUrl}/dealers/getCustomerTagTitles`;
     const {data} = await get(url)
     return data;
   },
   async updateCustomerTags(payload: CustomerTagTitle): Promise<boolean>{
    const url = `${dealerUrl}/dealers/updateCustomerTags`;
    const { data } = await put(url, payload);
    return data;
   },
   async insertCustomerTagSetting(payload: CustomerTagTitle): Promise<boolean> {
    const url = `${dealerUrl}/dealers/insertCustomerTagSetting`;
    const { data } = await post(url, payload);
    return data;
   }
}

export default CustomerSettingsApi;