import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import { ReactElement } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

const useUploadedImagesStyle = makeStyles((theme: Theme): any => ({
  uploadedImages: {
    overflowX: 'auto',
    display: 'flex',
  },
  thumb: {
    display: 'inline-flex',
    position: 'relative',
    margin: '10px 5px',
    width: '100px',
    height: '100px',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    padding: 2,
    border: `1px solid ${theme.palette.divider}`,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    borderRadius: 2,
    width: '100%',
    height: '100%',
  },
  crossBtn: {
    position: 'absolute',
    cursor: 'pointer',
    top: '-10px',
    right: '-10px',
    backgroundColor: 'white',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
  },
}));

export function UploadedImages({
  galleryImages,
  loading,
  onRemove,
  progressCompleted
}: {
  galleryImages: GalleryImage[];
  loading: boolean;
  onRemove(galleryImage: GalleryImage): void;
  progressCompleted: number;
}): ReactElement {
  const classes: any = useUploadedImagesStyle();
  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box className={classes.uploadedImages}>
      {!loading ? (
        galleryImages.map((file) => (
          <div className={classes.thumb} key={file.fileName}>
            <div className={classes.thumbInner}>
              {file.mimeType === 'application/pdf' ? (
                <img src="/pdf.png" className={classes.img} alt={file.fileName} height="40px" />
              ) : (
                <img src={file.mediaUrl} className={classes.img} alt={file.fileName} />
              )}
              <Tooltip title={`Remove ${file.id}`}>
                <Box className={classes.crossBtn} onClick={(): void => onRemove(file)}>
                  <CancelIcon />
                </Box>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <Box width="100%">
          <Typography variant="subtitle1">Uploading files</Typography>
          <LinearProgressWithLabel value={progressCompleted} />
        </Box>
      )}
    </Box>
  );
}
export default UploadedImages;
