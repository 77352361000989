import { Box, CircularProgress, Container, Grid, makeStyles, MenuItem, Paper, TablePagination, TextField, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAppSelector } from 'app';

import { PageHeader } from 'common/components/layout';
import { useRouter } from 'common/hooks';
import useLocations from 'common/hooks/useLocations';
import { SurveyListItem } from 'components/survey/models';
import { LocationOption } from 'common/models/location-option';
import AppSnackbar from 'components/snackbar/snackbar';
import SurveyAPI from 'components/survey/api/survey-api';

import { SurveyPreview } from '../survey-preview';
import { NewSurveyCard } from './new-survey-card';
import { NewSurveyDrawer } from './new-survey-drawer';
import { NewSurveyFormValues } from './new-survey-drawer/new-survey-form';
import { SurveyCard } from './survey-card';

type StatusFilter = 'All' | 'Drafted' | 'Sent' | 'Deactivated';
type StatusOption = {
  value: StatusFilter;
  label: string;
};

function SurveysHeader({
  status,
  location,
  locationLoading,
  statusOptions,
  locationOptions,
  onLocationChange,
  onStatusChange,
}: {
  status: StatusFilter;
  location?: number;
  locationLoading: boolean;
  statusOptions: StatusOption[];
  locationOptions?: LocationOption[];
  onLocationChange(dealerLocationId: number): void;
  onStatusChange(status: StatusFilter): void;
}): ReactElement {
  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1}>
        <PageHeader title="Surveys" />
      </Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          size="small"
          margin="dense"
          variant="outlined"
          name="location"
          label="Location"
          value={location}
          disabled={locationLoading}
          inputProps={{ id: 'location' }}
          onChange={({ target: { value } }): void => onLocationChange(parseInt(value, 10))}>
          {locationOptions &&
            locationOptions.map((loc) => (
              <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                {loc.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          margin="dense"
          variant="outlined"
          name="status"
          label="Status"
          value={status}
          inputProps={{ id: 'type' }}
          onChange={({ target: { value } }): void => onStatusChange(value as StatusFilter)}>
          {statusOptions.map((st) => (
            <MenuItem key={st.value} value={st.value}>
              {st.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function SurveysPage(): ReactElement {
  const classes: any = useStyles();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const router = useRouter();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);

  const {
    loading: locationLoading,
    locations,
    defaultLocationOption,
    locationOptions,
    getLocationName,
  } = useLocations(dmsDealerIdCrm);
  const dealerId = useAppSelector((state) => state.user.dealerId);

  const [status, setStatus] = React.useState<StatusFilter>('All');
  const [dealerLocationId, setDealerLocationId] = React.useState<number | undefined>(
    defaultLocationOption?.dealerLocationId,
  );

  React.useEffect(() => {
    setDealerLocationId(defaultLocationOption?.dealerLocationId);
  }, [defaultLocationOption]);

  const [surveys, setSurveys] = React.useState<SurveyListItem[] | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedSurveyId, setSelectedSurveyId] = React.useState<number | undefined>(undefined);
  const [showNewSurveyDrawer, setShowNewSurveyDrawer] = React.useState<boolean>(false);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [totalRows, setTotalRows] = React.useState<number>(-999);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(17);

  const statusOptions: StatusOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Drafted', label: 'Drafts' },
    { value: 'Sent', label: 'Sent' },
    { value: 'Deactivated', label: 'Recycle bin' },
  ];

  const fetchSurveys = async () : Promise<void>  => {
    if (dealerLocationId !== undefined && dealerLocationId >= 0) {
      try {
        setLoading(true);
        const response = await SurveyAPI.getSurveysByDealerId(dealerId, status, rowsPerPage, pageNumber, dealerLocationId);
        setLoading(false);
        setSurveys(response.surveys);
        setTotalRows(response.total);
      } catch (error) {
        setLoading(false);
        showSnackbar({ type: 'error', message: 'Unable to fetch surveys' });
      }
    }
  }


  React.useEffect(() => {
    setSurveys([]);
    fetchSurveys();
  }, [dealerId, dealerLocationId, status, pageNumber]);

  function handleNewSurvey(): void {
    setShowNewSurveyDrawer(true);
  }
  async function handleCreate(values: NewSurveyFormValues): Promise<void> {
    if (locations) {
      try {
        let surveyLocation = [];
        if (values.dealerLocationId === 0) {
          surveyLocation = locations
            .filter((x) => x.dealerId !== 0)
            .map((x) => ({
              dealerLocationId: x.dealerId,
              googleReviewUrl: x.googleLink,
              facebookReviewUrl: x.facebookLink,
            }));
        } else {
          surveyLocation = locations
            .filter((x) => x.dealerId === values.dealerLocationId)
            .map((x) => ({
              dealerLocationId: x.dealerId,
              googleReviewUrl: x.googleLink,
              facebookReviewUrl: x.facebookLink,
            }));
        }
        const response = await SurveyAPI.createSurvey({
          title: values.title,
          type: values.type,
          description: values.description,
          dealerId,
          dealerLocationId: values.dealerLocationId,
          isAllLocations: values.dealerLocationId === 0,
          locations: surveyLocation,
          queryEquipment: values.queryEquipment,
          queryParts: values.queryParts,
          queryServices: values.queryServices,
          startingHour: values.startingHour,
          endingHour: values.endingHour,
          delayForHours: 0,
        });
        router.push(`/surveys/${response.id}/designer#questions`);
        setShowNewSurveyDrawer(false);
      } catch (error) {
        setShowNewSurveyDrawer(false);
        showSnackbar({ type: 'error', message: 'Unable to create survey.' });
      }
    }
  }
  async function handleClick(surveyId: number): Promise<void> {
    router.push(`/surveys/${surveyId}/designer#questions`);
  }
  async function handleEdit(surveyId: number): Promise<void> {
    router.push(`/surveys/${surveyId}/designer`);
  }
  async function handlePreview(surveyId: number): Promise<void> {
    setSelectedSurveyId(surveyId);
  }
  async function handleDeactivate(surveyId: number): Promise<void> {
    try {
      await SurveyAPI.deactivateSurvey(surveyId);
      setSurveys([]);
      fetchSurveys();
      showSnackbar({ type: 'success', message: 'Survey deactivated' });
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to deactivate survey' });
    }
  }
  async function handleActivate(surveyId: number): Promise<void> {
    try {
      await SurveyAPI.activateSurvey(surveyId);
      setSurveys([]);
      fetchSurveys();
      showSnackbar({ type: 'success', message: 'Survey activated' });
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to activate survey' });
    }
  }
  async function handleDuplicate(surveyId: number): Promise<void> {
    try {
      await SurveyAPI.duplicateSurvey(surveyId);
      showSnackbar({ type: 'success', message: 'Survey duplicated' });
      setSurveys([]);
      fetchSurveys();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to duplicate survey' });
    }
  }
  async function handleResults(surveyId: number): Promise<void> {
    router.push(`/surveys/${surveyId}/designer#summary`);
  }

  function handleChangePage(_: unknown, newPage: number): void {
    setPageNumber(newPage);
  }

  let content = (
    <>
      {status !== 'Deactivated' && (
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <NewSurveyCard onClick={handleNewSurvey} />
        </Grid>
      )}
      {surveys &&
        surveys.map((survey) => (
          <Grid key={survey.id} item xs={12} sm={6} md={4} lg={2}>
            <SurveyCard
              survey={survey}
              getLocationName={getLocationName}
              onClick={handleClick}
              onDeactivate={handleDeactivate}
              onDuplicate={handleDuplicate}
              onEdit={handleEdit}
              onPreview={handlePreview}
              onActivate={handleActivate}
              onResults={handleResults}
            />
          </Grid>
        ))}
    </>
  );
  return (
    <Container className={classes.container} maxWidth="lg">
      <Paper style={{ padding: '10px 10px 0px 10px' }}>
        <SurveysHeader
          status={status}
          locationLoading={locationLoading}
          location={dealerLocationId}
          statusOptions={statusOptions}
          locationOptions={locationOptions}
          onLocationChange={(value: number): void => {
            setDealerLocationId(value);
            setPageNumber(0);
          }}
          onStatusChange={(value: StatusFilter): void => {
            setStatus(value);
            setPageNumber(0);
          }}
        />
        {loading ? (
          <Box style={{ textAlign: 'center', height: '70.7vh', overflow: 'hidden' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            mt={2}
            component="section"
            style={{ height: '69vh', padding: '0px 10px 0px 10px', overflow: 'hidden', overflowY: 'auto' }}>
            <Grid container style={{ paddingTop: '10px' }} spacing={2}>
              {content}
            </Grid>
          </Box>
        )}
        <SurveyPreview
          open={!!selectedSurveyId}
          onClose={(): void => setSelectedSurveyId(undefined)}
          surveyId={selectedSurveyId}
        />
        <NewSurveyDrawer
          open={showNewSurveyDrawer}
          selectedDealerLocationId={dealerLocationId}
          locationOptions={locationOptions}
          onCreate={handleCreate}
          onClose={(): void => setShowNewSurveyDrawer(false)}
        />
        {typeof surveys != 'undefined' && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={handleChangePage}
          />
        )}
      </Paper>
    </Container>
  );
}

export default SurveysPage;
