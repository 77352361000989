import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  FormControl,
  MenuItem,
  TablePagination,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app';
import { endOfWeek, startOfWeek, startOfYear, startOfMonth, endOfMonth, sub, format } from 'date-fns';
import { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  LinearProgress,
  Popover,
  ButtonGroup,
  makeStyles,
  createStyles,
  Link,
  IconButton,
} from '@material-ui/core';
import PaymentApi, { IGravityPaymentData, IRemotePaymentResponse } from './api/payments-api';
import useLocations from 'common/hooks/useLocations';
import MailIcon from '@mui/icons-material/Mail';
import AppSnackbar from 'components/snackbar/snackbar';
import { DateRangePickerDayProps } from '@mui/lab/DateRangePickerDay';
import DateRangePickerDay from '@mui/lab/DateRangePickerDay';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDateRangePicker } from '@mui/lab';
import { pickersDayClasses } from '@mui/lab/PickersDay';
import { fetchSelectedCustomer } from 'components/customer/components/Customers/customersSlice';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import moment from 'moment';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';
import MessengerDrawer from 'components/messaging/components/messenger-drawer';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import MessagingAPI, { GravityPaymentCancelOrVoid } from 'components/messaging/api/messaging-api';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { TablePaginationActions } from 'common/utils/tablePaginationUtil';
const useStyles = makeStyles(() =>
  createStyles({
    nameCell: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    buttonSelected: {
      backgroundColor: '#b0c4de',
    },
    mySwalPopup: {
      borderRadius: '10px !important',
    }
  }),
);
enum selectionFilterOptions {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  YTD = 'ytd',
}
enum paymentStatus {
  APPROVED = 'approved',
  CANCELED = 'cancelled',
  PENDING = 'pending',
  VOIDED = 'voided',
}
export function RemotePaymentPage() {
  const styleClass: any = useStyles();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dmsType = useAppSelector((state) => state.user.dmsTypeId);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [remotePayments, setRemotePayments] = useState<IRemotePaymentResponse>();
  const [openMessengerDrawer, SetOpenMessengerDrawer] = useState<boolean>(false);
  const [MessengerDrawerCustomerId, SetMessengerDrawerCustomerId] = useState<number>();
  const dispatch = useAppDispatch(); 

  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  let isCustomerPermission = 0;
  let isMessengerPermission = 0;
  if (!userPermissions) {
    userPermissions = [];
    isCustomerPermission = 2;
    isMessengerPermission = 2;
}
if (isCustomerPermission == 0) {
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
    isCustomerPermission = 1;
 } 
}
if (isMessengerPermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'messages')) {
        isMessengerPermission = 1;
    }
}

    // ----------------------- GetLOcations ---------------------------//
  const { loading: loadingLocations, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);
  const [locationFilter, setLocationFilter] = useState<number | undefined>(0);
 
  // -----------------------DATE HANDLERS ---------------------------//
  const [activeButton, setActiveButton] = useState<string | null>(selectionFilterOptions.TODAY);
  const [dateRange, setDateRange] = useState<[Date, Date]>([new Date(), new Date()]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    dateRange[0] && dateRange[1] && setAnchorEl(null);
  };
  const renderWeekPickerDay = (date: Date, dateRangePickerDayProps: DateRangePickerDayProps<Date>) => {
    return (
      <DateRangePickerDay
        sx={{
          [`&&.${pickersDayClasses.today}`]: {
            backgroundColor: '#939afa',
            color: 'white',
            border: '#939afa',
          },
        }}
        {...dateRangePickerDayProps}
      />
    );
  };
  const handleDateChange = (newValue: any) => {
    setDateRange(newValue);
  };

  // -----------------------PAGE HANDLERS ---------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  // -----------------------FETCH PAYMENTS HANDLERS ---------------------------//
  async function handleFetchRemotePayments(): Promise<void> {
    try {
      if (locationOptions && locationOptions[0].dealerLocationId !== -1) {
       if (locationOptions?.length == 1 ) {
        setLocationFilter( locationOptions[0].dealerLocationId)
       }

        setIsFetching(true);
        const response = await PaymentApi.fetchRemotePayments({
          dealerId: locationFilter === 0 ? undefined : locationFilter,
          fromDate: dateRange[0],
          toDate: dateRange[1],
          Page: page,
          PageSize: rowsPerPage,
        });
        setIsFetching(false);
        if (response) {
          const newResponse = { ...response };
          if (newResponse.remotePayments.data.length > 0) {
            const gravityResult = newResponse.remotePayments.data.map((item: any) => ({
              ...item,
              result: JSON.parse(item.result),
              moreInfo: JSON.parse(item.moreInfo),
            }));
            newResponse.remotePayments.data = gravityResult;
          }
          setRemotePayments(newResponse);
        }
      }
    } catch (error) {
      setIsFetching(false);
      showSnackBar({ type: 'error', message: 'unable to fetch payments' });
    }
  }
  useEffect(() => {
      handleFetchRemotePayments();
  }, [locationFilter, locationOptions, dateRange, page]);

  function handlePaymentCancelOrVoid(value: GravityPaymentCancelOrVoid) {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${value.paymentType === 'canceled' ? 'cancel' : 'void'} this payment!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes`,
      cancelButtonText: `Close`,
      showLoaderOnConfirm: true,
      width: '21em',
      customClass: { popup: `${styleClass.mySwalPopup}` },
      preConfirm: async () => {
        try {
          let response: Boolean = false;
          if (value.paymentType === 'canceled') {
            response = await MessagingAPI.cancelGravityPayment(value);
          } else {
            response = await MessagingAPI.voidGravityPayment(value);
          }
          if (!response) {
            Swal.fire({
              title: 'Error!',
              text: `Error occurred while ${value.paymentType === 'canceled' ? 'cancelling' : 'voiding'}.`,
              icon: 'error',
              customClass: { popup: `${styleClass.mySwalPopup}` },
            });
          }
          return response;
        } catch (error) {
          Swal.fire({
            title: 'Error!',
            text: `Error occurred while ${value.paymentType === 'canceled' ? 'cancelling' : 'voiding'}.`,
            icon: 'error',
            customClass: { popup: `${styleClass.mySwalPopup}` },
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `${value.paymentType === 'canceled' ? 'Cancelled' : 'Voided'}`,
          text: `Your payment has been ${value.paymentType === 'canceled' ? 'cancelled' : 'voided'}.`,
          icon: 'success',
          customClass: { popup: `${styleClass.mySwalPopup}` },
        });
        handleFetchRemotePayments();
      }
    });
  }
  return (
    <>
      <Container sx={{ marginTop: '10px', padding: '0px', minWidth: '1400px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" component="h1" gutterBottom>
            Payments
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            {/* Date range filter */}
            <Stack direction="row" alignItems="center" mr={4}>
              <TextField
                margin="dense"
                size="small"
                label="Date Range"
                disabled={loadingLocations}
                inputProps={{ readOnly: true }}
                onClick={(e: any) => {
                  handleClick(e);
                }}
                value={`${dateRange[0] ? dateRange[0].toLocaleDateString('en-US') : ''} − ${
                  dateRange[1] ? dateRange[1].toLocaleDateString('en-US') : ''
                }`}
                error={!dateRange[0] || !dateRange[1] ? true : false}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Box mt={1} ml={1}>
                      <ButtonGroup
                        orientation="vertical"
                        style={{ inlineSize: 'max-content' }}
                        aria-label="vertical outlined button group">
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.TODAY && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [new Date(), new Date()];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.TODAY);
                          }}>
                          Today
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.YESTERDAY && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [
                              sub(new Date(), { days: 1 }),
                              sub(new Date(), { days: 1 }),
                            ];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.YESTERDAY);
                          }}>
                          Yesterday
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.THIS_WEEK && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [startOfWeek(new Date()), endOfWeek(new Date())];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.THIS_WEEK);
                          }}>
                          This Week
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.LAST_WEEK && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [
                              startOfWeek(sub(new Date(), { weeks: 1 })),
                              endOfWeek(sub(new Date(), { weeks: 1 })),
                            ];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.LAST_WEEK);
                          }}>
                          Last Week
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.THIS_MONTH && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [startOfMonth(new Date()), endOfMonth(new Date())];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.THIS_MONTH);
                          }}>
                          This Month
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.LAST_MONTH && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [
                              startOfMonth(sub(new Date(), { months: 1 })),
                              endOfMonth(sub(new Date(), { months: 1 })),
                            ];
                            setPage(0);
                            setDateRange(dateRange);
                            setActiveButton(selectionFilterOptions.LAST_MONTH);
                          }}>
                          Last Month
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={activeButton === selectionFilterOptions.YTD && styleClass.buttonSelected}
                          onClick={() => {
                            const dateRange: [Date, Date] = [startOfYear(new Date()), new Date()];
                            setPage(0);
                            setDateRange(dateRange!);
                            setActiveButton(selectionFilterOptions.YTD);
                          }}>
                          YTD
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        label="date range"
                        value={dateRange}
                        onChange={handleDateChange}
                        renderDay={renderWeekPickerDay}
                        renderInput={(startProps: any, endProps: any) => (
                          <>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Popover>
            </Stack>

            {/* Transaction Type Filter */}
            <FormControl sx={{ minWidth: 200 }} size="small">
              <TextField
                fullWidth
                select
                size="small"
                margin="dense"
                variant="outlined"
                name="location"
                label="Location"
                value={locationFilter}
                disabled={loadingLocations}
                inputProps={{ id: 'location' }}
                onChange={(event: any) => {
                  setPage(0);
                  setLocationFilter(event.target.value);
                }}>
                {locationOptions &&
                  locationOptions.map((loc) => (
                    <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                      {loc.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Box>
        </Stack>

        <Grid container spacing={4} mb={3}>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Card
              sx={{
                py: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                boxShadow: 0,
                borderRadius: '10px',
                textAlign: 'center',
                color: '#061b64',
                bgcolor: '#d1e9fc',
                height: '80px',
                // minHeight: '100%',
              }}>
              <Typography
                fontWeight={400}
                fontSize={
                  String(remotePayments?.totalAmountProcessed).length < 10
                    ? 32
                    : String(remotePayments?.totalAmountProcessed).length < 15
                    ? 25
                    : 20
                }
                sx={{ opacity: 0.72 }}>
                ${remotePayments?.totalAmountProcessed.toLocaleString('en-US', { minimumFractionDigits: 2 }) ?? 0}
              </Typography>
              <Typography variant="subtitle1" mb={2} pl={1} pr={1}>
                Total Amount Processed
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                borderRadius: '10px',
                textAlign: 'center',
                color: '#04297a',
                bgcolor: '#d0f2ff',
                height: '80px',
                // minHeight: '100%',
              }}>
              <Typography variant="h4" sx={{ opacity: 0.72 }}>
                {remotePayments?.totalApprovedTransactions ?? 0}
              </Typography>

              <Typography variant="subtitle1" mb={2} pl={1} pr={1}>
                Total Approved Transactions
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                borderRadius: '10px',
                textAlign: 'center',
                color: '#061b64',
                bgcolor: '#ff1d1d54',
                height: '80px',
                // minHeight: '100%',
              }}>
              <Typography variant="h4" sx={{ opacity: 0.72 }}>
                {remotePayments?.totalDeclinedTransactions ?? 0}
              </Typography>

              <Typography variant="subtitle1" mb={2} pl={1} pr={1}>
                Total Declined Transactions
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                borderRadius: '10px',
                textAlign: 'center',
                color: ' #7a4f01',
                bgcolor: '#fff7cd',
                height: '80px',
                // minHeight: '100%',
              }}>
              <Typography variant="h4" sx={{ opacity: 0.72 }}>
                {remotePayments?.remotePayments.rowCount ?? 0}
              </Typography>

              <Typography variant="subtitle1" mb={2} pl={1} pr={1}>
                Transaction Count
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Paper sx={{ width: '100%', mb: 2 }}>
          {isFetching && <LinearProgress style={{ height: '2px', borderRadius: '30px' }} />}
          <TableContainer>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Customer
                  </TableCell>
                  {dmsType !== DmsTypeEnum.Infinity && (
                    <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                      Organization
                    </TableCell>
                  )}
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    InvoiceId
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Memo
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Payment URL
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Location
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Department
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Sent
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Paid
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Card
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '100px', textAlign: 'center', inlineSize: 'auto' }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {remotePayments && remotePayments.remotePayments.data.length > 0 && (
                  <>
                    {remotePayments.remotePayments.data.map((row: IGravityPaymentData, index: any) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            row.status.toLowerCase() === paymentStatus.APPROVED
                              ? 'rgba(0, 255, 0, 0.03)'
                              : row.status.toLowerCase() === paymentStatus.CANCELED
                              ? 'rgba(255, 0, 0, 0.03)'
                              : row.status.toLowerCase() === paymentStatus.VOIDED
                              ? 'rgba(128, 0, 128, 0.04)'
                              : '',
                        }}>
                        {' '}
                        <TableCell
                          align="center"
                          className={isCustomerPermission == 1 ? styleClass.nameCell : ""}
                          onClick={() => {
                            isCustomerPermission == 1 ? 
                            dispatch(fetchSelectedCustomer(row.customerID))
                            : undefined;
                          }}>
                          {row.customerName}
                        </TableCell>
                        {dmsType !== DmsTypeEnum.Infinity && (
                          <TableCell align="center">{row.moreInfo?.organization}</TableCell>
                        )}
                        <TableCell align="center">{row.moreInfo?.invoiceId ?? 'N/A'}</TableCell>
                        <TableCell align="center">{row.memo}</TableCell>
                        <TableCell align="center">
                          {'$' + row.amount.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell align="center">
                          <a href={row.paymentPageUrl}>Link</a>
                        </TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">
                          {locationOptions?.find((x) => x.dealerLocationId === row.dealerId)?.name ?? 'N/A'}
                        </TableCell>
                        {/* <TableCell align="center"><Link href={row.paymentPageUrl} style={{cursor: 'pointer'}} underline="hover" onClick={(): any => window.open(row.paymentPageUrl)} target="_blank" rel="noopener">Click here</Link></TableCell> */}
                        <TableCell align="center">{row.moreInfo?.department}</TableCell>
                        <TableCell align="center">
                          {moment.utc(row.requestDate).local().format('M-D-YYYY \n hh:mmA')}
                        </TableCell>
                        <TableCell align="center">
                          {row.transactionDate
                            ? moment.utc(row.transactionDate).local().format('M-D-YYYY \n hh:mmA')
                            : 'N/A'}
                        </TableCell>
                        {/* <TableCell align="center">Text</TableCell> */}
                        <TableCell align="center">
                          {row.result ? (!row.result.maskedAccount ? 'N/A' : row.result.maskedAccount) : 'N/A'}
                        </TableCell>
                        <TableCell align="center">
                          <Box style={{ display: 'inline-flex', gap: '5px' }}>
                            <IconButton
                              style={{ padding: '0px', backgroundColor: 'transparent' }}
                              onClick={() => {
                                SetMessengerDrawerCustomerId(row.customerID);
                                SetOpenMessengerDrawer(true);
                              }}>
                              <Box style={{ display: isMessengerPermission == 1 ? "flex" : "none", flexDirection: 'column' }}>
                                <MailIcon color="primary" />
                                <Typography variant="caption">Text</Typography>
                              </Box>
                            </IconButton>
                            {/* Void Payment */}
                            {/* {row.status.toLowerCase() === paymentStatus.APPROVED && (
                              <IconButton
                                style={{ padding: '0px', backgroundColor: 'transparent' }}
                                onClick={() => {
                                  handlePaymentCancelOrVoid({
                                    dealerId: row.dealerId,
                                    paymentPageId: row.paymentPageId,
                                    transactionReferenceId: row.externalTransId,
                                    uniqueTransId: row.result.uniqueTransId,
                                    paymentType: 'voided',
                                  });
                                }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <BlockIcon color="error" />
                                  <Typography variant="caption">Void</Typography>
                                </Box>
                              </IconButton>
                            )} */}
                            {/* Cancel Payment */}
                            {row.status.toLowerCase() === paymentStatus.PENDING && (
                              <IconButton
                                style={{ padding: '0px', backgroundColor: 'transparent' }}
                                onClick={() => {
                                  handlePaymentCancelOrVoid({
                                    paymentPageId: row.paymentPageId,
                                    transactionReferenceId: row.externalTransId,
                                    paymentType: 'canceled',
                                  });
                                }}>
                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <CancelIcon color="error" />
                                  <Typography variant="caption">Cancel</Typography>
                                </Box>
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                        {/* <TableCell align="center">
                          <Button variant="outlined" color="error">
                            Void
                          </Button>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            {!isFetching && remotePayments?.remotePayments?.data && remotePayments?.remotePayments?.data.length <= 0 && (
              <>
                <Typography mt={1} mb={1} sx={{ color: '#178df3', textAlign: 'center' }}>
                  No Record Found
                </Typography>
                <Divider style={{ color: '#acaaaa5c' }} />
              </>
            )}
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={remotePayments?.remotePayments.rowCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            ActionsComponent={(props) => <TablePaginationActions {...props} isFetching={isFetching} />}
          />
          {openMessengerDrawer && (
            <MessengerDrawer
              defaultSearch={'CrmCustomerId:' + MessengerDrawerCustomerId}
              phoneNumber={''}
              open={openMessengerDrawer}
              onClose={() => SetOpenMessengerDrawer(false)}></MessengerDrawer>
          )}
          {<CustomerRootPageDrawer />}
        </Paper>
        <Box display="flex" alignItems="center" justifyContent="end"></Box>
      </Container>
    </>
  );
}
