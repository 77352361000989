import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {ButtonGroup, Button as MaterialButton} from '@mui/material';
import { teal } from '@material-ui/core/colors';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import clsx from 'clsx';
import moment from 'moment';
import React, { useRef } from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import { useRouter } from 'common/hooks';
import DealerAPI from 'components/authentication/api/dealer-api';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { BroadCast } from 'components/broadcast/models/broadcast';
import CustomerSegmentAPI from 'components/customer-groups/api/customer-group-api';
import AppSnackBar from 'components/snackbar/snackbar';
import CustomerGroup from 'components/customer-groups/models/customer-group';
////////////////////////////////////////////////////////////////////////////////////////////
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
import { format, isBefore } from 'date-fns';
import { ITaskTimes, taskHours } from '../../../lead/components/task-board/taskhours-step';

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    position: 'relative',
    background: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  title: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
    textAlign: 'center',
  },
  container: {
    height: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  contentTitle: {
    paddingBottom: theme.spacing(2),
  },
  cardList: {
    flexGrow: 1,
  },
  datePicker: {
    '& .css-dhopo2': {
      minHeight:'192px'
    },
  },
}));

interface CustomerGroupStatus {
  [key: number]: { loading: boolean; selected: boolean; count: number };
}
function CustomerGroups(): React.ReactElement {
  //Temporary Code Schedule Broadcast Code
  // const dealerNumber = useAppSelector((state)=>state.user.dmsDealerId);
  // const dealerNumbersToCheck = [999002, 99204001, 777666001];
  // const isDealerNumber = dealerNumbersToCheck.includes(dealerNumber);
  // End Temporary Code Schedule Broadcast Code
  const classes: any = useStyles();
  const router = useRouter();
  const { query }: any = router;
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const [customerGroups, setCustomerGroups] = React.useState<CustomerGroup[] | undefined>(undefined);
  const [customerGroupStatuses, setCustomerGroupStatuses] = React.useState<CustomerGroupStatus | undefined>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [broadcast, setBroadcast] = React.useState<BroadCast | undefined>(undefined);
  ////////**************************Schedule Broadcast*******************////////////////////////
  const [scheduleTime, setScheduleTime] = React.useState<ITaskTimes[]>(taskHours);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = React.useState<string>();
  const [isScheduling, setIsScheduling] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (date:any): void => {
    setSelectedDate(date);
  };
  const handleTimeChange = (e:any) => {
    setSelectedTime(e.target.value);
  };

  const combineDateTime = (date:any, time:any) => {
    if (!date || !time) return null;
    const dateObj = new Date(date);
    const [hours, minutes] = time.split(':');
    dateObj.setHours(parseInt(hours, 10));
    dateObj.setMinutes(parseInt(minutes, 10));
    dateObj.setSeconds(0,0);
    return dateObj;
  };
  const isSelectTimePassed = (yourTime:string) : boolean => {
    const [hours, minutes] = yourTime.split(':');
    const date = new Date();
    date.setHours(parseInt(hours,10));
    date.setMinutes(parseInt(minutes,10));
    date.setSeconds(0,0);
    if(date < new Date()){
      return true;
    }else{
      return false;
    }
  };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const filteredTimes = scheduleTime.filter((time) => {
        const timeValue = time.value;
        const timeDate = new Date();
        const [hours, minutes] = timeValue.split(':');
        timeDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
        return !isBefore(timeDate, new Date());
      });
      if(selectedDate.getDate() === new Date().getDate()){
        setScheduleTime(filteredTimes);
      }else if(selectedDate.getDate() != new Date().getDate()){
        setScheduleTime(taskHours);
      }
      if(selectedTime && selectedDate.getDate() === new Date().getDate() && isSelectTimePassed(selectedTime)){
        setSelectedTime(undefined);
      }
    }, 500); // Update every second

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [scheduleTime, selectedDate, new Date()]);

  React.useEffect(() => {
    async function fetchBroadcast(): Promise<void> {
      try {
        const response = await BroadCastAPI.getBroadcast(parseInt(query.id, 10), query.type);
        setBroadcast(response);
      } catch (error) {
        router.history.goBack();
      }
    }
    fetchBroadcast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.id]);

  function getSegmentIdsAndCustomerCount(): [number[], number] {
    let customerCount = 0;
    const segmentIds: number[] = [];
    if (customerGroupStatuses !== undefined) {
      Object.entries(customerGroupStatuses).forEach(([key, value]) => {
        if (value.selected) {
          if (value.count !== undefined) {
            customerCount += value.count;
          }
          segmentIds.push(parseInt(key, 10));
        }
      });
    }
    return [segmentIds, customerCount];
  }

  async function handleSendBroadcast(): Promise<void> {
    setButtonClicked(true);
    const [segmentIds, customerCount] = getSegmentIdsAndCustomerCount();
    try {
      const dealerPhone = await DealerAPI.getDealerPhoneSetting(dmsDealerIdCrm);
      if (broadcast === undefined) {
        return;
      }
      if (dealerPhone.messageCount < customerCount) {
        showSnackBar({ type: 'error', message: 'Not enough message credits' });
      } else {
        await BroadCastAPI.sendBroadcast(
          broadcast,
          query.type,
          segmentIds,
          broadcast?.dealerLocationId === 0 ? undefined : broadcast?.dealerLocationId,
          broadcast?.dealerLocationId === 0 ? dmsDealerIdCrm : undefined,
        );
        router.push(`/broadcasts`);
      }
      setButtonClicked(false);
    } catch (error) {
      setButtonClicked(false);
      showSnackBar({ type: 'error', message: 'Unable to sent broadcast' });
    }
  }

  async function handleScheduleBroadcastForm(): Promise<void> {
    const scheduledDateTime = combineDateTime(selectedDate, selectedTime);
    if (scheduledDateTime) {
      setButtonClicked(true);
      const [segmentIds, customerCount] = getSegmentIdsAndCustomerCount();
      try {
        const dealerPhone = await DealerAPI.getDealerPhoneSetting(dmsDealerIdCrm);
        if (broadcast === undefined) {
          return;
        }
        if (dealerPhone.messageCount < customerCount) {
          showSnackBar({ type: 'error', message: 'Not enough message credits' });
        } else {
          showSnackBar({ type: 'success', message: 'Scheduling broadcast to customer groups' });
          setIsScheduling(true)
          await BroadCastAPI.sendScheduledBroadcast(
            broadcast,
            query.type,
            segmentIds,
            scheduledDateTime,
            broadcast?.dealerLocationId === 0 ? undefined : broadcast?.dealerLocationId,
            broadcast?.dealerLocationId === 0 ? dmsDealerIdCrm : undefined,
          );
          setIsScheduling(false);
          handleClose();
          showSnackBar({ type: 'success', message: `Scheduled broadcast on ${scheduledDateTime.toLocaleString('en-US', {hour12:true})}` });
          router.push(`/broadcasts`);
        }
        setButtonClicked(false);
      } catch (error) {
        setIsScheduling(false);
        setButtonClicked(false);
        showSnackBar({ type: 'error', message: 'Unable to schedule broadcast' });
      }
    }
  }

  async function handleSegmentCardClick(segmentId: number): Promise<void> {
    if (customerGroups !== undefined && customerGroupStatuses !== undefined) {
      if (customerGroupStatuses[segmentId].selected === false) {
        try {
          let newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
          newCustomerGroupStatuses[segmentId].loading = true;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
          const response = await CustomerSegmentAPI.getSegmentCustomerCount(
            segmentId,
            broadcast?.dealerLocationId === 0 ? undefined : broadcast?.dealerLocationId,
            broadcast?.dealerLocationId === 0 ? dmsDealerIdCrm : undefined,
            "B",
            query.type === 'EMAIL',
            
          );
          newCustomerGroupStatuses = { ...customerGroupStatuses };
          if (response > 0) {
            newCustomerGroupStatuses[segmentId].selected = true;
          }
          newCustomerGroupStatuses[segmentId].loading = false;
          newCustomerGroupStatuses[segmentId].count = response;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
        } catch (error) {
          const newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
          newCustomerGroupStatuses[segmentId].loading = false;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
          showSnackBar({ type: 'error', message: 'Unable to get customer count' });
        }
      } else {
        const newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
        newCustomerGroupStatuses[segmentId].selected = false;
        setCustomerGroupStatuses(newCustomerGroupStatuses);
      }
    }
  }

  function handleGoBack(): void {
    router.history.goBack();
  }
  React.useEffect(() => {
    async function fetchCustomerSegments(): Promise<void> {
      if (broadcast) {
        try {
          setLoading(true);
          const response = await CustomerSegmentAPI.getCustomerSegments(broadcast?.dealerLocationId);
          setCustomerGroups(response);
          const ids = response.reduce(
            (reduce, customerGroup) => ({
              ...reduce,
              [customerGroup.segmentId]: { loading: false, selected: false, count: undefined },
            }),
            {},
          );
          setCustomerGroupStatuses(ids);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          showSnackBar({ type: 'error', message: 'Unable to fetch customer groups' });
        }
      }
    }
    fetchCustomerSegments();
  }, [broadcast, showSnackBar]);




  if (broadcast === undefined) {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  const isSendable =
    customerGroupStatuses &&
    Object.entries(customerGroupStatuses).filter(([key, value]: any): boolean => value.selected === true).length !== 0;
  return (
    <Box>
      <Toolbar variant="dense">
        <IconButton id="goBackBtn" edge="start" color="default" onClick={handleGoBack} aria-label="close">
          <KeyboardBackspaceIcon />
        </IconButton>
        <Box className={classes.title}>
          <Typography variant="h4" component="h1">
            <b>{broadcast?.title}</b>
          </Typography>
        </Box>
      </Toolbar>
      <Divider />
      <Container className={classes.container}>
        <Box className={classes.contentTitle}>
          <Typography variant="h4">Select Customer Group(s)</Typography>
        </Box>
        {loading ? (
          <Box style={{ margin: 'auto' }} display="flex" justifyContent="center">
            <SmallCircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs="auto">
                  {!broadcast.isSchedule && (<Button
                    style={{ marginRight: '2px' }}
                    disabled={!isSendable || buttonClicked}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleClick}>
                    Schedule Broadcast
                  </Button>)}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    style={{width:'350px'}}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Box className={classes.datePicker}>
                            <StaticDatePicker
                              displayStaticWrapperAs="desktop"
                              disablePast
                              label="date range"
                              value={[selectedDate]}
                              onChange={handleDateChange}
                              renderInput={(props) => <TextField {...props} />}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={1} pl={3} pr={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography style={{fontWeight:'bold', float:'left'}}>{format(selectedDate, 'MMMM d, yyyy')} at</Typography>
                          <TextField select fullWidth margin='dense' size='small' label='Time' style={{float:'right', width:'115px'}} value={selectedTime} onChange={handleTimeChange}>{scheduleTime.map((hour:ITaskTimes) => (
                            <MenuItem
                              style={{ overflowY: 'auto', maxHeight: '200px' }}
                              key={hour.value}
                              value={hour.value}>
                              {hour.label}
                            </MenuItem>
                          ))}</TextField>
                          {/* <TextField /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pl={3} pr={3} mt={2} mb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                          <Button onClick={handleClose} variant='contained' style={{float:'left', backgroundColor:'red', color:'white'}}>Close</Button>
                          <Button onClick={handleScheduleBroadcastForm} disabled={!selectedTime} variant='contained' color='primary' style={{float:'right', width:'164px', height:'36.5px'}}>{isScheduling ? <CircularProgress size='1.7rem' style={{color:'white'}}/>:'Schedule & Close'}</Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Popover>
                  <Button
                    id="sendBroadcastBtn"
                    color="primary"
                    disabled={!isSendable || buttonClicked}
                    variant="contained"
                    size="medium"
                    onClick={handleSendBroadcast}>
                    Send Now {buttonClicked && <CircularProgress size={18} style={{marginLeft: 3}} />}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.cardList}>
              <Grid container spacing={2}>
                {customerGroups &&
                  customerGroupStatuses &&
                  customerGroups.map((customerGroup: CustomerGroup) => (
                    <Grid key={customerGroup.segmentId} item xs={12} sm={6} md={4} lg={3}>
                      <CustomerGroupCard
                        customerGroup={customerGroup}
                        loading={customerGroupStatuses[customerGroup.segmentId].loading}
                        selected={customerGroupStatuses[customerGroup.segmentId].selected}
                        count={customerGroupStatuses[customerGroup.segmentId].count}
                        onClick={handleSegmentCardClick}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}

const useCardStyles = makeStyles((theme: Theme): any => ({
  card: {
    height: '225px',
    cursor: 'pointer',
  },
  header: {
    height: '60px',
    alignItems: 'baseline',
  },
  headerTitle: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  content: {
    height: '65px',
    overflow: 'hidden',
  },
  actions: {
    height: '20px',
  },
  menuBtn: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  cardPaperSelected: {
    border: `2px solid ${teal[500]}`,
  },
}));

function CustomerGroupCard({
  customerGroup,
  onClick,
  loading,
  count,
  selected,
}: {
  customerGroup: CustomerGroup;
  onClick(segmentId: number): Promise<void>;
  loading: boolean;
  count?: number;
  selected: boolean;
}): React.ReactElement {
  const classes: any = useCardStyles();

  async function onCardClick(): Promise<void> {
    onClick(customerGroup.segmentId);
  }

  const formatDate = (date: string): string => moment(date).format('M-DD-YYYY');
  let segmentDate = null;
  const forDate = `From: ${formatDate(customerGroup.calculatedFromDate || '')}`;
  const toDate = `To: ${formatDate(customerGroup.calculatedToDate || '')}`;
  segmentDate = (
    <>
      <Box>
        <Typography variant="body1">{forDate}</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">{toDate}</Typography>
      </Box>
    </>
  );
  const title = customerGroup.segmentName;
  const subHeader =
    customerGroup?.period && customerGroup?.periodValue
      ? `${customerGroup?.periodValue} ${customerGroup?.period === 1 ? 'Month' : 'Day'}${
          customerGroup.periodValue && customerGroup?.periodValue > 1 ? 's' : ''
        }`
      : null;
  const customerCountText = count && count == 1 ? `${count} Customer` : `${count} Customers`;
  let cardActionContent = null;
  if (count !== undefined) {
    cardActionContent = <Typography variant="button">{customerCountText}</Typography>;
  }
  if (loading) {
    cardActionContent = (
      <>
        <SmallCircularProgress />
        <Typography variant="caption">fetching number of customers</Typography>
      </>
    );
  }
  return (
    <Card className={`${clsx(classes.card, selected && classes.cardPaperSelected)} SegmentCard`} onClick={onCardClick}>
      <CardHeader
        className={classes.header}
        classes={{ title: classes.headerTitle }}
        title={title}
        subheader={subHeader}
      />
      <CardContent className={classes.content}>
        <Box mx={1}>{segmentDate}</Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>{cardActionContent}</CardActions>
    </Card>
  );
}

export default CustomerGroups;
