import { get, post, put } from 'api';
import config from 'config';
import {UserProfileData,UserEmailAndChecks} from '../models/userProfileData';


const dealerApi = config.get('api.dealer');

const UserProfileAPI = {

  async getUserData(): Promise<UserProfileData> {
    const url = `${dealerApi}/getProfileUser`
    const { data } = await get(url);
    return data;
  },
  async updateUserProfile(userEmailandChecks: UserEmailAndChecks):Promise<any>  {
    const url = `${dealerApi}/updateProfileUser`;
    const { data } : any = await post(url, userEmailandChecks);
    return data;
  },
};

export default UserProfileAPI;
