import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import { useRouter } from 'common/hooks';
import useLocations from 'common/hooks/useLocations';
import DealerAPI from 'components/authentication/api/dealer-api';
import CustomerSegmentAPI from 'components/customer-groups/api/customer-group-api';
import AppSnackBar from 'components/snackbar/snackbar';
import SurveyAPI from 'components/survey/api/survey-api';

import CustomerGroup from 'components/customer-groups/models/customer-group';
import { Survey } from 'components/survey/models';

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    position: 'relative',
    background: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  title: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
    textAlign: 'center',
  },
  container: {
    height: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  contentTitle: {
    paddingBottom: theme.spacing(2),
  },
  cardList: {
    flexGrow: 1,
  },
}));

interface CustomerGroupStatus {
  [key: number]: { loading: boolean; selected: boolean; count: number };
}

function CustomerGroups(): React.ReactElement | null {
  const classes: any = useStyles();
  const router = useRouter();
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonClicked, setbuttonClicked] = React.useState(false);
  const [customerGroups, setCustomerGroups] = React.useState<CustomerGroup[] | undefined>(undefined);
  const [customerGroupStatuses, setCustomerGroupStatuses] = React.useState<CustomerGroupStatus | undefined>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { getLocationName } = useLocations(dmsDealerIdCrm);
  const [survey, setSurvey] = React.useState<Survey | undefined>(undefined);
  const { query }: any = router;

  function getSegmentIdsAndCustomerCount(): [number[], number] {
    let customerCount = 0;
    const segmentIds: number[] = [];
    if (customerGroupStatuses !== undefined) {
      Object.entries(customerGroupStatuses).forEach(([key, value]) => {
        if (value.selected) {
          if (value.count !== undefined) {
            customerCount += value.count;
          }
          segmentIds.push(parseInt(key, 10));
        }
      });
    }
    return [segmentIds, customerCount];
  }

  function handleGoBack(): void {
    router.history.goBack();
  }

  async function handleSendSurvey(): Promise<void> {
    const [segmentIds, customerCount] = getSegmentIdsAndCustomerCount();
    try {
      setbuttonClicked(true);
      const dealerPhone = await DealerAPI.getDealerPhoneSetting(dmsDealerIdCrm);
      if (survey === undefined) {
        setbuttonClicked(false);
        return;
      }
      if (dealerPhone.messageCount < customerCount) {
        showSnackBar({ type: 'error', message: 'Not enough message credits' });
      } else {
        const publishedSurveyUrl = window.location.origin;
        await SurveyAPI.sendSurvey(
          survey.id,
          segmentIds,
          publishedSurveyUrl,
          survey.isAllLocations === false ? survey.dealerLocationId : undefined,
          survey.isAllLocations === true ? dmsDealerIdCrm : undefined,
        );
        showSnackBar({ type: 'success', message: 'Sending survey to customer groups' });
        router.push(`/surveys`);
      }
      setbuttonClicked(false);
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to sent survey' });
      setbuttonClicked(false);
    }
  }

  async function handleSegmentCardClick(segmentId: number): Promise<void> {
    if (customerGroups !== undefined && customerGroupStatuses !== undefined) {
      if (customerGroupStatuses[segmentId].selected === false) {
        try {
          let newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
          newCustomerGroupStatuses[segmentId].loading = true;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
          const response = await CustomerSegmentAPI.getSegmentCustomerCount(
            segmentId,
            survey?.isAllLocations === false ? survey?.dealerLocationId : undefined,
            survey?.isAllLocations === true ? dmsDealerIdCrm : undefined,
            "S",
            false,
          );
          if (response > 0) {
            newCustomerGroupStatuses[segmentId].selected = true;
          }
          newCustomerGroupStatuses = { ...customerGroupStatuses };
          newCustomerGroupStatuses[segmentId].loading = false;
          newCustomerGroupStatuses[segmentId].count = response;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
        } catch (error) {
          const newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
          newCustomerGroupStatuses[segmentId].loading = false;
          setCustomerGroupStatuses(newCustomerGroupStatuses);
          showSnackBar({ type: 'error', message: 'Unable to get customer count' });
        }
      } else {
        const newCustomerGroupStatuses: CustomerGroupStatus = { ...customerGroupStatuses };
        newCustomerGroupStatuses[segmentId].selected = false;
        setCustomerGroupStatuses(newCustomerGroupStatuses);
      }
    }
  }

  React.useEffect(() => {
    async function fetchSurvey(): Promise<void> {
      try {
        const response = await SurveyAPI.getSurvey(parseInt(query.id, 10));
        setSurvey(response);
      } catch (error) {
        router.history.goBack();
      }
    }
    fetchSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.id]);

  React.useEffect(() => {
    async function fetchCustomerSegments(): Promise<void> {
      if (survey) {
        try {
          setLoading(true);
          const response = await CustomerSegmentAPI.getCustomerSegments(survey?.dealerLocationId);
          setCustomerGroups(response);
          const ids = response.reduce(
            (reduce, customerGroup) => ({
              ...reduce,
              [customerGroup.segmentId]: { loading: false, selected: false, count: undefined },
            }),
            {},
          );
          setCustomerGroupStatuses(ids);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          showSnackBar({ type: 'error', message: 'Unable to fetch customer groups' });
        }
      }
    }
    fetchCustomerSegments();
  }, [survey, showSnackBar]);

  if (survey === undefined) {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  const isSendable =
    customerGroupStatuses &&
    Object.entries(customerGroupStatuses).filter(([key, value]: any): boolean => value.selected === true).length !== 0;
  return (
    <Box>
      <Toolbar variant="dense">
        <IconButton id="closeSegmentDrawerBtn" edge="start" color="default" onClick={handleGoBack} aria-label="close">
          <KeyboardBackspaceIcon />
        </IconButton>
        <Box className={classes.title}>
          <Typography variant="h4" component="h1">
            <b>{survey?.title}</b>
          </Typography>
          <Typography variant="caption">{getLocationName(survey?.dealerLocationId)}</Typography>
        </Box>
      </Toolbar>
      <Divider />
      <Container className={classes.container}>
        <Box className={classes.contentTitle}>
          <Typography variant="h4">Select Customer Group(s)</Typography>
        </Box>
        {loading ? (
          <Box style={{ margin: 'auto' }} display="flex" justifyContent="center">
            <SmallCircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <Grid container spacing={5} justifyContent="flex-end">
                <Grid item xs="auto">
                  <Button
                    id="sendNow"
                    color="primary"
                    disabled={!isSendable || buttonClicked}
                    variant="contained"
                    size="medium"
                    onClick={handleSendSurvey}>
                    Send Now
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.cardList}>
              <Grid container spacing={2}>
                {customerGroups &&
                  customerGroupStatuses &&
                  customerGroups.map((customerGroup: CustomerGroup) => (
                    <Grid key={customerGroup.segmentId} item xs={12} sm={6} md={4} lg={3}>
                      <CustomerGroupCard
                        customerGroup={customerGroup}
                        loading={customerGroupStatuses[customerGroup.segmentId].loading}
                        selected={customerGroupStatuses[customerGroup.segmentId].selected}
                        count={customerGroupStatuses[customerGroup.segmentId].count}
                        onClick={handleSegmentCardClick}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}

const useCardStyles = makeStyles((theme: Theme): any => ({
  card: {
    height: '225px',
    cursor: 'pointer',
  },
  header: {
    height: '60px',
    alignItems: 'baseline',
  },
  headerTitle: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  content: {
    height: '65px',
    overflow: 'hidden',
  },
  actions: {
    height: '20px',
  },
  menuBtn: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  cardPaperSelected: {
    border: `2px solid ${teal[500]}`,
  },
}));

function CustomerGroupCard({
  customerGroup,
  onClick,
  loading,
  count,
  selected,
}: {
  customerGroup: CustomerGroup;
  onClick(segmentId: number): Promise<void>;
  loading: boolean;
  count?: number;
  selected: boolean;
}): React.ReactElement {
  const classes: any = useCardStyles();

  async function onCardClick(): Promise<void> {
    onClick(customerGroup.segmentId);
  }

  const formatDate = (date: string): string => moment(date).format('M-DD-YYYY');
  let segmentDate = null;
  const forDate = `From: ${formatDate(customerGroup.calculatedFromDate || '')}`;
  const toDate = `To: ${formatDate(customerGroup.calculatedToDate || '')}`;
  segmentDate = (
    <>
      <Box>
        <Typography variant="body1">{forDate}</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">{toDate}</Typography>
      </Box>
    </>
  );

  const title = customerGroup.segmentName;
  const subHeader =
    customerGroup?.period && customerGroup?.periodValue
      ? `${customerGroup?.periodValue} ${customerGroup?.period === 1 ? 'Month' : 'Day'}${
          customerGroup.periodValue && customerGroup?.periodValue > 1 ? 's' : ''
        }`
      : null;
  const customerCountText = `${count} Customers`;
  let cardActionContent = null;
  if (count !== undefined) {
    cardActionContent = <Typography variant="button">{customerCountText}</Typography>;
  }
  if (loading) {
    cardActionContent = (
      <>
        <SmallCircularProgress />
        <Typography variant="caption">fetching number of customers</Typography>
      </>
    );
  }
  return (
    <Card className={`${clsx(classes.card, selected && classes.cardPaperSelected)} SegmentCard`} onClick={onCardClick}>
      <CardHeader
        className={classes.header}
        classes={{ title: classes.headerTitle }}
        title={title}
        subheader={subHeader}
      />
      <CardContent className={classes.content}>
        <Box mx={1}>{segmentDate}</Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>{cardActionContent}</CardActions>
    </Card>
  );
}
export default CustomerGroups;
