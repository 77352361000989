import { get, post } from 'api';
import { DealerSecondaryProfileData, IContactJobPosition, IDealerBusinessType } from './twilioInterfaces';
import config from 'config';

const BASE_URL = config.get('api.dealer');

const businessTypes: IDealerBusinessType[] = 
[{ business_type: 'Sole Proprietorship' }, { business_type: 'Partnership' }, 
{ business_type: 'Limited Liability Corporation' },{ business_type: 'Non-profit Corporation' },
{ business_type: 'Corporation' }];

const jobPositions: IContactJobPosition[]=[{ jobPosition: 'Director' }, { jobPosition: 'GM' }, { jobPosition: 'VP' },{ jobPosition: 'CEO' },{ jobPosition: 'CFO' }
,{ jobPosition: 'General Counsel' },{ jobPosition: 'Other' }];

const addPhoneRegistration = async (dmsDealerIdCrm: number, model: DealerSecondaryProfileData): Promise<any> => {
    return await post(`${BASE_URL}/dealers/${dmsDealerIdCrm}/createsecondaryprofile`, model);
  };

const getSecondaryProfileData = async (dmsDealerIdCrm: number): Promise<any> => {
  const { data } = await get(`${BASE_URL}/dealers/${dmsDealerIdCrm}/fetchsecondaryprofile`);
    return data;
};

  
export default {
    businessTypes,
    jobPositions,
    addPhoneRegistration,
    getSecondaryProfileData
}