import { Avatar, Box, Container, Grid, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Status from 'common/models/status';
import { showSnackbar } from 'components/root-snackbar';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { loginUser } from '../authentication-slice';
import LoginForm, { LoginFormValues } from '../login-form';
import useStyles from './login-style';

interface LocationState {
  from: {
    pathname: string;
  };
}

export const Login: React.FC = () => {
  localStorage.removeItem('PROJECT_X_STATE');
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const status = useAppSelector((state) => state.user.status);
  const [redirectTo, setRedirectTo] = React.useState<string | null>(null);

  const history = useHistory();
  const handleLoginFormSubmit = async ({ dealerId, username, password, token }: LoginFormValues): Promise<void> => {
    dispatch(loginUser({ dealerId, username, password, token })).then(x => {
      console.log("history line");
      console.log(redirectTo);
       history.push(redirectTo??"/");
    });
  };

  React.useEffect(() => {
    if (status === Status.failed) {
      dispatch(showSnackbar({ type: 'info', message: 'Invalid Credentials' }));
    }
    let redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
    if (window.location.search.indexOf("?redirectUrl=") > -1) {
      redirectUrl = window.location.search.replace("?redirectUrl=/", "");
    }
    console.log(window.location);
    console.log(redirectUrl);
    setRedirectTo(redirectUrl ?? '/');
  }, [dispatch, status, redirectTo]);

  return (
    <Container component="div" maxWidth="xs" className={classes.container}>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.grid}>
        <Grid item>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Box component="div" mt={1}>
            <Typography component="h1" variant="h5">
              Log In
            </Typography>
          </Box>
          <LoginForm onSubmit={handleLoginFormSubmit} disabled={status === Status.loading} />
        </Grid>
      </Grid>
      {loggedIn && <Redirect to={'/'} />}
    </Container>
  );
};

export default Login;
