import { Box, Container, Grid, IconButton, TextField, Theme, Typography, Fab } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PageHeader } from 'common/components/layout';
import { debounce } from 'lodash';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { CustomerRootDrawer } from '../CustomerDrawer/CustomerRootDrawer';
import CustomerRootPageDrawer from '../CustomerDrawer/CustomerRootPageDrawer';
import CustomerTable from '../CustomerTable/CustomerTable';
import { fetchCustomers, hideCustomerDrawer, saveCustomer, setEditCustomerLoading, SetSelectedCustomers, showCustomerDrawer, updateCustomerSearchString } from './customersSlice';
import Customer from 'common/models/customer';
import AddIcon from '@mui/icons-material/Add';
import DealerAPI from 'components/authentication/api/dealer-api';
import CustomerAPI, { CustomerCategory } from 'components/customer/api/CustomerAPI';
import Swal from 'sweetalert2';
import { showSnackbar } from 'components/root-snackbar';
import Tooltip from '@mui/material/Tooltip/Tooltip';

const drawerWidth = 550;

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  containerShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  primaryAddBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

export function Customers(): ReactElement {
  const [isLoading, SetIsLoading] = React.useState<any>(1);
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const debouncedSearchCustomer = useRef(debounce(() => dispatch(fetchCustomers()), 1000)).current;
  const customerSearchText = useAppSelector((state) => state.customers.searchString);
  const [defaultCategory,setDefaultCategory] = useState<string>('');
  const [categories, setCategories] = useState<CustomerCategory[]>([]);
  const handleSearchCustomer = (searchString: string): void => {
    dispatch(updateCustomerSearchString(searchString));
  };

  useEffect(() => {
    return function onUnMount(): void {
      dispatch(hideCustomerDrawer());
      dispatch(SetSelectedCustomers([]));
      dispatch(updateCustomerSearchString(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if(isLoading==1)SetIsLoading(0);
    if(isLoading==0) debouncedSearchCustomer();
  }, [customerSearchText, debouncedSearchCustomer]);

  const drawerOpen = useAppSelector((state) => !!state.customers.selectedCustomer);
  const containerClassName = clsx(classes.container, drawerOpen && classes.containerShift);
  const [loadingCategory, setLoadingCategory]= useState<boolean>(false);
  const { drawerType, drawerProps } = useAppSelector((state) => ({
    drawerType: state.customers.drawerType,
    drawerProps: state.customers.drawerProps,
  }));
  let open = true;
  const handleClose = () => dispatch(hideCustomerDrawer());

  if (!drawerType) open = false;
  const dealerdefaultCategory = async () => {
    setLoadingCategory(true);
    const resp = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
    const catCheck = resp.dealerLocationSettings.length > 0 && 
      (resp.dealerLocationSettings[0].defaultCustomerCategoryId === null || resp.dealerLocationSettings[0].defaultCustomerCategoryId === '');
    if (resp && (resp.dealerLocationSettings.length <= 0 ||  catCheck) ) {
      const categories = await CustomerAPI.getCategories(dealerId);
      setCategories(categories);
    }else{
      setDefaultCategory(resp.dealerLocationSettings[0]?.defaultCustomerCategoryId);
    }
    setLoadingCategory(false);
  };
  useEffect(()=>{
    if(defaultCategory==='' || defaultCategory === undefined){
      dealerdefaultCategory();
    }
  },[])

  const onAddCustomerBtn = () => dispatch(showCustomerDrawer({ drawerType: 'CUSTOMER_ADD', drawerProps: {} }));
  const handleCustomerFormSubmit = (customer: Customer): void => {
    dispatch(saveCustomer(customer));
  };
  const handleOnClose = (): void => {
    dispatch(hideCustomerDrawer());
  };

  return (
    <Container className={containerClassName} maxWidth="lg">
      <PageHeader title="Customers" />
      <Box mt={2}>
        <Grid container style={{ display: 'flex', flexWrap: 'inherit' }}>
              <IconButton
                style={{height:'49.67px'}}
                title='New Customer'
                className={classes.primaryAddBtn}
                onClick={(): void => {
                  !loadingCategory && onAddCustomerBtn();
                }}
                size="medium">
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <AddIcon />
                  <Typography style={{ fontSize: '8px', marginTop: '-12px' }} >New</Typography>
                </Box>
              </IconButton>
          <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
            <TextField
              fullWidth
              style={{ backgroundColor: 'white' }}
              margin="dense"
              variant="outlined"
              name="searchText"
              id="search-text"
              placeholder="Search customers"
              value={customerSearchText}
              InputProps={{
                startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
              }}
              onChange={({ target: { value } }): void => {
                handleSearchCustomer(value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <CustomerTable />
      </Box>
      {<CustomerRootPageDrawer />}
      {<CustomerRootDrawer />}
      {/* {
        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          onClose={()=>{handleClose()}}
          SlideProps={{ timeout: { enter: 300, exit: 300 } }}>
          {open &&
            <>
              <DrawerToolbar title="Add Customer" onClose={()=> {handleOnClose()}} />
              <Container maxWidth="sm">
                <CustomerForm
                  disabled={false}
                  btnDisabled={false}
                  onFormSubmit={(e)=> {handleCustomerFormSubmit(e); }}
                  onClose={()=> {handleOnClose();}}
                  validate
                />
              </Container>
            </>}
        </Drawer>
      } */}
    </Container>
  );
}

export default Customers;
