import { get, post } from 'api';
import config from 'config';


const dealerUrl = config.get('api.dealer');

const authenticateUser = async (dealerId: string, userName: string, password: string, token: string ): Promise<any> => {
  const { data } = await post(`${config.get('api.authentication')}/v2/authentication`, {
    dealerId,
    userName,
    password,
    token
  });
  return data;
};

const refreshTokenUser = async (refreshToken: string): Promise<any> => {
  const { data } = await post(`${config.get('api.authentication')}/refreshToken`, { refreshToken });
  return data;
};

const validateTokenUser = async (): Promise<any> => {
  const { data } = await get(`${dealerUrl}/validateToken`);
  return data;
};

export default { authenticateUser, refreshTokenUser, validateTokenUser };
