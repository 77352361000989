import TableContainer from '@material-ui/core/TableContainer';
import { Box, Button, Checkbox, Drawer, Fab, Grid, Container, IconButton, Link, makeStyles, Popover, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useTheme, Paper } from "@material-ui/core";
import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import React, { useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomerApi from 'components/customer/api/CustomerAPI';
import { Chip } from '@mui/material';
import { SmallCircularProgress } from 'common/components/loaders';
import messagingApi from 'components/messaging/api/messaging-api';
import Swal from 'sweetalert2';
import { Conversation } from 'components/messaging/models/conversation';
import ArchiveConversationPanelDrawer from 'components/messaging/components/archive-conversation-panel-drawer';
import { formatDateTimeDateFns } from 'common/utils';
import { dateTimeFormats } from 'common/utils/shared/shared-constants';

const useStyles1 = makeStyles((theme) => ({
    paper: {},
    cursorChip: {
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    myChip: {
        border: "1px solid silver",
        borderRadius: "10px",
        padding: "4px 6px",
        margin: "2px 3px",
        backgroundColor: "#FBFCFC",



    },
    tableRowRoot: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'red',
        },
    },
    myTable: {
        // margin: "10px 20px",
        // fontSize:"large",
        '& th': {
            backgroundColor: "#F7F9F9",
            fontSize: "medium",
            fontWeight: "bold"
        },
        '& td': {
            borderBottom: "1px solid #EAEDED",
            verticalAlign: "top",
            fontSize: "medium",
            // // whiteSpace: "nowrap",
            // height: "25px"
        },
        '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
        // '& tr:nth-child(even)': { backgroundColor: "red" }
    },

    selectedRow: {
        backgroundColor: "#EAF2F8",

    },
}));

// let dollarUSLocale = Intl.NumberFormat('en-US');
let dollarUSLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); // $148,000
function CustomerSummary({ customer, isInvoice, isMessenger, isTaskPermission , open, onClose }: { customer: any, isInvoice: number, isMessenger: number, isTaskPermission: number, open: boolean, onClose(): void }) {
    const classes = useStyles1();
    const [dataSummary, setSummary] = React.useState([] as any);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);
    const [openArchvieConvPanelDrawer, SetOpenArchvieConvPanelDrawer] = React.useState<boolean>(false);
    const [conversation, setConversation] = React.useState<Conversation|undefined>();
    useEffect(() => {
        GetContacts();
    }, []);

    async function GetContacts() {
        let obj: any = new Object();
        obj.Dealerid = customer.dealerId;
        obj.CustomerId = customer.customerId;
        //alert(customer.customerId);
        const jsonString = JSON.stringify(obj);
        // const response = await CustomerApi.GetJsonSp('Kartic.getCustomerSummary', jsonString);
        const response = await CustomerApi.GetJsonSp('[Customers].[spCustomerSummary]', jsonString);
        setIsLoading(false);
        setSummary(response[0]);

    }

    function GetPhoneNumber(row: any): string {
        let phoneType = row.PhoneTypeName;
        if (phoneType == null) {
            if (row.PhoneType == 0) phoneType = 'Landline';
            if (row.PhoneType == 1) phoneType = 'Cell';
            if (row.PhoneType == 2) phoneType = 'Alternate';
            if (row.PhoneType == 3) phoneType = 'Fax';
        }
        return phoneType + ': ' + row.PhoneNumber;
    }

    function GetInvoiceData(tag: string) {
        let item: any = dataSummary.Invoice.filter((x: any) => x.Id === 0);
        if (item.length > 0) {
            let dt: any;
            if (tag == 'first') dt = formatDate(item[0].FirstInvoiceDate);
            if (tag == 'last') dt = formatDate(item[0].LastInvoiceDate);
            if (tag == 'lifetime') {
                dt = item[0].TotalService + item[0].TotalPart + item[0].TotalWG;

            }
            if (tag != 'lifetime' && new Date(dt).getFullYear() <= 2000) return ' (none)';
            return dt;
        }
    }

    function ConvetToLocalTime(value: any) {
        try {
            let dt = value.toString().replace('T', ' ') + ' UTC';
            return new Date(dt).toLocaleDateString() + ' ' + new Date(dt).toLocaleTimeString([], { timeStyle: 'short' });
        }
        catch (err) {
            return value;
        }
    }

    function formatDate(date: Date, showFullDateTime: boolean = false) {
        try {
            let newDate = date.toString().replace('T', ' ') + ' UTC';
            let dt = new Date(newDate);
            if (showFullDateTime) return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
           
            return (dt.getMonth() + 1) + '/' + dt.getDate() + '/' + dt.getFullYear();

        }
        catch (err) {
            return date;
        }
    }

    async function handleArchiveTitleClick(conversationId:number)
    {
        setIsArchiveLoading(true);
        try
        {
            const conResponse = await messagingApi.getArchiveConversation(conversationId);
            setIsArchiveLoading(false);
            if(conResponse.isSuccess){
                setConversation(conResponse.data)
                SetOpenArchvieConvPanelDrawer(true);
                   
            }else{
                
                Swal.fire(
                    'Error!',
                    'Error occured while getting the conversation thread. Please see details:\n'+conResponse.error,
                    'error'
                    );
            
            }

        }
        catch (error) {
            setIsArchiveLoading(false);
            Swal.fire(
                'Error!',
                'Error occured while getting the conversation thread.',
                'error');
          }

    }

    return (
        <div>
            <Drawer PaperProps={{ style: { width: "auto", minWidth: "900px", maxWidth: "80%" } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
                <DrawerToolbar title="Customer Summary feedback" onClose={onClose} />
                {isLoading && (
                    <div style={{ margin: "5px" }}>
                        <table>
                            <tr>
                                <td>
                                    <h2>Loading...</h2>
                                </td>
                                <td>
                                    <SmallCircularProgress></SmallCircularProgress>

                                </td>
                            </tr>
                        </table>


                    </div>
                )}

                {typeof (dataSummary.Invoice) !== 'undefined' && dataSummary.Invoice != null && isInvoice == 1 && (
                    <Box style={{ display: "table", margin: "10px 20px", border: "0px solid red" }}>
                        <table>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>First Invoice: </td>
                                <td>{GetInvoiceData('first')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Last Invoice: </td>
                                <td>{GetInvoiceData('last')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Lifetime Purchases:&nbsp;&nbsp;&nbsp; </td>
                                <td>{dollarUSLocale.format(GetInvoiceData('lifetime'))}</td>
                            </tr>

                        </table>
                        <Box style={{ display: "table", border: "0px solid red", marginTop: "10px" }}>
                            <Paper>
                                <Table size="small" className={classes.myTable} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align='right'>Service</TableCell>
                                            <TableCell align='right'>Parts</TableCell>
                                            <TableCell align='right'>Equipment</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {dataSummary.Invoice.map((row: any) => (
                                        <TableRow hover>
                                            <TableCell style={{ fontWeight: "bold" }}>{row.Type}</TableCell>
                                            <TableCell align='right' >{dollarUSLocale.format(row.TotalService)}</TableCell>
                                            <TableCell align='right'>{dollarUSLocale.format(row.TotalPart)}</TableCell>
                                            <TableCell align='right'>{dollarUSLocale.format(row.TotalWG)}</TableCell>


                                        </TableRow>

                                    ))}
                                </Table>
                            </Paper>
                        </Box>
                    </Box>
                )}
                 {typeof (dataSummary.ArchiveConversation) !== 'undefined' && isMessenger == 1 && (
                    <Paper style={{ margin: "10px 20px" }}>
                        {isArchiveLoading && (
                            <div style={{ margin: "5px" }}>
                                <table>
                                    <tr>
                                        <td>
                                            <h2>Loading...</h2>
                                        </td>
                                        <td>
                                            <SmallCircularProgress></SmallCircularProgress>

                                        </td>
                                    </tr>
                                </table>


                            </div>
                        )}
                        <Box style={{ background: "#E5E7E9", padding: "0.5rem", paddingLeft:'1rem', fontWeight: "bold" }}>
                            Archive Conversation History 
                        </Box>
                        {dataSummary.ArchiveConversation == null && (
                            <Box padding={'1rem'}>
                                <Typography variant="h5">No archive conversation found</Typography>
                            </Box>
                        )}
                        {dataSummary.ArchiveConversation != null && (
                            
                            <Box style={{ display: "table", border: "0px solid red" }}>
                                <Table size="small" className={classes.myTable} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Department</TableCell>
                                            <TableCell>Archive&nbsp;Date</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {dataSummary.ArchiveConversation.map((row: any) => (

                                        <TableRow hover >
                                            <TableCell><Chip size="small"  className={classes.cursorChip}  label={row.archiveTitle} 
                                            onClick={()=>{
                                                handleArchiveTitleClick(row.id);
                                                }}/></TableCell>
                                            <TableCell><Chip size="small" label={row.DepartmentName} /></TableCell>
                                            <TableCell>{formatDateTimeDateFns(row.DateUpdated,dateTimeFormats.MonthDayYearHourMinuteFormat)}</TableCell>


                                        </TableRow>

                                    ))}
                                </Table>
                            </Box>
                        )}
                    </Paper>
                )}
                {typeof (dataSummary.Task) !== 'undefined' && isTaskPermission == 1 && (
                    <Paper style={{ display: "table", margin: "10px 20px" }}>
                        <Box style={{ background: "#E5E7E9", padding: '0.5rem', paddingLeft: '1rem', fontWeight: "bold" }}>
                            Tasks
                        </Box>
                        {dataSummary.Task == null && (
                            <Box padding={'1rem'}>
                                <Typography variant="h5">No tasks found</Typography>
                            </Box>
                        )}
                        {dataSummary.Task != null && (
                            <Box style={{ display: "table", border: "0px solid red" }}>
                                <Table size="small" className={classes.myTable} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Created</TableCell>
                                            <TableCell>User</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Subject</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Notes</TableCell>
                                            <TableCell>Outcome</TableCell>
                                            <TableCell>Last&nbsp;Updated</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {dataSummary.Task.map((row: any) => (
                                        <TableRow hover >
                                            <TableCell>{ConvetToLocalTime(row.DateCreated)}</TableCell>
                                            <TableCell>{row.UserName}</TableCell>
                                            <TableCell>{row.Type}</TableCell>
                                            <TableCell>{row.TaskSubject}</TableCell>
                                            <TableCell>{row.Status}</TableCell>
                                            <TableCell>{row.Notes}</TableCell>
                                            <TableCell>{row.Outcome}</TableCell>
                                            <TableCell>{ConvetToLocalTime(row.LastUpdate)}</TableCell>
                                        </TableRow>
                                    ))}
                                </Table>

                            </Box>

                        )}
                    </Paper>
                )}
                {openArchvieConvPanelDrawer && (
                    <Box> 
                    <ArchiveConversationPanelDrawer
                    conversation={conversation as Conversation}
                    open={openArchvieConvPanelDrawer}
                    onClose={() => { 
                        SetOpenArchvieConvPanelDrawer(false);
                    }}></ArchiveConversationPanelDrawer>
                    </Box>
                    
                )}
            </Drawer>
        </div>
    )
}
export default CustomerSummary;