import { Box, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createTheme';
import { Alert } from '@mui/material';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
    mergeMessageAlert: {
        "& .MuiAlert-icon": {
          fontSize:'16px'
        }
      },
}));

function MergeTag({
    body,
    mergedUserName,
    dateMerged,
}: {
    body: string;
    dateMerged: string;
  mergedUserName: string;
}): React.ReactElement {
  const classes: any = useStyles();
  const formattedDateTime =  moment.utc(dateMerged).local().format('M-D h:mm a');
  return (
    <Box>
    <Alert severity="info" className={classes.mergeMessageAlert} sx={{ bgcolor: 'background.paper', fontSize: '10px' }}>
        {body} by {mergedUserName} on {formattedDateTime}
    </Alert>
    </Box>
  );
}

export default MergeTag;
