import React, { useState, useEffect, useRef } from 'react';
import { LocalAudioTrack, LocalVideoTrack, VideoTrack } from 'twilio-video';
import { TypeFormatFlags } from 'typescript';
import img from 'assets/images/userImage.png';
import InitialsAvatar from 'components/messaging/components/initials-avatar';
import { Box, Grid } from '@material-ui/core';

const LocalParticipant = ({
  key,
  participant,
  videoTrackEnable,
  setVideoTrackEnable,
  audioTrackEnable,
  setAudioTrackEnable,
}: {
  key: string;
  participant: any;
  videoTrackEnable: boolean;
  setVideoTrackEnable: (videoStatus: boolean) => void;
  audioTrackEnable: boolean;
  setAudioTrackEnable: (audioStatus: boolean) => void;
}) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  const trackpubsToTracks = (trackMap: any[]) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    const trackSubscribed = (track: any) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTrack: any) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }
    };
    const trackUnsubscribed = (track: any) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any) => videoTracks.filter((v: any) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any) => audioTracks.filter((a: any) => a !== track));
      }
    };
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    ToggleVideoTracks();
  }, [videoTrackEnable]);

  useEffect(() => {
    ToggleAudioTracks();
  }, [audioTrackEnable]);

  function ToggleVideoTracks() {
    if (videoTracks) {
      videoTracks.forEach((videoTrack: LocalVideoTrack) => {
        if (videoTrack) {
          if (videoTrackEnable) {
            videoTrack.enable();
          } else {
            videoTrack.disable();
          }
        }
      });
    }
  }

  function ToggleAudioTracks() {
    if (audioTracks) {
      audioTracks.forEach((audioTrack: any) => {
        if (audioTrack) {
          if (audioTrackEnable) {
            audioTrack.enable();
          } else {
            audioTrack.disable();
          }
        }
      });
    }
  }

  let userInfo = JSON.parse(participant.identity);
  return (
    <div className="participant" key={key} style={{ background: '#1F1F1F' }}>
      {/* <h3 style={{ position: 'absolute' }}>{userInfo.username}</h3> */}
      {
        <video
          style={{
            maxHeight: '90vh',
            width: '100%',
            background: 'black',
            display: videoTrackEnable ? 'block' : 'none',
          }}
          ref={videoRef}
          autoPlay={true}
        />
      }
      {
        !videoTrackEnable && (
          <Box
            style={{
              height: '150px',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
            }}>
            <InitialsAvatar
              name={userInfo.username?.replace(/[0-9]/g, '').replace('(', '').replace(')', '')}
              maxInitials={2}
              textSizeRatio={1.75}
              size="50"
              round
            />
          </Box>
        )

        // <img style={{ height: "200px", width: '100%', background: 'black', display : !videoTrackEnable ? "block" : "none" }} src={img} />
      }
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default LocalParticipant;
