import { Box, Button, Container, Drawer, TextField } from '@mui/material';
import React, { useState } from 'react';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { LostReason } from '../../models/deal-pipelines';
import AppSnackbar from 'components/snackbar/snackbar';
import EditIcon from '@mui/icons-material/Edit';
import LostDealAPI from 'components/setting/api/lost-deal-api';
import { useAppSelector } from 'app';
import CircularProgress from '@mui/material/CircularProgress';

function LostDealDrawer({ open, onClose }: { open: boolean; onClose: any }) {
  const [openDrawer, SetOpenDrawer] = React.useState<any>(open);

  const [lostReasons, SetLostReasons] = React.useState<LostReason[]>([]);
  const [currentReason, SetCurrentReason] = React.useState('' as string);
  const [currentId, SetCurrentId] = React.useState(-1 as number);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [showSpinner, SetSpinner] = React.useState(false);
  const [rowId, setRowId] = useState(-1);
  const [showEditSpinner, setEditSpinner] = React.useState(false);
  const [ReasonError, setReasonError] = React.useState(false);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function SetReason(value: string) {
    SetCurrentReason(value);
  }

  function EditReason(id: number) {
    setRowId(id);
    let result = lostReasons.find((obj) => {
      return obj.id === id;
    });

    if (result != null) {
      SetCurrentReason(result.reason);
      SetCurrentId(result.id);
    }
  }

  async function AddReasonToList() {
    if (currentReason.trim().length > 0) {
      if (currentId >= 0) {
        //Edit case
        setEditSpinner(true);
        
        const reasonObj: LostReason = {
          id: currentId,
          dmsDealerIdCrm: 0,
          reason: currentReason.trim(),
        };
        const response = await LostDealAPI.UpdateLostReason(dmsDealerIdCrm, reasonObj);

        SetLostReasons(response);
        setReasonError(false);
        SetCurrentReason('');
        setEditSpinner(false);
        showSnackBar({ type: 'success', message: 'Reason updated successfully' });
        SetCurrentId(-1);
      } else {
        //New Reason
        SetSpinner(true);
        const reasonObj: LostReason = {
          id: 0,
          dmsDealerIdCrm: 0,
          reason: currentReason.trim(),
        };

        const response = await LostDealAPI.AddLostReason(dmsDealerIdCrm, reasonObj);

        SetLostReasons(response);
        SetCurrentReason('');
        SetSpinner(false);
        setReasonError(false);
        showSnackBar({ type: 'success', message: 'Reason added successfully' });
       
      }
    } else {
      setReasonError(true);
    
    }
    
  }

  async function GetLostReasons() {
    SetSpinner(true);
    const response = await LostDealAPI.getLostReasons(dmsDealerIdCrm);
    SetLostReasons(response);
    SetSpinner(false);
  }
  React.useEffect(() => {
    GetLostReasons();
    SetOpenDrawer(open);
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setReasonError(false);
        SetReason('');
        onClose(0);
      }}>
      <Box style={{ width: '500px' }}>
        <DrawerToolbar
          title="Lost Deal Reasons"
          onClose={() => {
            setReasonError(false);
            SetReason('');
            onClose(0);
          }}
        />

        <Container>
          <Box mt={1}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  size="small"
                  value={currentReason}
                  error={ReasonError }
                  helperText={ReasonError && 'Please enter a valid reason'}
                  onChange={(e: any) => {

                    setReasonError(false);
                    if(e.target.value.trim() == '')
                      SetReason('');
                    else
                      SetReason(e.target.value);
                  }}
                  placeholder="Type lost deal reason here"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={AddReasonToList}
                  style={{ marginTop: 10, float: 'left', backgroundColor: '#3f51b5' }}>
                  Save
                </Button>
              </Grid>
            </Grid>
            {showSpinner ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ marginLeft: '200px', marginTop: '100px' }} />
              </Box>
            ) : (
              <TableContainer style={{ border: 2, marginTop: 30 }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {lostReasons.map((row: any, index: any) => (
                      <StyledTableRow hover key={row.id}>
                        <StyledTableCell scope="row">{row.reason}</StyledTableCell>
                        <TableCell
                          align="right"
                          style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
                          onClick={(event) => {EditReason(row.id );  setReasonError(false); }}>
                          {showEditSpinner && row.id === rowId && <CircularProgress size="1.1em" />}{' '}
                          <EditIcon style={{ fontSize: 'small' }}></EditIcon>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
export default LostDealDrawer;
