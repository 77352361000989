import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import useLocations from 'common/hooks/useLocations';
import AppSnackbar from 'components/snackbar/snackbar';

import AutoResponderSettingAPI from '../../api/auto-responder-setting-api';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const hours = [
  { label: '12 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '1 AM', value: '01:00' },
  { label: '1:30 AM', value: '01:30' },
  { label: '2 AM', value: '02:00' },
  { label: '2:30 AM', value: '02:30' },
  { label: '3 AM', value: '03:00' },
  { label: '3:30 AM', value: '03:30' },
  { label: '4 AM', value: '04:00' },
  { label: '4:30 AM', value: '04:30' },
  { label: '5 AM', value: '05:00' },
  { label: '5:30 AM', value: '05:30' },
  { label: '6 AM', value: '06:00' },
  { label: '6:30 AM', value: '06:30' },
  { label: '7 AM', value: '07:00' },
  { label: '7:30 AM', value: '07:30' },
  { label: '8 AM', value: '08:00' },
  { label: '8:30 AM', value: '08:30' },
  { label: '9 AM', value: '09:00' },
  { label: '9:30 AM', value: '09:30' },
  { label: '10 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '1 PM', value: '13:00' },
  { label: '1:30 PM', value: '13:30' },
  { label: '2 PM', value: '14:00' },
  { label: '2:30 PM', value: '14:30' },
  { label: '3 PM', value: '15:00' },
  { label: '3:30 PM', value: '15:30' },
  { label: '4 PM', value: '16:00' },
  { label: '4:30 PM', value: '16:30' },
  { label: '5 PM', value: '17:00' },
  { label: '5:30 PM', value: '17:30' },
  { label: '6 PM', value: '18:00' },
  { label: '6:30 PM', value: '18:30' },
  { label: '7 PM', value: '19:00' },
  { label: '7:30 PM', value: '19:30' },
  { label: '8 PM', value: '20:00' },
  { label: '8:30 PM', value: '20:30' },
  { label: '9 PM', value: '21:00' },
  { label: '9:30 PM', value: '21:30' },
  { label: '10 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' },
];

const AutoResponder: React.FC = () => {
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { getLocationName } = useLocations(dmsDealerIdCrm);
  const [autoResponderSettings, setAutoResponderSetting] = React.useState<any[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accordionsStatus, setAccordionsStatus] = React.useState<boolean[]>([]);

  React.useEffect(() => {
    async function fetchAutoResponderSetting(): Promise<void> {
      try {
        setLoading(true);
        const response = await AutoResponderSettingAPI.getAutoResponderSetting(dmsDealerIdCrm);
        if (response.length) {
          const accordionsOpenState = response.map((): boolean => false);
          accordionsOpenState[0] = true;
          setAccordionsStatus(accordionsOpenState);
        }
        setAutoResponderSetting(response);
        setLoading(false);
      } catch (error) {
        showSnackBar({ type: 'error', message: 'Unable to fetch Auto responder settings' });
        setLoading(false);
      }
    }
    fetchAutoResponderSetting();
  }, [dmsDealerIdCrm, showSnackBar]);

  async function saveAutoResponderSetting(): Promise<void> {
    try {
      setSaving(true);
      await AutoResponderSettingAPI.updateAutoResponderSetting(dmsDealerIdCrm, autoResponderSettings);
      showSnackBar({ type: 'success', message: 'Auto responder settings updated' });
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  }

  function handleAccordionChange(event: any, idx: number): void {
    if (event.target.name !== 'isEnabled') {
      const newAccordions = [...accordionsStatus];
      newAccordions[idx] = !newAccordions[idx];
      setAccordionsStatus(newAccordions);
    }
  }

  function handleChange(event: any, idx: number): void {
    if (event.target.type === 'checkbox') {
      const { name, checked } = event.target;
      const newSettings = [...autoResponderSettings];
      newSettings[idx][name] = checked;
      setAutoResponderSetting(newSettings);
    } else {
      const { name, value } = event.target;
      const newSettings = [...autoResponderSettings];
      newSettings[idx][name] = value;
      setAutoResponderSetting(newSettings);
    }
  }

  return (
    <Paper style={{ height: '600px', overflow: 'scroll' }}>
      <Container>
        {loading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <SmallCircularProgress />
          </Box>
        ) : (
          <>
            <Box mt={2} display="flex" flexDirection="row-reverse">
              <Button variant="contained" color="primary" disabled={saving} onClick={saveAutoResponderSetting}>
                Save
              </Button>
            </Box>
            <Box mt={2}>
              {autoResponderSettings.map((autoResponderSetting, idx) => (
                <Accordion
                  key={autoResponderSetting.dealerId}
                  elevation={3}
                  expanded={accordionsStatus[idx]}
                  style={{ marginBottom: '10px' }}>
                  <AccordionSummary
                    onClick={(event: any): void => handleAccordionChange(event, idx)}
                    expandIcon={<ExpandMoreIcon />}>
                    <Box alignItems="center" display="flex" flexGrow={1} height="100%">
                      <Box>{getLocationName(autoResponderSetting.dealerId)}</Box>
                      <Box flexGrow={1} />
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isEnabled"
                                checked={autoResponderSetting.isEnabled}
                                onChange={(event: any): void => handleChange(event, idx)}
                              />
                            }
                            label="Enabled"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Box mt={4}>
                          <TextLimitField
                            fullWidth
                            multiline
                            rows={4}
                            label="Default Automatic Text Responder"
                            name="duringHoursMessage"
                            value={autoResponderSetting.duringHoursMessage}
                            onChange={(event: any): void => handleChange(event, idx)}
                            variant="outlined"
                            disabled={!autoResponderSetting.isEnabled}
                            characters={160}
                          />
                        </Box>
                        <Box mt={4}>
                          <TextLimitField
                            fullWidth
                            multiline
                            rows={4}
                            value={autoResponderSetting.afterHoursMessage}
                            onChange={(event: any): void => handleChange(event, idx)}
                            label="After Hours Message"
                            variant="outlined"
                            name="afterHoursMessage"
                            disabled={!autoResponderSetting.isEnabled}
                            characters={160}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Box mt={4}>
                              <Typography variant="h5">Business Hours</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box mt={4}>
                              <Typography variant="h5">Closed</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box mt={2}>
                          {days.map((day) => (
                            <Grid container key={day} style={{ alignItems: 'center' }}>
                              <Grid item xs={10}>
                                <Grid container style={{ alignItems: 'center' }}>
                                  <Grid item xs={4}>
                                    {day}
                                  </Grid>
                                  {autoResponderSetting[`is${day}Closed`] ? (
                                    <Grid item xs={8} style={{ textAlign: 'center' }}>
                                      Closed
                                    </Grid>
                                  ) : (
                                    <>
                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          variant="outlined"
                                          size="medium"
                                          style={{ width: '105px' }} // Set your desired fixed width here

                                          margin="dense"
                                          value={autoResponderSetting[`${day.toLowerCase()}From`]}
                                          disabled={!autoResponderSetting.isEnabled}
                                          onChange={(event: any): void => handleChange(event, idx)}
                                          name={`${day.toLowerCase()}From`}>
                                          {hours.map((hour) => (
                                            <MenuItem key={hour.value} value={hour.value}>
                                              {hour.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      <Grid item xs={2}>
                                        To
                                      </Grid>
                                      <Grid item xs={3}>
                                        <TextField
                                          select
                                          variant="outlined"
                                          size="medium"
                                          style={{ width: '105px' }} // Set your desired fixed width here
                                          margin="dense"
                                          value={autoResponderSetting[`${day.toLowerCase()}To`]}
                                          disabled={!autoResponderSetting.isEnabled}
                                          onChange={(event: any): void => handleChange(event, idx)}
                                          name={`${day.toLowerCase()}To`}>
                                          {hours.map((hour) => (
                                            <MenuItem key={hour.value} value={hour.value}>
                                              {hour.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Switch
                                  key={day}
                                  name={`is${day}Closed`}
                                  checked={autoResponderSetting[`is${day}Closed`]}
                                  disabled={!autoResponderSetting.isEnabled}
                                  onChange={(event: any): void => handleChange(event, idx)}
                                />
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </>
        )}
      </Container>
    </Paper>
  );
};

export default AutoResponder;
