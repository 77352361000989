import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Switch,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Chip from '@mui/material/Chip';
import { useAppSelector } from 'app';
import { CustomerDto } from 'common/models';
import { formatPhoneNumber } from 'common/utils';
import CustomerAPI, { CustomerEmailType, CustomerPhoneType } from 'components/customer/api/CustomerAPI';
import { CustomerContacResponse } from 'components/deals/model/dealsInterfaces';
import AppSnackbar from 'components/snackbar/snackbar';
import { useFormik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';

import EmailLink from 'common/components/buttons/email-link';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide
      keepCharPositions
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};
const formatDate = (date: string): string => {
  if (date) return moment(date).format('M/DD/YYYY');
  else {
    return '';
  }
};
export interface CustomerContactNew {
  dmsId: number;
  crmCustomerId: string;
  firstName: string;
  lastName: string;
  name: string;
  jobTitle: string;
  dateOfBirth: '';
  contactId?: string;
  isPrimary: boolean;
  phones?: ContactPhone[];
  emails?: ContactEmail[];
}

export interface ContactPhone {
  Id?: number;
  phoneNumber: string;
  phoneType: number;
  extension: string;
  isDefault: boolean;
}
export interface ContactEmail {
  Id?: number;
  address: string;
  emailType: number;
  isDefault: boolean;
}
export function CustomerContactDrawer({
  open,
  selectedCustomers,
  editContact,
  addCustomer,
  setNewCustomerContact,
  onClose,
  IsCustomerOrganization,
  defaultPhoneType,
  convPhoneIds,
}: {
  open: boolean;
  selectedCustomers: CustomerDto[] | undefined;
  editContact?: any;
  addCustomer?: any;
  setNewCustomerContact(val: CustomerContacResponse | null): void;
  onClose(val: boolean): void;
  IsCustomerOrganization?: any;
  defaultPhoneType?: number;
  convPhoneIds: number[] | undefined;
}): ReactElement {
  const useStyles = makeStyles((theme: Theme) => ({
    textFieldPadding: {
      paddingBottom: '5%',
    },
    errorMsg: {
      color: '#e53935',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '-0.06px',
      marginLeft: '10px',
      marginTop: '-15px',
    },
    muiPopoverPaper: {
      '@media screen and (max-width: 1280px)': {
        width: '50%',
        maxHeight: '200px !important',
        top: '40% !important',
        left: '0 !important',
        right: '0',
        margin: '0 auto',
      },
    },
  }));
  const classes = useStyles();
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [phoneTypes, setPhoneTypes] = useState<CustomerPhoneType[]>([]);
  const [emailTypes, setEmailTypes] = useState<CustomerEmailType[]>([]);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchLookups() {
      let phoneTypes = await CustomerAPI.getPhoneTypes(dealerId);
      let emailTypes = await CustomerAPI.getEmailTypes(dealerId);

      //Inifinity has only Cell Phone number
      if (dmsTypeId == 1) {
        phoneTypes = phoneTypes.filter((x) => x.customerPhoneTypeId == 1);
      }
      setPhoneTypes(phoneTypes);
      setEmailTypes(emailTypes);
    }
    fetchLookups();
  }, [1]);
  let initialValues: CustomerContactNew = {
    dmsId: dmsTypeId,
    crmCustomerId: editContact ? editContact.CrmCustomerId : addCustomer || '',
    contactId: editContact ? editContact.ContactId : '',
    firstName: editContact ? editContact.FirstName : '',
    lastName: editContact ? editContact.LastName : '',
    name: editContact ? editContact.Name : '',
    jobTitle: editContact ? editContact.JobTitle : '',
    dateOfBirth: editContact ? editContact.DateOfBirth : '',
    phones: editContact ? (editContact.Phones != null ? editContact.Phones : []) : [],
    emails: editContact && editContact.Emails != null ? editContact.Emails : [],
    isPrimary: editContact && editContact.Primary != null ? editContact?.Primary : false,
  };

  const validationSchema = Yup.object<any>({
    crmCustomerId: Yup.string().required('Customer Required'),
    firstName: dmsTypeId == 0 || dmsTypeId == 9 ? Yup.string().required('First Name Required') : undefined,
    lastName: dmsTypeId == 0 || dmsTypeId == 9 ? Yup.string().required('Last Name Required') : undefined,
    name: dmsTypeId == 1 ? Yup.string().required('Name Required') : undefined,
    jobTitle: Yup.string().max(50,'Job Title cannot exceed 50 characters.')
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (customerContact: any) => {
      if (editContact && editContact.ContactId != 0) {
        customerContact.contactId = editContact.ContactId;
      } else {
        customerContact.contactId = '0';
      }
      if(dmsTypeId == DmsTypeEnum.Aspen){
        if (customerContact.phones != null && customerContact.phones.length > 0 && !customerContact.phones?.find((phone: { IsDefault: boolean; }) => phone.IsDefault == true)) {
          showSnackBar({ type: 'error', message: 'At least one phone should be Default.' });
          return;
        }
        if (customerContact.emails != null && customerContact.emails.length > 0 && !customerContact.emails?.find((email: { IsDefault: boolean; }) => email.IsDefault == true)) {
          showSnackBar({ type: 'error', message: 'At least one email should be Default.' });
          return;
        }
      }
      handleSubmitCustomerContact(customerContact);
    },
  });

  async function handleSubmitCustomerContact(customerContact: CustomerContactNew): Promise<void> {
    try {
      if (dmsTypeId == DmsTypeEnum.Ideal) {
        customerContact.name = [customerContact.lastName, customerContact.firstName].join(', ');
      }
      if (dmsTypeId == DmsTypeEnum.Aspen) {
        customerContact.name = [customerContact.firstName, customerContact.lastName].join(', ');
      }

      let phoneNumbers: any[] = [];
      //Need to unformat phone numbers for DB insertion
      if (customerContact.phones) {
        customerContact.phones.forEach((x:any) => {
          if (x.PhoneNumber) {
            let p = {
              ...x,
              PhoneNumber: x.PhoneNumber.replace(/[^0-9]/g,''),
            };
            phoneNumbers.push(p);
          }
        });
      }
      customerContact.phones = phoneNumbers;
     
      setLoading(true);
      const response = (await CustomerAPI.addCustomerContact(dealerId, customerContact)).data as CustomerContacResponse;

      if (response) {
        setNewCustomerContact(response);
        showSnackBar({ type: 'success', message: `Contact ${editContact ? 'updated' : 'added'} successfully.` });
        onCloseHandler();
      } else {
        showSnackBar({ type: 'error', message: `Unable to ${editContact ? 'update' : 'add'} contact.` });
        throw new Error('Unable to add contact.');
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const message = typeof error === "string" ? error:'Unable to add contact.';
      showSnackBar({ type: 'error', message:message  });
    }
  }

  function onCloseHandler() {
    onClose(false);
    formik.resetForm();
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={() => {
          onCloseHandler();
          // editContact && setEnableEdit(true);
        }}>
        {!loading ? (
          <Box style={{ width: '550px' }}>
            <Toolbar variant="dense">
              <Box flexGrow={1}>
                <Typography variant="h4">{editContact ? 'Edit' : 'Add'} Customer Contact</Typography>
              </Box>
              <IconButton onClick={() => onCloseHandler()}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <Container>
              <Box mt={1}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      size="small"
                      color="primary"
                      type="submit"
                      onClick={() => formik.handleSubmit()}>
                      {editContact ? 'Update' : 'Save'}
                    </Button>
                  </Grid>
                  {selectedCustomers && selectedCustomers?.length > 0 && (
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        name="crmCustomerId"
                        placeholder="Customer"
                        label="Customer"
                        variant="outlined"
                        size="small"
                        value={formik.values.crmCustomerId}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              className: classes.muiPopoverPaper,
                            },
                          },
                        }}
                        onChange={formik.handleChange}
                        error={!!formik.errors.crmCustomerId}
                        helperText={formik.errors.crmCustomerId}>
                        {selectedCustomers?.map((options) => {
                          return <MenuItem value={options.id}>{options.name}</MenuItem>;
                        })}
                      </TextField>
                    </Grid>
                  )}
                  {(dmsTypeId == 0 || dmsTypeId == 9) && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled={dmsTypeId == 0 && IsCustomerOrganization != 1 && formik.values.isPrimary}
                          name="firstName"
                          value={formik.values.firstName}
                          error={!!formik.errors.firstName}
                          helperText={formik.errors.firstName}
                          onChange={formik.handleChange}
                          label="First Name"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          disabled={dmsTypeId == 0 && IsCustomerOrganization != 1 && formik.values.isPrimary}
                          name="lastName"
                          value={formik.values.lastName}
                          error={!!formik.errors.lastName}
                          helperText={formik.errors.lastName}
                          onChange={formik.handleChange}
                          label="Last Name"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </>
                  )}

                  {dmsTypeId == 1 && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        error={!!formik.errors.name}
                        helperText={formik.errors.name}
                        onChange={formik.handleChange}
                        label="Name"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="jobTitle"
                      value={formik.values.jobTitle}
                      onChange={formik.handleChange}
                      error={!!formik.errors.jobTitle}
                      helperText={formik.errors.jobTitle}
                      label="Job Title/Relationship"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={formik.values.dateOfBirth}
                        maxDate={new Date()}
                        onChange={(e: any) => {
                          if (!e) {
                            e = '';
                          }
                          formik.setFieldValue('dateOfBirth', formatDate(e));
                        }}
                        renderInput={(params: any) => {
                          return (
                            <TextField
                              fullWidth
                              name="dateOfBirth"
                              {...params}
                              size="small"
                              helperText={formik.errors.dateOfBirth}
                              variant="outlined"
                              error={!!formik.errors.dateOfBirth}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {(dmsTypeId == 9 ||
                    (dmsTypeId == 0 && IsCustomerOrganization) ||
                    (dmsTypeId == 0 && formik.values.isPrimary)) && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="isPrimary"
                              disabled={formik.values.isPrimary}
                              checked={formik.values.isPrimary}
                              onChange={formik.handleChange}
                            />
                          }
                          label="Primary"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <hr />
                    <ContactPhoneList
                      formik={formik}
                      phoneTypes={phoneTypes}
                      dmsTypeId={dmsTypeId}
                      defaultPhoneType={defaultPhoneType}
                      convPhoneIds={convPhoneIds}
                    />
                    <hr />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactEmailList formik={formik} emailTypes={emailTypes} dmsTypeId={dmsTypeId} />
                    <hr />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="40%" style={{ width: '400px' }}>
            <CircularProgress />
          </Box>
        //   <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '400px' }} minHeight="100vh">
        //   <CircularProgress />
        // </Box>
        
        )}
      </Drawer>
    </form>
  );
}

function ContactPhoneList({
  formik,
  phoneTypes,
  dmsTypeId,
  defaultPhoneType,
  convPhoneIds,
}: {
  formik: any;
  phoneTypes: CustomerPhoneType[];
  dmsTypeId: number;
  defaultPhoneType?: number;
  convPhoneIds: number[] | undefined;
}): ReactElement {
  const [popoverState, setPopoverState] = useState<any>({ openedPopoverId: null, anchorEl: null });
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const classes: any = useStyles();

  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopoverState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  };
  const handlePopoverClose = () => {
    setPopoverState({
      openedPopoverId: null,
      anchorEl: null,
    });

    const data = formik.values.phones.length - 1;
    if (formik.values.phones[data].PhoneNumber === '') {
      let contactPhones = [...formik.values.phones];
      contactPhones.splice(data, 1);
      formik.setFieldValue('phones', contactPhones);
    }
  };
  const addPhoneRow = (event: any) => {
    //Infinity has only one cell phone
    if (dmsTypeId == 1 && formik.values.phones.length == 1) {
      showSnackBar({ type: 'info', message: 'Only one phone number is allowed.' });
      return;
    }
    //Ideal has only four phone numbers
    if (dmsTypeId == 0 && formik.values.phones.length == 4) {
      showSnackBar({ type: 'info', message: 'Only four phone numbers are allowed.' });
      return;
    }

    let contactPhones: any = [];
    if (formik.values.phones && formik) {
      contactPhones = [...formik.values.phones];
    }

    let allPhoneTypes: number[] = phoneTypes.map((x: CustomerPhoneType) => x.customerPhoneTypeId);
    let phoneType: number[] = [];
    phoneType = contactPhones.map((x: any) => x.PhoneType);

    let notAddedPhoneTypes = allPhoneTypes.filter((x: number) => phoneType.indexOf(x) == -1);
    if (notAddedPhoneTypes.length > 0) {
      const contactPhone: any = {
        Id: 0,
        ContactId: 0,
        PhoneType: notAddedPhoneTypes[0],
        PhoneNumber: '',
        PhoneTypeName: '',
        IsCellPhone: false,
        Extension: '',
        IsDefault: dmsTypeId == 9 && formik.values.phones.length == 0 ? true : false,
      };
      contactPhones.push(contactPhone);
      formik.setFieldValue('phones', contactPhones);
      const _index = contactPhones.indexOf(contactPhone);
      handlePopoverOpen(event, _index);
    }
  };
  const removePhoneRow = (index: number) => {
    let contactPhones = [...formik.values.phones];
    contactPhones.splice(index, 1);
    formik.setFieldValue('phones', contactPhones);
  };
  const removePhoneRowHandler = (index: number, phoneId: number) => {
    if (!convPhoneIds?.includes(phoneId)) {
      removePhoneRow(index);
    } else {
      showSnackBar({
        type: 'error',
        message: 'Cannot delete a phone number that is associated with a conversation',
      });
    }
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <LocalPhoneIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">Phone</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography variant="h5">
                <AddIcon
                  onClick={(e: any) => {
                    addPhoneRow(e);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          {formik &&
            formik.values.phones.map((row: any, index: number) => {
              return (
                <>
                  <Box mt={1} key={index}>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography variant="body1">
                          {formik.values.phones[index].PhoneNumber ? (
                            formatPhoneNumber(formik.values.phones[index].PhoneNumber) +
                            ' ' +
                            (formik.values.phones[index].Extension != null &&
                            formik.values.phones[index].Extension != '' &&
                            (dmsTypeId === 9 || dmsTypeId === 0)
                              ? '(' + formik.values.phones[index].Extension + ')'
                              : '')
                          ) : (
                            <span style={{ color: 'rgb(152, 152, 152)' }}>(000) 000-0000</span>
                          )}
                          &nbsp;
                          <Chip
                            size="small"
                            label={
                              phoneTypes.filter(
                                (x) => formik.values.phones[index].PhoneType == x.customerPhoneTypeId,
                              )[0]?.name
                            }
                            color="primary"
                            variant="filled"
                          />
                          {row.IsDefault && dmsTypeId === 9 && <>&nbsp;</>}
                          {row.IsDefault && dmsTypeId === 9 && (
                            <Chip size="small" label={'Default'} color="info" variant="filled" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ marginTop: '-10px' }}
                          aria-describedby={index.toString()}
                          onClick={(e: any) => handlePopoverOpen(e, index)}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                        <ClearIcon
                          fontSize="small"
                          aria-describedby={index.toString()}
                          onClick={(e: any) => {
                            const phoneId = Number(formik.values.phones[index]?.Id);
                            removePhoneRowHandler(index, phoneId);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Popover
                    id={'pop_' + index}
                    open={popoverState.openedPopoverId === index}
                    anchorEl={popoverState.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box p={2} style={{ width: '500px' }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={8}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            id={`phones_${index}.PhoneNumber`}
                            name={`phones[${index}].PhoneNumber`}
                            label="Phone Number"
                            value={formik.values.phones[index].PhoneNumber}
                            onChange={(e) => {
                              formik.setFieldValue(`phones[${index}].PhoneNumber`, e.target.value);
                            }}
                            InputProps={{
                              inputComponent: TextMaskCustom as any,
                            }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {(dmsTypeId === 9 || dmsTypeId === 0) && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              id={`phones_${index}.Extension`}
                              name={`phones[${index}].Extension`}
                              label="Extension"
                              value={formik.values.phones[index].Extension}
                              onChange={(e) => {
                                formik.setFieldValue(`phones[${index}].Extension`, e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === '-' ||
                                  event?.key === '+' ||
                                  formik.values.phones[index].Extension.length > 4
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              InputProps={{
                                type: 'number',
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          {dmsTypeId === 9 && (
                            <Box ml={1}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={row.IsDefault}
                                    onChange={(e) => {
                                      formik.values.phones.forEach((e: any, i: number) => {
                                        if (i !== index) {
                                          formik.setFieldValue(`phones[${i}].IsDefault`, false);
                                        }
                                      });
                                      formik.setFieldValue(`phones[${index}].IsDefault`, e.target.checked);
                                    }}
                                    checked={row.IsDefault}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Default"
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            margin="dense"
                            id={`phones_${index}.PhoneType`}
                            name={`phones[${index}].PhoneType`}
                            label={`Type`}
                            value={formik.values.phones[index].PhoneType}
                            onChange={(e: any) => {
                              formik.setFieldValue(`phones[${index}].PhoneType`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}>
                            {phoneTypes.map((option: CustomerPhoneType) => {
                              return (
                                <MenuItem
                                  disabled={
                                    formik.values.phones.filter((x: any) => {
                                      if (formik.values.phones) {
                                        return x.PhoneType === option.customerPhoneTypeId;
                                      }
                                    })?.length > 0 && dmsTypeId == 0
                                  }
                                  key={option.customerPhoneTypeId}
                                  value={option.customerPhoneTypeId}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerAddressBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}

function ContactEmailList({
  formik,
  emailTypes,
  dmsTypeId,
}: {
  formik: any;
  emailTypes: CustomerEmailType[];
  dmsTypeId: number;
}): ReactElement {
  const [emailError, setEmailError] = useState<{ notEmail: boolean; helperText: string }>({
    notEmail: false,
    helperText: '',
  });
  const [popoverState, setPopoverState] = useState<any>({ openedPopoverId: null, anchorEl: null });
  const [showSnackBar] = AppSnackbar.useSnackBar();

  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopoverState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  };
  const handlePopoverClose = () => {
    !emailError.notEmail &&
      setPopoverState({
        openedPopoverId: null,
        anchorEl: null,
      });

    const data = formik.values.emails.length - 1;
    if (formik.values.emails[data].Address === '') {
      let contactEmails = [...formik.values.emails];
      contactEmails.splice(data, 1);
      formik.setFieldValue('emails', contactEmails);
    }
  };

  const addEmailRow = (event: any) => {
    if ((dmsTypeId == 1 || dmsTypeId == 0) && formik.values.emails.length == 1) {
      showSnackBar({ type: 'info', message: 'Only one email is allowed.' });
      return;
    }
    let emails = [...formik.values.emails];

    if (dmsTypeId != 9) {
      let allEmailTypes: number[] = emailTypes.map((x: CustomerEmailType) => x.customerEmailTypeId);
      let emailType: number[] = emails.map((x: ContactEmail) => x.emailType);
      let notAddedEmailTypes = allEmailTypes.filter((x: number) => emailType.indexOf(x) == -1);
      if (notAddedEmailTypes.length > 0) {
        const contactEmail: any = {
          Id: 0,
          Address: '',
          EmailType: emailTypes[0] ? emailTypes[0].customerEmailTypeId : 0,
          IsDefault: dmsTypeId == 9 && formik.values.emails.length == 0 ? true : false,
        };
        emails.push(contactEmail);
        formik.setFieldValue('emails', emails);
        const _index = emails.indexOf(contactEmail);
        handlePopoverOpen(event, _index);
      }
    } else {
      const contactEmail: any = {
        Id: 0,
        Address: '',
        EmailType: emailTypes[0] ? emailTypes[0].customerEmailTypeId : 0,
        IsDefault: dmsTypeId == 9 && formik.values.emails.length == 0 ? true : false,
      };
      emails.push(contactEmail);
      formik.setFieldValue('emails', emails);
      const _index = emails.indexOf(contactEmail);
      handlePopoverOpen(event, _index);
    }
  };
  const removeEmailRow = (index: number) => {
    let contactEmails = [...formik.values.emails];
    contactEmails.splice(index, 1);
    formik.setFieldValue('emails', contactEmails);
  };
  function ValidateEmail(email: any, index: number) {
    if (email.length > 0 && !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      formik.setFieldError(`emails[${index}].address`, 'Invalid Email', false);
      setEmailError({ notEmail: true, helperText: 'Invalid Email' });
    } else {
      setEmailError({ notEmail: false, helperText: '' });
    }
  }
  const classes: any = useStyles();
  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <EmailIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">Email</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* {dmsTypeId == 9 || (formik.values.emails.length == 0) && ( */}
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography variant="h5">
                <AddIcon
                  onClick={(e: any) => {
                    addEmailRow(e);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* )} */}
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          {formik.values.emails &&
            formik.values.emails.map((row: any, index: number) => {
              return (
                <>
                  <Box mt={1}>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography variant="body1">
                          {formik.values.emails[index].Address ? (
                             <EmailLink email={formik.values.emails[index].Address} />
                          ) : (
                            <span style={{ color: 'rgb(152, 152, 152)' }}>address@example.com</span>
                          )}
                          &nbsp; &nbsp;
                          <Chip
                            size="small"
                            label={
                              emailTypes.filter(
                                (x) => formik.values.emails[index].EmailType === x.customerEmailTypeId,
                              )[0]?.name
                            }
                            color="primary"
                            variant="filled"
                          />
                          {row.IsDefault && dmsTypeId === 9 && <>&nbsp;</>}
                          {row.IsDefault && dmsTypeId === 9 && (
                            <Chip size="small" label={'Default'} color="info" variant="filled" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ marginTop: '-10px' }}
                          aria-describedby={index.toString()}
                          onClick={(e: any) => handlePopoverOpen(e, index)}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                        <ClearIcon
                          fontSize="small"
                          aria-describedby={index.toString()}
                          onClick={(e: any) => {
                            removeEmailRow(index);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Popover
                    id={'pop_' + index}
                    open={popoverState.openedPopoverId === index}
                    anchorEl={popoverState.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box p={2} style={{ width: '500px' }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            id={`emails_${index}.Address`}
                            name={`emails[${index}].Address`}
                            label="Email Address"
                            value={formik.values.emails[index].Address}
                            onChange={(e) => {
                              formik.setFieldValue(`emails[${index}].Address`, e.target.value);
                            }}
                            onChangeCapture={(e: any) => {
                              ValidateEmail(e.target.value, index);
                            }}
                            error={emailError.notEmail}
                            helperText={emailError.helperText}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            margin="dense"
                            id={`emails_${index}.EmailType`}
                            name={`emails[${index}].EmailType`}
                            label={`Type`}
                            value={formik.values.emails[index].EmailType}
                            onChange={(e: any) => {
                              formik.setFieldValue(`emails[${index}].EmailType`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}>
                            {emailTypes.map((option: CustomerEmailType) => {
                              return (
                                <MenuItem key={option.customerEmailTypeId} value={option.customerEmailTypeId}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={3}>
                          <Box ml={1} mt={1}>
                            {dmsTypeId === 9 && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={row.IsDefault}
                                    onChange={(e) => {
                                      formik.setFieldValue(`emails[${index}].IsDefault`, e.target.checked);
                                      formik.values.emails.forEach((e: any, i: number) => {
                                        if (i !== index) {
                                          formik.setFieldValue(`emails[${i}].IsDefault`, false);
                                        }
                                      });
                                    }}
                                    checked={row.IsDefault}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Default"
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerEmailBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}
