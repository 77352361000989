import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  MenuItem,
} from '@material-ui/core';
import { ReactElement, useContext, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DealItem } from '../model/dealsInterfaces';
import { useFormik } from 'formik';
import User from 'components/setting/models/user';
import PipelineDeals from 'components/deals/apis/deals-api';
import AppSnackbar from 'components/snackbar/snackbar';
import useStyles from '../styles/deals-styles';
import DealContext from '../contexts/deal-context';

export function AssignDealDrawer({
  open,
  editCount,
  selectedColumn,
  renderDeals,
  onClose,
}: {
  open: boolean;
  selectedColumn: DealItem[];
  editCount: number;
  renderDeals: () => void;
  onClose(val: boolean): void;
}): ReactElement {
  const classes = useStyles();
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const dealContext = useContext(DealContext);

  const [users, setUsers] = useState<User[]>([]);
  let user = dealContext.user;

  const formik = useFormik({
    initialValues: {
      deals: selectedColumn,
    },
    onSubmit: async (values) => {
      const assignValues = [...selectedColumn];
      if (values.deals.length > 0) {
        assignValues.map((data, _index) => {
          values.deals.map((val, index) => {
            if (_index === index) {
              data.assignedUserId = val.assignedUserId;
            }
          });
        });
      }
      if (assignValues && assignValues.length > 0) {
        const unselectedAssignment = assignValues.find((x) => !x.assignedUserId);
        if (unselectedAssignment) {
          showSnackBar({
            type: 'error',
            message: `Select assignment for seleted ${assignValues.length == 1 ? 'deal' : 'deals'}`,
          });
          return;
        }

        await PipelineDeals.PipelineDeals.AssignDealToUser(assignValues).then((response) => {
          if (response === 'Ok') {
            onClose(false);
            renderDeals();
            showSnackBar({ type: 'success', message: 'Sales Person Assigned Successfully' });
          } else {
            onClose(false);
            showSnackBar({ type: 'error', message: 'Something Went Wrong' });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (!open) {
      formik.values.deals = [];
    }
    setUsers(user!);
  }, [user, selectedColumn, formik.values]);
  return (
    <Drawer anchor={'right'} open={open} onClose={() => onClose(false)}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.primary} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Edit {editCount + (editCount > 1 ? ' Deals' : ' Deal')}: Assign</Typography>
          </Box>
          <IconButton id="closeBtn" onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Container>
          <Box mt={1}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button variant="contained" size="small" color="primary" onClick={() => formik.handleSubmit()}>
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {selectedColumn.map((deal, index) => {
                  return (
                    <form onSubmit={formik.handleSubmit}>
                      <Box key={deal.id} mt={2} mb={2}>
                        <Typography variant="h4"> {deal.title}</Typography>
                        <Typography variant='body1'>{deal.customer}</Typography>

                        <TextField
                          select
                          key={`deals[${index}].id`}
                          name={`deals[${index}].assignedUserId`}
                          variant="outlined"
                          margin="dense"
                          label="Assignment"
                          fullWidth
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                className: classes.muiPopoverPaper,
                              },
                            },
                          }}
                          onChange={formik.handleChange}
                          placeholder="Select Users"
                          style={{ color: 'rgb(50, 50, 50) !important' }}>
                          {users?.map((options) => {
                            return <MenuItem value={options.crmUserId}>{options.username}</MenuItem>;
                          })}
                        </TextField>
                      </Box>

                      <Divider style={{ height: '3px', background: '#a9a9a9' }} />
                    </form>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
