import React, { ReactElement } from 'react';
import icon from 'assets/images/feedback.svg';

export function Logo(): ReactElement {
  return <img src="/logo.png" alt="TargetCRM icon" height="40px" style={{ paddingLeft: "15px" }} />;
}


export function feedbackIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`icon icon-${props.name}`}>
      <use xlinkHref={`${icon}#icon-${props.name}`} />
    </svg>
  );
}


export default Logo;
