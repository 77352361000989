import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 550;

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  containerShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    position: 'static',
    height: '45px',
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: 'block',
  },
  reviewHubBar: {
    float: 'right',
    marginTop: '5px',
  },
  barInfoIcon: {
    cursor: 'pointer',
    marginLeft: '-7px',
  },
}));

export default useStyles;
