import { Chip, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  avatar: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));
export default function AddFilterChip({ onClick }: { onClick(): void }): React.ReactElement {
  const classes: any = useStyles();
  return (
    <Chip
      classes={{ avatar: classes.avatar }}
      avatar={<AddIcon />}
      color="primary"
      onClick={onClick}
      label="Add Filters"
    />
  );
}
