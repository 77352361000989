import { createSlice } from '@reduxjs/toolkit';

export interface DrawerState {
  drawerType: string | null;
  drawerProps: any;
}

const initialState: DrawerState = {
  drawerType: null,
  drawerProps: {},
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    showDrawer: (state, action) => ({
      drawerType: action.payload.drawerType,
      drawerProps: action.payload.drawerProps,
    }),
    hideDrawer: (state) => initialState,
  },
});

export const { showDrawer, hideDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
