import {
  Box,
  Button, Grid,
  IconButton, Popover, TextField, Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import { useStyles } from './CustomerForm';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';

export function JobTitle({ formik }: { formik: any; }): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const classes: any = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
    // formik.setFieldValue('salesRepName', salesPerson);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // const [salesPerson, setSalesPerson] = useState<string>("");
  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item style={{ marginRight: '5px', alignItems:"center", display: 'flex', justifyContent: 'center' }}>
        {' '}
        <WorkOutlinedIcon style={{ fontSize: '1.2rem' }} />{' '}
      </Grid>
      <Grid item>
        <Typography style={{ marginTop: '0px' }} variant="body1">
          Job Title:&nbsp;
          {formik.values?.jobTitle}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          <TextField
            fullWidth
            variant="outlined"
            autoFocus
            margin="dense"
            id="jobTitle"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            name="jobTitle"
            label={`Job Title`}
            value={formik.values?.jobTitle}
            onChange={(e: any) => {
              formik.setFieldValue('jobTitle', e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }} />
          <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerAddressBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
}
