import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import PdfSVG from 'assets/images/pdf.svg';

const useStyles = makeStyles(() => ({
  logo: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

function PDF(): ReactElement {
  const classes: any = useStyles();
  return <img src={PdfSVG} alt="PDF icon" className={classes.logo} />;
}

export default PDF;
