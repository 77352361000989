import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import FireSvg from 'assets/images/fire.svg';

const useStyles = makeStyles(() => ({
  logo: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

function Fire(): ReactElement {
  const classes: any = useStyles();
  return <img src={FireSvg} alt="Fire icon" className={classes.logo} />;
}

export default Fire;
