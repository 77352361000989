import { CircularProgress, Slide } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Backdrop } from '@mui/material';
import RootSnackbar from 'components/root-snackbar';
import AppSnackbar from 'components/snackbar/snackbar';
import React, { ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Routes } from 'routes';
import { CustomTheme } from 'theme';
import appHistory from './history';
import { useAppSelector } from './hooks';
import { store } from './store';
import 'animate.css';
import '@sweetalert2/theme-minimal/minimal.css';
import './../app/app.css';
import { ErrorBoundary } from 'react-error-boundary';
import InternalServerError from 'components/error/internal-service-error-page';
import DealerAPI from 'components/authentication/api/dealer-api';
function SlideTransition(props: TransitionProps): ReactElement {
  return <Slide {...props} direction="left" />;
}

const queryClient = new QueryClient();


const App: React.FC = () => {

  
  const state = store.getState().user;
  return (
    <ErrorBoundary
      FallbackComponent={InternalServerError}
      onError={(error, componentStack) => {
        console.log("Error Boundry", error);
        DealerAPI.logError(error, componentStack.componentStack, state);
      }}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={CustomTheme}>
            <AppSnackbar.SnackbarProvider
              maxSnack={3}
              TransitionComponent={SlideTransition}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <Router history={appHistory}>
                <Routes />
              </Router>
            </AppSnackbar.SnackbarProvider>
            <RootSnackbar />
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
