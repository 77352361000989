import { isNotNullOrUndefined } from './object-check';

const parseErrorMessage = (error: any) => {
  if (isNotNullOrUndefined(error)) {
    if (error.message === 'Network Error') {
      return 'Network error';
    }
  }
  return 'Something went wrong';
};

const ErrorMessages = {
  standard: 'Something went wrong',
  networkError: 'Network Error',
};
export { parseErrorMessage, ErrorMessages };
