import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import LoadingButton from 'common/components/buttons/LoadingButton';
import EmailEditor from 'components/broadcast/components/broadcast-page/email-editor';
import config from 'config';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import {
  fetchDealerEmail,
  resendVerificationEmail,
  setDealerFooter,
  setDealerFrom,
  setDealerFromName,
  setDealerReplyTo,
  setDealerReplyToName,
  setSameFromAndReplyToAddressChange,
  updateDealerEmail,
  verifyDealerEmail,
} from 'store/dealerEmailSlice';
import { showSnackbar } from 'components/root-snackbar';
import useStyles from './email-styles';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import MediaGalleryAPI from 'components/broadcast/api/mediaGallery-api';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EmailSetting: React.FC = () => {
  const dispatch = useAppDispatch();
  const appUrl = config.get('app.url');
  const url = `${appUrl}/unsubscribe?s={encryptedEmail}`;
  const appendedContent = `<a href="${url}">Unsubscribe</a>`;
  const classes: any = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const dealerEmail = useAppSelector((state) => state.dealerEmail);
  const UnsubscribeInFooter: Boolean = !!dealerEmail.emailFooter && dealerEmail.emailFooter.indexOf(url) !== -1;

  const validationSchema = Yup.object<any>().shape<any>({
    ReplyTo: Yup.string().email('Invalid email address format').required('Email is required'),  
  });

  const formik = useFormik({
    initialValues: {
      ReplyTo: dealerEmail?.replyToAddress || '',
      FromName: dealerEmail?.fromName || '',
      ReplyToName: dealerEmail?.replyToName || '',
      EmailFooter: dealerEmail?.emailFooter || ''
    },
    validationSchema,
    onSubmit: (values) => {
      if (UnsubscribeInFooter) {
        dispatch(updateDealerEmail(dealerEmail));
      } else {
        dispatch(showSnackbar({ type: 'error', message: 'Please add the unsubscribe link in the footer first' }));
      }
    },
  });

  useEffect(() => {
    dispatch(fetchDealerEmail());
  }, [dispatch]);


  useEffect(() => {
    formik.setValues({
      ReplyTo: dealerEmail?.replyToAddress || '',
      FromName: dealerEmail?.fromName || '',
      ReplyToName: dealerEmail?.replyToName || '',
      EmailFooter: dealerEmail?.emailFooter || ''
    })
  }, [dealerEmail]);

  const handleOnChange = (event: any): void => {
    dispatch(setDealerReplyTo(event.target.value));
  };

  const handleOnChangeReplyToName = (event: any): void => {
    dispatch(setDealerReplyToName(event.target.value));
  };

  const handleOnChangeFromName = (event: any): void => {
    dispatch(setDealerFromName(event.target.value));
  };

  const handleOnChangeFrom = (event: any): void => {
    if (dealerEmail.sameFromAndReplyToAddress) {
      dispatch(setDealerReplyTo(event.target.value));
    }
    dispatch(setDealerFrom(event.target.value));
  };

  const handleClick = (event: any): void => {
    if (formik.errors.ReplyTo) {
      dispatch(showSnackbar({ type: 'error', message: formik.errors.ReplyTo }))
    } else {
      formik.handleSubmit();
    }
  };

  const handleEmailVerificationOk = (event: any): void => {
    dispatch(verifyDealerEmail());
  };

  const handleEmailResending = (event: any): void => {
    dispatch(resendVerificationEmail());
  };

  const handleSameFromAndReplyToAddressChange = (event: any): void => {
    dispatch(setSameFromAndReplyToAddressChange(event.target.checked));
  };

  const handleEditorChange = (content: any): void => {
    console.log('EditorChange : ' + content);
    dispatch(setDealerFooter(content));
  };

  const AppendUrl = (e: any): void => {
    e.preventDefault();
    console.log('AppendUrl : ' + appendedContent);
    let content = (dealerEmail.emailFooter ? dealerEmail.emailFooter : '') + '<p>' + appendedContent + '</p>';
    handleEditorChange(content);
  };

  async function handleImageUpload(file: File): Promise<GalleryImage[]> {
    const response = await MediaGalleryAPI.addImage([file]);
    return response;
  }

  return (
    dealerEmail && (
      <>
        <Dialog
          open={
            !dealerEmail.fromAddressVerified &&
            dealerEmail.sendGridId !== null &&
            dealerEmail.sendGridId !== undefined &&
            dealerEmail.sendGridId !== 0
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Email Verification is still in progress</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please open your provided email and verify the Email that you have received from SendGrid. After that
              click on the Ok Button to Confirm the verification
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleEmailResending}>
              {' '}
              Re-send Verification{' '}
            </Button>

            <Button color="primary" onClick={handleEmailVerificationOk} autoFocus>
              {' '}
              OK{' '}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={fixedHeightPaper}>
          <Toolbar style={{ minHeight: 'auto !important' }}>
            <Typography variant="h4">Email Settings</Typography>
          </Toolbar>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Grid container spacing={2}></Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Reply-To Email</Typography>
                  <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      id="ReplyTo"
                      name="ReplyTo"
                      label="Reply To"
                      onChange={handleOnChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.ReplyTo}
                      error={formik.touched.ReplyTo && Boolean(formik.errors.ReplyTo)}
                      helperText={formik.touched.ReplyTo && formik.errors.ReplyTo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Email Sender Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="FromName"
                    name="FromName"
                    label="From Name"
                    onChange={handleOnChangeFromName}
                    value={formik.values.FromName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Reply-To Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="ReplyToName"
                    name="ReplyToName"
                    label="Reply-To Name"
                    onChange={handleOnChangeReplyToName}
                    value={formik.values.ReplyToName}                  
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Typography variant="subtitle1">Footer</Typography>
              {!UnsubscribeInFooter && (
                <Alert severity="warning">
                  Please use the unsubscribe Url in the footer. or it will be reset to the default value with the
                  Url.&nbsp;
                  <Link onClick={AppendUrl}>Click here</Link> to Append Unsubscribe link now.
                </Alert>
              )}
              <EmailEditor
                value={formik.values.EmailFooter}
                onChange={handleEditorChange}
                onImageUpload={handleImageUpload}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={2}>
              <Box mt={2}>
                <LoadingButton
                  fullWidth
                  id="generalSaveBtn"
                  color="primary"
                  disabled={dealerEmail.loading}
                  variant="contained"
                  size="medium"
                  type="submit"
                  onClick={handleClick}>
                  {' Save'}
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Paper>
      </>
    )
  );
};

export default EmailSetting;

