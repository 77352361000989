import { Box, Button, Grid, IconButton, Popover, TextField, Tooltip, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React, { ReactElement } from 'react';

import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';

export function UrlShortenerWidget({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: (body: string) => void;
}): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [PopoverUrl, setPopOverUrl] = React.useState('');

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handlePopOverChange = (event: any): void => {
    setPopOverUrl(event.target.value);
  };
  const handleShortenUrl = async (event: any): Promise<void> => {
    if (PopoverUrl) {
      const shortenedUrl = await BroadCastAPI.shortenUrl(PopoverUrl);
      setPopOverUrl(shortenedUrl);
      setAnchorEl(null);
      const url = shortenedUrl;
      onClick(url);
      shortenedUrl.length > 0 && setPopOverUrl('');
    }
  };
  return (
    <>
      <Tooltip title="Add URL Shortener">
        <IconButton
          style={{ padding: 0 }}
          size="small"
          id="urlShortnerWidgetBtn"
          onClick={handleClick}
          disabled={disabled}>
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container>
          <Box p={2}>
            <Typography> Enter URL </Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              id="shortenUrlTextField"
              name="url"
              label="url"
              value={PopoverUrl}
              onChange={handlePopOverChange}
            />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={handleShortenUrl} id="shortenUrlBtn" color="primary">
                  {' '}
                  Shorten URL{' '}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Popover>
    </>
  );
}

export default UrlShortenerWidget;
