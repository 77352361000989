import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Fab,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useAppDispatch, useAppSelector } from 'app';
import React, { useEffect } from 'react';
import GetPaymentSettings, { IGravityPaymentResponse, IGravitySettings } from './api/gravitypayment-api';
import AppSnackbar from 'components/snackbar/snackbar';
import { setIsAllowTextToPay } from 'components/authentication/components/authentication-slice';
import { useFormik } from 'formik';
import DealerAPI from 'components/authentication/api/dealer-api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SmallCircularProgress } from 'common/components/loaders';

let initialValues: IGravitySettings = {
  paymentsData: [],
};
const GravityPaymentSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [loadingLoc, setLoadingLoc] = React.useState(false); 
  const alterFormikPaymentsData = (paymentValues: IGravityPaymentResponse[]) => {
    const values = paymentValues.map((paymentValue) => ({
      oid: paymentValue.oid === "0" ? "" : paymentValue.oid,
      authToken: paymentValue.authToken === "0" ? "" : paymentValue.authToken,
      secretPhrase: paymentValue.secretPhrase === "0" ? "" : paymentValue.secretPhrase,
      allowGiftCards: paymentValue.allowGiftCards,
      allowTextToPay: paymentValue.allowTextToPay,
      dealerId: paymentValue.dealerId,
      isSandbox: paymentValue.isSandbox,
      locationName: paymentValue.locationName,
      merchantKey: paymentValue.merchantKey,
      merchantName: paymentValue.merchantName,
    }));
    return values;
  }
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      savePaymentSettings(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const getLocations = async () => {
      try {
        setLoadingLoc(true);
        const response = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
        if (response.dealerLocationSettings) {
          const paymentResponse = response.dealerLocationSettings.map(async (x) => {
            const response = await GetPaymentSettings.fetchPaymentSettings(x.dealerId);
            if (response) {
              return { ...response, dealerId: x.dealerId, locationName: x.locationName };
            }
          });
          const paymentDataList = await Promise.all(paymentResponse);
          const formikValues = alterFormikPaymentsData(paymentDataList);
          initialValues.paymentsData = formikValues;
        }
        setLoadingLoc(false);
      } catch (error) {
        setLoadingLoc(false);
        return error;
      }
    };
    getLocations();
  }, []);
  
  async function savePaymentSettings(PaymentSettings: IGravitySettings) {
    try {
      let isPayments: boolean = false;

      PaymentSettings.paymentsData.forEach(async (paymentSettings: IGravityPaymentResponse) => {
        if (paymentSettings.oid === "" || paymentSettings.secretPhrase === "" || paymentSettings.authToken === ""
             || paymentSettings.oid === "0" || paymentSettings.secretPhrase === "0" || paymentSettings.authToken === "0"
              || !paymentSettings.oid || !paymentSettings.secretPhrase || !paymentSettings.authToken) 
        {
          paymentSettings.oid = '0';
          paymentSettings.secretPhrase = '0';          
          paymentSettings.authToken = '0';
        }
        const response = await GetPaymentSettings.updatePaymentSettings(paymentSettings);
        if(response === ""){
          if(paymentSettings.oid.length > 0 && paymentSettings.oid !== "0" && 
              paymentSettings.authToken.length > 0 && paymentSettings.authToken !== "0" &&
               paymentSettings.secretPhrase.length > 0 && paymentSettings.secretPhrase !== "0"){
            isPayments = true
          }
          showSnackBar({ type: 'success', message: 'Payment settings saved successfully!' });
          dispatch(setIsAllowTextToPay(isPayments));
        }
      });
      const formikValues = alterFormikPaymentsData(PaymentSettings.paymentsData)
      formik.setFieldValue('paymentsData',formikValues);
    } catch (e: any) {
      showSnackBar({ type: 'error', message: 'Unable to Update payment settings' });
    }
  }

  return (
    <Paper style={{ paddingBottom: '30px' }}>
      <Grid container spacing={2}>
        <Grid item xs={3} />
        <Grid container xs={6} spacing={2} style={{ minHeight: '72vh', alignContent: 'flex-start' }}>
          <Grid item xs={12} style={{ paddingTop: '30px', textAlign: 'center' }}>
            <Typography variant="h4">EmergePay Settings</Typography>
          </Grid>
          <Box my={1} width={'100%'}>
            {loadingLoc ? (
              <Box style={{ margin: 'auto' }} display="flex" justifyContent="center">
                <SmallCircularProgress />
              </Box>
            ) : (
              formik.values.paymentsData &&
              formik.values.paymentsData.map((locations: IGravityPaymentResponse, idx: number) => (
                <Accordion
                  key={locations.dealerId}
                  style={{ marginBottom: '10px' }}
                  elevation={3}
                  defaultExpanded={idx === 0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{locations.locationName}</AccordionSummary>
                  <AccordionDetails>
                    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          id={`paymentsData[${idx}].oid`}
                          name={`paymentsData[${idx}].oid`}
                          label="Oid"
                          placeholder="Oid"
                          onChange={(e) => {
                            formik.setFieldValue(`paymentsData[${idx}].oid`, e.target.value);
                          }}
                          value={formik.values.paymentsData[idx].oid}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          id={`paymentsData[${idx}].authToken`}
                          name={`paymentsData[${idx}].authToken`}
                          label="Auth Token"
                          placeholder="Auth Token"
                          value={formik.values.paymentsData[idx].authToken}
                          onChange={(e) => {
                            formik.setFieldValue(`paymentsData[${idx}].authToken`, e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          id={`paymentsData[${idx}].secretPhrase`}
                          name={`paymentsData[${idx}].secretPhrase`}
                          label="Secret Phrase"
                          placeholder="Secret Phrase"
                          value={formik.values.paymentsData[idx].secretPhrase}
                          onChange={(e) => {
                            formik.setFieldValue(`paymentsData[${idx}].secretPhrase`, e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </form>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Box>
        </Grid>
        <Grid container xs={3}>
          <Grid item xs={12} style={{ marginTop: '65px', marginLeft: '15px' }}>
            {!loadingLoc && (
              <Fab onClick={() => formik.handleSubmit()} title="Save Settings" color="primary">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <SaveIcon />
                  <p style={{ fontSize: '7px', margin: '0px' }}>Save</p>
                </div>
              </Fab>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default GravityPaymentSettings;
