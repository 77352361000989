import { CircularProgress, Divider, Grid, List, ListItemIcon, ListItemText, MenuItem, TextField, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CustomerTags } from "common/models";
import Customer from "common/models/customer";
import { isNotNullOrUndefined } from "common/utils";
import AppSnackBar from 'components/snackbar/snackbar';
import CustomerSettingsApi from "components/setting/api/customer-settings-api";
import { CustomerTagOptions, CustomerTagTitle } from "components/setting/models/customer-tag-title";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";

interface CustomerTagProps {
    customerTags: CustomerTagTitle[];
    setCustomerTags: any;
    tag?: string,
    setTag?: any
    chipIndex?: number;
    formik?: any;
    setPopoverChipState?: any;
    children?: ReactNode;  
  }
     function CustomerTagPopover({ customerTags, setCustomerTags, chipIndex, formik, setPopoverChipState, tag, setTag }: CustomerTagProps) {

    //const [customerTags, setCustomerTags] = useState<CustomerTagTitle[]>([]);
    const [tagsLoading, setTagsLoading] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState('');
    const [showSnackBar] = AppSnackBar.useSnackBar();

    const filteredTags = (title: any) => {
        if (!searchInput) return title.customerTagOptions;
        return title.customerTagOptions.filter((option: any) =>
          option.tag.toLowerCase().includes(searchInput.toLowerCase())
        );
      };

    const tagExists = (selectedTagOption: CustomerTagOptions) => {
    const found = formik.values.customerTags.some((customerTag: any) =>
        selectedTagOption.tag === customerTag.tag
    );
    return found;
    };

    async function fetchTags() {
        setTagsLoading(true)
        const staticTags = await CustomerSettingsApi.getCustomerTags();
        setCustomerTags(staticTags)
        setTagsLoading(false)
    }

    useEffect(() => {
      if (customerTags?.length == 0) {
      fetchTags();
      }
    }, []);


    const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
    };
    return(
        <Grid container justifyContent="space-between">
        {tagsLoading ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          customerTags.length == 0 ? 
          (<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6" align="center">
            No tag settings found<br />
            Please add new tags in customer settings
          </Typography>          
          </Grid>) 
          :
          (<Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search tags"
              value={searchInput}
              onChange={handleSearchChange}
              size="small"
              style={{ marginBottom: '10px' }}
            />
              <List dense style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {customerTags && customerTags.map((title) => (
                  <React.Fragment key={title.id}>
                    {filteredTags(title).length > 0 && (
                      <>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#333', fontWeight: 'bold', marginBottom: '5px' }}
                        >
                          {title.title}
                        </Typography>
                        <Divider />
                      </>
                    )}                   
                    {filteredTags(title).map((option: any) => (
                     
                      <MenuItem
                        key={option.id}
                        onClick={() => {
                        if (isNotNullOrUndefined(tag)) {
                          setTag(option.tag)
                            setPopoverChipState({
                            openedPopoverId: null,
                            anchorEl: null,
                          });
                        } else {
                          if (formik && !tagExists(option)) {
                          formik.setFieldValue(`customerTags[${chipIndex}].tag`, option.tag);
                          setPopoverChipState({
                            openedPopoverId: null,
                            anchorEl: null,
                          });
                        } else {
                          showSnackBar({ type: 'error', message: 'Tag already present' });
                        }
                        }}}

                      >
                        <ListItemIcon style={{ minWidth: '24px' }}>
                          <FiberManualRecordIcon style={{ fontSize: '12px' }} />
                        </ListItemIcon>
                        <ListItemText primary={option.tag} />
                      </MenuItem>
                    ))}
                  </React.Fragment>
                ))}
              </List>
          </Grid>
        ))}
      </Grid>

    );

}

export default CustomerTagPopover;
