import { Box, Button, Container, Drawer, MenuItem, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import React, { ReactElement } from 'react';
import { useAppSelector } from 'app';
import * as Yup from 'yup';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';
import { BroadCastType } from 'components/broadcast/models/broadcast';

import { TypeOption } from './broadcasts-tab';
import TextLimitField from 'common/components/input-fields/TextLimitField';

export interface NewBroadCastFormValue {
  title: string;
  type: BroadCastType;
  dealerLocationId?: number;
}

function NewBroadCastDrawer({
  locationOptions,
  typeOptions,
  selectedType,
  open,
  selectedLocationId,
  onClose,
  onCreate,
}: {
  locationOptions?: LocationOption[];
  typeOptions: TypeOption[];
  selectedType : BroadCastType;
  open: boolean;
  selectedLocationId?: number;
  onClose(): void;
  onCreate(value: any): Promise<void>;
}): ReactElement {
  const validationSchema = Yup.object<any>().shape<any>({
    title: Yup.string().required('Broadcast title is required'),
    type: Yup.string().required('BroadCast type is required'),
    dealerLocationId: Yup.number().required('Department location is required'),
  });

  const initialValues: NewBroadCastFormValue = {
    title: '',
    type: selectedType,
    dealerLocationId: selectedLocationId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSubmit(values: NewBroadCastFormValue): Promise<void> {
    try {
      await onCreate(values);
      formik.resetForm();
    } catch (error) {
      formik.resetForm();
    }
  }

  const dealerEmail = useAppSelector((state) => state.dealerEmail);

  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Create Broadcast" onClose={onClose} />
      <Box mb={1} />
      <Container>
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextLimitField
            characters={50}
            helperTextAlignLeft= {true}
            fullWidth
            autoFocus
            margin="dense"
            variant="outlined"
            name="title"
            id="title"
            label="Title"
            onChange={formik.handleChange}
            placeholder="Broadcast title"
            value={formik.values.title}
            error={!!formik.errors.title}
            helperText={formik.errors.title}
            required
          />
          <TextField
            fullWidth
            select
            margin="dense"
            variant="outlined"
            name="type"
            inputProps={{ id: 'type' }}
            label="Type"
            onChange={formik.handleChange}
            value={formik.values.type}
            error={!!formik.errors.type}
            helperText={formik.errors.type}
            required>
            {typeOptions.map((typeOption) => (
              <MenuItem
                key={typeOption.value}
                value={typeOption.value}
                disabled={typeOption.value === 'EMAIL' && !dealerEmail.replyToAddress}>
                {typeOption.label} &nbsp;
                {typeOption.value === 'EMAIL' && !dealerEmail.replyToAddress && (
                  <Alert severity="error" variant="outlined">
                    Please add your email in settings to enable Emails.
                    {/* <Link href="#">
                      Click here
                      </Link>
                   to set */}
                  </Alert>
                )}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            margin="dense"
            variant="outlined"
            name="dealerLocationId"
            inputProps={{ id: 'dealerLocationId' }}
            label="Location"
            onChange={formik.handleChange}
            value={formik.values.dealerLocationId}
            error={!!formik.errors.dealerLocationId}
            helperText={formik.errors.dealerLocationId}
            required>
            {locationOptions &&
              locationOptions.map((locationOption) => (
                <MenuItem key={locationOption.dealerLocationId} value={locationOption.dealerLocationId}>
                  {locationOption.name}
                </MenuItem>
              ))}
          </TextField>
          <Box mt={2} display="flex" flexDirection="row-reverse">
            <Button
              fullWidth
              id="save"
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              disabled={formik.isSubmitting}>
              Create
            </Button>
          </Box>
        </form>
      </Container>
    </Drawer>
  );
}

export default NewBroadCastDrawer;
