import { destroy, get, post, put } from 'api';
import axios, { CancelTokenSource } from 'axios';
import {
  GetTaskListResponse,
  TaskListRescheduleRequest,
  TaskType,
  TaskOutcome,
  TaskListDoneRequest,
} from 'components/lead/models/task';
import config from 'config';
import { addTaskRequest, RescheduleTaskRequest } from './taskboardSlice';

const BASE_URL = config.get('api.lead');

let token: CancelTokenSource;
const getTasks = async (
  dealerId: number,
  createdByUserId: string | null,
  assignedUserIds: string[] | null,
  taskStatusId: number | null,
  taskTypeId: number | null,
  fromDate: Date | null,
  toDate: Date | null,
  page: number,
  pageSize: number,
  searchString: string,
  isArchived: boolean,
): Promise<any> => {
  if (token) {
    token.cancel();
  }
  let searchStr = searchString.replace('&', '%26');
  token = axios.CancelToken.source();

  const axiosResponse = await post<GetTaskListResponse>(`${BASE_URL}/lead/tasks`, {
    searchString: searchStr,
    dealerId,
    createdByUserId,
    assignedUserIds,
    taskStatusId,
    taskTypeId,
    isArchived,
    fromDate,
    toDate,
    pageNumber: page,
    pageSize,
  });
  return axiosResponse?.data;
};

const getTask = async (dealerId: number, taskId: number): Promise<any> => {
  const { data } = await get(`${BASE_URL}/lead/task/${taskId}?dealerId=${dealerId}`);
  return data;
};

const getTaskTypes = async (): Promise<any> => {
  const { data } = await get(`${BASE_URL}/lead/taskType`);
  return data;
};

const getTaskCategories = async (): Promise<any> => {
  const { data } = await get(`${BASE_URL}/lead/taskCategory`);
  return data;
};

const getTaskOutcomes = async (): Promise<any> => {
  const { data } = await get(`${BASE_URL}/lead/taskOutcome`);
  return data;
};

const addTask = async (task: addTaskRequest): Promise<number> => {
  return await post(`${BASE_URL}/lead/task`, task);
};

const updateTask = async (task: RescheduleTaskRequest): Promise<number> => {
  return await put(`${BASE_URL}/lead/task/${task.taskId}`, task);
};

const updateTasks = async (task: TaskListRescheduleRequest): Promise<number> => {
  return await put(`${BASE_URL}/lead/tasks`, task);
};

const updateTasksForDone = async (task: TaskListDoneRequest): Promise<number> => {
  return await put(`${BASE_URL}/lead/tasks`, task);
};

const createTaskType = async (taskType: TaskType): Promise<any> => {
  const url = `${BASE_URL}/lead/taskType`;
  let req = {
    departmentIds: taskType.departments.map((x) => x.departmentId),
    id: taskType.id,
    value: taskType.value,
    categoryId: taskType.taskCategoryId,
  };
  const { data } = await post<TaskType>(url, req);
  return data;
};
const updateTaskType = async (taskType: TaskType): Promise<TaskType> => {
  const url = `${BASE_URL}/lead/taskType/${taskType.id}`;
  taskType.departments;
  let req = {
    departmentIds: taskType.departments.map((x) => x.departmentId),
    id: taskType.id,
    value: taskType.value,
    categoryId: taskType.taskCategoryId,
  };
  const { data } = await put<TaskType>(url, req);
  return data;
};
const deleteTaskType = async (taskType: number): Promise<TaskType> => {
  const url = `${BASE_URL}/lead/taskType/${taskType}`;
  const { data } = await destroy<TaskType>(url);
  return data;
};

const createTaskOutcome = async (taskOutcome: TaskOutcome): Promise<any> => {
  const url = `${BASE_URL}/lead/taskOutcome`;
  let req = {
    departmentIds: taskOutcome.departments.map((x) => x.departmentId),
    id: taskOutcome.id,
    value: taskOutcome.value,
    categoryId: 2,
  };
  const { data } = await post<TaskOutcome>(url, req);
  return data;
};
const updateTaskOutcome = async (taskOutcome: TaskOutcome): Promise<TaskOutcome> => {
  const url = `${BASE_URL}/lead/taskOutcome/${taskOutcome.id}`;
  taskOutcome.departments;
  let req = {
    departmentIds: taskOutcome.departments.map((x) => x.departmentId),
    id: taskOutcome.id,
    value: taskOutcome.value,
    categoryId: 2,
  };
  const { data } = await put<TaskOutcome>(url, req);
  return data;
};
const deleteTaskOutcome = async (taskOutcome: number): Promise<TaskOutcome> => {
  const url = `${BASE_URL}/lead/taskOutcome/${taskOutcome}`;
  const { data } = await destroy<TaskOutcome>(url);
  return data;
};

const deleteCustomer = async (dealerId: number, customerId: number): Promise<any> => {
  try {
    return await destroy(`${BASE_URL}/customers/${customerId}`);
  } catch (error) {
    return null;
  }
};

const getCustomerPhoneNumberCount = async (): Promise<any> => {
  try {
    return await post(`${BASE_URL}/getCustomerPhoneNumbersCount/`);
  } catch (err) {
    return null;
  }
};
const getTodoOrOverdueTaskCount = async (): Promise<{ taskCount: number }> => {
  const url = `${BASE_URL}/getTodoOrOverdueTaskCount/`;
  const { data } = await get(url);
  return data;
};

export default {
  getTasks,
  getTask,
  getTaskTypes,
  getTaskOutcomes,
  addTask,
  updateTask,
  updateTasks,
  updateTasksForDone,
  createTaskType,
  updateTaskType,
  deleteTaskType,
  createTaskOutcome,
  updateTaskOutcome,
  deleteTaskOutcome,
  deleteCustomer,
  getCustomerPhoneNumberCount,
  getTaskCategories,
  getTodoOrOverdueTaskCount,
};
