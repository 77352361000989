import Dealer, { PersistentAppData } from 'common/models/dealer';
import { DealerEmailState } from 'store/dealerEmailSlice';
import Bracket from 'components/setting/models/bracket';
import { DealerSetting } from 'common/models/dealer-setting';
import config from 'config';
import { get, post, put } from 'api';
import DealerPhoneSetting from 'common/models/dealer-phone-setting';

const dealerUrl = config.get('api.dealer');
const logUrl = config.get('api.log');


export const DealerAPI = {
  getDealer: async (dealerId: number): Promise<Dealer | null> => {
    const { data } = await get(`${dealerUrl}/dealer/GetDealer/${dealerId}`);
    return data;
  },
  getBrackets: async (): Promise<Bracket[]> => {
    const { data } = await get(`${dealerUrl}/brackets`);
    return data;
  },
  updateCredits: async (messageCredits: number, dmsDealerIdCrm: number): Promise<number> => {
    const { data } = await post(`${dealerUrl}/dealers/${dmsDealerIdCrm}/credits`, { messageCredits });
    return data;
  },
  getDealerLocationSettings: async (dmsDealerIdCrm: number, dealerId: number): Promise<any> => {
    let url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/locations/${dealerId}/settings`;
    url = url.replace('v2/', '');
    const { data } = await get(url);
    return data;
  },
  getDealerSettings: async (dmsDealerId: number): Promise<DealerSetting> => {
    let url = `${dealerUrl}/dealers/${dmsDealerId}/settings`;
    const { data } = await get(url);
    return data;
  },
  updateDealerSettings: async (dmsDealerId: number, dealerSetting: DealerSetting): Promise<void> => {
    const { data } = await put(`${dealerUrl}/dealers/${dmsDealerId}/settings`, dealerSetting);
    return data;
  },
  getDealerPhoneSetting: async (dmsDealerId: number): Promise<DealerPhoneSetting> => {
    const { data } = await get(`${dealerUrl}/dealers/${dmsDealerId}/phone-settings`);
    return data;
  },
  getUsageHistory: async (dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await get(`${dealerUrl}/dealers/${dmsDealerIdCrm}/credit-usage-history`);
    return data;
  },
  updateDealerPhoneSetting: async (dmsDealerIdCrm: number, dealerPhone: DealerPhoneSetting): Promise<any> => {
    const { data } = await put(`${dealerUrl}/dealers/${dmsDealerIdCrm}/phone-settings`, dealerPhone);
    return data;
  },
  getDealerEmail: async (dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await get(`${dealerUrl}/dealers/${dmsDealerIdCrm}/email/settings`);
    return data;
  },
  updateDealerEmail: async (dealerEmail: DealerEmailState, dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await put(`${dealerUrl}/dealers/${dmsDealerIdCrm}/email/settings`, dealerEmail);
    return data;
  },
  verifyDealerEmail: async (dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await post(`${dealerUrl}/dealers/${dmsDealerIdCrm}/email/verify`);
    return data;
  },
  resendVerificationEmail: async (dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await post(`${dealerUrl}/dealers/${dmsDealerIdCrm}/email/verify/re`);
    return data;
  },
  cancelVerificationEmail: async (dmsDealerIdCrm: number): Promise<any> => {
    const { data } = await post(`${dealerUrl}/dealers/${dmsDealerIdCrm}/email/verify/cancel`);
    return data;
  },
  logUserActivity: async (crmUserId: string, dealerId: number, route: string, action: string) => {
    const { data } = await post(`${logUrl}/UsageLog`, {
      crmUserId,
      dealerId,
      route,
      action,
      'Environment': 'Production',
      'Application': 'Target',
    });
    return data;
  },
  logError: async (error: Error, componentStack: string, user: any) => {
    let errObj = {
      exception: error.message,
      componentStack,
      crmUserId: user.crmUserId ?? 0,
      dealerId: user.dealerId,
      methodName: "ReactApp : " + error.name,
    };
    const { data } = await post(`${logUrl}/ErrorLog`, errObj);
    return data;
  },
  decryptLoginKey: async (key: string): Promise<{ dmsDealerIdCrm: number; username: string; password: string }> => {
    const { data } = await get(`${dealerUrl}/decrypt-target-login-key/${key}`);
    return data;
  },
  ExecuteJsonSP: async (spName: string, json: string): Promise<any> => {
      const { data } = await get(`${dealerUrl}/ExecuteJsonSP/${spName}/${escape(json)}`);
      return data;      
  },
  ExecuteJsonSPPost: async (spName: string, json: string): Promise<any> => {
    let errObj = {
      spName,
      json
    };
    const { data } = await post(`${dealerUrl}/ExecuteJsonSPPost`,errObj);
    return data;
  },
  getPersistentAppData: async (type: string): Promise<any> => {
    const { data } = await get(`${dealerUrl}/dealers/getPersistentAppData/${type}`);
    return data;
  },
  addPersistentAppData: async (persistentData: PersistentAppData): Promise<boolean> => {
    const { data } = await post(`${dealerUrl}/dealers/addPersistentAppData`, persistentData);
    return data;
  },
  updatePersistentAppData: async (persistentData: PersistentAppData): Promise<boolean> => {
    const { data } = await post(`${dealerUrl}/dealers/updatePersistentAppData`, persistentData);
    return data;
  },
  updateDealersDefaultCategory: async (categoryId: string): Promise<any> => {
    const { data } = await post(`${dealerUrl}/dealers/updateDealersDefaultCategory/${categoryId}`);
    return data;
  }
};

export default DealerAPI;
