import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import Messenger from 'components/messaging/components/messenger';
import React from 'react';
import { Box, Button, Container, Drawer, Grid, MenuItem, TextField, Paper, Typography } from '@material-ui/core';
export default function MessengerDrawer({ defaultSearch="", phoneNumber="",  open, onClose }: { defaultSearch?: string, phoneNumber?:string,  open: boolean, onClose(): void }): React.ReactElement {
    React.useEffect(() => {
    }, [open]);

    function CloseMe1(){
        //alert('CloseMe');
    }

    return (
        <Paper>
            <Drawer PaperProps={{ style: { width: "auto", minWidth: "1250px" } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
            <DrawerToolbar title="Messenger" onClose={onClose} />
                {open && (
                    <Box>
                        <Messenger defaultSearch={defaultSearch} phoneNumber={phoneNumber} CloseMe={CloseMe1} ></Messenger>
                    </Box>
                )}
            </Drawer>
        </Paper>
    );

}