import {
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { closeNotificationDrawer, Notification, setNotificationIsRead, SetSelectedNotifications } from './notificationSlice';
import { fetchSelectedCustomer } from 'components/customer/components/Customers/customersSlice';
import { TaskListObject } from 'components/lead/models/task';
import { showTaskDrawer, UpdateSelectedTask } from 'components/lead/components/task-board/taskboardSlice';
import taskboardApi from 'components/lead/components/task-board/taskboard-api';
import TaskRootDrawer from 'components/lead/components/task-board/TaskDrawer/TaskRootDrawer';
import EventTypes from 'common/models/eventTypes';
import notificationApi from './notification-api';

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    width: 300,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  drawerPaper: {
    // position: 'absolute',
    whiteSpace: 'nowrap',
    width: 350, // theme.notificationDrawer.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: theme.navBar.height,
  },

  successIcon: {
    color: theme.palette.success.main,
    fontSize: 20,
    paddingRight: 5,
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: 20,
    paddingRight: 5,
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 20,
    paddingRight: 5,
  },
  infoIcon: {
    color: theme.palette.info.main,
    fontSize: 20,
    paddingRight: 5,
  },
  InProgressIcon: {
    //width: 30,
    // marginRight: 15,
    // height: 30,
    // marginTop: 8
  },
  notificationHeader: {
    marginLeft: 10,
    marginTop: 10,
  },
  primaryText: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  secondaryText: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
}));

export function NotificationList(): React.ReactElement {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const notificationList = useAppSelector((state) => state.notification.notifications);
  notificationList.filter((x)=>x.isRead === false).length > 0 && notificationApi.NotificationApis.updateAllNotifications().then(()=>dispatch(setNotificationIsRead()));
  const onEditTask = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_EDIT', drawerProps: {} }));
  };
  return (
    <>
      <List className={classes.root}>
        {notificationList.map((notification, index) => {
          console.log("Notifications",notification);
          return (
            <>
              <ListItem alignItems="center" style={{ padding: 0, marginLeft: '5%' }}>
                <ListItemText
                  primary={
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {(() => {
                            switch (notification.type) {
                              case 'InProgress':
                                return <CircularProgress className={classes.InProgressIcon} size={20} />;
                              case 'Success':
                                return <CheckCircleIcon className={classes.successIcon} />;
                              case 'Warning':
                                return <ErrorIcon className={classes.warningIcon} />;
                              case 'Error':
                                return <CancelIcon className={classes.errorIcon} />;
                              default:
                                return <InfoIcon className={classes.infoIcon} />;
                            }
                          })()}
                        </Grid>
                        <Grid item xs={11}>
                          <Typography className={classes.primaryText}>{notification.title}</Typography>
                        </Grid>
                      </Grid>
                    </>
                  }
                  secondary={
                    <>
                      {notification.eventType === EventTypes.NotificationEvents.TaskNotification ? (
                        <Typography className={classes.secondaryText}>
                          {notification.message}{' '}
                          <Link
                            href={'#'}
                            underline="hover"
                            onClick={async () => {
                              const response = await taskboardApi.getTask(
                                parseInt(notification.dealerId!),
                                parseInt(notification.eventData!),
                              );
                              if (response) {
                                onEditTask(response);
                              }
                            }}>
                            See details
                          </Link>
                        </Typography>
                      ) : (
                        <Typography className={classes.secondaryText}>{notification.message}</Typography>
                      )}
                    </>
                  }
                />
                {notification.eventType === EventTypes.NotificationEvents.TaskNotification && (
                  <IconButton
                    onClick={async () => {
                      const response = await notificationApi.NotificationApis.removeNotification(notification.id);
                      if (response === 'success') {
                        dispatch(SetSelectedNotifications(notificationList.filter((x) => x.id !== notification.id)));
                      }
                    }}
                    size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </ListItem>
              <Divider variant="inset" component="li" />
              <TaskRootDrawer />
            </>
          );
        })}
      </List>
    </>
  );
}

export function NotificationDrawer(): React.ReactElement {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.notification.isNotificationDrawerOpen);
  const handleDrawerClose = (): void => {
    dispatch(closeNotificationDrawer());
  };
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      classes={{
        paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
      }}
      open={isDrawerOpen}>
      <DrawerToolbar title="Notifications" onClose={handleDrawerClose} />
      <NotificationList />
    </Drawer>
  );
}

export default NotificationDrawer;
