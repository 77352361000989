import { makeStyles, Theme } from '@material-ui/core';
import GooglePNG from 'assets/images/google.png';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  logo: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

function Google(): ReactElement {
  const classes: any = useStyles();
  return <img src={GooglePNG} alt="Google logo" className={classes.logo} />;
}

export default Google;
