import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  grid: {
    height: '100%',
    textAlign: 'center',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
