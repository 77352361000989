import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { PageHeader } from 'common/components/layout';
import { debounce } from 'lodash';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CustomerTable from '../task-board/TaskBoardTable';
import {
  fetchCustomers,
  hideCustomerDrawer,
  SetSelectedCustomers,
  updateCustomerSearchString,
} from '../../../customer/components/Customers/customersSlice';
import { Calendar } from '@material-ui/pickers';
import { DateRangePickerDay } from '@mui/lab';
import TaskboardTable from '../task-board/TaskBoardTable';
import AddIcon from '@material-ui/icons/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  showTaskDrawer,
  setFilterDates,
  setTaskStatusId,
  fetchTasks,
  updateTaskSearchString,
  SetSelectedTasks,
  SetAllSelectedTasks,
  TaskFilterPersistent,
  setPersistantFilterState,
} from './taskboardSlice';
import { UserTaskStatus } from 'components/lead/models/task';
import SignalRContext from 'components/layouts/DashboardLayout/signalr-context';
import { showSnackbar } from 'components/root-snackbar';
import DealerAPI from 'components/authentication/api/dealer-api';
const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  // containerShift: {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   marginRight: drawerWidth,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  primaryAddBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

export function Taskboard(): ReactElement {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();
  const signalRConnection = useContext(SignalRContext);

  // const debounceSearchCustomer = debounce(() => dispatch(fetchCustomers()), 1000);
  const debouncedSearchTask = useRef(debounce(() => dispatch(fetchTasks()), 1000)).current;

  const taskSearchText = useAppSelector((state) => state.tasks.searchString);
  const dmsDealerId = useAppSelector((state)=>state.user.dmsDealerId);
  const crmUserId = useAppSelector((state)=>state.user.crmUserId);


  const handleSearchTask = (searchString: string): void => {
    dispatch(updateTaskSearchString(searchString));
    // debounceSearchCustomer();
  };

  useEffect(() => {
    return function onUnMount(): void {
      dispatch(hideCustomerDrawer());
      dispatch(SetSelectedCustomers([]));
      dispatch(updateCustomerSearchString(''));
    };
  }, [dispatch]);

  useEffect(() => {
    debouncedSearchTask();
  }, [taskSearchText, debouncedSearchTask]);

  useEffect(() => {
    function tasksBoardUpdated(notification: Notification) {
      dispatch(fetchTasks());
    }

    if (signalRConnection) {
      signalRConnection.on('TasksBoardUpdated', tasksBoardUpdated);
    }
    return (): void => {
      if (signalRConnection) {
        signalRConnection.off('TasksBoardUpdated', tasksBoardUpdated);
      }
    };
  }, [signalRConnection]);

  const drawerOpen = useAppSelector((state) => !!state.tasks.selectedTask);

  const containerClassName = clsx(classes.container, drawerOpen && classes.containerShift);

  const taskStatusId = useAppSelector((state: any) => state.tasks.taskStatusId);

  const onAddTaskBtn = () => dispatch(showTaskDrawer({ drawerType: 'TASK_ADD', drawerProps: {} }));

  async function UpdatePersistentFilterState() {
    let taskFilterLocalData = localStorage.getItem(`TaskFilter_${dmsDealerId}_${crmUserId}`);
    if(taskFilterLocalData === null){
      const resp=await DealerAPI.getPersistentAppData(`TaskFilter_${dmsDealerId}_${crmUserId}`);
      taskFilterLocalData = resp.data;
    }
    const taskFilterData: TaskFilterPersistent = taskFilterLocalData ? JSON.parse(taskFilterLocalData) : null;
    if (taskFilterLocalData != null) {
      dispatch(
        setPersistantFilterState({
          assignUser: taskFilterData.assignedUserIds,
          statusId: taskFilterData.taskStatusId,
          typeId: taskFilterData.taskTypeId,
          archieve: taskFilterData.isArchived,
          location: taskFilterData.location,
        }),
      );
    }
  }

  useEffect(() => {
    UpdatePersistentFilterState();
  }, []);

  return (
    <Container className={containerClassName} maxWidth="lg">
      <PageHeader title="Tasks" />
      <Box mt={2}>
        <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'inherit' }}>
          <Grid item xs={1} style={{ flexBasis: 0}}>
            <Fab
                  type="submit"
                  onClick={(): void => {
                    onAddTaskBtn();
                  }}
                  title="New"
                  color="primary">
                  <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <AddIcon />
                    <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>New</Typography>
                  </Box>  
              </Fab>
          </Grid>
          <Grid item xs={11} style={{paddingRight: '70px' }}>
            <TextField
              fullWidth
              style={{ backgroundColor: 'white' }}
              margin="dense"
              variant="outlined"
              name="searchText"
              id="search-text"
              placeholder="Search Tasks"
              value={taskSearchText}
              InputProps={{
                startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
              }}
              onChange={({ target: { value } }): void => {
                handleSearchTask(value);
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={11}>
              <Paper className={classes.paper}>
                <Box mt={2}>
                  <TaskboardTable />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                onClick={() => {
                  dispatch(showTaskDrawer({ drawerType: 'TASK_FILTER', drawerProps: {} }));
                  dispatch(SetSelectedTasks([]));
                  dispatch(SetAllSelectedTasks(false));
                  
                }}
                style={{ marginLeft: '10px', marginTop: '10px' }}
                title="Filter data">
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <FilterAltIcon />
                    <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                  </Box> 
              </Fab>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Container>
  );
}

export default Taskboard;
