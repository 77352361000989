import { SnackbarProvider } from 'notistack';
import React from 'react';
import { useAppDispatch } from 'app';

import { showSnackbar } from 'components/root-snackbar';

interface SnackbarProps {
  type: 'success' | 'error' | 'info'|'warning';
  message: string;
}
function useSnackBar(): [(props: SnackbarProps) => void] {
  const dispatch = useAppDispatch();
  const show = React.useCallback(
    (props: SnackbarProps): void => {
      dispatch(showSnackbar(props));
    },
    [dispatch],
  );
  return [show];
}

const AppSnackbar = {
  SnackbarProvider,
  useSnackBar,
};

export default AppSnackbar;
