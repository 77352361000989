/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Customer, { CustomerDto, CustomerEmail } from 'common/models/customer';
import { AppThunk } from 'app';
import { showSnackbar } from 'components/root-snackbar';

import CustomerAPI from '../../api/CustomerAPI';
import { setSelectedCustomer } from 'components/lead/components/task-board/taskboardSlice';

export type CustomerDialogType =
  | undefined
  | 'NEW'
  | 'SHOW'
  | 'EDIT'
  | 'DELETE'
  | 'INVOICE'
  | 'SHOW_PHONE_NUMBERS'
  | 'VALIDATE_ALL'
  | 'VALIDATE_EMAIL'
  | 'VALIDATE_PHONE';

export interface CustomersState {
  customers: CustomerDto[];
  currentPage: number;
  pageSize: number;
  searchString: string;
  totalCustomers: number;
  loading: boolean;
  editCustomerLoading : boolean;
  error: string | null;
  selectedCustomers: CustomerDto[];
  AllCustomersSelected: boolean;
  dialogType: CustomerDialogType;
  selectedCustomer: Customer | undefined;
  drawerType: string | null;
  drawerProps: any | {};
  addCustomerLoading: boolean;
  addCustomerError: string | null;
  createdCustomer: Customer | undefined;
  updatedCustomerName: string | null;
  customerDetaillsUpdated: boolean | null	;
}
const initialState: CustomersState = {
  customers: [],
  currentPage: 0,
  pageSize: 15,
  totalCustomers: 0,
  searchString: '',
  loading: false,
  error: null,
  editCustomerLoading : false,
  selectedCustomers: [],
  AllCustomersSelected: false,
  dialogType: undefined,
  selectedCustomer: undefined,
  drawerType: null,
  drawerProps: {},
  addCustomerLoading: false,
  addCustomerError: null,
  createdCustomer: undefined,
  updatedCustomerName: null,
  customerDetaillsUpdated: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomersStart(state): void {
      state.loading = true;
      state.error = null;
    },
    getCustomersSuccess(
      state,
      action: PayloadAction<{
        customers: CustomerDto[];
        pageSize: number;
        currentPage: number;
        totalCustomers: number;
      }>,
    ): void {
      const { customers, pageSize, totalCustomers, currentPage } = action.payload;
      state.customers = customers;
      state.currentPage = currentPage;
      state.pageSize = pageSize;
      state.totalCustomers = totalCustomers;
      state.loading = false;
      state.error = null;
    },
    getCustomersFailure(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.error = action.payload;
    },
    toggleCustomerStart(state, action: PayloadAction<CustomerDto>): void {
      state.loading = false;
      let indexOfCustomer = 0;
      const c: CustomerDto = state.customers.filter((x: CustomerDto, index: number): any | undefined => {
        if (x.id === action.payload.id) {
          indexOfCustomer = index;
          return x;
        }
        return undefined;
      })[0];

      if (c.optIn === 1) c.optIn = 0;
      else c.optIn = 1;

      state.customers[indexOfCustomer] = c;
    },
    UpdateSelectedCustomer(state, action: PayloadAction<Customer | undefined>): void {
      state.selectedCustomer = action.payload;
    },
    UpdateDialogType(state, action: PayloadAction<CustomerDialogType>): void {
      state.dialogType = action.payload;
    },
    SetSelectedCustomers(state, action: PayloadAction<CustomerDto[]>): void {
      if (state.customers && state.customers.length > 0) {
        state.selectedCustomer = undefined;
      }

      if (action.payload.length === 0) {
        state.selectedCustomers = action.payload;
      } else if (action.payload.length > 1) {
        state.selectedCustomers = action.payload;
      } else {
        const customer = action.payload[0];
        const selected = state.selectedCustomers;
        const selectedIndex = selected.map((c) => c.id).indexOf(customer.id);
        let newSelected: CustomerDto[] = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, customer);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        state.selectedCustomers = newSelected;
      }
    },
    setEditCustomerLoading(state, action : PayloadAction<boolean>): void{
      state.editCustomerLoading = action.payload;
    },
    SetAllSelectedCustomers(state, action: PayloadAction<boolean>): void {
      state.AllCustomersSelected = action.payload;
    },
    showCustomerDrawer(state, action: PayloadAction<{ drawerType: string; drawerProps: any }>): void {
      console.log('state drawer action : ', action);
      state.drawerType = action.payload.drawerType;
      state.drawerProps = action.payload.drawerProps;
    },
    hideCustomerDrawer(state): void {
      state.drawerProps = {};
      state.drawerType = null;
    },
    saveCustomerStart(state): void {
      state.addCustomerLoading = true;
      state.addCustomerError = null;
    },
    saveCustomerSuccess(state): void {
      state.addCustomerLoading = false;
      state.addCustomerError = null;
    },
    saveCustomerFailure(state, action: PayloadAction<string>): void {
      state.addCustomerLoading = false;
      state.addCustomerError = action.payload;
    },
    setCreatedCustomer(state, action: PayloadAction<Customer | undefined>): void {
      state.createdCustomer = action.payload;
    },
    updateCustomerSearchString(state, action: PayloadAction<string>): void {
      state.currentPage = 0;
      state.searchString = action.payload;
    },
    setUpdatedCustomerName(state, action: PayloadAction<string | null>): void {
      state.updatedCustomerName = action.payload;
    },
    setCustomerDetaillsUpdated(state, action: PayloadAction<boolean | null>): void {
      state.customerDetaillsUpdated = action.payload;
    },
    addEmailRow(state, action: PayloadAction<string>): void {
      let em: CustomerEmail = {
        address: '',
        customerId: state.selectedCustomer !== undefined ? state.selectedCustomer.crmCustomerId : 0,
        dealerId: 0,
        contactId: -1,
        emailType: 0,
        lastUpdated: '',
        isPrimary: false,
        isDefault: false,
      };
      if (state.selectedCustomer && state.selectedCustomer.customerEmails == null) {
        state.selectedCustomer.customerEmails = [];
      }
      state.selectedCustomer?.customerEmails.push(em);
    },
    updateEmailRow(state, action: PayloadAction<{ index: number; email: string }>): void {
      if (state.selectedCustomer && state.selectedCustomer.customerEmails) {
        state.selectedCustomer.customerEmails[action.payload.index].address = action.payload.email;
      }
    },
    removeEmailRow(state, action: PayloadAction<number>): void {
      if (state.selectedCustomer && state.selectedCustomer.customerEmails) {
        state.selectedCustomer.customerEmails.splice(action.payload, 1);
      }
    },
  },
});

export const {
  getCustomersStart,
  getCustomersSuccess,
  getCustomersFailure,
  UpdateSelectedCustomer,
  UpdateDialogType,
  SetSelectedCustomers,
  SetAllSelectedCustomers,
  showCustomerDrawer,
  hideCustomerDrawer,
  saveCustomerStart,
  saveCustomerSuccess,
  saveCustomerFailure,
  setCreatedCustomer,
  setEditCustomerLoading,
  toggleCustomerStart,
  updateCustomerSearchString,
  setUpdatedCustomerName,
  setCustomerDetaillsUpdated,
  addEmailRow,
  updateEmailRow,
  removeEmailRow,
} = customerSlice.actions;

export default customerSlice.reducer;





export const fetchSelectedCustomer = (crmCustomerId: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let saveCustomer;
    let message;
    let errorMessage;

    try {
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(setEditCustomerLoading(true));
      let customer = await CustomerAPI.getCustomer( crmCustomerId);
      dispatch(UpdateSelectedCustomer(customer));
      dispatch(setEditCustomerLoading(false));
    } catch (error : any) {
      dispatch(saveCustomerFailure(error.message));
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
      dispatch(setEditCustomerLoading(true));
    }
  };



export const saveCustomerToggle = (customer: CustomerDto): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let saveCustomer;
    let message;
    let errorMessage;

    try {
      const { dealerId } = getState().user;
      dispatch(toggleCustomerStart(customer));
      const newCustomerObject = { ...customer };
      if (newCustomerObject.optIn === 1) newCustomerObject.optIn = 0;
      else newCustomerObject.optIn = 1;

      saveCustomer = CustomerAPI.toggleCustomerOptIn;
      message = 'Customer is being updated';
      dispatch(showSnackbar({ type: 'info', message }));

      await saveCustomer(customer.id, newCustomerObject.optIn);

      dispatch(saveCustomerSuccess());
    } catch (error : any) {
      dispatch(saveCustomerFailure(error.message));
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };


  
// export const saveCustomerToggle = (customer: CustomerDto): AppThunk =>
// async (dispatch, getState): Promise<void> => {
//   let errorMessage;

//   try {
//     const { dealerId } = getState().user;
//     dispatch(toggleCustomerOptIn(customer));
//     const saveCustomer = CustomerAPI.editCustomer;
//     let c = { ...customer };
//     if (c.optIn === 0) {
//       c.optIn = 1;
//     } else {
//       c.optIn = 0;
//     }
//     await saveCustomer(dealerId, c);
//     // dispatch(saveCustomerSuccess());
//     dispatch(UpdateSelectedCustomer(undefined));
//   } catch (error) {
//     console.error(error);
//     // dispatch(saveCustomerFailure(error.message));
//     dispatch(UpdateSelectedCustomer(undefined));
//     // dispatch(hideCustomerDrawer());
//     dispatch(showSnackbar({ type: 'error', message: errorMessage }));
//   }
// };

export const fetchCustomers =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const state = getState();

      dispatch(getCustomersStart());
      const { currentPage, pageSize, searchString } = state.customers;
      const { dealerId } = state.user;
      const customersResponse = await CustomerAPI.getCustomers(dealerId, currentPage, pageSize, searchString);
      if (customersResponse) {
        dispatch(
          getCustomersSuccess({
           
            customers: customersResponse.customers,
            pageSize: customersResponse.pageSize,
            totalCustomers: customersResponse.count,
            currentPage: customersResponse.pageNumber,
          }),
        );
      }
    } catch (error : any) {
      dispatch(getCustomersFailure(error.message));
    }
  };

export const fetchCustomersPage =
  (newPage: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(getCustomersStart());
      const state = getState();
      const { pageSize, searchString } = state.customers;
      const { dealerId } = state.user;

      const customersResponse = await CustomerAPI.getCustomers(dealerId, newPage, pageSize, searchString);
      dispatch(
        getCustomersSuccess({
          customers: customersResponse.customers,
          pageSize: customersResponse.pageSize,
          totalCustomers: customersResponse.count,
          currentPage: customersResponse.pageNumber,
        }),
      );
    } catch (error : any) {
      dispatch(getCustomersFailure(error.message));
    }
  };

export const saveCustomer =
  (customer: Customer): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let saveCustomer;
    let message;
    let errorMessage;

    try {
      const { isUserAdmin, dealerId } = getState().user;
      dispatch(hideCustomerDrawer());
      dispatch(saveCustomerStart());

      if (customer.crmCustomerId) {
        saveCustomer = CustomerAPI.editCustomer;
        message = 'Customer is being updated';
        dispatch(setCustomerDetaillsUpdated(true));
      } else {
        if (!isUserAdmin) customer.reviewFlag = true;

        saveCustomer = CustomerAPI.addCustomer;
        message = 'Customer is being created.';
      }
      dispatch(showSnackbar({ type: 'info', message }));

      const createdCustomer = (await saveCustomer(dealerId, customer)).data;

      if (!customer.crmCustomerId) {
        dispatch(setCreatedCustomer(createdCustomer));
      } else {
        let customerName = '';
        if (createdCustomer.isOrganization) {
          customerName = createdCustomer.organizationName;
        } else {
          customerName =
            createdCustomer?.firstName && createdCustomer?.firstName != ''
              ? createdCustomer?.lastName + ', ' + createdCustomer?.firstName
              : createdCustomer?.name;
        }
        dispatch(setUpdatedCustomerName(customerName));
      }
      dispatch(saveCustomerSuccess());
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(hideCustomerDrawer());
      dispatch(fetchCustomers());
      if (createdCustomer.reviewFlag) {
        dispatch(showSnackbar({ type: 'success', message: 'Customer moved to the Review Hub successfully' }));
      }
      dispatch(setCustomerDetaillsUpdated(false));
    } catch (error : any) {
      dispatch(saveCustomerFailure(error.message));
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(hideCustomerDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };


export const deleteSelectedCustomer =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const state = getState();
      const { selectedCustomer } = state.customers;
      const { dealerId } = state.user;
      if (selectedCustomer === undefined) return;
      await CustomerAPI.deleteCustomer(dealerId, selectedCustomer.crmCustomerId);
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(fetchCustomers());
    } catch (error) {
      dispatch(UpdateSelectedCustomer(undefined));
    }
  };

export const searchCustomer =
  (value: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(updateCustomerSearchString(value));
      dispatch(fetchCustomers());
    } catch (error) {
      console.error(error);
    }
  };
