import moment from 'moment';
import { dateTimeFormats } from './shared/shared-constants';
import { format } from 'date-fns';


/**
 * Format date in  m-dd-yyyy format
 * @param date
 * return Invalid date when invalid date is provided
 */
export default function formatDateTime(dateTime: Date): string {
  if (dateTime) {
    return `${
      dateTime.getMonth() + 1
    }-${dateTime.getDate()}-${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
  }
  return '';
}

export function getConversationFormattedDate(latestDateTime: string): string {
  if (latestDateTime === '') return '';
  const currentDateTime = moment().local();
  let timeStamp = '';
  const diffHours = moment.duration(currentDateTime.diff(latestDateTime)).asHours();
  if (diffHours <= 24) {
    timeStamp = moment(latestDateTime).local().format(dateTimeFormats.HourMinuteFormat);
  } else {
    timeStamp = moment(latestDateTime).local().format(dateTimeFormats.MonthDayFormat);
  }
  return timeStamp;
}

export function getConversationFormattedDateNew(latestDateTime: string): string {
  if (latestDateTime === '') return '';
  let timeStamp = '';
  let dateTime = moment.utc(latestDateTime).local();
  
  if (dateTime == null)
    return timeStamp

  if (moment().local().isSame(dateTime, 'day')) {
    timeStamp = dateTime.format(dateTimeFormats.HourMinuteFormat);
  } else {
    timeStamp = dateTime.format(dateTimeFormats.MonthDayFormat);
  }

  if (timeStamp.toLowerCase() === 'invalid date') {
    timeStamp = '';
  }
    return timeStamp;
  }

export const formatDatePicker = (date: string): string => {
  if (date) return moment(date).format(dateTimeFormats.MonthDayYearFormat);
  else {
    return '';
  }
};


export function formatDateTimeDateFns (dateTime:Date,dateformat:string ):string
{
  let newDate = dateTime.toString().replace('T', ' ') + ' UTC';
  let dt = new Date(newDate);
  return  format(dt, dateformat );
}



