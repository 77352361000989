import {
  Box,
  Button, CircularProgress, Grid,
  IconButton, List, ListItemText, MenuItem, Popover, TextField, Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-regular-svg-icons';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { useStyles } from './CustomerForm';
import useUsersList from 'components/setting/components/department/userUsersList';
import { useAppSelector } from 'app';
import User from 'components/setting/models/user';

export function SalesPerson({ formik }: { formik: any; }): ReactElement {
  console.log(formik.values.assignedSalesRepId)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [usersLoading, setUsersLoading] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState('');
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const classes: any = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
    // formik.setFieldValue('salesRepName', salesPerson);
  };
  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dealerId = useAppSelector((state) => state.user.dealerId);
  let users = useUsersList(dealerId,setUsersLoading);
  
  const filteredUsers = (users: User[]) => {
    if (!searchInput) return users;
    return users.filter((user: User) =>
      user.username.toLowerCase().includes(searchInput.toLowerCase())
    );
  };
  // const [salesPerson, setSalesPerson] = useState<string>("");
  return (
    <Grid container justify="flex-start" alignItems="center" style={{ marginLeft: '.2em' }}>
      <Grid item style={{ marginRight: '5px' }}>
        {' '}
        <FontAwesomeIcon icon={faHandshake} />{' '}
      </Grid>
      <Grid>
        <Typography variant="body1">
          Salesperson:&nbsp;
          {formik.values.salesRepName}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '300px' }}>
          {usersLoading? (
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Grid>
          ) : (
            <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search users"
              value={searchInput}
              onChange={handleSearchChange}
              size="small"
              style={{ marginBottom: '10px' }}
            />
            <List dense style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {users && filteredUsers(users).map((user: User) => (
                  <React.Fragment key={user.id}>
                    <MenuItem key={user.id} onClick={()=> {formik.setFieldValue('salesRepName', user.fullName);
                    setAnchorEl(null)
                    }}>
                      <ListItemText primary={user.username} />
                    </MenuItem>
                  </React.Fragment>
            ))}
          </List>
          </Grid>
          )}
          {/* <TextLimitField
            characters={20}
            fullWidth
            variant="outlined"
            autoFocus
            margin="dense"
            id="salesPerson"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            name="salesPerson"
            label={`Sales Person`}
            value={formik.values.salesRepName}
            onChange={(e: any) => {
              formik.setFieldValue('salesRepName', e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }} /> */}
          {/* <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerAddressBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid> */}
        </Box>
      </Popover>
    </Grid>
  );
}
