import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

function VideoCallRoute(props: RouteProps): React.ReactElement | null {
  const { component: Component, ...restProps } = props;

  if (!Component) return null;
  return (
    <Route {...restProps} render={(routeRenderProps): React.ReactElement => <Component {...routeRenderProps} />} />
  );
}

export default VideoCallRoute;
