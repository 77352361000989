import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { useRouter } from 'common/hooks';

export default function InternalServerError(): ReactElement {
  const router = useRouter();
  function handleGoBack(): void {
    router.history.goBack();
  }
  function handleGotoHomepage(): void {
    router.push('/');
  }
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        paddingTop: '30px',
      }}
      maxWidth="lg">
      <Paper>
        <Box mt={3} mb={1} mx={2}>
          <Typography style={{ marginBottom: '8px' }} variant="h2" component="h1">
            Internal Server Error :(
          </Typography>
          <Typography variant="subtitle2" component="h2">
            The server encountered an internal error or misconfiguration and was unable to complete your request
          </Typography>
        </Box>
        <Divider />
        <Box mx={2} mb={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Button id="goBack" variant="text" color="primary" onClick={handleGoBack}>
                Go back
              </Button>
            </Grid>
            <Grid item>
              <Button id="homePage" variant="text" color="primary" onClick={handleGotoHomepage}>
                Go to homepage
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
