import axios, { CancelTokenSource } from 'axios';
import { CustomerReviewHubResponse } from '../model/customer-review-hub';
import config from 'config';
import { get, put } from 'api';

const customerURL = config.get('api.customer');
const dealerURL = config.get('api.dealer');

let token: CancelTokenSource;

const getCustomersForReviewHub = async (dealerId: number, page: number, pageSize: number, searchString: string): Promise<CustomerReviewHubResponse> => {
  if (token) {
    token.cancel();
  }

  let searchstr = searchString.replace('&', '%26');
  token = axios.CancelToken.source();
  const url = `${customerURL}/v2/customersReviewHub?pageNumber=${page}&pageSize=${pageSize}&searchString=${searchstr}`
  const resp = await get<CustomerReviewHubResponse>(url, { cancelToken: token.token });
  return resp?.data;
};

const toggleDealerIsReviwHubSetting = async (isReviewHubEnabled: boolean) => {
  const url = `${dealerURL}/dealers/toggleDealerIsReviwHubSetting/${isReviewHubEnabled}`;
  await put(url);
};

export default {
  getCustomersForReviewHub,
  toggleDealerIsReviwHubSetting,
};
