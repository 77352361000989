import { Box, Container, Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAppSelector } from 'app';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';

import RoleForm from './role-form';
import PermissionList from './permissionList';
import { showSnackbar } from 'components/root-snackbar';
import UserAPI from 'components/setting/api/user-api';




function NewRoleDrawer({
  open,
  onClose,
  onCreate,
}: {
  open: boolean;
  onClose(): void;
  onCreate(value: any, createMore: boolean): Promise<void>;
}): ReactElement {
  const permissions = PermissionList();
  return (
    <Drawer PaperProps={{ style: { width: '20%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Add Role" onClose={onClose} />
      <Box mb={1} />
      <Container>
        {open && (
          <RoleForm permissions={permissions || []} onCancel={onClose} onSubmit={onCreate} />
        )}
      </Container>
    </Drawer>
  );
}

export default NewRoleDrawer;
