import { Box, Button, Container, Drawer, TextField } from '@mui/material';
import React, { useState } from 'react';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import AppSnackbar from 'components/snackbar/snackbar';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import LeadSourceAPI, { DealLeadTagRequest, DealLeadTags } from 'components/setting/api/manage-lead-source-api';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export function ManageLeadSource({ open, onClose }: { open: boolean; onClose: any }) {
  const [LeadTags, setLeadTags] = React.useState<DealLeadTags[]>([]);
  const [currentValue, SetCurrentValue] = React.useState('' as string);
  const [currentId, SetCurrentId] = React.useState(-1 as number);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [showSpinner, SetSpinner] = React.useState(false);
  const [rowId, setRowId] = useState(-1);
  const [showAddSpinner, setAddSpinner] = React.useState(false);
  const [showEditSpinner, setEditSpinner] = React.useState(false);
  const [showDeleteSpinner, setDeleteSpinner] = React.useState(false);
  const [LeadError, setLeadError] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function SetLeadTag(value: string) {
    SetCurrentValue(value);
  }

  function EditLeadTags(id: number) {
    setIsEdit(true);
    setRowId(id);
    let result = LeadTags.find((obj) => {
      return obj.id === id;
    });

    if (result != null) {
      SetCurrentValue(result.leadTagName);
      SetCurrentId(result.id);
    } else {
      setIsEdit(false);
    }
  }

  async function AddReasonToList() {
    if (currentValue.trim().length > 0) {
      if (currentId >= 0) {
        //Edit case
        setEditSpinner(true);

        const leadTagObj: DealLeadTagRequest = {
          leadTagId: currentId,
          leadDescription: currentValue,
          leadTag: currentValue,
        };
        const response = await LeadSourceAPI.updateLeadSource(leadTagObj);
        let tags = [...LeadTags];
        const index = tags.findIndex((x: DealLeadTags) => x.id === response.id);
        tags[index] = response;
        setLeadTags(tags);
        setLeadError(false);
        SetCurrentValue('');
        setEditSpinner(false);
        showSnackBar({ type: 'success', message: 'Tag updated successfully' });
        SetCurrentId(-1);
        setIsEdit(false);
      } else {
        //New Lead Tag
        setAddSpinner(true);
        const leadTagObj: DealLeadTagRequest = {
          leadDescription: currentValue,
          leadTag: currentValue,
        };
        const response = await LeadSourceAPI.addLeadSource(leadTagObj);
        let tags = [...LeadTags];
        tags.push(response);
        setLeadTags(tags);
        SetCurrentValue('');
        setAddSpinner(false);
        setLeadError(false);
        showSnackBar({ type: 'success', message: 'Tag added successfully' });
      }
    } else {
      setLeadError(true);
    }
  }

  async function handleDelete(id: number) {
    setDeleteSpinner(true);
    try{
      const response = await LeadSourceAPI.deleteLeadSource({ leadTagId: id });
      if (response) {
        let tags = [...LeadTags];
        const index = tags.findIndex((x: DealLeadTags) => x.id === id);
        tags.splice(index, 1);
        setLeadTags(tags);
        setDeleteSpinner(false);
        showSnackBar({ type: 'success', message: 'Tag deleted successfully' });
      } else {
        setDeleteSpinner(false);
        showSnackBar({ type: 'error', message: 'Tag deletion failed' });
      }
    }
    catch(e){
      setDeleteSpinner(false);
      showSnackBar({ type: 'error', message: 'The selected lead source tag is in use and cannot be deleted.' });
    }
  }
  React.useEffect(() => {
    async function fetchData() {
      SetSpinner(true);
      const response = await LeadSourceAPI.getLeadSources();
      setLeadTags(response);
      SetSpinner(false);
    }
    fetchData();
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setLeadError(false);
        SetLeadTag('');
        setIsEdit(false);
        onClose(0);
      }}>
      <Box style={{ width: '500px' }}>
        <DrawerToolbar
          title="Lead Source Tags List"
          onClose={() => {
            setLeadError(false);
            SetLeadTag('');
            setIsEdit(false);
            onClose(0);
          }}
        />

        <Container>
          <Box mt={1}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  size="small"
                  value={currentValue}
                  error={LeadError}
                  helperText={LeadError && 'Please enter a valid tag name'}
                  onChange={(e: any) => {
                    setLeadError(false);
                    if (e.target.value.trim() == '') {
                      SetLeadTag('');
                    } else SetLeadTag(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={AddReasonToList}
                  style={{ marginTop: 10, float: 'left', backgroundColor: '#3f51b5' }}>
                  {isEdit ? 'Update Lead Source' : 'Add Lead Source'}
                </Button>
                {isEdit && (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => {
                      setLeadError(false);
                      SetCurrentValue('');
                      SetCurrentId(-1);
                      setEditSpinner(false);
                      setIsEdit(false);
                    }}
                    style={{ marginTop: 10, float: 'right' }}>
                    Cancel
                  </Button>
                )}
              </Grid>
            </Grid>
            {showSpinner ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress style={{ marginLeft: '200px', marginTop: '100px' }} />
              </Box>
            ) : (
              <TableContainer style={{ border: 2, marginTop: 30 }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {LeadTags.map((row: DealLeadTags, index: any) => (
                      <StyledTableRow hover key={row.id}>
                        <StyledTableCell scope="row">{row.leadTagName}</StyledTableCell>
                        {row.dealerId != -1 ? (
                          <>
                            <TableCell
                              align="right"
                              sx={{
                                color: 'gray',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                '&:hover': { color: 'blue' },
                                width: '0px',
                              }}
                              onClick={(event) => {
                                if (!showEditSpinner && !showDeleteSpinner) {
                                  EditLeadTags(row.id);
                                  setLeadError(false);
                                }
                              }}>
                              {showEditSpinner && row.id === rowId ? (
                                <CircularProgress size="1.1em" />
                              ) : (
                                <EditIcon sx={{ fontSize: 'medium' }}></EditIcon>
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: 'gray',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                '&:hover': { color: 'red' },
                                width: '0px',
                              }}
                              onClick={() => {
                                if (!showEditSpinner && !showDeleteSpinner) {
                                  setRowId(row.id);
                                  handleDelete(row.id);
                                }
                              }}>
                              {showDeleteSpinner && row.id === rowId ? (
                                <CircularProgress size="1.1em" style={{ color: 'red' }} />
                              ) : (
                                <DeleteForeverIcon sx={{ fontSize: 'medium' }}></DeleteForeverIcon>
                              )}
                            </TableCell>
                          </>
                        ):(
                          <>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          </>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {showAddSpinner && (
                  <Box textAlign={'center'}>
                    <CircularProgress size="1.1em" color="primary" />
                  </Box>
                )}
              </TableContainer>
            )}
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
