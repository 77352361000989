import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAppDispatch, useAppSelector } from 'app';
import FormHelperText from '@mui/material/FormHelperText';
// import Switch from '@mui/joy/Switch';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';

const UserToggles = (props: { notifState: any; handleToggle: any }) => {
  const { handleToggle } = props;
  const { dailySms, notiMsg, taskNotif, leadNotif, convoNotif, detracNotif, paymentNotif } = props.notifState;
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Email Notifications</FormLabel>
      <FormGroup>
        <Grid container>
          <Grid item md={6} xs={6}>
            <FormControlLabel
              control={<Switch checked={dailySms} onChange={(e) => handleToggle(e)} name="dailySms" />}
              label="Daily summary"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControlLabel
              control={<Switch checked={notiMsg} onChange={(e) => handleToggle(e)} name="notiMsg" />}
              label="Notification for each inbound text"
            />
          </Grid>
          {userPermissions.find((x) => x.featureName.toLowerCase() === 'deals') && (
            <Grid item md={6} xs={6}>
              <FormControlLabel
                control={<Switch checked={leadNotif} onChange={(e) => handleToggle(e)} name="leadNotif" />}
                label="Lead assignment"
              />
            </Grid>
          )}
          {userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks') && (
            <Grid item md={6} xs={6}>
              <FormControlLabel
                control={<Switch checked={taskNotif} onChange={(e) => handleToggle(e)} name="taskNotif" />}
                label="Task assignment"
              />
            </Grid>
          )}
          {userPermissions.find((x) => x.featureName.toLowerCase() === 'messages') &&(
            <Grid item md={6} xs={6}>
            <FormControlLabel
              control={<Switch checked={convoNotif} onChange={(e) => handleToggle(e)} name="convoNotif" />}
              label="Conversation assignment"
            />
          </Grid>
          )}
          <Grid item md={6} xs={6}>
            <FormControlLabel
              control={<Switch checked={detracNotif} onChange={(e) => handleToggle(e)} name="detracNotif" />}
              label="Survey detractor"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormControlLabel
              control={<Switch checked={paymentNotif} onChange={(e) => handleToggle(e)} name="paymentNotif" />}
              label="Payment received"
            />
          </Grid>
        </Grid>
      </FormGroup>
      {/* <FormHelperText>Be careful</FormHelperText> */}
    </FormControl>
  );
};

export default UserToggles;
