import { Box, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import MergeTagWidget from 'components/broadcast/components/broadcast-page/merge-tags';
import { MessageTemplate } from 'components/broadcast/models/message-template';
import Department from 'components/setting/models/department';

interface QuickMessageForm {
  id?: number;
  departmentId: number;
  body: string;
}
export default function QuickMessageForm({
  quickMessage,
  departments,
  onCancel,
  onSubmit,
}: {
  departments: Department[];
  quickMessage?: MessageTemplate;
  onCancel(): void;
  onSubmit(values: any, createMore: boolean): void;
}): React.ReactElement {
  const initialValues = {
    id: quickMessage?.id || 0,
    body: quickMessage?.body || '',
    departmentId: quickMessage?.departmentId || 0,
    title: quickMessage?.title || ''
  };
  const editMode = !!quickMessage?.departmentId;
  const validationSchema = Yup.object<any>().shape<any>({
    body: Yup.string().required('Body is required'),
    departmentId: Yup.number().notOneOf([0], 'Department is required').required('Department is required'),
    title: Yup.string().required('Title is required').max(49,'Max length 50'),

    
  });

  function appendToBody(value: string): void {
    if (formik.values.body == null) formik.values.body = '';
      //const curLength = type === 'SMS' ? 160 : 1500;
      const curLength = 1500;
      const bodyLength = formik.values.body.length;
      const totalLength = curLength - bodyLength;
      if (value.length > totalLength) {
        value = value.substring(0, totalLength);
      }
    formik.setFieldValue('body', formik.values.body + value + ' ');
  }
  
  async function handleSubmit(values: any): Promise<void> {
    await onSubmit(values, false);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSaveAndCreateNew(): Promise<void> {
    await formik.validateForm();
    if (formik.isValid) {
      try {
        formik.setSubmitting(true);
        await onSubmit(formik.values, true);
        formik.setSubmitting(false);
        formik.resetForm();
      } catch (error) {
        formik.setSubmitting(false);
        console.log(error);
      }
    }
  }

  function handleDepartmentsChange(department: Department): void {
    formik.setFieldValue('departmentId', department.departmentId);
  }

  const departmentValue = departments.find((x) => x.departmentId === formik.values.departmentId) || null;
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        multiline
        autoFocus
        margin="dense"
        variant="outlined"
        name="title"
        id="title"
        label="Message Title"
        onChange={formik.handleChange}
        placeholder="Message title"
        value={formik.values.title}
        error={!!formik.errors.title}
        helperText={formik.errors.title}
        inputProps={{ maxLength: 50} }
        required
      />
      <TextField
        fullWidth
        multiline
        rows={3}
        margin="dense"
        variant="outlined"
        name="body"
        id="body"
        label="Message"
        onChange={formik.handleChange}
        placeholder="Quick Message"
        value={formik.values.body}
        error={!!formik.errors.body}
        helperText={formik.errors.body}
        required
      />

     <MergeTagWidget
                    refScreen = "Messenger"
                    disabled={false}
                    onClick={(value: string): void => appendToBody(value)}
      />

      <Autocomplete
        openOnFocus
        options={departments}
        id="departments"
        groupBy={(option: Department): string => option.dealerLocationName}
        onChange={(event: any, values: any): void => handleDepartmentsChange(values)}
        getOptionLabel={(option: any): string => option.departmentName}
        value={departmentValue}
        getOptionSelected={(option: Department, value: Department): boolean =>
          option.departmentId === value.departmentId
        }
        renderInput={(params): React.ReactElement => (
          <TextField
            {...params}
            name="departmentId"
            id="departments"
            variant="outlined"
            margin="dense"
            error={!!formik.errors.departmentId}
            helperText={formik.errors.departmentId}
            label="Departments"
            placeholder="Select Departments"
          />
        )}
      />
      <Box mt={2} id="formBtn" display="flex">
        <Box flexGrow={1} />
        {editMode ? (
          <Button
            id="saveQuickMessageBtn"
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            disabled={formik.isSubmitting}>
            Save
          </Button>
        ) : (
          <>
            <Box mx={1}>
              <Button
                id="createQuickMessageBtn"
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                disabled={formik.isSubmitting}>
                Create
              </Button>
            </Box>
            <Box>
              <Button
                id="saveAndCreateNewQuickMessageBtn"
                type="button"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSaveAndCreateNew}
                disabled={formik.isSubmitting}>
                Save & Create New
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}
