import { get, post, put } from 'api';
import config from 'config';

import { LostReason } from '../models/deal-pipelines';

const dealPipelineUrl = config.get('api.lead');

const LostDealAPI = {
  async getLostReasons(dmsDealerIdCrm: number): Promise<LostReason[]> {
    const url = `${dealPipelineUrl}/lead/deal/lostReason/${dmsDealerIdCrm}`;
    const { data } = await get(url);
    return data;
  },
  async AddLostReason(dmsDealerIdCrm: number, lostReason: LostReason): Promise<LostReason[]> {
    const url = `${dealPipelineUrl}/lead/deal/lostReason/${dmsDealerIdCrm}`;
    const { data } = await post(url, lostReason);
    return data;
  },
  async UpdateLostReason(dmsDealerIdCrm: number, lostReason: LostReason): Promise<LostReason[]> {
    const url = `${dealPipelineUrl}/lead/deal/lostReason/${dmsDealerIdCrm}`;
    const { data } = await put(url, lostReason);
    return data;
  },
};

export default LostDealAPI;
