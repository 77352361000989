import { Box, Button, Divider, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
}));

function DrawerToolbar({
  title,
  onSave,
  onClose,
}: {
  title: string;
  onSave?(): Promise<void> | void;
  onClose?: (e: any) => void;
}): ReactElement {
  const classes: any = useStyles();
  const [saving, setSaving] = React.useState(false);
  async function handleOnSave(): Promise<void> {
    if (onSave) {
      setSaving(true);
      await onSave();
      setSaving(false);
    }
  }
  return (
    <>
      <Toolbar variant="dense">
        <Box flexGrow={1}>
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        </Box>
        {onSave ? (
          <Button variant="contained" size="small" onClick={handleOnSave} disabled={saving} color="primary">
            Save
          </Button>
        ) : (
          <IconButton id="closeBtn" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
    </>
  );
}

export default DrawerToolbar;
