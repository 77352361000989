import { Container } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Customer from 'common/models/customer';
import React, { forwardRef, ReactElement, Ref, useImperativeHandle, useRef } from 'react';
import { useAppDispatch } from 'app';

import { hideCustomerDrawer, saveCustomer } from '../Customers/customersSlice';
import CustomerForm from '../CustomerForm/CustomerForm';
import { CustomerFormRef } from "../CustomerForm/CustomerFormRef";
import { createDispatchHook } from 'react-redux';

export interface CustomerAddDrawerProps {
  CompareChanges: (callback : any) => void;
  SubmitForm: () => void;
}



const CustomerAddDrawer = forwardRef(({}, ref: Ref<CustomerAddDrawerProps>): ReactElement => {

  const dispatch = useAppDispatch();
  const childRef = useRef<CustomerFormRef>(null);
  
  const handleCustomerFormSubmit = (customer: Customer): void => {
    dispatch(saveCustomer(customer));
  };
  
  useImperativeHandle(ref, () => ({
    CompareChanges: function (callback : any) {
      if(childRef && childRef.current){
        childRef.current.CompareChanges((r : boolean)=> {
          callback(r);
        });
      }else{
        callback(false);
      }
    },
    SubmitForm: function(){
      if(childRef && childRef.current){
        childRef.current.SubmitForm();
      }
    }
  }));

  console.log("rendered drawer");

  return (
    <>
      <DrawerToolbar title="Add Customer" onClose={()=> {dispatch(hideCustomerDrawer())}} />
      <Container>
        <CustomerForm
          ref={childRef}
          disabled={false}
          btnDisabled={false}
          onClose={()=> {}}
          onFormSubmit={handleCustomerFormSubmit}
          validate
        />
      </Container>
    </>
  );
});

export default CustomerAddDrawer;
