import { CustomerTags } from './../../../common/models/customer';
import { destroy, get, patch, post, put } from 'api';
import axios, { CancelTokenSource } from 'axios';
import Customer, { CustomerDto } from 'common/models/customer';
import { RouteConstants } from 'common/utils/shared/routes-constants';
import { CustomerContactNew } from 'components/customer/components/CustomerDrawer/CustomerContactDrawer';
import config from 'config';
import { v1 as uuidv1 } from 'uuid';

const BASE_URL = config.get('api.customer');
const GoogleApiKey = config.get('api.googlePlaces');

interface GetCustomersResponse {
  customers: CustomerDto[];
  count: number;
  pageSize: number;
  pageNumber: number;
}
export interface CustomerCategory {
  customerCategoryId: string;
  dealerId: number;
  isActive: boolean;
  lastUpdated: Date;
  name: string;
}

export interface CustomerPhoneType {
  dealerId: number;
  customerPhoneTypeId: number;
  name: string;
  lastUpdated: Date;
}
export interface CustomerEmailType {
  dealerId: number;
  customerEmailTypeId: number;
  name: string;
  lastUpdated: Date;
}
export interface GravityPaymentsInvoiceRequest {
  query?: "GetOrders";
  queryBody: QueryBody;
}
export interface QueryBody {
  DealerId: number;
  PageNumber?: number;
  PageSize?: number;
  CustomerId: string;
  OrderStatus?: string;
  WithDetails?: number;
}
export type GravityPaymentInvoicesResponse = GravityPaymentInvoicesData[];
export interface GravityPaymentInvoicesData {
  dealerId?: number
  orderStatus?: string
  orderTypeId?: number
  orderStatusId?: number
  invoiceId?: number
  orderId?: number
  invoiceNumber?: number
  invoiceDate?: string
  customerId?: number
  isInternal?: boolean
  hasPartsInvoice?: boolean
  hasWorkOrder?: boolean
  hasSalesDeal?: boolean
  hasRental?: boolean
  isWarranty?: boolean
  isQuote?: boolean
  isActive?: boolean
  tax?: number
  invoiceAmount?: number
  customer?: string
  dmsOrderStatus?: any
  payment?: number
}


let token: CancelTokenSource;

const getCustomers = async (dealerId: number, page: number, pageSize: number, searchString: string): Promise<any> => {
  if (token) {
    token.cancel();
  }
  let searchstr = searchString.replace('&', '%26');
  token = axios.CancelToken.source();
  const axiosResponse = await get<GetCustomersResponse>(
    `${BASE_URL}/v2/customers?dealerId=${dealerId}&pageNumber=${page}&pageSize=${pageSize}&searchString=${searchstr}`,
    { cancelToken: token.token },
  );
  return axiosResponse?.data;
};

const searchCustomersAndContacts = async (dealerId: number, page: number, pageSize: number, searchString: string): Promise<any> => {
  if (token) {
    token.cancel();
  }
  let searchstr = searchString.replace('&', '%26');
  token = axios.CancelToken.source();
  const axiosResponse = await get<any>(
    `${BASE_URL}/v2/searchCustomersAndContacts?dealerId=${dealerId}&pageNumber=${page}&pageSize=${pageSize}&searchString=${searchstr}`,
    { cancelToken: token.token },
  );
  return axiosResponse?.data;
};

const addCustomer = async (dealerId: number, customer: Customer): Promise<number> => {
  customer.dealerId = dealerId;
  return await post(`${BASE_URL}/customer/details`, customer, { headers: { Guid: uuidv1() } });
};

const getCustomer = async (crmCustomerId: number): Promise<any> => {
  const { data } = await get(`${BASE_URL}/customer/detailsv2/${crmCustomerId}`);
  return data;
};

const getCustomerCrmIdByCustomerId = async (customerId: number): Promise<any> => {
  const { data } = await get(`${BASE_URL}/customerCrmIdByCustomerId/${customerId}`);
  return data;
};

const editCustomer = async (dealerId: number, customer: Customer): Promise<any> => {
  try {
    return await put(`${BASE_URL}/customer/detailsv2/${customer.crmCustomerId}`, customer);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteCustomer = async (dealerId: number, customerId: number): Promise<any> => {
  try {
    return await destroy(`${BASE_URL}/customers/${customerId}`);
  } catch (error) {
    return null;
  }
};

// const getPhoneType = (phoneTypeCode: number): string => {
//   switch (phoneTypeCode) {
//     case 0:
//       return 'Land line';
//     case 1:
//       return 'Cell phone';
//     case 2:
//       return 'Alternate phone number';
//     case 3:
//       return 'Fax';
//     default:
//       return 'UnKnown';
//   }
// };

const getPhoneTypes = async (dealerId: number): Promise<CustomerPhoneType[]> => {
  try {
    let resp = await get(`${BASE_URL}/customer/details/phone/types/`);
    return resp.data;
  } catch (err) {
    return [];
  }
};

const getEmailTypes = async (dealerId: number): Promise<CustomerEmailType[]> => {
  try {
    let resp = await get(`${BASE_URL}/customer/details/email/types/`);
    return resp.data;
  } catch (err) {
    return [];
  }
};

const getCategories = async (dealerId: number): Promise<CustomerCategory[]> => {
  try {
    let resp = await get(`${BASE_URL}/customer/details/categories/`);
    return resp.data;
  } catch (err) {
    return [];
  }
};
const getCustomerPhoneNumberCount = async (crmCustomerIds: number[] | null): Promise<any> => {
  try {
    return await post(`${BASE_URL}/getCustomerPhoneNumbersCount/`, crmCustomerIds);
  } catch (err) {
    return null;
  }
};

const toggleCustomerOptIn = async (crmCustomerId: number, optInStatus: number): Promise<any> => {
  try {
    return await patch(`${BASE_URL}/customer/details/optin/`, { crmCustomerId, optIn: optInStatus });
  } catch (err) {
    return null;
  }
};
const getPhoneNumbers = async (crmCustomerId: number): Promise<any> => {
  try {
    let response = await get(`${BASE_URL}/customer/details/phonenumbers/${crmCustomerId}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

const updatePhoneNumberToPrimary = async (crmCustomerId: number, phoneNumberId: number): Promise<any> => {
  try {
    return await patch(`${BASE_URL}/customer/details/phonenumbers/primary/${crmCustomerId}/${phoneNumberId}`, {
      phoneNumberId,
    });
  } catch (err) {
    return null;
  }
};

const GetContacts = async (json: string): Promise<any> => {
  try {
    let resp = await get(`${BASE_URL}/ExecuteJsonSP/Customers.spGetContacts/${json}`);
    return resp.data;
  } catch (err) {
    return null;
  }
};

const GetJsonSp = async (sp: string, json: string): Promise<string> => {
  try {
    let resp = await get(`${BASE_URL}/ExecuteJsonSP/${sp}/${json}`);
    return resp.data;
  } catch (err) {
    return '';
  }
};

const addCustomerContact = async (dealerId: number, customerContact: CustomerContactNew): Promise<any> => {
  return await post(`${BASE_URL}/customer/contact/${dealerId}`, customerContact);
};

const checkCustomerNumberExists = async (req: any): Promise<any> => {
  try {
    let resp = await get(`${BASE_URL}/customer/customerNumberExists/${req.crmCustomerId}/${req.customerNumber}`);
    return resp.data;
  } catch (err) {
    return [];
  }
};

const TextToPayCustomer = async (json: string): Promise<string> => {
  try {
    let url = `${BASE_URL}/TextToPayCustomer`;
    let resp = await post(url, json);
    return resp.data;
  } catch (err) {
    return 'Err: ' + err;
  }
};

const GetCustomerTags = async (): Promise<CustomerTags[]> => {
  try {
    let url = `${BASE_URL}/customer/tags`;
    return  await (await post(url)).data;
  } catch (err:any) {
    return err;
  }
}

const GetZipcode = async (lat: number, lng: number) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GoogleApiKey}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
const GetCustomerInvoices = async (request: GravityPaymentsInvoiceRequest) : Promise<GravityPaymentInvoicesResponse> => {
  try {
    let url = `${BASE_URL}/FetchCustomerInvoices`;
    let resp = await post(url, request);
    return resp.data;
  } catch (err) {
    console.error('Error:', err);
    throw err;
  }
}

// #region Part Number/Model Number Methods

let modelSuggestionToken: CancelTokenSource;

const cancelPartNumberToken = ()=>{
  if (modelSuggestionToken) {
    modelSuggestionToken.cancel();
  }
}
const getModelNumbers = async ( searchKeyword: string,pageNumber: number, pageSize: number):Promise<any>=>
{
  cancelPartNumberToken();

  let url = `${RouteConstants.GetCustomerPartAndModelNumber}?`;
  if (pageNumber !== undefined) {
    url += `pageNumber=${pageNumber}`;
  }
  if (pageSize !== undefined) {
    url += `&pageSize=${pageSize}`;
  }
  if (searchKeyword !== undefined && searchKeyword!="") {
    url += `&searchKeyword=${searchKeyword}`;
  }
  
  modelSuggestionToken = axios.CancelToken.source();
  const { data } = await get(url,{ cancelToken: modelSuggestionToken.token });

  return data;
};

// #endregion Part Number/Model Number

export default {
  cancelPartNumberToken,
  getModelNumbers,
  getCustomers,
  getCustomer,
  searchCustomersAndContacts,
  addCustomer,
  editCustomer,
  deleteCustomer,
  toggleCustomerOptIn,
  updatePhoneNumberToPrimary,
  getCustomerPhoneNumberCount,
  getPhoneTypes,
  getPhoneNumbers,
  getEmailTypes,
  getCategories,
  GetContacts,
  GetJsonSp,
  addCustomerContact,
  checkCustomerNumberExists,
  TextToPayCustomer,
  GetCustomerTags,
  getCustomerCrmIdByCustomerId,
  GetZipcode,
  GetCustomerInvoices
};
