import config from 'config';
import { get, post, destroy, put } from 'api';
import { CustomerCategory } from '../models/customer-category';
import CustomerGroup from '../models/customer-group';
import { Manufacturer } from '../models/manufacturer';

const customerUrl = config.get('api.customer');

export const CustomerSegmentAPI = {
  getCustomerSegments: async (dealerId: number | undefined): Promise<CustomerGroup[]> => {
    let url = `${customerUrl}/segments`;
    if (dealerId) {
      url += `?dealerId=${dealerId}`;
    }
    const { data } = await get(url);
    return data;
  },
  getSegmentCustomers: async (segmentId: number, pageNumber: number, pageSize: number, forAllLocation:number, searchSegment:string, dealerId: number, EmailOrSms: string, isDownload?: boolean): Promise<any> => {
    let url = `${customerUrl}/segments/${segmentId}/customers?`;

      if (pageNumber !== undefined) {
      url += `pageNumber=${pageNumber}`;
    }
    if (pageSize !== undefined) {
      url += `&pageSize=${pageSize}`;
    }
    if (forAllLocation !== undefined) {
      url += `&forAllLocation=${forAllLocation}`;
    }
    if (searchSegment !== undefined) {
        let searchstr = searchSegment.replace('&', '%26');

        url += `&searchSegment=${searchstr}`;
    }
    if (dealerId !== undefined && dealerId>0) {
      url += `&dealerId=${dealerId}`;
    }
    if (EmailOrSms !== undefined && EmailOrSms!="") {
      url += `&EmailOrSms=${EmailOrSms}`;
    }
    if (isDownload !== undefined && isDownload) {
      url += `&isDownload=1`;
    }


    const { data } = await get(url);
    return data;
  },
  sendMessageToSegment: async (
    message: string,
    surveyUrl: string,
    segmentIds: number[],
    dealerId?: number,
    dmsDealerId?: number,
  ): Promise<any> => {
    const url = `${customerUrl}/SendMessageToSegment?dealerLocationId=${dealerId || ''}&dmsDealerId=${
      dmsDealerId || ''
    }`;
    const { data } = await post(url, { message, surveyUrl, segmentIds });
    return data;
  },
  getSegmentCustomerCount: async (
    segmentId: number,
    dealerId: number | undefined,
    dmsDealerId?: number,
    surveyOrBroadcast? : string ,
    fetchOnlyEmails = false
    
  ): Promise<any> => {
    try {
      const url = `${customerUrl}/segments/${segmentId}/customer/count?dealerId=${dealerId || ''}&dmsDealerId=${
        dmsDealerId || ''
      }&fetchOnlyEmails=${fetchOnlyEmails}&surveyOrBroadcast=${surveyOrBroadcast}`;
      const { data } = await get(url);
      return data.count;
    } catch (error) {}
  },
  createCustomerGroup: async (customerGroup: CustomerGroup): Promise<number> => {
    const url = `${customerUrl}/segments`;
    const { data } = await post<number>(url, customerGroup);
    return data;

  },
  getCustomerGroup: async (id: number): Promise<CustomerGroup> => {
    const url = `${customerUrl}/segments/${id}`;
    const { data } = await get<CustomerGroup>(url);
    return data;
  },
  updateCustomerGroup: async (customerGroup: CustomerGroup): Promise<void> => {
    const url = `${customerUrl}/segments`;
    const { data } = await put(url, customerGroup);
    return data;
  },
  deleteCustomerGroup: async (id: number): Promise<void> => {
    const url = `${customerUrl}/segments/${id}`;
    const { data } = await destroy(url);
    return data;
  },
  getCustomerCategories: async (): Promise<CustomerCategory[]> => {
    const url = `${customerUrl}/customer-categories`;
    const { data } = await get(url);
    return data;
  },
  getManufacturers: async (): Promise<Manufacturer[]> => {
    const url = `${customerUrl}/manufacturers`;
    const { data } = await get(url);
    return data;
  },
  getZipCodes: async (): Promise<string[]> => {
    const url = `${customerUrl}/zipCodes`;
    const { data } = await get(url);
    return data;
  },
  GetCustomerInvoice: async (dealerId: number, InvoiceId: number): Promise<string[]> => {
    const url = `${customerUrl}/GetCustomerInvoice/${dealerId}/${InvoiceId}`;
    const { data } = await get(url);
    return data.invoice[0];
  },
  GetCustomerInvoicesList: async (dealerId: number, crmCustomerId: number): Promise<any[]> => {
    const url = `${customerUrl}/GetCustomerInvoicesList/${dealerId}/${crmCustomerId}`;
    const { data } = await get(url);
    return data.invoice;
  },
  GetInvoices: async (params:string): Promise<string[]> => {
    const url = `${customerUrl}/GetInvoices`;
    const { data } = await post(url, params);
    return data.invoice;
  },
  CreateInvoiceExportFile: async (params:string): Promise<string[]> => {
    const url = `${customerUrl}/CreateInvoiceExportFile`;
    //alert(url);
    const { data } = await post(url, params);
    return data;
  },
  GetDealerManufacturer: async (): Promise<string[]> => {
    const url = `${customerUrl}/GetDealerManufacturer`;
    const { data } = await get(url);
    return data.Manufacturer;
  },
  GetDealerLocations: async (): Promise<string[]> => {
    const url = `${customerUrl}/GetLocationsByDealerId`;
    // alert(url);
    const { data } = await get(url);
    return data.Locations;
  },
  




};

export default CustomerSegmentAPI;
