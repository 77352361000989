/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DealerAPI from 'components/authentication/api/dealer-api';
import { AppThunk } from 'app';
import { showSnackbar } from 'components/root-snackbar/snackbar-slice';

export interface DealerEmailState {
  sendGridId: number;
  loading: boolean;
  dealerId: number;
  replyToAddress: string;
  replyToName: string;
  emailFooter: string;
  fromAddress: string;
  fromName: string;
  sameFromAndReplyToAddress: boolean;
  fromAddressVerified: boolean;
  replyToAddressVerified: boolean;
}

const initialState: DealerEmailState = {
  sendGridId: 0,
  loading: false,
  dealerId: 0,
  replyToAddress: '',
  replyToName: '',
  emailFooter: '',
  fromAddress: '',
  fromName: '',
  sameFromAndReplyToAddress: false,
  replyToAddressVerified: false,
  fromAddressVerified: false
};

const dealerEmail = createSlice({
  name: 'dealerEmail',
  initialState,
  reducers: {
    setDealerReplyTo(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.replyToAddress = action.payload;
    },
    setDealerReplyToName(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.replyToName = action.payload;
    },
    setDealerFromName(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.fromName = action.payload;
    },
    setDealerFrom(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.fromAddress = action.payload;
    },
    setDealerEmail(state, action: PayloadAction<DealerEmailState>): void {
      state.dealerId = action.payload.dealerId;
      state.sendGridId = action.payload.sendGridId;
      state.loading = false;

      state.replyToAddress = action.payload.replyToAddress;
      state.replyToName = action.payload.replyToName;
      state.replyToAddressVerified = action.payload.replyToAddressVerified;

      state.fromAddress = action.payload.fromAddress;
      state.fromName = action.payload.fromName;
      state.fromAddressVerified = action.payload.fromAddressVerified;
      state.sameFromAndReplyToAddress = action.payload.sameFromAndReplyToAddress;
      state.emailFooter = action.payload.emailFooter;
    },
    setDealerEmailLoading(state, action: PayloadAction<boolean>): void {
      state.loading = action.payload;
    },
    setDealerFooter(state, action: PayloadAction<string>): void {
      state.emailFooter = action.payload;
    },
    setSameFromAndReplyToAddressChange(state, action: PayloadAction<boolean>) {
      state.sameFromAndReplyToAddress = action.payload;
      if (state.sameFromAndReplyToAddress) {
        state.replyToAddress = state.fromAddress;
        state.replyToName = state.fromName;
      }
    },
  },
});

export const {
  setSameFromAndReplyToAddressChange,
  setDealerReplyTo,
  setDealerFromName,
  setDealerReplyToName,
  setDealerFrom,
  setDealerEmail,
  setDealerEmailLoading,
  setDealerFooter,
} = dealerEmail.actions;
export default dealerEmail.reducer;

export const updateDealerEmail =
  (dealerEmail: DealerEmailState): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const {
        user: { dmsDealerId },
      } = getState();
      dispatch(setDealerEmailLoading(true));
      let resp = await DealerAPI.updateDealerEmail(dealerEmail, dmsDealerId);
      if (resp) {
        dispatch(setDealerEmail(resp));
        dispatch(showSnackbar({ type: 'success', message: 'Email Settings updated Successfully' }));
      }
      dispatch(setDealerEmailLoading(false));
    } catch (error) {
      dispatch(setDealerEmailLoading(false));
    }
  };

export const verifyDealerEmail =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const {
        user: { dmsDealerId },
      } = getState();
      dispatch(setDealerEmailLoading(true));
      let resp = await DealerAPI.verifyDealerEmail(dmsDealerId);
      if (resp) {
        dispatch(setDealerEmail(resp));
        dispatch(showSnackbar({ type: 'success', message: 'Email Verification completed successfully' }));
      }
      dispatch(setDealerEmailLoading(false));
    } catch (err) {}
  };

export const resendVerificationEmail =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const {
        user: { dmsDealerId },
      } = getState();
      dispatch(setDealerEmailLoading(true));
      let resp = await DealerAPI.resendVerificationEmail(dmsDealerId);
      if (resp) {
        dispatch(showSnackbar({ type: 'success', message: 'Verification request re-sent' }));
      }
      dispatch(setDealerEmailLoading(false));
    } catch (err) {}
  };

export const cancelVerificationEmail =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const {
        user: { dmsDealerId },
      } = getState();
      dispatch(setDealerEmailLoading(true));
      let resp = await DealerAPI.cancelVerificationEmail(dmsDealerId);
      dispatch(setDealerEmail(initialState));
      if (resp) {
        dispatch(showSnackbar({ type: 'success', message: 'Request Cancelled. Please add your new Email' }));
      }
      dispatch(setDealerEmailLoading(false));
    } catch (err) {}
  };

export const fetchDealerEmail =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { dmsDealerId } = getState().user;
      const dealerEmail = await DealerAPI.getDealerEmail(dmsDealerId);
      dispatch(setDealerEmail(dealerEmail));
    } catch (error) {}
  };
