import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Editor } from '@tinymce/tinymce-react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Button, Chip, CircularProgress, Grid, IconButton, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import { TimelineOppositeContent } from '@material-ui/lab';
import useStyles from '../styles/deal-edit-drawer-styles';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { DealAttachment, DealNote, DealTask, DealTimeline } from '../model/deal-edit-drawer';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import NoteIcon from '@mui/icons-material/Note';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAppDispatch, useAppSelector } from 'app';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { ITaskTimes, taskHours } from 'components/lead/components/task-board/taskhours-step';
import { formatDatePicker } from 'common/utils/format-date';
import { DealStatusEnum } from '../model/DealStatusEnum';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImageLibraryButton from 'components/image-library/components/image-library-modal';
import { post } from 'api';
import { AxiosResponse } from 'axios';
import { showSnackbar } from 'components/root-snackbar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import config from 'config';
import { GetAttachments } from 'dmsAppsCallbacks';
import PdfPreviewModal from './PdfPreviewModal';
import ImageModal from 'components/messaging/components/conversation-panel/image-modal';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { getFileExtension } from 'common/utils/shared/getFileExtension';

const textEditorApiKey = config.get('api.textEditor');

const editorInit = {
  height: 150,
  convert_urls: true,
  plugins: 'lists',
  toolbar: `bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify`,
  menubar: false,
  branding: false,
  force_br_newlines: true,
  force_p_newlines: false,
  forced_root_block: '', // Needed for 3.x
};

export interface DmsAttachmentAttribute{
  name: string,
  size: number,

}

const sortDealTimeline = (list: DealTimeline[]): DealTimeline[] => {
  //Take scheduled tasks always on the top and then sort by date
  let scheduledList = list.filter((x) => !x.isAlreadyCompleted);
  scheduledList = scheduledList.sort((x: DealTimeline, y: DealTimeline) => {
    return new Date(x.date).getTime() - new Date(y.date).getTime();
  });
  let othersList = list.filter((x) => x.isAlreadyCompleted);
  othersList = othersList.sort((x: DealTimeline, y: DealTimeline) => {
    return new Date(y.date).getTime() - new Date(x.date).getTime();
  });
  const finalList = scheduledList.concat(othersList);
  return finalList;
};

let formikNote: any = undefined;
let timelines: DealTimeline[] = [];
function DealEditDrawerTabs({
  formik,
  taskTypes,
  taskOutcomes,
  dealerUsers,
  filesToUpload,
  removedDealAttachments
}: {
  formik: any;
  taskTypes: any[];
  taskOutcomes: any[];
  dealerUsers: any[] | undefined;
  filesToUpload: DealAttachment[];
  removedDealAttachments: DealAttachment[];
}) {
  const classes: any = useStyles();
  const [value, setValue] = React.useState('1');
  const [disableTaskForm, setDisableTaskForm] = React.useState<boolean>(false);
  timelines = [...formik.values.dealTimelines];

  let initialValues: DealNote = {
    noteId: 0,
    note: '',
    isUpdated: false,
    dateCreated: '',
  };

  const validation = Yup.object<any>({
    note: Yup.string().required('Required'),
  });

  formikNote = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (task: DealNote) => {
      handleNoteFormSubmission(task);
    },
  });

  function handleNoteFormSubmission(note: DealNote) {

    if (note.noteId == 0 && !note.isUpdated) {
      let noteTimeline: DealTimeline = {
        timelineId: 0,
        timelineType: 'Note',
        subject: '',
        taskTypeId: 0,
        taskTypeName: '',
        assignedUserId: '',
        note: note.note,
        taskOutcomeId: undefined,
        isAlreadyCompleted: true,
        IsArchived: false,
        isUpdated: false,
        assignedUserIdPrev: '',
        date: '',
      };
      timelines.unshift(noteTimeline);
    } else {
      timelines[timelineIndex].note = note.note;
      timelines[timelineIndex].isUpdated = true;
      timelines[timelineIndex].isAlreadyCompleted = true;
    }

    timelineIndex = -1;
    timelines = sortDealTimeline(timelines);
    formik.setFieldValue('dealTimelines', timelines);
    formikNote.resetForm();
  }
  const handleChange = (event: React.SyntheticEvent, tabValue: string) => {
    setValue(tabValue);

    if (tabValue === '1') {
      formikNote.resetForm();
    } else if (tabValue === '2') {
      setDisableTaskForm(false);
    }
  };

  React.useEffect(() => {
    //Set tabs window height based on main page height
    const mediaQuery1 = window.matchMedia('(max-width: 1280px)');
    const mediaQuery2 = window.matchMedia('(max-width: 1080px)');

    let drawerRightWindow = document.getElementById('tabsWindow') as HTMLElement;
    let desiredHeight;
    if (mediaQuery1.matches || mediaQuery2.matches) {
      desiredHeight = drawerRightWindow.scrollHeight;
    } else {
      desiredHeight = document.documentElement.scrollHeight - 220;
    }
    drawerRightWindow.style.height = desiredHeight.toString() + 'px';
  }, []);

  const isDuplicate = (file: any) => {
      console.log("removed attachments: ", removedDealAttachments)
      return (formik.values.dealAttachments?.some(
        (attachment: DealAttachment) => attachment.fileName === file.name
      ) && !removedDealAttachments?.some((attachment: DealAttachment) => attachment.fileName === file.name)) ||
      filesToUpload?.some(
        (attachment: DealAttachment) => attachment.fileName === file.name
      )
  };

  const [isUploading, setIsUploading] = React.useState(false);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  // const [uploadingProgress, setUploadingProgress] = React.useState(0);
  const fileNameLimitMsg = 'File(s) name must not exceed 50 characters.'
  const fileSizeLimitMsg = 'File(s) over 10MB, Please resize.'
  const duplicateFileMsg = 'File(s) with the same name already exists. Please choose a different file name.'
  const fileSizeLimit = 10485760;

    const dispatch = useAppDispatch();
    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isTaskPermission = 0;

    if (!userPermissions) {
        userPermissions = [];
        isTaskPermission = 2;
    }
    if (isTaskPermission == 0) {
        if (userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks')) {
            isTaskPermission = 1;
        }
    }

  const appendError = ((file: any, errorsList: string[]) => {
    const fileNameWithoutExtension = String(file.name).replace(/\.[^.]+$/, '');
    if (fileNameWithoutExtension.length > 50 && !errorsList.includes(fileNameLimitMsg)) {
          errorsList.push(fileNameLimitMsg)
      } 
      if (file.size > fileSizeLimit && !errorsList.includes(fileSizeLimitMsg)) { 
          errorsList.push(fileSizeLimitMsg)
      } 
      // if (isDuplicated && !errorsList.includes(duplicateFileMsg)) {
      //     errorsList.push(duplicateFileMsg);
      // }
    return errorsList;
  });

  const errorsListSnackbar = ((errorsList: string[]) => {
    if (errorsList.length > 0) {
      const formattedErrorMsgs = errorsList.map((msg, index) => (
        <React.Fragment key={index}>
          {msg}
          {index < errorsList.length - 1 && <br />}
        </React.Fragment>
      ));
      dispatch(showSnackbar({ type: 'error', message: formattedErrorMsgs }));
    }
  });

  const appendFilesToUpload = ((acceptedFiles: any[]) => {
    let errorsList: string[] = [];

     acceptedFiles.forEach((file) => {
      const dmsAttachmentAttribute: DmsAttachmentAttribute = {
        name: file.fileName,
        size: file.FileSize
      }
      const dealAttachment: DealAttachment = {
        attachmentId: file.id,
        fileName: file.fileName,
        fileURL: file.mediaUrl
      }

      // let isDuplicated = isDuplicate(dmsAttachmentAttribute);
      const fileNameWithoutExtension = String(dmsAttachmentAttribute.name).replace(/\.[^.]+$/, '');

      if (fileNameWithoutExtension.length > 50 || dmsAttachmentAttribute.size > fileSizeLimit) {
        errorsList = appendError(dmsAttachmentAttribute, errorsList);
        }
      else {
        filesToUpload.push(dealAttachment)
        formik.setFieldValue('dealAttachmentsToUpload', filesToUpload);
      }
     });

     if (errorsList.length == 0) {
        dispatch(showSnackbar({ type: 'success', message: 'Upload successful' }));
      } else {
        errorsListSnackbar(errorsList);
     }
  });

  const uploadFiles = ((acceptedFiles: File[]) => {
    let dispatchRequest = false;
    let errorsList: string[] = [];
    const baseUrl = config.get('api.lead');
    const url = `${baseUrl}/lead/deal/uploadAttachmentToBlob/${formik.values.dealId}`;
    const requestForm = new FormData();
    acceptedFiles.forEach((file) => {
      // let isDuplicated = isDuplicate(file);
      const fileNameWithoutExtension = String(file.name).replace(/\.[^.]+$/, '');
      if (fileNameWithoutExtension.length > 50 || file.size > fileSizeLimit) {
        errorsList = appendError(file, errorsList);
        }
      else {
      requestForm.append('files', file);
      dispatchRequest = true;
      }
    });
    errorsListSnackbar(errorsList);

    if (dispatchRequest) {
        setIsUploading(true);
        post(url, requestForm, {
          onUploadProgress: (progressEvent: any) => {
            // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setUploadingProgress(percentCompleted);
          },
        })
          .then((res: AxiosResponse<DealAttachment[]>) => {
            res.data.forEach((file) => {
              filesToUpload.push(file)
            });
            addTimelineNodes(res.data);
            // const updatedList = [...formik.values.dealAttachmentsToUpload, ...res.data];
            formik.setFieldValue('dealAttachmentsToUpload', filesToUpload);
            setIsUploading(false);
            if (errorsList.length == 0) {
            dispatch(showSnackbar({ type: 'success', message: 'Upload successful' }));
            }
          })
          .catch((err) => {
            setIsUploading(false);
            dispatch(showSnackbar({ type: 'error', message: err + ': Unable to upload file' }));
          });
        }
  });

  const addTimelineNodes = (attachments: DealAttachment[]) => {
    removedDealAttachments.forEach((attachment) => {
    timelines = timelines.filter((i: any) => i.fileName !== attachment.fileName)
    })

    attachments.forEach((attachment) => {
    let note: DealTimeline = {
      timelineId: attachment.attachmentId,
      timelineType: 'Attachment',
      subject: '',
      taskTypeId: 0,
      taskTypeName: '',
      assignedUserId: '',
      note: attachment.fileName,
      taskOutcomeId: undefined,
      isAlreadyCompleted: true,
      IsArchived: false,
      isUpdated: false,
      assignedUserIdPrev: '',
      date: '',
      fileName: attachment.fileName,
      fileURL: attachment.fileURL
    };
    timelines.unshift(note); 
    });

    timelines = sortDealTimeline(timelines);
    formik.setFieldValue('dealTimelines', timelines);
  }
  const onDropAccepted = React.useCallback(
    async (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    [dispatch],
  );

  React.useEffect(() => {
      removedDealAttachments.forEach((attachment) => {
        const filteredList = formik.values.dealTimelines.filter((i: any) => i.fileName !== attachment.fileName);
        formik.setFieldValue(
          'dealTimelines', filteredList) 
      })
  }, [removedDealAttachments]);

  const onDropRejected = (error: FileRejection[], event: DropEvent): void => {
      dispatch(showSnackbar({ type: 'error', message: 'File type must be .jpeg, .jpg, .png, .gif, .pdf, .xls, .xlsx' }));      
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!isUploading) {
        // Call onDropAccepted only if isUploading is false
        onDropAccepted(acceptedFiles);
      } else {
        dispatch(showSnackbar({ type: 'error', message: 'File upload is already in progress.' }));      
      }
    },
    onDropRejected,
    accept: '.jpeg,.jpg,.png,.gif,.pdf,.xls,.xlsx'
  });
  
  React.useEffect(() => {
    const listenStorageChange = () => {
      const fileName = dmsTypeId==0 ? localStorage.getItem('IdealFileUpload'):localStorage.getItem('InfinityFileUpload');
      if (fileName === 'CancelAttachment') {
        setIsUploading(false);
      } else if (fileName != null) {
        const response = localStorage.getItem(fileName);
        const addFileResponse: any[] = JSON.parse(response!);
        appendFilesToUpload(addFileResponse);
        localStorage.removeItem(fileName);
        dmsTypeId==0 ? localStorage.removeItem('IdealFileUpload'):localStorage.removeItem('InfinityFileUpload');
      }
    };
    window.addEventListener('storage', listenStorageChange);
    return () => window.removeEventListener('storage', listenStorageChange);
  }, []);

  return (
    <Box>
      {formik.values.dealStateId != DealStatusEnum.Open?
      <>
      <Accordion style={{ backgroundColor: '#ffffff'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{backgroundColor:'#f8f9fa'}}
        >
          <Typography variant="h5">Additional Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ width: '100%' }} style={{ backgroundColor: '#ffffff' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="deal tabs" style={{ fontSize: 'small' }}>
              <Tab label="Note" value="1" />
              {isTaskPermission == 1 ? <Tab label="Task" value="2" />
              : <Tab disabled label="Task" value="2" />
              }
            </TabList>
          </Box>

          <TabPanel value="1" style={{ padding: 0 }}>
            <Grid container>
              <Grid item xs={12}>
                <Editor
                  tagName="dealNoteEditor"
                  apiKey={textEditorApiKey ||''}
                  id="tinyDealNote"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open}
                  value={formikNote.values.note}
                  onEditorChange={(content: any) => {
                    formikNote.setFieldValue('note', content);
                  }}
                  init={editorInit}></Editor>
              </Grid>
              <Grid container style={{ display: 'block', paddingBottom: '2%', paddingTop: '2%' }}>
                <Grid item style={{ float: 'right', paddingRight: '2%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={formik.values.dealStateId != DealStatusEnum.Open}
                    onClick={() => {
                      formikNote.handleSubmit();
                    }}>
                    {formikNote.values.isUpdated ? 'Update' : 'Add'} Note
                  </Button>
                </Grid>
                <Grid item style={{ paddingLeft: '2%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled={formik.values.dealStateId != DealStatusEnum.Open}
                    onClick={() => {
                      formikNote.resetForm();
                    }}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="2" style={{ padding: 10 }}>
            <TaskTab
              formik={formik}
              disableTaskForm={disableTaskForm}
              taskTypes={taskTypes}
              taskOutcomes={taskOutcomes}
              dealerUsers={dealerUsers}
              setDisableTaskForm={setDisableTaskForm}></TaskTab>
          </TabPanel>
        </TabContext>
      </Box>
        </AccordionDetails>
      </Accordion>
      </>:
      <>
      <Box sx={{ width: '100%' }} style={{ backgroundColor: '#ffffff' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="deal tabs" style={{ fontSize: 'small' }}>
              <Tab label="Note" value="1" />
              {isTaskPermission == 1 ? <Tab label="Task" value="2" />
              : <Tab disabled label="Task" value="2" />
              }
              <Tab
                label={
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <AttachFileIcon style={{ fontSize: 18 }} />
                    </Grid>
                    <Grid item>
                      Files
                    </Grid>
                  </Grid>
                }
                value="3"
              />            
              </TabList>
          </Box>

          <TabPanel value="1" style={{ padding: 0 }}>
            <Grid container>
              <Grid item xs={12}>
                <Editor
                  tagName="dealNoteEditor"
                  apiKey={textEditorApiKey ||''}
                  id="tinyDealNote"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open}
                  value={formikNote.values.note}
                  onEditorChange={(content: any) => {
                    formikNote.setFieldValue('note', content);
                  }}
                  init={editorInit}></Editor>
              </Grid>
              <Grid container style={{ display: 'block', paddingBottom: '2%', paddingTop: '2%' }}>
                <Grid item style={{ float: 'right', paddingRight: '2%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={formik.values.dealStateId != DealStatusEnum.Open}
                    onClick={() => {
                      formikNote.handleSubmit();
                    }}>
                    {formikNote.values.isUpdated ? 'Update' : 'Add'} Note
                  </Button>
                </Grid>
                <Grid item style={{ paddingLeft: '2%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disabled={formik.values.dealStateId != DealStatusEnum.Open}
                    onClick={() => {
                      formikNote.resetForm();
                    }}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="2" style={{ padding: 10 }}>
            <TaskTab
              formik={formik}
              disableTaskForm={disableTaskForm}
              taskTypes={taskTypes}
              taskOutcomes={taskOutcomes}
              dealerUsers={dealerUsers}
              setDisableTaskForm={setDisableTaskForm}></TaskTab>
          </TabPanel>
          <TabPanel value="3" 
          style={{ padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          >
            <Box
                {...getRootProps()}
                className={classes.fileUploaderBox}
                style={{cursor: isUploading ? 'not-allowed' : 'pointer'}}
                onClick={(): void => {
                  if (!isUploading) {
                  if (localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL' || localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY') {
                    GetAttachments(fileSizeLimit,'jpeg,jpg,png,gif,pdf,xls,xlsx');
                  } else {
                    open()
                  }
                }
                }}
            >
            <Box
             className={classes.fileUploadStatusBox}
            >
          { isUploading ? (
              <CircularProgress/>
              ) : (
                <CloudUploadIcon
                style={{ fontSize: 80 }} // Adjust the size of the cloud upload icon (e.g., fontSize: 40)
              />
              )}
            </Box>
              <Box
              className={classes.fileUploadTextBox}
              >
              <Typography variant="h6" style={{ userSelect: 'none' }}> Choose files to upload </Typography>
              </Box>
              <Typography variant="h6" style={{ userSelect: 'none' }}>or drag and drop here</Typography>
              <div>
                <input {...getInputProps()} hidden />
              </div>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
      </>}
      
      
      <BasicTimeline formik={formik} setTabValue={setValue} setDisableTaskForm={setDisableTaskForm}></BasicTimeline>
    </Box>
  );
}

let formikTask: any = undefined;
function TaskTab({
  formik,
  disableTaskForm,
  taskTypes,
  taskOutcomes,
  dealerUsers,
  setDisableTaskForm,
}: {
  formik: any;
  disableTaskForm: boolean;
  taskTypes: any[];
  taskOutcomes: any[];
  dealerUsers: any[] | undefined;
  setDisableTaskForm(value: boolean): void;
}) {
  const classes: any = useStyles();
  const currentUserId = useAppSelector((state) => state.user.crmUserId);
  const [openCalender, setOpenCalender] = React.useState<boolean>(false);
  const [taskTime, setTaskTime] = React.useState<ITaskTimes[]>(taskHours);

  let initialValues: DealTask = {
    taskId: 0,
    taskTypeId: '',
    taskTypeName: '',
    assignedUserId: currentUserId ?? undefined,
    subject: '',
    date: '',
    time: '',
    note: '',
    taskOutcomeId: undefined,
    isAlreadyCompleted: false,
    IsArchived: false,
    isUpdated: false,
    assignedUserIdPrev: '',
  };

  const schema = Yup.object<any>({
    subject: Yup.string().required('Required'),
    taskTypeId: Yup.string().required('Required'),
    assignedUserId: Yup.string().required('Required'),
    taskOutcomeId: formikTask?.values?.isAlreadyCompleted ? Yup.string().nullable().required('Required') : undefined,
  });

  formikTask = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (task: DealTask) => {
      handleTaskFormSubmission(task);
    },
  });

  function handleTaskFormSubmission(task: DealTask) {
    task.taskTypeName = taskTypes.find((x) => x.id == task.taskTypeId)?.value;

    if (task.isAlreadyCompleted) {
      task.date = moment.utc().local().format();
      task.time="";
    }
  
    let taskTimeline: DealTimeline = {
      timelineId: task.taskId,
      timelineType: 'Task',
      subject: task.subject,
      taskTypeId: Number(task.taskTypeId),
      taskTypeName: task.taskTypeName,
      assignedUserId: task.assignedUserId,
      note: task.note,
      taskOutcomeId: task.taskOutcomeId,
      isAlreadyCompleted: task.isAlreadyCompleted,
      IsArchived: task.IsArchived,
      isUpdated: task.isUpdated,
      assignedUserIdPrev: task.assignedUserIdPrev,
      date: task?.date && task?.time ? moment.utc(new Date(task.date.split('T')[0] + 'T' + task.time)).format()
                      : task.isAlreadyCompleted ? task?.date : '',
      time: task.time
    };

    if (taskTimeline.timelineId == 0 && !taskTimeline.isUpdated) {
      timelines.unshift(taskTimeline);
    } else {
      timelines[timelineIndex] = taskTimeline;
    }

    timelineIndex = -1;
    timelines = sortDealTimeline(timelines);
    formik.setFieldValue('dealTimelines', timelines);
    formikTask.resetForm();
  }
  React.useEffect(() => {
    const newDate = moment.utc().toDate().toISOString().split('T')[0];
    if (formikTask.values.date.length > 0 && formikTask.values.date < newDate) {
      formikTask.setFieldValue('date', newDate);
    }
  }, [formikTask.values.date]);


  const isDueDate = formikTask.values.date === '' ? true : false;
  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, '0');
      const minutes = currentTime.getMinutes().toString().padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      const currentDate = `${currentTime.getFullYear()}-${(currentTime.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentTime.getDate().toString().padStart(2, '0')}`;
      if (formikTask.values.date.split('T')[0] === currentDate) {
        const time:ITaskTimes[] = taskTime.filter((x:ITaskTimes) => x.value > formattedTime);
        const formikTime = formikTask.values.time.length > 0 && time.find((x:ITaskTimes) => x.value === formikTask.values.time);
        if ((formikTime ?? '') === '') {
          formikTask.setFieldValue('time', '');
        }
        setTaskTime(time);
      } else {
        setTaskTime(taskHours);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [formikTask.values.date, formikTask.values.time]);

  return (
    <form noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            {!disableTaskForm && (
              <Typography>
                {formikTask.values.isUpdated ? 'Done' : 'Already Completed'}
                <Switch
                  name="isAlreadyCompleted"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open}
                  checked={formikTask.values.isAlreadyCompleted}
                  onChange={formikTask.handleChange}
                />
              </Typography>
            )}
          </Box>
        </Grid>
        {(!formikTask.values.isUpdated ||
          (formikTask.values.isUpdated && !formikTask.values.isAlreadyCompleted) ||
          disableTaskForm) && (
          <>
            <Grid item xs={12}>
              <TextField
                className={classes.fieldPadding}
                fullWidth
                name="subject"
                placeholder="Subject"
                variant="outlined"
                size="small"
                disabled={formik.values.dealStateId != DealStatusEnum.Open || disableTaskForm}
                value={formikTask.values.subject}
                onChange={formikTask.handleChange}
                error={!!formikTask.errors.subject}
                helperText={formikTask.errors.subject}
              />
            </Grid>
            <Grid container item xs={12} className={classes.fieldPadding}>
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  name="taskTypeId"
                  placeholder="Task Type"
                  label="Task Type"
                  variant="outlined"
                  size="small"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open || disableTaskForm}
                  value={formikTask.values.taskTypeId}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  onChange={formikTask.handleChange}
                  error={!!formikTask.errors.taskTypeId}
                  helperText={formikTask.errors.taskTypeId}>
                  {taskTypes?.map((options) => {
                    return (
                      <MenuItem key={options.id} value={options.id}>
                        {options.value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  fullWidth
                  name="assignedUserId"
                  placeholder="Assignment"
                  label="Assignment"
                  variant="outlined"
                  size="small"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open || disableTaskForm}
                  value={formikTask.values.assignedUserId}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  onChange={(event: any) => {
                    formikTask.setFieldValue('assignedUserIdPrev', formikTask.values.assignedUserId);
                    formikTask.setFieldValue('assignedUserId', event.target.value);
                  }}
                  error={!!formikTask.errors.assignedUserId}
                  helperText={formikTask.errors.assignedUserId}>
                  {dealerUsers?.map((options) => {
                    return (
                      <MenuItem key={options.crmUserId} value={options.crmUserId}>
                        {options.username}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {!formikTask.values.isAlreadyCompleted ? (
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={classes.fieldPadding}
                    label="Due Date"
                    open={openCalender}
                    onClose={() => setOpenCalender(false)}
                    minDate={new Date()}
                    maxDate={new Date('2099-12-31')}
                    disabled={formik.values.dealStateId != DealStatusEnum.Open}
                    value={formatDatePicker(formikTask.values.date)}
                    onError={() => {
                      console.log('error');
                    }}
                    onChange={(e: any) => {
                        formikTask.setFieldValue(
                          'date',
                          moment.utc(formatDatePicker(e)).toDate().toISOString().split('T')[0],
                          true,
                        );
                        setOpenCalender(false);
                    }}
                    renderInput={(params: any) => {
                      params.error = !!formik.errors.dueDate;
                      return (
                        <TextField
                          margin="dense"
                          name="dueDate"
                          fullWidth
                          {...params}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onClick={() => setOpenCalender(!openCalender)}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  className={classes.fieldPadding}
                  select
                  variant="outlined"
                  size="small"
                  margin="dense"
                  name="dueTime"
                  label="Time"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open || isDueDate}
                  value={formikTask.values.time}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        className: classes.muiPopoverPaper,
                      },
                    },
                  }}
                  onChange={(e: any) => formikTask.setFieldValue('time', e.target.value)}
                  >
                  {taskTime.map((hour:ITaskTimes) => (
                    <MenuItem style={{ overflowY: 'auto', maxHeight: '200px' }} key={hour.value} value={hour.value}>
                      {hour.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ) : (
            <>
              <TextField
                select
                fullWidth
                name="taskOutcomeId"
                placeholder="Outcome"
                label="Outcome"
                variant="outlined"
                size="small"
                disabled={formik.values.dealStateId != DealStatusEnum.Open || disableTaskForm}
                className={classes.fieldPadding}
                value={formikTask.values.taskOutcomeId}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      className: classes.muiPopoverPaper,
                    },
                  },
                }}
                onChange={formikTask.handleChange}
                error={!!formikTask.errors.taskOutcomeId}
                helperText={formikTask.errors.taskOutcomeId}>
                {taskOutcomes?.map((x: any) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextLimitField
            className={classes.fieldPadding}
            fullWidth
            autoFocus
            multiline
            rows={2}
            placeholder="Add note"
            variant="outlined"
            name="note"
            disabled={formik.values.dealStateId != DealStatusEnum.Open || disableTaskForm}
            value={formikTask.values.note}
            onChange={formikTask.handleChange}
            characters={100}
          />
        </Grid>
        <Grid container style={{ display: 'block' }}>
          <Grid item className={classes.floatRight}>
            {!disableTaskForm && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={formik.values.dealStateId != DealStatusEnum.Open}
                onClick={() => {
                  formikTask.handleSubmit();
                }}>
                {formikTask.values.isUpdated ? 'Update' : 'Add'} Task
              </Button>
            )}
          </Grid>
          <Grid item className={disableTaskForm ? classes.floatRight : classes.paddingRight2Percentage}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              disabled={formik.values.dealStateId != DealStatusEnum.Open}
              onClick={() => {
                formikTask.resetForm();
                setDisableTaskForm(false);
              }}>
              {!disableTaskForm ? 'Clear' : 'Cancel'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

let timelineIndex = -1;

function BasicTimeline({
  formik,
  setTabValue,
  setDisableTaskForm,
}: {
  formik: any;
  setTabValue: any;
  setDisableTaskForm(value: boolean): void;
}) {
  const classes: any = useStyles();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>('');
  const [selectedPdf, setSelectedPdf] = React.useState<string | null>(null);
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined);

  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  let isTaskPermission = 0;
  
  if (!userPermissions) {
    userPermissions = [];
    isTaskPermission = 2;
  }
  
  if (isTaskPermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks')) {
        isTaskPermission = 1;
    }
  }

  const openPdf = (isOpen: boolean, url: any, fileName: any) => {
    setOpenModal(isOpen);
    setSelectedPdf(url);
    setFileName(fileName);
}

  function setEditTaskForm(index: number, disableTaskForm: boolean) {
    setTabValue('2');
    setDisableTaskForm(disableTaskForm);

    setTimeout(() => {
      timelineIndex = index;
      const dealTasks = [...formik.values.dealTimelines] as DealTimeline[];
      let dueDatee, time;
      if(dealTasks[timelineIndex].date){
        dueDatee = moment.utc(dealTasks[timelineIndex].date).local().format().substring(0, 19);
        time=(dueDatee.split('T')[1]).slice(0,5)
      }else{
        dueDatee="";
        time="";
      }
      
      formikTask.setValues({
        taskId: dealTasks[timelineIndex].timelineId,
        taskTypeId: dealTasks[timelineIndex].taskTypeId,
        taskTypeName: dealTasks[timelineIndex].taskTypeName,
        assignedUserId: dealTasks[timelineIndex].assignedUserId,
        subject: dealTasks[timelineIndex].subject,
        date: dueDatee,
        time: time,
        note: dealTasks[timelineIndex].note,
        taskOutcomeId: dealTasks[timelineIndex].taskOutcomeId,
        isAlreadyCompleted: true,
        IsArchived: dealTasks[timelineIndex].IsArchived,
        isUpdated: true,
      });

      formikTask.setFieldValue('isAlreadyCompleted', dealTasks[timelineIndex].isAlreadyCompleted);
    }, 0);

    //Move the scroll to top
    let drawerRightWindow = document.getElementById('tabsWindow') as HTMLElement;
    drawerRightWindow.scrollTop = 0;
  }

  function setEditNoteForm(index: number) {
    setTabValue('1');

    setTimeout(() => {
      timelineIndex = index;
      const notes = [...formik.values.dealTimelines] as DealTimeline[];
      
      formikNote.setValues({
        noteId: notes[timelineIndex].timelineId,
        note: notes[timelineIndex].note,
        dateCreated: notes[timelineIndex]?.date ,
        isUpdated: true,
      });
    }, 0);

    //Move the scroll to top
    let drawerRightWindow = document.getElementById('tabsWindow') as HTMLElement;
    drawerRightWindow.scrollTop = 0;
  }
  return (
    <Box className={classes.dealHistoryBox}>
      <ImageModal open={imageSrc !== undefined} imageSrc={imageSrc} onClose={(): void => setImageSrc(undefined)} />
      <Timeline className={classes.dealTimeline}>
        {formik.values.dealTimelines.map((timeline: DealTimeline, index: number) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent style={{ flex: 0 }} />
            <TimelineSeparator>
              <TimelineDot color="primary">
                {timeline.timelineType == 'Task' ? <AssignmentIcon fontSize="small" /> : timeline.timelineType == 'Note'? <NoteIcon fontSize="small" /> : <AttachmentIcon style={{ transform: 'rotate(270deg)' }}/>}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
            <div onClick={() => {
                if (isTaskPermission == 1 && timeline.timelineType == 'Task') {
                  setEditTaskForm(index, timeline.isAlreadyCompleted);
                } else if (timeline.timelineType == 'Note') {
                  setEditNoteForm(index);
                } else if (timeline.timelineType == 'Attachment') {
                  const extension = getFileExtension(timeline.fileURL? timeline.fileURL : null)
                  if(extension == 'xlsx' || extension == 'xls'){
                    const link = document.createElement('a');
                    link.href = timeline.fileURL!;
                    link.download = timeline.fileName!;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                  else if (extension != 'pdf') {
                    console.log('image opened: ', timeline.fileURL)
                    setImageSrc(timeline.fileURL)
                    }
                    else {
                      openPdf(true, timeline.fileURL, timeline.fileName)
                    }
                  }
                }
              } style={{ cursor: isTaskPermission == 0 && timeline.timelineType == 'Task' ? "default" : "pointer" }}>
                        {timeline.timelineType == 'Task' ? (
                <Box className={classes.dealHistoryChatBox}>
                  <Typography variant="h6">
                    {timeline.subject}{' '}
                    <Chip
                      label={timeline.isAlreadyCompleted ? 'Completed' : 'Scheduled'}
                      className={timeline.isAlreadyCompleted ? classes.taskCompletedChip : classes.taskScheduledChip}
                    />
                  </Typography>
                  <Typography style={{ fontSize: 'small' }}>
                    {timeline.taskTypeName} {timeline.note ? '- ' + timeline.note : ''}
                  </Typography>
                  <Typography style={{ fontSize: 'x-small' }}>
                  {!timeline.isAlreadyCompleted ? 'Due Date: ' : 'Completed: '}
                    { timeline.isAlreadyCompleted
                      ? moment.utc(timeline.date ? timeline.date : Date()).local().format('MMMM Do YYYY, h:mm a')
                      : timeline?.date
                      ? moment.utc(timeline.date).local().format('MMMM Do YYYY, h:mm a')
                      : 'N/A'}
                  </Typography>
                </Box>
              ) : timeline.timelineType == 'Note'? (
                <Box className={classes.dealHistoryChatBox} style={{ height: '55px' }}>
                  <Typography
                    className={classes.dealHistoryNote}
                    dangerouslySetInnerHTML={{ __html: timeline.fileName? 'Please see the attachment titled - ' + timeline.fileName : timeline.note }}></Typography>
                  <Typography style={{ fontSize: 'x-small' }}>
                    Created Date:{' '}
                    {moment
                      .utc(timeline.date ? timeline.date : Date())
                      .local(timeline.timelineId == 0 ? true : false)
                      .format('MMMM Do YYYY, h:mm a')}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.dealHistoryChatBox}>
                  <Typography variant="h6">
                    {'File Uploaded - '}
                    <a href={timeline.fileURL} target="_blank" rel="noopener noreferrer">
                      {timeline.fileName}
                    </a>
                  </Typography>
                  {/* <Typography style={{ fontSize: 'small' }}>
                    {timeline.taskTypeName} {timeline.note ? '- ' + timeline.note : ''}
                  </Typography> */}
                  <Typography style={{ fontSize: 'x-small' }}>
                  {moment
                      .utc(timeline.date ? timeline.date : Date())
                      .local(timeline.timelineId == 0 ? true : false)
                      .format('MMMM Do YYYY, h:mm a')}
                  </Typography>
                </Box>
              )
              }
            </div>

            </TimelineContent>
          </TimelineItem>
        ))}
        <TimelineItem>
          <TimelineOppositeContent style={{ flex: 0 }} />
          <TimelineSeparator>
            <TimelineDot color="primary">
              <FilterListIcon fontSize="small" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.timelineDealCreated}>
              <Typography variant="h6">Deal created</Typography>
              <Typography style={{ fontSize: 'small' }}>
                {moment.utc(formik.values.createdDate).local().format('MMMM Do YYYY, h:mm a')} -{' '}
                {formik.values.dealType}
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>  
    {selectedPdf && (
      <PdfPreviewModal 
      openModal = {openModal}
      openPdf = {openPdf}
      fileName= {fileName}
      selectedPdf={selectedPdf}
      />
      )}
      
    </Box>
  );
}

export default DealEditDrawerTabs;
