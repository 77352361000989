import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';


import InputLabel from '@mui/material/InputLabel';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { store } from 'app';
import CustomerGroupAPI from 'components/customer-groups/api/customer-group-api';
import CheckIcon from '@material-ui/icons/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Checkbox, Drawer, Fab, Grid, Container, IconButton, Link, makeStyles, Popover, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useTheme, Paper } from "@material-ui/core";
import Button from '@mui/material/Button';
import React, { useEffect } from "react";
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sanitizeString } from '../../common/utils/inventory-sanitizeSearch'
import config from 'config';

const useStyles1 = makeStyles((theme) => ({
    myTable: {
        '& th': {
        },
        '& td': {
            borderBottom: "1px solid #EAEDED",
            // whiteSpace: "nowrap",
            height: "25px"
        },
        '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
    },

    selectedRow: {
        backgroundColor: "#EAF2F8",

    },
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    mySelect: {
        fontSize: "small",
        borderBottom: "1px solid #EAEDED"
    },
    myInput: {
        border: "1px solid #BDC3C7",
        padding: "4px 7px",
        width: "100%",
        '&:focus': {
            border: "1px solid #5DADE2",
            outline: "none",
            color: "#5DADE2",
        },
    },
}));

const headCells = [
    { id: 'LineName', numeric: false, disablePadding: false, label: 'Line', showMore: false },
    { id: 'Model', numeric: false, disablePadding: false, label: 'Model', showMore: false },
    { id: 'ModelDescription', numeric: false, disablePadding: false, label: 'Model Description', showMore: false },
    { id: 'SerialNumber', numeric: false, disablePadding: false, label: 'Serial Number', showMore: false },
    { id: 'StockNumber', numeric: false, disablePadding: false, label: 'Stock#', showMore: false },
    { id: 'Age', numeric: true, disablePadding: false, label: 'Age', showMore: false },
    { id: 'Used', numeric: false, disablePadding: false, label: 'Condition', showMore: false },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status', showMore: false },
    // { id: 'SuggestedRetailPrice', numeric: true, disablePadding: false, label: 'MSRP', showMore: false },
    { id: 'Price', numeric: true, disablePadding: true, label: 'Price', showMore: true },
    { id: 'DealerName', numeric: false, disablePadding: false, label: 'Location', disableSortBy: 'true', sortable: 'false', showMore: false },
];

let mySearchTimeOut: any;
let lastPriceFilterMessage = '';
let lastPLSearch = '';
function InventoryWG() {
    const [dealerId, SetDealerId] = React.useState(-1);
    const classes = useStyles1();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Model');
    const [searchLine, SetSearchLine] = React.useState('');
    const [searchText, SetSearchText] = React.useState('');
    const [minAmount, SetMinAmount] = React.useState(0.00);
    const [maxAmount, SetMaxAmount] = React.useState(0.00);
    // const [dmsDealerId, SetDmsDealerId] = React.useState(store.getState().user.dmsDealerId);

    const [data, SetData] = React.useState([] as any);
    const [dealerData, SetDealerData] = React.useState([] as any);
    const [totalRows, SetTotalRows] = React.useState(-1);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [page, setPage] = React.useState(0);
    const [openDrawer, SetOpenDrawer] = React.useState<any>(false);
    const [openLocatorDrawer, SetOpenLocatorDrawer] = React.useState<any>(false);
    const [advSearch, SetAdvSearch] = React.useState<any>(false);
    const [model, setModel] = React.useState('');
    const [modelDescription, setModelDescription] = React.useState('');
    const [stockNumber, SetStockNumber] = React.useState('');
    const [serialNumber, SetSerialNumber] = React.useState('');
    const [lineCode, setLineCode] = React.useState('');
    const [wgStatus, SetWGStatus] = React.useState(4);
    const [condition, SetCondition] = React.useState(-1);
    const [line, SetLine] = React.useState(-1);
    const [LocationId, SetLocationId] = React.useState(-1);
    const [companyId, SetCompanyId] = React.useState(store.getState().user.dealerId);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorModelHelper, SetAnchorModelHelper] = React.useState(null);
    const [anchorElPriceFilter, SetAnchorElPriceFilter] = React.useState(null);
    const [openLineSearch, SetOpenLineSearch] = React.useState<any>(false);
    const [openPriceFilter, SetOpenPriceFilter] = React.useState<any>(false);
    const [openModelHelper, SetOpenModelHelper] = React.useState<any>(false);
    const [selectedLine, SetSelectedLine] = React.useState({ Code: "-1" } as any);
    const [refreshTag, SetRefreshTag] = React.useState('');
    const [showWorking, SetShowWorking] = React.useState(false);
    const [showFilter, SetshowFilter] = React.useState(true);
    const [dealerLocations, SetDealerLocations] = React.useState<any>([]);
    const [plCount, setPLCount] = React.useState(0);
    const [plOptIn, setPlOptIn] = React.useState<any>(false);
    const dealerUrl = config.get('api.dealerExternal');
    const env = config.get('env');

    // useEffect(() => {
    //     let id=store.getState().user.dmsDealerId;
    //     if(store.getState().user.dmsTypeId==0) id= parseInt('1' + store.getState().user.dmsDealerId.toString() + '001') ;
    //     SetDmsDealerId(id);
    //     let urlPartlocator=`https://locator.csidealer.com/?dealer=${id}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}`;
    //     console.log('urlPartlocator: ' + urlPartlocator);

    // }, []);
    useEffect(() => {
        GetData(-1);

    }, [wgStatus, line, LocationId, condition, refreshTag, rowsPerPage]);

    useEffect(() => {
        
        if (dealerId == -1) {
            let search = window.location.search.toLowerCase();
            let params = new URLSearchParams(search);
            let _dealerId = params.get('dealerid');
            if (_dealerId == null) _dealerId = store.getState().user.dealerId.toString();
            SetDealerId(Number(_dealerId));
            return;
        }
        GetDealerData();
        //if (LocationId == -99) { SetLocationId(dealerId) }
    }, [dealerId]);

    useEffect(() => {
        GetData(totalRows);
    }, [page, order, orderBy]);

    useEffect(() => {
        GetPLOptIn();
    }, []);

    function GetPLOptIn(){
        //let url=`http://localhost:7071/api/PLOptIn/${store.getState().user.dealerId.toString()}`;
        let url=`https://azurefunctionkg.azurewebsites.net/api/PLOptIn/${store.getState().user.dealerId.toString()}?code=EGOORJsYCZQf22qUU1fQfWi/EiAXK61o2kXuvjAAGox5qRamMQ1fTw==`;
        fetch(url)
        .then(response => response.json())
        .then(data =>
        (
            setPlOptIn(data[0].OptInPL)
        )

        ).catch(
            error => 
            setPlOptIn(false)
        );
    }

    

    function GetCompanyData() {
        let data_filter = dealerLocations.filter((x: any) => x.DealerId === x.CompanyDealerId);
        return data_filter;
    }

    function GetLocationData() {
        if (typeof (dealerLocations) == 'undefined') { return ''; }
        let data_filter = dealerLocations.filter((x: any) => x.CompanyDealerId === companyId);
        return data_filter;
    }

    function GetDmsDealerId() {
        // alert(store.getState().user.dmsDealerId);
        // alert(store.getState().user.dealerId);
        let id = store.getState().user.dmsDealerId;
        if (store.getState().user.dmsTypeId == 0) id = parseInt('1' + store.getState().user.dmsDealerId.toString() + '001');
        return id;
    }


    function GetDealerData(dealerId: number = 0) {
        if (dealerId == 0) dealerId = store.getState().user.dealerId;
        GetDealerLocations();

        //let url = "https://azurefunctionkg.azurewebsites.net/api/GetDealerInfo/" + dealerId + "?code=8e7e0AOUnSE/s1UxPxyOCZHN0nrOh81eZAOdvEBvsCf9bj1efIldMQ=="       
        let url = dealerUrl + "/dealers/inventory/" + dealerId
       fetch(url)
            .then(response => response.json())
            .then(data =>
            (             
               SetDealerData(data.DealerData[0])                       
            )

            ).catch(
                error => alert('Error DealerInfo: ' + error)
            );
    }

    function PLSearchCount() {
        if (lastPLSearch == searchText) return;
        if (searchText == '') {
            lastPLSearch = '';
            setPLCount(0);
            return;
        }
        //let  url=`https://equipmentlocatorfunctionsprod.azurewebsites.net/api/CheckModel/12072/1/ms260/0?code=EdIvSIpgiTrjrKbmEeuLVW/m7aRsOqdxCt999SURsbaHAEKSRP/uXg==`;
        //let url=`https://equipmentlocatorfunctionsprod.azurewebsites.net/api/EquipmentLocator/10469/ms260/---ALL---/0/Any/1/false/Both?code=DD2Xt0Pz4cf9b8mEM1Lh5z//aySx1ke2dZMc7zIABNbR6Oiw4AU/9g==`;
        let url = `https://equipmentlocatorfunctionsprod.azurewebsites.net/api/EquipmentLocator/${store.getState().user.dealerId}/${searchText}/---ALL---/0/Any/1/false/Both?code=DD2Xt0Pz4cf9b8mEM1Lh5z//aySx1ke2dZMc7zIABNbR6Oiw4AU/9g==`;
        lastPLSearch = searchText;
        fetch(url)
            .then(response => response.json())
            .then(data =>
            (
                setPLCount(data.length)
            )
            ).catch(
                error =>
                    //alert('Error DealerInfo: ' + error
                    setPLCount(0)
            );
    }

    function ApplyChanges() {
        clearTimeout(mySearchTimeOut);
        mySearchTimeOut = setTimeout(SearchData, 1000);
        setPage(0);
    }

    async function GetDealerLocations() {
        const data: any = await CustomerGroupAPI.GetDealerLocations();
        if (typeof (data) == 'undefined') { return ''; }
        //alert(data);
        SetDealerLocations(data);
        console.log(data);
    }

    function SearchData() {
        SetRefreshTag(new Date().toString());
    }

    function GetData(totalRows: any) {
        if (openDrawer == true && totalRows > -99) { return }
        if (LocationId == -99) { return }
        SetShowWorking(true);
        // alert(companyId);
        // alert(LocationId);
        let _totalRows = totalRows;
        if (_totalRows < -1) _totalRows = -1;
        let obj: any = new Object();
        // obj.dealerId = dealerId;
        obj.dealerId = companyId;
        obj.lineCode = lineCode;
        obj.model = model;
        obj.stockNumber = stockNumber;
        obj.modelDescription = modelDescription;
        obj.condition = condition;
        obj.serialNumber = serialNumber;
        obj.rowsPerPage = rowsPerPage;
        obj.pageNumber = page;
        obj.count = _totalRows;
        obj.order = order;
        obj.orderBy = orderBy;
        obj.line = line;
        obj.LocationId = LocationId;
        obj.wgStatus = wgStatus;
        obj.minAmount = minAmount;
        obj.maxAmount = maxAmount;       
        if (env === 'staging') {
            obj.Env = "stage";
        }       

        if (searchText == 'advancesearch' || searchText == 'advsearch') {
            SetAdvSearch(true);
        }
        else {
            SetAdvSearch(false);
            obj.searchText = searchText;
        }


        let jsonString = JSON.stringify(obj);
        //alert(jsonString);
        let url = 'https://azurefunctionkg.azurewebsites.net/api/GetInventoryWG/' + escape(jsonString) + '?code=8e7e0AOUnSE/s1UxPxyOCZHN0nrOh81eZAOdvEBvsCf9bj1efIldMQ==';
        //let url='http://localhost:7071/api/GetInventoryWG/'+ jsonString;
        //console.log('url: ')
        fetch(url)
            .then(response => response.json())
            .then(data =>
            (
                SetData(data.result.data),
                SetTotalRows(data.count),
                SetshowFilter(false),
                PLSearchCount()

            )

            ).catch(
                error => alert('Error: ' + error)
            );
        SetShowWorking(false);
    }

    function CheckNumber(value: any, xType: string) {
        if (!isNaN(value)) {
            if (xType == "minamount") SetMinAmount(value);
            if (xType == "maxamount") SetMaxAmount(value);
            clearTimeout(mySearchTimeOut);
            mySearchTimeOut = setTimeout(SearchData, 1000);
        }
    }

    function FilterString() {
        if (openPriceFilter) return lastPriceFilterMessage;
        let _minAmount = 0;
        let _maxAmount = 0;
        let response = '';
        if (!isNaN(minAmount)) { _minAmount = minAmount }
        if (!isNaN(maxAmount)) { _maxAmount = maxAmount }
        if (_minAmount != 0 && _maxAmount != 0) {
            response = '[' + _minAmount.toString() + " - " + _maxAmount.toString() + ']';
        }
        if (response == '' && _minAmount != 0) response = ">=" + _minAmount.toString();
        if (response == '' && _maxAmount != 0) response = "<=" + _maxAmount.toString();
        //if(minAmount!='' && parseInt(minAmount)!=0 ){}

        lastPriceFilterMessage = response;
        return response;
    }


    function EnhancedTableHead(props: any) {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        const createSortHandler = (property: any) => (event: any) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow style={{ backgroundColor: "#EAECEE" }}>
                    {headCells.map((headCell) => {
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ padding: "2px 0px 2px 11px", border: "0px solid red" }}
                            >
                                <table style={{ width: "100%", padding: "0px", margin: "0px", border: "0px solid red" }}>
                                    <tr>
                                        <td style={{ width: "100%", border: "0px solid blue" }}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>

                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </td>
                                    </tr>
                                </table>
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function ApplyFilter() {
        GetData(-1);

    }

    function OpenCloseFilter(value: number = 0) {
        SetOpenDrawer(value == 0 ? false : true);
    }


    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (value: any) => {
        setRowsPerPage(value);
        setPage(0);
    };

    function TablePaginationActions(props: any) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (event: any) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event: any) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event: any) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event: any) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const handleSelectAllClick = (event: any) => {
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        //alert('handleRequestSort');
    };

    function OnConditionChage(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetCondition(e.target.value);
    }

    function OnStatusChage(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetWGStatus(e.target.value);
    }
    function OnLineChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetLine(e.target.value);
    }
    function OnLocationChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetLocationId(e.target.value);
    }

    function OnCompanyChange(e: any) {
        if (typeof (e.target.value) == 'undefined') return;
        SetCompanyId(e.target.value);
        SetLocationId(e.target.value);
        GetDealerData(e.target.value);
    }


    function HandleOnKeyUp(e: any) {
        if (e.keyCode == 13) {
            GetData(-1);
        }
    }

    const handlePopoverClose = () => {
        SetOpenLineSearch(false);
        SetOpenPriceFilter(false);
        SetOpenModelHelper(false);
    };

    const handleClick = (event: any) => {
        if (anchorEl == null) { setAnchorEl(event.currentTarget); }
        SetOpenLineSearch(true);

    };

    const handlePriceFilterClick = (event: any) => {
        if (anchorElPriceFilter == null) { SetAnchorElPriceFilter(event.currentTarget); }
        SetOpenPriceFilter(true);
    };

    const handlerModelHelper = (event: any) => {
        if (anchorModelHelper == null) { SetAnchorModelHelper(event.currentTarget); }
        SetOpenModelHelper(true);
    };

    const id = openLineSearch ? 'simple-popover' : undefined;
    function GetLines() {
        return dealerData.Line.filter((x:any)=>x.IsWholegoodManufacturer === true );
    }

    function GetFilterdLines() {
        return GetLines().filter(filterRow);
    }

    function filterRow(element: any, index: any, array: any) {
        if (dealerData.Line.length > 0) {
            let obj1 = dealerData.Line[0];
            let keys = Object.keys(obj1);
            let i = 0;
            for (i = 0; i < keys.length; i++) {
                let cellValue = String(element[keys[i]]);
                if (cellValue.toLowerCase().includes(searchLine.toLowerCase())) { return true; }
            }
        }
        return false;
    }

    function handleRowLineClick(row: any) {
        if (row == null) {
            SetLine(-1);
            SetSelectedLine({ Code: "-1" });
            SetSearchLine("");
        } else {
            SetLine(row.ManufacturerId); SetSelectedLine(row);
        };
        handlePopoverClose();
    }

    const SearchCircularIcon = () => (
        <Box style={{ margin: "0px", padding: "0px" }}>
            {(showWorking) && (<CircularProgress size={15} style={{ marginRight: '10px' }} />)}
            {(!showWorking) && (<SearchIcon color="disabled" style={{ marginRight: '5px' }} />)}
        </Box>
    );

    function GetDealerNickName(row: any) {
        let item = dealerLocations.filter(function (item: any) { return item.DealerId === row.DealerId; });
        if (item != null && item.length > 0) {
            console.log("GetDealerNickName");
            console.log(item);
            console.log(item[0]);
            return item[0].DealerLocationNickName;
        }
        return row.DealerName;
    }

    function GetPLButtonColor(): any {
        if (plCount == 0) return 'primary';
        if (plCount > 0) return 'success';
    }

    return (
        <Box>
            <table>
                <tr>
                    <td>
                        <Box style={{ border: "0px solid green", padding: "0px", position: "relative" }}>
                            {plOptIn && (
                                <div style={{ position: "absolute", right: "-60px", top: "-54px" }} >
                                    <Badge badgeContent={plCount} color="primary">
                                        <Button color={GetPLButtonColor()} variant="contained" onClick={(event) => { SetOpenLocatorDrawer(true) }}>Equipment Locator</Button>
                                    </Badge>
                                </div>
                            )}
                            <Box style={{ border: "0px solid gold" }}>
                                <Grid>
                                    <Grid item >
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            style={{ backgroundColor: 'white' }}
                                            margin="dense"
                                            variant="outlined"
                                            name="searchText"
                                            id="search-text"
                                            placeholder="Search wholegoods"
                                            value={searchText}
                                            InputProps={{
                                                startAdornment: <SearchIcon color="disabled" style={{ marginRight: '5px' }} />,
                                                //startAdornment:<SearchCircularIcon/>,
                                            }}
                                            onChange={(e: any) => { 
                                                const newValue = sanitizeString(e.target.value.toString())
                                                SetSearchText(newValue);
                                                ApplyChanges() 
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <div style={{ position: "absolute", marginTop: "-45px", marginLeft: "5px" }} >
                                {(showWorking) && (<CircularProgress />)}
                            </div>
                            <Box >
                                <Box>
                                    <Paper>
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td>
                                                    <Box style={{ display: "table", border: "0px solid red" }}>
                                                        <Table
                                                            size={'small'}
                                                            className={classes.myTable}
                                                        >
                                                            <EnhancedTableHead
                                                                classes={classes}
                                                                numSelected={0}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onSelectAllClick={handleSelectAllClick}
                                                                onRequestSort={handleRequestSort}
                                                                rowCount={0}
                                                            />
                                                            <TableBody>
                                                                <TableRow style={{ display: `${advSearch ? "" : "none"}` }}>
                                                                    <TableCell>
                                                                        <table style={{ border: "0px solid red" }}>
                                                                            <tr>
                                                                                <td style={{ width: "100%" }} >
                                                                                    {typeof (dealerData.Line) !== 'undefined' && dealerData.Line != null && (
                                                                                        <Select onClick={OnLineChange} value={line} style={{ fontSize: "small" }}
                                                                                        >
                                                                                            <MenuItem className={classes.mySelect} value={-1}>{"All Lines"}</MenuItem>
                                                                                            {GetLines().map((row: any) => {
                                                                                                return (<MenuItem
                                                                                                    className={classes.mySelect}
                                                                                                    // value={row.ManufacturerId}>{row.Name} ({row.Code})</MenuItem>)
                                                                                                    value={row.ManufacturerId}>{row.Name}</MenuItem>)
                                                                                            })}
                                                                                        </Select>

                                                                                    )}

                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Link style={{ cursor: "pointer", padding: "0px", margin: "0px", border: "0px solid blue" }} onClick={handleClick}><FilterAltIcon style={{ color: "silver", padding: "0px", margin: "0px", border: "0px solid green" }}></FilterAltIcon></Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <table cellPadding="0" cellSpacing="0">
                                                                            <tr>
                                                                                <td>
                                                                                    <input className={classes.myInput} type="text" style={{ maxWidth: "110px" }} value={model} onKeyUp={HandleOnKeyUp} onChange={(e: any) => { setModel(e.target.value); ApplyChanges() }} ></input>
                                                                                </td>
                                                                                <td valign="bottom">
                                                                                    <Link id='linkModelHelper' style={{ cursor: "pointer", padding: "0px", margin: "0px", border: "0px solid blue" }} onClick={handlerModelHelper}><HelpIcon color="primary" fontSize="small" style={{ fontSize: "15px" }}  ></HelpIcon></Link>
                                                                                </td>

                                                                            </tr>

                                                                        </table>
                                                                    </TableCell>
                                                                    <TableCell><input className={classes.myInput} type="text" style={{ maxWidth: "200px" }} value={modelDescription} onChange={(e: any) => { setModelDescription(e.target.value); ApplyChanges() }} ></input></TableCell>
                                                                    {<TableCell><input className={classes.myInput} type="text" style={{ maxWidth: "100px" }} value={serialNumber} onChange={(e: any) => { SetSerialNumber(e.target.value); ApplyChanges() }} ></input></TableCell>}
                                                                    <TableCell><input className={classes.myInput} type="text" style={{ maxWidth: "100px" }} value={stockNumber} onChange={(e: any) => { SetStockNumber(e.target.value); ApplyChanges() }} ></input></TableCell>
                                                                    <TableCell></TableCell>
                                                                    <TableCell align="center">
                                                                        {typeof (dealerData.WGStatus) !== 'undefined' && dealerData.WGStatus != null && (
                                                                            <Select onClick={OnConditionChage} value={condition} style={{ fontSize: "small" }}>
                                                                                <MenuItem className={classes.mySelect} value={-1}>{"All"}</MenuItem>
                                                                                <MenuItem className={classes.mySelect} value={1}>{"Used"}</MenuItem>
                                                                                <MenuItem className={classes.mySelect} value={2}>{"New"}</MenuItem>
                                                                            </Select>
                                                                        )}

                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {typeof (dealerData.WGStatus) !== 'undefined' && dealerData.WGStatus != null && (
                                                                            <Select onClick={OnStatusChage} value={wgStatus} style={{ fontSize: "small" }}>
                                                                                <MenuItem className={classes.mySelect} value={-1}>{"All Status"}</MenuItem>
                                                                                {dealerData.WGStatus.map((row: any) => {
                                                                                    return (<MenuItem className={classes.mySelect} value={row.StatusId}>{row.Name}</MenuItem>)
                                                                                })}


                                                                            </Select>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <table style={{ border: "0px solid red", width: "100%", paddingRight: "0px" }} >
                                                                            <tr>
                                                                                <td style={{ textAlign: "right", width: "100%" }}>
                                                                                    &nbsp;{FilterString()}
                                                                                </td>
                                                                                <td style={{ border: "0px solid green", minWidth: "5px" }}>
                                                                                    <div style={{ position: "relative" }}>
                                                                                        <Link id="linkPriceFilter" style={{ position: "absolute", cursor: "pointer", padding: "0px", marginTop: "-10px", marginRight: "-20px", right: "3px", border: "0px solid blue" }} onClick={handlePriceFilterClick}><FilterAltIcon style={{ color: "silver", padding: "0px", margin: "0px", border: "0px solid green" }}></FilterAltIcon></Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {typeof (dealerData.Location) !== 'undefined' && dealerData.Location != null && (
                                                                            <Select onClick={OnLocationChange} value={LocationId} style={{ fontSize: "small" }} >
                                                                                <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                                                                                {dealerLocations.map((row: any) => {
                                                                                    return (<MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>)
                                                                                })}

                                                                            </Select>
                                                                        )}


                                                                    </TableCell>

                                                                </TableRow>
                                                                {data.map((row: any) => {
                                                                    let year = row.year;
                                                                    if (year == 0) year = '';
                                                                    let Line = row.LineName;
                                                                    let age = row.Age;
                                                                    if (age > 30000) age = "";
                                                                    let used = row.Used ? "Used" : "New"
                                                                    let dealernickName = GetDealerNickName(row);
                                                                    return (
                                                                        <TableRow hover>

                                                                            <TableCell>{Line}</TableCell>
                                                                            <TableCell>{row.Model}</TableCell>
                                                                            <TableCell>{row.ModelDescription}</TableCell>
                                                                            <TableCell>{row.SerialNumber}</TableCell>
                                                                            <TableCell>{row.StockNumber}</TableCell>
                                                                            <TableCell align="right">{age}</TableCell>
                                                                            <TableCell align="center">{used}</TableCell>
                                                                            <TableCell>{row.Status}</TableCell>
                                                                            {/* <TableCell align="right">${row.SuggestedRetailPrice.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell> */}
                                                                            <TableCell align="right">${row.Price.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                                                                            <TableCell>{dealernickName}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                        <TablePagination
                                                            size="small"
                                                            component="div"
                                                            rowsPerPageOptions={[10, 15, 20, 25, 30]}
                                                            count={totalRows}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={(e) => { handleChangeRowsPerPage(e.target.value) }}


                                                        />

                                                    </Box>
                                                </td>
                                            </tr>
                                        </table>
                                    </Paper>
                                </Box>

                                <Popover
                                    id={"123"}
                                    open={openLineSearch}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Typography style={{}}>
                                        <input autoFocus className={classes.myInput} placeholder="Search..." type="text" style={{ margin: "5px", maxWidth: "150px" }} value={searchLine} onKeyUp={HandleOnKeyUp} onChange={(e: any) => { SetSearchLine(e.target.value) }} ></input>
                                        <div style={{ display: "block", maxHeight: "400px", overflowY: "auto" }}>
                                            <Table size={'small'}>
                                                {typeof (dealerData.Line) !== 'undefined' && dealerData.Line != null && (
                                                    <TableBody
                                                    >
                                                        <TableRow hover style={{ cursor: "pointer" }} onClick={(event) => handleRowLineClick(null)}>
                                                            <TableCell>All Lines </TableCell>
                                                        </TableRow>

                                                        {GetFilterdLines().map((row: any) => {
                                                            let selected = selectedLine.Code == row.Code;
                                                            console.log("selected:" + selected);
                                                            return (
                                                                <TableRow className={selected ? classes.selectedRow : ""} hover style={{ cursor: "pointer" }} onClick={(event) => handleRowLineClick(row)}>
                                                                    <TableCell>{row.Name}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}

                                                    </TableBody>
                                                )}
                                            </Table>
                                        </div>
                                    </Typography>
                                </Popover>

                                <Popover
                                    id={"pricefilter"}
                                    open={openPriceFilter}
                                    anchorEl={anchorElPriceFilter}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Typography style={{}}>
                                        <div style={{ padding: "5px 10px" }}>
                                            <fieldset style={{ fontSize: "small", border: "1px solid silver" }}>
                                                <legend>Range:</legend>
                                                <table style={{ fontSize: "small" }}>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <input autoFocus className={classes.myInput} placeholder="" type="text" style={{ width: "70px", textAlign: "right" }} value={minAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'minamount') }} ></input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                            <input className={classes.myInput} placeholder="" type="text" style={{ width: "70px", textAlign: "right" }} value={maxAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'maxamount') }} ></input>
                                                        </td>
                                                    </tr>

                                                </table>
                                            </fieldset>
                                        </div>
                                    </Typography>
                                </Popover>
                                <Popover
                                    id={"modelHelper"}
                                    open={openModelHelper}
                                    anchorEl={anchorModelHelper}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Typography style={{}}
                                        onMouseEnter={handlerModelHelper}
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        <div style={{ padding: "5px 10px", fontSize: "small" }}>
                                            *607 = Model number ending with 607<br></br>
                                            607* = Model number starting with 607<br></br>
                                            607 = Model number contains 607
                                        </div>
                                    </Typography>
                                </Popover>

                            </Box>


                        </Box>
                    </td>
                    <td valign="top">
                        <Fab disabled={showFilter} color="primary" onClick={() => OpenCloseFilter(1)} style={{ marginLeft: "10px", marginTop: "10px" }} title='Filter data'>
                            <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                                <FilterAltIcon />
                                <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                            </Box>
                        </Fab>
                    </td>
                </tr>
            </table>
            <Drawer
                PaperProps={{ style: { width: 'auto', minWidth: "25%" } }}
                anchor="right"
                open={openDrawer}
                onClose={() => OpenCloseFilter(0)}
                variant={'temporary'}
            >
                <DrawerToolbar title="Wholegoods Filter" onClose={() => OpenCloseFilter(0)} />
                <Box style={{ padding: "5px 20px" }}>
                    <table cellPadding={0} cellSpacing={0} style={{ border: "0px solid red", width: "100%" }}>
                        <tr style={{ display: GetCompanyData().length == 1 ? "none" : "" }}>
                            <td colSpan={1} >
                                Company&nbsp;&nbsp;

                            </td>
                            <td style={{ width: "100%" }}>
                                <TextField
                                    fullWidth
                                    select
                                    type="select"
                                    id="dealerCompanyId"
                                    name="dealerCompanyId"
                                    margin="dense"
                                    value={ companyId}
                                    onChange={OnCompanyChange}
                                    variant="outlined">
                                    {
                                        GetCompanyData().map((row: any) => (
                                            <MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </td>


                        </tr>
                        <tr>
                            <td>
                                Location
                            </td>

                            <td>
                                {typeof (dealerData.Location) !== 'undefined' && dealerData.Location != null && (
                                    // <FormControl variant="filled" fullWidth style={{ border: "1px solid silver", padding: "10px" }}>
                                    //     {/* <InputLabel id="demo-simple-select-label">Location</InputLabel> */}
                                    //     <Select
                                    //         //                                 labelId="demo-simple-select-label"
                                    //         // id="demo-simple-select"
                                    //         // label="Location"
                                    //         onClick={OnLocationChange} value={LocationId} style={{ fontSize: "small" }} >
                                    //         <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                                    //         {/* {dealerLocations.map((row: any) => { */}
                                    //         {GetLocationData().map((row: any) => {
                                    //             return (<MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>)
                                    //         })}

                                    //     </Select>
                                    // </FormControl>


                                    <TextField
                                        fullWidth
                                        select
                                        type="select"
                                        id="dealerLocationId"
                                        name="dealerLocationId"
                                        margin="dense"
                                        value={dealerLocations.length == 1 ? dealerLocations[0].DealerId :LocationId}
                                        onChange={OnLocationChange}
                                        variant="outlined"
                                        style={{ marginTop: "0px" }}
                                       disabled={GetLocationData().length == 1 ? true : false}
                                    >
                                        <MenuItem className={classes.mySelect} value={-1}>{"All Locations"}</MenuItem>
                                        {

                                            GetLocationData().map((row: any) => (
                                                <MenuItem className={classes.mySelect} value={row.DealerId}>{row.DealerLocationNickName}</MenuItem>
                                            ))
                                        }
                                    </TextField>

                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Line
                            </td>
                            <td>
                                {typeof (dealerData.Line) !== 'undefined' && dealerData.Line != null && (
                                    // <Select onClick={OnLineChange} value={line} style={{ fontSize: "medium" }}
                                    // >
                                    //     
                                    //     {GetLines().map((row: any) => {
                                    //         return (<MenuItem
                                    //             className={classes.mySelect}
                                    //             value={row.ManufacturerId}>{row.Name}</MenuItem>)
                                    //     })}
                                    // </Select>
                                    <TextField
                                        fullWidth
                                        select
                                        type="select"
                                        id="dealerLine"
                                        name="dealerLine"
                                        margin="dense"
                                        value={line}
                                        onChange={OnLineChange}
                                        variant="outlined"
                                        style={{ marginTop: "0px" }}
                                    >
                                        <MenuItem value={-1}>{"All Lines"}</MenuItem>
                                        {
                                            GetLines().map((row: any) => (
                                                <MenuItem className={classes.mySelect} value={row.ManufacturerId}>{row.Name}</MenuItem>
                                            ))
                                        }
                                    </TextField>

                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status
                            </td>
                            <td>
                                {typeof (dealerData.WGStatus) !== 'undefined' && dealerData.WGStatus != null && (
                                    // <Select onClick={OnStatusChage} value={wgStatus} style={{ fontSize: "medium" }}>
                                    //     <MenuItem className={classes.mySelect} value={-1}>{"All Status"}</MenuItem>
                                    //     {dealerData.WGStatus.map((row: any) => {
                                    //         return (<MenuItem className={classes.mySelect} value={row.StatusId}>{row.Name}</MenuItem>)
                                    //     })}
                                    // </Select>
                                    <TextField
                                        fullWidth
                                        select
                                        type="select"
                                        id="dealerLine"
                                        name="dealerLine"
                                        margin="dense"
                                        value={wgStatus}
                                        onChange={OnStatusChage}
                                        variant="outlined"
                                        style={{ marginTop: "0px" }}
                                    >
                                        <MenuItem value={-1}>{"All Status"}</MenuItem>
                                        {
                                            dealerData.WGStatus.map((row: any) => (
                                                <MenuItem value={row.StatusId}>{row.Name}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )}

                            </td>

                        </tr>
                        <tr>
                            <td>Condition</td>
                            <td>
                                {typeof (dealerData.WGStatus) !== 'undefined' && dealerData.WGStatus != null && (
                                    // <Select onClick={OnConditionChage} value={condition} style={{ fontSize: "medium" }}>
                                    //     <MenuItem className={classes.mySelect} value={-1}>{"All"}</MenuItem>
                                    //     <MenuItem className={classes.mySelect} value={1}>{"Used"}</MenuItem>
                                    //     <MenuItem className={classes.mySelect} value={2}>{"New"}</MenuItem>
                                    // </Select>
                                    <TextField
                                        fullWidth
                                        select
                                        type="select"
                                        id="dealerLine"
                                        name="dealerLine"
                                        margin="dense"
                                        value={condition}
                                        onChange={OnConditionChage}
                                        variant="outlined"
                                        style={{ marginTop: "0px" }}
                                    >
                                        <MenuItem className={classes.mySelect} value={-1}>{"All"}</MenuItem>
                                        <MenuItem className={classes.mySelect} value={1}>{"Used"}</MenuItem>
                                        <MenuItem className={classes.mySelect} value={2}>{"New"}</MenuItem>
                                    </TextField>
                                )}

                            </td>
                        </tr>
                        <tr>
                            <td valign='top'>
                                Price Range
                            </td>
                            <td >
                                <Box>
                                    <input className={classes.myInput} placeholder="" type="text" style={{ width: "70px", textAlign: "right" }} value={minAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'minamount') }} ></input>
                                </Box>
                                <Box>
                                    <input className={classes.myInput} placeholder="" type="text" style={{ marginTop: "5px", width: "70px", textAlign: "right" }} value={maxAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'maxamount') }} ></input>
                                </Box>
                                {/* <table style={{ fontSize: "small" }}>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input className={classes.myInput} placeholder="" type="text" style={{ width: "70px", textAlign: "right" }} value={minAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'minamount') }} ></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input className={classes.myInput} placeholder="" type="text" style={{ width: "70px", textAlign: "right" }} value={maxAmount} onChange={(e: any) => { CheckNumber(e.target.value, 'maxamount') }} ></input>
                                        </td>
                                    </tr>

                                </table> */}
                            </td>
                        </tr>

                    </table>
                    <Box>
                        <hr></hr>
                        <Box display="flex" justifyContent="center" alignItems="center" >
                            <Button color="primary" variant="contained" onClick={() => { SetOpenDrawer(false); GetData(-99) }}>Apply Filter</Button>
                        </Box>

                    </Box>
                </Box>
            </Drawer>


            <Drawer
                PaperProps={{ style: { width: '80%', minWidth: "25%" } }}
                anchor="right"
                open={openLocatorDrawer}
                onClose={() => SetOpenLocatorDrawer(false)}
                variant={'temporary'}
            >
                <Box>
                { env === 'staging' ? 
                    <iframe src={`https://equipmentlocatortest.azurewebsites.net/?dealer=${GetDmsDealerId()}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}&istestmachine=true`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe>
                    :
                    <iframe src={`https://equipment-locator.csidealer.com/?dealer=${GetDmsDealerId()}&location=1&dmstype=${store.getState().user.dmsTypeId}&part=${searchText}`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe>
                }                  
                </Box>
            </Drawer>




        </Box>
    )
}
export default InventoryWG;