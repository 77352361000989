import { Drawer } from '@material-ui/core';
import CustomerEditDrawer from 'components/messaging/components/customer-edit-drawer';
import AddCreditsDrawer from 'components/setting/components/account/add-credits-drawer';
import { NewSurveyDrawer } from 'components/survey/components/surveys-page/new-survey-drawer';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { hideDrawer } from 'components/root-drawer/drawer-slice';

const DRAWER_COMPONENTS: { [id: string]: any } = {
  NEW_SURVEY: NewSurveyDrawer,
  CUSTOMER_EDIT: CustomerEditDrawer,
  ADD_CREDITS: AddCreditsDrawer,
};

const RootDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { drawerType, drawerProps } = useAppSelector((state) => state.drawer);

  let open = true;
  const handleOnClose = (): void => {
    dispatch(hideDrawer());
  };

  if (!drawerType) open = false;
  const SpecificDrawer: React.FC = DRAWER_COMPONENTS[drawerType || 'null'];
  return (
    <Drawer
      PaperProps={{ style: { width: '40%' } }}
      anchor="right"
      open={open}
      SlideProps={{ timeout: { enter: 500, exit: 500 } }}
      onClose={handleOnClose}>
      {open && <SpecificDrawer {...drawerProps} />}
    </Drawer>
  );
};

export default RootDrawer;
