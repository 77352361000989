import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import appLocalStorage from 'app/local-storage';
import Status from 'common/models/status';
import authenticationReducer from 'components/authentication/components/authentication-slice';
import customerReducer from 'components/customer/components/Customers/customersSlice';
import taskReducer from 'components/lead/components/task-board/taskboardSlice';
import customerValidationReducer from 'components/customer/components/CustomerValidation/customerValidationSlice';
import notificationReducer from 'components/layouts/DashboardLayout/notificationSlice';
import drawerReducer from 'components/root-drawer/drawer-slice';
import snackbarReducer from 'components/root-snackbar/snackbar-slice';
import publishedSurveyReducer from 'components/survey/components/published-survey/published-survey-slice';
import config from 'config';
import dealerEmailReducer from 'store/dealerEmailSlice';
import dealerPhoneReducer from 'store/dealerPhoneSlice';

const persistedState: any = appLocalStorage.get();
let preloadedState: any = undefined;
if (persistedState) {
  const persistedSystem = {
    ...persistedState.system,
    status: Status.idle,
  };
  preloadedState = {
    user: persistedSystem,
  };
}

const store = configureStore({
  reducer: {
    dealerEmail: dealerEmailReducer,
  user: authenticationReducer,
    dealerPhone: dealerPhoneReducer,
    customers: customerReducer,
    drawer: drawerReducer,
    snackbar: snackbarReducer,
    notification: notificationReducer,
    customerValidation: customerValidationReducer,
    publishedSurvey: publishedSurveyReducer,
    tasks : taskReducer,
  },
  devTools: config.get('env') !== 'production',
  preloadedState,
});


store.subscribe(() => {
  const { user } = store.getState();
  const state = {
    system: user,
  };
  appLocalStorage.save(state);
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export { store };
export type { RootState, AppDispatch, AppThunk };
