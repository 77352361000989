import { IconButton } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

export interface PaginationProps{
    isFetching: boolean;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
  }
  export function TablePaginationActions(props:PaginationProps) {
    const { isFetching, onPageChange, page, count, rowsPerPage } = props;
  
    return (
      <div style={{display:'inline-flex'}} >
        <IconButton
          style={{backgroundColor: 'transparent'}}
          onClick={(event) => onPageChange(event, page - 1)}
          disabled={page === 0 || isFetching}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          style={{backgroundColor: 'transparent'}}
          onClick={(event) => onPageChange(event, page + 1)}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1 || isFetching}
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }
  