import { TextMaskCustomProps } from "components/customer/components/CustomerForm/TextMaskCustomProps";
import MaskedInput from "react-text-mask";

export const PhoneNumberTextMaskCustom = (props: TextMaskCustomProps) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        guide
        keepCharPositions
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  };