import { Box, Checkbox, Chip, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import ArchiveIcon from '@mui/icons-material/Archive';
import FacebookIcon from '@mui/icons-material/Facebook';
import InitialsAvatar from 'components/messaging/components/initials-avatar';
import moment from 'moment';
import { ReactElement } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import _ from 'lodash';
import SendIcon from '@mui/icons-material/Send';
import formatAvatarString from 'common/utils/format-string';
import { useAppSelector } from 'app';
import { getConversationFormattedDateNew } from 'common/utils/format-date';
import GroupIcon from '@material-ui/icons/Group';

export const useConversationLabelStyle = makeStyles((theme: Theme): any => ({
  notificationCounter: {
    width: '25px',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '10px',
    border: '2px solid red',
    height: '25px',
  },
  name: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    marginLeft: '-4px',
  },
  latestMessageText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  conversationLabel: (props: { backgroundColor: string }): any => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
    backgroundColor: props.backgroundColor,
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.02, 1.02, 1)',
      backgroundColor: '#f5f5f5',
    },
  }),
  departmentChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexBasis: '50%',
    marginRight: '5px',
    fontSize: '0.7rem',
  },
  locationChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexBasis: '50%',
    marginRight: '5px',
    fontSize: '0.7rem',
  },
  phoneChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
    flexBasis: '50%',
    fontSize: '0.7rem',
  },
  assignedDepartmentChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
    flexBasis: '50%',
    fontSize: '0.7rem',
  },
  assigneduserChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
    flexBasis: '50%',
    fontSize: '0.7rem',
  },
  unAssignedChip: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: '5px',
    flexBasis: '50%',
    fontSize: '0.7rem',
  },
  markColor: {
    backgroundColor: '#3f51b5c4',
    color: 'white',
  },
  markedChipColor: {
    backgroundColor: '#0027ff',
    color: 'white',
  },
  convLabelText: {
    fontSize: '13px',
  }
}));

interface ConversationLabelProps {
  name: string;
  unreadCount: number;
  latestMessage: string;
  latestDateTime: string | null;
  selected: boolean;
  departmentName: string;
  customerCellPhone: string;
  locationName: string;
  searchText: string;
  onClick(event: any): void;
  checked: boolean;
  onChangeCheckBox(conversationId: number, status: boolean): void;
  conversationId: number;
  assignedDepartmentName: string;
  assignedUsername: string;
  organizationName: string;
  isOrganization: boolean;
  isAspenPrimaryContact: boolean;
  isArchive: boolean;
  IsFacebookConversation: boolean;
  FacebookCustomerId:string;
  FacebookPageId:string;
  archiveTitle: string;
  IsMergeList?: boolean;
}
export function ConversationLabel({
  name,
  unreadCount,
  latestMessage,
  latestDateTime,
  departmentName,
  locationName,
  customerCellPhone,
  searchText,
  onClick,
  selected,
  checked,
  onChangeCheckBox,
  conversationId,
  assignedDepartmentName,
  assignedUsername,
  organizationName,
  isOrganization,
  isAspenPrimaryContact,
  IsFacebookConversation,
  isArchive,
  archiveTitle,
  IsMergeList =false
}: ConversationLabelProps): ReactElement {
  const classes: any = useConversationLabelStyle({ backgroundColor: selected ? '#f5f5f5' : 'white' });
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  const UnreadCountChip = unreadCount > 0 && (
    <Box>
      <Chip size="small" label={unreadCount} color="secondary" />
    </Box>
  );
  const groupName = name?.includes(':') ? name.split(':')[0] : '';
  const personName = name?.includes(':') ? name?.split(':')[1] : name;

  let timeStamp = getConversationFormattedDateNew(latestDateTime||'');
  
  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
    const parts = text ? text.split(regex) : [];
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <mark className={classes.markColor} key={i}>
                {part}
              </mark>
            ) : (
              <span key={i}>{part}</span>
            ),
          )}
      </span>
    );
  };

  const HighlightedChip = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return (
        <></>
        // <Chip
        //   color="primary"
        //   icon={<PhoneIcon />}
        //   size="small"
        //   className={classes.phoneChip}
        //   label={customerCellPhone}
        // />
      );
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regex);
    return (
      <Chip
        color="primary"
        icon={<PhoneIcon />}
        size="small"
        className={classes.phoneChip}
        label={parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <mark className={classes.markedChipColor} key={i}>
                {part}
              </mark>
            ) : (
              <span key={i}>{part}</span>
            ),
          )}
      />
    );
  };
  const handleCheckboxChange = (conversationId: number, status: boolean) => {
    onChangeCheckBox(conversationId, status);
  };
  let filteredName = name ? name.replace(',', ' ').replace("'", ' ') :  '';

  function isJSON(str: string) {
    try {
     if (str?.length > 0 && str?.charAt(0) == '{') {
      JSON.parse(str);
      return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  
  const decideLatestMsg = (latestMessage: string) => {
    if (isJSON(latestMessage)) {
       const parsedJsonObject = JSON.parse(latestMessage);
      return parsedJsonObject.memo? 'Payment: $' + parseInt(parsedJsonObject.amount,10).toFixed(2).toString() : latestMessage; 
    } else {
      return latestMessage; 
    }
  };

const isCheckBoxDisabled = IsMergeList?false:isArchive;
  return (
    <Box className={classes.conversationLabel}>
      {IsFacebookConversation?(<Box display="flex" justifyContent="flex-end" ><FacebookIcon style={{'color':'blue'}}/></Box>):null} 
      {isArchive?(
        <Box display="flex" justifyContent="space-between" width="100%" onClick={onClick}>
          {archiveTitle?(
             <Chip size="small" label={
              searchText ? (
                <Highlighted text={archiveTitle} highlight={searchText} />
              ) : (
                <Typography display="inline" className={classes.convLabelText}>
                  {archiveTitle}
                </Typography>
              )} 
              />):(
              <Box >
                <Typography display="inline" className={classes.convLabelText}>{archiveTitle}</Typography>
              </Box>
              )
          }
          
          <Box>
            <Chip size="small" icon={<ArchiveIcon />} label="Archived" />
          </Box>
        </Box>
      
      ):null} 
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          
          <Checkbox
            size="small"
            checked={checked}
            disabled={isCheckBoxDisabled||IsFacebookConversation}
            onChange={(e: any) => 
              {
                handleCheckboxChange(conversationId, e.target.checked); 
              }}
          />
        </Box>
        <Box>
          <InitialsAvatar
            name={formatAvatarString(filteredName)}
            maxInitials={2}
            textSizeRatio={1.75}
            size="30"
            round
          />
        </Box>
        <Box flexGrow={1} px={1} flexDirection="column" minWidth={0} onClick={onClick}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} className={classes.name}>
              <Grid container>
                {isOrganization &&
                ((dmsTypeId === 0 && !name?.includes(':')) || (dmsTypeId === 9 && isAspenPrimaryContact)) ? (
                  <></>
                ) : (
                  <>
                    <Grid item>
                      <PersonIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="button" className={classes.convLabelText} title={personName?.toUpperCase()?.trim() || 'N/A'}>
                        {searchText && <Highlighted text={personName?.length > 15 ? personName?.slice(0, 15).concat('...') : personName?.trim() || 'N/A'} highlight={searchText} />}
                        {!searchText && (personName?.length > 15 ? personName?.slice(0, 15).concat('...') : personName?.trim() || 'N/A')}
                      </Typography>
                    </Grid>
                  </>
                )}
                {/* Below show Organization Name */}
                {(dmsTypeId === 0 || dmsTypeId === 9) && isOrganization && organizationName && (
                  <>
                    {(dmsTypeId === 0 && !name?.includes(':')) || (dmsTypeId === 9 && isAspenPrimaryContact) ? (
                      <> </>
                    ) : (
                      <Grid item>&nbsp;&nbsp;|&nbsp;&nbsp;</Grid>
                    )}
                    <Grid item>
                      <BusinessIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="button" className={classes.convLabelText} title={organizationName.toUpperCase()}>
                        &nbsp;
                        {searchText && <Highlighted text={organizationName.length > 15 ? organizationName.slice(0, 15).concat('...') : organizationName} highlight={searchText} />}
                        {!searchText && (organizationName.length > 15 ? organizationName.slice(0, 15).concat('...') : organizationName)}
                      </Typography>
                    </Grid>
                  </>
                )}
                {/* Below show Group Name */}
                {(dmsTypeId === 1 || ((dmsTypeId === 0 || dmsTypeId === 9) && !isOrganization)) && !isAspenPrimaryContact && groupName && (
                    <>
                      <Grid item>&nbsp;&nbsp;|&nbsp;&nbsp;</Grid>
                      <Grid item>
                        <GroupIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="button" className={classes.convLabelText} title={groupName.toUpperCase()}>
                          &nbsp;
                          {searchText && <Highlighted text={groupName.length > 15 ? groupName.slice(0, 15).concat('...') : groupName} highlight={searchText} />}
                          {!searchText && (groupName.length > 15 ? groupName.slice(0, 15).concat('...') : groupName)}
                        </Typography>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Box>
            <Box>
              <Typography variant="caption">{timeStamp}</Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box flexGrow={1} className={classes.latestMessageText}>
              {searchText && <Highlighted text={decideLatestMsg(latestMessage)} highlight={searchText} />}
              {!searchText && <Typography display="inline" className={classes.convLabelText}>{decideLatestMsg(latestMessage)}</Typography>}
            </Box>
            <Box>{UnreadCountChip}</Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" pt={1} px={1}>
        {assignedDepartmentName && (
          <Tooltip title={assignedDepartmentName}>
            <Chip
              color="primary"
              icon={<DomainIcon />}
              size="small"
              className={classes.assignedDepartmentChip}
              label={assignedDepartmentName}
            />
          </Tooltip>
        )}
        {!assignedDepartmentName && (
          <Tooltip title={departmentName}>
            <Chip
              color="primary"
              icon={<DomainIcon />}
              size="small"
              className={classes.departmentChip}
              label={departmentName}
            />
          </Tooltip>
        )}

        <Tooltip title={locationName}>
          <Chip
            color="primary"
            icon={<LocationOnIcon />}
            size="small"
            className={classes.locationChip}
            label={locationName}
          />
        </Tooltip>
        <Tooltip title={customerCellPhone}>
          <HighlightedChip text={customerCellPhone} highlight={searchText} />
        </Tooltip>
        {!assignedUsername && (
          <Tooltip title="Unassigned">
            <Chip
              style={{ backgroundColor: '#F0B41B' }}
              color="secondary"
              // icon={<DomainIcon />}
              size="small"
              className={classes.unAssignedChip}
              label="Unassigned"
            />
          </Tooltip>
        )}
        {assignedUsername && (
          <Tooltip title={assignedUsername}>
            <Chip
              color="primary"
              icon={<SendIcon fontSize="small" />}
              size="small"
              className={classes.assigneduserChip}
              label={assignedUsername}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default ConversationLabel;
