import CustomerFileUpload from './CustomerFileUpload';
import Chip from '@mui/material/Chip';
import { Box, Button, FormControl, InputLabel, Link, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { teal } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';

import Paper from '@material-ui/core/Paper';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Pagination } from '@material-ui/lab';
import { useAppDispatch, useAppSelector } from 'app';
import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { SmallCircularProgress } from 'common/components/loaders';
import { CustomerTableDto, PhoneVerificationStatusEnum } from 'common/models/customer';
import { formatDatePicker, formatPhoneNumber } from 'common/utils';
import { showSnackbar } from 'components/root-snackbar';
import { CustomerDto } from 'common/models/customer';
import {
  fetchCustomers,
  fetchCustomersPage,
  fetchSelectedCustomer,
  saveCustomerToggle,
  SetAllSelectedCustomers,
  SetSelectedCustomers,
  showCustomerDrawer
} from '../Customers/customersSlice';
import { slice } from 'lodash';
import EmailLink from 'common/components/buttons/email-link';
import { format } from 'date-fns';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): 1 | -1 | 0 {
  if (orderBy != 'id') {
    if (b[orderBy] < a[orderBy] || (b[orderBy] == null && a[orderBy]) ) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || (a[orderBy] == null && b[orderBy])) {
      return 1;
    }
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | boolean | string | Date | null },
  b: { [key in Key]: number | boolean | string | Date | null },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof CustomerTableDto;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'organizationName',
    numeric: false,
    disablePadding: false,
    label: 'Organization',
  },
  {
    id: 'cellPhone',
    numeric: false,
    disablePadding: false,
    label: 'Cell Phone',
  },
  {
    id: 'emailAddress',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'originationSource',
    numeric: false,
    disablePadding: false,
    label: 'Origination Source',
  },
  // {
  //   id: 'uniqueIdentifier',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Unique Identifier',
  // },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Creation Date',
  },
  {
    id: 'optIn',
    numeric: true,
    disablePadding: false,
    label: 'Opt In',
  },
];

interface EnhancedTableProps {
  classes: any;
  // numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CustomerTableDto) => void;
  // Commented and not removed for future reference
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  // onSelectVisibleClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps): ReactElement {
  const { classes, 
    // Commented and not removed for future reference
    // onSelectAllClick, onSelectVisibleClick, numSelected,
    order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof CustomerTableDto) =>
    (event: React.MouseEvent<unknown>): void => {
      onRequestSort(event, property);
    };
  let tableHeads = [...headCells];
  // Commented and not removed for future reference
  // const AllCustomerSelected = useAppSelector((state) => state.customers.AllCustomersSelected);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  if (dmsTypeId === 1) {
    tableHeads = tableHeads.filter((x) => x.id != 'organizationName');
  }

  return (
    <TableHead style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
      <TableRow>
        {tableHeads.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ whiteSpace: 'nowrap', overflow: 'elipses' }}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> 
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.common.black,
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: teal[500],
          },
    title: {
      flex: '1 1 100%',
      fontWeight: 'bold',
    },
    'Mui-disabled': {
      cursor: 'default',
      'pointer-events': 'none',
      color: 'grey',
    },
  }),
);

interface CustomerTableToolbarProps {
  numSelected: number;
  validPhoneNumbers: number;
}

const CustomerTableToolbar = (props: CustomerTableToolbarProps): ReactElement => {
  const classes: any = useToolbarStyles();
  const { numSelected, validPhoneNumbers } = props;
  const dispatch = useAppDispatch();
// Commented and not removed for future reference

  // const AllCustomerSelected = useAppSelector((state) => state.customers.AllCustomersSelected);

  // const { count } = useAppSelector(
  //   (state) => ({
  //     count: state.customers.totalCustomers,
  //   }),
  //   shallowEqual,
  // );

  // let totalSelected = numSelected;
  // let phoneCount = validPhoneNumbers;
  // if (AllCustomerSelected) {
  //   totalSelected = count;
  //   phoneCount = 1;
  // } else {
  //   phoneCount = 1; // Call API to get all Phone numbers
  // }

  // const handleValidatePhoneNumbers = () => {
  //   dispatch(
  //     showCustomerDrawer({
  //       drawerType: 'CUSTOMER_PHONE_VALIDATE',
  //       drawerProps: {},
  //     }),
  //   );
  // };
  // const onAddCustomerBtn = () => dispatch(showCustomerDrawer({ drawerType: 'CUSTOMER_ADD', drawerProps: {} }));
  return (
    // <Toolbar variant="dense" className={clsx(classes.root, { [classes.highlight]: totalSelected > 0 })}>
    <Toolbar variant="dense" className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h5" id="tableTitle">
          
          </Typography>
      {/* {totalSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {totalSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h5" id="tableTitle">
          
        </Typography>
      )} */}
      {/* {totalSelected > 0 ? (
        <Tooltip title="Add Customer">
          <IconButton id="addCustomerBtn" onClick={onAddCustomerBtn}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : (
        //FileUploadIcon
        // <Button
        //   id="verifyPhonesBtn"
        //   variant="contained"
        //   disabled={phoneCount === 0}
        //   color="primary"
        //   onClick={handleValidatePhoneNumbers}
        //   size="small"
        //   style={{ width: '193px' }}
        //   startIcon={<VerifiedUserIcon fontSize="small" />}>
        //   <Typography variant="caption" noWrap>
        //     Verify Phones
        //   </Typography>
        // </Button>
        <Tooltip title="Add Customer">
          <IconButton id="addCustomerBtn" onClick={onAddCustomerBtn}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )} */}
      <CustomerFileUpload></CustomerFileUpload>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableRow: {},
    pagination: {
      width: 'fit-content',
      marginLeft: 'auto',
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 12,
      paddingRight: 5,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingRight: 5,
    },
    nameCell: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
      padding: '0 12px 0 16px',
    },
  }),
);

export default function CustomerTable(): ReactElement {
  const classes: any = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof CustomerTableDto>('id');
  // Commented and not removed for future reference
  // const selected = useAppSelector((state) => state.customers.selectedCustomers);
  const dispatch = useAppDispatch();
  const [openCalender, setOpenCalender] = React.useState<boolean>(false);
  const [currentCustomerId, setCurrentCustomerId] = React.useState<number | null>(null)

  const { loading, error } = useAppSelector((state) => ({
    loading: state.customers.loading,
    error: state.customers.error,
  }));
 
  const page = useAppSelector((state) => state.customers.currentPage);
  const rowsPerPage = useAppSelector((state) => state.customers.pageSize);
  let rows: any[] = useAppSelector((state) => state.customers.customers);
  const count = useAppSelector((state) => state.customers.totalCustomers);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CustomerTableDto): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Commented and not removed for future reference

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.checked) {
  //     dispatch(SetSelectedCustomers(rows));
  //     dispatch(SetAllSelectedCustomers(true));
  //   } else {
  //     dispatch(SetSelectedCustomers([]));
  //     dispatch(SetAllSelectedCustomers(false));
  //   }
  // };

  // const handleSelectVisibleClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.checked) {
  //     dispatch(SetSelectedCustomers(rows));
  //     return;
  //   }
  //   dispatch(SetSelectedCustomers([]));
  // };

  // const handleClick = (event: React.MouseEvent<unknown>, customer: any): void => {
  //   dispatch(SetSelectedCustomers([customer]));
  // };

  const handleSelectCustomer = (customer: any): void => {
    dispatch(fetchSelectedCustomer(customer.id));
  };

  const toggleOptIn = (customer: any): void => {
    dispatch(saveCustomerToggle(customer));
  };

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    dispatch(fetchCustomersPage(newPage));
  };

  const selectedCustomerId = useAppSelector((state) => {
    if (state.customers.selectedCustomer) {
      return state.customers.selectedCustomer.crmCustomerId;
    }
    return -1;
  }, shallowEqual);
  // Commented and not removed for future reference
  // const isSelected = (id: number): boolean => !!selected.find((x: any) => x.id === id);

  const emptyRows = rowsPerPage - rows.length;

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ type: 'error', message: 'Failed to fetch customers' }));
    }
  }, [error, dispatch]);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box>
          {/* <CustomerTableToolbar validPhoneNumbers={0} numSelected={selected.length} /> */}
          <CustomerTableToolbar validPhoneNumbers={0} numSelected={0} />
        </Box>
        <TableContainer style={{ maxHeight: '65vh' }}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // Commented and not removed for future reference
              // onSelectAllClick={handleSelectAllClick}
              // onSelectVisibleClick={handleSelectVisibleClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {!loading && (
              <TableBody>
                {rows.length > 0 ? (
                  stableSort(rows, getComparator(order, orderBy)).map((row: any, index) => {
                 // Commented and not removed for future reference
                    // const isItemSelected = isSelected(row.id);
                    const isRowSelected = selectedCustomerId === row.id;
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover className={classes.tableRow} tabIndex={-1} key={row.id} selected={isRowSelected}>
                        <TableCell
                          onClick={() => handleSelectCustomer(row)}
                          component="th"
                          id={labelId}
                          className={classes.nameCell}
                          scope="row"
                          padding="none">
                          {row.name && row.name.length > 30 ? row.name.slice(0, 30) + '...' : row.name}
                          {row.isOrganization && row.ccOrganizationName && (
                            <Chip
                              label={row.ccOrganizationName}
                              variant="outlined"
                              size="small"
                              style={{ height: '20px', marginLeft: '5px' }}
                            />
                          )}
                        </TableCell>
                        {dmsTypeId != 1 && (
                          <TableCell align="left">{row.isOrganization ? row.organizationName : ''}</TableCell>
                        )}

                        <TableCell align="left" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          {row.cellPhoneVerificationStatus &&
                          row.cellPhoneVerificationStatus === PhoneVerificationStatusEnum.Verified ? (
                            <CheckCircleIcon className={classes.successIcon + ' successIcon'} />
                          ) : (
                            ''
                          )}
                          {row.cellPhoneVerificationStatus &&
                          row.cellPhoneVerificationStatus === PhoneVerificationStatusEnum.Failed ? (
                            <CancelIcon className={classes.errorIcon + ' errorIcon'} />
                          ) : (
                            ''
                          )}
                          {row.cellPhone ? formatPhoneNumber(row.cellPhone) : 'N/A'}
                        </TableCell>
                        <TableCell align="left">{row.emailAddress && <EmailLink email={row.emailAddress}/> }</TableCell>
                        <TableCell align="left">{row.isFacebookCustomer? 'Facebook' : row.originationSource}</TableCell>
                        {/* <TableCell align="left">{row.uniqueIdentifier}</TableCell> */}
                        <TableCell align="left">{row.createdAt? format(new Date(row.createdAt), 'M/d/yyyy'): ''} </TableCell>
                          {/* for future use */}
                        {/* <TableCell align="left">
                          {(row.address
                            ? row.address.length > 50
                              ? row.address.slice(0, 50) + '..., '
                              : row.address + ''
                            : '') +
                            (row.city && row.address ? ', ' : '') + 
                            (row.city ? row.city: '') +
                            (row.stateProvince ? ', ' + row.stateProvince : '') +
                            (row.zipPostal ? ', ' + row.zipPostal : '')}
                        </TableCell> */}
                        {/* <TableCell align="left">{row.stateProvince}</TableCell>
                        <TableCell align="left">{row.zipPostal}</TableCell> */}
                        <TableCell align="left">
                          <Switch
                            checked={row.optIn === 1}
                            onChange={() => toggleOptIn(row)}
                            size="small"
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          {/* {row.optIn === 1 ? (
                          <Tooltip
                            title="CUSTOMER opted-in for receiving messages"
                            aria-label="add"
                          >
                            <CheckIcon className={classes.successIcon} />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={`CUSTOMER opted-out on ${Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }).format(new Date(row.optOutDate))}`}
                            aria-label="add"
                          >
                            <CancelIcon className={classes.errorIcon} />
                          </Tooltip>
                        )
                          } */}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center"  size="medium" style={{ textAlign: 'center',fontSize: '20px', color: 'grey' }}>
                      No Customers Found
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell style={{ textAlign: 'center' }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
            {loading && (
              <TableBody>
                <TableRow style={{ height: 30 * 15, width: '100%' }}>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    <SmallCircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box textAlign="right" m={1}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(count / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </div>
  );
}
