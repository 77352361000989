import { Box, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      width: '98%',
      margin: '5px 5px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      paddingRight: theme.spacing(1),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export function SelectedDepartmentsCard({
  dealerLocationName,
  departments,
  removeDepartment,
}: {
  dealerLocationName: string;
  departments: { name: string; id: number }[];
  removeDepartment(id: number): void;
}): React.ReactElement {
  const classes: any = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const tagsCount = 5;

  let expandButton = null;
  if (departments.length > tagsCount) {

    let tagDifference:number = departments.length - tagsCount
    let collapseText = tagDifference > 1 ? `+${tagDifference} Others` : `+${tagDifference} Other`
    
    expandButton = (
      <Button size="small" variant="text" color="primary" onClick={(): void => setExpanded(!expanded)}>
        {expanded ? <ExpandMoreIcon /> : collapseText}
      </Button>
    );
  }
  return (
    <div className={classes.root}>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={expandButton}>
          <Box>
            <Box>
              <Typography className={classes.heading}>{dealerLocationName}</Typography>
            </Box>
            {!expanded && (
              <Typography className={classes.secondaryHeading} component="div">
                {departments.slice(0, tagsCount).map((dep) => (
                  <Chip
                    key={dep.id}
                    style={{ margin: '5px' }}
                    label={dep.name}
                    onDelete={(): void => removeDepartment(dep.id)}
                  />
                ))}
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div>
            {departments.map((dep) => (
              <Chip
                key={dep.id}
                style={{ margin: '5px' }}
                label={dep.name}
                onDelete={(): void => removeDepartment(dep.id)}
              />
            ))}
          </div>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
}

export default SelectedDepartmentsCard;
