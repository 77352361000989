import User from 'components/setting/models/user';
import { createContext } from 'react';
import { FilterDeals, PipelineListResponse } from '../model/dealsInterfaces';

interface DealState {
  user?: User[] | undefined;
  pipeline?: PipelineListResponse[] | undefined;
  defaultPipelineId?: number;
  filterRequest?:FilterDeals;
}
export const DealContext = createContext<DealState>({});

export default DealContext;
