import { Box, Button, Container, Drawer, makeStyles, MenuItem, TextField, Theme } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Bracket from 'components/setting/models/bracket';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    height: '100%',
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface AddCreditsForm {
  credits: number;
}

interface Props {
  brackets: Bracket[];
  onSubmit(values: AddCreditsForm): void;
  open: boolean;
  onClose(event: any): void;
}

const AddCreditsDrawer: React.FC<Props> = ({ brackets, onSubmit, open, onClose }) => {
  const classes: any = useStyles();

  const initialValues = {
    credits: 0,
  };

  const validationSchema = Yup.object<any>().shape<any>({
    credits: Yup.number().min(1).required('Credits are required'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Drawer PaperProps={{ style: { width: '50%' } }} open={open} onClose={onClose} variant="temporary" anchor="right">
      <DrawerToolbar title="Add Message Credits" onClose={onClose} />
      <Container className={classes.container}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            select
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth
            onChange={formik.handleChange}
            label="Credits"
            name="credits"
            placeholder="Select Credit Amount"
            value={formik.values.credits}
            error={!!(formik.touched.credits && formik.errors.credits)}
            helperText={formik.touched.credits ? formik.errors.credits : ''}>
            <MenuItem disabled value={0}>
              Select Credits
            </MenuItem>
            {brackets?.map((x) => {
              return (
                <MenuItem value={x.creditsToRecharge}>
                  {x.creditsToRecharge + ' - $' + x.payment + ' ' + x.packageText}
                </MenuItem>
              );
            })}
          </TextField>
          <Box className={classes.submit}>
            <Button id="addCreditsBtn" fullWidth type="submit" color="primary" variant="contained" size="medium">
              Add Credits
            </Button>
          </Box>
        </form>
      </Container>
    </Drawer>
  );
};

export default AddCreditsDrawer;
