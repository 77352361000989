import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    maxHeight: 'inherit',
    minHeight: '80vh',
    marginBottom: '120px',
  },
  hover: {
    '&:hover': {
      background: 'none',
    },
  },
  boxStyle: {
    display: 'flex',
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  searchCard: {
    marginBottom: theme.spacing(-1),
    marginTop: '1em',
    backgroundColor: 'Inherit',
    border: 'none',
    borderColor: 'inherit',
    width: '100%',
  },
  primaryAddBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  primaryIconBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingLeft: '1%',
    marginLeft: '1%',
    minWidth: '90px',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  successIconBtn: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    paddingLeft: '1%',
    marginLeft: '1%',
    minWidth: '90px',
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
  errorIconBtn: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    paddingLeft: '1%',
    marginLeft: '1%',
    minWidth: '90px',
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  defaultIconBtn: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
    paddingLeft: '1%',
    marginLeft: '1%',
    minWidth: '90px',
    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
  textFieldPadding: {
    paddingBottom: '2%',
  },
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  success: {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  danger: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  archive: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  filterIconBtn: {
    background: 'black',
    color: 'white',
  },
  chipStyles: {
    color: 'white',
    width: '70%',
    marginTop: 2,
    fontWeight: 'bold',
    borderRadius: '5px',
    height: '20px',
  },
  cardAmount: {
    color: 'green',
    marginTop: 2,
    fontWeight: 'bold',
  },
  cardDay: {
    color: 'red',
    fontWeight: 'bold',
    float: 'right',
  },
  muiPopoverPaper: {
    '@media screen and (max-width: 1280px)': {
      width: '50%',
      maxHeight: '200px !important',
      top: '40% !important',
      left: '0 !important',
      right: '0',
      margin: '0 auto',
    },
  },
}));

export default useStyles;
