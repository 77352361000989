import {
  AppBar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@material-ui/icons/History';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PersonIcon from '@material-ui/icons/Person';
import PollIcon from '@material-ui/icons/Poll';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FilterListIcon from '@mui/icons-material/FilterList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import clsx from 'clsx';
import TargetCRMIcon from 'common/components/icons/targetCRM';
import { useRouter } from 'common/hooks';
import { DashboardItem } from 'common/models/dashboard-item';
import MessagingAPI from 'components/messaging/api/messaging-api';
import React, { ReactElement, ReactNode, SVGProps, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import FeedbackIcon from '@mui/icons-material/Feedback';
import BeamerButton from './beamer-button';
import DepartmentFilterContext from './department-filter-context';
import DepartmentFilterDrawer from './department-filter-drawer';
import { MessengerContext } from './messenger-context';
import { NotificationDrawer } from './NotificationDrawer';
import {
  addNotification,
  closeNotificationDrawer,
  Notification,
  openNotificationDrawer,
  SetSelectedNotifications,
} from './notificationSlice';
import { SignalRContext } from './signalr-context';
import { useSignalRConnection } from './useSignalRConnection';
import { useUserDepartmentSettings } from './useUserDepartmentSettings';
import SmsAudio from 'assets/audios/sms.mp3';
import RootDrawer from 'components/root-drawer';
import { logoutUser } from 'components/authentication/components/authentication-slice';
import { Backdrop, ListItemButton } from '@mui/material';
import { UpdateSelectedCustomer } from 'components/customer/components/Customers/customersSlice';
import DealerAPI from 'components/authentication/api/dealer-api';
import { DealerSetting } from 'common/models/dealer-setting';
import taskboardApi from 'components/lead/components/task-board/taskboard-api';
import TaskContext from 'components/lead/components/task-board/tasks-context';
import AuthenticationAPI from 'components/authentication/api/authentication-api';
import notificationApi from './notification-api';
import { PersistentAppData } from 'common/models';
import { getTeamSupportWidgetSnippet } from 'teamSupportWidget';
import config from 'config';
import twilioApi from 'components/setting/components/twilio/twilio-api';
import { useLocation } from 'react-router-dom';
import { showSnackbar } from 'components/root-snackbar';
import { TriggerMessageCount } from '../../../dmsAppsCallbacks';
import VisibilityIcon from '@mui/icons-material/Visibility';
declare global {
  interface Window {
    Intercom: any;
    intercomSettings: {
      app_id: string;
      name: string; // Full name
      email: string; // Email address
      user_id: string; // User Id
      created_at: string; // Signup date as a Unix timestamp
      horizontal_padding: number;
      vertical_padding: number;
      alignment: string;
    };
  }
}
 
const feedbackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -350 1280 1024" xmlSpace="none" {...props}>
    <path d="M467 15.999H44c-24.814 0-44 20.186-44 45v300c0 24.814 20.186 45 45 45h46v75a14.983 14.983 0 0 0 9.258 13.857c5.531 2.308 12.018 1.078 16.348-3.252l85.605-85.605H467c24.814 0 45-20.186 45-45v-300c0-24.815-20.186-45-45-45zM292.87 290.613c-8.525 7.119-17.593 14.678-27.114 22.837-5.569 4.811-13.919 4.831-19.512 0-9.521-8.159-18.589-15.718-27.114-22.837-50.054-41.792-83.13-69.39-83.13-112.178 0-40.02 29.648-71.367 67.5-71.367 25.562 0 42.202 13.638 52.5 27.466 10.298-13.828 26.938-27.466 52.5-27.466 37.852 0 67.5 31.348 67.5 71.367 0 42.788-33.076 70.386-83.13 112.178z" />
    <path d="M308.5 137.068c-27.847 0-37.676 32.725-38.071 34.116-1.86 6.401-7.749 10.854-14.414 10.854h-.073c-6.68-.044-12.539-4.438-14.37-10.854-.396-1.392-10.225-34.116-38.071-34.116-21.372 0-37.5 17.783-37.5 41.367 0 28.74 27.231 51.489 72.363 89.15a3297.273 3297.273 0 0 1 17.637 14.795 3258.177 3258.177 0 0 1 17.637-14.795c45.132-37.661 72.363-60.41 72.363-89.15-.001-23.584-16.129-41.367-37.501-41.367z" />
  </svg>
);
 
const useMainListStyles = makeStyles((theme: Theme): any => ({
  list: {
    height: 'inherit',
  },
  listItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  listItemText: {
    fontWeight: 'bolder',
  },
  listItemIcon: {},
  listItemSelected: {
    color: theme.palette.primary.dark,
  },
}));
 
export function DashboardDrawerItems({
  unreadConversationsCount,
  unreadTaskCount,
  phoneRegistration,
}: {
  unreadConversationsCount: number;
  unreadTaskCount: number;
  phoneRegistration: number;
}): ReactElement {
  const dispatch = useAppDispatch();
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  // const isAdmin = useAppSelector((state) => state.user?.isUserAdmin);
  let textToPay = useAppSelector((state) => state.user?.isTextToPayAllow);  
  if (!userPermissions) {
    userPermissions = [];
  }
  const router = useRouter();
  const classes: any = useMainListStyles();
  let dashboardItems = new Array<DashboardItem>();
 
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'dashboard')) {
    dashboardItems.push({
      title: 'Dashboard',
      route: '/',
      icon: DashboardIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'broadcasts')) {
    dashboardItems.push({
      title: 'Broadcasts',
      route: '/broadcasts',
      icon: SendIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'messages')) {
    dashboardItems.push({
      title: 'Messenger',
      route: '/messages',
      icon: MailIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'surveys')) {
    dashboardItems.push({
      title: 'Surveys',
      route: '/surveys',
      icon: PollIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'survey-response')) {
    dashboardItems.push({
      title: 'Feedback',
      route: '/survey-response',
      icon: FeedbackIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks')) {
    dashboardItems.push({
      title: 'Tasks',
      route: '/tasks',
      icon: AssignmentIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
    dashboardItems.push({
      title: 'Customers',
      route: '/customers',
      icon: PersonIcon,
    });
  }
  //ReviewHub and Flagging - Reverted
  // if (userPermissions.find((x) => x.featureName.toLowerCase() === 'settings')) {
  //   dashboardItems.push({
  //     title: 'Review Hub',
  //     route: '/review-hub',
  //     icon: VisibilityIcon,
  //   });
  // }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customer-groups')) {
    dashboardItems.push({
      title: 'Customer Groups',
      route: '/customer-groups',
      icon: GroupIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'invoice')) {
    dashboardItems.push({
      title: 'Orders',
      route: '/invoice',
      icon: HistoryIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'inventory')) {
    dashboardItems.push({
      title: 'Inventory',
      route: '/inventory',
      icon: AgricultureIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'deals')) {
    dashboardItems.push({
      title: 'Deals',
      route: '/deals',
      icon: FilterListIcon,
    });
  }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'automations')) {
   dashboardItems.push({
     title: 'Automations',
       route: '/automations',
     icon: AutoAwesomeIcon,
   });
  }
 
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'payments') && textToPay) {
    dashboardItems.push({
      title: 'Payments',
      route: '/payments',
      icon: AttachMoneyIcon,
    });
  }
  let settingItems = new Array<DashboardItem>();
  settingItems.push(
    {
      title: 'General',
      route: '/settings#general',
      icon: '',
    },
    {
      title: 'Customers',
      route: '/settings#customers',
      icon: '',
    },
    {
      title: 'Auto-Responder',
      route: '/settings#auto-responder',
      icon: '',
    },
    {
      title: 'Quick-Messages',
      route: '/settings#quick-messages',
      icon: '',
    },
    {
      title: 'Account',
      route: '/settings#account',
      icon: '',
    },
    {
      title: 'Tasks',
      route: '/settings#tasks',
      icon: '',
    },
    {
      title: 'Email',
      route: '/settings#email',
      icon: '',
    },
    {
      title: 'Departments',
      route: '/settings#departments',
      icon: '',
    },
    {
      title: 'Users',
      route: '/settings#users',
      icon: '',
    },
    {
      title: 'Widget',
      route: '/settings#widget',
      icon: '',
    },
    {
      title: 'Deals',
      route: '/settings#deals',
      icon: '',
    },
    {
      title: 'Payments',
      route: '/settings#payments',
      icon: '',
    },                   
  )
  // if (userPermissions.find((x) => x.featureName.toLowerCase() === "automations")) {
  //   settingItems.push({
  //     title: 'Automations',
  //     route: '/settings#automation',
  //     icon: '',
  //   },);
  // }
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'settings')) {
    dashboardItems.push({
      title: 'Settings',
      icon: SettingsIcon,
      subMenu: settingItems
    });
  }
 
  // Do not delete the code below
  // if(dashboardItems.length > 0 && isAdmin){
  //   const settingsIndex=dashboardItems.findIndex(x=>x.title.toLowerCase() === 'settings');
  //   dashboardItems[settingsIndex].subMenu?.push({
  //     title: 'Payments',
  //     route: '/settings#payments',
  //     icon: '',
  //   })
  // }
 
  const [myRoute, setMyRoute] = useState('');
  const handleOnClick = (route: string): void => {
    if (route != '') {
      setMyRoute(route);
    }
    dispatch(UpdateSelectedCustomer(undefined));
    if (route != '') {
      router.push(route);
    } else {
      router.push(myRoute);
    }
  };
  const isSelected = (currentRoute: string): boolean => {
    if (currentRoute === '/') {
      return currentRoute === router.pathname;
    }
    return router.pathname.includes(currentRoute);
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => { }, [open]);
  return (
    <List className={classes.list} onMouseLeave={() => setOpen(false)}>
      {dashboardItems.map(({ title, icon, route, subMenu }) => {
        const Icon = icon;
        const selected = isSelected(route!);
        return (
          <>
            {!subMenu ? (
              <ListItemButton id={`ListItem${title}`} dense key={route} onClick={(): void => handleOnClick(route!)}>
                <ListItemIcon className={clsx(classes.listItemIcon, selected && classes.listItemSelected)}>
                  {title === 'Messenger' ? (
                    <Badge badgeContent={unreadConversationsCount} color="secondary">
                      {<Icon fontSize="small" />}
                    </Badge>
                  ) : title === 'Tasks' ? (
                    <Badge badgeContent={unreadTaskCount} color="secondary">
                      {<Icon fontSize="small" />}
                    </Badge>
                  ) : (
                    <>{<Icon fontSize="small" />}</>
                  )}
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primaryTypographyProps={{ variant: 'h6' }}
                  primary={title}
                />
              </ListItemButton>
            ) : (
              <>
                <ListItemButton id={`ListItem${title}`} dense key={route} onClick={handleClick}>
                  <ListItemIcon className={clsx(classes.listItemIcon, selected && classes.listItemSelected)}>
                    {subMenu.find((x) => x.route === myRoute) ? (
                      <Icon className={classes.listItemSelected} />
                    ) : title === 'Settings' && phoneRegistration === 1 ? (
                      <Badge badgeContent={1} color="secondary">
                        {<Icon fontSize="small" />}
                      </Badge>
                    ) : (
                      <Icon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primaryTypographyProps={{ variant: 'h6' }}
                    primary={title}
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {subMenu?.map(({ title, route }) => {
                      const isDepartment = title === 'Departments' && phoneRegistration === 1;
 
                      return (
                        <ListItemButton dense sx={{ pl: 10 }} key={route} onClick={(): void => handleOnClick(route!)}>
                          {/* Conditionally add a badge to the item with the title "Departments" */}
                          {isDepartment ? (
                            <Badge badgeContent={1} color='secondary'>
                              <ListItemText
                                className={classes.listItemText}
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary={title}
                              />
                            </Badge>
                          ) : (
                            <ListItemText
                              className={classes.listItemText}
                              primaryTypographyProps={{ variant: 'h6' }}
                              primary={title}
                            />
                          )}
                        </ListItemButton>
                      );
                    })}
                  </List>
                  <div style={{ marginBottom: 20 }}></div>
                </Collapse>
              </>
            )}
          </>
        );
      })}
    </List>
  );
}
 
function NotificationButton(): ReactElement {
  const dispatch = useAppDispatch();
  const notificationCount = useAppSelector(
    (state) => state.notification.notifications.filter((x) => x.isRead === false).length,
  );
  const isDrawerOpen = useAppSelector((state) => state.notification.isNotificationDrawerOpen);
  const inProgress = useAppSelector((state) =>
    state.notification.notifications.some((elem) => elem.type === 'InProgress'),
  );
 
  const handleDrawerIconClick = (): void => {
    if (isDrawerOpen) {
      dispatch(closeNotificationDrawer());
    } else {
      dispatch(openNotificationDrawer());
    }
  };
 
  return (
    <>
      <IconButton id="notificationBtn" size="small" onClick={handleDrawerIconClick}>
        <Badge color="secondary" badgeContent={notificationCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {inProgress && <LinearProgress />}
    </>
  );
}
 
const useDashboardStyles = makeStyles((theme: Theme): any => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    paddingRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.navBar.height,
    marginLeft: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7),
    },
    height: `calc(100vh - ${theme.navBar.height})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    backgroundColor: 'green !important',
    border: '5px solid green !important',
 
    marginLeft: theme.dashboardDrawer.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    position: 'fixed',
    boxShadow: theme.shadows[1],
    whiteSpace: 'nowrap',
    width: theme.dashboardDrawer.width,
    top: theme.navBar.height,
    height: `calc(100% - ${theme.navBar.height})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  menuUser: {
    '@media screen and (max-width: 1280px)': {
      '& .MuiPaper-root': {
        position: 'relative',
        float: 'right',
        right: '15px',
        left: 'unset !important',
      },
    },
  },
}));
 
function useIntercomSetting(dealerName: string, userName: string, crmUserId: string): void {
  if (window.intercomSettings) {
    const intercomName = `${dealerName} - ${userName}`;
    window.intercomSettings.name = intercomName;
    if (userName != '' && crmUserId != '0') {
      const intercomEmail = `${userName.replace(/\s/g, '').toLowerCase()}@targetcrm.com`;
      window.intercomSettings.email = intercomEmail;
      window.intercomSettings.user_id = crmUserId;
    }
  }
  useEffect(() => {
    const intercomName = `${dealerName} - ${userName}`;
    if (window.intercomSettings) {
      window.intercomSettings.name = intercomName;
      window.intercomSettings.alignment = 'left';
      window.intercomSettings.vertical_padding = 0;
      window.intercomSettings.horizontal_padding = 0;
      if (userName != '' && crmUserId != '0') {
        const intercomEmail = `${userName.replace(/\s/g, '').toLowerCase()}@targetcrm.com`;
        window.intercomSettings.email = intercomEmail;
        window.intercomSettings.user_id = crmUserId;
      }
    }
  }, [dealerName, userName]);
}
 
let unreadMessagesCount = 0;
export function Dashboard({ children }: { children?: ReactNode }): ReactElement | null {
  const classes: any = useDashboardStyles();
 
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.user.loggedIn);
  const dealerName = useAppSelector((state) => state.user.dealerName);
  const userName = useAppSelector((state) => state.user.userFullName);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  const [dealerSettings, setDealerSettings] = React.useState<DealerSetting | undefined>();
  const crmUserId = useAppSelector((state) => state.user.crmUserId);
  const isHostedDealer = useAppSelector((state) => state.user.isHosted);

  const {
    loading: departmentSettingLoading,
    selectedDepartmentIds,
    assignedDepartments,
    onUserDepartmentSettingUpdate,
    fetchUserDepartmentSettings,
  } = useUserDepartmentSettings(crmUserId);
  useIntercomSetting(dealerName, userName, crmUserId);
 
  const connection = useSignalRConnection();
 
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isDepartmentDrawerOpen, setIsDepartmentDrawerOpen] = React.useState(false);
  const toggle = useRef(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [unreadConversationsCount, setUnreadConversationsCount] = useState(0);
  const [taskTodoOrOverdueCount, setTaskTodoOrOverdueCount] = useState(0);
  const [phoneRegistration, setPhoneRegistration] = useState(0);
  const [hideOtherNavigation, setHideOtherNavigation] = useState(false);
 
  const location = useLocation();
  const query = new URLSearchParams(location.search);
 
  const user = useAppSelector((state) => state.user);
  const getNotifications = useCallback(async (): Promise<void> => {
    if (isLoggedIn) {
      try {
        const response = await notificationApi.NotificationApis.getAllNotifications();
        dispatch(SetSelectedNotifications(response));
      } catch (error) {
        console.error(error);
      }
    }
  }, [isLoggedIn]);

  const updateTitle = useCallback((count) => {
    if (toggle.current && count) {
      document.title = `(${count}) New Message${count > 1 ? 's' : ''}!`;
      toggle.current = false;
    } else {
      document.title = `Target CRM`;
      toggle.current = true;
    }
  }, []);
 
  // const handleBroadcastMessage = useCallback((event) => {
  //   const newCount = event.data;
  //   updateTitle(newCount);
  // }, [updateTitle]);
 
  const fetchUnreadConversationsCount = useCallback(async (): Promise<void> => {
    if (isLoggedIn) {
      try {
        const response = await MessagingAPI.getUnreadConversationCount(dmsTypeId);
        const unreadConversationCount = response.count;

        setUnreadConversationsCount(unreadConversationCount);
        unreadMessagesCount = unreadConversationCount;

        // Update the title locally
        updateTitle(unreadConversationCount);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isLoggedIn, setUnreadConversationsCount, updateTitle]);

  //Currently enabled the message-count functionality only for all infinity and ideal, aspen hosted dealers
  const allowedDMSForTriggerMessageCount = (): boolean => {
    return (
      localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY' ||
      (isHostedDealer && localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL') ||
      (isHostedDealer && localStorage.getItem('Login_Source')?.toString() === 'DMS_ASPEN')
    );
  };
  useEffect(() => {
    if (allowedDMSForTriggerMessageCount()) {
      TriggerMessageCount(unreadConversationsCount);
    }
  }, [unreadConversationsCount]);

  const fetchTodoOrOverdueCount = useCallback(async (): Promise<void> => {
    if (isLoggedIn) {
      try {
        const response = await taskboardApi.getTodoOrOverdueTaskCount();
        const taskCount = response.taskCount;
        setTaskTodoOrOverdueCount(taskCount);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isLoggedIn]);
 
  const fetchPhoneRegistrationStatus = useCallback(async (): Promise<void> => {
    if (isLoggedIn) {
      try {
        const response = await twilioApi.getSecondaryProfileData(dmsDealerIdCrm);
        if (response.campaignStatus === 'VERIFIED' && response.isActive == true) {
          setPhoneRegistration(0);
        }
        else {
          setPhoneRegistration(1);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, [isLoggedIn]);
 
  useEffect(() => {
    const hideNavigationRaw = query.get("hideOtherNavigation");
    const hideNavigation = hideNavigationRaw === "true" ? true : false;
    setHideOtherNavigation(hideNavigation);
  }, []);
 
  useEffect(() => {
    fetchUnreadConversationsCount();
    fetchTodoOrOverdueCount();
    getNotifications();
  }, [dmsDealerIdCrm, fetchUnreadConversationsCount, fetchTodoOrOverdueCount, isLoggedIn, selectedDepartmentIds]);
 
  useEffect(() => {
    fetchPhoneRegistrationStatus();
 
    //Validating the IDMS token on each page load
    AuthenticationAPI.validateTokenUser();
  }, []);
 
  //Team Support widget implementation
  useEffect(() => {
    const userState = localStorage.getItem('PROJECT_X_STATE');
    if (!userState) return;
 
    let interval: any = undefined;
    //Only show the team support widget for Ideal DMS
    const env = config.get('env');
    if (dmsTypeId === 0 && env === 'production') {
      let teamSupportWidget = document.getElementById('designstudio-button') as HTMLElement;
      if (teamSupportWidget) {
        //Again show back the team support
        teamSupportWidget.style.display = 'unset';
        let teamSupportBody = document.getElementById('designstudio') as HTMLElement;
        if (teamSupportBody) teamSupportBody.style.display = 'none';
      } else {
        getTeamSupportWidgetSnippet();
      }
      //Hide the intercom
      interval = setInterval(() => {
        let intercormWidgetLauncher = document.getElementsByClassName('intercom-launcher')[0] as HTMLElement;
        let intercormWidgetBody = document.getElementsByClassName('intercom-app')[0] as HTMLElement;
        if (intercormWidgetLauncher) {
          intercormWidgetLauncher.style.display = 'none';
          //clearInterval(interval);
        } else if (intercormWidgetBody) {
          intercormWidgetBody.style.display = 'none';
          //clearInterval(interval);
        }
      }, 100);
    }
    return () => {
      clearInterval(interval);
      //Hide the team support if already open
      let teamSupportWidget = document.getElementById('designstudio-button') as HTMLElement;
      if (teamSupportWidget) teamSupportWidget.style.display = 'none';
      let teamSupportBody = document.getElementById('designstudio') as HTMLElement;
      if (teamSupportBody) teamSupportBody.style.display = 'none';
      //Again show back the intercom
      let intercormWidgetLauncher = document.getElementsByClassName('intercom-launcher')[0] as HTMLElement;
      if (intercormWidgetLauncher) intercormWidgetLauncher.style.display = 'unset';
      let intercormWidgetBody = document.getElementsByClassName('intercom-app')[0] as HTMLElement;
      if (intercormWidgetBody) intercormWidgetBody.style.display = 'unset';
    };
  }, []);
 
 
  useEffect(() => {
    let interval: NodeJS.Timeout;
    const hideElementById = (id: string) => {
      const element = document.getElementById(id) as HTMLElement;
      if (element) element.style.display = 'none';
    };
    const hideElementsByClassName = (className: string) => {
      const elements = Array.from(document.getElementsByClassName(className)) as HTMLElement[];
      elements.forEach(element => {
        element.style.display = 'none';
      });
    };
    if (hideOtherNavigation) {
      hideElementById('designstudio-button');
      hideElementById('designstudio');
 
      interval = setInterval(() => {
        hideElementsByClassName('intercom-launcher');
        hideElementsByClassName('intercom-app');
      }, 100);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toggle.current && unreadConversationsCount) {
        document.title = `(${unreadConversationsCount}) New Message${unreadConversationsCount > 1 ? 's' : ''}!`;
        toggle.current = false;
      } else {
        document.title = `Target CRM`;
        toggle.current = true;
      }
    }, 1000);

    return (): void => clearInterval(interval);
  }, [unreadConversationsCount]);

  React.useEffect(() => {
    async function getDealerSettings(): Promise<void> {
      try {
        const dealerSettingsResponse = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
        setDealerSettings(dealerSettingsResponse);
      } catch (error) { }
    }
    getDealerSettings();
  }, []);
 
  useEffect(() => {
    async function messageReceived(notification: Notification | any): Promise<void> {
      const audio = new Audio(SmsAudio);
      audio.play();
      unreadMessagesCount++;
      setUnreadConversationsCount(unreadMessagesCount);
      //fetchUnreadConversationsCount();
      if(notification.EventType === 'PaymentReceived'){
        const notify: Notification = {
          id: notification.Id,
          guid: notification.Guid,
          title: notification.Title,
          message: notification.Message,
          type: notification.Type,
          userName: notification.Username,
          isRead: notification.IsRead,
          dealerId: notification.DealerId,
          eventType: notification.EventType,
          eventData: notification.EventData,
        };
        dispatch(addNotification(notify));
        dispatch(showSnackbar({ type: notification.Type.toLowerCase(), message: notification.Message }));
      }
    }
    async function conversationReadOrUnread(notification: Notification): Promise<void> {
      fetchUnreadConversationsCount();
    }
    if (connection) {
      connection.on('MessageReceived', messageReceived);
      connection.on('PaymentReceived', messageReceived);
      connection.on('ConversationRead', conversationReadOrUnread);
      connection.on('ConversationUnread', conversationReadOrUnread);
    }
    return (): void => {
      if (connection) {
        connection.off('MessageReceived', messageReceived);
        connection.off('PaymentReceived', messageReceived);
        connection.off('ConversationRead', conversationReadOrUnread);
        connection.off('ConversationUnread', conversationReadOrUnread);
      }
    };
  }, [connection]);
 
  useEffect(() => {
    const logoutOtherTabs = (event: any) => {
      if (event.key == 'logoutOtherTabs') {
        try {
          document.title = 'Target CRM';
          localStorage.removeItem('PROJECT_X_STATE');
          dispatch(logoutUser());
          router.push('/login');
        } catch (error) {
          window.location.reload();
        }
        localStorage.removeItem('logoutOtherTabs');
      }
    };
 
    window.addEventListener('storage', logoutOtherTabs);
    return () => window.removeEventListener('storage', logoutOtherTabs);
  }, []);
 
  async function handleLogout(): Promise<void> {
    if (connection) {
      try {
        connection.stop();
      } catch (error) {
        console.error('Unable to stop connection:', error);
      }
    }
    document.title = 'Target CRM';
    localStorage.removeItem('PROJECT_X_STATE');
    localStorage.setItem('logoutOtherTabs', 'logout' + Math.random());
    dispatch(logoutUser());
    router.push('/login');
  }
 
  async function handleUserProfile(): Promise<void> {
    router.push('/user-profile');
  }
 
  async function handleDealsFilter() {
    const localdealfilter = `DealsFilter_${dmsDealerIdCrm}_${crmUserId}`;
    // const localdata = localStorage.getItem(localdealfilter) || null;
    // if (localdata == null) {
    const resp: any = await DealerAPI.getPersistentAppData(localdealfilter);
    if (resp != '') {
      localStorage.setItem(localdealfilter, resp.data);
    } else {
      const addtoDb: PersistentAppData = {
        crmUserId: crmUserId,
        dmsDealerId: dmsDealerIdCrm,
        type: localdealfilter,
        data: JSON.stringify({
          pipeId: 0,
          request: {
            isFilter: false,
            statusId: 1,
            ageValue: 0,
          },
        }),
      };
      localStorage.setItem(localdealfilter, addtoDb.data);
      await DealerAPI.addPersistentAppData(addtoDb);
    }
    // }
  }
  async function handleTaskFilter() {
    const localtaskfilter = `TaskFilter_${dmsDealerIdCrm}_${crmUserId}`;
    // const localdata = localStorage.getItem(localtaskfilter) || null;
    // if (localdata == null) {
    const resp: any = await DealerAPI.getPersistentAppData(localtaskfilter);
    if (resp != '') {
      localStorage.setItem(localtaskfilter, resp.data);
    }
    // }
  }
  useEffect(() => {
    handleDealsFilter();
    handleTaskFilter();
  }, []);
  // React.useEffect(()=> {
  //   history.listen((location : any, action: any) => {
  //     console.log(user);
  //     let route = `${location.pathname}${location.search}${location.hash}`;
  //     DealerAPI.logUserActivity(user.crmUserId, user.dealerId, route, action)
  //     .then(r =>{}).catch(e => {});
  //   })
  // },[1]);
 
  const backDrop = useAppSelector((state) => state.customers.editCustomerLoading);
  function HandleDepartmentClose() {
    setIsDepartmentDrawerOpen(false);
    // window.location.reload();
  }
 
  const hideShowTeamSupport = (show: boolean) => {
    const env = config.get('env');
    if (dmsTypeId === 0 && env === 'production') {
      // Hide/Show the team support if present
      let teamSupportWidget = document.getElementById('designstudio-button') as HTMLElement;
      if (teamSupportWidget) teamSupportWidget.style.display = !show ? 'none' : 'unset';
    }
  };
 
  const handleDrawerOpenHide = (val: boolean) => {
    setIsDrawerOpen(val);
    hideShowTeamSupport(!val);
  };
 
  return (
    <SignalRContext.Provider value={connection}>
      <MessengerContext.Provider
        value={{
          unreadConversationsCount,
          fetchUnreadConversationsCount,
        }}>
        <TaskContext.Provider value={{ taskTodoOrOverdueCount, fetchTodoOrOverdueCount }}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }} open={backDrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DepartmentFilterContext.Provider value={{ selectedDepartmentIds, fetchUserDepartmentSettings }}>
            <AppBar position="fixed" variant="outlined" className={classes.appBar}>
              <Toolbar variant="dense" disableGutters>
                <a href="/" style={{ display: hideOtherNavigation ? 'none' : 'block' }}>
                  <TargetCRMIcon />
                </a>
                <Box flexGrow={1} />
                {/* Hiding this clock temporarily */}
                {/* <Box mr={1}>{dealerSettings && <DealerClockTime dmsDealerIdCrm={dmsDealerIdCrm} />}</Box> */}
                <Box mr={1} style={{ display: hideOtherNavigation ? 'none' : 'block' }}>
                  <BeamerButton />
                </Box>
 
                <Box>
                  <Button
                    size="small"
                    disabled={departmentSettingLoading}
                    startIcon={<LocationOnIcon />}
                    variant="outlined"
                    onClick={(): void => setIsDepartmentDrawerOpen(true)}>
                    {departmentSettingLoading
                      ? 'Departments filter'
                      : `${selectedDepartmentIds.length} Department${selectedDepartmentIds.length === 1 ? '' : 's'}`}
                  </Button>
                </Box>
                <Box style={{ display: hideOtherNavigation ? 'none' : 'block' }}>
                  <NotificationButton />
                </Box>
                <Box style={{ display: hideOtherNavigation ? 'none' : 'block' }}>
                  <Tooltip title={"Dealer "+dmsDealerIdCrm}>
                    <Button
                      id="userFullName"
                      size="small"
                      onClick={(event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget)}
                      startIcon={<AccountCircleIcon fontSize="small" />}>
                      {userName}
                    </Button>
                  </Tooltip>
                  <Menu
                    className={classes.menuUser}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id="use-menu"
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={(): void => setAnchorEl(null)}> 
                    <MenuItem dense onClick={handleUserProfile}>
                      <ListItemText primary="User Profile" />
                    </MenuItem>
                    <MenuItem dense onClick={handleLogout}>
                      <ListItemText primary="Log Out" />
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              style={{ display: hideOtherNavigation ? 'none' : 'block' }}
              variant="permanent"
              onMouseOver={(): void => handleDrawerOpenHide(true)}
              onFocus={(): void => handleDrawerOpenHide(true)}
              onMouseOut={(): void => handleDrawerOpenHide(false)}
              onBlur={(): void => handleDrawerOpenHide(false)}
              classes={{
                paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
              }}
              open={isDrawerOpen}>
              <DashboardDrawerItems
                unreadConversationsCount={unreadConversationsCount}
                unreadTaskCount={taskTodoOrOverdueCount}
                phoneRegistration={phoneRegistration}
              />
            </Drawer>
            <main className={classes.content}>{children}</main>
            <NotificationDrawer />
            <DepartmentFilterDrawer
              open={isDepartmentDrawerOpen}
              selectedDepartmentIds={selectedDepartmentIds}
              assignedDepartments={assignedDepartments}
              onSave={onUserDepartmentSettingUpdate}
              onClose={(): void => HandleDepartmentClose()}
            />
            <RootDrawer />
          </DepartmentFilterContext.Provider>
        </TaskContext.Provider>
      </MessengerContext.Provider>
    </SignalRContext.Provider>
  );
}
// Hiding this clock temporarily
// function DealerClockTime({ dmsDealerIdCrm }: { dmsDealerIdCrm: number }) {
//   const [dealerTimeState, setdealerTimeState] = React.useState<string>('');
//   const [hourDiffState, setHourDiffState] = React.useState<number>();
//   React.useEffect(() => {
//     async function getDealerSettings(): Promise<void> {
//       try {
//         const dealerSettingsResponse = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
//         if (dealerSettingsResponse) {
//           const currentTime = new Date();
//           const dealerTime = new Date(dealerSettingsResponse.dealerTime);
//           const diff = currentTime.getTime() - dealerTime.getTime();
//           const diffHours = Math.floor(diff / (1000 * 60 * 60));
//           setdealerTimeState(dealerSettingsResponse.dealerTime.toString());
//           setHourDiffState(diffHours);
//         }
//       } catch (error) {}
//     }
//     getDealerSettings();
//   }, [1]);
 
//   React.useEffect(() => {
//     const interval = setInterval(() => {
//       const dealerTime = new Date();
//       dealerTime.setHours(dealerTime.getHours() - (hourDiffState || 0));
//       setdealerTimeState(dealerTime.toString());
//     }, 1000);
//     return () => {
//       clearInterval(interval);
//     };
//   }, [hourDiffState]);
 
//   return (
//     <Box style={{ marginTop: '5px' }}>
//       <Grid container style={{ color: 'black' }}>
//         <Grid item>
//           <AccessTimeIcon style={{ marginTop: '-1px' }} />{' '}
//         </Grid>
//         <Grid item>
//           <Typography variant="h4" style={{ color: 'black', fontSize: '14px' }}>
//             {' '}
//             <span>&nbsp;{dealerTimeState ? moment(dealerTimeState).local().format('h:mm:ss A') : 'N/A'}</span>
//           </Typography>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }
export default Dashboard;