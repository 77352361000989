import { Box, CircularProgress, Grid, IconButton, MenuItem, Paper, TablePagination, TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { useRouter } from 'common/hooks';
import useLocations from 'common/hooks/useLocations';
import { LocationOption } from 'common/models/location-option';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { BroadCast, BroadCastStatus, BroadCastType, BroadCastTypeFilter } from 'components/broadcast/models/broadcast';
import AppSnackBar from 'components/snackbar/snackbar';
import { fetchDealerEmail } from 'store/dealerEmailSlice';
import { Notification } from 'common/models/notification';
import BroadCastCard from './broadcast-card';
import NewBroadCastCard from './new-broadcast-card';
import NewBroadCastDrawer, { NewBroadCastFormValue } from './new-broadcast-drawer';
import SignalRContext from 'components/layouts/DashboardLayout/signalr-context';

type StatusOption = {
  value: BroadCastStatus;
  label: string;
};
export type TypeOption = {
  value: BroadCastType;
  label: string;
};
export type TypeOptionFilter = {
  value: BroadCastTypeFilter;
  label: string;
};

function BroadCastHeader({
  type,
  status,
  location,
  loadingLocations,
  typeOptions,
  statusOptions,
  locationOptions,
  loading,
  onStatusChange,
  onTypeChange,
  onLocationChange,
}: {
  status: BroadCastStatus;
  type: BroadCastType;
  loadingLocations: boolean;
  location?: number;
  typeOptions: TypeOptionFilter[];
  statusOptions: StatusOption[];
  locationOptions?: LocationOption[];
  loading?:boolean;
  onStatusChange(value: string): void;
  onTypeChange(value: string): void;
  onLocationChange(value: number): void;
}): React.ReactElement {
  const dealerEmail = useAppSelector((state) => state.dealerEmail);

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1}>{/* <PageHeader title="Broadcasts" /> */}</Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          size="small"
          margin="dense"
          variant="outlined"
          name="location"
          label="Location"
          value={location}
          disabled={loadingLocations}
          inputProps={{ id: 'location' }}
          onChange={(event: any): void => onLocationChange(event.target.value)}>
          {locationOptions &&
            locationOptions.map((loc) => (
              <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                {loc.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          margin="dense"
          variant="outlined"
          name="type"
          label="Type"
          value={type}
          disabled={loading}
          inputProps={{ id: 'type' }}
          onChange={(event: any): void => onTypeChange(event.target.value)}>
          {typeOptions.map((typ) => (
            <MenuItem key={typ.value} value={typ.value} disabled={typ.value === 'EMAIL' && !dealerEmail.replyToAddress}>
              {typ.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          margin="dense"
          variant="outlined"
          name="status"
          label="Status"
          value={status}
          inputProps={{ id: 'type' }}
          onChange={(event: any): void => onStatusChange(event.target.value)}>
          {statusOptions.map((st) => (
            <MenuItem key={st.value} value={st.value}>
              {st.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
}

function BroadCastsTab(): React.ReactElement {
  const [typeFilter, setTypeFilter] = React.useState<BroadCastType|any>('ALL');
  const [statusFilter, setStatusFilter] = React.useState<BroadCastStatus>('ALL');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [broadCasts, setBroadCasts] = React.useState<BroadCast[]>([]);
  const [showNewBroadCastDrawer, setShowNewBroadCastDrawer] = React.useState<boolean>(false);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [totalRows, setTotalRows] = React.useState<number>(-999);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(17);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const signalRConnection = useContext(SignalRContext);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  

  const {
    loading: loadingLocations,
    locationOptions,
    defaultLocationOption,
    getLocationName,
  } = useLocations(dmsDealerIdCrm);
  const [locationFilter, setLocationFilter] = React.useState<number | undefined>(
    defaultLocationOption?.dealerLocationId === -1 ? 0 : defaultLocationOption?.dealerLocationId,
  );

  useEffect(() => {
    dispatch(fetchDealerEmail());
  }, [dispatch]);

  useEffect(() => {
    async function updateBroadcastStatus(notification: Notification) {
      let data: any = JSON.parse(notification.EventData);
      try {
        setLoading(true);
        await BroadCastAPI.getBroadCasts(dmsDealerIdCrm, typeFilter, statusFilter, locationFilter, rowsPerPage, pageNumber).then(response=>{
          setBroadCasts(response.broadcasts);
          setTotalRows(response.count);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showSnackBar({ type: 'error', message: 'Unable to fetch broadcasts' });
      }
    }

    if (signalRConnection) {
      signalRConnection.on('ProgressFinished', updateBroadcastStatus);
    }
    return (): void => {
      if (signalRConnection) {
        signalRConnection.off('ProgressFinished', updateBroadcastStatus);
      }
    };
  }, [signalRConnection]);

  const statusOptions: StatusOption[] = [
    { value: 'ALL', label: 'ALL' },
    { value: 'DRAFTS', label: 'DRAFTS' },
    { value: 'SCHEDULED', label: 'SCHEDULED' },
    { value: 'SENT', label: 'SENT' },
    { value: 'DELETED', label: 'RECYCLE BIN' },
  ];
  const typeOptions: TypeOption[] = [
    { value: 'SMS', label: 'SMS' },
    { value: 'MMS', label: 'MMS' },
    { value: 'EMAIL', label: 'EMAIL' },
  ];
  const typeOptionsFilter: TypeOptionFilter[] = [
    { value: 'ALL', label: 'ALL' },
    { value: 'TEXT', label: 'TEXT' },
    { value: 'EMAIL', label: 'EMAIL' },
  ];


  const fetchBroadCasts = async (): Promise<void> => {
    if (locationFilter !== undefined && locationFilter >= 0) {
      try {
        setLoading(true);
        await BroadCastAPI.getBroadCasts(dmsDealerIdCrm, typeFilter, statusFilter, locationFilter, rowsPerPage, pageNumber).then(response=>{
          setBroadCasts(response.broadcasts);
          setTotalRows(response.count);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showSnackBar({ type: 'error', message: 'Unable to fetch broadcasts' });
      }
    }
  };

  async function handleCreate(newBroadcastForm: NewBroadCastFormValue): Promise<void> {
    try {
      const response = await BroadCastAPI.createBroadcast(
        newBroadcastForm.type,
        newBroadcastForm.title,
        newBroadcastForm.dealerLocationId,
      );
      setShowNewBroadCastDrawer(false);
      router.push(`/broadcasts/${response.id}?type=${newBroadcastForm.type}`);
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to create broadcast' });
    }
  }
  async function handleDuplicate(id: number, type: BroadCastType): Promise<void> {
    try {
      await BroadCastAPI.duplicateBroadcast(id, type);
      await fetchBroadCasts();
      showSnackBar({ type: 'success', message: 'Broadcast duplicated' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to duplicate broadcast' });
    }
  }
  async function handleDelete(id: number, type: BroadCastType): Promise<void> {
    try {
      await BroadCastAPI.deleteBroadcast(id, type);
      await fetchBroadCasts();
      showSnackBar({ type: 'success', message: 'Broadcast deleted' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to delete broadcast' });
    }
  }
  async function handleRestore(id: number, type: BroadCastType): Promise<void> {
    try {
      await BroadCastAPI.restoreBroadcast(id, type);
      await fetchBroadCasts();
      showSnackBar({ type: 'success', message: 'Broadcast restored' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to restore broadcast' });
    }
  }
  async function handleCancel(id: number, type: BroadCastType): Promise<void> {
    try {
      await BroadCastAPI.deleteScheduledBroadcast(id, type);
      await fetchBroadCasts();
      showSnackBar({ type: 'success', message: 'Scheduled broadcast canceled' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to cancel broadcast' });
    }
  }

  // Events
  function handleNewBroadCastCardClicked(): void {
    setShowNewBroadCastDrawer(true);
  }

  function handleChangePage(_: unknown, newPage: number): void {
    setPageNumber(newPage);
  }
  React.useEffect(() => {
    setLocationFilter(defaultLocationOption?.dealerLocationId);
  }, [defaultLocationOption]);
  
  React.useEffect(() => {
    fetchBroadCasts();
  }, [dmsDealerIdCrm, locationFilter,showSnackBar, statusFilter, typeFilter, signalRConnection, pageNumber, rowsPerPage]);

  
  const content = (
    <>
      {statusFilter !== 'DELETED' && (
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <NewBroadCastCard onClick={handleNewBroadCastCardClicked} />
        </Grid>
      )}
      {broadCasts &&
        broadCasts.map((broadCast) => (
          <Grid key={broadCast.id} item xs={12} sm={6} md={4} lg={2}>
            <BroadCastCard
              getLocationName={getLocationName}
              broadCast={broadCast}
              broadCastType={broadCast.type}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
              onRestore={handleRestore}
              onCancel={handleCancel}
            />
          </Grid>
        ))}
    </>
  );
  return (
        <Paper square style={{ padding: '10px',paddingBottom:0, height: 'auto' }}>
            <BroadCastHeader
              loading={loading}
              status={statusFilter}
              type={typeFilter}
              loadingLocations={loadingLocations}
              location={locationFilter}
              locationOptions={locationOptions}
              statusOptions={statusOptions}
              typeOptions={typeOptionsFilter}
              onTypeChange={(type: BroadCastType): void => {
                setTypeFilter(type);
                setPageNumber(0);
              }}
              onStatusChange={(status: BroadCastStatus): void => {
                setStatusFilter(status);
                setPageNumber(0);
              }}
              onLocationChange={(location: number): void => {
                setLocationFilter(location);
                setPageNumber(0);
              }}
            />
          {loading ? (
            <Box style={{ textAlign: 'center', height: '67.7vh', overflow: 'hidden' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box mt={2} component="section" style={{  height: '66vh',  overflow: 'scroll', overflowX: 'hidden' }}>
              <Grid container spacing={2}>
                {content}
              </Grid>
            </Box>
          )}
          <NewBroadCastDrawer
            open={showNewBroadCastDrawer}
            onCreate={handleCreate}
            selectedLocationId={locationFilter}
            locationOptions={locationOptions}
            selectedType={typeFilter === 'EMAIL' ? 'EMAIL' : 'SMS'}
            typeOptions={
              typeFilter === 'TEXT'
                ? typeOptions.filter((x) => x.value != 'EMAIL')
                : typeFilter === 'EMAIL'
                ? typeOptions.filter((x) => x.value === 'EMAIL')
                : typeOptions
            }
            onClose={(): void => setShowNewBroadCastDrawer(false)}
          />
          {typeof broadCasts != 'undefined' && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
            />
          )}
        </Paper>
  );
}

export default BroadCastsTab;
