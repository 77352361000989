import { Box, Container, Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Department from 'components/setting/models/department';
import TaskOutcomeForm from './task-outcome-form';

function NewTaskOutcomeDrawer({
  departments,
  open,
  onClose,
  onCreate,
}: {
  departments: Department[];
  open: boolean;
  onClose(): void;
  onCreate(value: any, createMore: boolean): Promise<void>;
}): ReactElement {
  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Add Task Outcome" onClose={onClose} />
      <Box mb={1} />
      <Container>
        {open && <TaskOutcomeForm taskOutcome={null} taskOutcomeDepartments={[]} departments={departments} onCancel={onClose} onSubmit={onCreate} />}
      </Container>
    </Drawer>
  );
}

export default NewTaskOutcomeDrawer;
