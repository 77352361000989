import { get, put, post } from 'api';
import config from 'config';
import { Notification } from './notificationSlice';

const notificationBaseUrl = config.get('api.notification');
const notificationUrl = `${notificationBaseUrl}/notification`;

const NotificationApis = {
  async getAllNotifications(): Promise<Notification[]> {
    const url = `${notificationUrl}/getAll`;
    const { data } = await get(url);
    return data as Notification[];
  },
  async removeNotification(Id: number): Promise<string> {
    const url = `${notificationUrl}/remove/${Id}`;
    const { data } = await post(url);
    return data;
  },
  async updateAllNotifications(): Promise<string> {
    const url = `${notificationUrl}/updateAll`;
    const { data } = await put(url);
    return data;
  },
  async removeAllNotifications(): Promise<string> {
    const url = `${notificationUrl}/removeAll`;
    const { data } = await put(url);
    return data;
  },
};
export default {
  NotificationApis,
};
