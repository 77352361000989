import { Box, Button, Fade, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { amber, green, red } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useAppDispatch, useAppSelector } from 'app';
import LoadingButton from 'common/components/buttons/LoadingButton';
import React, { ReactElement, useEffect, useState } from 'react';

import { Question, Survey } from '../../models';
import QuestionField from '../question-field/question-field';
import QuestionFieldTitle from '../question-field/question-field-title';
import { QuestionCategory } from '../survey-page/survey-questions/new-question-block/new-question-block';

function SelectedNpsRating({ selectedNps }: { selectedNps: any }): ReactElement | null {
  if (selectedNps !== undefined) {
    const ratingTitle = `Rating: ${selectedNps}/10`;
    let fontColor;
    if (selectedNps <= 6) {
      // eslint-disable-next-line prefer-destructuring
      fontColor = red[500];
    } else if (selectedNps > 6 && selectedNps <= 8) {
      // eslint-disable-next-line prefer-destructuring
      fontColor = amber[600];
    } else if (selectedNps > 8 && selectedNps <= 10) {
      // eslint-disable-next-line prefer-destructuring
      fontColor = green[500];
    }
    return (
      <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: fontColor }}>
        {ratingTitle}
      </Typography>
    );
  }
  return null;
}

function SurveyProgressBar({ percentage }: { percentage: number }): ReactElement {
  const progressBarTitle = `${percentage}% completed`;

  return (
    <Grid container justifyContent="flex-end">
      <Grid item xs={12} sm={6}>
        <Typography variant="caption">{progressBarTitle}</Typography>
        <LinearProgress variant="determinate" value={percentage} />
      </Grid>
    </Grid>
  );
}

/**
 * if currentQuestionStep is 1 => rating category question should be
 * when answer of rating category question is >= 7. display questionCategory.PassiveAndPromoters category questions
 * when answer of rating category question is <= 6. display questionCategory.Detractors category questions
 */
function useSurveyForm(survey: Survey | null, onSubmit: (values: any) => void): any {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [categorizedQuestions, setCategorizedQuestions] = useState<any>(undefined);
  const [currentQuestion, setCurrentQuestion] = useState<{ question: Question; answer: any } | undefined>(undefined);

  useEffect(() => {
    if (survey) {
      const groupedQuestions = survey.questions.reduce((cQ: any, question: Question) => {
        (cQ[question.category] = cQ[question.category] || []).push({ question, answer: undefined });
        return cQ;
      }, {});
      setCategorizedQuestions(groupedQuestions);
    }
  }, [survey]);

  useEffect(() => {
    if (categorizedQuestions) {
      if (currentIndex === 1) {
        if (categorizedQuestions) {
          let selectedQuestion = categorizedQuestions[QuestionCategory.Rating][0];
          setCurrentQuestion(selectedQuestion);
        }
      } else {
        // When rating question is answered, check rating answer
        const ratingAnswer = categorizedQuestions[QuestionCategory.Rating][0].answer;
        if (ratingAnswer !== undefined) {
          let currentCategory =
            parseInt(ratingAnswer, 10) > 6 ? QuestionCategory.PromotersAndPassive : QuestionCategory.Detractors;
          setCurrentQuestion(categorizedQuestions[currentCategory][currentIndex - 2]);
        } else {
          // un-determinate case.
        }
      }
    }
  }, [categorizedQuestions, currentIndex]);

  /**
   * Handle navigate to previous question
   */
  function onPreviousQuestion() {
    setCurrentIndex(currentIndex - 1);
  }

  /**
   * Handle navigation for next question and register response for a question
   */
  function onNextQuestion() {
    setCurrentIndex(currentIndex + 1);
  }

  function onChange(value: any, goNext: boolean) {
    if (currentQuestion) {
      let newQuestions = { ...categorizedQuestions };
      const currentCategory = currentQuestion.question.category;
      if (currentCategory === QuestionCategory.Rating) {
        newQuestions[QuestionCategory.Rating][0].answer = value;
        setCategorizedQuestions(newQuestions);
        if (value !== undefined && goNext) {
          goNextOnRatingQuestion(value);
        }
      } else {
        newQuestions[currentCategory][currentIndex - 2].answer = value;
        setCategorizedQuestions(newQuestions);
      }
    }
  }

  function goNextOnRatingQuestion(answer: any) {
    const ratingCategory = getRatingCategory(answer);
    const PnPCount = categorizedQuestions[QuestionCategory.PromotersAndPassive];
    const DCount = categorizedQuestions[QuestionCategory.Detractors];
    if (ratingCategory === QuestionCategory.PromotersAndPassive && PnPCount !== undefined) {
      onNextQuestion();
    } else if (ratingCategory === QuestionCategory.Detractors && DCount !== undefined) {
      onNextQuestion();
    }
  }

  function getRatingCategory(answer: any) {
    let ratingCategory = parseInt(answer, 10) > 6 ? QuestionCategory.PromotersAndPassive : QuestionCategory.Detractors;
    return ratingCategory;
  }

  function getNavigation() {
    if (categorizedQuestions && currentQuestion) {
      const currentCategory = currentQuestion?.question.category;
      if (currentCategory === QuestionCategory.Rating) {
        // If rating question is answered
        if (currentQuestion.answer !== undefined) {
          let ratingCategory = getRatingCategory(currentQuestion.answer);
          const PnPCount = categorizedQuestions[QuestionCategory.PromotersAndPassive];
          const DCount = categorizedQuestions[QuestionCategory.Detractors];
          let navigation = { isNext: true, isPrev: false, showSubmit: false };
          if (ratingCategory === QuestionCategory.PromotersAndPassive && PnPCount === undefined) {
            navigation.isNext = false;
            navigation.showSubmit = true;
          }
          if (ratingCategory === QuestionCategory.Detractors && DCount === undefined) {
            navigation.isNext = false;
            navigation.showSubmit = true;
          }
          return navigation;
        } else {
          return { isNext: false, isPrev: false, showSubmit: false };
        }
      } else if (currentCategory === QuestionCategory.PromotersAndPassive) {
        const PromotersAndPassiveQuestionsCount = categorizedQuestions[QuestionCategory.PromotersAndPassive].length;
        if (currentIndex - 1 < PromotersAndPassiveQuestionsCount) {
          return { isNext: true, isPrev: true, showSubmit: false };
        } else {
          return { isNext: false, isPrev: true, showSubmit: true };
        }
      } else if (currentCategory === QuestionCategory.Detractors) {
        const DetractorsQuestionCount = categorizedQuestions[QuestionCategory.Detractors].length;
        if (currentIndex - 1 < DetractorsQuestionCount) {
          return { isNext: true, isPrev: true, showSubmit: false };
        } else {
          return { isNext: false, isPrev: true, showSubmit: true };
        }
      }
    }

    return { isNext: false, isPrev: false };
  }

  function getProgress() {
    if (categorizedQuestions && currentQuestion) {
      const currentCategory = currentQuestion?.question.category;
      if (currentCategory !== QuestionCategory.Rating) {
        const totalQuestions = categorizedQuestions[currentCategory].length + 1;
        const completedQuestions =
          categorizedQuestions[currentCategory].filter(
            (x: { question: Question; answer: any }) => x.answer !== undefined,
          ).length + 1;
        return Math.round((completedQuestions / totalQuestions) * 100);
      } else {
        // when only rating answer no other questions
        const ratingCategory = getRatingCategory(currentQuestion.answer);
        const PnPCount = categorizedQuestions[QuestionCategory.PromotersAndPassive];
        const DCount = categorizedQuestions[QuestionCategory.Detractors];
        if (ratingCategory === QuestionCategory.PromotersAndPassive && PnPCount === undefined) {
          return 100;
        }
        if (ratingCategory === QuestionCategory.Detractors && DCount === undefined) {
          return 100;
        }
      }
    }
    return 0;
  }

  function handleSubmit() {
    if (survey && categorizedQuestions) {
      const ratingQuestion = categorizedQuestions[QuestionCategory.Rating][0];
      const ratingCategory = getRatingCategory(ratingQuestion.answer);
      let feedback: {
        ratingCategory: QuestionCategory;
        surveyId: number;
        customerId: number | null;
        responses: { questionId: number; answer: any }[];
      } = {
        ratingCategory,
        surveyId: survey?.id,
        customerId: null,
        responses: [],
      };
      feedback.responses.push({
        questionId: ratingQuestion.question.id,
        answer: ratingQuestion.answer,
      });
      const PnpQuestions = categorizedQuestions[QuestionCategory.PromotersAndPassive];
      const DetractorQuestions = categorizedQuestions[QuestionCategory.Detractors];
      if (ratingCategory === QuestionCategory.PromotersAndPassive && PnpQuestions) {
        PnpQuestions.forEach((q: any) => {
          if (q.answer !== undefined) {
            feedback.responses.push({
              questionId: q.question.id,
              answer: q.answer,
            });
          }
        });
      }
      if (ratingCategory === QuestionCategory.Detractors && DetractorQuestions) {
        DetractorQuestions.forEach((q: any) => {
          if (q.answer !== undefined) {
            feedback.responses.push({
              questionId: q.question.id,
              answer: q.answer,
            });
          }
        });
      }
      onSubmit(feedback);
    }
  }

  return [
    currentIndex,
    currentQuestion?.question,
    currentQuestion?.answer,
    onNextQuestion,
    onPreviousQuestion,
    onChange,
    getNavigation(),
    getProgress(),
    handleSubmit,
    categorizedQuestions && categorizedQuestions[QuestionCategory.Rating][0].answer,
  ];
}

const useSurveyPaperStyles = makeStyles((theme: Theme): any => ({
  root: {
    height: '80vh',
    minWidth: '50vw',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    justifyContent: 'space-around',
  },
  header: {
    textAlign: 'center',
  },
  content: {
    height: '70%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationButtons: {
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    textAlign: 'center',
    button: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      button: {
        width: '100%',
      },
    },
  },
}));

export default function SurveyForm({
  survey,
  loading,
  onSubmit,
}: {
  survey: Survey;
  loading : boolean;
  onSubmit(values: any): void;
}): ReactElement {
  const classes: any = useSurveyPaperStyles();
  const [
    questionNumber,
    currentQuestion,
    currentAnswer,
    handleNextQuestion,
    handlePreviousQuestion,
    handleOnChange,
    navigation,
    percentage,
    handleSurveySubmit,
    ratingAnswer,
  ] = useSurveyForm(survey, onSubmit);
  const dispatch = useAppDispatch();

  const onHandleSubmit = () => {
    handleSurveySubmit();
  }


  const renderNavigation = (
    <Box mt={2} className={classes.navigationButtons}>
      <Grid container>
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <Button
            size="small"
            id="back"
            color="primary"
            disabled={!navigation.isPrev}
            onClick={handlePreviousQuestion}
            startIcon={<ChevronLeftIcon />}>
            Back
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            id="next"
            size="small"
            color="primary"
            disabled={!navigation.isNext}
            onClick={handleNextQuestion}
            endIcon={<ChevronRightIcon />}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  const renderCurrentQuestion = currentQuestion && (
    <form className={classes.form}>
      <Box mb={1}>
        <SelectedNpsRating selectedNps={ratingAnswer} />
      </Box>
      <Box mb={2} width="100%" flexGrow={1}>
        <QuestionFieldTitle id={questionNumber} title={currentQuestion.title} />
      </Box>
      <QuestionField
        id={`${currentQuestion.id}`}
        type={currentQuestion.type}
        value={currentAnswer}
        name={`${currentQuestion.type}_${questionNumber}`}
        onChange={handleOnChange}
      />
      <Box mt={2} className={classes.navigationButtons}>
        {renderNavigation}
      </Box>
      {navigation.showSubmit && (
        <Fade in>
          <Box my={3} mb={2} className={classes.submitButton}>
            <LoadingButton id="surveySubmitBtn" color="primary" disabled={loading} onClick={onHandleSubmit} variant="contained">
              Submit
            </LoadingButton>
          </Box>
        </Fade>
      )}
    </form>
  );

  return (
    <>
      <Box className={classes.content}>{renderCurrentQuestion}</Box>
      <SurveyProgressBar percentage={percentage} />
    </>
  );
}

