import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadIcon from '@mui/icons-material/Download';
import { store } from 'app';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Drawer, Button, Box, Theme, Typography, IconButton, Paper, Fab, TableSortLabel } from '@material-ui/core';
import Input from '@material-ui/core/Input';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import NewCard from 'common/components/cards/new-card';
import { PageHeader } from 'common/components/layout';
import { useRouter } from 'common/hooks';
import CustomerGroup from 'components/customer-groups/models/customer-group';
import CustomerSegmentAPI from 'components/customer-groups/api/customer-group-api';
import React from 'react';
import CustomerGroupAPI from 'components/customer-groups/api/customer-group-api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { formatPhoneNumber } from 'common/utils';
import CustomerInvoiceDrawer from 'components/customer-groups/components/customer-group/customers-table/customer-invoice//index';
import { red } from '@material-ui/core/colors';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import FilterIconButton from '@material-ui/core/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import widget from 'components/setting/components/widget';
import { width } from '@mui/system';
import EmailLink from 'common/components/buttons/email-link';
import { useFormik } from 'formik';
import * as Yup from 'yup';


const useStyles2 = makeStyles((theme: Theme): any => ({
  drawer: {
    marginTop: theme.navBar.height,
    width: `30%`,
    height: `calc(100% - ${theme.navBar.height})`,
  },
}));


const useStyles = makeStyles((theme: Theme): any => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  drawer: {
    marginTop: theme.navBar.height,
    // width: `30%`,
    height: `calc(100% - ${theme.navBar.height})`,
    //height: `100vh`,
  },

  customOutline: {
    // paddingLeft:"5px",
    borderColor: "blue",
    "& .MuiInput-underline": {
      // backgroundColor: 'silver',
      borderColor: "white",
      border: "2px solid silver"
    },
    // backgroundColor: 'Red',
    "& .MuiOutlinedInput-notchedOutline": {
      backgroundColor: 'Red',
      borderColor: "green"
    }
  },
  defaultSelect: {
    padding: "5px",
    //  backgroundColor: 'Red',
    borderBottom: "0px solid red",
    // marginBottom:"-10px"
  },

  'MuiTablePagination-caption': {
    backgroundColor: 'green',
    display: "none",

  },
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  myTable: {
    '& th': {
    },
    '& td': {
      borderBottom: "1px solid #EAEDED",
      // whiteSpace: "nowrap",
      height: "25px"
    },
    '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
  },

  labelLink: {
    border: "1px solid silver",
    padding: "3px 10px",
    cursor: 'pointer',
    '&:hover': {
      // backgroundColor: "#E5E7E9",
      border: "1px solid black",
    },

  },

  noWrap: {
    whiteSpace: "nowrap",
  },
  myColumn: {
    "@media (max-width: 1100px)": {
      display: "none"
    }
  },
  myColumnMini: {

    "@media (max-width: 1100px)": {
      display: "none"
    }
  },
  myColumnMiniX: {
    display: "none",
    whiteSpace: "nowrap",
    "@media (max-width: 1100px)": {
      display: "block"
    }
  }


}));



let mySearchTimeOut: any;
export default function invoice(): React.ReactElement {
  const classes: any = useStyles();
  const router = useRouter();
  const headCells = {
    DisplayInvoiceId: 'DisplayInvoiceId',
    InvoiceStatus: 'InvoiceStatus',
    InvoiceDate: 'InvoiceDate',
    InvoiceType: 'InvoiceType',
    CellPhone: 'CellPhone',
    DisplayAddress: 'DisplayAddress',
    DisplayCustomerName: 'DisplayCustomerName',
    Email: 'Email',
    LocationName: 'LocationName',
   };

  const [invoiceData, SetInvoiceData] = React.useState<any>([]);
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [searchType, SetSearchType] = React.useState<any>('x');
  const [searchData, SetSearchData] = React.useState<any>('');
  const [searchType2, SetSearchType2] = React.useState<any>('x');
  const [searchData2, SetSearchData2] = React.useState<any>('');
  const [searchText, SetSearchText] = React.useState<any>('');
  const [searchText2, SetSearchText2] = React.useState<any>('');
  const [selectedRow, SetSelectedRow] = React.useState<any>([]);
  const [invoiceType, setInvoiceType] = React.useState<any>(0);
  const [invoiceType2, setInvoiceType2] = React.useState<any>(0);
  const [selectedInvoiceId, SetSelectedInvoiceId] = React.useState(0);
  const [openInvoiceDrawer, setOpenInvoiceDrawer] = React.useState<boolean>(false);
  const [showFilter, SetShowFilter] = React.useState<boolean>(false);
  const [pageNumber, SetPageNumber] = React.useState<number>(0);
  const [totalRows, SetTotalRows] = React.useState<number>(-999);
  const [orderBy, setOrderBy] = React.useState(headCells.InvoiceDate);
  const [orderByDirection, setOrderByDirection] = React.useState<'asc' | 'desc'|undefined>('desc');
  // const [withInRadius, SetWithInRadius] = React.useState<any>(null);
  const [amountSpent, SetAmountSpent] = React.useState<any>(null);
  const [manufacturers, SetManufacturers] = React.useState<any>([]);
  const [dealerLocations, SetDealerLocations] = React.useState<any>([]);
  const [dealerLocationId, SetDealerLocationId] = React.useState<any>(-1);
  const [isDealerLocation, SetIsDealerLocation] = React.useState<boolean>(false);
  const [rowsPerPage, SetRowsPerPage] = React.useState<any>(15);
  const [manufacturer, setManufacturer] = React.useState([] as any);
  const [customerTypes, SetCustomerTypes] = React.useState(['x'] as any);
  const [windowWidth, SetWindowWidth] = React.useState<any>(window.innerWidth);
  const [showWorking, SetShowWorking] = React.useState(false);
  const [companyId, SetCompanyId] = React.useState(store.getState().user.dealerId);
  

  //const [companyId, SetCompanyId] = React.useState(0);
  const customerTypeData: any = [
    // { keyId: 'all', Name: "All Customers" },
    { keyId: 'cell', Name: "With Cellphone" },
    { keyId: 'email', Name: "With Email" },
    { keyId: 'address', Name: "With Address" },
  ]

  interface FormValues {
    withInRadius: any | null;
  }

  const validationSchema = Yup.object<any>().shape<any>({
    withInRadius: Yup.number()
      .max(5000, 'Value must range from 1-5000')
      .min(1, 'Value must range from 1-5000')
      .nullable(),
  });

    const formik = useFormik<FormValues>({
      initialValues: {
        withInRadius: null
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const handleResize = (e: any) => {
    SetWindowWidth(window.innerWidth)
  };

  function OpenCloseFilter(value: number = 0) {
    SetShowFilter(value == 0 ? false : true);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    let startDate = new Date();
    setEndDate(startDate.toISOString().slice(0, 10));
    startDate.setMonth(startDate.getMonth() - 6);
    setStartDate(startDate.toISOString().slice(0, 10));
    GetManufacturers();
    GetDealerLocations();
  }, []);

  function CustomerTypesRender(): string {
    let value = '';
    for (const ct of customerTypes) {
      for (const ctd of customerTypeData) {
        if (ctd.keyId == ct) {
          value = value + ctd.Name.replace('With', '') + " "
        }
      }
    }
    if (value == '') { value = 'All Customers' }
    return value;
  }

  function GetJson(rowsCount: number, forExportData: number): string {
    let dealerIds = dealerLocationId;
    if (dealerLocationId == 0) {
      dealerIds = '';
      for (const item of GetLocationData()) {
        dealerIds = dealerIds + item.DealerId + ',';
      }
    }
    if (dealerLocationId == -1) { return ''; }
    if (totalRows == -999) { rowsCount = -999 }
    if (typeof (startDate) == 'undefined') { return ''; }
    if (typeof (endDate) == 'undefined') { return ''; }
    if (dealerLocationId == 0 && dealerIds == '-1') { return ''; }
    if (dealerIds == '') { return '' }
    let _withInRadius = 0;
    if (formik.values.withInRadius != null && formik.values.withInRadius != '') { _withInRadius = formik.values.withInRadius };
    let _amountSpent = 0;
    if (amountSpent != null && amountSpent != '') { _amountSpent = amountSpent };
    //alert(dealerIds);
    let json = '{' +
      '"dealerLocationId":' + dealerLocationId +
      ', "dealerIds":"' + dealerIds + '"' +
      ', "startDate":"' + startDate + '"' +
      ', "endDate":"' + endDate + '"' +
      ', "invoiceType":' + invoiceType +
      ', "invoiceType2":' + invoiceType2 +
      ', "searchType":"' + searchType + '"' +
      ', "searchText":"' + searchData + '"' +
      ', "searchType2":"' + searchType2 + '"' +
      ', "searchText2":"' + searchData2 + '"' +
      ', "pageNumber":' + pageNumber +
      ', "rowsCount":' + (rowsCount == -1 ? -1 : rowsCount) +
      ', "withInRadius":' + _withInRadius +
      ', "amountSpent":' + _amountSpent +
      ', "forExportData":' + forExportData +
      ', "rowsPerPage":' + rowsPerPage +
      ', "orderBy":"' + orderBy + '"' +
      ', "orderByDirection":"' + orderByDirection + '"' +
      ', "customerTypes":"' + customerTypes.join(', ') + '"' +
      '}';
    return json;
  }

  async function GetInvoices(rowsCount: number) {
    if (!formik.errors.withInRadius) {
    try {
      let json = GetJson(rowsCount, 0);
      if(json && json.length > 0){
        SetShowWorking(true);
        const data: any = await CustomerGroupAPI.GetInvoices(JSON.parse(json));
        SetInvoiceData(data);
        if (pageNumber == 0) {
          SetTotalRows(typeof (data) == 'undefined' || data.length == 0 ? 0 : data[0].RowsCount);
        }
      }
      SetShowWorking(false);
      SetShowFilter(false);
    }
    catch (error) {
      SetShowWorking(false);
    }
  }
  }


  function HandleCustomerTypes(customertypes: any) {
    //alert(customertypes);
    SetCustomerTypes(customertypes);
  }
  async function GetManufacturers() {
    const data: any = await CustomerGroupAPI.GetDealerManufacturer();
    //SetManufacturers(data);
    console.log('manufacturer');
    console.log(data);
  }

  async function GetDealerLocations() {
    const data: any = await CustomerGroupAPI.GetDealerLocations();
    if (typeof (data) == 'undefined') { return ''; }
    SetDealerLocations(data);
    SetDealerLocationId(0);
    SetIsDealerLocation(true);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
        display: "table",
        padding: "0px 5px",
      },
    },
  };
  async function downloadData() {
    let json = GetJson(totalRows, 1);
    const response: any = await CustomerGroupAPI.CreateInvoiceExportFile(JSON.parse(json));
    if (typeof (response) == 'undefined' || response == null) { return ''; }
    window.location.href = response;
  }


  function HandleSearchDataChange(e: any, searchType: string) {
    switch (searchType) {
      case 'search':
        SetSearchData(e.target.value);
        break;
      case 'AmountSpent':
        SetAmountSpent(e.target.value)
        break;
      // case 'withInRadius':
      //   SetWithInRadius(e.target.value)
      //   break;

      default:
      // code block
    }

    clearTimeout(mySearchTimeOut);
    mySearchTimeOut = setTimeout(SearchData, 1000, e.target.value);
  }

  function HandleSearchDataChange2(e: any) {

    SetSearchData2(e.target.value);
    clearTimeout(mySearchTimeOut);
    mySearchTimeOut = setTimeout(SearchData, 1000, e.target.value);
  }


  function SearchData(value: string) {
    SetSearchText(value);
  }

  function onRowClick(row: any) {
    SetSelectedRow(row);
    SetSelectedInvoiceId(row.InvoiceId);
    handleOpenInvoiceDrawer();
  }

  function handleInvoiceDrawerClose(): void {
    setOpenInvoiceDrawer(false);
  }

  function handleOpenInvoiceDrawer(): void {
    setOpenInvoiceDrawer(true);
  }

  function handleChangePage(_: unknown, newPage: number): void {
    SetPageNumber(newPage);
  }

  function OnCompanyChange(e: any) {
    if (typeof (e.target.value) == 'undefined') return;
    SetCompanyId(e.target.value);
    SetDealerLocationId(e.target.value);
  }
  function GetCompanyData() {
    let data_filter = dealerLocations.filter((x: any) => x.DealerId === x.CompanyDealerId);
    return data_filter;
  }

  function GetLocationData() {
    if (typeof (dealerLocations) == 'undefined') { return ''; }
    let data_filter = dealerLocations.filter((x: any) => x.CompanyDealerId === companyId);
    return data_filter;
  }

  const handleSortChange = (property: any) => (event: any) => {
    const isAsc = orderBy === property && orderByDirection === 'asc';
    setOrderByDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
}

  React.useEffect(() => {
      SetTotalRows(-1);
      SetPageNumber(0);
      if (pageNumber == 0) { GetInvoices(-1) }
  }, [isDealerLocation]);

  React.useEffect(() => {
    GetInvoices(totalRows);
  }, [windowWidth, pageNumber, rowsPerPage,orderBy,orderByDirection]);

  const cleanupAndUpdateValues = async () => {
    if (formik.values.withInRadius) {
      let cleanedWithinRadiusValue = String(Math.floor(Number(formik.values.withInRadius)));
      formik.setFieldValue('withInRadius', cleanedWithinRadiusValue);
    }
  };  

  React.useEffect(() => {
    cleanupAndUpdateValues();
  }, [formik.values.withInRadius]); 

  return (
    //  <Container style={{ float: "left", border: "0px solid red", width: "99%", maxWidth: "99%", margin: "5px", padding: "0px" }}  ></Container>
    //  <Container style={{ float: "left", border: "0px solid red", width: "99%", maxWidth: "99%", margin: "5px", padding: "0px" , marginLeft:"20px", marginTop:"20px"}}  >
    //  <Container style={{ float: "left",  marginLeft:window.innerWidth > 1100?"20px":"5px", marginTop:window.innerWidth > 1100?"20px":"5px"}}>
    // <Container style={{ marginTop: "10px" }}>
    <Box display="flex" justifyContent="center" alignItems="center" >
      <Box>

        {/* <Container style={{ marginTop: "10px" }}> */}
        {/* Width: {windowWidth} */}
        <table>
          <tr>
            <td><PageHeader title="Orders" /></td>
            <td style={{ width: "100%", paddingLeft: "20px" }}>{(showWorking) && (<CircularProgress size={20} />)}</td>
          </tr>
        </table>


        {/* <Box sx={{ display: 'flex' }}> */}

        <Box my={2} component="section">


          <table style={{ width: "100%" }}>
            <tr>
              <td>
                <Paper className={classes.paper}>

                  <div style={{ display: 'flex', flexDirection: 'row', border: "0px solid blue" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>


                      <TableContainer style={{ border: "0px solid #C8CCCF" }}>

                        {/* <Table stickyHeader size="small" className={classes.myTable}> */}
                        <Table stickyHeader size="small" className={classes.myTable}>
                          <TableHead>
                            <TableRow>
                              <TableCell key={headCells.DisplayInvoiceId}>
                                <TableSortLabel 
                                active={orderBy === headCells.DisplayInvoiceId}
                                direction={orderBy === headCells.DisplayInvoiceId ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.DisplayInvoiceId)}
                                >
                                  Invoice#
                                </TableSortLabel>
                              </TableCell>
                              <TableCell key={headCells.InvoiceStatus}>
                              <TableSortLabel 
                                 active={orderBy === headCells.InvoiceStatus}
                                 direction={orderBy === headCells.InvoiceStatus ? orderByDirection : 'asc'}
                                 onClick={handleSortChange(headCells.InvoiceStatus)}
                                >
                                  Status
                                </TableSortLabel>
                                </TableCell>
                              <TableCell key={headCells.InvoiceDate} className={classes.myColumnMini} >
                              <TableSortLabel 
                                active={orderBy === headCells.InvoiceDate}
                                direction={orderBy === headCells.InvoiceDate ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.InvoiceDate)}
                                >
                                  Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell key={headCells.InvoiceType}>
                              <TableSortLabel 
                                active={orderBy === headCells.InvoiceType}
                                direction={orderBy === headCells.InvoiceType ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.InvoiceType)}
                                >
                                  Type
                                </TableSortLabel>
                                </TableCell>
                              <TableCell >
                              <TableSortLabel 
                                active={orderBy === headCells.DisplayCustomerName}
                                direction={orderBy === headCells.DisplayCustomerName ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.DisplayCustomerName)}
                                >
                                  Customer
                                </TableSortLabel>
                              </TableCell>
                              <TableCell key={headCells.DisplayAddress} className={classes.myColumnMini}>
                              <TableSortLabel 
                                active={orderBy === headCells.DisplayAddress}
                                direction={orderBy === headCells.DisplayAddress ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.DisplayAddress)}
                                >
                                  Address
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={classes.myColumnMiniX} >Address</TableCell>
                              {/* <TableCell className={classes.myColumnMini}>City</TableCell>
                              <TableCell className={classes.myColumnMini}>State</TableCell>
                              <TableCell className={classes.myColumnMini}>Zip</TableCell> */}
                              <TableCell key={headCells.CellPhone}>
                              <TableSortLabel 
                                active={orderBy === headCells.CellPhone}
                                direction={orderBy === headCells.CellPhone ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.CellPhone)}
                                >
                                  Cell
                                </TableSortLabel>
                              </TableCell>
                              <TableCell key={headCells.Email} className={classes.myColumnMini}>
                              <TableSortLabel 
                                active={orderBy === headCells.Email}
                                direction={orderBy === headCells.Email ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.Email)}
                                >
                                  Email
                                </TableSortLabel>
                              </TableCell>
                              {/* if (dealerLocationId == 0){
                                <TableCell className={classes.myColumnMini}>Location</TableCell>
                              } */}
                              {dealerLocationId == 0 && showFilter == false && (
                                <TableCell key={headCells.LocationName} className={classes.myColumnMini}>
                                 <TableSortLabel 
                                active={orderBy === headCells.LocationName}
                                direction={orderBy === headCells.LocationName ? orderByDirection : 'asc'}
                                onClick={handleSortChange(headCells.LocationName)}
                                >
                                  Location
                                </TableSortLabel>
                                </TableCell>
                              )}

                            </TableRow>
                          </TableHead>
                          {typeof (invoiceData) !== 'undefined' && invoiceData != null && (
                            <TableBody>
                              {invoiceData.map((row: any) => {
                                let formattedPhoneNumber = formatPhoneNumber(row.CellPhone);
                                let invoiceDate = new Date(row.InvoiceDate).toLocaleDateString();
                                let email: string = row.Email;
                                if (email != null && email != undefined && email.length > 40) email = email.substring(0, 35) + '....';
                                let InvoiceId = row.InvoiceId;
                                if (store.getState().user.dmsTypeId != 1) InvoiceId = row.InvoiceNumber;
                                if (row.OrderId > 0) {
                                  InvoiceId = row.InvoiceNumber;
                                  if (InvoiceId == 0) InvoiceId = row.OrderId;
                                }
                                let customerAddress=row.Address + ' ' + row.City + ' ' + row.StateProvince + ' ' + row.ZipPostal;


                                return (
                                  <TableRow hover onClick={() => onRowClick(row)}>
                                    {/* <TableCell><span className={classes.labelLink} > {InvoiceId}<span className={classes.myColumnMiniX}>{invoiceDate}</span></span> </TableCell> */}
                                    <TableCell><span className={classes.labelLink} > {row.DisplayInvoiceId}<span className={classes.myColumnMiniX}>{invoiceDate}</span></span> </TableCell>
                                    {/* <TableCell> {row.InvoiceNumber==0?"Open":"Closed"}</TableCell> */}
                                    <TableCell> {row.InvoiceStatus}</TableCell>
                                    <TableCell className={classes.myColumnMini}> {invoiceDate}</TableCell>
                                    <TableCell> {row.InvoiceType}</TableCell>
                                    {/* <TableCell className={classes.noWrap}> {row.Name}<span className={classes.myColumnMini}> ({row.CustomerId}) </span></TableCell> */}
                                    <TableCell className={classes.noWrap}>{row.DisplayCustomerName}</TableCell>
                                    {/* <TableCell className={classes.myColumnMini}> {customerAddress}- {row.DisplayAddress}</TableCell> */}
                                    <TableCell className={classes.myColumnMini}> {row.DisplayAddress}</TableCell>
                                    <TableCell className={classes.noWrap} > {formattedPhoneNumber}<span className={classes.myColumnMiniX}><EmailLink email={row.Email}/></span></TableCell>
                                    <TableCell className={classes.myColumnMini}><EmailLink email={email} /> </TableCell>
                                    {dealerLocationId == 0 && showFilter == false && (
                                      <TableCell className={classes.myColumnMini}> {row.LocationName}</TableCell>
                                    )}

                                    {/* if (dealerLocationId == 0){
                                
                              } */}

                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {typeof (invoiceData) != 'undefined' && (
                        <TablePagination
                          size="small"
                          rowsPerPageOptions={[10, 15, 20, 25, 30]}
                          count={totalRows}
                          rowsPerPage={rowsPerPage}
                          page={pageNumber}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={(e) => { SetRowsPerPage(e.target.value) }}
                        />
                      )}



                      {typeof (invoiceData) == 'undefined' && (
                        <div style={{ color: "orangered", fontFamily: "Quicksand, sans-serif", border: "0px solid silver", padding: "4px 10px", display: "inline-block !important" }}>No data found...</div>
                      )}

                    </div>
                    {/* kg01 */}

                  </div>
                </Paper>
              </td>
              <td valign="top" >
                <table>
                  <tr>
                    <td>
                      <Tooltip title="Filter Orders" placement='top'>
                      <Fab color="primary" onClick={() => SetShowFilter(true)} style={{ marginLeft: "10px" }} >
                        <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                          <FilterAltIcon />
                          <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                        </Box>
                      </Fab>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {typeof (invoiceData) != 'undefined' && invoiceData.length > 0 && (
                        <Tooltip title="Download Orders">
                        <Fab color="primary" onClick={() => downloadData()} style={{ marginLeft: "10px" }} >
                          <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                            <DownloadIcon />
                            <Typography style={{ fontSize: '7.5px', marginTop: '-12px' }}>Download</Typography>
                          </Box>
                        </Fab>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

          </table>
          {openInvoiceDrawer && (
            <CustomerInvoiceDrawer open={openInvoiceDrawer} onClose={handleInvoiceDrawerClose} invoiceId={selectedRow.InvoiceId} dealerId={selectedRow.DealerId} invoiceRow={selectedRow} >
            </CustomerInvoiceDrawer>
          )}

        </Box>
        <Box>
          <Drawer
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
            }}
            anchor="right"
            open={showFilter}
            onClose={() => SetShowFilter(false)}
            variant={'temporary'}
          >
            <DrawerToolbar title="Orders Filter" onClose={() => SetShowFilter(false)} />
            <Container>
              <div style={{ display: 'flex', flexDirection: 'column', float: "right", borderLeft: "0px solid silver", paddingLeft: "10px", marginTop: "5px" }}>
                {GetCompanyData().length > 1 && (
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">Company</InputLabel>
                    <Select

                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={companyId}
                      label="Company"
                      onChange={OnCompanyChange}
                    >
                      {
                        GetCompanyData().map((row: any) => (
                          <MenuItem className={classes.mySelect} value={row.DealerId}>{row.Name}</MenuItem>
                        ))
                      }

                    </Select>
                  </FormControl>
                )}
                <TextField
                  select
                  type="select"
                  id="dealerLocationId"
                  name="dealerLocationId"
                  margin="dense"
                  value = {dealerLocations.length == 1 ? dealerLocations[0].CompanyDealerId : dealerLocationId}
                  onChange={(e) => { SetDealerLocationId(e.target.value) }}
                  disabled={GetLocationData().length == 1 ? true : false}
                  variant="outlined">
                  {
                    GetLocationData().map((row: any) => (
                      <MenuItem value={row.DealerId} className={classes.default} >{row.DealerLocationNickName}</MenuItem>
                    ))
                  }
                  <MenuItem value={0} className={classes.default} >{'All Locations'}</MenuItem>
                </TextField>

                <fieldset style={{ border: "1px solid silver", margin: "0px", padding: "3px", fontSize: "small",borderRadius:"4px" }} >
                  <legend>Transaction Date Between</legend>
                  <table>
                    <tr>
                      <td>
                        <TextField
                          id="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => { setStartDate(e.target.value) }}
                          defaultValue={startDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: 130 }}
                        />

                      </td>
                      <td>
                        <TextField
                          id="enddate"
                          type="date"
                          value={endDate}
                          defaultValue={endDate}
                          onChange={(e) => { setEndDate(e.target.value) }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: 130, paddingLeft: "10px" }}
                        />
                      </td>

                    </tr>

                  </table>

                </fieldset>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box>
                      <TextField
                        fullWidth
                        select
                        type="select"
                        id="invoice-types"
                        name="invoice-types"
                        margin="dense"
                        value={invoiceType2}
                        onChange={(e) => { setInvoiceType2(e.target.value) }}
                        variant="outlined">
                        <MenuItem value={0}>All Types</MenuItem>
                        <MenuItem value={1}>Open</MenuItem>
                        <MenuItem value={2}>Closed</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <TextField
                        fullWidth
                        select
                        type="select"
                        id="invoice-type"
                        name="invoice-type"
                        margin="dense"
                        value={invoiceType}
                        onChange={(e) => { setInvoiceType(e.target.value) }}
                        variant="outlined">
                        <MenuItem value={0}>All Invoices</MenuItem>
                        <MenuItem value={3}>Parts</MenuItem>
                        <MenuItem value={4}>Wholegoods</MenuItem>
                        <MenuItem value={2}>Service</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>

                </Grid>
                <Box>
                  <table style={{ border: "0px solid red", marginLeft: "-3px", marginRight: "-3px", padding: "0px" }} className={classes.myTable} >
                    <tr>
                      <td style={{width:"50%",paddingRight:"3px"}} >
                        <TextField
                          fullWidth
                          style={{ border: "0px solid green", margin: "0px" }}
                          select
                          type="select"
                          id="searchType"
                          name="searchType"
                          margin="dense"
                          value={searchType}
                          onChange={(e) => { SetSearchType(e.target.value) }}
                          variant="outlined">
                          <MenuItem value='x'>Search By...</MenuItem>
                          <MenuItem value='customer'>Customer</MenuItem>
                          {/* <MenuItem value='customercategory'>Customer Category</MenuItem> */}
                          <MenuItem value='city'>City</MenuItem>
                          <MenuItem value='part'>Part#</MenuItem>
                          <MenuItem value='model'>Model#</MenuItem>
                          <MenuItem value='mfr'>Line Code</MenuItem>
                          <MenuItem value='linename'>Line Name</MenuItem>
                          <MenuItem value='serialnumber'>Serial#</MenuItem>
                        </TextField>

                      </td>
                      <td style={{width:"50%",paddingLeft:"3px"}}>
                        <TextField
                          style={{ border: "0px solid green", marginLeft: "0px", padding: "0px", margin: "0px" }}
                          type="text"
                          id="searchData"
                          name="searchData"
                          margin="dense"
                          value={searchData}
                          onChange={(e) => { HandleSearchDataChange(e, 'search') }}
                          variant="outlined"
                          label="Keywords"
                          >
                          
                        </TextField>
                      </td>
                    </tr>
                  </table>

                </Box>
                <Box>
                  {<FormControl variant="filled" classes={{ root: classes.customOutline }} style={{ border: "0px solid red", padding: "0px",minWidth:'49%' }} >
                    <Select
                      disableUnderline={true}
                      variant="filled"
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={customerTypes}
                      className={classes.defaultSelect}
                      onChange={(e) => { SetCustomerTypes(e.target.value); }}
                      input={<Input id="select-multiple-checkbox" classes={classes.customOutline} />}
                      // renderValue={(selected: any) => selected.join(', ')}
                      renderValue={CustomerTypesRender}
                      margin="none"
                      autoWidth={true}
                      style={{ border: "1px solid silver", padding: "2px", paddingLeft: "13px", marginTop: "5px",borderRadius:"4px" }}

                      MenuProps={MenuProps}

                    >
                      {customerTypeData.map((row: any) => (
                        <MenuItem key={row.keyId} value={row.keyId} className={classes.default} style={{ fontSize: "small", border: "0px solid red", padding: "0px", margin: "0px" }} >
                          <Checkbox checked={customerTypes.indexOf(row.keyId) > -1} style={{ padding: "2px", margin: "0px" }} />
                          {row.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>}
                </Box>
                {/* <table style={{ border: "0px solid red", marginLeft: "-2px", padding: "0px" }} className={classes.myTable} > */}
                  <tr style={{borderBottom: "1px solid #EAEDED", marginTop: "1px"}}>
                    <td style={{width:"50%",paddingLeft: "0px",paddingRight:"3px"}}>              <TextField
                      // fullWidth
                      variant="outlined"
                      size="small"
                      margin="dense"
                      type="number"
                      label="Within Miles"
                      name="withInRadius"
                      id="withInRadius"
                      onBlur={formik.handleBlur}
                      // onChange={(e) => { SetWithInRadius(e.target.value) }}
                      // onChange={(e) => { HandleSearchDataChange(e, 'withInRadius') }}
                      onChange={formik.handleChange}
                      value={formik.values.withInRadius}
                      error={Boolean(formik.errors.withInRadius)}
                      helperText= {formik.errors.withInRadius}
                      style={{ marginBottom: "5px" }}
                      autoComplete="off"
                    />
                    </td>
                    <td style={{width:"50%",paddingLeft:"3px"}}>
                      <TextField
                        // fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        type="number"
                        label="Minimum Spent"
                        name="withInRadius"
                        // onChange={(e) => { SetAmountSpent(e.target.value) }}
                        onChange={(e) => { HandleSearchDataChange(e, 'AmountSpent') }}
                        value={amountSpent}
                        style={{marginBottom: "5px" }}
                      />




                    </td>
                    {/* <td>
                  {<HelpOutlineIcon></HelpOutlineIcon>}
                  </td> */}
                  </tr>
                {/* </table> */}


                <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "15px" }} >
                  <Button color="primary" variant="contained" onClick={() => GetInvoices(totalRows)}>Apply Filter</Button>
                </Box>



                {/* <Box>
                <Button color="primary" variant="contained" onClick={downloadData}>Download CSV</Button>
              </Box> */}



              </div>
            </Container>

          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}
