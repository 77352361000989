import { Button } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { formatPhoneNumber } from 'common/utils';
import SuccessCheckIcon from 'common/components/icons/success-check';
import ErrorCancelIcon from 'common/components/icons/error-cancel';
import { SmallCircularProgress } from 'common/components/loaders';
import CustomerInvoiceDrawer from "./customer-invoice/index";
import EmailLink from 'common/components/buttons/email-link';
import { useAppSelector } from 'app';

// import FilterDrawer from './filter-drawer';
//src\components\customer-groups\components\customer-group\customers-table\customer-invoice


interface Column {
  id: 'customerId' | 'name' | 'address' | 'city' | 'stateProvince' | 'zipPostal' | 'phoneNumber' | 'invoiceDate' | 'email';
  label: string;
  align?: 'right';
  format?: (column: Data) => React.ReactElement;
}

const columns: Column[] = [
  // { id: 'customerId', label: 'Id' },
  {
    id: 'name', label: 'Name',
    format(column: Data): React.ReactElement {
      const formattedPhoneNumber = column.invoiceDate;
      return (
        <Box display="inline-flex" alignItems="center">
          <Box mr={1}>{column.name + ' (' + column.customerId + ')'}</Box>
        </Box>
      );
      return <Box>N/A</Box>;
    }
  },
  { id: 'address', label: 'Address' },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'stateProvince',
    label: 'State',
  },
  {
    id: 'zipPostal',
    label: 'Zip',
  },
  {
    id: 'phoneNumber',
    label: 'Cell-Phone',
    format(column: Data): React.ReactElement {
      if (column.phoneNumber) {
        const formattedPhoneNumber = formatPhoneNumber(column.phoneNumber);
        return (
          <Box display="inline-flex" alignItems="center">
            <Box mr={1}>{formattedPhoneNumber}</Box>
            {column.twilioVerificationStatus === 1 ? <SuccessCheckIcon /> : column.twilioVerificationStatus === 0 ?<ErrorCancelIcon/> : null }
          </Box>
        );
      }
      return <Box>N/A</Box>;
    },

  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'invoiceDate',
    label: 'Order #/Date',
    format(column: Data): React.ReactElement | any {
        if (column.invoiceNumber && column.invoiceDate) {
        return (
          <Box display="inline-flex" alignItems="center">
            <Box id='boxInvoice' className={column.isInvoicePermission == 1 ? "boxInvoice" : "boxNoInvoice"} mr={1} >{column.invoiceNumber + ' (' + new Date(column.invoiceDate).toLocaleDateString() + ')'}</Box>
          </Box>
        );
      }
      return '';
    },
  },
];

interface Data {
  customerId: number;
  invoiceId: number;
  invoiceNumber: number;
  address: string;
  city: string;
  crmCustomerId: number;
  invoiceDate: string;
  name: string;
  phoneNumber: string;
  twilioVerificationStatus?: number;
  stateProvince: string;
  zipPostal: string;
  email: string;
  amount?: DoubleRange;
  isInvoicePermission?: number;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  selectedRow: {
    backgroundColor: '#F9EBEA',
  },
  customTable: {
    
    // "& .MuiTableCell-sizeSmall": {
    //   // padding: "2px 0px 0px 8px" // <-- arbitrary value
    // },
    '& thead tr th': {
      padding: "5px 5px",
      borderTop:"1px solid silver"
    },
    '& tbody tr:hover': {
      backgroundColor: '#F2F3F4',
   },

    '& tbody tr td': {
      padding: "3px 6px",
    },
    "& .boxInvoice":{
      border:"1px solid silver", 
      margin:"0px", 
      padding:"1px 5px", 
      cursor:"url(hand.cur), pointer"
    },
    "& .boxInvoice:hover":{
      backgroundColor: '#D5F5E3',
      color:"brown",
      border:"1px solid gray"
    },
    "& .boxNoInvoice":{
      border:"1px solid silver", 
      margin:"0px", 
      padding:"1px 5px",       
    }    

  
  }
    
    ,
  buttonColumn:{
    backgroundColor:"red",
  }
});

interface Props {
  pageNumber: number;
  pageSize: number;
  loading: boolean;
  customers: Data[];
  count: number;
  onPageChange(newPage: number): void;
}
export default function CustomerTable({
  customers,
  pageNumber,
  pageSize,
  loading,
  count,
  onPageChange,
}: Props): React.ReactElement {
  const classes: any = useStyles();
  const [selectedRow, SetSelectedRow] = React.useState<any>([]);
  const [selectedInvoiceId, SetSelectedInvoiceId] = React.useState(0);
  const [openInvoiceDrawer, setOpenInvoiceDrawer] = React.useState<boolean>(false);
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  let isInvoicePermission = 0;
  if (!userPermissions) {
    userPermissions = [];
    isInvoicePermission = 2;
  }
  if (isInvoicePermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'invoice')) {
      isInvoicePermission = 1;
    } 
  }

  function handleInvoiceDrawerClose(): void {
      setOpenInvoiceDrawer(false);
  }

  function handleOpenInvoiceDrawer(): void {
      setOpenInvoiceDrawer(true); 
  }


  function handleChangePage(_: unknown, newPage: number): void {
    onPageChange(newPage);
  }

  function onRowClick(row: any) {
    SetSelectedRow(row);
    SetSelectedInvoiceId(row.invoiceId);
    if (isInvoicePermission == 1) {
      handleOpenInvoiceDrawer();
    }    
  }

  return (
    <Paper className={classes.root}>

      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" classes={{root: classes.customTable}}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {customers.map((row) => {
                let classNameSelected = row.invoiceId == selectedInvoiceId ? classes.selectedRow : '';
                row.isInvoicePermission = isInvoicePermission;
                return (
                  <TableRow tabIndex={-1} key={row.crmCustomerId} className={classNameSelected} onClick={() => onRowClick(row)}  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if(column.id=='email')
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value?<EmailLink email={value.toString()}/>:value}                         
                      </TableCell>
                      )
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(row) : value }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow style={{ height: 10 * 15, width: '100%' }}>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  <SmallCircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {customers.length === 0 && !loading ? (
            <TableBody>
              <TableRow style={{ height: 10 * 15, width: '100%' }}>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  <Typography variant="h5">No Customers</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
      <TablePagination
        size="small"
        rowsPerPageOptions={[pageSize]}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
      />
      {openInvoiceDrawer && (
        <CustomerInvoiceDrawer open={openInvoiceDrawer} onClose={handleInvoiceDrawerClose} invoiceId={selectedRow.invoiceId} dealerId={selectedRow.invoiceDealerId} invoiceRow={selectedRow} >

        </CustomerInvoiceDrawer>
      )}
    </Paper>


  );

}
