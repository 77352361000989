import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useAppDispatch, useAppSelector } from 'app';
import { SmallCircularProgress } from 'common/components/loaders';
import useDebounce from 'common/hooks/useDebounce';
import { CustomerContactDto, CustomerDto } from 'common/models/customer';
import { formatPhoneNumber, getConversationFormattedDate, getConversationFormattedDateNew } from 'common/utils';
import formatAvatarString from 'common/utils/format-string';
import isPhoneNumber from 'common/utils/is-phone-number';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import useUserDepartmentSettings from 'components/layouts/DashboardLayout/useUserDepartmentSettings';
import InitialsAvatar from 'components/messaging/components/initials-avatar';
import { showSnackbar } from 'components/root-snackbar';
import { UserDepartment } from 'components/setting/models/user-department-settings';
import { useFormik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import MessagingAPI from '../api/messaging-api';
import { Conversation, CustomerContactPhoneNumberResponse } from '../models/conversation';
import { NewConversationType } from '../models/new-conversation-type';
import CustomerPhoneApi from '../../../components/customer/api/CustomerPhoneAPI';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@mui/icons-material/Business';
import Chip from '@mui/material/Chip';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide
      keepCharPositions
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

function useCustomerSearch(searchText: string): { status: string; customers: any[] | undefined } {
  const [customers, setCustomers] = useState<CustomerContactDto[] | undefined>();
  const [status, setStatus] = useState<'success' | 'loading' | 'error'>('loading');
  const dealerId = useAppSelector((state) => state.user.dealerId);
  useEffect(() => {
    const pageNumber = 0;
    const pageSize = 100;
    const fetchCustomers = async (): Promise<void> => {
      if (searchText) {
        try {
          setStatus('loading');
          const data = await CustomerAPI.searchCustomersAndContacts(dealerId, pageNumber, pageSize, searchText);
          if (data) {
            setCustomers(data.customerAndContacts);
            setStatus('success');
          }
        } catch (error) {
          setStatus('error');
        }
      }
    };
    fetchCustomers();
  }, [dealerId, searchText]);
  return {
    status,
    customers,
  };
}

function StartConversationSearchList({
  searchText,
  loading,
  onSelect,
  tag,
  setSelectedPhoneNumber,
}: {
  tag?: string;
  loading: boolean;
  searchText: string;
  onSelect: (type: NewConversationType, data: CustomerContactDto | string) => void;
  setSelectedPhoneNumber: (phoneNumber: string) => void;
}): ReactElement | null {
  const { status, customers } = useCustomerSearch(searchText);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  let customerSearchResult = null;
  const schema = Yup.object<any>().shape<any>({
    newCustomerPhone: Yup.string().min(10, 'Phone Number must be 10 digit.').required('Phone Number must be 10 digit.'),
  });

  const formik = useFormik({
    initialValues: {
      newCustomerPhone: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {},
  });

  function handleNewCustConv() {
    if (formik.values.newCustomerPhone.replace(/\s/g, '').length === 13) {
      const customerObj: CustomerContactDto = {
        crmCustomerId: 0,
        customerId: 0,
        customerName: '',
        contactId: 0,
        contactName: '',
        phoneId: 0,
        isOrganization: false,
        organizationName: '',
        phoneNumber: formik.values.newCustomerPhone,
        isAspenPrimaryContact: false,
      };
      setSelectedPhoneNumber(formik.values.newCustomerPhone);
      onSelect(NewConversationType.NewCustomerConversation, customerObj);
      formik.setFieldValue('newCustomerPhone', '');
    } else {
      formik.setFieldValue('newCustomerPhone', '');
    }
  }

  function handleNewPhoneConv() {
    setSelectedPhoneNumber(searchText);
    onSelect(NewConversationType.NewCustomerConversation, searchText);
  }

  if (status === 'success' && customers) {
    let startConversationWithNumber = null;
    if (isPhoneNumber(searchText)) {
      startConversationWithNumber = (
        <ListItem button onClick={(): void => handleNewPhoneConv()}>
          <ListItemIcon>
            <AddCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={`Add new customer with cellphone ${formatPhoneNumber(searchText)}`} />
          {loading && (
            <ListItemIcon>
              <SmallCircularProgress />
            </ListItemIcon>
          )}
        </ListItem>
      );
    }

    const customerListItems = customers.map((customerContact: CustomerContactDto) => (
      <ListItem
        button
        key={customerContact.customerId}
        onClick={(): void => onSelect(NewConversationType.CustomerConversation, customerContact)}>
        <ListItemIcon>
          <InitialsAvatar
            name={formatAvatarString(customerContact.customerName)}
            maxInitials={2}
            textSizeRatio={1.75}
            size="30"
            round
          />
        </ListItemIcon>
        <Grid container>
          {customerContact.isOrganization &&
          ((dmsTypeId === 0 && customerContact.contactId === 0) ||
            (dmsTypeId === 9 && customerContact.isAspenPrimaryContact)) ? (
            <></>
          ) : (
            <>
              <Grid item>
                <PersonIcon />
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {customerContact.contactId === 0 || customerContact.isAspenPrimaryContact
                    ? customerContact.customerName
                    : customerContact.contactName}
                </Typography>
              </Grid>
            </>
          )}
          {/* Below show Organization Name */}
          {(dmsTypeId === 0 || dmsTypeId === 9) && customerContact.isOrganization && customerContact.organizationName && (
            <>
              {(dmsTypeId === 0 && customerContact.contactId === 0) ||
              (dmsTypeId === 9 && customerContact.isAspenPrimaryContact) ? (
                <> </>
              ) : (
                <Grid item>&nbsp;&nbsp;|&nbsp;&nbsp;</Grid>
              )}
              <Grid item>
                <BusinessIcon />
              </Grid>
              <Grid item>
                <Typography variant="button" title={customerContact.organizationName?.toUpperCase()}>
                  &nbsp;
                  {customerContact.organizationName?.length > 15
                    ? customerContact.organizationName?.slice(0, 15).concat('...')
                    : customerContact.organizationName}
                </Typography>
              </Grid>
            </>
          )}
          {/* Below show Group Name */}
          {(dmsTypeId === 1 || ((dmsTypeId === 0 || dmsTypeId === 9) && !customerContact.isOrganization)) &&
            customerContact.contactId !== 0 && !customerContact.isAspenPrimaryContact && (
              <>
                <Grid item>&nbsp;&nbsp;|&nbsp;&nbsp;</Grid>
                <Grid item>
                  <GroupIcon />
                </Grid>
                <Grid item>
                  <Typography variant="button" title={customerContact.customerName?.toUpperCase()}>
                    &nbsp;
                    {customerContact.customerName?.length > 15
                      ? customerContact.customerName?.slice(0, 15).concat('...')
                      : customerContact.customerName}
                  </Typography>
                </Grid>
              </>
            )}
          <Grid item>&nbsp;&nbsp;|&nbsp;&nbsp;</Grid>
          <Grid item>
            <PhoneIcon />
          </Grid>
          <Grid item>
            <Typography variant="button">{formatPhoneNumber(customerContact.phoneNumber)}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    ));

    if (customerListItems.length) {
      startConversationWithNumber = null;
      if (customerListItems.length == 1 && tag != undefined && tag?.indexOf('CrmCustomerId') > -1) {
        let id = tag.replace('CrmCustomerId:', '').trim();
        let data_filter: any = customers.filter((x: any) => x.id == id);
        if (data_filter.length > 0) {
          onSelect(NewConversationType.CustomerConversation, data_filter[0]);
        }
      }
    }
    customerSearchResult = (
      <List component="nav" id="customerList" aria-label="customers list">
        {startConversationWithNumber}
        {customerListItems}
      </List>
    );
  }
  if (customers && customers.length === 0 && !isPhoneNumber(searchText)) {
    return (
      <ListItem button onClick={(): void => handleNewCustConv()}>
        <ListItemIcon>
          <AddCircleIcon color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={`Enter phone number to start conversation with ${searchText}`}
          primaryTypographyProps={{ align: 'left' }}
        />
        <TextField
          autoFocus
          size="small"
          variant="outlined"
          name="newCustomerPhone"
          margin="dense"
          error={!!formik.errors.newCustomerPhone}
          helperText={formik.errors.newCustomerPhone}
          style={{ width: 300 }}
          InputProps={{
            inputComponent: TextMaskCustom as any,
          }}
          // onKeyPress={(e) => {
          //   if (formik.values.newCustomerPhone.length > 9 || e.code.toString().includes('Key')) {
          //     e.preventDefault();
          //   }
          // }}
          onChange={formik.handleChange}
          value={formik.values.newCustomerPhone}></TextField>
        {loading && (
          <ListItemIcon>
            <SmallCircularProgress />
          </ListItemIcon>
        )}
      </ListItem>
    );
  }
  if (status === 'loading') {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <SmallCircularProgress />
      </Box>
    );
  }
  return customerSearchResult;
}

const useNewConversationPanelStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    height: '-webkit-fill-available',
  },
  menu: {
    overflowY: 'auto',
  },
}));

function NewConversationPanel({
  onCancel,
  onStart,
  onContinue,
  loading,
  defaultSearch = '',
  tag,
  disableSearch,
}: {
  disableSearch?: boolean;
  tag?: string;
  defaultSearch?: string;
  loading: boolean;
  onCancel: () => void;
  onContinue: (conversation: Conversation) => void;
  onStart: (
    type: NewConversationType,
    data: CustomerContactDto | string,
    departmentId: number,
    selectedPhoneNumberId: number,
  ) => Promise<void>;
}): ReactElement {
  const classes: any = useNewConversationPanelStyles();
  const [searchText, setSearchText] = useState(defaultSearch);
  const [loadingOldConversations, setLoadingOldConversations] = React.useState(false);
  const [startingNewConversation, setStartingNewConversation] = React.useState(false);
  const debouncedSearchText = useDebounce(searchText, 1000);
  const [existingConversations, setExistingConversations] = React.useState<Conversation[]>([]);
  const [createConversationData, setCreateConversationData] = React.useState<
    { type: NewConversationType; data: CustomerContactDto | string } | undefined
  >(undefined);
  const [customerContactPhoneNumbers, setCustomerContactPhoneNumbers] = React.useState<
    CustomerContactPhoneNumberResponse[] | undefined
  >(undefined);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState<string>('');
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = React.useState<number>(0);
  const crmUserId = useAppSelector((state) => state.user.crmUserId);
  const { assignedDepartments, selectedDepartmentIds } = useUserDepartmentSettings(crmUserId);

  const dispatch = useAppDispatch();
  function handleOnCustomerSearchChange(event: any): void {
    if (createConversationData === undefined) {
      setSearchText(event.target.value);
    }
  }

  function handleKeyBoardButton(): void {
    onCancel();
  }

  function handleCancelCreateConversation(): void {
    setCreateConversationData(undefined);
    setExistingConversations([]);
  }

  function getName(data: CustomerContactDto | string): string {
    if (typeof data === 'string') {
      return data;
    }
    const name =
      data.contactId === 0 || data.isAspenPrimaryContact
        ? data.customerName
        : data.customerName + ' - ' + data.contactName;
    return name;
  }

  async function onPhoneNumberSelected(phone: any, contactName = '') {
    try {
      setSelectedPhoneNumber(phone.phoneNumber);
      setSelectedPhoneNumberId(phone.phoneId);
      setCustomerContactPhoneNumbers(undefined);

      if (customerContactPhoneNumbers && customerContactPhoneNumbers.length > 0) {
        const customerName = customerContactPhoneNumbers?.find(
          (x) => x.isCustomer || x.phoneNumbers?.find((x) => x.isPrimaryCellPhone),
        )?.customerName;
        const selectedName = contactName ? `${customerName} - ${contactName}` : customerName ?? '';
        setSearchText(selectedName);
      }

      setLoadingOldConversations(true);
      const response = await MessagingAPI.getConversationsByPhoneNumberId(phone.phoneId);
      if (response) {
        setExistingConversations(response);
      } else {
        setExistingConversations([]);
      }
      setLoadingOldConversations(false);
    } catch (error) {
      setLoadingOldConversations(false);
      console.log('No existing conversation found');
    }
  }

  async function fetchCustomerContactPhoneNumbers(CrmCustomerId: number, contactId: number) {
    const response = await CustomerPhoneApi.getCustomerContactPhoneNumbers(CrmCustomerId);
    if (response) {
      const contactPhoneNumbers = response.find(
        (x) => contactId != 0 && x.id === contactId && !x.isAspenPrimaryContact,
      )?.phoneNumbers;
      if (response.length === 1 && response[0].phoneNumbers.length === 1) {
        onPhoneNumberSelected(response[0].phoneNumbers[0]);
      } else if (contactPhoneNumbers?.length === 1) {
        //If contact was selected to start conversation with one cellphone
        onPhoneNumberSelected(contactPhoneNumbers[0]);
      } else {
        setCustomerContactPhoneNumbers(response);
      }
    } else {
      setCustomerContactPhoneNumbers(undefined);
    }
  }

  async function handleSelectStartConversation(
    type: NewConversationType,
    data: CustomerContactDto | string,
  ): Promise<void> {
    setCreateConversationData({ type, data });

    setSearchText(getName(data));
    if (type === NewConversationType.CustomerConversation) {
      if (typeof data !== 'string') {
        try {
          setLoadingOldConversations(true);
          await fetchCustomerContactPhoneNumbers(data?.crmCustomerId, data?.contactId);
          setLoadingOldConversations(false);
        } catch (error) {
          setLoadingOldConversations(false);
          console.log('No customer or contact phone number found');
        }
      }
    }
  }

  async function onDepartmentSelected(departmentId: number): Promise<void> {
    if (createConversationData) {
      if (createConversationData.data && typeof createConversationData.data !== 'string') {
        createConversationData.data.phoneNumber = selectedPhoneNumber;
      }
      setStartingNewConversation(true);
      await onStart(createConversationData?.type, createConversationData?.data, departmentId, selectedPhoneNumberId);
      setStartingNewConversation(false);
      setSelectedPhoneNumber('');
      setSelectedPhoneNumberId(0);
    }
  }

  function getDepartmentLabel(departmentId: number): string {
    let label = '';
    const department = assignedDepartments.find((x) => x.departmentId === departmentId);
    if (department) {
      label = `${department.dealerLocationName} - ${department.departmentName}`;
    }
    return label;
  }

  function filterAlreadyUsedDepartments(assignedDepartment: UserDepartment): boolean {
    let exist = true;
    if (existingConversations.find((x) => x.departmentId === assignedDepartment.departmentId)) {
      exist = false;
    }
    return exist;
  }

  let panelMenu = <></>;
  if (createConversationData) {
    if (loadingOldConversations) {
      panelMenu = (
        <Box p={2} display="flex" justifyContent="center">
          <SmallCircularProgress />
        </Box>
      );
    } else if (
      existingConversations[0] &&
      existingConversations[0].customer &&
      existingConversations[0].customer.cellPhone == null
    ) {
      panelMenu = <></>;
      dispatch(showSnackbar({ type: 'error', message: 'Please enter a cell phone number to start a conversation.' }));
    } else if (customerContactPhoneNumbers) {
      if (customerContactPhoneNumbers.length > 0) {
        panelMenu = (
          <>
            <List>
              <ListSubheader style={{ fontWeight: 'bold', position: 'unset' }}>
                Select a phone number to start a conversation:
              </ListSubheader>
              <ListItem>
                <ListItemIcon>
                  <InitialsAvatar
                    name={
                      customerContactPhoneNumbers.find(
                        (x) => x.isCustomer || x.phoneNumbers?.find((x) => x.isPrimaryCellPhone),
                      )?.customerName
                    }
                    maxInitials={2}
                    textSizeRatio={1.75}
                    size="30"
                    round
                  />
                </ListItemIcon>
                <ListItemText>
                  <span style={{ fontWeight: 'bold' }}>
                    {
                      customerContactPhoneNumbers.find(
                        (x) => x.isCustomer || x.phoneNumbers?.find((x) => x.isPrimaryCellPhone),
                      )?.customerName
                    }
                  </span>
                </ListItemText>
              </ListItem>
              {customerContactPhoneNumbers
                .find((x) => x.isCustomer || x.phoneNumbers?.find((x) => x.isPrimaryCellPhone))
                ?.phoneNumbers?.map((phone) => (
                  <ListItem
                    key={phone.phoneId}
                    button
                    onClick={() => {
                      onPhoneNumberSelected(phone);
                    }}>
                    <ListItemIcon style={{ marginLeft: 55 }}>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText style={{ marginLeft: -20 }}>{formatPhoneNumber(phone.phoneNumber)}</ListItemText>
                  </ListItem>
                ))}
            </List>
            {customerContactPhoneNumbers
              .filter((x) => !x.isCustomer && !x.isAspenPrimaryContact)
              .map((item) => (
                <List key={item.id} style={{ paddingLeft: 55 }}>
                  <ListItem>
                    <ListItemIcon>
                      <InitialsAvatar name={item.contactName} maxInitials={2} textSizeRatio={1.75} size="30" round />
                    </ListItemIcon>
                    <ListItemText>
                      <span style={{ fontWeight: 'bold' }}>{item.contactName}</span>
                    </ListItemText>
                  </ListItem>
                  {item.phoneNumbers?.map((phone) => (
                    <ListItem
                      key={phone.phoneId}
                      button
                      onClick={() => {
                        onPhoneNumberSelected(phone, item.contactName);
                      }}>
                      <ListItemIcon style={{ marginLeft: 55 }}>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText inset style={{ marginLeft: -75 }}>
                        {formatPhoneNumber(phone.phoneNumber)}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              ))}
          </>
        );
      } else {
        panelMenu = (
          <Box p={2} display="flex" justifyContent="center">
            <Box>The selected customer has no cellphone number</Box>
          </Box>
        );
      }
    } else if (selectedPhoneNumber !== '') {
      panelMenu = (
        <List>
          {existingConversations
            .filter((x) => selectedDepartmentIds.indexOf(x.departmentId) !== -1)
            .map((existingConversation) => (
              <ListItem
                button
                onClick={(): void => {
                  onContinue(existingConversation);
                  setSelectedPhoneNumber('');
                  setSelectedPhoneNumberId(0);
                }}
                key={existingConversation.id}>
                <ListItemIcon>
                  <InitialsAvatar
                    name={existingConversation.customer.name}
                    maxInitials={2}
                    textSizeRatio={1.75}
                    size="30"
                    round
                  />
                </ListItemIcon>
                <ListItemText
                  secondaryTypographyProps={{ component: 'div' }}
                  primary={`Continue conversation  with ${existingConversation.customer.name} from ${getDepartmentLabel(
                    existingConversation.departmentId,
                  )}`}
                  secondary={
                    <>
                    <Box display="flex">
                      <Box>{existingConversation.latestMessage}</Box>
                      <Box flexGrow={1} />
                      <Box>{getConversationFormattedDateNew(existingConversation.latestDateTime || '')}</Box>
                    </Box>
                    {/* <Box> {existingConversation.isArchive?(<Box display="flex" justifyContent="flex-end" ><Chip size="small" icon={<ArchiveIcon />} label="Archived" /></Box>):null} </Box> */}
                    </>
                  }
                />
              </ListItem>
            ))}
          {assignedDepartments
            .filter((x) => selectedDepartmentIds.indexOf(x.departmentId) !== -1)
            .filter(filterAlreadyUsedDepartments)
            .filter(x=>x.departmentType !='Facebook')
            .map((department) => (
              <ListItem
                key={department.departmentId}
                button
                onClick={(): Promise<void> => onDepartmentSelected(department.departmentId)}>
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText>
                  Start conversation from {department.dealerLocationName} - {department.departmentName}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      );
      if (selectedDepartmentIds.length === 0) {
        panelMenu = (
          <Box p={2} display="flex" justifyContent="center">
            <Box>Please select department(s) to continue</Box>
          </Box>
        );
      }
    }
  } else if (searchText !== '') {
    panelMenu = (
      <StartConversationSearchList
        loading={loading}
        onSelect={handleSelectStartConversation}
        searchText={debouncedSearchText}
        tag={tag}
        setSelectedPhoneNumber={setSelectedPhoneNumber}
      />
    );
  }

  if (startingNewConversation) {
    panelMenu = (
      <Box p={2} display="flex" justifyContent="center">
        <Box pr={1}>Starting new conversation</Box>
        <SmallCircularProgress />
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <AppBar position="relative" style={{ backgroundColor: 'white', color: 'black' }} elevation={0}>
        <Toolbar variant="dense" disableGutters>
          <IconButton id="goBack" onClick={handleKeyBoardButton}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h5">New Conversation</Typography>
        </Toolbar>
      </AppBar>
      <Box px={1}>
        <TextField
          fullWidth
          autoFocus
          autoComplete="off"
          id="searchCustomerField"
          size="small"
          margin="dense"
          variant="outlined"
          value={searchText}
          placeholder="Type a name, phone number"
          onChange={handleOnCustomerSearchChange}
          disabled={disableSearch}
          InputProps={{
            startAdornment: (
              <Box pr={2}>
                <Typography variant="subtitle1">To:</Typography>
              </Box>
            ),
            endAdornment: createConversationData && (
              <IconButton size="small" onClick={handleCancelCreateConversation}>
                <CancelIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Divider />
      <Box className={classes.menu}>
        <></>
        {panelMenu}
      </Box>
    </Box>
  );
}

export default NewConversationPanel;
