/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app';
import { showSnackbar } from 'components/root-snackbar';

import TaskboardAPI from './taskboard-api';
import {
  Task,
  TaskListDoneRequest,
  TaskListObject,
  TaskListRescheduleRequest,
  UserTaskStatus,
} from 'components/lead/models/task';
import DealerAPI from 'components/authentication/api/dealer-api';
import { PersistentAppData } from 'common/models';
export type CustomerDialogType =
  | undefined
  | 'NEW'
  | 'SHOW'
  | 'EDIT'
  | 'DELETE'
  | 'INVOICE'
  | 'SHOW_PHONE_NUMBERS'
  | 'VALIDATE_ALL'
  | 'VALIDATE_EMAIL'
  | 'VALIDATE_PHONE';

export interface addTaskRequest {
  dealerId: number;
  isFollowed: boolean;
  followUpTaskId: number;
  createdByUserId: string;
  assignedUserId: string;
  taskSubject: string;
  taskTypeId: number;
  customerId: number;
  dueDate: string;
  notes: string;
  statusId: number;
  taskOutcomeId: number | null;
  followUpTask: addTaskRequest | null;
  dealId: number | null;
}

export interface RescheduleTaskRequest {
  taskId: number;
  assignedUserId: string;
  taskTypeId: number;
  dueDate: Date;
  notes: string;
  statusId: number | null;
  taskOutcomeId: number | null;
  taskSubject: string;
  dealerId: number;
}

export interface TasksState {
  tasks: TaskListObject[];
  currentPage: number;
  pageSize: number;
  searchString: string;
  createdByUserId: string | null;
  assignedUserIds: string[] | null;
  taskStatusId: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  totalTasks: number;
  taskTypeId: number | null;
  dealerId: number | null;
  loading: boolean;
  error: string | null;
  selectedTasks: TaskListObject[];
  AllTasksSelected: boolean;
  dialogType: CustomerDialogType;
  selectedTask: TaskListObject | undefined;
  drawerType: string | null;
  drawerProps: any | {};
  addTaskLoading: boolean;
  addTaskError: string | null;
  selectedCustomer: number | undefined;
  isArchived: boolean;
}
export interface TaskFilterPersistent {
  assignedUserIds:string[] | null;
  taskStatusId:number | null;
  taskTypeId:number | null;
  isArchived: boolean;
  location:number;
}

let initialState: TasksState = {
  tasks: [],
  currentPage: 0,
  pageSize: 15,
  createdByUserId: null,
  assignedUserIds: null,
  taskStatusId: UserTaskStatus.New,
  fromDate: null,
  toDate: null,
  totalTasks: 0,
  taskTypeId: null,
  dealerId: null,
  searchString: '',
  loading: false,
  error: null,
  selectedTasks: [],
  AllTasksSelected: false,
  dialogType: undefined,
  selectedTask: undefined,
  drawerType: null,
  drawerProps: {},
  addTaskLoading: false,
  addTaskError: null,
  selectedCustomer: 0,
  isArchived: false,
};

const taskboardSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    getTasksStart(state): void {
      state.loading = true;
      state.error = null;
    },
    getTasksSuccess(
      state,
      action: PayloadAction<{ tasks: TaskListObject[]; pageSize: number; currentPage: number; count: number }>,
    ): void {
      const { tasks, pageSize, count, currentPage } = action.payload;
      state.tasks = tasks;
      state.currentPage = currentPage;
      state.pageSize = pageSize;
      state.totalTasks = count;
      state.loading = false;
      state.error = null;
    },
    getTasksFailure(state, action: PayloadAction<string>): void {
      state.loading = false;
      state.error = action.payload;
    },
    toggleTaskStart(state, action: PayloadAction<TaskListObject>): void {
      state.loading = false;
      let indexOfCustomer = 0;
      const c: TaskListObject = state.tasks.filter((x: TaskListObject, index: number): any | undefined => {
        if (x.taskId === action.payload.taskId) {
          indexOfCustomer = index;
          return x;
        }
        return undefined;
      })[0];

      // if (c.optIn === 1) c.optIn = 0;
      // else c.optIn = 1;

      // state.customers[indexOfCustomer] = c;
    },
    setTaskStatusId(state, action: PayloadAction<number | null>) {
      state.currentPage = 0;
      state.taskStatusId = action.payload;
    },

    setTaskIsArchived(state, action: PayloadAction<boolean>) {
      state.currentPage = 0;
      state.isArchived = action.payload;
    },

    setTaskTypeId(state, action: PayloadAction<number | null>) {
      state.currentPage = 0;
      state.taskTypeId = action.payload;
    },
    setDealerLocationId(state, action: PayloadAction<number | null>) {
      state.currentPage = 0;
      state.dealerId = action.payload;
    },
    setFilterDates(state, action: PayloadAction<{ fromDate: Date | null; toDate: Date | null }>) {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setSelectedCustomer(state, action: PayloadAction<number | undefined>): void {
      state.selectedCustomer = action.payload;
    },
    setAssignedUsers(state, action: PayloadAction<string[] | null>): void {
      state.currentPage = 0;
      state.assignedUserIds = action.payload;
    },
    UpdateSelectedTask(state, action: PayloadAction<TaskListObject | undefined>): void {
      state.selectedTask = action.payload;
    },
    UpdateDialogType(state, action: PayloadAction<CustomerDialogType>): void {
      state.dialogType = action.payload;
    },
    SetSelectedTasks(state, action: PayloadAction<TaskListObject[]>): void {
      if (state.tasks && state.tasks.length > 0) {
        state.selectedTask = undefined;
      }

      if (action.payload.length === 0) {
        state.selectedTasks = action.payload;
      } else if (action.payload.length > 1) {
        state.selectedTasks = action.payload;
      } else {
        const task = action.payload[0];
        const selected = state.selectedTasks;
        const selectedIndex = selected.map((c: TaskListObject) => c.taskId).indexOf(task.taskId);
        let newSelected: TaskListObject[] = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, task);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        state.selectedTasks = newSelected;
      }
    },

    SetAllSelectedTasks(state, action: PayloadAction<boolean>): void {
      state.AllTasksSelected = action.payload;
    },
    showTaskDrawer(state, action: PayloadAction<{ drawerType: string; drawerProps: any }>): void {
      state.drawerType = action.payload.drawerType;
      state.drawerProps = action.payload.drawerProps;
    },
    hideTaskDrawer(state): void {
      state.drawerProps = {};
      state.drawerType = null;
    },
    saveTaskStart(state): void {
      state.addTaskLoading = true;
      state.addTaskError = null;
    },
    saveTaskSuccess(state): void {
      state.addTaskLoading = false;
      state.addTaskError = null;
    },
    saveTaskFailure(state, action: PayloadAction<string>): void {
      state.addTaskLoading = false;
      state.addTaskError = action.payload;
    },
    resetFilter(state) {
      state.currentPage = 0;
      state.createdByUserId = null;
      state.assignedUserIds = [];
      state.taskStatusId = UserTaskStatus.New;
      state.fromDate = null;
      state.toDate = null;
      state.totalTasks = 0;
      state.taskTypeId = null;
      // state.dealerId = null;
      state.searchString = '';
      state.isArchived = false;
    },
    updateTaskSearchString(state, action: PayloadAction<string>): void {
      state.currentPage = 0;
      state.searchString = action.payload;
    },
    setPersistantFilterState(state, action: PayloadAction<{assignUser:any, statusId:any, typeId:any, archieve:boolean, location: any}>){
      state.assignedUserIds = action.payload.assignUser;
      state.taskStatusId = action.payload.statusId;
      state.taskTypeId = action.payload.typeId;
      state.isArchived = action.payload.archieve;
      state.dealerId = action.payload.location;
    }
    // addEmailRow(state, action: PayloadAction<string>): void {
    //   let em: CustomerEmail = {
    //     address: '',
    //     customerId: state.selectedCustomer !== undefined ? state.selectedCustomer.id : 0,
    //     dealerId: 0,
    //     emailType: 0,
    //     lastUpdated: '',
    //   };
    //   if (state.selectedCustomer && state.selectedCustomer.emails == null) {
    //     state.selectedCustomer.emails = [];
    //   }
    //   state.selectedCustomer?.emails.push(em);
    // },
    // updateEmailRow(state, action: PayloadAction<{ index: number; email: string }>): void {
    //   if (state.selectedCustomer && state.selectedCustomer.emails) {
    //     state.selectedCustomer.emails[action.payload.index].address = action.payload.email;
    //   }
    // },
    // removeEmailRow(state, action: PayloadAction<number>): void {
    //   if (state.selectedCustomer && state.selectedCustomer.emails) {
    //     state.selectedCustomer.emails.splice(action.payload, 1);
    //   }
    // },
  },
});

export const {
  getTasksStart,
  getTasksSuccess,
  getTasksFailure,
  UpdateSelectedTask,
  setAssignedUsers,
  setTaskStatusId,
  setFilterDates,
  setDealerLocationId,
  setTaskTypeId,
  UpdateDialogType,
  SetSelectedTasks,
  SetAllSelectedTasks,
  showTaskDrawer,
  resetFilter,
  hideTaskDrawer,
  saveTaskStart,
  saveTaskSuccess,
  saveTaskFailure,
  toggleTaskStart,
  updateTaskSearchString,
  setSelectedCustomer,
  setTaskIsArchived,
  setPersistantFilterState
  // addEmailRow,
  // updateEmailRow,
  // removeEmailRow,
} = taskboardSlice.actions;

export default taskboardSlice.reducer;

export const fetchTasks =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const state = getState();
      dispatch(getTasksStart());
      const taskFilterLocalData= localStorage.getItem(`TaskFilter_${state.user.dmsDealerId}_${state.user.crmUserId}`);
      let taskFilterData:TaskFilterPersistent =taskFilterLocalData ? JSON.parse(taskFilterLocalData):null; 
      if(taskFilterLocalData === null){
        const resp=await DealerAPI.getPersistentAppData(`TaskFilter_${state.user.dmsDealerId}_${state.user.crmUserId}`);
        if(resp === null || resp === ''){
          const taskfilerApiData:PersistentAppData = {
            crmUserId: state.user.crmUserId,
            dmsDealerId: state.user.dmsDealerId,
            type: `TaskFilter_${state.user.dmsDealerId}_${state.user.crmUserId}`,
            data: JSON.stringify({
              assignedUserIds:state.tasks.assignedUserIds,
              taskStatusId:state.tasks.taskStatusId,
              taskTypeId:state.tasks.taskTypeId,
              isArchived:state.tasks.isArchived,
              location:state.tasks.dealerId ?? state.user.dealerId
            })
          }
          localStorage.setItem(`${taskfilerApiData.type}`,taskfilerApiData.data);
          await DealerAPI.addPersistentAppData(taskfilerApiData);
        }else{
          localStorage.setItem(`${resp.type}`,resp.data);
          taskFilterData = JSON.parse(resp.data)
        }
      }
      const {
        currentPage,
        pageSize,
        searchString,
        createdByUserId,
        assignedUserIds,
        taskStatusId,
        taskTypeId,
        isArchived,
        fromDate,
        toDate,
      } = state.tasks;
      let dealerId = state.tasks.dealerId;
      if (!dealerId) {
        dealerId = state.user.dealerId;
      }
      const userTaskResponse = await TaskboardAPI.getTasks(
        taskFilterData ? taskFilterData.location :dealerId,
        state.user.crmUserId,
        taskFilterData ? taskFilterData.assignedUserIds : assignedUserIds,
        taskFilterData ? taskFilterData.taskStatusId : taskStatusId,
        taskFilterData ? taskFilterData.taskTypeId : taskTypeId,
        fromDate,
        toDate,
        currentPage,
        pageSize,
        searchString,
        taskFilterData ? taskFilterData.isArchived : isArchived,
      );
      if (userTaskResponse) {
        dispatch(
          getTasksSuccess({
            tasks: userTaskResponse.userTasks,
            pageSize: userTaskResponse.pageSize,
            count: userTaskResponse.itemCount,
            currentPage: userTaskResponse.pageNumber,
          }),
        );
      }
    } catch (error: any) {
      dispatch(getTasksFailure(error.message));
    }
  };

export const fetchTasksPage =
  (newPage: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(getTasksStart());
      const state = getState();
      const {
        currentPage,
        pageSize,
        searchString,
        createdByUserId,
        assignedUserIds,
        taskStatusId,
        isArchived,
        taskTypeId,
        fromDate,
        toDate,
      } = state.tasks;
      let dealerId = state.tasks.dealerId;
      if (!dealerId) {
        dealerId = state.tasks.dealerId ?? 0;
      }
      const userTaskResponse = await TaskboardAPI.getTasks(
        dealerId,
        state.user.crmUserId,
        assignedUserIds,
        taskStatusId,
        taskTypeId,
        fromDate,
        toDate,
        newPage,
        pageSize,
        searchString,
        isArchived,
      );
      if (userTaskResponse) {
        dispatch(
          getTasksSuccess({
            tasks: userTaskResponse.userTasks,
            pageSize: userTaskResponse.pageSize,
            count: userTaskResponse.itemCount,
            currentPage: userTaskResponse.pageNumber,
          }),
        );
      }
    } catch (error: any) {
      dispatch(getTasksFailure(error.message));
    }
  };

export const saveTask =
  (task: TaskListObject, followUpTask: TaskListObject | null = null): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let message;
    let errorMessage;

    try {
      const user = getState().user;
      const _task = getState().tasks;
      dispatch(hideTaskDrawer());
      dispatch(saveTaskStart());
      task.createdByUserId = user.crmUserId;

      let taskRequest: addTaskRequest = {
        assignedUserId: task.assignedUserId,
        createdByUserId: task.createdByUserId,
        customerId: task.customerId,
        dealerId: task.dealerId,
        dueDate: task.dueDate,
        followUpTaskId: task.followUpTaskId,
        isFollowed: task.isFollowed,
        notes: task.notes,
        statusId: task.taskStatusId,
        taskSubject: task.taskSubject,
        taskTypeId: task.taskTypeId || 0,
        taskOutcomeId: task.taskOutcomeId,
        followUpTask: null,
        dealId: null,
      };

      if (followUpTask != null) {
        taskRequest.followUpTask = {
          assignedUserId: followUpTask.assignedUserId,
          createdByUserId: task.createdByUserId,
          customerId: followUpTask.customerId,
          dealerId: followUpTask.dealerId,
          dueDate: followUpTask.dueDate,
          followUpTaskId: followUpTask.followUpTaskId,
          isFollowed: followUpTask.isFollowed,
          notes: followUpTask.notes,
          statusId: followUpTask.taskStatusId,
          taskSubject: followUpTask.taskSubject,
          taskTypeId: followUpTask.taskTypeId || 0,
          taskOutcomeId: followUpTask.taskOutcomeId,
          followUpTask: null,
          dealId: null,
        };
      }

      await TaskboardAPI.addTask(taskRequest);
      dispatch(showSnackbar({ type: 'success', message: 'Task created' }));
      dispatch(saveTaskSuccess());
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(fetchTasks());
    } catch (error: any) {
      dispatch(saveTaskFailure(error.message));
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const rescheduleTask =
  (task: TaskListObject): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let message;
    let errorMessage;

    try {
      const user = getState().user;
      const _task = getState().tasks;
      dispatch(hideTaskDrawer());
      dispatch(saveTaskStart());
      task.createdByUserId = user.crmUserId;

      let taskRequest: RescheduleTaskRequest = {
        assignedUserId: task.assignedUserId,
        dueDate: new Date(task.dueDate),
        notes: task.notes,
        taskTypeId: task.taskTypeId || 0,
        statusId: null,
        taskOutcomeId: null,
        taskId: task.taskId,
        taskSubject: task.taskSubject,
        dealerId: task.dealerId,
      };
      await TaskboardAPI.updateTask(taskRequest);

      dispatch(showSnackbar({ type: 'success', message: 'Task Updated' }));
      dispatch(saveTaskSuccess());
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(fetchTasks());
    } catch (error: any) {
      dispatch(saveTaskFailure(error.message));
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const rescheduleTaskList =
  (tasks: TaskListRescheduleRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let message;
    let errorMessage;

    try {
      const user = getState().user;
      dispatch(hideTaskDrawer());
      dispatch(saveTaskStart());
      await TaskboardAPI.updateTasks(tasks);
      if (tasks.isArchived) {
        dispatch(
          showSnackbar({
            type: 'success',
            message: tasks.taskIds.length > 1 ? 'Selected tasks archived' : 'Selected task archived',
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            type: 'success',
            message: tasks.taskIds.length > 1 ? 'Selected tasks updated' : 'Selected task updated',
          }),
        );
      }
      dispatch(saveTaskSuccess());
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(SetSelectedTasks([]));
      dispatch(SetAllSelectedTasks(false));
      dispatch(fetchTasks());
    } catch (error: any) {
      dispatch(saveTaskFailure(error.message));
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(SetSelectedTasks([]));
      dispatch(SetAllSelectedTasks(false));
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const markTaskAsDone =
  (newTask: TaskListObject | null, currentTask: TaskListObject, outcomeId: number, notes: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let message;
    let errorMessage;

    try {
      const user = getState().user;
      const _task = getState().tasks;

      dispatch(hideTaskDrawer());
      dispatch(saveTaskStart());
      let taskUpdateRequest: RescheduleTaskRequest = {
        assignedUserId: currentTask.assignedUserId,
        dueDate: new Date(currentTask.dueDate),
        notes: notes,
        taskTypeId: currentTask.taskTypeId || 0,
        statusId: UserTaskStatus.Completed,
        taskOutcomeId: outcomeId,
        taskId: currentTask.taskId,
        taskSubject: currentTask.taskSubject,
        dealerId: currentTask.dealerId,
      };
      await TaskboardAPI.updateTask(taskUpdateRequest);
      message = 'Task Marked as Done';

      dispatch(showSnackbar({ type: 'success', message }));
      if (newTask) {
        newTask.createdByUserId = user.crmUserId;
        let taskRequest: addTaskRequest = {
          assignedUserId: newTask.assignedUserId,
          createdByUserId: newTask.createdByUserId,
          customerId: newTask.customerId,
          dealerId: newTask.dealerId,
          dueDate: newTask.dueDate,
          isFollowed: newTask.isFollowed,
          notes: newTask.notes,
          statusId: newTask.taskStatusId,
          taskSubject: newTask.taskSubject,
          taskTypeId: newTask.taskTypeId || 0,
          followUpTaskId: currentTask.taskId,
          dealId: currentTask.dealId,
          taskOutcomeId: null,
          followUpTask: null,
        };
        await TaskboardAPI.addTask(taskRequest);
      }

      dispatch(saveTaskSuccess());
      dispatch(UpdateSelectedTask(undefined));

      dispatch(hideTaskDrawer());
      dispatch(fetchTasks());
    } catch (error: any) {
      dispatch(saveTaskFailure(error.message));
      dispatch(UpdateSelectedTask(undefined));
      dispatch(hideTaskDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const markTasksAsDone =
  (currentTasks: TaskListObject[], outcomeId: number, notes: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let message;
    let errorMessage;

    try {
      const user = getState().user;
      dispatch(hideTaskDrawer());
      dispatch(saveTaskStart());
      let taskUpdateRequest: TaskListDoneRequest = {
        taskIds: currentTasks.map((x) => x.taskId),
        outcomeId: outcomeId,
        notes: notes,
        statusId: UserTaskStatus.Completed,
      };
      await TaskboardAPI.updateTasksForDone(taskUpdateRequest);
      message = 'Task Marked as Done';

      dispatch(showSnackbar({ type: 'success', message }));

      dispatch(saveTaskSuccess());
      dispatch(SetSelectedTasks([]));
      dispatch(hideTaskDrawer());
      dispatch(fetchTasks());
    } catch (error: any) {
      dispatch(saveTaskFailure(error.message));
      dispatch(SetSelectedTasks([]));
      dispatch(hideTaskDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const saveTaskToggle =
  (task: Task): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    let errorMessage;

    try {
      // const { dealerId } = getState().user;
      // dispatch(toggleCustomerOptIn(customer));
      // const saveCustomer = CustomerAPI.editCustomer;
      // let c = { ...customer };
      // if (c.optIn === 0) {
      //   c.optIn = 1;
      // } else {
      //   c.optIn = 0;
      // }
      // await saveCustomer(dealerId, c);
      // // dispatch(saveCustomerSuccess());
      // dispatch(UpdateSelectedCustomer(undefined));
    } catch (error) {
      console.error(error);
      // dispatch(saveCustomerFailure(error.message));
      dispatch(UpdateSelectedTask(undefined));
      // dispatch(hideCustomerDrawer());
      dispatch(showSnackbar({ type: 'error', message: errorMessage }));
    }
  };

export const deleteSelectedCustomer =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      // const state = getState();
      // const { selectedCustomer } = state.customers;
      // const { dealerId } = state.user;
      // if (selectedCustomer === undefined) return;
      // await CustomerAPI.deleteCustomer(dealerId, selectedCustomer.id);
      // dispatch(UpdateSelectedCustomer(undefined));
      // dispatch(fetchCustomers());
    } catch (error) {
      dispatch(UpdateSelectedTask(undefined));
    }
  };

export const searchCustomer =
  (value: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(updateTaskSearchString(value));
      dispatch(fetchTasks());
    } catch (error) {
      console.error(error);
    }
  };
