import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles, FormControlLabel, Switch, Button, FormHelperText } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { ReactElement, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { hideTaskDrawer, markTaskAsDone, SetAllSelectedTasks, SetSelectedTasks } from '../taskboardSlice';
import TaskForm from './TaskForm';
import { TaskListObject } from 'components/lead/models/task';
import TaskboardAPI from '../taskboard-api';
import { LoadingButton } from 'common/components/buttons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
}));

function TaskDoneDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  const handleTaskFormSubmit = (task: TaskListObject | null): void => {
    if (formik.isValid) {
      if (selectedTask) {
        if (task && task.dueDate) {
          task.dueDate = moment.utc(new Date(task?.dueDate.split('T')[0] + 'T' + task?.dueTime)).format();
        }
        dispatch(markTaskAsDone(task, selectedTask, formik.values.taskOutcomeId, formik.values.notes));
      }
    }
  };
  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };

  const classes: any = useStyles();
  const selectedTask = useAppSelector((state) => state.tasks.selectedTask);
  const [outcomes, setOutcomes] = useState<any>([]);
  const [followTask, setFollowTask] = useState(false);

  const getOutcomeList = useCallback(async (): Promise<void> => {
    let data = await TaskboardAPI.getTaskOutcomes();
    setOutcomes(data);
    let taskOutcomeId = outcomes[0]?.id;
    formik.setFieldValue('taskOutcomeId', taskOutcomeId);
  }, [1]);

  React.useEffect(() => {
    getOutcomeList();
  }, [getOutcomeList]);

  let initialValues: any = {
    notes: selectedTask?.notes,
    taskOutcomeId: 0
  };

  const schema = Yup.object<any>().shape<any>({
    // notes: Yup.string().required('Required').max(1500, 'Max length 1500'),
    taskOutcomeId: Yup.number().nullable().required('Required').min(1, 'this field is required')
  });


  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (!formik.isValid) {
        return;
      }
      const sanitizedValues = { ...values };
      handleTaskFormSubmit(null);
      dispatch(SetSelectedTasks([]));
      dispatch(SetAllSelectedTasks(false));
    }
  });

  let selectedCustomer = { customerId: selectedTask?.customerId ? selectedTask.customerId : 0, customerName: selectedTask?.customerName ? selectedTask.customerName : '',   locationDealerId: selectedTask?.dealerId ?? 0, };
  return (
    <>
      <DrawerToolbar title={"Completed Task : " + selectedTask?.taskSubject} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <Box mt={1}>
          <form>
            <Grid container>
              <Grid item xs={6} style={{marginTop:'2rem'}}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="Outcome" variant="outlined" margin="dense">Outcome</InputLabel>
                  <Select
                    MenuProps={{
                      anchorPosition:{ top: 0, left:0 },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    labelId="Outcome"
                    id="Outcome"
                    value={formik.values.taskOutcomeId}
                    error={!!formik.errors.taskOutcomeId}
                    label="Task Outcome"
                    margin="dense"
                    onChange={(e: any, opt: any) => {
                      formik.handleChange(e);
                      formik.setFieldValue('taskOutcomeId', opt.props.value);
                    }}
                  >
                    {outcomes?.map((x: any) => (
                      <MenuItem value={x.id}>{x.value}</MenuItem>
                    ))}
                  </Select>
                  {!!formik.errors.taskOutcomeId && <FormHelperText style={{ color: "#e53935" }}>{formik.errors.taskOutcomeId}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows={4}
                  id="notes"
                  name="notes"
                  label="Notes"
                  value={formik.values.notes}
                  error={!!formik.errors.notes}
                  helperText={formik.errors.notes}
                  onChange={(e: any) => { formik.handleChange(e) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <hr />
        <Box flexGrow={1}>
          <Typography className={classes.title} variant="h4">
            Follow-Up task (Optional)
            <FormControlLabel
              control={
                <Switch
                  id="followTask"
                  checked={followTask}
                  onChange={(e: any) => { setFollowTask(e.target.checked) }}
                  name="followTask"
                  color="primary"
                />
              }
              label=""
              labelPlacement="start"
            />
          </Typography>
        </Box>

        <TaskForm
          disabled={false}
          btnDisabled={false}
          onFormSubmit={handleTaskFormSubmit}
          onClose={handleOnClose}
          onMarkAlreadyCompleted={() => { }}
          done={followTask}
          validate={true}
          reschedule={false}
          edit={false}
          hideButtons={false}
          previousCustomer={selectedCustomer}
        />
        {followTask === false &&
          <Box mt={2}>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button id="cancelTaskFormBtn" variant="contained" onClick={handleOnClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  fullWidth
                  id="saveTaskFormBtn"
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={(e: any) => { formik.submitForm(); }}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        }
      </Container>
    </>
  );
}

export default TaskDoneDrawer;
