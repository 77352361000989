import CustomerAPI from 'components/customer/api/CustomerAPI';
import Messenger from 'components/messaging/components/messenger';
import React, { useState } from 'react';

export default function MessengerCustomer(): React.ReactElement {
  const query = new URLSearchParams(location.search);

  const [hideOtherNavigation, setHideOtherNavigation] = useState(false);
  const [defaultSearch, setDefaultSearch] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  async function getAndSetCustomerCrmId(customerId: number) {
    const crmCustomerId = await CustomerAPI.getCustomerCrmIdByCustomerId(customerId);
    setDefaultSearch(`CrmCustomerId:${crmCustomerId}`);
  }

  React.useEffect(() => {
    //Getting values from query parameters for showing messenger only for a customer
    const hideNavigationFromQuery = query.get('hideOtherNavigation');
    const customerIdFromQuery = query.get('customerId');
    const phoneNumberFromQuery = query.get('phoneNumber');
    if (hideNavigationFromQuery && customerIdFromQuery) {
      getAndSetCustomerCrmId(Number(customerIdFromQuery));
      setHideOtherNavigation(hideNavigationFromQuery === 'true' ? true : false);
      if (phoneNumberFromQuery) setPhoneNumber(phoneNumberFromQuery);
    }
  }, []);

  return defaultSearch != '' ? (
    <Messenger
      hideNavigations={hideOtherNavigation}
      defaultSearch={defaultSearch}
      phoneNumber={phoneNumber}
      CloseMe={() => {}}></Messenger>
  ) : (
    <div style={{ margin: '20px' }}>
      <h2>Loading Customer Data. Please Wait......</h2>
    </div>
  );
}
