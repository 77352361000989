import { get, post, put, destroy } from 'api';
import config from 'config';

import Department from '../models/department';
import DepartmentRequest from '../models/department-request';
import { UserDepartmentSettings } from '../models/user-department-settings';

const dealerUrl = config.get('api.dealer');

const DepartmentAPI = {
  async getDepartments(selectedDealerLocationId: number, dmsDealerIdCrm: number): Promise<Department[]> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/departments?dealerId=${selectedDealerLocationId}`;
    const { data } = await get(url);
    return data;
  },
  async getDepartment(dmsDealerIdCrm: number, id: number): Promise<any> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/departments/${id}`;
    const { data } = await get(url);
    return data;
  },
  async createDepartment(
    dmsDealerIdCrm: number,
    departmentName: string,
    dealerLocationId: number,
    crmUserIds: number[],
  ): Promise<Department> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/departments`;
    const requestBody: DepartmentRequest = {
      departmentName,
      dealerId: dealerLocationId,
      crmUserIds,
    };
    const { data } = await post(url, requestBody);
    return data;
  },
  async updateDepartment(
    dmsDealerIdCrm: number,
    departmentId: number,
    departmentName: string,
    crmUserIds: number[],
    dealerLocationId: number,
  ): Promise<any> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/departments/${departmentId}`;
    const updateDepartmentRequest = {
      crmUserIds,
      departmentId,
      departmentName,
      dealerId: dealerLocationId,
    };
    const { data } = await put(url, updateDepartmentRequest);
    return data;
  },
  async deleteDepartment(dmsDealerIdCrm: number, id: number): Promise<any> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/departments/${id}`;
    const { data } = await destroy(url);
    return data;
  },
  async getUserDepartmentSetting(dmsDealerIdCrm: number, crmUserId: string): Promise<UserDepartmentSettings> {
    const url = `${dealerUrl}/v2/dealers/${dmsDealerIdCrm}/users/${crmUserId}/departments`;
    const { data } = await get(url);
    return data;
  },
  async updateUserDepartmentSettings(
    dmsDealerIdCrm: number,
    crmUserId: string,
    departmentIds: number[],
  ): Promise<UserDepartmentSettings> {
    const url = `${dealerUrl}/v2/dealers/${dmsDealerIdCrm}/users/${crmUserId}/departments`;
    const { data } = await post(url, { crmUserId, UserSelectedDepartments: departmentIds });
    return data;
  },
  async updateDealerFacebookTokens(facebookSettingsRequest:any): Promise<UserDepartmentSettings> {
    const url = `${dealerUrl}/dealers/UpdateDealerFacebookTokens`;    
    const { data } = await post(url, facebookSettingsRequest );
    return data;
  },
};

export default DepartmentAPI;
