import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import CustomerAPI from 'components/customer/api/CustomerAPI';
import { AppThunk } from 'app';

import CustomerPhoneAPI from '../../api/CustomerPhoneAPI';

export interface CustomerPhoneNumbers {
  cellPhone: number;
  landLine: number;
  alternatePhone: number;
  fax: number;
}

export interface CustomerPhoneValidationRequest {
  PhoneType: CustomerPhoneTypeRequest;
  Count: number;
}

export interface CustomerPhoneTypeRequest {
  Name: string;
  Id: number;
}

export interface CustomerValidationState {
  loadingValidationCost: boolean;
  validationCost: number | null;
  customerPhoneNumbers: CustomerPhoneValidationRequest[];
  errorLoadingValidationCost: string | null;
  loadingCustomerPhoneCount: boolean;
  errorCustomerPhoneCount: string | null;
}

const initialState: CustomerValidationState = {
  loadingValidationCost: false,
  validationCost: null,
  errorLoadingValidationCost: null,
  loadingCustomerPhoneCount: false,
  errorCustomerPhoneCount: null,
  customerPhoneNumbers : [],
};

const customerValidation = createSlice({
  name: 'customerValidation',
  initialState,
  reducers: {
    getValidationCostStart(state) {
      state.loadingValidationCost = true;
      state.errorLoadingValidationCost = null;
    },
    getValidationCostSuccess(state, action: PayloadAction<number>) {
      state.loadingValidationCost = false;
      state.validationCost = action.payload;
      state.errorLoadingValidationCost = null;
    },
    getValidationCostFailure(state, action: PayloadAction<string>) {
      state.loadingValidationCost = false;
      state.errorLoadingValidationCost = action.payload;
    },
    getCustomerPhoneNumberCountStart(state) {
      state.loadingCustomerPhoneCount = true;
      state.errorCustomerPhoneCount = null;
    },
    getCustomerPhoneNumberCountSuccess(state, action: PayloadAction<CustomerPhoneValidationRequest[]>) {
      state.loadingCustomerPhoneCount = false;
      state.customerPhoneNumbers = action.payload;
      state.errorCustomerPhoneCount = null;
    },
    getCustomerPhoneNumberCountFailure(state) {
      state.loadingCustomerPhoneCount = false;
      state.errorCustomerPhoneCount = null;
    },
  },
});

export const {
  getValidationCostStart,
  getValidationCostSuccess,
  getValidationCostFailure,
  getCustomerPhoneNumberCountStart,
  getCustomerPhoneNumberCountSuccess,
  getCustomerPhoneNumberCountFailure,
} = customerValidation.actions;

export default customerValidation.reducer;

export const getCustomerPhoneValidationCost =
  (phoneNumberCount: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(getValidationCostStart());
        const response = await CustomerPhoneAPI.getPhoneValidationCost(phoneNumberCount);
        dispatch(getValidationCostSuccess(response.validationCost));
      } catch (error) {
        dispatch(getValidationCostFailure(error));
      }
    };

export const getCustomerPhoneNumberCount = (crmCustomerIds: number[] | null): AppThunk => async (dispatch) => {
  try {
    dispatch(getCustomerPhoneNumberCountStart());
    const response = await CustomerAPI.getCustomerPhoneNumberCount(crmCustomerIds);
    if (response !== null && response.data !== null)
      dispatch(getCustomerPhoneNumberCountSuccess(response.data as CustomerPhoneValidationRequest[]));
    else {
    }
  } catch (ex) {
    dispatch(getCustomerPhoneNumberCountFailure());
  }
};
