import { get, post, put } from 'api';
import config from 'config';
import exp from 'constants';

export interface DealLeadTagRequest {
    leadTagId?: number;
    leadDescription: string;
    leadTag: string;
}
export interface DealLeadTags {
    id: number;
    dealerId: number;
    leadTagName: string;
    createdAt: Date;
    updatedAt?: Date;
    createdBy: string;
    updatedBy: string;
}
export interface DealLeadTagDeleteRequest {
    leadTagId: number;
}

const BaseUrl = config.get('api.lead');
const LeadSourceAPI = {
    async getLeadSources(): Promise<DealLeadTags[]> {
        const url = `${BaseUrl}/lead/deal/allLeadTags`;
        const { data } = await get(url);
        return data;
    },
    async addLeadSource(leadRequest: DealLeadTagRequest): Promise<DealLeadTags> {
        const url = `${BaseUrl}/lead/deal/addLeadTags`;
        const { data } = await post(url, leadRequest);
        return data;
    },
    async updateLeadSource(leadRequest: DealLeadTagRequest): Promise<DealLeadTags> {
        const url = `${BaseUrl}/lead/deal/updateLeadTags`;
        const { data } = await put(url, leadRequest);
        return data;
    },
    async deleteLeadSource(leadRequest:DealLeadTagDeleteRequest): Promise<boolean> {
        const url = `${BaseUrl}/lead/deal/deleteLeadTags`;
        const { data } = await post(url,leadRequest);
        return data;
    },

};

export default LeadSourceAPI;