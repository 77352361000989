import { ReactElement, useEffect, useState } from 'react';
import { DealItem } from '../model/dealsInterfaces';
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  Button,
  Container,
  Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppSelector } from 'app';
import { useFormik } from 'formik';
import AppSnackbar from 'components/snackbar/snackbar';
import PipelineDeals from 'components/deals/apis/deals-api';
import useStyles from '../styles/deals-styles';
import { DealStatusEnum } from '../model/DealStatusEnum';

export function WonDealDrawer({
  open,
  editCount,
  selectedColumn,
  renderDeals,
  onClose,
}: {
  open: boolean;
  selectedColumn: DealItem[];
  editCount: number;
  renderDeals: () => void;
  onClose(val: boolean): void;
}): ReactElement {
  const classes = useStyles();
  const [showSnackBar] = AppSnackbar.useSnackBar();

  const dealerId = useAppSelector((state) => state.user.dealerId);
  const formik = useFormik({
    initialValues: {
      deals: selectedColumn,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const wonDealValues = [...selectedColumn];
      if (values.deals.length > 0) {
        wonDealValues.map((data, _index) => {
          values.deals.map((val, index) => {
            if (_index === index) {
              data.isArchiveTask = val.isArchiveTask;
            }
          });
        });
      }
      if (wonDealValues && wonDealValues.length > 0) {
        await PipelineDeals.PipelineDeals.updateDealWon(wonDealValues).then((response) => {
          if (response === 'Ok') {
            onClose(false);
            renderDeals();
            showSnackBar({ type: 'success', message: `${editCount > 1 ? 'Deals' : 'Deal'} Updated Successfully` });
          } else {
            onClose(false);
            showSnackBar({ type: 'error', message: 'Something Went Wrong' });
          }
        });
      }
    },
  });
  useEffect(() => {
    if (!open) {
      formik.values.deals = [];
    }
  }, [selectedColumn, formik.values]);

  return (
    <Drawer anchor={'right'} open={open} onClose={() => onClose(false)}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.success} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Edit {editCount + (editCount > 1 ? ' Deals' : ' Deal')}: Mark All Won</Typography>
          </Box>
          <IconButton id="closeBtn" onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />

        <Container maxWidth="sm">
          <Box mt={1}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Won).length > 0}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => formik.handleSubmit()}>
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                {selectedColumn.map((deal, index) => {
                  return (
                    <>
                      <form onSubmit={formik.handleSubmit}>
                        <Box key={deal.id} mt={2} mb={2}>
                          <Typography variant="h4"> {deal.title}</Typography>
                          <Typography variant='body1'>{deal.customer}</Typography>
                          <Typography>
                            Archive scheduled tasks{' '}
                            <Switch
                              disabled={formik.values.deals.filter((x) => x.dealStateId == DealStatusEnum.Won).length > 0}
                              id={`deals[${index}].id`}
                              name={`deals[${index}].isArchiveTask`}
                              checked={formik.values.deals[index] && formik.values.deals[index].isArchiveTask}
                              onChange={formik.handleChange}
                            />
                          </Typography>
                        </Box>
                        <Divider style={{ height: '3px', background: '#a9a9a9' }} />
                      </form>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}
