// import CustomerEditDrawer from '././customer-edit-drawer';
import CustomerEditDrawer from 'components/messaging/components/customer-edit-drawer';
import Badge from '@mui/material/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { store } from 'app';
import { GetLink } from 'dmsAppsCallbacks';
import { PageHeader } from 'common/components/layout';
import TextField from '@material-ui/core/TextField';
import { Button, MenuItem, Drawer, Box, Container, Dialog, Paper, Typography, IconButton, Fab, Grid } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import SurveyAPI from 'components/survey/api/survey-api';
import { SurveyResp } from 'components/survey/models/question';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatPhoneNumber } from 'common/utils';
import FilterIconButton from '@material-ui/core/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
//import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadIcon from '@mui/icons-material/Download';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useAppSelector } from 'app';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const useStyles = makeStyles({
  SurveyStatistics: {
    fontSize: "small",
    border: "1px solid silver",
    padding: "2px 10px"
  },
  menuPaper: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    // top:400,
    width: "auto",
    // display: "table",
    padding: "0px 5px",

  },
  detractor: {
    backgroundColor: 'red',
    color: 'White'
  },
  passive: {
    backgroundColor: 'orange',
    color: 'White'
  },
  promoters: {
    backgroundColor: 'green',
    color: 'White'
  },


  root: {
    width: '100%',
    martin: '20px',
    backgroundColor: 'red',

  },
  defaultFont: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "small"
  },
  tableType: {
    color: 'black',
    backgroundColor: '#EAEDED',
    padding: "4px 10px",
    fontFamily: "Quicksand, sans-serif",

    // fontWeight: 'bold',
  },
  table01: {
    border: "1px solid silver",
    // marginBottom: "10px",
    borderCollapse: "collapse",
    margin: "10px",
    marginLeft: "0px",
    fontSize: "0.9em",
    fontFamily: "sans-serif",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",

    // border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // font-family: sans-serif;
    // min-width: 400px;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  },
  table02: {
    // cursor: "url(hand.cur), pointer"
  },

  buttonValidatePhone: {
    margin: "2px",
    padding: "2px 5px",
    fontSize: '8px',
    backgroundColor: '#EAFAF1',
    color: 'gray',
    border: '1px solid silver',

  },
  selectedRow: {
    backgroundColor: '#F9EBEA',
  },
  selectMe: {
    textDecorationLine: 'underline',
    cursor: "pointer",
    '&:hover': {
      color: "green",
      // fontWeight: 'bold',
    },

  },
  myTable: {
    '& th': {
    },
    '& td': {
      borderBottom: "1px solid #EAEDED",
      // whiteSpace: "nowrap",
      height: "25px"
    },
    '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
  },
  noWrap: {
    whiteSpace: "nowrap",
  },




});

let mySearchTimeOut: any;
let lastSurveyIds: any = [-1];
export default function SurveyResponse(): React.ReactElement {
  const classes: any = useStyles();
  const [startDate, setStartDate] = React.useState<any>(new Date('01-01-1900'));
  const [endDate, setEndDate] = React.useState<any>(new Date());
  // const [endDate, setEndDate] = React.useState<Date>(new Date().toISOString().slice(0, 10));
  const [surveyData, SetSurveyData] = React.useState<any>([]);
  const [SurveyStatisticsData, SetSurveyStatisticsData] = React.useState<any>([]);
  const [pageNumber, SetPageNumber] = React.useState<number>(-1);
  const [totalRows, SetTotalRows] = React.useState<number>(1000);
  const [rowsPerPage, SetRowsPerPage] = React.useState<any>(15);
  const [openDrawer, SetOpenDrawer] = React.useState<any>(false);
  const [filterType, SetFilterType] = React.useState<string>('');
  const [keywordSearch, SetKeywordSearch] = React.useState<string>('');
  const [searchText, SetSearchText] = React.useState<any>('');
  const [dealerLocations, SetDealerLocations] = React.useState<any>([]);
  const [dealerLocationId, SetDealerLocationId] = React.useState<any>(store.getState().user.dealerId);
  const [showPagination, SetShowPagination] = React.useState<any>(false);
  // const [dealerLocationIds, SetDealerLocationIds] = React.useState<any>([store.getState().user.dealerId]);
  //const [dealerLocationIds, SetDealerLocationIds] = React.useState([store.getState().user.dealerId] as any);
  const [dealerLocationIds, SetDealerLocationIds] = React.useState([] as any);
  const [surveyIds, SetSurveyIds] = React.useState<any>([-1] as any);
  const [surveysData, SetSurveysData] = React.useState<any>([]);
  const [showWorking, SetShowWorking] = React.useState(false);
  const [selectedCustomerId, SetSelectedCustomerId] = React.useState<number>(0);
  const isHostedDealer = useAppSelector((state) => state.user?.isHosted);
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  let isCustomerPermission = 0;
  if (!userPermissions) {
    userPermissions = [];
    isCustomerPermission = 2;
  }
  if (isCustomerPermission == 0) {
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
    isCustomerPermission = 1;
  } 
  }

  React.useEffect(() => {
    try {

      const rememberMe = localStorage.getItem('rememberSurveyResponse');
      if (rememberMe != null) {
        const dataJson = JSON.parse(rememberMe);
        if(dataJson.DealerId ==undefined || dataJson.DealerId ==null || dataJson.DealerId!=store.getState().user.dealerId) return;
        SetFilterType(dataJson.ResponseType);
        if (dataJson.LocationIds != null && dataJson.LocationIds != '') SetDealerLocationIds(dataJson.LocationIds);
        if (dataJson.SurveyIds != null && dataJson.SurveyIds != '') SetSurveyIds(dataJson.SurveyIds);
        SetSearchText(dataJson.Keyword);
        SetKeywordSearch(dataJson.Keyword);
      }
    }
    catch (error) {
    }

  }, []);

  React.useEffect(() => {
    if (new Date(Date.parse(startDate)).getFullYear() == 1900) {
      let xstartDate = new Date();
      setEndDate(xstartDate.toISOString().slice(0, 10));
      xstartDate.setMonth(xstartDate.getMonth() - 6);
      setStartDate(xstartDate.toISOString().slice(0, 10));
      return;
    }
    if (pageNumber >= 0) { GetSurveyData(0, pageNumber) }

    // }, [rowsPerPage, pageNumber, filterType, searchText, startDate, endDate, dealerLocationId, dealerLocationIds]);
  }, [rowsPerPage, pageNumber]);

  function ResetLocation(dealerLocations: any) {
    // If no locations are selected
    if (dealerLocationIds.length === 0) {
      const value2 = [];
  
      // If there is only one dealer location, set it as the default selection
      if (dealerLocations.length === 1) {
        value2.push(dealerLocations[0].dealerId); // Add the single dealer ID
      } else {
        // Otherwise, add all dealer IDs to value2
        for (const ctd of dealerLocations) {
          value2.push(ctd.dealerId);
        }
      }
  
      // Set the dealer location IDs
      SetDealerLocationIds(value2);
    }
  }

  React.useEffect(() => {
    SetPageNumber(0);
    GetSurveyData(0, 0);
  }, [filterType, filterType, searchText, startDate, endDate, dealerLocationId, dealerLocationIds, surveyIds]);

  const MenuProps = {
    PaperProps: {
      style: {
        innerHeight: 100,
        maxHeight: 500,
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // maxHeight: 100,
        // innerHeight: 100,
        // width: 250,
        width: "auto",
        // minWidth: "200px"
        display: "table",
        padding: "0px 5px",
      },
    },
  };



  function OnLocationFilterChange(values: any) {
    if(values.length==0)return;
    SetDealerLocationIds(values);
    if (values.indexOf(-1) > -1) {
      const value2 = [-1];
      for (const ctd of dealerLocations) {
        value2.push(ctd.dealerId);
      }
      SetDealerLocationIds(value2)
    }
  }

  function OnSurveyChange(values: any) {
    if (values.indexOf(-1) >= 0 && lastSurveyIds.indexOf(-1) == -1) {
      SetSurveyIds([-1]);
      lastSurveyIds = [-1];
      return;
    }

    if (values.length == 0) {
      SetSurveyIds([-1]);
      lastSurveyIds = [-1];
      return;
    }
    if (values.length > 1) {
      let i = values.indexOf(-1);
      if (i > -1) {
        values.splice(i, 1);
      }
    }
    SetSurveyIds(values)
    lastSurveyIds = values;

    // if (values.indexOf(-1) > -1) {
    //   const value2 = [-1];
    //   for (const ctd of dealerLocations) {
    //     value2.push(ctd.dealerId);
    //   }
    //   SetDealerLocationIds(value2)
    // }
  }

  function SurveyRender(): string {
    if (surveyIds.length == 0) return 'All Surveys';
    if (surveyIds.length == 1 && surveyIds.indexOf(-1) > -1) return 'All Surveys';;
    if (surveyIds.length > 1) return 'Multiple Surveys';
    for (const ctd of surveysData) {
      if (ctd.id == surveyIds[0]) {
        return ctd.title;
      }
    }

    return '';

  }

  function LocationRender(): string {
    let value = '';
    if (dealerLocationIds.length == dealerLocations.length || dealerLocationIds.length==0) {
      return 'All Locations';
    }

    for (const ct of dealerLocationIds) {
      if (ct == -1) {
        value = '';
        break;
      }
      for (const ctd of dealerLocations) {
        if (ctd.dealerId == ct) {
          if (value != '') {
            value = 'Multiple Locations';
            break;
          }
          value = ctd.locationName;
        }
      }
    }
    if (value == '') { value = 'All Locations' }
    return value;
  }


  function GetDealerLocation(locationId: number) {
    for (const item of dealerLocations) {
      if (item.dealerId == locationId) {
        return item.locationName;
      }
    }
    return '';
  }


  function GetSurveyQuery(rowsCount: number, pageNumber: number): SurveyResp {
    // if (new Date(Date.parse(startDate)).getFullYear() == 1900) { return }

    let x: number = rowsPerPage;
    let _dealerLocationId: number = dealerLocationId;
    let dealerIds = [dealerLocationId];
    if (dealerLocationId == 0) {
      dealerIds = [];
      for (const item of dealerLocations) {
        dealerIds.push(item.dealerId);
      }
    }
    let _surveyIds = surveyIds;
    if (_surveyIds.length == 1 && _surveyIds[0] == -1) _surveyIds = [];
    
    const surveyResponse: SurveyResp = {

      DealerId:store.getState().user.dealerId,
      LocationIds: dealerLocationIds,
      ResponseType: filterType,
      DateFrom: new Date(Date.parse(startDate)),
      DateTo: new Date(Date.parse(endDate)),
      Keyword: searchText,
      Offset: pageNumber * rowsPerPage,
      SurveyIds: _surveyIds,
      Rows: x
    };
    return surveyResponse;
  }

  async function GetSurveyStatistics() {
    try {
      let obj: any = new Object();
      obj.Dealerid = dealerLocationIds.toString();
      obj.StartDate = new Date(Date.parse(startDate));
      obj.EndDate = new Date(Date.parse(endDate));
      obj.ForAllLocation = 0;
      const jsonString = JSON.stringify(obj);
      const response = await SurveyAPI.GetSurveyStatistics(jsonString);
      SetSurveyStatisticsData(response[0]);
    }
    catch (error) {
      SetSurveyStatisticsData(null);
    }
  }

  async function GetSurveyData(rowsCount: number, pageNumber: number, customerOnly?: boolean) {
    if (customerOnly === undefined) customerOnly = false;
    if (new Date(Date.parse(startDate)).getFullYear() == 1900) { return }
    if (new Date(Date.parse(startDate)).getFullYear() > 2099) { return }
    if (new Date(Date.parse(startDate)).getFullYear() < 1999) { return }
    if (!customerOnly) SetShowWorking(true);

    const surveyResponse: SurveyResp = GetSurveyQuery(rowsCount, pageNumber);
    localStorage.setItem('rememberSurveyResponse', JSON.stringify(surveyResponse));
    const response = await SurveyAPI.GetSurveyResponse(surveyResponse);
    SetSurveyData(response.surveyInboxResponses);
    if (customerOnly) return;
    if (dealerLocations.length == 0) 
    { SetDealerLocations(response.dealerLocations);
      ResetLocation(response.dealerLocations);
    }
    if (surveysData.length == 0) SetSurveysData(response.surveys);
    if (typeof (response.surveyInboxResponses) != 'undefined') { SetShowPagination(response.surveyInboxResponses.length > 0 ? true : false) }
    if (pageNumber > 0) { SetShowPagination(true) }
    if (response.surveyInboxResponses.length < rowsPerPage) {
      const totalRowCount = response.surveyInboxResponses.length + (pageNumber * rowsPerPage);
      SetTotalRows(totalRowCount);
    }
    else { SetTotalRows(1000) };
    GetSurveyStatistics();
    SetShowWorking(false);
  }

  async function handlerDownloadCSV() {
    const surveyResponse: SurveyResp = GetSurveyQuery(1000, 0);
    const response = await SurveyAPI.FeedbackCSVDownload(surveyResponse);
    if (typeof (response) == 'undefined' || response == null) { return ''; }
    window.location.href = response;
  }

  function handleChangePage(_: unknown, newPage: number): void {
    SetPageNumber(newPage);
  }

  function OpenCloseFilter(value: number = 0) {
    SetOpenDrawer(value == 0 ? false : true);
    //SetShowFilter(value == 0 ? false : true);
  }

  function HandleSearchDataChange(e: any) {
    SetKeywordSearch(e.target.value)
    clearTimeout(mySearchTimeOut);
    mySearchTimeOut = setTimeout(SearchData, 1000, e.target.value);
  }


  function SearchData(value: string) {
    SetSearchText(value);
  }

  function ConvetToLocalTime(value: any) {

    try{
      return new Date(value.toString().replace("T", " ") + " UTC");
    }
    catch(err){
      return value;
    }
    
  }

  function OpenCustomerDrawer(crmCustomerId: number) {
    if (isCustomerPermission == 1) {
    SetSelectedCustomerId(crmCustomerId);
    }
  }

  function CloseCustomerDrawer() {
    SetSelectedCustomerId(0);
  }
  function OnCustomerSave() {
    if (pageNumber >= 0) { GetSurveyData(0, pageNumber, true) }
  }

  return (
    // <Box style={{ margin: "15px 35px 0px 10px", border: "0px solid silver" }}>
    // <Container style={{ marginTop: "10px" }}>
    <Box display="flex" justifyContent="center" alignItems="center" style={{ margin: "10px 20px 5px 10px" }}>
      <Box style={{width:"90%"}}>
        {/* <Box> */}
        <Box style={{ display: "table" }} >
          <Table >
            <tr>
              <td>
                <PageHeader title="Feedback" />
              </td>
              <td style={{ paddingLeft: "25px" }}>
                {(showWorking) && (<CircularProgress size={25} />)}

              </td>
              <td>
                {typeof (SurveyStatisticsData) !== 'undefined' && SurveyStatisticsData != null && (
                  <div>
                    <table>
                      <tr>
                        <td>
                          <span className={classes.SurveyStatistics} style={{ backgroundColor: "gray", color: "white" }} >Total: {SurveyStatisticsData.ResponseCount} </span>

                        </td>
                        <td>
                          <span style={{ backgroundColor: "red", color: "white" }} className={classes.SurveyStatistics} >Detractors: {SurveyStatisticsData.Detractor} ({SurveyStatisticsData.DetractorPercentage}%)</span>
                        </td>
                        <td>
                          <span className={classes.SurveyStatistics} style={{ backgroundColor: "orange", color: "white" }} >Passive: {SurveyStatisticsData.Passive} ({SurveyStatisticsData.PassivePercentage}%)</span>
                        </td>
                        <td>
                          <span style={{ backgroundColor: "green", color: "white" }} className={classes.SurveyStatistics} >Promoters: {SurveyStatisticsData.Promoters} ({SurveyStatisticsData.PromotersPercentage}%)</span>
                        </td>
                        <td>
                          <span className={classes.SurveyStatistics} style={{ backgroundColor: "#F4F6F6" }} >Loyalty Score: {SurveyStatisticsData.LoyaltyScore}</span>
                        </td>

                      </tr>
                    </table>
                  </div>

                )}
                {/* SurveyStatisticsData */}
              </td>
            </tr>
          </Table>
        </Box>
        {/* </Box> */}
        {/* <Box display={"table"}> */}
        {/* <Box display="flex" justifyContent="center" alignItems="center" > */}
          {/* <Box style={{width:"90%"}}> */}
          <Box>
            <table style={{ width: "100%" }} className={classes.myTable}>
              <tr>
                <td>
                  <Paper className={classes.paper}>

                    <TableContainer style={{ border: "1px solid #C8CCCF", marginTop: "10px" }}>
                      {/* <Table stickyHeader size="small" className={classes.myTable}  > */}
                      <Table stickyHeader size="small"  >
                        <TableHead>
                          <TableRow>
                            {/* {dealerLocationIds.length > 1 && ( */}

                            {/* )} */}

                            <TableCell>Date</TableCell>
                            <TableCell>Customer</TableCell>
                            {<TableCell>Cell</TableCell>}
                            <TableCell>Rating</TableCell>
                            <TableCell>Response</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Survey</TableCell>



                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {surveyData.map((row: any) => {
                            // let surveyDate = new Date(row.updatedAt).toLocaleString();
                            //let surveyDate = new Date(row.updatedAt).toLocaleDateString();
                            let surveyDate = ConvetToLocalTime(row.updatedAt).toLocaleDateString();
                            //let surveyTime = new Date(row.updatedAt).toLocaleTimeString();
                            let surveyTime = ConvetToLocalTime(row.updatedAt).toLocaleTimeString();
                            let surveyDateTime = surveyDate + ' ' + surveyTime;
                            let formattedPhoneNumber = formatPhoneNumber(row.customer.cellPhone);
                            let scoreClassName = '';
                            if (row.score <= 7) { scoreClassName = 'classes.detractor' }
                            //detractor
                            return (
                              <TableRow>
                                {/* {dealerLocationIds.length > 1 && ( */}

                                {/* )} */}

                                {/* <TableCell><Box>{surveyDate}</Box>  {surveyTime}</TableCell> */}
                                {/* <TableCell>{surveyDate} <span style={{whiteSpace:"nowrap"}}>{surveyTime}</span></TableCell> */}
                                <TableCell><span style={{ whiteSpace: "nowrap" }}>{surveyDateTime}</span></TableCell>
                                {/* <TableCell className={classes.noWrap} onClick={OpenCustomerDrawer(12)}> {row.customer.name}</TableCell> */}
                                
                               <TableCell className={classes.noWrap} 
onClick={(event) => { OpenCustomerDrawer(row.customer.crmCustomerId) }
                                }>
                                  <span className={
                                    isCustomerPermission == 1 ? classes.selectMe : ""}>
                                    {row.customer.name}</span>
                                </TableCell>

                                {<TableCell className={classes.noWrap}> {formattedPhoneNumber}</TableCell>}
                                {/* <TableCell className={classes.noWrap}> {row.question}</TableCell> */}
                                <TableCell style={{ textAlign: "center" }}><div className={row.score <= 6 ? classes.detractor : row.score <= 8 ? classes.passive : classes.promoters} style={{ borderRadius: "50px", border: "0px solid silver", padding: "5px 0px" }} >    {row.score}</div></TableCell>
                                <TableCell style={{ maxWidth: "400px" }}> {row.answer}</TableCell>
                                <TableCell> {GetDealerLocation(row.dealerLocationId)}</TableCell>
                                <TableCell> {row.surveyTitle}</TableCell>




                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>

                    </TableContainer>
                  </Paper>
                  <div style={{ margin: "10px", display: showPagination ? "none" : "" }}>No feedback to show. Please check filter...</div>

                  <div style={{ float: "right", display: showPagination ? "" : "none" }}>
                    {/* <div style={{ position:"fixed", zIndex:100, bottom:"5px",right:"5px", border:"1px solid gray"  }}> */}
                    <TablePagination

                      size="small"
                      rowsPerPageOptions={[10, 15, 20, 25, 30]}
                      count={totalRows}
                      rowsPerPage={rowsPerPage}
                      page={pageNumber}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={(e) => { SetRowsPerPage(e.target.value) }}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to}`}

                    />
                  </div>


                </td>
                <td valign="top">
                  <table>
                    <tr>
                      <td>
                        <Fab color="primary" onClick={() => OpenCloseFilter(1)} style={{ marginLeft: "10px", marginTop: "10px" }} title='Filter data'>
                         <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                          <FilterAltIcon />
                          <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                        </Box>
                        </Fab>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {<Fab color="primary" onClick={() => handlerDownloadCSV()} style={{ marginLeft: "10px", marginTop: "5px" }} title='Download CSV' >
                        <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                          <DownloadIcon />
                          <Typography style={{ fontSize: '7.5px', marginTop: '-12px' }}>Download</Typography>
                        </Box>
                        </Fab>}

                      </td>
                    </tr>

                  </table>

                  {/* <div style={{ position: "fixed", right: "10px", marginTop: "-5px", zIndex: 10 }}> */}
                  {/* </div> */}

                </td>

              </tr>
            </table>


            {selectedCustomerId > 0 && (
              <CustomerEditDrawer customerId={selectedCustomerId} onClose={CloseCustomerDrawer} variant='temporary' onSave={OnCustomerSave} ></CustomerEditDrawer>
            )}


            <Drawer
              PaperProps={{ style: { width: 'auto' } }}
              // PaperProps={{ style: { position: 'absolute' } }}
              // BackdropProps={{ style: { position: 'absolute' } }}
              // ModalProps={{
              //   container: document.getElementById('drawer-container'),
              //   style: { position: 'absolute' }
              // }}
              anchor="right"
              open={openDrawer}
              classes={{ paper: classes.drawer }}
              onClose={() => OpenCloseFilter(0)}
              // variant={showFilter ? 'permanent' : 'temporary'}
              variant={'temporary'}

            >
              <Box style={{ margin: "10px" }}>
                <DrawerToolbar title="Feedback Filter" onClose={() => OpenCloseFilter(0)} />
                <Box style={{ display: "inline-block" }}>
                  <fieldset style={{ border: "1px solid silver", margin: "0px", padding: "3px", fontSize: "small" }} >
                    <legend>Survey Response Between</legend>
                    <table>
                      <tr>
                        <td>
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>                         */}
                          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                          {/* <DatePicker label="Basic example" value={startDate} onChange={(e:any) => { setStartDate(e.target.value) }} renderInput={(params:any) => <TextField {...params} />} /> */}
                          {/* </LocalizationProvider> */}

                          {/* </MuiPickersUtilsProvider> */}



                          {<TextField
                            id="startDate"
                            type="date"
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                            defaultValue={startDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: 150 }}
                          />}

                        </td>
                        <td>
                          <TextField
                            id="enddate"
                            type="date"
                            value={endDate}
                            defaultValue={endDate}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: 150, paddingLeft: "10px" }}
                          />
                        </td>

                      </tr>

                    </table>

                  </fieldset>
                </Box>
                {/* <Box>
              <TextField
                select
                type="select"
                id="dealerLocationId"
                name="dealerLocationId"
                margin="dense"
                value={dealerLocationId}
                onChange={(e) => { SetDealerLocationId(e.target.value) }}
                variant="outlined">
                {dealerLocations.map((row: any) => (
                  <MenuItem value={row.dealerId} className={classes.default} >{row.locationName}</MenuItem>
                ))}
                <MenuItem value={0} className={classes.default} >{'All Locations'}</MenuItem>
              </TextField>
            </Box> */}

                <Box>
                  {<FormControl variant="filled" classes={{ root: classes.customOutline }} style={{ border: "0px solid red", padding: "0px" }} >
                    <Select
                      disableUnderline={true}
                      variant="filled"
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={  dealerLocationIds}
                      className={classes.defaultSelect}
                      onChange={(e) => { OnLocationFilterChange(e.target.value); }}
                      input={<Input id="select-multiple-checkbox" classes={classes.customOutline} />}
                      renderValue={LocationRender}
                      margin="none"
                      // autoWidth={true}
                      style={{ border: "1px solid silver", padding: "2px", paddingLeft: "5px", marginTop: "5px" }}
                      MenuProps={MenuProps}

                    >
                      {dealerLocations.map((row: any) => (
                        <MenuItem key={row.keyId} value={row.dealerId} className={classes.default} style={{ backgroundColor: "inherits", fontSize: "small", border: "0px solid red", padding: "0px", margin: "0px" }} >
                          <Checkbox checked={dealerLocationIds.indexOf(row.dealerId) > -1} style={{ padding: "2px", margin: "0px" }} />
                          {row.locationName}
                        </MenuItem>
                      ))}
                     
                    </Select>
                  </FormControl>}
                </Box>
                <Box>
                  {/* Surveys */}
                  {<FormControl variant="filled" classes={{ root: classes.customOutline }} style={{ border: "0px solid red", padding: "0px" }} >
                    <Select
                      disableUnderline={true}
                      variant="filled"
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={surveyIds}
                      className={classes.defaultSelect}
                      onChange={(e) => { OnSurveyChange(e.target.value); }}
                      input={<Input id="select-multiple-checkbox" classes={classes.customOutline} />}
                      renderValue={SurveyRender}
                      margin="none"
                      autoWidth={true}
                      style={{ border: "1px solid silver", padding: "2px", paddingLeft: "5px", marginTop: "5px" }}
                      // MenuProps={MenuProps}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem key='-1' value={-1} className={classes.default} style={{ fontSize: "small", border: "0px solid red", padding: "0px", margin: "0px" }} >
                        <Checkbox checked={surveyIds.indexOf(-1) > -1} style={{ padding: "2px", margin: "0px" }} />
                        {'All Surveys'}
                      </MenuItem>

                      {surveysData.map((row: any) => (
                         <MenuItem key={row.id} value={row.id} className={classes.default} style={{whiteSpace:'normal',maxWidth:300, backgroundColor: "inherits", fontSize: "small", border: "0px solid red", padding: "0px", margin: "0px" }} >
                          <Checkbox checked={surveyIds.indexOf(row.id) > -1} style={{ padding: "2px", margin: "0px" }} />
                          {row.title}
                        </MenuItem>
                      ))}
                      {/* <hr></hr> */}
                    </Select>
                  </FormControl>}
                </Box>


                <Box>
                  <TextField
                    // fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                    type="text"
                    label="Keyword Search"
                    name="withInRadius"
                    onChange={(e) => { HandleSearchDataChange(e) }}
                    value={keywordSearch}
                    style={{ width: "100%" }}
                  />
                </Box>

                <Box>


                  <FormControl component="fieldset" className={classes.formControl}>
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup
                      aria-label=""
                      name="filterType"
                      className={classes.group}
                      value={filterType}
                      onChange={(e) => { SetFilterType(e.target.value) }}
                    >
                      <Box>
                        <FormControlLabel value="" control={<Radio />} label="All Responses222" />
                      </Box>

                      <Box>
                        <FormControlLabel value="Promoter" control={<Radio />} label="Promoters [9-10]" />
                        {/* <span className={classes.promoters}  > &nbsp;&nbsp;&nbsp;</span> */}
                        <span className={classes.promoters} style={{ padding: "1px 11px", border: "0px solid red", borderRadius: "50px" }}  ></span>
                      </Box>
                      <Box>
                        <FormControlLabel value="Passive" control={<Radio />} label="Passive [7-8]" />
                        {/* <span className={classes.passive} > &nbsp;&nbsp;&nbsp;</span> */}
                        <span className={classes.passive} style={{ padding: "1px 11px", border: "0px solid red", borderRadius: "50px" }}  ></span>
                      </Box>

                      <Box>
                        <FormControlLabel value="Detractor" control={<Radio />} label="Detractor [0-6]" />
                        {/* <span className={classes.detractor} > &nbsp;&nbsp;&nbsp;</span> */}
                        <span className={classes.detractor} style={{ padding: "1px 11px", border: "0px solid red", borderRadius: "50px" }}  ></span>
                      </Box>


                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Drawer>
          </Box>
        {/* </Box> */}
        {/* </Box> */}
      </Box>
    </Box>
    // </Container>

  )
}