import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  makeStyles,
  Theme,
  Link,
  Chip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { CustomerContact, CustomerTreeNodeType, CustomerTreeOption } from '../model/dealsInterfaces';
import { Autocomplete, TreeItem, TreeView } from '@material-ui/lab';
import { CustomerDto } from 'common/models';
import CustomerApi from 'components/customer/api/CustomerAPI';
import { groupBy } from 'lodash';
import { useAppDispatch, useAppSelector } from 'app';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import { DealCustomerContacts } from '../model/deal-edit-drawer';
import {
  fetchSelectedCustomer,
  setCreatedCustomer,
  showCustomerDrawer,
} from 'components/customer/components/Customers/customersSlice';

function CustomerContactTreeView({
  preSelected,
  dealCustomerContacts,
  selectedContacts,
  selectedCustomers,
  customerTreeOptions,
  enableCustomerValidation,
  nodeIdCount,
  disabledTreeView,
  setSelectedContacts,
  setSelectedCustomers,
  setCustomerTreeOptions,
  setCustomerContactDrawerState,
  setNodeIdCount,
}: {
  preSelected?: boolean;
  dealCustomerContacts: DealCustomerContacts[];
  selectedContacts: number[];
  selectedCustomers: CustomerDto[] | undefined;
  customerTreeOptions: CustomerTreeOption[];
  enableCustomerValidation: boolean;
  nodeIdCount: number;
  disabledTreeView: boolean;
  setSelectedContacts(contacts: number[]): void;
  setSelectedCustomers(customers: CustomerDto[] | undefined): void;
  setCustomerTreeOptions(options: CustomerTreeOption[]): void;
  setCustomerContactDrawerState(value: boolean): void;
  setNodeIdCount(value: number): void;
}) {
  const useStyles = makeStyles((theme: Theme) => ({
    textFieldPadding: {
      paddingBottom: '2%',
    },
    drawerFieldContainer: {
      paddingRight: '2%',
      paddingLeft: '2%',
    },
    errorMsg: {
      color: '#e53935',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '-0.06px',
      marginLeft: '10px',
    },
    contactsBox: {
      marginBottom: '15px',
      marginTop: '5px',
      maxHeight: '200px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    addIcon: {
      cursor: 'pointer',
      float: 'right',
      padding: '7px',
    },
    hyperLink: {
      fontSize: 'small',
      marginTop: '12px',
      paddingLeft: '20px',
    },
    disableTreeView: {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  }));

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const dmsDealerId = useAppSelector((state) => state.user.dmsDealerId);
  const createdCustomer = useAppSelector((state) => state.customers.createdCustomer);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  const [contactsLoading, setContactsLoading] = useState(false);
  const [openCustomerDropdown, setOpenCustomerDropdown] = useState(false);
  const [customerOptions, setCustomerOptions] = useState<CustomerDto[]>([]);
  const [customerloading, setCustomerLoading] = useState(false);

  const expandedIds = Array.from({ length: nodeIdCount }).map((x: any, i: number): string => i.toString());
  const onAddCustomerBtn = () => dispatch(showCustomerDrawer({ drawerType: 'CUSTOMER_ADD', drawerProps: {} }));
  const onEditCustomerBtn = (crmCustomerId: number) => dispatch(fetchSelectedCustomer(crmCustomerId));


  useEffect(() => {
    setExistingCustomersAndContacts();
  }, [dealCustomerContacts]);

  useEffect(() => {
    if (selectedCustomers && selectedCustomers.length > 0 && customerTreeOptions.length == 0 && preSelected) {
      const latestCustomer = selectedCustomers[selectedCustomers.length - 1];
      getCustomerContacts(latestCustomer);
    }
  }, []);

  useEffect(() => {
    if (createdCustomer) {
      let custName = '';
      if (dmsTypeId === 1) {
        custName = createdCustomer.name;
      } else if (createdCustomer.organizationName && !createdCustomer.firstName && !createdCustomer.lastName) {
        custName = `${createdCustomer.organizationName}`;
      } else if (dmsTypeId === 0 && createdCustomer.organizationName) {
        custName = `${createdCustomer.lastName},  ${createdCustomer.firstName} - ${createdCustomer.organizationName}`;
      } else if (dmsTypeId === 9 && createdCustomer.organizationName) {
        custName = `${createdCustomer.firstName}  ${createdCustomer.lastName} - ${createdCustomer.organizationName}`;
      } else if (dmsTypeId === 0) {
        custName = `${createdCustomer.lastName},  ${createdCustomer.firstName}`;
      } else if (dmsTypeId === 9) {
        custName = `${createdCustomer.firstName}  ${createdCustomer.lastName}`;
      }
      const newlyCreatedCust: CustomerDto = {
        id: createdCustomer.crmCustomerId,
        name: custName,
        dealerId: createdCustomer.dealerId,
        cellPhone: '',
        verificationStatus: false,
        optIn: 0,
        city: '',
        stateProvince: '',
        zipPostal: '',
      };

      if (selectedCustomers) {
        setSelectedCustomers([...selectedCustomers, newlyCreatedCust]);
        dispatch(setCreatedCustomer(undefined));
      }
    }
  }, [createdCustomer]);

  function setExistingCustomersAndContacts() {
    //Set existing customers
    if (dealCustomerContacts.length > 0) {
      let existingCustomers = dealCustomerContacts.map((x) => ({
        id: x.customerId,
        name: x.customerName,
      })) as CustomerDto[];
      //Get unique customers
      let uniqueExistingCustomers: CustomerDto[] = [];
      existingCustomers.filter((item) => {
        const i = uniqueExistingCustomers.findIndex((x) => x.id == item.id);
        if (i <= -1) {
          uniqueExistingCustomers.push(item);
        }
        return null;
      });
      setSelectedCustomers(uniqueExistingCustomers);
    }

    //Set existing contacts
    if (dealCustomerContacts.filter((x) => x.contactId).length > 0) {
      const customerContacts = dealCustomerContacts.filter((x) => x.contactId);
      const contactsPerCustomer = groupBy(customerContacts, 'customerId');
      let index = nodeIdCount;

      const contactsTree = Object.entries(contactsPerCustomer).map(([key, value]): CustomerTreeOption => {
        return {
          type: 'customer',
          title: value[0].customerName,
          value: parseInt(key, 10),
          nodeId: index++,
          nodes: value.map(
            (contact: DealCustomerContacts): CustomerTreeOption => ({
              type: 'contact',
              title: contact.contactName,
              value: contact.contactId,
              nodeId: index++,
              customerId: contact.customerId,
            }),
          ),
        };
      });

      setCustomerTreeOptions(contactsTree);
      setNodeIdCount(index);

      const selectedContacts = dealCustomerContacts.filter((x) => x.selected).map((x) => x.contactId);
      setSelectedContacts(selectedContacts);
    }
  }

  const onCustomerDropdownChange = async (value: string) => {
    setCustomerLoading(true);
    let data = await CustomerAPI.getCustomers(dealerId, 0, 10, value);
    if (data) {
      let customers = data?.customers.map((x: any) => ({
        name: `${x.name} ${
          dmsTypeId != 1 && x.organizationName && x.organizationName != '' ? ' - ' + x.organizationName : ''
        }`,
        id: x.id,
      }));
      setCustomerOptions(customers);
    } else {
      setCustomerOptions([]);
    }
    setCustomerLoading(false);
  };

  function setCustomersAndGetContacts(selectedCustomers: CustomerDto[], isDeleted: boolean) {
    setSelectedCustomers(selectedCustomers);

    if (isDeleted) {
      const selectedCustomerIds = selectedCustomers.map((y) => y.id);
      const existingcustomerTreeOptions = [...customerTreeOptions].filter((x) => selectedCustomerIds.includes(x.value));
      setCustomerTreeOptions(existingcustomerTreeOptions);

      const existingSelectedContacts = existingcustomerTreeOptions
        .filter((x) => x.nodes?.filter((x) => selectedContacts.includes(x.value)))
        .map((x) => x.value);
      setSelectedContacts(existingSelectedContacts);
      return;
    }

    if (selectedCustomers.length > 0) {
      const latestCustomer = selectedCustomers[selectedCustomers.length - 1];
      getCustomerContacts(latestCustomer);
    }
  }

  async function getCustomerContacts(customer: CustomerDto) {
    let obj: any = new Object();
    obj.DealerId = dealerId;
    obj.crmCustomerId = customer.id;
    const jsonString = JSON.stringify(obj);
    setContactsLoading(true);
    const customerContacts = (await CustomerApi.GetContacts(jsonString)) as CustomerContact[];

    if (customerContacts) {
      const contactsPerCustomer = groupBy(customerContacts, 'CrmCustomerId');
      let index = nodeIdCount;

      let contactsTree: CustomerTreeOption[] = [];
      if (customerContacts.length > 0) {
        contactsTree = Object.entries(contactsPerCustomer).map(([key, value]): CustomerTreeOption => {
          return {
            type: 'customer',
            title: customer.name,
            value: parseInt(key, 10),
            nodeId: index++,
            nodes: value.map(
              (contact: CustomerContact): CustomerTreeOption => ({
                type: 'contact',
                title: contact.Name,
                value: contact.Id,
                nodeId: index++,
                customerId: contact.CrmCustomerId,
              }),
            ),
          };
        });
      }

      setCustomerTreeOptions([...customerTreeOptions, ...contactsTree]);
      setNodeIdCount(index);
      setContactsLoading(false);
    }
  }

  function RenderLabel({
    title,
    type,
    checked,
    onClick,
  }: {
    title: string;
    type: 'customer' | 'contact';
    checked: boolean;
    onClick(): void;
  }): React.ReactElement {
    return (
      <ListItem dense component="div" disableGutters>
        <ListItemIcon>
          <Checkbox onClick={onClick} checked={checked} size="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant={type === 'contact' ? 'subtitle2' : 'body1'}
            style={{ fontWeight: type === 'customer' ? 'bold' : 'normal' }}>
            {title}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }

  function CutomerTreeContactsItem({
    nodeId,
    title,
    nodes,
    customerId,
    selectedContactsIds,
    onSelect,
  }: {
    nodeId: string;
    title: string;
    customerId: number;
    nodes: CustomerTreeOption[];
    selectedContactsIds: number[];
    onSelect(type: CustomerTreeNodeType, id: number, select: boolean): void;
  }): React.ReactElement | null {
    if (nodes.length === 0) {
      return null;
    }
    const isCustomerSelected = selectedContactsIds.length
      ? nodes.map((dep) => dep.value).every((x) => selectedContactsIds.includes(x))
      : false;
    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={
          <Grid container item xs={12}>
            <Grid container item xs={9}>
              <RenderLabel
                title={title}
                type="customer"
                checked={isCustomerSelected}
                onClick={(): void => onSelect('customer', customerId, isCustomerSelected)}
              />
            </Grid>
            <Grid container item xs={3}>
              <Link
                href="#"
                underline="hover"
                className={classes.hyperLink}
                onClick={() => {
                  onEditCustomerBtn(customerId);
                }}>
                See Details
              </Link>
            </Grid>
          </Grid>
        }>
        {nodes.map((custOp) => (
          <TreeItem
            key={custOp.value}
            nodeId={custOp.nodeId.toString()}
            label={
              <RenderLabel
                title={custOp.title}
                type="contact"
                checked={selectedContactsIds.indexOf(custOp.value) !== -1}
                onClick={(): void =>
                  onSelect('contact', custOp.value, selectedContactsIds.indexOf(custOp.value) !== -1)
                }
              />
            }
          />
        ))}
      </TreeItem>
    );
  }

  function CustomerTreeItem({
    customerTreeOption,
    onSelect,
    selectedCutomerIds,
  }: {
    customerTreeOption: CustomerTreeOption;
    onSelect(type: CustomerTreeNodeType, id: number, select: boolean): void;
    selectedCutomerIds: number[];
  }): React.ReactElement | null {
    if (customerTreeOption.type === 'customer' && customerTreeOption.nodes) {
      return (
        <CutomerTreeContactsItem
          nodeId={customerTreeOption.nodeId.toString()}
          title={customerTreeOption.title}
          nodes={customerTreeOption.nodes}
          customerId={customerTreeOption.value}
          selectedContactsIds={selectedCutomerIds}
          onSelect={onSelect}
        />
      );
    }
    return null;
  }

  const treeItems = customerTreeOptions.map((opt) => (
    <CustomerTreeItem
      key={opt.nodeId}
      customerTreeOption={opt}
      onSelect={handleSelect}
      selectedCutomerIds={selectedContacts}
    />
  ));

  function handleSelect(type: CustomerTreeNodeType, id: number, selected: boolean): void {
    if (selected === true) {
      if (type === 'customer') {
        const contactsId = customerTreeOptions.find((x) => x.value == id)?.nodes?.map((x) => x.value) as number[];
        setSelectedContacts([...selectedContacts.filter((x) => !contactsId.includes(x))]);
      } else if (type === 'contact') {
        setSelectedContacts([...selectedContacts.filter((x) => x !== id)]);
      }
    } else if (selected === false) {
      if (type === 'customer') {
        const contactsId = customerTreeOptions.find((x) => x.value == id)?.nodes?.map((x) => x.value) as number[];
        setSelectedContacts([...selectedContacts, ...contactsId]);
      } else if (type === 'contact') {
        setSelectedContacts([...selectedContacts, id]);
      }
    }
  }

  const CustomerChip = (props: any) => {
    return (
      <Chip
        {...props}
        onClick={() => {
          onEditCustomerBtn(props.customerId);
        }}
      />
    );
  };

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={11}>
          {!preSelected? 
          <Autocomplete
            multiple
            onChange={(event: any, values: CustomerDto[]): void => {
              const isDeleted = event.currentTarget.tagName === 'svg';
              setCustomersAndGetContacts(values, isDeleted);
            }}
            open={openCustomerDropdown}
            onOpen={() => {
              setOpenCustomerDropdown(true);
            }}
            onClose={() => {
              setOpenCustomerDropdown(false);
            }}
            getOptionLabel={(option: any) => option.name || ''}
            options={customerOptions}
            filterOptions={(customerOptions) => customerOptions}
            disabled={disabledTreeView}
            value={selectedCustomers}
            getOptionSelected={(option: CustomerDto, value: CustomerDto): boolean => option.id === value.id}
            loading={customerloading}
            noOptionsText="Please type a customer name"
            renderInput={(params) => (
              <TextField
                className={classes.textFieldPadding}
                {...params}
                label="Type a customer name"
                error={enableCustomerValidation && selectedCustomers?.length === 0}
                helperText={enableCustomerValidation && selectedCustomers?.length === 0 && 'Customer Required'}
                variant="outlined"
                fullWidth
                size="small"
                onChange={(ev) => {
                  if (ev.target.value !== '' || ev.target.value !== null) {
                    onCustomerDropdownChange(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {customerloading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                style={{ color: 'rgb(50, 50, 50) !important' }}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomerChip {...getTagProps({ index })} customerId={option.id} label={option.name} />
              ));
            }}
          /> : <TextField
          className={classes.textFieldPadding}
          fullWidth
          label= {'Customer'}
          size="medium"
          variant="outlined"
          disabled={true}
          InputProps={{
            startAdornment: true && <CustomerChip customerId={selectedCustomers && selectedCustomers[0]? selectedCustomers[0].id : null} label={selectedCustomers && selectedCustomers[0]? selectedCustomers[0].name : ''} />,
          }}
        />}
        </Grid>
        <Grid item xs={1}>
          {!disabledTreeView && !preSelected && (
            <AddIcon
              id="addCustomerBtn"
              onClick={onAddCustomerBtn}
              className={classes.addIcon}
            />
          )}
        </Grid>
      </Grid>
      {selectedCustomers && selectedCustomers.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={11}>
            {contactsLoading ? (
              <Box textAlign="center" className={classes.contactsBox}>
                <CircularProgress color="inherit" size={40} />
              </Box>
            ) : (
              <Box flexGrow={1} overflow="scroll" className={classes.contactsBox}>
                {customerTreeOptions?.length && treeItems.filter((x) => x !== null).length ? (
                  <TreeView expanded={expandedIds} className={disabledTreeView ? classes.disableTreeView : ''}>
                    {treeItems}
                  </TreeView>
                ) : (
                  <Box margin="auto" textAlign="center">
                    <Typography>No Customer Contacts Available</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={1}>
            {!disabledTreeView && (
              <AddIcon onClick={(e: any) => setCustomerContactDrawerState(true)} className={classes.addIcon} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CustomerContactTreeView;
