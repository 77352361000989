import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { PageHeader } from 'common/components/layout';
import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@material-ui/icons/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArchive,
  faCancel,
  faDollarSign,
  faUserPlus,
  faArrowCircleRight,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { store, useAppDispatch, useAppSelector } from 'app';
import {
  DealsResponse,
  DealItem,
  UpdateDealPosition,
  FilterDeals,
  PipelineListResponse,
  PipelineUsersRequest,
  PersistantFilterDeals,
} from './model/dealsInterfaces';
import { AssignDealDrawer } from './drawer/assignDealDrawer';
import { WonDealDrawer } from './drawer/wonDealDrawer';
import { LostDealDrawer } from './drawer/lostDealDrawer';
import { ArchiveDealDrawer } from './drawer/archiveDealDrawer';
import DealEditDrawer from './drawer/deal-edit-drawer';
import dealsApi from './apis/deals-api';
import AddNewDeal from './drawer/addNewDealDrawer';
import { FilterDealDrawer } from './drawer/filterDealsDrawer';
import AppSnackbar from 'components/snackbar/snackbar';
import UsersContext from './contexts/deal-context';
import useStyles from './styles/deals-styles';
import LinearProgress from '@mui/material/LinearProgress';
import User from 'components/setting/models/user';
import { setCreatedCustomer } from 'components/customer/components/Customers/customersSlice';
import MoveDealDrawer from './drawer/move-deal-drawer';
import { SignalRContext } from 'components/layouts/DashboardLayout/signalr-context';
import './styles/deals-overide.css';
import { showSnackbar } from 'components/root-snackbar';
import { DealStatusEnum } from './model/DealStatusEnum';
import moment from 'moment';
import DealerAPI from 'components/authentication/api/dealer-api';
import { PersistentAppData } from 'common/models';
import { PipelineStageList } from './model/deal-edit-drawer';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LeadSourceAPI, { DealLeadTags } from 'components/setting/api/manage-lead-source-api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

//Adding comment for redeploying web project
let selectedpipelineId = 0;
let selectedFilters: FilterDeals = {
  isFilter: false,
  statusId: 1,
  ageValue: 0,
};
export default function Deals() {
  //#region ALL variables
  const dmsDealerId = useAppSelector((state) => state.user.dmsDealerId);
  const crmUserId = useAppSelector((state) => state.user.crmUserId);
  const localDealFilter = `DealsFilter_${dmsDealerId}_${crmUserId}`;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [persistFilter] = useState<PersistantFilterDeals>(() => {
    const localFilterData = localStorage.getItem(localDealFilter);
    if (localFilterData === null) {
      const data: PersistantFilterDeals = {
        pipeId: 0,
        request: {
          isFilter: false,
          statusId: 1,
          ageValue: 0,
        },
      };
      localStorage.setItem(
        localDealFilter,
        JSON.stringify({ pipeId: data.pipeId, request: data.request }),
      );
      return data;
    }
    return JSON.parse(localFilterData);
  });
  const containerClassName = clsx(classes.container);
  const currentUserId = useAppSelector((state) => state.user.crmUserId);
  const isCurrentUserAdmin = useAppSelector((state) => state.user.isUserAdmin);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const signalRConnection = useContext(SignalRContext);
  const query = new URLSearchParams(location.search);
  //#endregion

  //#region All States
  const [pipeAvailable, setPipelineAvailable] = useState(true);
  const [loadingPipline, setLoadingPipeline] = useState(false);
  const [columns, setColumns] = useState<DealsResponse[]>([]);
  const [users, setPipelineUsers] = useState<User[] | undefined>([]);
  const [dealsTotalAmount, setTotalAmount] = useState<string>('');
  const [selectedEditDeal, setSelectedEditDeal] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [pipelineList, setPipelineList] = useState<PipelineListResponse[]>([]);
  const [pipelineId, setPipelineId] = useState<number>(persistFilter.pipeId);
  const [disablePipelineDD, setDisablePipelineDD] = useState<boolean>(false);
  const [departments, setPipelineDepartments] = useState<any[] | undefined>([]);
  const [filterState, setFilterState] = useState<FilterDeals>(persistFilter.request);
  //#endregion

  //#region Deals Drawer States
  const [addDealDrawerState, setAddDealDrawerState] = useState(false);
  const [assignDealDrawerState, setAssignDealDrawerState] = useState(false);
  const [lostDealDrawerState, setLostDealDrawerState] = useState(false);
  const [wonDealDrawerState, setWonDealDrawerState] = useState(false);
  const [archiveDealDrawerState, setArchiveDealDrawerState] = useState(false);
  const [moveDealDrawerState, setMoveDealDrawerState] = useState(false);
  const [filterDealDrawerState, setFilterDealDrawerState] = useState(false);
  const [dealEditDrawerState, setDealEditDrawerState] = useState(false);
  const [filterApplyStatus, setFilterApplyStatus] = useState<boolean>(false);
  const [pipelineStages, setPipelineStages] = useState<PipelineStageList[]>([]);
  const [leadTags, setLeadTags] = useState<DealLeadTags[]>([]);

  //#endregion

  useEffect(() => {
    async function fetchData() {
      const response = await LeadSourceAPI.getLeadSources();
      setLeadTags(response);
    }
    fetchData();
  }, []);
  //#region Sorting
  const setSortOrder = (columnId: number) => {
    let items = [...columns];
    let col = items.find((x) => x.id === columnId);
    if (col) {
      col.sortOrder = (col.sortOrder === 'asc' || col.sortOrder === undefined) ? 'desc' : 'asc';
      col.dealItems.sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) {
          return -1;
        }
        // If b is favorite and a is not, b should come first
        if (b.isFavorite && !a.isFavorite) {
          return 1;
        }
        const dateA = new Date(a.createdDate || 0);
        const dateB = new Date(b.createdDate || 0);
        if (col && col.sortOrder === 'asc') {
          return dateA.getTime() - dateB.getTime();
        } else {
          return dateB.getTime() - dateA.getTime();
        }
      });
    }
    setColumns(items);
  };
  //#endregion

  //#region Deal Favorite Filter
  const setDealFavorite = async (columnId: number, itemId: number) => {
    let items = [...columns];
    const col=items.find((x) => x.id === columnId);
    const item = col && col.dealItems.find((x) => x.id === itemId);
    if(item && !item.isFavorite){
      item.isFavorite = true;
    }else if(item && item.isFavorite){
      item.isFavorite = false;
    }
    await dealsApi.PipelineDeals.updateDealIsFavorite(item?.id!, item?.isFavorite!);
    setColumns(items);
  };
  //#endregion

  useEffect(() => {
    const stages: PipelineStageList[] = [];
     columns.forEach((column) => {
       let stage: PipelineStageList = {
        stageId: column.id,
        stageName: column.name,
        stagePosition: column.position,
       };
       stages.push(stage)
     })
     setPipelineStages(stages)
  }, [columns])
  //#region Deals Functions
  async function getPipelineList() {
    try {
      await dealsApi.PipelineDeals.getDealsPipelinesDD(dmsDealerIdCrm).then((data) => {
        if (data && data.length > 0) {
          setPipelineList(data);
          if (pipelineId <= 0) {
            setPipelineDepartments(data[0].departmentIds);
            setPipelineId(data[0].pipelineId);
            selectedpipelineId = data[0].pipelineId;
          } else {
            const index = data.findIndex((x) => x.pipelineId === pipelineId);
            setPipelineDepartments(data[index].departmentIds);
            setPipelineId(pipelineId);
            selectedpipelineId = pipelineId;
            setFilterApplyStatus(!filterApplyStatus);
          }
        } else {
          setPipelineAvailable(false);
        }
      });
    } catch (error: any) {
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }

  function SumDealsAmount(deals: DealItem[]) {
    let sum: number = 0;
    deals
      .filter(
        (x) =>
          searchValue.length == 0 ||
          x.title.toLowerCase().includes(searchValue.toLowerCase()) ||
          x.assignedTo?.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          x.customer?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .forEach((a: DealItem) => (sum += a.amount ?? 0));

    return sum;
  }

  function SumDealsCount(deals: DealItem[]) {
    let count: number = deals.filter(
      (x) =>
        searchValue.length == 0 ||
        x.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        x.assignedTo?.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        x.customer?.toLowerCase().includes(searchValue.toLowerCase()),
    ).length;

    return count;
  }

  const getSelectedContent = (columns: DealsResponse[]) => {
    let selectedContent: DealItem[] = [];
    columns.map((column) => {
      column.dealItems.map((item) => {
        if (item.selected) selectedContent.push(item);
      });
    });
    return selectedContent;
  };

  const selectedContent = getSelectedContent(columns);
  const sumContent = selectedContent.length;

  function SumDealsTotalAmount(dealPipelines: DealsResponse[]) {
    let sumDeals = 0;
    dealPipelines.forEach((a: DealsResponse) => (sumDeals += SumDealsAmount(a.dealItems) ?? 0));

    let totalDeals = 0;
    dealPipelines.forEach((a: DealsResponse) => (totalDeals += SumDealsCount(a.dealItems) ?? 0));

    return `${sumDeals.toLocaleString('en-US', { minimumFractionDigits: 2 })} - ${totalDeals.toString()} ${
      totalDeals === 1 ? 'Deal' : 'Deals'
    }`;
  }

  async function GetDealsList(pipeId: number, request: FilterDeals) {
    try {
      const pipelineIdFromQuery = query.get('pipelineId');
      if (pipelineIdFromQuery && pipeId != Number(pipelineIdFromQuery)) {
        setPipelineId(Number(pipelineIdFromQuery));
        selectedpipelineId = Number(pipelineIdFromQuery);
        pipeId = Number(pipelineIdFromQuery);
      }

      setLoadingPipeline(true);
      const response = await dealsApi.PipelineDeals.getDeals(dmsDealerIdCrm, pipeId, request);
      localStorage.setItem(localDealFilter, JSON.stringify({ pipeId: pipeId, request: request }));
      const addtoDb:PersistentAppData={
        crmUserId: crmUserId,
        dmsDealerId: dmsDealerId,
        type: localDealFilter,
        data: JSON.stringify({ pipeId: pipeId, request: request }),
      };
      await DealerAPI.updatePersistentAppData(addtoDb);
      setColumns([]);
      setColumns(response);
      setTotalAmount(SumDealsTotalAmount(response));
      setLoadingPipeline(false);

      if (pipelineIdFromQuery) openDealUsingQueryParamDealId(response);
    } catch (error: any) {
      setLoadingPipeline(false);
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }

  const setItemSelection = (columnId: number, itemId: number, checked: boolean) => {
    let items = [...columns];
    let col = items.filter((x) => x.id === columnId)[0];
    let item = col.dealItems.filter((x) => x.id === itemId)[0];
    item.selected = checked;
    setColumns(items);
  };

  async function setFilter(req: FilterDeals) {
    setFilterState(req);
    selectedFilters = req;
    if (req.isFilter || persistFilter.pipeId > 0) {
      await GetDealsList(pipelineId, req);
    }
  }

  function openDealEditDrawer(dealItem: DealItem) {
    columns.forEach((x) => x.dealItems.forEach((y) => (y.selected = false)));
    setSelectedEditDeal(dealItem);
    setDealEditDrawerState(true);
  }

  function openDealUsingQueryParamDealId(response: DealsResponse[]) {
    const dealIdFromQuery = query.get('dealId');

    if (dealIdFromQuery) {
      if (pipelineList?.length > 0 && response?.length > 0) {
        const dealObj = response
          .find((x) => x.dealItems?.find((x) => x.id == Number(dealIdFromQuery)))
          ?.dealItems.find((x) => x.id == Number(dealIdFromQuery));
        if (dealObj) openDealEditDrawer(dealObj);
      }
      setDisablePipelineDD(true);
    }
  }

  async function GetDeals() {
    if (pipelineList && pipelineList.length > 0) {
      const pId = pipelineId === 0 ? pipelineList[0].pipelineId : pipelineId;
      await GetDealsList(pId, filterState);
    }
  }

  async function GetPipelineUsers() {
    const request: PipelineUsersRequest = {
      pipelineDepartments: departments,
    };
    const response = await dealsApi.PipelineDeals.getPipelineUsers(request);
    setPipelineUsers(response);
  }

  async function reopenSelectedDeals(): Promise<void> {
    try {
      const selectedDealsId = selectedContent.map((x) => x.id);
      await dealsApi.PipelineDeals.reopenDeal(selectedDealsId);

      showSnackBar({ type: 'success', message: `Deal${selectedDealsId.length > 1 ? 's' : ''} reopened successfully.` });
    } catch (error: any) {
      showSnackBar({ type: 'error', message: `Unable to reopen.` });
      throw new Error('Unable to reopen deal.');
    }
  }
  //#endregion

  //#region Drag Function
  const onDragEnd = (result: DropResult, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.dealItems];
      const destItems = [...destColumn.dealItems];
      const [removed] = sourceItems.splice(source.index, 1);

      if (removed.dealStateId != DealStatusEnum.Open) return;
      if (!removed.assignedTo) {
        store.dispatch(
          showSnackbar({ type: 'error', message: 'An unassigned deal requires an assignment to move to a new stage.' }),
        );
        return;
      }

      removed.days = '0';
      destItems.splice(destination.index, 0, removed);
      let k: any = [...columns];
      k[source.droppableId] = {
        ...sourceColumn,
        dealItems: sourceItems,
      };
      k[destination.droppableId] = {
        ...destColumn,
        dealItems: destItems,
      };
      setColumns(k);

      //Retain deal card position on DB level
      let currentDealPositionChangeDate = new Date();
      if (destItems.length > 1 && destination.index > 0) {
        const prevDealPositionChangeDate = new Date(destItems[destination.index - 1]?.positionChangeDate);
        currentDealPositionChangeDate = new Date(prevDealPositionChangeDate.getTime() + 1); // add 1 millisecond
      } else if (destItems.length > 1 && destination.index === 0) {
        const nextDealPositionChangeDate = new Date(destItems[destination.index + 1]?.positionChangeDate);
        currentDealPositionChangeDate = new Date(nextDealPositionChangeDate.getTime() - 1); // remove 1 millisecond
      }

      const dealObj: UpdateDealPosition = {
        dealId: removed.id,
        position: destColumn.position,
        pipelinestageId: destColumn.id,
        positionChangeDate: moment(currentDealPositionChangeDate).format('YYYY-MM-DD HH:mm:ss.SSS a'),
        positionType: 'STAGE',
        nextStageDealIds: destItems.slice(destination.index + 1).map((x) => x.id),
      };
      dealsApi.PipelineDeals.updateDealPosition(dealObj);
      //Retain deal card position on DB level
    } else {
      if(columns[source.droppableId].dealItems[source.index].isFavorite) return;
      const column = columns[source.droppableId];
      const copiedItems = [...column.dealItems];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      let k: any = [...columns];
      (k[source.droppableId] = {
        ...column,
        dealItems: copiedItems,
      }),
        setColumns(k);

      //Retain deal card position on DB level
      let currentDealPositionChangeDate = new Date();
      if (copiedItems.length > 1 && destination.index > 0) {
        const prevDealPositionChangeDate = new Date(copiedItems[destination.index - 1]?.positionChangeDate);
        currentDealPositionChangeDate = new Date(prevDealPositionChangeDate.getTime() + 1); // add 1 millisecond
      } else if (copiedItems.length > 1 && destination.index === 0) {
        const nextDealPositionChangeDate = new Date(copiedItems[destination.index + 1]?.positionChangeDate);
        currentDealPositionChangeDate = new Date(nextDealPositionChangeDate.getTime() - 1); // remove 1 millisecond
      }

      const dealObj: UpdateDealPosition = {
        dealId: copiedItems[destination.index].id,
        position: copiedItems[destination.index].position,
        pipelinestageId: copiedItems[destination.index].id,
        positionChangeDate: moment(currentDealPositionChangeDate).format('YYYY-MM-DD HH:mm:ss.SSS a'),
        positionType: 'DEAL',
        nextStageDealIds: copiedItems.slice(destination.index + 1).map((x) => x.id),
      };
      dealsApi.PipelineDeals.updateDealPosition(dealObj);
      //Retain deal card position on DB level
    }
  };
  //#endregion

  useEffect(() => {
    if (pipelineList.length <= 0) {
      //Clearing createdCustomer state on deals page load
      dispatch(setCreatedCustomer(undefined));
      getPipelineList();
    } else {
      GetPipelineUsers();
      GetDeals();
    }
  }, [pipelineId, filterApplyStatus]);

  useEffect(() => {
    function dealsBoardUpdated(notification: Notification) {
      GetDealsList(selectedpipelineId, selectedFilters);
    }

    if (signalRConnection) {
      signalRConnection.on('DealsBoardUpdated', dealsBoardUpdated);
    }
    return (): void => {
      if (signalRConnection) {
        signalRConnection.off('DealsBoardUpdated', dealsBoardUpdated);
      }
    };
  }, [signalRConnection]);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = () => {
    const exportData = columns.flatMap((x:DealsResponse) => 
      x.dealItems.map((y:DealItem) => ({
        'Name':y.title.toUpperCase(),
        'Value':'$'+y.amount ?? 0,
        'Stage of Deal': x.name.toUpperCase(),
        'Assign To': y.assignedTo,
        'Products': y.productDetails,
        'Deal Source': leadTags.find((z) => z.id === y.dealTypeId)?.leadTagName,
        'Deal Originated': moment(y.createdDate).format('M-D-YYYY'),
        'Deal Closed': y.dealCloseDate && moment(y.dealCloseDate).format('M-D-YYYY'),
        'Tax Exempt': y.isTaxExempted ? 'Yes' : 'No',
      }))
    );
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'DealsExport' + fileExtension);
  };
  return (
    <UsersContext.Provider
      value={{ user: users, pipeline: pipelineList, defaultPipelineId: pipelineId, filterRequest: filterState }}>
      <Container className={containerClassName} maxWidth="xl">
        <div className="dealBoard" style={{ width: '100%', marginTop: '1em' }}>
          <Grid container>
            <Grid item xs={11}>
              <Box width="100%" style={{ clear: 'both' }}>
                <Box style={{ float: 'left' }}>
                  <PageHeader title="Deals" />
                </Box>
                {dealsTotalAmount != '' && (
                  <Typography
                    variant="h4"
                    style={{
                      float: 'right',
                      padding: '1px',
                      backgroundColor: 'white',
                      width: '14em',
                      textAlign: 'center',
                    }}>
                    ${dealsTotalAmount}
                  </Typography>
                )}
              </Box>
              <Card variant="outlined" className={classes.searchCard}>
                <Box
                  mr={2}
                  style={{
                    display: 'inline-flex',
                    marginTop: 'Inherit',
                    width: '100%',
                  }}>
                  {columns &&
                  columns.filter((x) => x.dealItems.filter((y) => y.selected === true).length > 0).length > 0 ? (
                    <></>
                  ) : (
                    <Box mr={2} mt={-1}>
                      <Fab
                        className={classes.primaryAddBtn}
                        onClick={(): void => {
                          setAddDealDrawerState(true);
                        }}
                        size="medium"
                        color="primary"
                        >
                        <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                          <AddIcon />
                          <Typography style={{ fontSize: '8px', marginTop: '-15px' }}>New</Typography>
                        </Box>
                      </Fab>
                    </Box>
                  )}

                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item xs={9}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        placeholder="Search by Customer Name, Assigned Person and Deal Title"
                        style={{ width: '100%', background: 'white' }}
                        InputProps={{
                          startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
                        }}></TextField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        select
                        variant="outlined"
                        focused={false}
                        fullWidth
                        size="small"
                        value={pipelineId}
                        disabled={disablePipelineDD}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              className: classes.muiPopoverPaper,
                            },
                          },
                        }}
                        onChange={(e) => {
                          const pId = Number(e.target.value);
                          setPipelineId(pId);
                          selectedpipelineId = pId;
                          const departmentIds = pipelineList.find((x) => x.pipelineId === pId)?.departmentIds;
                          setPipelineDepartments(departmentIds);
                        }}
                        label="Select Pipeline"
                        style={{ width: '100%', background: 'white' }}>
                        {pipelineList?.map((options) => {
                          return (
                            <MenuItem key={options.pipelineId} value={options.pipelineId}>
                              {options.pipelineName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <Box mt={1} mb={2}>
                        {selectedContent.length > 0 &&
                          (filterState?.statusId === DealStatusEnum.Open ? (
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                className={classes.primaryIconBtn}
                                onClick={(): void => {
                                  setMoveDealDrawerState(true);
                                }}
                                size="medium">
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                                &nbsp;Move
                              </Button>
                              <div style={{ marginInline: 3 }} />
                              <Button
                                className={classes.primaryIconBtn}
                                onClick={(): void => {
                                  setAssignDealDrawerState(true);
                                }}
                                size="medium">
                                <FontAwesomeIcon icon={faUserPlus} />
                                &nbsp;Assign
                              </Button>
                              <div style={{ marginInline: 3 }} />
                              <Button
                                className={classes.successIconBtn}
                                onClick={(): void => {
                                  setWonDealDrawerState(true);
                                }}
                                size="medium">
                                <FontAwesomeIcon icon={faDollarSign} />
                                &nbsp;Won
                              </Button>
                              <div style={{ marginInline: 3 }} />
                              <Button
                                className={classes.errorIconBtn}
                                onClick={(): void => {
                                  setLostDealDrawerState(true);
                                }}
                                size="medium">
                                <FontAwesomeIcon icon={faCancel} />
                                &nbsp;Lost
                              </Button>
                              <div style={{ marginInline: 3 }} />
                              <Button
                                className={classes.defaultIconBtn}
                                onClick={(): void => {
                                  setArchiveDealDrawerState(true);
                                }}
                                size="medium">
                                <FontAwesomeIcon icon={faArchive} />
                                &nbsp;Archive
                              </Button>
                            </Box>
                          ) : (
                            <Box display="flex" justifyContent="flex-end">
                              <Button className={classes.primaryIconBtn} onClick={reopenSelectedDeals} size="medium">
                                <FontAwesomeIcon icon={faRotateLeft} />
                                &nbsp;Reopen
                              </Button>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>

              {loadingPipline ? <LinearProgress /> : ''}
              <Card
                style={{
                  marginTop: 'inherit',
                  backgroundColor: 'white',
                  height: '80vh',
                  width: '100%',
                  overflow: 'auto',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}>
                {pipelineList.length > 0 && pipelineId !== 0 ? (
                  <Box my={2} className={classes.root}>
                    <Box className={classes.boxStyle}>
                      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                        {Object.entries(columns).map(([id, column]) => {
                          return (
                            // Control Margin b/w columns
                            <Box key={id} style={{ margin: 3 }}>
                              {/*Header Card and its styling*/}
                              <Card
                                variant="elevation"
                                style={{
                                  marginBottom: 5,
                                  marginTop: '-1em',
                                  display: 'flex',
                                  clipPath: 'polygon(0% 0%,92% 0%,100% 50%,100% 50%, 0% 720%)',
                                  maxHeight: '100px',
                                  userSelect: 'none',
                                  minWidth: '200px',
                                  backgroundColor: '#e4e4e5',
                                }}>
                                <CardContent>
                                  <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' mb={1}>
                                    <Typography
                                      variant="h5"
                                      title={column.name.toUpperCase()}
                                      style={{ fontWeight: 'bold' }}>
                                      {column.name.length > 15
                                        ? column.name.slice(0, 15).toUpperCase().concat('...')
                                        : column.name.toUpperCase()}
                                    </Typography>
                                    <IconButton onClick={()=>setSortOrder(column.id)} style={{padding: '0px'}}>
                                      {(column.sortOrder === 'asc' || column.sortOrder === undefined) ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                    </IconButton>
                                  </Box>                                  
                                  <Typography
                                    variant="h6"
                                    style={{
                                      marginTop: '-.5em',
                                      marginLeft: 'auto',
                                      width: '42px',
                                    }}>
                                    {/* <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}> */}
                                      {SumDealsCount(column.dealItems)}
                                    {/* </Typography>{' '} */}
                                    &nbsp;{SumDealsCount(column.dealItems) === 1 ? 'Deal' : 'Deals'}
                                  </Typography>
                                  <Box
                                    style={{
                                      width: '160px',
                                      display: 'flex',
                                      alignContent: 'space-around',
                                      marginTop: '-10px',
                                    }}>
                                    <Typography variant="h5" style={{ color: 'green', fontWeight: 'bold',marginTop: '1.2em' }}>
                                      $
                                      {SumDealsAmount(column.dealItems).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Card>
                              <Droppable droppableId={id} key={id}>
                                {(provided, snapshot) => {
                                  return (
                                    <Card
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver ? '#e7e5ff' : '#f4f4f4',
                                        padding: 4,
                                        minWidth: 200,
                                        minHeight: '100%',
                                      }}>
                                      {column.dealItems
                                        .filter(
                                          (x) =>
                                            searchValue.length == 0 ||
                                            x.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                                            x.assignedTo?.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            x.customer?.toLowerCase().includes(searchValue.toLowerCase()),
                                        )
                                        .map((item, index) => {
                                          return (
                                            <Draggable
                                              isDragDisabled={loadingPipline}
                                              key={item.id}
                                              draggableId={item.id.toString()}
                                              index={index}>
                                              {(provided, snapshot) => {
                                                const [showActions, setShowActions] = useState(false);
                                                return (
                                                  <Card
                                                    onMouseOver={() => setShowActions(true)}
                                                    onMouseLeave={() => setShowActions(false)}
                                                    variant="elevation"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps} //// to drag the items
                                                    {...provided.dragHandleProps} //// to pickup the items
                                                    style={{
                                                      maxHeight: '200px',
                                                      maxWidth: '200px',
                                                      margin: '0 0 4px 0',
                                                      minHeight: '50px',
                                                      backgroundColor: item.isAged 
                                                        ? '#ff000029' 
                                                        : snapshot.isDragging || showActions
                                                          ? '#fffaff'
                                                          : (selectedEditDeal === item && dealEditDrawerState)
                                                            ? '#f4dbff'
                                                            : 'white',
                                                      ...provided.draggableProps.style,
                                                    }}>
                                                    <CardHeader
                                                      style={{
                                                        fontWeight: 'bolder',
                                                        marginBottom: '.5em',
                                                        marginTop: '.5em',
                                                        paddingTop: 0,
                                                      }}
                                                      avatar={
                                                        !item.selected ? (
                                                          showActions && (
                                                            <Checkbox
                                                              size="small"
                                                              checked={item.selected}
                                                              disabled={loadingPipline}
                                                              onChange={(e) => {
                                                                setItemSelection(column.id, item.id, e.target.checked);
                                                              }}
                                                              style={{
                                                                padding: 0,
                                                              }}></Checkbox>
                                                          )
                                                        ) : (
                                                          <Checkbox
                                                            size="small"
                                                            checked={item.selected}
                                                            disabled={loadingPipline}
                                                            onChange={(e) => {
                                                              setItemSelection(column.id, item.id, e.target.checked);
                                                            }}
                                                            style={{
                                                              padding: 0,
                                                            }}></Checkbox>
                                                        )
                                                      }
                                                      // WARNING: Do not Remove This....!
                                                      // action={
                                                      //   !item.selected ? (
                                                      //     showActions && (
                                                      //       <IconButton
                                                      //         aria-label="settings"
                                                      //         disabled={loadingPipline}
                                                      //         className={classes.hover}
                                                      //         style={{
                                                      //           marginTop: '.2em',
                                                      //           padding: 0,
                                                      //         }}
                                                      //         onClick={() => {
                                                      //           openDealEditDrawer(item);
                                                      //         }}>
                                                      //         <MoreVertTwoToneIcon />
                                                      //       </IconButton>
                                                      //     )
                                                      //   ) : (
                                                      //     <IconButton
                                                      //       aria-label="settings"
                                                      //       disabled={loadingPipline}
                                                      //       className={classes.hover}
                                                      //       style={{
                                                      //         marginTop: '.2em',
                                                      //         padding: 0,
                                                      //       }}
                                                      //       onClick={() => {
                                                      //         openDealEditDrawer(item);
                                                      //       }}>
                                                      //       <MoreVertTwoToneIcon />
                                                      //     </IconButton>
                                                      //   )
                                                      // }
                                                      titleTypographyProps={{ variant: 'h5' }}
                                                      title={
                                                        <Link
                                                          onClick={() => openDealEditDrawer(item)}
                                                          color="inherit"
                                                          underline="hover"
                                                          style={{ cursor: 'pointer' }}>
                                                          <Tooltip title={item.title.toUpperCase()}>
                                                            <Typography
                                                              variant={
                                                                item.title.length < 15
                                                                  ? 'h5'
                                                                  : item.title.length < 41
                                                                  ? 'h6'
                                                                  : 'body2'
                                                              }
                                                              //noWrap
                                                              style={{
                                                                textAlign: 'left',
                                                                width: showActions || item.selected ? '8rem' : '10rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                              }}>
                                                              {<strong>{item.title.toUpperCase()}</strong>}
                                                            </Typography>
                                                          </Tooltip>
                                                        </Link>
                                                      }></CardHeader>
                                                    <CardContent style={{ marginTop: -33 }}>
                                                      {/* <Typography
                                                        variant="h5"
                                                        noWrap
                                                        style={{
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis',
                                                          width: '10rem',
                                                        }}>
                                                        {<strong>{item.title.toUpperCase()}</strong>}
                                                      </Typography> */}
                                                      <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        mb={1}>
                                                        <Typography
                                                          variant="h6"
                                                          component="div"
                                                          style={{
                                                            display: 'flex',
                                                          }}>
                                                          <PersonIcon fontSize="small" style={{ marginRight: 5 }} />
                                                          {item.customer}
                                                        </Typography>
                                                        <IconButton
                                                          onClick={() => setDealFavorite(column.id, item.id)}
                                                          style={{ padding: '0px' }}>
                                                          {item.isFavorite ? (
                                                            <StarIcon style={{ color: '#3f51b5' }} />
                                                          ) : (
                                                            <StarOutlineIcon />
                                                          )}
                                                        </IconButton>
                                                      </Box>
                                                      <Typography variant="h6" style={{ display: 'flex' }}>
                                                        <FormatListBulletedIcon
                                                          fontSize="small"
                                                          style={{ marginRight: 5 }}
                                                        />{' '}
                                                        {item.tasksScheduled} tasks scheduled
                                                      </Typography>
                                                      {item.assignedTo ? (
                                                        <Typography variant="h6" style={{ display: 'flex' }}>
                                                          <AssignmentOutlinedIcon
                                                            fontSize="small"
                                                            style={{ marginRight: 5 }}
                                                          />
                                                          {item.assignedTo}
                                                        </Typography>
                                                      ) : (
                                                        <Typography
                                                          style={{
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                            marginTop: 1,
                                                          }}>
                                                          <Chip
                                                            className={classes.chipStyles}
                                                            style={{ background: '#edce20' }}
                                                            size="small"
                                                            label="UNASSIGNED"
                                                          />
                                                        </Typography>
                                                      )}
                                                      {item.dealStateId != DealStatusEnum.Open && (
                                                        <Typography
                                                          style={{
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                          }}>
                                                          <Chip
                                                            className={classes.chipStyles}
                                                            style={
                                                              item.dealStateId === DealStatusEnum.Won
                                                                ? {
                                                                    background: 'green',
                                                                  }
                                                                : item.dealStateId === DealStatusEnum.Lost
                                                                ? { background: 'red' }
                                                                : {
                                                                    background: 'grey',
                                                                  }
                                                            }
                                                            size="small"
                                                            label={
                                                              item.dealStateId === DealStatusEnum.Won
                                                                ? 'WON'
                                                                : item.dealStateId === DealStatusEnum.Lost
                                                                ? 'LOST'
                                                                : item.dealStateId === DealStatusEnum.Archive
                                                                ? 'ARCHIVE'
                                                                : ''
                                                            }
                                                          />
                                                        </Typography>
                                                      )}
                                                      <Typography
                                                        variant="h5"
                                                        align="left"
                                                        className={classes.cardAmount}>
                                                        {item.amount?.toLocaleString('en-US', {
                                                          minimumFractionDigits: 2,
                                                        })}{' '}
                                                        <span className={classes.cardDay}>{item.days}d</span>
                                                      </Typography>
                                                    </CardContent>
                                                  </Card>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        })}
                                      {provided.placeholder}
                                    </Card>
                                  );
                                }}
                              </Droppable>
                            </Box>
                          );
                        })}
                      </DragDropContext>
                    </Box>
                  </Box>
                ) : (
                  <Typography
                    hidden={pipeAvailable}
                    variant="h3"
                    style={{ marginTop: 10, textAlign: 'center', color: 'grey', fontWeight: 'bold' }}>
                    Pipeline not configured. Please add stages in Settings.
                  </Typography>
                )}
              </Card>
            </Grid>
            <Grid item xs={1}>
              <Fab
                color="primary"
                onClick={() => {
                  setFilterDealDrawerState(true);
                }}
                style={{ marginLeft: '10px', marginTop: '112px' }}
                title="Filter data">
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <FilterAltIcon />
                  <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Filter</Typography>
                </Box>
              </Fab>
            </Grid>
          </Grid>
        </div>

       {pipelineStages?.length > 0 &&
        <AddNewDeal renderDeals={() => {}} open={addDealDrawerState} onClose={() => setAddDealDrawerState(false)} pipelineStages={pipelineStages}/>
       }
        <AssignDealDrawer
          open={assignDealDrawerState}
          editCount={sumContent}
          selectedColumn={selectedContent}
          renderDeals={() => {}}
          onClose={() => {
            setAssignDealDrawerState(false);
          }}></AssignDealDrawer>

        <WonDealDrawer
          open={wonDealDrawerState}
          editCount={sumContent}
          selectedColumn={selectedContent}
          renderDeals={() => {}}
          onClose={() => setWonDealDrawerState(false)}
        />

        <LostDealDrawer
          open={lostDealDrawerState}
          editCount={sumContent}
          selectedDeals={selectedContent}
          renderDeals={() => {}}
          onClose={() => setLostDealDrawerState(false)}
        />

        <ArchiveDealDrawer
          open={archiveDealDrawerState}
          editCount={sumContent}
          selectedColumn={selectedContent}
          renderDeals={() => {}}
          onClose={() => setArchiveDealDrawerState(false)}
        />

        <MoveDealDrawer
          open={moveDealDrawerState}
          editCount={sumContent}
          selectedColumn={selectedContent}
          renderDeals={() => {}}
          onClose={() => setMoveDealDrawerState(false)}
        />

        <FilterDealDrawer
          loadingDeals = {loadingPipline}
          open={filterDealDrawerState}
          defaultFilterValues={filterState}
          leadTags={leadTags}
          filterUserIds={isCurrentUserAdmin ? [] : [currentUserId]}
          setFilter={setFilter}
          onClose={() => setFilterDealDrawerState(false)}
          exportDeals={exportToCSV}
        />

        <DealEditDrawer
          open={dealEditDrawerState}
          dealItem={selectedEditDeal}
          openedFromTasksBoard={false}
          renderDeals={() => {}}
          onClose={() => setDealEditDrawerState(false)}
        />
      </Container>
    </UsersContext.Provider>
  );
}
