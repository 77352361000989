import { get, post } from 'api';
import { CustomerContactPhoneNumberResponse } from 'components/messaging/models/conversation';
import config from 'config';

const messagingUrl = config.get('api.messaging');
const customerUrl = config.get('api.customer');

const validateAllPhoneNumbers = async (validatePhoneType: any) => {
  try {
    return await post(`${messagingUrl}/ValidatePhoneNumberByDealer`, validatePhoneType).then(
      (response) => response.data,
    );
  } catch (error) {
    return null;
  }
};

const getCustomerPhoneSummary = async (dealerId: number) => {
  try {
    return await get(`${messagingUrl}/customerPhoneSummary/${dealerId}`).then((response) => response.data);
  } catch (error) {
    return null;
  }
};

const getPhoneValidationCost = async (phoneCount: number) => {
  try {
    return await get(`${messagingUrl}/phoneValidationCost/${phoneCount}`).then((resp) => resp.data);
  } catch (ex) {
    return null;
  }
};

const getAccountBalance = async () => {
  const { data } = await get(`${messagingUrl}/GetAccountBalance/`);
  return data;
};

const valdiatePhoneNumberByCustomer = async (customerIds: number[], validatePhoneTypes: any) => {
  let phoneUrl = `${customerUrl}/customers/GetCustomerPhones`.replace("v2", "");
  const { data } = await post(`${messagingUrl}/ValidatePhoneNumberByCustomer/`, {
    Url: phoneUrl,
    SelectedCustomers: customerIds,
    ValidatePhoneType: validatePhoneTypes,
  });
  return data;
};

const getCustomerContactPhoneNumbers = async (crmCustomerId: number): Promise<CustomerContactPhoneNumberResponse[] | undefined> => {
  const { data } = await get(`${customerUrl}/customerPhone/customerAndContactPhoneNumbers/${crmCustomerId}`);
  return data;
};

export default {
  validateAllPhoneNumbers,
  getCustomerPhoneSummary,
  getPhoneValidationCost,
  getAccountBalance,
  valdiatePhoneNumberByCustomer,
  getCustomerContactPhoneNumbers,
};
