import { Box, Button, FormControlLabel, Grid, makeStyles, MenuItem, Switch, TextField, Theme } from '@material-ui/core';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { LocationOption } from 'common/models/location-option';
import { useFormik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme): any => ({
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export interface NewSurveyFormValues {
  title: string;
  description: string;
  type: string;
  dealerLocationId: number;
  queryParts: boolean;
  queryEquipment: boolean;
  queryServices: boolean;
  startingHour: string;
  endingHour: string;
}

const schema = Yup.object<any>().shape<any>({
  title: Yup.string().required('Survey title is required'),
  type: Yup.string().required('Survey type required'),
});

export default function NewSurveyForm({
  selectedDealerLocationId,
  locationOptions,
  onSubmit,
}: {
  selectedDealerLocationId?: number;
  locationOptions?: LocationOption[];
  onSubmit: (values: NewSurveyFormValues) => void;
}): ReactElement {
  const initialValues = {
    title: '',
    description: '',
    type: 'Nps',
    dealerLocationId: 0,
    queryParts: false,
    queryServices: false,
    queryEquipment: false,
    startingHour: '',
    endingHour: '',
    delayForHours: 0,
  };
  if (selectedDealerLocationId !== undefined) {
    initialValues.dealerLocationId = selectedDealerLocationId;
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    enableReinitialize: true,
  });
  const classes: any = useStyles();

  return (
    <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
      <TextLimitField
        fullWidth
        autoFocus
        characters={100}
        margin="dense"
        variant="outlined"
        name="title"
        id="title"
        label="Title"
        onChange={formik.handleChange}
        placeholder="Survey Title"
        value={formik.values.title}
        error={!!formik.errors.title}
        helperText={formik.errors.title}
        required
      />
      <TextLimitField
        fullWidth
        margin="dense"
        variant="outlined"
        name="description"
        id="description"
        label="Description"
        multiline
        characters={500}
        rows={3}
        onChange={formik.handleChange}
        value={formik.values.description}
        error={!!formik.errors.description}
        helperText={formik.errors.description}
      />
      <TextField
        fullWidth
        select
        margin="dense"
        id="surveyTypList"
        variant="outlined"
        name="type"
        inputProps={{ id: 'surveyType' }}
        label="Type"
        onChange={formik.handleChange}
        value={formik.values.type}
        error={!!formik.errors.type}
        helperText={formik.errors.type}
        required>
        <MenuItem id="NpsSurveyTypeOption" className="surveyTypeOptions" value="Nps">
          Broadcast Loyalty Survey
        </MenuItem>
        <MenuItem id="AutoSurveyTypeOption" className="surveyTypeOptions" value="Auto">
          Automatic Loyalty Survey
        </MenuItem>
      </TextField>
      <TextField
        fullWidth
        select
        margin="dense"
        variant="outlined"
        name="dealerLocationId"
        inputProps={{ id: 'dealerLocationId' }}
        label="Location"
        onChange={formik.handleChange}
        value={formik.values.dealerLocationId}
        error={!!formik.errors.dealerLocationId}
        helperText={formik.errors.dealerLocationId}
        required>
        {locationOptions &&
          locationOptions.map((location) => (
            <MenuItem
              style={{ textTransform: 'capitalize' }}
              key={location.dealerLocationId}
              value={location.dealerLocationId}>
              {location.name}
            </MenuItem>
          ))}
      </TextField>
      {formik.values.type === 'Auto' && (
        <Grid direction="row">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.queryParts}
                  onChange={formik.handleChange}
                  name="queryParts"
                  color="primary"
                />
              }
              label="Send to Part customers"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.queryEquipment}
                  onChange={(e: any): void => {
                    formik.handleChange(e);
                  }}
                  name="queryEquipment"
                  color="primary"
                />
              }
              label="Send to Equipment customers"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.queryServices}
                  onChange={formik.handleChange}
                  name="queryServices"
                  color="primary"
                />
              }
              label="Send to Service customers"
            />
          </Grid>
        </Grid>
      )}

      <Box className={classes.submit}>
        <Button
          id="createSurveyBtn"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
          color="primary"
          variant="contained"
          size="medium">
          Create
        </Button>
      </Box>
    </form>
  );
}
