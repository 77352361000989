import { Box, Checkbox, Container, Drawer, MenuItem, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import * as Yup from 'yup';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';
import User, { Permission, Role } from 'components/setting/models/user';
import AppSnackbar from 'components/snackbar/snackbar';
import { PermissionList } from './permissionList';
import UserAPI from 'components/setting/api/user-api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditRoleDrawer({
  roleId,
  open,
  onClose,
  onSave
}: {
  roleId?: string;
  open: boolean;
  onClose(): void;
  onSave(value: any): void;
}): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [role, setRole] = React.useState<Role | undefined>(undefined);
  const permissions = PermissionList();

  const initialValues: {
    roleId?: string;
    roleName?: string;
    permissions: Permission[];
  } = {
    roleId: role?.roleId || "0",
    roleName: role?.roleName || '',
    permissions: role?.permissions || [],
  };

  const validationSchema = Yup.object<any>().shape<any>({
    roleName: Yup.string().required("please enter a name")
  });

  async function handleSubmit(values: any): Promise<void> {
    if(!formik.values.permissions || formik.values.permissions.length == 0){ 
      showSnackbar({ type: 'error', message: 'atleast one permission must be selected' });
      return;
    }
    console.log("reached handler");
    console.log(values);
    await onSave(values);
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema : validationSchema,
    enableReinitialize: true,
  });

  function handleUserChange(values: any): void {
    console.log(values);
    formik.setValues(
      {
        roleId : formik.values.roleId,
        roleName : formik.values.roleName,
        permissions: values,
      },
      false,
    );

  }

  React.useEffect(() => {
    async function fetchRole(): Promise<void> {
      if (roleId !== undefined) {
        const response = await UserAPI.getRoleById(dmsDealerIdCrm, roleId);
        if (!response) throw new Error('Invalid response');
        setRole(response);
      } else {
        setRole(undefined);
      }
    }
    fetchRole();
  }, [roleId]);

  return (
    <Drawer PaperProps={{ style: { width: '20%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Edit Role" onSave={()=> { console.log("clicked");  formik.handleSubmit();}} />
      <Box mb={1} />
      <Container>
        {open && (
          <form >
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              variant="outlined"
              name="roleName"
              id="roleName"
              label="Role Name"
              onChange={formik.handleChange}
              placeholder="Role Name"
              value={formik.values.roleName}
              error={!!formik.errors.roleName}
              helperText={formik.errors.roleName}
              required
            />
            <Autocomplete
              multiple
              id="tags-standard"
              value={formik.values.permissions}
              options={permissions || []}
              onChange={(event: any, values: any): void => handleUserChange(values)}
              getOptionLabel={(option: any) => option.permissionName}
              disableCloseOnSelect
              getOptionSelected={(option: Permission, value: Permission): boolean => option.permissionId === value.permissionId}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                  {option.permissionName}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="permissions"
                  id="permissions"
                  variant="outlined"
                  margin="dense"
                  label="permissions"
                  placeholder="Select Permission"
                />
              )}
            />
          </form>
        )}
      </Container>
    </Drawer>
  );
}
