import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  dealPiplineWindow: {
    height: '600px',
    overflow: 'scroll',
    padding: '10px',
  },
  pipeLineEditIcon: {
    cursor: 'pointer',
    fontSize: '1rem !important',
    marginLeft: '5px',
  },
  pipeLineClearIcon: {
    cursor: 'pointer',
    fontSize: '1.5rem !important',
    marginTop: '6px',
  },
  pipleLineBox: {
    padding: '5px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    marginTop: '15px',
  },
  stageCard: {
    marginBottom: '5px !important',
    marginTop: '1em !important',
    clipPath: 'polygon(0% 0%,92% 0%,100% 50%,100% 50%, 0% 720%)',
    height: '100px',
    width: '100px',
    backgroundColor: 'rgb(228, 228, 229)',
  },
  stageCardDeleteIcon: {
    cursor: 'pointer',
    fontSize: '1rem',
    float: 'right',
    marginRight: '10px',
  },
  stackDivider: {
    height: '90px',
    marginTop: '24px !important',
  },
  deletePipelineBtn: {
    backgroundColor: '#ff4d4d',
    color: 'white',
  },
}));

export default useStyles;
