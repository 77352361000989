import { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { hideTaskDrawer, rescheduleTask, showTaskDrawer, UpdateSelectedTask } from '../taskboardSlice';
import { TaskListObject, UserTaskStatus } from 'components/lead/models/task';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { Box, Button, ButtonGroup, Container, createStyles, Grid, makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import { SButton } from '../TaskBoardTable';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles(() =>
  createStyles({
    completedButton: {
      '&:hover': {
        backgroundColor: '#4caf50 !important',
      },
    },
  }),
);
function TaskEditDrawer(): ReactElement {
  const classes: any = useStyles();
  const dispatch = useAppDispatch();

  const btnDisabled = useAppSelector((state) => state.tasks.addTaskLoading);
  const task = useAppSelector((state) => state.tasks.selectedTask);
  const taskIsArchived = useAppSelector((state: any) => state.tasks.isArchived);

  const selectedTask = useAppSelector((state) => state.tasks.selectedTask);
  const handleTaskFormSubmit = (task: TaskListObject): void => {
    dispatch(rescheduleTask(task));
  };

  const onReScheduleTaskBtn = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_RESCHEDULE', drawerProps: {} }));
  };
  const onEditTask = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_EDIT', drawerProps: {} }));
  };
  const onDoneTaskBtn = async (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_DONE', drawerProps: {} }));
  };

  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };
  const fontColor: any = {
    style: { color: 'rgb(50, 50, 50)' },
  };
  return (
    <>
      <DrawerToolbar title={'Task Details'} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="taskSubject"
                    name="taskSubject"
                    label="Subject"
                    value={selectedTask?.taskSubject}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1} ml={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="taskStatus"
                    name="taskStatus"
                    label="Task Status"
                    value={selectedTask?.taskStatus ? UserTaskStatus[selectedTask?.taskStatusId] : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="customer"
                    name="customer"
                    label="Customer"
                    value={selectedTask?.customerName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box ml={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="assignment"
                    name="assignment"
                    label="Assignment"
                    value={selectedTask?.assignedUsername}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="due"
                    name="due"
                    label="Due"
                    value={
                      selectedTask?.dueDate
                        ? moment.utc(selectedTask?.dueDate).local().format('MM/DD/YYYY hh:mm a')
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box ml={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="taskType"
                    name="taskType"
                    label="Task Type"
                    value={selectedTask?.taskType}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="dealerId"
                    name="dealerId"
                    label="Location"
                    value={selectedTask?.dealerLocationName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={fontColor}
                    disabled={true}
                  />
                </Box>
              </Grid>
              {selectedTask?.taskStatusId === UserTaskStatus.Completed && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        multiline
                        id="taskOutcome"
                        name="taskOutcome"
                        label="Task Outcome"
                        value={selectedTask?.taskOutcome}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={fontColor}
                        disabled={true}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      multiline
                      rows={4}
                      id="notes"
                      name="notes"
                      label="Notes"
                      value={selectedTask?.notes}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={fontColor}
                      disabled={true}
                    />
                  </Box>
                </Grid>
              </Grid>
              {taskIsArchived && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        multiline
                        rows={4}
                        id="taskArchiveReason"
                        name="taskArchiveReason"
                        label="Task Archive Reason"
                        value={selectedTask?.taskArchiveReason}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={fontColor}
                        disabled={true}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonGroup aria-label="small button group">
                  {selectedTask && selectedTask.taskStatusId === UserTaskStatus.Completed && (
                    <Button
                      className={classes.completedButton}
                      variant="contained"
                      style={{ backgroundColor: '#4caf50', color: 'white' }}
                      size="medium"
                      startIcon={<DoneIcon />}>
                      Completed
                    </Button>
                  )}
                  {!(selectedTask && selectedTask.taskStatusId === UserTaskStatus.Completed) && !taskIsArchived && (
                    <>
                      <SButton
                        ml={3}
                        variant="contained"
                        disabled={selectedTask && selectedTask.taskStatusId === UserTaskStatus.Completed}
                        size="medium"
                        onClick={() => {
                          onReScheduleTaskBtn(selectedTask!);
                        }}
                        color="secondary">
                        Edit
                      </SButton>
                      <div style={{ padding: 2 }}></div>
                      <SButton
                        variant="contained"
                        disabled={selectedTask && selectedTask.taskStatusId === UserTaskStatus.Completed}
                        size="medium"
                        onClick={() => {
                          onDoneTaskBtn(selectedTask!);
                        }}
                        color="primary">
                        Done
                      </SButton>
                    </>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TaskEditDrawer;
