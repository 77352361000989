import { makeStyles } from '@material-ui/core';
import SmileSVG from 'assets/images/smile.svg';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  logo: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

function Smile(): ReactElement {
  const classes: any = useStyles();
  return <img src={SmileSVG} alt="Smile icon" className={classes.logo} />;
}

export default Smile;
