import MailIcon from '@mui/icons-material/Mail';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { store } from 'app';
import Chip from '@mui/material/Chip';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { Drawer, Box, Container, Dialog, Paper, IconButton, Fab, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useRef } from 'react';
import DealerAPI from 'components/authentication/api/dealer-api';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import Menu from '@mui/material/Menu';


// import MenuItem from '@mui/material/MenuItem';
// import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import { Checkbox, Button, ListSubheader, InputAdornment, FormControl, Select, MenuItem, TextField } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from "@mui/icons-material/Search";
//import { Autocomplete } from '@material-ui/lab';
import Autocomplete from '@mui/material/Autocomplete';
// import { createFilterOptions } from "@material-ui/lab/Autocomplete";
// import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from '@mui/material/Autocomplete';

const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Highlighted = ({ text = "", highlight = "" }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return (
        <span>
            {parts.filter(String).map((part, i) => {
                return regex.test(part) ? (
                    <mark key={i}>{part}</mark>
                ) : (
                    <span key={i}>{part}</span>
                );
            })}
        </span>
    );
};

let mySearchTimeOut: any;
function getStyles(name: any, personName: any, theme: any) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}




let accordExpanded = [] as any;
function UserActivity({ open, onClose, isMessenger }: { open: boolean, isMessenger: number , onClose(): void }) {
    // const inpRef = useRef(null) as any;
    const [refreshTag, SetRefreshTag] = React.useState('');
    const [openDrawer, SetOpenDrawer] = React.useState<any>(true);
    const [dataUserActivity, setDataUserActivity] = React.useState([] as any);
    const [expanded, setExpanded] = React.useState(false);
    const [showFilter, setShowFilter] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [showMore, setShowMore] = React.useState(false);
    const openMore = Boolean(anchorEl);
    const idMore = openMore ? 'simple-popover' : undefined;
    const [startDate, setStartDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 1)) as any);
    const [endDate, setEndDate] = React.useState(new Date(new Date()) as any);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([] as any);
    const [allUsers, setAllUsers] = React.useState([] as any);
    const [displayType, setDisplayType] = React.useState('D');
    const [searchUser, setSearchUser] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);

    const useStyles = makeStyles((theme: Theme): any => ({
        SearchBox: {
            minWidth: "250px",
            width: "250px",
            "@media (max-width: 1300px)": {
                minWidth: "150px",
                width: "150px",
            }

        },
        DrawerWidth: {
            width: "85%",
            "@media (max-width: 1300px)": {
                width: "95%",
            }
        },

        taskColor: {
            backgroundColor: "#EAF2F8"
            , padding: "5px 5px"
        },
        textSentColor: {
            backgroundColor: "#E9F7EF"
            , padding: "5px 5px"
        },
        textReceivedColor: {
            backgroundColor: "#FDF2E9"
            , padding: "5px 5px"
        },

        input1: {
            height: 50
        },
        // input2: {
        //     height: 200,
        //     fontSize: "3em"
        // },
        // root: {
        //     "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        //         // Default transform is "translate(14px, 20px) scale(1)""
        //         // This lines up the label with the initial cursor position in the input
        //         // after changing its padding-left.
        //         transform: "translate(34px, 20px) scale(1);"
        //     }
        // },
        // inputRoot: {
        //     color: "purple",
        //     // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        //     '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        //         // Default left padding is 6px
        //         //   paddingLeft: 26
        //         //   padding: 0
        //         height: 10,
        //         maxHeight: 10
        //     },
        //     "& .MuiOutlinedInput-notchedOutline": {
        //         borderColor: "green"
        //     },
        //     "&:hover .MuiOutlinedInput-notchedOutline": {
        //         borderColor: "red"
        //     },
        //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        //         borderColor: "purple"
        //     }
        // }

    }));
    const classes: any = useStyles();

    // const MyChip = (props: any) => {
    //     const classes1 = useStyles();

    //     return (
    //         <Chip
    //             className={classes}
    //             {...props}
    //         />
    //     );
    // };

    function GetBGColor(row: any): any {
        let bgColor = classes.taskColor;
        if (row.Direction != '') bgColor = classes.textSentColor;
        if (row.Direction == 'inbound') {
            //row.Direction = " (Received) ";
            bgColor = classes.textReceivedColor;
        }
        return bgColor;

    }

    function FormatDateForDatePicker(date: Date) {
        // const offset = date.getTimezoneOffset()
        // let newDate = new Date(date.getTime() - (offset * 60 * 1000))
        // return newDate.toISOString().split('T')[0];
        date = new Date(date);
        //alert(date);
        return date.toISOString().split('T')[0];
    }

    function handleDateChange(date: string, dateType: string) {
        let parsedDate = Date.parse(date);
        if (!isNaN(parsedDate)) {
            if (new Date(parsedDate).getFullYear() > 1900 && new Date(parsedDate).getFullYear() < 2100) {
                if (dateType == 'startDate') setStartDate(date);
                if (dateType == 'endDate') setEndDate(date);
                //alert('set start date');
            }
        }

    }

    function handleSearch() {
        GetData();
        // alert(personName);
        // let users=[];
        // for (let i = 0; i <= personName.length - 1; i++) {
        //     users.push(personName[i].UserID )
        // }
        // alert(users);
        // console.log('personName');
        // console.log(personName);
    }
    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        let newValue = value;
        //alert(value);
        // if (value.length > 1 && value.includes(-1)) {
        //     const index = newValue.indexOf(-1);
        //     //alert(index);
        //     if (index == 0) newValue.splice(index, 1);
        //     if (index > 0) newValue = [-1];
        // }
        // if (newValue.length == 0) newValue = [-1]
        // setPersonName(
        //     // On autofill we get a stringified value.
        //     //   typeof value === 'string' ? value.split(',') : value,
        //     typeof newValue === 'string' ? newValue.split(',') : newValue,
        // );
        setPersonName(value);
        console.log("personName");
        console.log(personName);
    };
    function renderUserName(): string {
        //alert(personName.length);
        // alert(personName);
        let response = '';
        for (let i = 0; i <= personName.length - 1; i++) {
            let item: any = dataUserActivity.Users.filter((x: any) => x.UserID === personName[i]);
            if (item.length > 0) {
                if (response != '') response += ', ';
                response = response + item[0].UserName;
            }
        }
        //alert(response);
        return response;

    }

    React.useEffect(() => {
        GetData();
    }, [refreshTag]);
    // }, [refreshTag, personName]);

    async function GetData() {
        try {
            // alert('personName')
            // alert(personName.length);
            // console.log('personName');
            // console.log(personName);
            let users = [];
            for (let i = 0; i <= personName.length - 1; i++) {
                users.push(personName[i].UserID)
            }

            let obj: any = new Object();
            obj.dealerId = store.getState().user.dealerId;
            obj.users = users; // personName;
            obj.startDate = startDate;
            obj.endDate = endDate;
            obj.search = search;
            let jsonString = JSON.stringify(obj);
            //alert(jsonString);
            // const response = await DealerAPI.ExecuteJsonSP("Kartic.spUserActivity", jsonString);
            setIsLoading(true);
            //alert('test');
            const response = await DealerAPI.ExecuteJsonSP("Dealers.spUserActivity", jsonString);
            setIsLoading(false);
            setDataUserActivity(response[0]);
            if (allUsers.length == 0) {
                setAllUsers(response[0].Users)
            }
            //alert(response);
        }
        catch (error) {
            //alert(error);
        }
    }

    function GetHeaderData(): any {
        if (displayType == 'U') {
            let data_filter: any = dataUserActivity.Users.filter((x: any) => x.Count > 0);
            //alert(personName.length);
            for (let i = 0; i < data_filter.length; i++) {
                let data_filterUser: any = GetUserDetails(data_filter[i].UserID, '');
                let data_filterTask: any = data_filterUser.filter((x: any) => x.QID === 'T');
                let data_filterMessage: any = data_filterUser.filter((x: any) => x.QID === 'M');
                data_filter[i].TotalTaks = data_filterTask.length;
                data_filter[i].TotalMessages = data_filterMessage.length;
            }
            return data_filter;
        }
        else {
            return dataUserActivity.Summary;
        }
    }

    // function GetUserHeader(userId: number, name: string): any {
    //     //let data_filter: any = dataUserActivity.Details.filter((x: any) => x.UserID === userId);
    //     let data_filter: any = GetUserDetails(userId, '');
    //     let data_filterTask: any = data_filter.filter((x: any) => x.QID === 'T');
    //     let data_filterMessage: any = data_filter.filter((x: any) => x.QID === 'M');

    //     return name + '   [Activity: ' + data_filter.length.toString() + ' (Task: ' + data_filterTask.length + ', Text: ' + data_filterMessage.length + ') ]';
    // }

    function GetUserDetails(userId: number, QID: string): any {
        let data_filter: any = dataUserActivity.Details.filter((x: any) => x.UserID === userId);
        if (QID != '') {
            data_filter = data_filter.filter((x: any) => x.QID === QID);
        }
        return data_filter;

    }

    const HandleMoreClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        //ShowHideMore(true);
    };

    function ShowHideMore(open: boolean) {
        //SetOpenDrawer(open);
        //setShowMore(open);
        if (!open) setAnchorEl(null);

    }

    function GetAccordExpanded(id: string): boolean {
        let item = accordExpanded.filter((x: any) => x.id == id)
        if (item.length == 0) {
            accordExpanded.push({ id: id, value: true });
            return true;
        }
        return item[0].value;
    }

    function handlerAccordClick(id: string) {

        if (accordExpanded.length == 0) {
            accordExpanded.push({ id: id, value: false });
            setExpanded(!expanded);
            return;
        }
        let item = accordExpanded.filter((x: any) => x.id == id)
        if (item.length == 0) {
            accordExpanded.push({ id: id, value: false });
            setExpanded(!expanded);
            return;
        }
        if (item.length == 1) {
            item[0].value = !item[0].value;
            setExpanded(!expanded);
        } false
    }

    function OpenCloseDrawer(open: boolean) {
        SetOpenDrawer(open);
    }

    function GetDetails(dateUpdated: any, userId: number = 0): any {
        // let data_filter: any = dataUser.Details.filter((x: any) => x.DateUpdated === dateUpdated && x.UserID == userId);
        let data_filter: any = dataUserActivity.Details.filter((x: any) => x.DateUpdated === dateUpdated);
        if (userId > 0) data_filter = data_filter.filter((x: any) => x.UserID == userId);
        return data_filter;
    }

    function GetUserName(userId: number): any {
        let userName: string = userId.toString();
        let data_filter: any = dataUserActivity.Users.filter((x: any) => x.UserID === userId);
        if (data_filter.length > 0) userName = data_filter[0].FullName + ' (' + data_filter[0].UserName + ')';
        return userName;
    }

    function GetUsersByDate(key: any): any {
        let data_filter = GetDetails(key);
        const uniqueuserIds = Array.from(new Set(data_filter.map((item: any) => item.UserID)));
        return uniqueuserIds;

    }

    function GetTime(date: Date): any {
        let dt=date.toString().replace('T',' ') + ' UTC';
        return new Date(dt).toLocaleTimeString([], { timeStyle: 'short' });
    }

    function ExpandCollapse(expand: boolean) {
        for (let i = 0; i < accordExpanded.length; i++) {
            accordExpanded[i].value = expand;
        }
        setExpanded(!expanded);
        ShowHideMore(false);
    }

    function ApplySearchChanges() {
        clearTimeout(mySearchTimeOut);
        mySearchTimeOut = setTimeout(SearchNow, 1000);
    }

    function SearchNow() {
        SetRefreshTag(new Date().toString());
    }

    const filterOptions = createFilterOptions({
        // matchFrom: 'start',
        stringify: (option: any) => `${option.FullName} (${option.UserName}`
    });

      
    return (
        <Box>
            <Box style={{ backgroundColor: "white" }}>
                    <table>
                        <tr>
                            <td style={{ width: "100%" }}>
                                {showFilter && typeof (dataUserActivity) !== 'undefined' && dataUserActivity.Users != null && (
                                    <Autocomplete
                                        style={{ width: "100%", minWidth: "225px" }}
                                        multiple
                                        id="tags-outlined"
                                        // options={dataUserActivity.Users}
                                        filterOptions={filterOptions}
                                        options={allUsers}
                                        onChange={(event: any, values: any): void => setPersonName(values)}
                                        getOptionLabel={(option: any) => option.UserName}
                                        // getOptionLabel={(option: any) => `${option.FullName} (${option.UserName})`}
                                        // filterSelectedOptions
                                        //defaultValue={[{UserID: -1, FullName: 'All Users', UserName: 'All', Count: 0}]}
                                        disableCloseOnSelect
                                        renderOption={(props: any, option: any, { selected }) => (
                                            <>
                                                {/* <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} /> */}
                                                {/* <Checkbox checked={selected} style={{ padding: "2px", margin: "0px" }} /> */}
                                                {/* <Box>
                                                    {option.FullName} ({option.UserName})
                                                </Box> */}
                                                <li {...props} style={{ whiteSpace: "nowrap" }}>
                                                    <Checkbox checked={selected} style={{ padding: "2px", margin: "0px" }} />
                                                    {option.FullName} ({option.UserName})
                                                </li>

                                            </>
                                        )}
                                        // renderOption={(option: any, { selected }) => (
                                        //     <>
                                        //         {/* <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} /> */}
                                        //         {/* <Checkbox checked={selected} style={{ padding: "2px", margin: "0px" }} /> */}
                                        //         {option.FullName} ({option.UserName})
                                        //     </>
                                        // )}
                                        // renderTags={(value, getTagProps) =>
                                        //     value.map((option, index) => (
                                        //         <>
                                        //             <Checkbox style={{ padding: "2px", margin: "0px" }} />
                                        //             <Chip variant="outlined" label={option.FullName(option.UserName)} {...getTagProps({ index })} />
                                        //         </>
                                        //     ))
                                        // }

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select User"
                                                placeholder="User"
                                            />
                                        )}
                                    />
                                )}
                            </td>
                            <td>
                                <TextField className={classes.SearchBox} id="outlined-basic"
                                    label="Search keyword" variant="outlined" value={search} onChange={(event: any) => { setSearch(event.target.value); ApplySearchChanges() }} />
                            </td>

                            {/* <td valign='top'>
                                {showFilter && typeof (dataUserActivity) !== 'undefined' && dataUserActivity.Users != null && (
                                    <Autocomplete

                                        style={{ width: "200px", border: "0px solid red", margin: "0px", padding: "0px" }}
                                        multiple
                                        id="tags-standard"
                                        value={personName}
                                        options={dataUserActivity.Users}
                                        onChange={(event: any, values: any): void => setPersonName(values)}
                                        getOptionLabel={(option: any) => option.UserName}
                                        disableCloseOnSelect
                                        renderOption={(option: any, { selected }) => (
                                            <>
                                                <Checkbox checked={selected} style={{ padding: "2px", margin: "0px" }} />
                                                {option.FullName} ({option.UserName})
                                            </>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="users"
                                                id="users"
                                                variant="outlined"
                                                label="Users"
                                                placeholder="Select Users"
                                                InputProps={{ classes: { input: classes.input1 } }}
                                            />
                                        )}
                                    />
                                )}
                            </td> */}
                            <td>
                                {showFilter && (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Display Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={displayType}
                                            label="Display Type"
                                            // onChange={handleChange}
                                            onChange={(event: any) => { setDisplayType(event.target.value) }}
                                        >
                                            <MenuItem value={'D'}>By Date</MenuItem>
                                            <MenuItem value={'U'}>By User</MenuItem>
                                            {/* <MenuItem value={30}>Thirty</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                )}

                            </td>

                            <td>
                                {showFilter && (
                                    <TextField

                                        id="date"
                                        label="Start Date"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        defaultValue={FormatDateForDatePicker(startDate)}
                                        onChange={(event) => { handleDateChange(event.target.value, 'startDate') }}
                                        sx={{ width: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            </td>
                            <td>
                                {showFilter && (
                                    <TextField
                                        style={{ marginLeft: "10px" }}
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        //defaultValue="2017-05-24"
                                        defaultValue={FormatDateForDatePicker(endDate)}
                                        onChange={(event) => { handleDateChange(event.target.value, 'endDate') }}
                                        sx={{ width: 140 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            </td>
                            <td>
                                {showFilter && (
                                    <Button variant="contained" onClick={handleSearch} >Search</Button>
                                )}
                            </td>
                            {/* <td>
                                {!showFilter && (
                                    <Fab color="primary" onClick={() => setShowFilter(true)} style={{ marginLeft: "10px" }} >
                                        {<FilterAltIcon></FilterAltIcon>}
                                    </Fab>
                                )}
                            </td> */}
                            <td>

                                <IconButton size="medium" onClick={HandleMoreClick}>
                                    <MoreVertIcon fontSize="medium" />
                                </IconButton>
                            </td>

                            <td>
                                <Menu
                                    id={idMore}
                                    anchorEl={anchorEl}
                                    open={openMore}
                                    onClose={() => ShowHideMore(false)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => ExpandCollapse(false)}>Collapse All</MenuItem>
                                    <MenuItem onClick={() => ExpandCollapse(true)}>Expand All</MenuItem>

                                </Menu>
                                {/* </Box> */}

                            </td>

                            {/* <td>
                                <IconButton size="medium" onClick={onClose}>
                                    <CloseIcon fontSize="medium" />
                                </IconButton>

                            </td> */}
                        </tr>
                    </table>
                </Box>
                <Box>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            minDate={new Date('1900-01-01')}
                            value={startDate}
                            onError={() => {
                                console.log('error');
                            }}
                            onChange={(newValue: any) => { setStartDate(newValue); }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider> */}
                    {isLoading && (
                        <div style={{ margin: "75px" }}><h4>Loading...</h4></div>
                    )}
                </Box>

                <Box style={{ margin: "75px 30px 5px 20px", zIndex: 10 }}>
                    {typeof (dataUserActivity) !== 'undefined' && dataUserActivity.Summary != null && (
                        <Box>

                            {/* {dataUserActivity.Summary.map((row: any) => { */}
                            {GetHeaderData().map((row: any) => {
                                let rowKey: string = row.DateUpdated;
                                //rowKey = rowKey.replace('', '');
                                if (displayType == 'U') rowKey = row.UserID;
                                if (displayType == 'D') rowKey = rowKey.replace('', '');
                                //let rowKey: string = row.UserID;


                                return (
                                    <div style={{ marginBottom: "2px" }}>
                                        <Accordion className={classes.root} expanded={GetAccordExpanded(rowKey)} onChange={(event) => { handlerAccordClick(rowKey) }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <div style={{ display: "table-row" }}>
                                                    {displayType == 'D' && (
                                                        <div>
                                                            <div style={{ display: "table-cell" }} >{rowKey}</div>
                                                            <div style={{ paddingLeft: "10px", display: isMessenger == 1 ? "table-cell" : "none" }}
                                                            > Messages: {row.Messages}</div>
                                                            <div style={{ display: "table-cell", paddingLeft: "10px" }}> Tasks: {row.Tasks}</div>
                                                            <div style={{ display: "table-cell", paddingLeft: "10px" }}> Users: {row.Users}</div>
                                                        </div>
                                                    )}
                                                    {displayType == 'U' && (
                                                        <div>
                                                            <div style={{ display: "table-cell", paddingLeft: "10px" }}> {row.FullName} [Tasks: {row.TotalTaks} Messages: {row.TotalMessages}]</div>
                                                        </div>
                                                    )}
                                                </div>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ marginLeft: "20px" }}>
                                                    {displayType == 'D' && (
                                                        <div>
                                                            {GetUsersByDate(row.DateUpdated).map((userId: any) => {
                                                                return (
                                                                    <div style={{ display: isMessenger == 1 ? "block" : "none", border: "1px solid silver", marginTop: "3px" }}>
                                                                        <div style={{ fontWeight: "bold", color: "gray", backgroundColor: "#F2F3F4", padding: "2px 5px", textTransform: "uppercase" }} >
                                                                            {GetUserName(userId)}
                                                                        </div>

                                                                        <div style={{ margin: "5px 10px" }}>
                                                                            {GetDetails(row.DateUpdated, userId).map((row: any) => {
                                                                                let direction: string = "";
                                                                                // let bgColor=classes.taskColor;
                                                                                // if (row.Direction != '') bgColor=classes.textSentColor;
                                                                                if (row.Direction == 'inbound') {
                                                                                    direction = " (Received) ";
                                                                                    // bgColor=classes.textReceivedColor;
                                                                                }


                                                                                return (
                                                                                    <Box className={GetBGColor(row)} sx={{ display: 'flex' }}>
                                                                                        {/* <div className={GetBGColor(row)} style={{border:"1px solid red"}}> */}
                                                                                        <Box sx={{ display: 'inline-flex' }}>
                                                                                        {row.QID == 'M' && (<MailIcon color="action" sx={{ fontSize: 20 }} style={{ marginRight: "7px" }}></MailIcon>)}
                                                                                        {row.QID == 'T' && (<AssignmentIcon color="action" sx={{ fontSize: 20 }} style={{ marginRight: "7px" }}></AssignmentIcon>)}
                                                                                            
                                                                                            {/* AssignmentIcon */}
                                                                                        </Box>
                                                                                        {/* <div style={{display:"table", border:"1px solid blue"}}>
                                                                                        </div> */}
                                                                                        <Box sx={{ display: 'inline-flex', whiteSpace:"nowrap",marginRight: "7px"  }}>
                                                                                            {GetTime(row.DateUpdatedFull)} {row.QID == 'M' ? '' : 'Task'} {direction}
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'inline-flex' }}>
                                                                                           <Highlighted text={row.Body} highlight={search}  ></Highlighted>
                                                                                        </Box>


                                                                                        {/* {row.Body} */}

                                                                                        {/* </div> */}

                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                    {displayType == 'U' && (
                                                        <div>
                                                            {row.TotalTaks > 0 && (

                                                                <Paper>
                                                                    <div style={{ backgroundColor: "#F0F3F4", padding: "3px 0px" }}>Tasks</div>
                                                                    {GetUserDetails(row.UserID, 'T').map((row: any) => {
                                                                        // let direction: string = "";
                                                                        // if (row.Direction == 'inbound') direction = " (Received) ";

                                                                        return (
                                                                            <div className={GetBGColor(row)}>
                                                                                {/* {row.DateUpdated} {GetTime(row.DateUpdatedFull)}{direction}  <Highlighted text={row.Body} highlight={search}   ></Highlighted> */}
                                                                                {row.DateUpdated} {GetTime(row.DateUpdatedFull)} <Highlighted text={row.Body} highlight={search}   ></Highlighted>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Paper>
                                                            )}

                                                            {row.TotalMessages > 0 && (
                                                                <Paper style={{ marginTop: row.TotalTaks > 0 ? "10px" : "" }}>
                                                                    <div style={{ backgroundColor: "#F0F3F4", padding: "3px 0px" }}>Text</div>
                                                                    {GetUserDetails(row.UserID, 'M').map((row: any) => {
                                                                        let direction: string = "";
                                                                        if (row.Direction == 'inbound') direction = " (Received) ";

                                                                        return (
                                                                            // <div className={GetBGColor(row)} style={{ marginBottom: "3px", margin: "0px 5px" }}></div>
                                                                            <div className={GetBGColor(row)}>
                                                                                {row.DateUpdated} {GetTime(row.DateUpdatedFull)} {row.QID == 'M' ? '' : 'Task'} {direction}<Highlighted text={row.Body} highlight={search}   ></Highlighted>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Paper>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </AccordionDetails>

                                        </Accordion>
                                    </div>
                                )
                            })
                            }
                        </Box>
                    )}
                </Box>
        </Box>
    )
}
export default UserActivity;
