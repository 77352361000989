import Customer from 'common/models/customer';
import React, { forwardRef, ReactElement, Ref, useImperativeHandle, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import CustomerForm from '../CustomerForm/CustomerForm';
import { CustomerFormRef } from "../CustomerForm/CustomerFormRef";
import { saveCustomer, UpdateSelectedCustomer } from '../Customers/customersSlice';
import { CustomerAddDrawerProps } from './CustomerAddDrawer';

const CustomerEditDrawer = forwardRef(({}, ref: Ref<CustomerAddDrawerProps>): ReactElement => {

  const dispatch = useAppDispatch();
  const childRef = useRef<CustomerFormRef>(null);

  const btnDisabled = useAppSelector((state) => state.customers.addCustomerLoading);
  const customer = useAppSelector((state) => state.customers.selectedCustomer);

  console.log("state Customer : ", customer);

  useImperativeHandle(ref, () => ({
    CompareChanges: function (callback: any) {
      console.log("compareChanges called");
      if (childRef && childRef.current) {
        childRef.current.CompareChanges((r: boolean) => {
          callback(r);
        });
      } else {
        callback(false);
      }
    },
    SubmitForm : function(){
      console.log("submitForm called");
      if (childRef && childRef.current) {
        childRef.current.SubmitForm();
      }
    }
  }));
  const handleCustomerFormSubmit = (customerFormValues: Customer): void => {
    dispatch(saveCustomer(customerFormValues));
    dispatch(UpdateSelectedCustomer(undefined));
  };

  const handleOnClose = () => {
    dispatch(UpdateSelectedCustomer(undefined));
  };

  return (
    <CustomerForm
      ref={childRef}
      btnDisabled={btnDisabled}
      customer={customer}
      disabled={false}
      onFormSubmit={handleCustomerFormSubmit}
      onClose={handleOnClose}
      validate
    />
  );
});

export default CustomerEditDrawer;
