import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { SmallCircularProgress } from 'common/components/loaders';
import CreateIcon from 'common/components/icons/create';
import { debounce, indexOf } from 'lodash';
import moment from 'moment';
import React, { MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { Conversation } from '../models/conversation';
import { ConversationLabel } from './ConversationLabel';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { SelectChangeEvent } from '@mui/material';
import { ConversationType } from 'common/utils/shared/shared-constants';

const useConversationListPanelStyles = makeStyles((theme: Theme): any => ({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    height: '-webkit-fill-available',
  },
  loader: {
    position: 'absolute',
    top: '14%',
    left: '43%',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    zIndex: 1,
    borderRadius: '50%',
    padding: '2px 4px',
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarSearch: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  startConversationButton: {},
  conversationSearchField: {},
  toolbarSort: {
    flexBasis: '100%',
  },
  content: {
    overflowY: 'auto',
  },

  root: {
    height: 180,
  },
  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function ConversationListPanel({
  loading,
  conversations,
  messageSid,
  selectedConversation,
  onSearch,
  isSearchChecked,
  onStartConversation,
  onConversationSelect,
  fetchNextPage,
  fetchSortedConversation,
  handleConversationCheckbox,
  handleConvTypeChange,
  handleConvFiltersChange,
  selectedConversationIds,
  defaultSearch = '',
  IsMergeList = false,
}: {
  loading: boolean;
  selectedConversation?: Conversation;
  messageSid: string;
  conversations: Conversation[];
  onConversationSelect(conversation: Conversation): void;
  onSearch(value: string): void;
  isSearchChecked(value: boolean): void;
  onStartConversation(): void;
  fetchNextPage(): void;
  fetchSortedConversation(sortOrder: string): void;
  handleConversationCheckbox(conversationId: number, status: boolean): void;
  handleConvTypeChange(type: string): void;
  handleConvFiltersChange(filters: string[]): void;
  selectedConversationIds: number[];
  defaultSearch?: string;
  IsMergeList?:boolean;
}): ReactElement {
  const classes: any = useConversationListPanelStyles();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const searchRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleStartConversation = (): void => {
    onStartConversation();
  };

  function toggleSort(): void {
    if (sortOrder === 'asc') setSortOrder('desc');
    else if (sortOrder === 'desc') setSortOrder('asc');
    fetchSortedConversation(sortOrder);
  }

  const [searchText, setSearchText] = React.useState('');

  const debouncedSearch = useRef(debounce((value: string) => onSearch(value), 1000)).current;

  const addSearchText = (value: any): void => {
    setSearchText(value);
    debouncedSearch(value);
    // onSearch(value);
  };

  function showSearchBar(value: any): void {
    throw new Error('Function not implemented.');
  }
  const noConversationMessage = (
    <Box my={2} textAlign="center">
      <Typography variant="caption">No Conversations</Typography>
    </Box>
  );

  const filteredConversations = useMemo(() => {
    if (conversations.length) {
      const filterConversations = conversations.sort((conversationA, conversationB) => {
        const data1 = new Date(conversationA.latestDateTime!);
        const data2 = new Date(conversationB.latestDateTime!);
        if (conversationA.latestDateTime && conversationB.latestDateTime) {
          const dateA = data1;
          const dateB = data2;
          if (dateA < dateB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          if (dateB < dateA) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (dateA == dateB) {
            return 0;
          }
        }
        if (conversationA.latestDateTime === null && conversationB.latestDateTime === null) {
          const dateA = data1; 
          const dateB = data2; 
          if (dateA < dateB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          if (dateB < dateA) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (dateA == dateB) {
            return 0;
          }
        }
        if (conversationB.latestDateTime === null) {
          return -1;
        }
        if (conversationA.latestDateTime === null) {
          return 1;
        }
        return 0;
      });
      return filterConversations;
    }
    return [];
  }, [conversations,sortOrder]);

  function fetchMoreConversations(): void {
    fetchNextPage();
  }

  const [searchChecked, setSearchChecked] = React.useState(false);

  const handleSearchIconClicked = () => {
    if (searchChecked && searchText != '') {
      addSearchText('');
    }
    setSearchChecked((prev) => !prev);
    isSearchChecked(searchChecked);
    setTimeout(() => {
      searchRef.current.focus();
     }, 0);  
  };

  const changeCheckboxStatus = (conversationId: number, status: boolean) => {
    handleConversationCheckbox(conversationId, status);
  };

  const handleRadioChange = (event: any) => {
    handleConvTypeChange(event.target.value);
  };
  const conversationFilters = ['My Conversations', 'Unassigned'];
  const [personName, setPersonName] = React.useState<string[]>([]);
  useEffect(() => {
    if (window.localStorage.getItem('Messenger_Filter') != null) {
      setPersonName(JSON.parse(window.localStorage.getItem('Messenger_Filter')!));
      handleConvFiltersChange(JSON.parse(window.localStorage.getItem('Messenger_Filter')!));
    }
  }, []);

  const handleCheckboxChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
    window.localStorage.setItem('Messenger_Filter', JSON.stringify(value));
    handleConvFiltersChange(value);
  };
  return (
    <Box id="conversationListPanel" position="relative" className={classes.panel}>
      {defaultSearch != '' && <Box style={{ marginTop: '85px', border: '0px solid red' }} hidden={IsMergeList}>
          <Tooltip title="Start conversation" className={classes.startConversationButton}>
            <IconButton disabled={loading} id="createConversationButton" onClick={handleStartConversation}>
              <CreateIcon /> <Typography variant="body1">&nbsp;&nbsp;Start a new conversation</Typography>
            </IconButton>
          </Tooltip>
        </Box>}
      {defaultSearch == '' && (
        <Box className={classes.toolbar}>
          <Box className={classes.toolbarSearch}>
            <Box hidden={IsMergeList}>
              <Tooltip title="Start conversation" className={classes.startConversationButton} >
                <IconButton disabled={loading} id="createConversationButton" onClick={handleStartConversation}>
                  <CreateIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Tooltip title="Search" className={classes.startConversationButton}>
              <IconButton id="searchConversationButton" onClick={handleSearchIconClicked}>
                {!searchChecked && <SearchIcon />}
                {searchChecked && <ClearIcon />}
              </IconButton>
            </Tooltip>

            <Zoom in={searchChecked}>
              <TextField
                className={classes.conversationSearchField}
                fullWidth
                variant="outlined"
                size="small"
                inputRef={searchRef}
                id="messengerSearchText"
                margin="dense"
                value={searchText}
                placeholder="Search"
                onChange={(e: any): void => addSearchText(e.target.value)}
              />
            </Zoom>
          </Box>

          <Box className={classes.toolbar}>
            <Box hidden={IsMergeList}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="convType"
                  name="row-radio-buttons-group"
                  defaultValue={ConversationType.All.Value}
                  onChange={handleRadioChange}>
                  <FormControlLabel value={ConversationType.All.Value} control={<Radio />} label={ConversationType.All.Display}  />
                  <FormControlLabel value={ConversationType.UnRead.Value} control={<Radio />} label={ConversationType.UnRead.Display} />
                  <FormControlLabel value={ConversationType.Archive.Value}control={<Radio />} label={ConversationType.Archive.Display} />
                </RadioGroup>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Filters</InputLabel>
                <Select disabled={loading}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={(e)=>{
                    if(!loading){
                      handleCheckboxChange(e)
                    }
                  }}
                  input={<OutlinedInput label="Filters" />}
                  renderValue={(selected: any) => selected.join(', ')}>
                  {conversationFilters.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox disabled={loading} checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* <FormControl variant="outlined" >
            <InputLabel id="demo-multiple-checkbox-label">Filter Conversations</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={personName}
              onChange={handleCheckboxChange}
              input={<OutlinedInput label="Filter Conversations" />}
              renderValue={(selected: any) => selected.join(', ')}
            >
              {conversationFilters.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={personName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          </Box>

          <Box className={classes.toolbarSort}>
            <Button
              onClick={toggleSort}
              variant="text"
              size="small"
              endIcon={sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}>
              {sortOrder === 'asc' ? 'Newest to oldest' : 'Oldest to newest'}
            </Button>
          </Box>
        </Box>
      )}
      <Divider />
      {loading && (
        <Box className={classes.loader}>
          <SmallCircularProgress />
        </Box>
      )}
      {filteredConversations.length ? (
        <ConversationLabelList
          onListEnd={fetchMoreConversations}
          onConversationSelect={onConversationSelect}
          selectedConversation={selectedConversation}
          conversations={filteredConversations}
          messageSid={messageSid}
          searchText={searchText}
          handleCheckbox={(conversationId: number, status: boolean) => {
            changeCheckboxStatus(conversationId, status);
          }}
          selectedConversationIds={selectedConversationIds}
          IsMergeList = {IsMergeList}
        />
      ) : (
        !loading && noConversationMessage
      )}
    </Box>
  );
}

function ConversationLabelList({
  conversations,
  searchText,
  onConversationSelect,
  selectedConversation,
  messageSid,
  onListEnd,
  handleCheckbox,
  selectedConversationIds,
  IsMergeList = false
}: {
  conversations: Conversation[];
  searchText: string;
  selectedConversation: Conversation | undefined;
  onConversationSelect(conversation: Conversation): void;
  messageSid: string;
  onListEnd(): void;
  handleCheckbox(conversationId: number, status: boolean): void;
  selectedConversationIds: number[];
  IsMergeList?:boolean;
}): ReactElement {
  const scrollBarRef = useRef<any | null>(null);
  const scrollBarContainerRef = useRef<any | null>(null);

  function handleOnYReachEnd(): void {
    if (scrollBarContainerRef.current && scrollBarRef.current && scrollBarContainerRef.current.scrollTop) {
      onListEnd();
    }
  }
  function onCheckboxChange(conversationId: number, status: boolean): void {
    handleCheckbox(conversationId, status);
  }
  return (
    <ScrollBar
      component="div"
      ref={scrollBarRef}
      containerRef={(ref: HTMLElement): void => {
        scrollBarContainerRef.current = ref;
      }}
      onYReachEnd={debounce(handleOnYReachEnd, 1000)}>
      <Box id="conversationListContent" style={{ overflowY: 'auto' }}>
        <List>
          {conversations.map((conversation: Conversation, index: number) => {
            let selected = false;
            if (searchText && searchText !== '' && messageSid && messageSid != '') {
              selected = selectedConversation?.latestMessageSid == conversation.latestMessageSid;
            } else {
              selected = selectedConversation?.id === conversation.id;
            }
            // (selectedConversation && (selectedConversation.id === conversation.id || selectedConversation.latestMessageSid == messageSid)) ?? false
            return (
              <ConversationLabel
                selected={selected}
                key={index}
                searchText={searchText}
                onClick={(): void => onConversationSelect(conversation)}
                name={conversation.customer.name}
                locationName={conversation.locationName}
                customerCellPhone={conversation.customer.cellPhone}
                departmentName={conversation.departmentName}
                unreadCount={conversation.unreadMessageCount}
                latestDateTime={conversation.latestDateTime}
                latestMessage={conversation.latestMessage || ''}
                checked={selectedConversationIds.indexOf(conversation.id) != -1}
                onChangeCheckBox={(conversationId, status) => {
                  onCheckboxChange(conversationId, status);
                }}
                conversationId={conversation.id}
                assignedDepartmentName={conversation.assignedDepartmentName}
                assignedUsername={conversation.assignedUsername}
                organizationName={conversation.organizationName}
                isOrganization={conversation.isOrganization}
                isAspenPrimaryContact={conversation.isAspenPrimaryContact}
                isArchive={conversation.isArchive}
                IsFacebookConversation={conversation.IsFacebookConversation}
                FacebookCustomerId={conversation.FacebookCustomerId}
                FacebookPageId={conversation.FacebookPageId}                
                archiveTitle={conversation.archiveTitle}
                IsMergeList={IsMergeList}
              />
            );
          })}
        </List>
      </Box>
    </ScrollBar>
  );
}
