import { Box, Container, Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { MessageTemplate } from 'components/broadcast/models/message-template';
import Department from 'components/setting/models/department';
import AppSnackbar from 'components/snackbar/snackbar';

import TaskOutcomeForm from './task-outcome-form';
import { TaskOutcome } from 'components/lead/models/task';

function EditTaskOutcomeDrawer({
  departments,
  open,
  taskOutcome,
  onClose,
  onSave,
}: {
  departments: Department[];
  open: boolean;
  taskOutcome: TaskOutcome | null;
  onClose(): void;
  onSave(value: any): Promise<void>;
}): ReactElement {
  const [showSnackbar] = AppSnackbar.useSnackBar();

  let taskDepartments: Department[] = taskOutcome ? taskOutcome.departments.map(x => {
    let d: Department = {
      departmentId: x.departmentId,
      departmentName: x.departmentName,
      dealerLocationId: 0,
      dealerLocationName: "",
      departmentType: "",
      phoneNumber: "",
      userCount: 0,
      isTollFreeNumber: false,
      users: []
    }
    return d;
  }) : [];

  // const [taskOutcomeState, setTaskOutcomeState] = React.useState<TaskOutcome | null>(taskOutcome);

  // React.useEffect(() => {
  //   async function fetchQuickMessage(): Promise<void> {
  //     try {
  //       const response = await BroadCastAPI.getMessageTemplate(taskOutcomeId);
  //       if (!response) throw new Error('Invalid response');
  //       setQuickMessage(response);
  //     } catch (error) {
  //       showSnackbar({ type: 'error', message: 'Unable to get quick message' });
  //       onClose();
  //       setQuickMessage(undefined);
  //     }
  //   }
  //   if (quickMessageId) {
  //     fetchQuickMessage();
  //   }
  // }, [quickMessageId, onClose, showSnackbar]);

  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Edit Task Outcome" onClose={onClose} />
      <Box mb={1} />
      <Container>
        {open && (
          <TaskOutcomeForm
            departments={departments}
            taskOutcomeDepartments={taskDepartments}
            onCancel={onClose}
            onSubmit={(value, createMore): Promise<void> => onSave(value)}
            taskOutcome={taskOutcome}
          />
        )}
      </Container>
    </Drawer>
  );
}

export default EditTaskOutcomeDrawer;
