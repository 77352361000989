export interface Task {
  id: number;
  dealerId: number;
  isFollowed: boolean;
  followUpTaskId: number;
  createdByUser: string;
  assignedUser: string;
  subject: string;
  taskType: string;
  customer: string;
  customerId: number | null;
  dueDate: Date;
  notes: string;
  taskStatus: string;
  location: string;
}

export interface GetTaskListResponse {
  tasks: TaskListObject[];
  count: number;
  pageSize: number;
  pageNumber: number;
}

export interface TaskListObject {
  taskId: number;
  dealerLocationName: string;
  dealerId: number;
  taskSubject: string;
  assignedUserId: string;
  assignedUsername: string;
  taskTypeId: number | null;
  taskType: string;
  taskStatus: string;
  taskStatusId: number;
  customerName: string;
  customerId: number;
  notes: string;
  dueDate: string;
  dueTime: string;
  isFollowed: boolean;
  followUpTaskId: number;
  createdByUserId: string;
  createdByUserName: string;
  dateCreated: string;
  dateUpdated: string | null;
  taskOutcomeId: number | null;
  taskOutcome: string | null;
  taskArchiveReason?: string;
  isArchived?: boolean;
  dealId: number | null;
  dealTitle: string | null;
}

export interface TaskListRescheduleRequest {
  assignedUserId?: string | null | undefined;
  dueDate?: Date | null;
  taskIds: number[];
  taskArchiveReason?: string;
  isArchived?: boolean;
}

export interface TaskType {
  id: number;
  value: string;
  taskCategoryId: number;
  taskCategory: TaskTypeCategory;
  departments: TaskTypeDepartment[];
}

export interface TaskOutcome {
  id: number;
  value: string;
  departments: TaskTypeDepartment[];
}
export interface TaskTypeDepartment {
  departmentId: number;
  departmentName: string;
}
export interface TaskTypeCategory {
  id: number;
  value: string;
}

export enum UserTaskStatus {
  New = 1,
  Overdue = 2,
  Completed = 3,
}

export interface TaskListDoneRequest {
  taskIds: number[];
  outcomeId: number;
  notes: string;
  statusId: number;
}
