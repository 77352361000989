import { Box, Container, Divider, makeStyles, Paper, Toolbar } from '@material-ui/core';
import React, { ReactElement } from 'react';

import TextLimitField from 'common/components/input-fields/TextLimitField';

const useStyles = makeStyles(() => ({
  paper: {
    maxHeight: '50vh',
    height: '50vh',
  },
}));

export default function SurveyMessage({
  message,
  disabled,
  onChange,
}: {
  message?: string;
  onChange(event: any): void;
  disabled: boolean;
}): ReactElement {
  const classes: any = useStyles();
  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense">Survey Text Message</Toolbar>
      <Divider />
      <Container maxWidth="md">
        <Box mt={2}>
          <TextLimitField
            disabled={disabled}
            fullWidth
            characters={120}
            variant="outlined"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            hiddenLabel
            rows={4}
            autoFocus
            value={message}
            margin="dense"
            name="message"
            id="message"
            label="Survey Text Message"
            onChange={onChange}
          />
        </Box>
      </Container>
    </Paper>
  );
}
