import { Box, Button, Checkbox, Divider, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import Department from 'components/setting/models/department';
import { useAppSelector } from 'app';
import { TaskOutcome } from 'components/lead/models/task';
import { DepartmentDropdown } from 'components/layouts/DashboardLayout/department-filter-drawer';

interface TaskOutcomeForm {
  id?: number;
  departmentId: number;
  body: string;
}
export default function TaskOutcomeForm({
  taskOutcome,
  departments,
  taskOutcomeDepartments,
  onCancel,
  onSubmit,
}: {
  taskOutcomeDepartments: Department[];
  departments: Department[];
  taskOutcome: TaskOutcome | null;
  onCancel(): void;
  onSubmit(values: any, createMore: boolean): void;
}): React.ReactElement {

  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>(taskOutcomeDepartments.map(x => x.departmentId) || []);



  const initialValues = {
    id: taskOutcome?.id || 0,
    value: taskOutcome?.value || '',
    departments: taskOutcome?.departments || [],
  };


  const editMode = taskOutcome && taskOutcome.id != 0;
  const validationSchema = Yup.object<any>().shape<any>({
    value: Yup.string().required('Value is required')
  });

  async function handleSubmit(values: any): Promise<void> {
    if (selectedDepartments.length == 0) {
      formik.setFieldError("departments", 'at least one department is required');
      return;
    }
    let deps = selectedDepartments.map((x: any) => ({ departmentId: x, departmentName: "" }));
    formik.setFieldValue("departments", deps);
    formik.values.departments = deps;
    console.log("Formik : ", values);

    await onSubmit(values, false);
    formik.resetForm();
    onCancel();
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSaveAndCreateNew(): Promise<void> {

    await formik.validateForm();
    if (selectedDepartments.length == 0) {
      formik.setFieldError("departments", 'at least one department is required');
      return;
    }
    let deps = selectedDepartments.map((x: any) => ({ departmentId: x, departmentName: "" }));
    formik.setFieldValue("departments", deps);
    formik.values.departments = deps;
    formik.setSubmitting(true);
    await onSubmit(formik.values, true);
    formik.setSubmitting(false);
    setSelectedDepartments([]);
    formik.resetForm();
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Box mt={2} id="formBtn" display="flex">
        <Box flexGrow={1} />
        {editMode ? (
          <Button
            id="saveQuickMessageBtn"
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            disabled={formik.isSubmitting}>
            Save
          </Button>
        ) : (
          <>
            <Box mx={1}>
              <Button
                id="createQuickMessageBtn"
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                disabled={formik.isSubmitting}>
                Create
              </Button>
            </Box>
            <Box>
              <Button
                id="saveAndCreateNewQuickMessageBtn"
                type="button"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSaveAndCreateNew}
                disabled={formik.isSubmitting}>
                Save & Create New
              </Button>
            </Box>
          </>
        )}
      </Box>
      <TextField
        fullWidth
        rows={3}
        autoFocus
        margin="dense"
        variant="outlined"
        name="value"
        id="value"
        label="Title"
        onChange={formik.handleChange}
        placeholder="Title"
        value={formik.values.value}
        error={!!formik.errors.value}
        helperText={formik.errors.value}
        required
      />

      <DepartmentDropdown
        setSelectedDepartments={(e) => { setSelectedDepartments(e); if(e && e.length > 0) {formik.setFieldValue('departments',e )}}}
        selectedDepartments={selectedDepartments}
        assignedDepartments={departments}
        error={!!formik.errors.departments}
        helperText={formik.errors.departments}
        placeholder="Select Departments"
      />
    </form>
  );
}

