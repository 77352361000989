import {
  Box,
  Button, Grid,
  IconButton, Popover, TextField, Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';
import { useStyles } from './CustomerForm';

export function Notes({ formik }: { formik: any; }): ReactElement {
  // setCompanyNameState(companyName);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes: any = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <NotesIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Notes</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography style={{ marginTop: '0px' }} variant="body1">
          {formik.values.notes}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          <TextField
            fullWidth
            variant="outlined"
            autoFocus
            multiline
            rows={4}
            margin="dense"
            id="notes"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            name="notes"
            label={`Notes`}
            value={formik.values.notes}
            onChange={(e: any) => {
              formik.setFieldValue('notes', e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }} />
          <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerAddressBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
}
