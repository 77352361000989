import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAppSelector } from 'app';
import { SmallCircularProgress } from 'common/components/loaders';
import useLocations from 'common/hooks/useLocations';
import { LocationOption } from 'common/models/location-option';
import { BroadCastType } from 'components/broadcast/models/broadcast';
import { Analytics, BroadCastSpecificAnalyticsParams, SpecificAnalytics } from 'components/broadcast/models/analytics';
import React from 'react';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import AppSnackBar from 'components/snackbar/snackbar';
import { DateRange } from '@mui/lab/DateRangePicker';
import {
  DateRangePickerDay,
  DateRangePickerDayProps,
  LocalizationProvider,
  pickersDayClasses,
  StaticDateRangePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailLink from 'common/components/buttons/email-link';

function AnalyticsHeader({
  type,
  dateRange,
  location,
  loadingLocations,
  typeOptions,
  locationOptions,
  onDateRangeChange,
  onTypeChange,
  onLocationChange,
}: {
  type: string;
  dateRange: DateRange<Date>;
  loadingLocations: boolean;
  location?: number;
  typeOptions: any[];
  locationOptions?: LocationOption[];
  onDateRangeChange(value: DateRange<Date>): void;
  onTypeChange(value: string): void;
  onLocationChange(value: number): void;
}): React.ReactElement {
  const dealerEmail = useAppSelector((state) => state.dealerEmail);

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1}>{/* <PageHeader title="Broadcasts" /> */}</Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          size="small"
          margin="dense"
          variant="outlined"
          name="location"
          label="Location"
          value={location}
          disabled={loadingLocations}
          inputProps={{ id: 'location' }}
          onChange={(event: any): void => onLocationChange(event.target.value)}>
          {locationOptions &&
            locationOptions.map((loc) => (
              <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                {loc.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
      <Box ml={1}>
        <TextField
          fullWidth
          select
          margin="dense"
          variant="outlined"
          name="type"
          label="Type"
          value={type}
          inputProps={{ id: 'type' }}
          onChange={(event: any): void => onTypeChange(event.target.value)}>
          {typeOptions.map((typ) => (
            <MenuItem key={typ.value} value={typ.value} disabled={typ.value === 'Email' && !dealerEmail.replyToAddress}>
              {typ.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box ml={1} mt={1}>
        <DateRangeFilter value={dateRange} setValue={onDateRangeChange} />
      </Box>
    </Box>
  );
}

function DateRangeFilter({
  value,
  setValue,
}: {
  value: DateRange<Date>;
  setValue(value: DateRange<Date>): void;
}): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderWeekPickerDay = (date: Date, dateRangePickerDayProps: DateRangePickerDayProps<Date>) => {
    return (
      <DateRangePickerDay
        sx={{
          [`&&.${pickersDayClasses.today}`]: {
            backgroundColor: '#939afa',
            color: 'white',
            border: '#939afa',
          },
        }}
        {...dateRangePickerDayProps}
      />
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button variant="outlined" color="primary" size="medium" onClick={handleClick}>
        Date Range
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container>
          <Grid item xs={2}>
            <Box mt={1} ml={1}>
              <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const date = new Date();
                    const startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                    const endDate = date;
                    let dateRange: DateRange<Date> = [startDate, endDate];
                    setValue(dateRange);
                    // Call API
                  }}>
                  Past Month
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const date = new Date();
                    let startDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
                    let endDate = date;
                    let dateRange: DateRange<Date> = [startDate, endDate];
                    setValue(dateRange);
                    // Call API
                  }}>
                  Past Quarter
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
                    const startDate = new Date(lastyear.getFullYear(), 0, 1);
                    const endDate = new Date(lastyear.getFullYear(), 11, 31);
                    const dateRange: DateRange<Date> = [startDate, endDate];
                    setValue(dateRange);
                    // Call API
                  }}>
                  Past Year
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const date = new Date();
                    const startDate = new Date(date.getFullYear(), 0, 1);
                    const endDate = date;
                    const dateRange: DateRange<Date> = [startDate, endDate];
                    setValue(dateRange);
                    // Call API
                  }}>
                  YTD
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const dateRange: DateRange<Date> = [null, null];
                    setValue(dateRange);
                    // Call API
                  }}>
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                label="date range"
                value={value}
                onChange={(newValue) => {
                  newValue[1]?.setHours(23, 59, 59);
                  setValue(newValue);
                  // Call API { toDate: newValue[1], fromDate: newValue[0] }));
                }}
                renderDay={renderWeekPickerDay}
                renderInput={(startProps: any, endProps: any) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
}

const typeOptions = [
  { value: 'Text', label: 'Text' },
  { value: 'Email', label: 'Email' },
];

function returnYtdDateRange(): DateRange<Date>{
  const date = new Date();
  const startDate = new Date(date.getFullYear(), 0, 1);
  const endDate = date;
  const dateRange: DateRange<Date> = [startDate, endDate];
  return dateRange;
}

function AnalyticsTab(): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [showSnackBar] = AppSnackBar.useSnackBar();

  const [analytics, setAnaltytics] = React.useState<Analytics[]>([]);
  const [specificAnalyticsParams, setSpecificAnalyticsParams] = React.useState<BroadCastSpecificAnalyticsParams>();
  const [specificAnalytics, setSpecificAnalytics] = React.useState<SpecificAnalytics[]>([]);
  const [typeFilter, setTypeFilter] = React.useState<string>('Text');
  const [dateRangeFilter, setDateRangeFilter] = React.useState<DateRange<Date>>(returnYtdDateRange());

  const [loading, setLoading] = React.useState<boolean>(false);
  let defaultLocation = useAppSelector((state) => state.user.dealerId);
  const {
    loading: loadingLocations,
    locationOptions,
    defaultLocationOption,
    getLocationName,
  } = useLocations(dmsDealerIdCrm);
  
  const [locationFilter, setLocationFilter] = React.useState<number>(defaultLocation);
  React.useEffect(() => {
    setLocationFilter(defaultLocation);
  }, [defaultLocationOption]);

  

  const fetchBroadCastsAnalytics = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await BroadCastAPI.getBroadCastsAnalytics(
        dmsDealerIdCrm,
        typeFilter,
        dateRangeFilter,
        locationFilter,
      );
      
      response.sort((a:Analytics, b:Analytics) => {
        // Initalize and use first, second dates in case of null for dateSent or dateSent isn't in the object.
        let first:Date = new Date();
        let second:Date = new Date();
        //Set to a future date. So the item will be at the last.
        first.setFullYear(first.getFullYear() + 1);
        second.setFullYear(second.getFullYear() + 1)

        if(a.dateSent  !== undefined)
        {
          first = new Date(a.dateSent)
        }
        if(b.dateSent  !== undefined)
        {
          second = new Date(b.dateSent)
        }
      return  second.getTime() - first.getTime();
      })

      setAnaltytics(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Unable to fetch broadcasts analytics' });
    }
  };

  React.useEffect(() => {
    fetchBroadCastsAnalytics();
  }, [dmsDealerIdCrm, locationFilter, showSnackBar, dateRangeFilter, typeFilter]);

  const [snippetAnchorEl, setSnippetAnchorEl] = React.useState(null);
  const [infoLoading, setInfoLoading] = React.useState<Boolean>(false);
  const openSnippet = Boolean(snippetAnchorEl);
  const idSnippet = openSnippet ? 'message-template-widget' : undefined;
  function handleSnippetClose(): void {
    setSnippetAnchorEl(null);
  }

  async function handleSnippetClick(event: any, messageId: number, type: string, status: string,usageHistoryId:number, title:string, dateSent:Date, customerGroup:string): Promise<void> {
    console.log('clicked');
    setSpecificAnalyticsParams({title,dateSent,customerGroup})
    setSnippetAnchorEl(event.currentTarget);
    setInfoLoading(true);
    const specificAnalytics = await BroadCastAPI.getBroadcastSpecificAnalytics(messageId, type, status, usageHistoryId);
    setSpecificAnalytics(specificAnalytics);
    setInfoLoading(false);
  }
  const dmsTypeId = useAppSelector((state)=>state.user.dmsTypeId);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData: SpecificAnalytics[], fileName: string) => {
    const exportData = csvData.map((data) => ({
      'Broadcast Title':specificAnalyticsParams && specificAnalyticsParams.title,
      'Date Sent':specificAnalyticsParams && moment.utc(specificAnalyticsParams.dateSent).format('MM/DD/YYYY'),
      'Customer Group':specificAnalyticsParams && specificAnalyticsParams.customerGroup,
      'Organization': data.customerName,
      'Name': dmsTypeId === 1 ? data.firstName : '',
      'First Name': dmsTypeId !== 1 ? data.firstName:'',
      'Last Name':  dmsTypeId !== 1 ? data.lastName:'',
      Email: data.email,
      Cellphone: data.cellphone ?? (/^\d+$/.test(data.to.slice(-10)) ? data.to.slice(-10) :''),
      To: data.to,
      'Billing Address':
      (data.address1 ?? '') +
      ', ' +
      (data.address2 ?? '') +
      ', ' +
      (data.city ?? '') +
      ', ' +
      (data.stateProvince ?? '') +
      ', ' +
      (data.zipCode ?? ''),
      Notes: data.notes,
      Category: data.category,
      Error: data.error,
      Reply: data.reply,
    }));
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  
  return (
    <Paper square style={{ padding: '10px' }}>
      <AnalyticsHeader
        dateRange={dateRangeFilter}
        type={typeFilter}
        loadingLocations={loadingLocations}
        location={locationFilter}
        locationOptions={locationOptions}
        typeOptions={typeOptions}
        onTypeChange={(type: BroadCastType): void => setTypeFilter(type)}
        onDateRangeChange={(dateRange: DateRange<Date>): void => setDateRangeFilter(dateRange)}
        onLocationChange={(location: number): void => setLocationFilter(location)}
      />
      <Box mt={2} component="section">
        {loading ? (
          <Box height="500px" margin="auto" display="flex" justifyContent="center">
            <SmallCircularProgress />
          </Box>
        ) : (
          <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
            {typeFilter === 'Email' ? (
              <Table stickyHeader id="analytics-table" aria-label="Analytics table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Date Sent</TableCell>
                    <TableCell>Customer Groups</TableCell>
                    <TableCell>Bounces</TableCell>
                    <TableCell>Success</TableCell>
                    <TableCell>Opened</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>Unsubscribes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analytics?.map((analytics: Analytics) => (
                    <TableRow key={analytics.messageId}>
                      <TableCell>{analytics.title}</TableCell>
                      <TableCell>{moment.utc(analytics.dateSent).format('MM/DD/YYYY')}</TableCell>
                      <TableCell>{analytics.segmentNames}</TableCell>
                      <TableCell>
                        {analytics.failed > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'email',
                                'failed',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.failed}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.success > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'email',
                                'success',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.success}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.opened > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'email',
                                'opened',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.opened}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.clicks > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'email',
                                'clicks',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.clicks}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.unsubscribes > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'email',
                                'unsubscribes',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.unsubscribes}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table stickyHeader id="analytics-table" aria-label="Analytics table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Date Sent</TableCell>
                    <TableCell>Customer Groups</TableCell>
                    <TableCell>Fails</TableCell>
                    <TableCell>Success</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>Replies</TableCell>
                    <TableCell>Unsubscribes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analytics?.map((analytics: Analytics) => (
                    <TableRow key={analytics.messageId}>
                      <TableCell>{analytics.title}</TableCell>
                      <TableCell>{moment.utc(analytics.dateSent).format('MM/DD/YYYY')}</TableCell>
                      <TableCell>{analytics.segmentNames}</TableCell>
                      <TableCell>
                        {analytics.failed > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'text',
                                'failed',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.failed}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.success > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'text',
                                'success',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.success}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        <p>{analytics.clicks}</p>
                      </TableCell>
                      <TableCell>
                        {analytics.replies > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'text',
                                'replies',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.replies}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {analytics.unsubscribes > 0 ? (
                          <p
                            onClick={(e) =>
                              handleSnippetClick(
                                e,
                                analytics.messageId,
                                'text',
                                'unsubscribes',
                                analytics.usageHistoryId,
                                analytics.title,
                                analytics.dateSent,
                                analytics.segmentNames,
                              )
                            }
                            style={{ color: '#0000EE', cursor: 'pointer', textDecoration: 'underline' }}>
                            {analytics.unsubscribes}
                          </p>
                        ) : (
                          <p>0</p>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <Popover
              id={idSnippet}
              open={openSnippet}
              anchorEl={snippetAnchorEl}
              onClose={handleSnippetClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Box p={1} border={1} borderColor="grey.300">
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} fontWeight="bold">
                  <Typography variant="h5">
                    Customer List
                  </Typography>
                  <Tooltip title="Export Table">
                    <IconButton  onClick={()=>{exportToCSV(specificAnalytics,specificAnalyticsParams ? specificAnalyticsParams.customerGroup:'test')}}>
                      <FileDownloadIcon/>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box textAlign="center" fontWeight="bold">
                  {infoLoading && <CircularProgress size={24} />}
                  {!infoLoading && (
                     <Box sx={{ overflow: 'auto', whiteSpace: 'nowrap', position: 'relative' }}>
                    <Table stickyHeader id="analytics-table" aria-label="Analytics table">
                      <TableHead style={{ whiteSpace: 'nowrap' }}>
                        <TableRow>
                          <TableCell>Organization</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Cell Phone</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Billing Address</TableCell>
                          <TableCell>Notes</TableCell>
                          {/* <TableCell>Category</TableCell>
                          <TableCell>To</TableCell> */}
                          <TableCell>Error</TableCell>
                          <TableCell>Reply</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {specificAnalytics?.map((analytics: SpecificAnalytics) => (
                          <TableRow key={analytics.crmCustomerId}>
                            <TableCell>{analytics.customerName}</TableCell>
                            <TableCell>{analytics.firstName}</TableCell>
                            <TableCell>{dmsTypeId!==1 && analytics.firstName}</TableCell>
                            <TableCell>{dmsTypeId !==1 && analytics.lastName}</TableCell>
                            <TableCell>{analytics.cellphone ?? (/^\d+$/.test(analytics.to.slice(-10)) && analytics.to.slice(-10))}</TableCell>
                            <TableCell>{analytics.email?<EmailLink email={analytics.email}/>:<></>}</TableCell>
                            <TableCell>
                              {(analytics.address1 ?? '') +
                                ', ' +
                                (analytics.address2 ?? '') +
                                ', ' +
                                (analytics.city ?? '') +
                                ', ' +
                                (analytics.stateProvince ?? '') +
                                ', ' +
                                (analytics.zipCode ?? '')}
                            </TableCell>
                            <TableCell>{analytics.notes}</TableCell>
                            {/* <TableCell>{analytics.category}</TableCell>
                            <TableCell>{analytics.to}</TableCell> */}
                            <TableCell>{analytics.error}</TableCell>
                            <TableCell>{analytics.reply}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </Box>
                  )}
                </Box>
              </Box>
            </Popover>
          </TableContainer>
        )}
      </Box>
    </Paper>
  );
}

export default AnalyticsTab;
