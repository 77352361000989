import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import FacebookLogo from 'common/components/icons/facebook';
import GoogleLogo from 'common/components/icons/google';

export const useSurveyFormMessageStyle = makeStyles((theme: Theme): any => ({
  root: {
    height: '90%',
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  socialBtn: {
    width: '100%',
  },
}));
export function SurveyFormMessage({
  type,
  facebookUrl,
  googleUrl,
  pnpMessage,
  detractorMessage,
}: {
  type: 'completed' | 'thankYou' | 'review';
  facebookUrl?: string;
  googleUrl?: string;
  pnpMessage: string;
  detractorMessage: string;
}): ReactElement {
  const classes: any = useSurveyFormMessageStyle();
  let message = <Typography />;
  switch (type) {
    case 'completed':
      message = <Typography id="completedSurveyMessage">You have already completed the survey</Typography>;
      break;
    case 'thankYou':
      message = <Typography id="detractorMessage" style={{whiteSpace: 'pre-line'}}>{detractorMessage}</Typography>;
      break;
    case 'review':
      return (
        <Box className={classes.root}>
          <Typography id="pnpMessage" style={{whiteSpace: 'pre-line'}}>{pnpMessage}</Typography>
          <Box mt={2} textAlign="center" width="100%">
            <Grid container justifyContent="space-evenly">
              {googleUrl && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Button
                    id="googleReviewBtn"
                    className={classes.socialBtn}
                    onClick={() => window.open(googleUrl, '_blank')}
                    startIcon={<GoogleLogo />}
                    variant="outlined">
                    Google review
                  </Button>
                </Grid>
              )}
              {facebookUrl && (
                <Grid item xs={12}>
                  <Button
                    id="facebookReviewBtn"
                    className={classes.socialBtn}
                    onClick={() => window.open(facebookUrl, '_blank')}
                    startIcon={<FacebookLogo />}
                    variant="outlined">
                    Facebook review
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      );
    default:
      message = <Typography />;
  }
  return <Box className={classes.root}>{message}</Box>;
}
