import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Question } from 'components/survey/models';
import { QuestionType, questionTypes } from 'components/survey/components/question-field';
import React, { ReactElement } from 'react';
import { $enum } from 'ts-enum-util';

const useStyles = makeStyles((theme: Theme): any => ({
  block: {
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(2),
  },
  contentBlock: {
    display: 'flex',
  },
  number: {
    marginRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
  },
}));

export default function QuestionBlock({
  index,
  question,
  onDelete,
  disabled,
  onChange,
}: TextFieldProps & {
  index: number;
  question: Question;
  onDelete(question: Question): void;
}): ReactElement {
  const classes: any = useStyles();
  const questionType = $enum(QuestionType).getKeyOrThrow(question.type);
  const QuestionTypeIcon = questionTypes[questionType].icon;
  return (
    <Paper elevation={3} className={classes.block}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Button id="questionTypeBtn" size="small" variant="text" color="primary" startIcon={<QuestionTypeIcon />}>
            {index + 1}
            {question.isMandatory && <Typography color="secondary">*</Typography>}
          </Button>
        </Box>
        <Box>
          {!question.isMandatory && (
            <Tooltip title="Remove question">
              <IconButton
                id="removeQuestionBtn"
                disabled={disabled}
                onClick={(): void => onDelete(question)}
                color="secondary"
                size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disabled={disabled}
            id="questionTitleField"
            size="small"
            margin="dense"
            name={question.id.toString()}
            variant="outlined"
            type="text"
            color="primary"
            value={question.title}
            placeholder="Question title"
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
