import { useAppSelector } from 'app';
import React from 'react';

import DepartmentAPI from 'components/setting/api/department-api';
import { UserDepartment } from 'components/setting/models/user-department-settings';
import AppSnackbar from 'components/snackbar/snackbar';

export function useUserDepartmentSettings(crmUserId: string): {
  loading: boolean;
  selectedDepartmentIds: number[];
  assignedDepartments: UserDepartment[];
  onUserDepartmentSettingUpdate(selectedIds: number[]): Promise<void>;
  fetchUserDepartmentSettings(): void;
} {
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = React.useState<number[]>([]);
  const [assignedDepartments, setAssignedDepartments] = React.useState<UserDepartment[]>([]);
  const fetchUserDepartmentSettings = React.useCallback(
    async function fetchUserDepartmentSettings(): Promise<void> {
      try {
        setLoading(true);
        const response = await DepartmentAPI.getUserDepartmentSetting(dmsDealerIdCrm, crmUserId);
        setSelectedDepartmentIds(response.userSelectedDepartments);
        setAssignedDepartments(response.userAssignedDepartments);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [crmUserId, dmsDealerIdCrm],
  );
  React.useEffect(() => {
    fetchUserDepartmentSettings();
  }, [crmUserId, fetchUserDepartmentSettings]);

  async function onUserDepartmentSettingUpdate(selectedIds: number[]): Promise<void> {
    try {
      setLoading(true);
      const response = await DepartmentAPI.updateUserDepartmentSettings(dmsDealerIdCrm, crmUserId, selectedIds);
      setSelectedDepartmentIds(response.userSelectedDepartments);
      showSnackbar({ type: 'success', message: 'Department filter updated' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar({ type: 'error', message: 'Unable to update department filter' });
    }
  }
  return {
    loading,
    selectedDepartmentIds,
    assignedDepartments,
    onUserDepartmentSettingUpdate,
    fetchUserDepartmentSettings,
  };
}

export default useUserDepartmentSettings;
