import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import React, { useContext, useState } from 'react';
import { Box, Button, Container, Drawer, Grid, MenuItem, TextField, Paper, Typography, makeStyles, Theme } from '@material-ui/core';
import ConversationListPanel from "./conversation-list-panel";
import { Conversation } from "../models/conversation";
import { useLocation } from "react-router-dom";
import { uniqBy } from "lodash";
import messagingApi from "../api/messaging-api";
import { ConversationType } from "common/utils/shared/shared-constants";
import DepartmentFilterContext from "components/layouts/DashboardLayout/department-filter-context";
import Swal from "sweetalert2";
import { showSnackbar } from "components/root-snackbar";
import { useAppDispatch } from "app";


const useMergeMessengerStyles = makeStyles((theme: Theme): any => ({
    
    root: {
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      height: '85vh',
      minHeight: '85vh',
    }
  }));

  
export default function MergeMessengerDrawer({ defaultSearch="",  conversation, open, onClose,refreshMessages }: 
{ 
    defaultSearch?: string,
    conversation: Conversation,
    open: boolean,
    onClose(): void,
    refreshMessages():void 

}): React.ReactElement 
{
    const [loading, setLoading] = useState(false);
    const [selectedMessageSid, setSelectedMessageSid] = useState("");
    const [selectedConversation, setSelectedConversation] = useState<Conversation | undefined>();
	const [pageNumber, setPageNumber] = useState(-1);
	const [isConversationRemaining, setConversationRemaining] = useState(true);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [sortOrder, setSortOrder] = useState('desc')
    const [searchText, setSearchText] = useState(defaultSearch);
    const [selectedConversationIds, setConversationList] = useState<number[]>([]);
    const pageSize = 30;
    const { selectedDepartmentIds } = useContext(DepartmentFilterContext);
    const classes: any = useMergeMessengerStyles();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const handleSortedConversations = async (sortOrder: string) => {
        setSortOrder(sortOrder);
      }
    const dispatch = useAppDispatch();
    let isLoading = 0;
    
    const handleSearchChecked = (value: boolean) => {
        setPageNumber(0);
      }

    function handleSearchConversation(value: string): void {
        if (loading === false) {
          setSearchText(value);
        }
      }
function handleFetchConversationNextPage(): void {

    if (isLoading == 0 && loading === false && isConversationRemaining) {
      isLoading = 1;
      setPageNumber(pageNumber + 1);
    }
  }

  async function handleOnConversationSelected(conversation: Conversation): Promise<void> {
    
  }
  async function handleStartConversation(): Promise<void> {
    
  }
  const handleConversationCheckbox = async (conversationId: number, status: boolean) => {
    if (status === true) {
      setConversationList([...selectedConversationIds, conversationId]);
    }
    else {
      let tempConvo = [...selectedConversationIds];
      tempConvo = tempConvo.filter(x => x !== conversationId);
      setConversationList(tempConvo);
    }
  }

  const handleConvTypeChange = async (type: string) => {
   
  }
  const handleConvFiltersChange = async (filters: string[]) => {
   
  }
  function setUniqConversations(conversList: Conversation[]): void {
    if (!conversList || conversList.length == 0) {
      const conversationId = query.get("conversationId");
      if (conversationId) {
        return;
      }
    }
    const uniqConversations = uniqBy(conversList, (conversation) => conversation.id);
    uniqConversations.sort((a: Conversation, b: Conversation) => {
      if (a.latestDateTime != null && b.latestDateTime != null) {
        return new Date(a.latestDateTime).getTime() - new Date(b.latestDateTime).getTime();
      }
      return 0;
    });
    setConversations(uniqConversations);
  }
  const fetchConversationsApi = async (conversationType: string,conversationFilters:string[] , load: boolean = false, loader: boolean = true, refreshCurrentConv: boolean = false) => {
    try {
      if (pageNumber < 0) return;
      loader && setLoading(true);
      const getConversationsResponse = await messagingApi.getConversationsForMerge(searchText, pageNumber,
            pageSize, conversationType, sortOrder,conversation.phoneNumberId??0,conversation.departmentId.toString());
      let newConversations = []
      if (pageNumber === 0) {
        newConversations = [...getConversationsResponse.conversations];
      } else {
        newConversations = [...conversations, ...getConversationsResponse.conversations];
      }


      if (searchText || load) {
        setConversations(newConversations);
      }
      else {
        setUniqConversations(newConversations);
      }

      setConversationRemaining(getConversationsResponse.isRemaining);
      loader && setLoading(false);
      isLoading = 0;
    } catch (error) {
      loader && setLoading(false);
      isLoading = 0;
      console.error(error);
    }
  }
 
  const fetchConversations = React.useCallback(async (pageNo?: number): Promise<void> => {
    await fetchConversationsApi(ConversationType.Archive.Value, []);
  },
    [searchText, pageNumber, sortOrder],
  );
  React.useEffect(() => {
    fetchConversations();
  }, [fetchConversations, pageNumber, searchText, sortOrder]);
  
  React.useEffect(() => {
    setConversations([]);
    setPageNumber(0);
    setSelectedConversation(undefined);
    fetchConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentIds]);


  const mergeConversationClick = ():void =>{
    if(selectedConversationIds.length == 0)
    {
        dispatch(
            showSnackbar({
              type: 'info',
              message: "Please select a conversation first",
            }),
          );
        return;
    }
   
    mergeConversationDialog(
      conversation.id,
      conversation.departmentPhoneNumber,
      conversation.customer.cellPhone,
      selectedConversationIds);
  }
  const mergeConversationDialog =  (
    newConversationId:number,
    fromNumber:string,
    toNumber:string,
    conversationIds:number[]
  ):void => {
    const mergeConfirmMsg:string = conversationIds.length>1
    ?'Are you sure you want to merge these conversations?':
    'Are you sure you want to merge this conversation?';
    Swal.fire({
        title: mergeConfirmMsg,
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
       
        if(!result.isConfirmed)
            return;

        setLoading(true)
        try {
            const mergeResponse = await messagingApi.mergeConversations(
              newConversationId,
              fromNumber,
              toNumber,
              conversationIds);
              setLoading(false);
            if(mergeResponse.isSuccess){
                
                Swal.fire(
                    'Merged!',
                    'Archived conversation'+(conversationIds.length>1?'s':'')+' successfully merged and reopened in the active thread.',
                    'success'
                    )
        
                    refreshMessages();
                    onCloseWrapper();
                   
                }else{
                   
                    Swal.fire(
                        'Error!',
                        'Error occured while merging. Please see details:\n'+mergeResponse.error,
                        'error'
                        );
                
                }
          } catch (error) {
            setLoading(false);
            Swal.fire(
                'Error!',
                'Error occured while merging.',
                'error');
          }
      })
  }

  const onCloseWrapper = ():void=>{
    //Clear Memory
    setConversations([]);
    setConversationList([])
    setSelectedConversation(undefined);

    onClose()
  }
    return (
        <Paper>
            <Drawer PaperProps={{ style: { width: "auto", minWidth: "500px" } }} variant="temporary" anchor="right" open={open} onClose={onCloseWrapper}>
            <DrawerToolbar title="Merge Conversations" onClose={onCloseWrapper} />
                {open && (
                    <Box>
                    <Paper className={classes.root}>
                    <ConversationListPanel
                            loading={loading}
                            messageSid={selectedMessageSid}
                            selectedConversation={selectedConversation}
                            fetchNextPage={handleFetchConversationNextPage}
                            fetchSortedConversation={handleSortedConversations}
                            conversations={conversations}
                            onSearch={handleSearchConversation}
                            isSearchChecked={handleSearchChecked}
                            onConversationSelect={handleOnConversationSelected}
                            onStartConversation={handleStartConversation}
                            handleConversationCheckbox={handleConversationCheckbox}
                            handleConvTypeChange={handleConvTypeChange}
                            handleConvFiltersChange={handleConvFiltersChange}
                            selectedConversationIds={selectedConversationIds}
                            defaultSearch={defaultSearch}
                            IsMergeList={true}
                        />
                    </Paper>
                    <Box display="flex" justifyContent="space-around" flexDirection="column" style={{marginTop:'1%',marginLeft:'1%',marginRight:'1%'}}>
                    <Button color="primary" variant="contained" size="large" onClick={mergeConversationClick}>
                        Merge Conversation
                    </Button>
                    </Box>
                    </Box>
                )}
            </Drawer>
        </Paper>
    );

}

