import { useEffect, useState } from 'react';

import DealerAPI from 'components/authentication/api/dealer-api';
import AppSnackbar from 'components/snackbar/snackbar';
import { useAppSelector } from 'app';

export const useDealerSettings = (dealerId?: number): [string | undefined, string | undefined, string | undefined] => {
  const [dealerLogo, setDealerLogo] = useState<string | undefined>(undefined);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [dealerGoogleUrl, setDealerGoogleUrl] = useState<string | undefined>(undefined);
  const [dealerFacebookUrl, setDealerFacebookUrl] = useState<string | undefined>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  useEffect(() => {
    async function fetchDealerSettings(): Promise<void> {
      if (dealerId) {
        try {
          const dealerLocationSetting = await DealerAPI.getDealerLocationSettings(dmsDealerIdCrm, dealerId);
          setDealerLogo(dealerLocationSetting.dealerLogo);
          setDealerGoogleUrl(dealerLocationSetting.googleLink);
          setDealerFacebookUrl(dealerLocationSetting.facebookLink);
        } catch (error) {
          showSnackBar({ type: 'error', message: 'Unable to get dealer settings' });
        }
      }
    }
    fetchDealerSettings();
  }, [dealerId, dmsDealerIdCrm, showSnackBar]);
  return [dealerLogo, dealerGoogleUrl, dealerFacebookUrl];
};

export default useDealerSettings;
