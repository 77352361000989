import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
} from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Autocomplete } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useAppDispatch, useAppSelector } from 'app';
import { LoadingButton } from 'common/components/buttons/LoadingButton';
import { CustomerDto } from 'common/models/customer';
import { TaskListObject, UserTaskStatus } from 'components/lead/models/task';
import useUsersList from 'components/setting/components/department/userUsersList';
import User from 'components/setting/models/user';
import { useFormik } from 'formik';
import React, { forwardRef, useCallback, useImperativeHandle, useState, Ref, useEffect } from 'react';
import * as Yup from 'yup';
import CustomerAPI from '../../../../customer/api/CustomerAPI';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import TaskboardAPI from '../taskboard-api';
import moment from 'moment';
import { ITaskTimes, taskHours } from '../taskhours-step';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { formatDatePicker } from 'common/utils';
import useLocations from 'common/hooks/useLocations';
import { setDealerLocationId } from '../taskboardSlice';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const schema = Yup.object<any>().shape<any>({
  taskSubject: Yup.string().required('Required').max(50, 'Max length 50'),
  taskTypeId: Yup.number().nullable().required('Required').min(1, 'this field is required'),
  dealerId: Yup.number().nullable().required('Required').min(1, 'this field is required'),
  dueDate: Yup.string(),
  dueTime: Yup.string().when('dueDate', {
    is: (dueDate: any | undefined) => !!dueDate,
    then: Yup.string().required('Required') as any,
    otherwise: Yup.string() as any, // Make it optional when dueDate is not selected
  })
});

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      marginTop: 10,
      width: '80%',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      backgroundColor: '#3f51b5',
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 12,
      paddingRight: 5,
      position: 'absolute',
      marginTop: 10,
      marginLeft: -15,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingRight: 5,
      position: 'absolute',
      marginTop: 10,
      marginLeft: -15,
    },
    removeEmailButton: {
      marginTop: '10px',
      marginLeft: '10px',
    },
  }),
);

export interface TaskFormRef {
  getValues: () => null | TaskListObject;
}

const TaskForm = forwardRef(
  (
    {
      task,
      onFormSubmit,
      onClose,
      onMarkAlreadyCompleted,
      disabled,
      btnDisabled,
      validate,
      reschedule,
      done,
      edit,
      hideButtons = true,
      previousCustomer,
      disableCustomerSelect
    }: {
      task?: TaskListObject;
      disabled: boolean;
      btnDisabled: boolean;
      validate: boolean;
      onFormSubmit(task: TaskListObject): void;
      done: boolean | null;
      onMarkAlreadyCompleted(status: boolean): void;
      onClose: () => void;
      reschedule: boolean;
      edit: boolean;
      hideButtons: boolean;
      previousCustomer: any;
      disableCustomerSelect?: boolean;
    },
    ref: Ref<TaskFormRef>,
  ) => {
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument

    const fontColor: any = {
      style: { color: 'rgb(50, 50, 50)' },
    };

    const classes: any = useStyles();
    const date = moment(task?.dueDate).format('dd/mm/yyyy') || '';
    const [openCalender, setOpenCalender] = useState<boolean>(false);
    const time = task?.dueDate ? task?.dueDate.split('T')[1] : '';

    let initialValues: TaskListObject = {
      taskId: 0,
      dealerId: previousCustomer ? previousCustomer?.locationDealerId ?? 0 : 0,
      assignedUserId: '',
      assignedUsername: '',
      createdByUserId: '',
      createdByUserName: '',
      customerName: '',
      dateCreated: '',
      dateUpdated: '',
      dealerLocationName: '',
      dueDate: '',
      dueTime: '',
      followUpTaskId: 0,
      isFollowed: false,
      notes: '',
      customerId: previousCustomer ? previousCustomer?.customerId : '',
      taskStatus: '',
      taskSubject: '',
      taskType: '',
      taskStatusId: 1,
      taskTypeId: null,
      taskOutcomeId: null,
      taskOutcome: null,
      taskArchiveReason: '',
      isArchived: false,
      dealId: 0,
      dealTitle: '',
    };

    if (task !== undefined) {
      initialValues = {
        ...task,
        dueDate: task.dueDate ? moment.utc(task.dueDate).local().format().split('T')[0] : '',
        dueTime: task.dueDate ? moment.utc(task.dueDate).local().format().split('T')[1].slice(0, 5) : '',
      };
    }

    useImperativeHandle(ref, () => ({
      getValues() {
        const sanitizedValues = { ...formik.values };

        if (alreadyCompleted) {
          if (!formik.values.taskOutcomeId) {
            formik.setFieldError('taskOutcomeId', 'Required');
          }
        }
        sanitizedValues.assignedUserId = selectedUser ? selectedUser.crmUserId : '';
        sanitizedValues.customerId = selectedCustomer ? selectedCustomer.id : 0;
        sanitizedValues.customerName = selectedCustomer ? selectedCustomer.name : '',
          sanitizedValues.taskStatusId = alreadyCompleted ? UserTaskStatus.Completed : UserTaskStatus.New;
        if (formik.isValid) {
          let k: TaskListObject = { ...sanitizedValues };
          return k;
        }
        return null;
      },
    }));
    const selectedTasks = useAppSelector((state) => state.tasks.selectedTasks);

    const [alreadyCompleted, setAlreadyCompleted] = useState<boolean>(false);
    const [followTask, setFollowTask] = useState<boolean>(false);
    const dealerId = useAppSelector((state) => state.user.dealerId);
    const users = useUsersList(dealerId);
    const defaultUserValue: User = {
      crmUserId: '',
      dealerId: 0,
      departments: [],
      fullName: '',
      id: '',
      isActive: false,
      isAdmin: false,
      userId: 0,
      userRoles: [],
      username: '',
    };
    const defaultCustomerValue: CustomerDto = {
      id: 0,
      dealerId: 0,
      cellPhone: '',
      city: '',
      name: previousCustomer?.Name,
      verificationStatus: false,
      stateProvince: '',
      zipPostal: '',
      optIn: 0,
    };

    const currentUserId = useAppSelector((state) => state.user.crmUserId);
    const [openCustomerDropdown, setOpenCustomerDropdown] = React.useState(false);
    const [customerOptions, setCustomerOptions] = React.useState<CustomerDto[]>([]);
    const [customerloading, setCustomerLoading] = React.useState(false);
    const [taskTypes, setTaskTypes] = React.useState([]);
    const [selectedOutcome, setSelectedOutcome] = useState(0);
    const [outcomes, setOutcomes] = useState([]);
    const [taskTime, setTaskTime] = useState<ITaskTimes[]>(taskHours);
    const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

    const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
    const { loading: locationLoading, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);
    const dealerLocationId = useAppSelector((state: any) => state.tasks.dealerId);

        const dispatch = useAppDispatch();
  //   let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  //   let isCustomerPermission = 0;
  //   if (!userPermissions) {
  //     userPermissions = [];
  //     isCustomerPermission = 2;
  //   }  
  //  if (isCustomerPermission == 0) {
  //   if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
  //     isCustomerPermission = 1;
  //  } 
  // }

    if (initialValues.dealerId === 0 && locationOptions?.length == 1) {   // This condition sets the location to the first value of dealer location if only one dealer location is available 
      initialValues.dealerId = locationOptions[0].dealerLocationId;
    } 

    const onCustomerDropdownChange = async (value: string) => {
      setCustomerLoading(true);
      let data = await CustomerAPI.getCustomers(dealerId, 0, 10, value);
      if (data) {
        let customers = data?.customers.map((x: any) => ({
          name:
            x.name +
            `${dmsTypeId != 1 && (x.organizationName && x.organizationName != '') ? ' - ' + x.organizationName : ''}`,
          id: x.id,
        }));
        setCustomerOptions(customers);
      } else {
        setCustomerOptions([]);
      }
      setCustomerLoading(false);
    };
    const getOutcomeList = useCallback(async (): Promise<void> => {
      let data = await TaskboardAPI.getTaskOutcomes();
      setOutcomes(data);
    }, [1]);

    React.useEffect(() => {
      getOutcomeList();
    }, [getOutcomeList]);

    const getTaskTypeList = useCallback(async (): Promise<void> => {
      let data = await TaskboardAPI.getTaskTypes();
      setTaskTypes(data);
    }, [1]);

    React.useEffect(() => {
      getTaskTypeList();
    }, [getTaskTypeList]);

    const handleUserChange = (val: User) => {
      // if(val && val.crmUserId){
      //   formik.setFieldValue('assignedUserId', val.crmUserId);
      // }else{
      //   formik.setFieldValue('assignedUserId', '');
      // }

      if (val && val.crmUserId !== currentUserId) {
        setFollowTask(true);
      } else {
        setFollowTask(false);
      }
      setSelectedUser(val);
    };

    // id: number;
    // dealerId: number;
    // name : string;
    // cellPhone : string;
    // verificationStatus: boolean;
    // optIn: number;
    // city : string;
    // stateProvince : string;
    // zipPostal : string;

    let customer: CustomerDto | null = null;
    if (task) {
      customer = {
        id: task.customerId,
        dealerId: 0,
        cellPhone: '',
        city: '',
        name: task.customerName,
        verificationStatus: false,
        stateProvince: '',
        zipPostal: '',
        optIn: 0,
      };
    } else if (previousCustomer) {
      customer = {
        id: previousCustomer?.customerId,
        dealerId: 0,
        cellPhone: '',
        city: '',
        name: previousCustomer?.customerName,
        verificationStatus: false,
        stateProvince: '',
        zipPostal: '',
        optIn: 0,
      };
    }

    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDto | null>(customer);
    React.useEffect(() => {
      if (selectedCustomer) {
        let k: any = customer;
        if (k) {
          setCustomerOptions([k]);
        }
      } else {
        setCustomerOptions([]);
      }
      if (!open) {
        onCustomerDropdownChange('');
      }
    }, [open, selectedCustomer]);

    React.useEffect(() => {
      let selectedCrmUserId = '';
      if (task) {
        selectedCrmUserId = task.assignedUserId;
      } else {
        selectedCrmUserId = currentUserId;
      }
      const currentUser = users?.filter((x) => x.crmUserId.toUpperCase() === selectedCrmUserId.toUpperCase())[0];
      if (currentUser) {
        setSelectedUser(currentUser);
      }
    }, [users]);

    const formik = useFormik({
      initialValues,
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: (values: TaskListObject) => {
        const sanitizedValues = { ...values };
        if (!alreadyCompleted) {
          if (!values.dueDate || !values.dueTime) {
            formik.setFieldValue('dueDate', '');
          } else {
            const dueDateWithTime = moment
              .utc(new Date(sanitizedValues.dueDate.split('T')[0] + 'T' + sanitizedValues.dueTime))
              .format();
            formik.setFieldValue('dueDate', dueDateWithTime);
          }
        }

        if (alreadyCompleted) {
          if (!formik.values.taskOutcomeId) {
            formik.setFieldError('taskOutcomeId', 'Required');
          }
        }
        sanitizedValues.assignedUserId = selectedUser ? selectedUser.crmUserId : '';
        sanitizedValues.customerId = selectedCustomer ? selectedCustomer.id : 0;
        sanitizedValues.taskStatusId = alreadyCompleted ? UserTaskStatus.Completed : UserTaskStatus.New;
        onFormSubmit(sanitizedValues);
      },
    });

    const handleSave = () => {
      formik.setTouched({
        taskSubject: true,
        taskTypeId: true,
        dealerId: true,
        dueDate: true,
        //dueTime: true
      });
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          formik.submitForm();
        } else {
          // Optionally
          formik.setTouched({ ...formik.touched, taskSubject: true, taskTypeId: true, dealerId: true, dueDate: true, dueTime: true }, false);
        }
      });
    };
    useEffect(() => {
      const newDate = moment.utc().toDate().toISOString().split('T')[0];
      if (formik.values.dueDate.length > 0 && formik.values.dueDate < newDate) {
        formik.setFieldValue('dueDate', newDate);
      }
    }, []);

    function taskDateTimeFilter() {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, '0');
      const minutes = currentTime.getMinutes().toString().padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      const currentDate = `${currentTime.getFullYear()}-${(currentTime.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentTime.getDate().toString().padStart(2, '0')}`;
      if (formik.values.dueDate === currentDate) {
        const time: ITaskTimes[] = taskTime.filter((x: ITaskTimes) => x.value > formattedTime);
        const formikTime = formik.values.dueTime.length > 0 && time.find((x: ITaskTimes) => x.value === formik.values.dueTime);
        if ((formikTime ?? '') === '') {
          formik.setFieldValue('dueTime', '');
        }
        setTaskTime(time);
      } else {
        setTaskTime(taskHours);
      }
    }

    useEffect(() => {
      const interval = setInterval(() => {
        taskDateTimeFilter();
      }, 500);
      return () => clearInterval(interval);
    }, [formik.values.dueDate, formik.values.dueTime]);

    return (
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        {edit && (
          <Grid container>
            <Grid item xs={6}>
              <Box mt={1}>
                <TextField
                  disabled={edit}
                  fullWidth
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  id="taskSubject"
                  style={{ paddingTop: '5px', paddingBottom: '5px' }}
                  name="taskSubject"
                  label="Subject *"
                  value={formik.values.taskSubject}
                  error={!!formik.errors.taskSubject}
                  helperText={formik.errors.taskSubject}
                  inputProps={fontColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mt={1} ml={1}>
                <TextField
                  disabled={edit}
                  fullWidth
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  id="taskStatus"
                  style={{ paddingTop: '5px', paddingBottom: '5px' }}
                  name="taskStatus"
                  label="Task Status"
                  inputProps={fontColor}
                  value={UserTaskStatus[formik.values.taskStatusId]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {(done === true || done === null) && (
          <Box>
            <Box mt={1}>
              <Grid container>
                <Grid item xs={!reschedule ? 6 : 12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="taskSubject"
                    style={{ paddingTop: '5px', paddingBottom: '5px' }}
                    name="taskSubject"
                    label="Subject *"
                    value={formik.values.taskSubject}
                    error={!!formik.errors.taskSubject}
                    helperText={formik.errors.taskSubject}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 51, color: fontColor }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </Grid>
                {!reschedule && !done && (
                  <Grid item xs={6}>
                    <Box mt={1}>
                      <FormControlLabel
                        control={
                          <Switch
                            id="alreadyCompleted"
                            checked={alreadyCompleted}
                            onChange={(e: any) => {
                              setAlreadyCompleted(e.target.checked);
                              onMarkAlreadyCompleted(e.target.checked);
                            }}
                            name="alreadyCompleted"
                            color="primary"
                          />
                        }
                        label="Already Completed"
                        labelPlacement="start"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Grid container>
              <Grid item xs={6}>
                <Autocomplete
                  id="customerDropdown"
                  disabled={reschedule || disableCustomerSelect}
                  value={selectedCustomer}
                  onChange={(event: any, values: any): void => {
                    setSelectedCustomer(values);
                  }}
                  open={openCustomerDropdown}
                  onOpen={() => {
                    setOpenCustomerDropdown(true);
                  }}
                  onClose={() => {
                    setOpenCustomerDropdown(false);
                  }}
                  getOptionSelected={(option: any, value: any) => option.id === value.id}
                  getOptionLabel={(option: any) => option.name || ''}
                  options={customerOptions}
                  filterOptions={(customerOptions) => customerOptions}
                  loading={customerloading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Customer"
                      variant="outlined"
                      margin="dense"
                      onChange={(ev) => {
                        if (ev.target.value !== '' || ev.target.value !== null) {
                          onCustomerDropdownChange(ev.target.value);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {customerloading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      style={{ color: 'rgb(50, 50, 50) !important' }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Box ml={1}>
                  <Autocomplete
                    disabled={edit}
                    id="usersDropdown"
                    disableClearable={true}
                    value={selectedUser || defaultUserValue}
                    options={users || []}
                    onChange={(event: any, values: any): void => handleUserChange(values)}
                    getOptionLabel={(option: any) => option.username}
                    disableCloseOnSelect
                    placeholder={selectedUser?.username}
                    getOptionSelected={(option: User, value: User): boolean => {
                      return option.crmUserId === value.crmUserId;
                    }}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        {option.username}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="users"
                        id="users"
                        variant="outlined"
                        margin="dense"
                        label="Assignment"
                        value={formik.values.assignedUserId}
                        error={!!formik.errors.assignedUserId}
                        helperText={formik.errors.assignedUserId}
                        placeholder="Select Users"
                        style={{ color: 'rgb(50, 50, 50) !important' }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel margin="dense" variant="outlined" id="dealerId">
                      Location *
                    </InputLabel>
                    <Select
                      disabled={edit}
                      labelId="dealerId"
                      id="dealerId"
                      value={formik.values.dealerId}
                      error={!!formik.errors.dealerId}
                      label="Location *"
                      margin="dense"
                      name="dealerId"
                      onChange={(e: any, opt: any) => {
                        formik.handleChange(e);
                        formik.setFieldValue('dealerId', opt.props.value);
                      }}>
                      {locationOptions &&
                        locationOptions.map((loc) => (
                          <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                            {loc.name === 'All Locations' ? 'Select Location' : loc.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {!!formik.errors.dealerId && (
                      <FormHelperText style={{ color: '#e53935' }}>{formik.errors.dealerId}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={8}>
                {alreadyCompleted && (
                  <Box mt={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="Outcome" variant="outlined" margin="dense">
                        Outcome
                      </InputLabel>
                      <Select
                        labelId="Outcome"
                        id="Outcome"
                        value={formik.values.taskOutcomeId}
                        error={!!formik.errors.taskOutcomeId}
                        label="Task Outcome"
                        margin="dense"
                        onChange={(e: any, opt: any) => {
                          formik.handleChange(e);
                          formik.setFieldValue('taskOutcomeId', opt.props.value);
                        }}>
                        {outcomes?.map((x: any) => (
                          <MenuItem value={x.id}>{x.value}</MenuItem>
                        ))}
                      </Select>
                      {!!formik.errors.taskOutcomeId && (
                        <FormHelperText style={{ color: '#e53935' }}>{formik.errors.taskOutcomeId}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                )}
                {!alreadyCompleted && (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disabled={edit}
                            label="Due Date"
                            open={openCalender}
                            onClose={() => setOpenCalender(false)}
                            minDate={new Date()}
                            maxDate={new Date('2099-12-31')}
                            value={formatDatePicker(formik.values.dueDate)}
                            onChange={(e: any) => {
                              if (e) {
                                formik.setFieldValue(
                                  'dueDate',
                                  moment.utc(formatDatePicker(e)).toDate().toISOString().split('T')[0],
                                  true,
                                );
                                setOpenCalender(false);
                              }
                            }}
                            renderInput={(params: any) => {
                              params.error = !!formik.errors.dueDate;
                              return (
                                <TextField
                                  margin="dense"
                                  name="dueDate"
                                  fullWidth
                                  {...params}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onClick={() => {
                                    setOpenCalender(!openCalender);
                                  }}
                                  variant="outlined"
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          disabled={formik.values.dueDate === ''}
                          fullWidth
                          select
                          variant="outlined"
                          size="small"
                          margin="dense"
                          name="dueTime"
                          label="Time *"
                          value={formik.values.dueTime}
                          onChange={formik.handleChange}
                          error={!!formik.errors.dueTime}
                          helperText={ formik.errors.dueTime}
                        >
                          {taskTime.map((hour: ITaskTimes) => (
                            <MenuItem
                              style={{ overflowY: 'auto', maxHeight: '200px' }}
                              key={hour.value}
                              value={hour.value}
                            >
                              {hour.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <Box mt={1} ml={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel margin="dense" variant="outlined" id="taskType">
                      Task Type *
                    </InputLabel>
                    <Select
                      disabled={edit}
                      labelId="taskType"
                      id="taskTypeId"
                      value={formik.values.taskTypeId}
                      error={!!formik.errors.taskTypeId}
                      label="Task Type *"
                      margin="dense"
                      name="taskTypeId"
                      onChange={(e: any, opt: any) => {
                        formik.handleChange(e);
                        formik.setFieldValue('taskTypeId', opt.props.value);
                      }}>
                      {taskTypes?.map((x: any) => (
                        <MenuItem value={x.id}>{x.value}</MenuItem>
                      ))}
                    </Select>
                    {!!formik.errors.taskTypeId && (
                      <FormHelperText style={{ color: '#e53935' }}>{formik.errors.taskTypeId}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {/* {
            currentUserId !== selectedUser?.crmUserId && !reschedule &&
            <Grid container >
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      id="followTask"
                      checked={followTask}
                      onChange={(e: any) => { setFollowTask(e.target.checked) }}
                      name="followTask"
                      color="primary"
                    />
                  }
                  label="Follow Task"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>

          } */}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  disabled={edit}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows={4}
                  id="notes"
                  name="notes"
                  label="Notes"
                  value={formik.values.notes}
                  error={!!formik.errors.notes}
                  helperText={formik.errors.notes}
                  onChange={formik.handleChange}
                  inputProps={fontColor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {(done == true || done == null) && edit === false && !hideButtons && (
          <Box mt={2}>
            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button id="cancelTaskFormBtn" variant="contained" onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  fullWidth
                  id="saveTaskFormBtn"
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={(e: any) => {
                    formik.handleSubmit(e);
                  }}>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </form>
    );
  },
);
export default TaskForm;
