import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { BroadCast, BroadCastType, SendBroadcastTestMessageRequest } from 'components/broadcast/models/broadcast';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { TabPanel } from 'common/components/tabs';
import { useRouter } from 'common/hooks';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import Swal from 'sweetalert2';
import { BroadcastType } from 'common/utils/shared/shared-constants';
import { PhoneNumberTextMaskCustom } from 'common/components/input-fields/PhoneNumberTextMaskCustom';
import { FormatPhoneNumberCustom } from 'common/utils/format-phoneNumber';

// Regular expressions for validation
const phoneNumberRegex = /^(\+?\d{1,4}?[\s-]?)?(\(?\d{1,3}?\)?[\s-]?)?[\d\s-]{10,}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
     padding: theme.spacing(3),
     marginBottom: theme.spacing(3),
  },
  toggleButtonGroup: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleButton: {
    height: '40px',
    fontWeight: 'bold',
    '&.Mui-selected': {
      backgroundColor: '#3f51b5',
      color: '#fff',
    },
    '& .MuiToggleButton-label': {
      fontWeight: 'bold',
    },
  },
  chipContainer: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
  },
  dialogTitle: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: '#fff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  sendButton: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  infoText: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: '0.9rem',
  },
  // Responsive adjustments
  '@media (max-width: 600px)': {
    dialogContent: {
      padding: theme.spacing(2),
    },
    toggleButton: {
      fontSize: '0.8rem',
      height: '36px',
    },
    textField: {
      fontSize: '0.9rem',
      marginTop: theme.spacing(2),
    },
    infoText: {
      fontSize: '1rem',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  swalContainerError:{
    '& .swal2-popup': {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
      '& .swal2-title': {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
      },
      '& .swal2-content': {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
      },
      '& .swal2-confirm': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        borderRadius: "4px",
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
  },
  swalContainer: {
    '& .swal2-popup': {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(3),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& .swal2-title': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
    '& .swal2-content': {
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily,
    },
    '& .swal2-confirm': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: "4px",
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  errorList: {
    fontSize: '15px',
    textAlign: 'left',
    color: theme.palette.error.main,
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  errorColor : {color:theme.palette.error.main},
}));

export default function BroadcastTestMessage({
  broadcast,
  type,
  openTestMessageDialog,
  onClose,
}: {
  broadcast: BroadCast;
  type: BroadCastType;
  openTestMessageDialog: boolean;
  onClose(): Promise<void>;
}): React.ReactElement {

  const router = useRouter();
  const { query }: any = router;
  const classes = useStyles();
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isTestMessageLoading, setIsTestMessageLoading] = React.useState(false);

  const validateAndSetItems = (
    input: string,
    setItems: React.Dispatch<React.SetStateAction<string[]>>,
    currentItems: string[],
    isPhone: boolean
  ) => {
    const items = input.split(/[\s,]+/).map(item => item.trim()).filter(item => item.length > 0);

    // Check for duplicates within the input
    const hasDuplicates = new Set(items).size !== items.length;
    if (hasDuplicates) {
      return isPhone
        ? "Duplicate phone number detected. Please remove duplicates and try again."
        : "Duplicate email detected. Please remove duplicates and try again.";
    }

    // Check for duplicates against current items
    const hasDuplicatesInCurrentItems = items.some(item => currentItems.includes(item));
    if (hasDuplicatesInCurrentItems) {
      return isPhone
        ? "Duplicate phone number detected. Please remove the duplicate and try again."
        : "Duplicate email detected. Please remove the duplicate and try again.";
    }

    // Check for invalid phone numbers or emails
    for (const item of items) {
      if (isPhone && !phoneNumberRegex.test(item)) {
        return "Invalid phone number format. Please check and try again.";
      }
      if (!isPhone && !emailRegex.test(item)) {
        return "Invalid email address. Please check and try again.";
      }
    }

    // Check if the number of items exceeds the limit
    if (items.length + currentItems.length > 2) {
      return isPhone
        ? "You can only enter up to 2 phone numbers."
        : "You can only enter up to 2 email addresses.";
    }

    // No errors, set items
    setItems([...currentItems, ...items]);
    return null;
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    setItems: React.Dispatch<React.SetStateAction<string[]>>,
    currentItems: string[],
    isPhone: boolean
  ) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault();
      const input = isPhone? (event.target as HTMLInputElement).value.trim().replace(/[^0-9]/g, '')
      : (event.target as HTMLInputElement).value.trim();
      
      if (input) {
        const error = validateAndSetItems(input, setItems, currentItems, isPhone);
        if (isPhone) {
          setPhoneNumberError(error);
        } else {
          setEmailError(error);
        }
        if (error === null) {
          (event.target as HTMLInputElement).value = '';
        }
      }
    }
  };

  const handleDelete = (
    itemToDelete: string,
    setItems: React.Dispatch<React.SetStateAction<string[]>>,
    isPhone: boolean
  ) => () => {
    setItems((prevItems) => prevItems.filter((item) => item !== itemToDelete));
    // Clear error message if there are no more errors
    if (isPhone && phoneNumbers.length === 1) {
      setPhoneNumberError(null);
    }
    if (!isPhone && emailAddresses.length === 1) {
      setEmailError(null);
    }
  };

  async function SendTestMessage() {
    if (phoneNumbers.length === 0 && emailAddresses.length === 0) {
        Swal.fire({
          title: 'Error',
          html: `<ul class="${classes.errorList}">
          <li>Please enter at least one ${BroadcastType.EMAIL == type?"email address to send a test email":"phone number to send a test message"}.</li>
          </ul>`,
          icon: 'error',
          customClass: {
            container: classes.swalContainerError,
          },
        });
        return;
      }

    let testMsgRequest : SendBroadcastTestMessageRequest={
        MessageSetting : JSON.stringify(broadcast) ,
        PhoneNumbers:phoneNumbers.join(','),
        EmailAddress:emailAddresses.join(';'),
        BroadcastType: query.type,
        DealerId:0,
        CrmUserId:"",
    };
    
    setIsTestMessageLoading(true);
    try
    { 
        const testMsgResponse = await BroadCastAPI.sendBroadcastTestMessage(testMsgRequest);
        console.log({testMsgResponse})

        if(testMsgResponse.isSuccess){
            
            if( ( testMsgResponse.data.failedEmailAddress.length > 0 
                && testMsgResponse.data.failedEmailAddress.length == testMsgResponse.data.totalCount
            ) ||
            (  testMsgResponse.data.failedPhoneNumbers.length > 0 
                && testMsgResponse.data.failedPhoneNumbers.length == testMsgResponse.data.totalCount)
            )
            {
                Swal.fire({
                    title: 'Error',
                    html: `An error occurred while sending the ${BroadcastType.EMAIL == type?"test email":"test message"}. Please try again.`,
                    icon: 'error',
                    customClass: {
                      container: classes.swalContainerError,
                    },
                  });
                return;
            }

            let html="", failedHtml = "", errorMsg="";

            if(testMsgResponse.data.failedEmailAddress.length > 0)
            {
                errorMsg = "However, some test broadcasts were unsuccessful against emails:";
                failedHtml = testMsgResponse.data.failedEmailAddress
                    .map((element) => `<li>${element.item1}</li>`)
                    .join('');
            }

            if(testMsgResponse.data.failedPhoneNumbers.length > 0)
            {
                errorMsg = "However, some test broadcasts were unsuccessful against phone numbers:";
                failedHtml = testMsgResponse.data.failedPhoneNumbers
                    .map((element) => `<li>${element.item1}</li>`)
                    .join('');
            }

            if(failedHtml != "")
            {
                html = `
                <div style="font-size: 15px; text-align: left; color: ${classes.errorColor}">
                    <p>Test ${type} sent successfully.</p>
                    <p>${errorMsg}</p>
                    <ul class="${classes.errorList}">
                    ${failedHtml}
                    </ul>
                </div>
                  `;        

                Swal.fire({
                    title: 'Test Message!',
                    html: html,
                    icon: 'success',
                    customClass: {
                        container: classes.swalContainer,
                    },
                });
            }
            else
            {
                Swal.fire({
                    title: 'Test Message!',
                    html: `Test ${type} sent successfully.`,
                    icon: 'success',
                    customClass: {
                        container: classes.swalContainer,
                    },
                });

                onClose();
            }
           
        }
        else
        {
            let formatedMessage =testMsgResponse.error.includes("Daily limit reached") ?testMsgResponse.error.replace(/\n/g, '<br>')
            : 'There was an issue sending your message. Please see details:<br>' + testMsgResponse.error.replace(/\n/g, '<br>');
           
            Swal.fire({
                title: 'Error!',
                html: formatedMessage,
                icon: 'error',
                customClass: {
                    container: classes.swalContainerError,
                  },
              });
        }
    }
    catch(error:any)
    {
        Swal.fire({
            title: 'Error',
            html: `An error occurred while sending the test message. Please try again.`,
            icon: 'error',
            customClass: {
              container: classes.swalContainerError,
            },
          });
        
    }
    finally {
        setIsTestMessageLoading(false);
      }
  }
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason && reason === "backdropClick") 
        return;
    onClose();
}
  return (
    <>
    
    <Dialog open={openTestMessageDialog} onClose={handleClose} fullWidth maxWidth="md" >
      <DialogTitle className={classes.dialogTitle}>Send Test Message</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Paper elevation={3} className={classes.paper}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box mt={2} onClick={(e) => e.preventDefault()}>
                <Grid container>
                  <Box display="flex" flexDirection="row" className={classes.toggleButtonGroup}>
                    <ToggleButtonGroup exclusive size="small" value={type}>
                      <ToggleButton className={classes.toggleButton} value="SMS" disabled>
                        SMS
                      </ToggleButton>
                      <ToggleButton className={classes.toggleButton} value="MMS" disabled>
                        MMS
                      </ToggleButton>
                      <ToggleButton className={classes.toggleButton} value="EMAIL" disabled>
                        Email
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>

                <TabPanel value={type} index={type == BroadcastType.SMS ? BroadcastType.SMS : BroadcastType.MMS}>
                  <Typography variant="h4" className={classes.infoText} style={{ fontWeight: 'bold' }}>
                    Who should receive the test {type == BroadcastType.SMS ? BroadcastType.SMS: BroadcastType.MMS}?
                  </Typography>
                  <Typography variant="body1" className={classes.infoText}>
                  Please enter one or more phone numbers and we will send a test message to those numbers.
                   </Typography>
                  <TextField
                  label="Phone Number"
                  placeholder="Enter phone numbers separated by commas or spaces"
                  onKeyDown={(e) => handleKeyDown(e, setPhoneNumbers, phoneNumbers, true)}
                  onChange={(e) => {
                    const input = e.target.value.replace(/[^0-9]/g, '');
                    console.log({input})
                    if (input.length == 10)
                    {
                      e.preventDefault();
                      if (input) {
                        const error = validateAndSetItems(input, setPhoneNumbers, phoneNumbers, true);
                       
                        setPhoneNumberError(error);
                       
                        if (error === null) {
                          (e.target as HTMLInputElement).value = '';
                        }
                      }
                    }    
                  }}
                  fullWidth
                  variant='outlined'
                  margin="dense"
                  size='small'
                  className={classes.textField}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                  InputProps={{
                    inputComponent: PhoneNumberTextMaskCustom as any,
                  }} />
                  
                  <Box className={classes.chipContainer}>
                    {phoneNumbers.map((phoneNumber) => (
                      <Chip
                        key={phoneNumber}
                        label={FormatPhoneNumberCustom(phoneNumber)}
                        onDelete={handleDelete(phoneNumber, setPhoneNumbers, true)}
                        className={classes.chip}
                      />
                    ))}
                  </Box>
                </TabPanel>

                <TabPanel value={type} index={BroadcastType.EMAIL}>
                  <Typography variant="h4" className={classes.infoText} style={{ fontWeight: 'bold' }}>
                    Who should receive the test {BroadcastType.EMAIL}?
                  </Typography>
                  <Typography variant="body1" className={classes.infoText}>
                  Please enter an email address, then press the space bar or comma to add more. We will send a test email to each address you enter.                 
                  </Typography>
                  <TextField
                    label="Email Address"
                    placeholder="Enter email addresses separated by commas or spaces"
                    onKeyDown={(e) => handleKeyDown(e, setEmailAddresses, emailAddresses, false)}
                    fullWidth
                    variant='outlined'
                    size='small'
                    className={classes.textField}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <Box className={classes.chipContainer}>
                    {emailAddresses.map((emailAddress) => (
                      <Chip
                        key={emailAddress}
                        label={emailAddress}
                        onDelete={handleDelete(emailAddress, setEmailAddresses, false)}
                        className={classes.chip}
                      />
                    ))}
                  </Box>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        {isTestMessageLoading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} className={classes.cancelButton} variant="contained">
          Cancel
        </Button>
        <Button onClick={SendTestMessage} className={classes.sendButton} variant="contained" disabled={isTestMessageLoading}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}
