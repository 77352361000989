import { Box } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { SmallCircularProgress } from 'common/components/loaders';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import config from 'config';
import React, { ReactElement } from 'react';

const textEditorApiKey = config.get('api.textEditor');

function EmailEditor({
  value,
  isEmpty,
  disabled = false,
  onChange,
  onImageUpload,
}: {
  value: string;
  isEmpty?:boolean;
  disabled?: boolean;
  onChange(data: string): void;
  onImageUpload?: (file: File) => Promise<GalleryImage[]>;
}): ReactElement {
  const [loading, setLoading] = React.useState(true);
  const maxImageSize = 26214400; //25MB
  const init = {
    paste_data_images: true,
    automatic_uploads: true,
    setup(ed: any): void {
      ed.on('init', () => setLoading(false));
    },
    height: 240,
    branding: false,
    image_advtab: true,
    convert_urls: true, 
    relative_urls: false,
    remove_script_host: false,
    async images_upload_handler(blobInfo: any, success: any, failure: any, progress: any): Promise<void> {
      if (onImageUpload) {
        try {
          progress(0);
          if (blobInfo.blob().size > maxImageSize) {
            progress(100);
            failure('Total Size Exceeds 25 MB');
          } else {
            const response = await onImageUpload(new File([blobInfo.blob()], blobInfo.filename()));
            progress(100);
            success(response[0].mediaUrl);
          }
        } catch (error) {
          progress(100);
          failure('Unable to upload image');
        }
      }
    },
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image code',
      'image imagetools',
      'insertdatetime media table paste code help wordcount',
    ],
    toolbar: `undo redo | image code | formatselect | bold italic backcolor |
                                                alignleft aligncenter alignright alignjustify |
                                                bullist numlist outdent indent | removeformat`,
    menu: {
      file: { title: 'File', items: ' preview | print ' },
    },
    menubar: 'file edit view insert format tools table',
  };
  return (
    <>
      <Box hidden={!loading}>
        <Box style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
          <Box mr={2}>Loading editor</Box>
          <SmallCircularProgress />
        </Box>
      </Box>
      <Box hidden={loading} style={{border: isEmpty ? '1px solid red':''}}>
        <Editor
          apiKey={textEditorApiKey ||''}
          id="tinyEmail"
          value={value}
          init={init}
          disabled={disabled}
          onEditorChange={onChange}
        />
      </Box>
      {isEmpty && <span style={{color:'#ec706d', fontSize:'11px'}}>&emsp;&nbsp;Email body is required</span>}
    </>
  );
}

export default EmailEditor;

