import { MessageTypeConst } from "./shared-constants";

export function checkStringIsEmpty(value: string): boolean {
    if (value.trim().length === 0)
        return true;
    return false;
}

export function IsPaymentMessage(messageType: string): boolean {
    if ( messageType == MessageTypeConst.PaymentReceived ||
         messageType == MessageTypeConst.PaymentSent ||
         messageType == MessageTypeConst.PaymentCanceled ||
         messageType == MessageTypeConst.PaymentVoided
    )
        return true;
        
    return false;
}