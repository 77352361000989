import { get, put } from 'api';
import config from 'config';

import AutoResponderSetting from '../models/auto-responder-setting';

const dealerUrl = config.get('api.dealer');

const AutoResponderSettingAPI = {
  async getAutoResponderSetting(dmsDealerIdCrm: number): Promise<AutoResponderSetting[]> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/auto-responder`;
    const { data } = await get(url);
    return data;
  },
  async updateAutoResponderSetting(dmsDealerIdCrm: number, autoResponder: AutoResponderSetting[]): Promise<void> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/auto-responder`;
    const { data } = await put(url, autoResponder);
    return data;
  },
};

export default AutoResponderSettingAPI;
