import { Box, Checkbox, Container, Drawer, MenuItem, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import * as Yup from 'yup';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';
import DepartmentAPI from 'components/setting/api/department-api';
import Department from 'components/setting/models/department';
import User from 'components/setting/models/user';
import AppSnackbar from 'components/snackbar/snackbar';

import useUsersList from './userUsersList';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditDepartmentDrawer({
  locations,
  departmentId,
  open,
  onClose,
  onSave,
  onDelete,
}: {
  locations: LocationOption[];
  departmentId?: number;
  open: boolean;
  onClose(): void;
  onSave(value: any): void;
  onDelete(departmentId: number): Promise<void>;
}): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dispatch = useAppDispatch();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [department, setDepartment] = React.useState<Department | undefined>(undefined);
  const users = useUsersList(dealerId);
  const [Istextboxdisabled, setIstextboxdisabled] = React.useState<boolean>(false);
  let defaultDealerLocation = 0;
  if (locations.length === 1) {
    defaultDealerLocation = locations[0].dealerLocationId;
  }
  const initialValues: {
    departmentId?: number;
    dealerLocationId?: number;
    departmentName?: string;
    users: User[];
  } = {
    departmentId: department?.departmentId || 0,
    dealerLocationId: department?.dealerLocationId || defaultDealerLocation,
    departmentName: department?.departmentName || '',
    users: department?.users || [],
  };
  const editMode = !!department?.departmentId;
 

  const validationSchema = Yup.object<any>().shape<any>({
    departmentName: Yup.string().required('Department name is required'),
    dealerLocationId: Yup.number()
      .notOneOf([0], 'Department location is required')
      .required('Department location is required'),
  });

  async function handleSubmit(values: any): Promise<void> {
    await onSave(values);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleUserChange(values: any[]): void {
    formik.setValues(
      {
        departmentId: formik.values.departmentId,
        departmentName: formik.values.departmentName,
        dealerLocationId: formik.values.dealerLocationId,
        users: values,
      },
      false,
    );
  }

  React.useEffect(() => {
    async function fetchDepartment(): Promise<void> {
      if (departmentId !== undefined) {
        try {
          const response = await DepartmentAPI.getDepartment(dmsDealerIdCrm, departmentId);
          if (!response) throw new Error('Invalid response');
          setDepartment(response);
          console.log(response)
          if(response?.departmentType == "Facebook"){setIstextboxdisabled(true)}
        } catch (error) {
          showSnackbar({ type: 'error', message: 'Unable to get department' });
          onClose();
          setDepartment(undefined);
        }
      } else {
        setDepartment(undefined);
      }
    }
    fetchDepartment();
  }, [departmentId, dispatch, dmsDealerIdCrm, onClose, showSnackbar]);

  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Edit Department" onSave={formik.handleSubmit} />
      <Box mb={1} />
      <Container>
        {open && (
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              variant="outlined"
              name="departmentName"
              id="departmentName"
              label="Department Name"
              onChange={formik.handleChange}
              placeholder="Department Name"
              value={formik.values.departmentName}
              error={!!formik.errors.departmentName}
              helperText={formik.errors.departmentName}
              disabled = {Istextboxdisabled}
              required
            />
            <TextField
              fullWidth
              select
              margin="dense"
              variant="outlined"
              name="dealerLocationId"
              disabled={editMode || locations.length === 1}
              inputProps={{ id: 'dealerLocationId' }}
              label="Location"
              onChange={formik.handleChange}
              value={formik.values.dealerLocationId}
              error={!!formik.errors.dealerLocationId}
              helperText={formik.errors.dealerLocationId}
              required>
              <MenuItem key={0} value={0}>
                Select Location
              </MenuItem>
              {locations.map((location) => (
                <MenuItem key={location.dealerLocationId} value={location.dealerLocationId}>
                  {location.name}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              multiple
              id="tags-standard"
              value={formik.values.users}
              options={users || []}
              onChange={(event: any, values: any): void => handleUserChange(values)}
              getOptionLabel={(option: any) => option.username}
              disableCloseOnSelect
              getOptionSelected={(option: User, value: User): boolean => option.crmUserId === value.crmUserId}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                  {option.username}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="users"
                  id="users"
                  variant="outlined"
                  margin="dense"
                  label="Users"
                  placeholder="Select Users"
                />
              )}
            />
          </form>
        )}
      </Container>
    </Drawer>
  );
}
