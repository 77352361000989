import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement, ReactNode } from 'react';

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 175,
    fontSize: '11px',
    border: '1px solid #dadde9',
    'margin-left': '10px',
  },
}))(Tooltip);

export default function CardToolTipWrapper({
  children,
  tooltip,
  toolTipVisible,
}: {
  children?: ReactNode;
  tooltip: any;
  toolTipVisible: boolean;
}): ReactElement {
  return (
    <>
      {toolTipVisible && (
        <HtmlTooltip
          title={
            tooltip ? (
              <>
                <Typography color="inherit" className='boradcastellipsis'>{tooltip}</Typography>
              </>
            ) : (
              ''
            )
          }
          arrow>
          <div>{children}</div>
        </HtmlTooltip>
      )}
      {!toolTipVisible && <div>{children}</div>}
    </>
  );
}
