import { Box, Container, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import { Redirect } from 'react-router-dom';

import { SimpleBackdrop } from 'common/components/loaders';
import { useRouter } from 'common/hooks';

import { SurveyForm } from '../survey-form';
import { QuestionCategory } from '../survey-page/survey-questions/new-question-block/new-question-block';
import useDealerSettings from '../survey-preview/useDealerSetting';
import { fetchPublishedSurvey, submitSurveyFeedback } from './published-survey-slice';
import { SurveyFormMessage } from './SurveyFormMessage';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      padding: theme.spacing(2, 0),
    },
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    justifyContent: 'space-around',
  },
  paper: {
    height: '80%',
    width: '50vw',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: `calc(100% - ${theme.spacing(2) * 2}px)`,
    },
    '@media (max-height: 500px)': {
      height: 'fit-content',
      padding: theme.spacing(5, 0),
    },
  },
}));

const useDealerLogoStyles = makeStyles((theme: Theme): any => ({
  root: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
}));
function DealerLogo({ url }: { url: string }): ReactElement {
  const classes: any = useDealerLogoStyles();
  return <img src={url} className={classes.root} alt="Dealer logo" />;
}

function SurveyHeader({ dealerLogoUrl }: { dealerLogoUrl?: string }): ReactElement {
  return <Box textAlign="center">{dealerLogoUrl ? <DealerLogo url={dealerLogoUrl} /> : null}</Box>;
}

function SurveyFormLoader({ loading }: { loading: boolean }): ReactElement | null {
  if (loading) {
    return <SimpleBackdrop />;
  }
  return null;
}

export function PublishedSurvey(): ReactElement {
  const classes: any = useStyles();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { query }: any = router;
  const loading = useAppSelector((state) => state.publishedSurvey.loading);
  const loader = useAppSelector((state) => state.publishedSurvey.loader);
  const error = useAppSelector((state) => (state.publishedSurvey.error ? true : false));
  const [ratingCategory, setRatingCategory] = useState<QuestionCategory>(QuestionCategory.Detractors);

  const feedbackSubmitted = useAppSelector((state) => state.publishedSurvey.feedbackSubmitted);
  const isCompleted = useAppSelector((state) => state.publishedSurvey.isCompleted);
  const feedbackSaving = useAppSelector((state) => state.publishedSurvey.savingFeedback);
  const survey = useAppSelector((state) => state.publishedSurvey.survey);
  const dealerLocationId = useAppSelector((state) => state.publishedSurvey.dealerLocationId);
  const [dealerLogo] = useDealerSettings(dealerLocationId);
  let selectSurveyLocationSetting;
  if (survey?.locations.length === 1) {
    selectSurveyLocationSetting = survey.locations[0];
  } else {
    selectSurveyLocationSetting = survey?.locations.find((x) => x.dealerLocationId === dealerLocationId);
  }
  useEffect(() => {
    if (query.hashId) {
      dispatch(fetchPublishedSurvey(query.hashId));
    }
  }, [dispatch, query.hashId]);

  const handleSurveyFormSubmit = (values: any): void => {
    setRatingCategory(values.ratingCategory);
    if (survey) {
      dispatch(submitSurveyFeedback(survey?.id, values.responses));
    }
  };
  let surveyFormMessage: 'completed' | 'review' | 'thankYou' | undefined;
  if (isCompleted) {
    surveyFormMessage = 'completed';
  } else if (feedbackSubmitted) {
    if (ratingCategory === QuestionCategory.Detractors) {
      surveyFormMessage = 'thankYou';
    } else {
      surveyFormMessage = 'review';
    }
  }
  return (
    <Box height="100%">
      <Container className={classes.container} maxWidth="sm">
        <Paper elevation={1} className={classes.paper}>
          <Container className={classes.innerContainer}>
            <SurveyHeader dealerLogoUrl={dealerLogo} />
            {surveyFormMessage ? (
              <SurveyFormMessage
                type={surveyFormMessage}
                googleUrl={
                  selectSurveyLocationSetting?.isGoogleReviewActive
                    ? selectSurveyLocationSetting.googleReviewUrl
                    : undefined
                }
                facebookUrl={
                  selectSurveyLocationSetting?.isFacebookReviewActive
                    ? selectSurveyLocationSetting.facebookReviewUrl
                    : undefined
                }
                pnpMessage={selectSurveyLocationSetting?.passiveNPromotersReviewMessage || ''}
                detractorMessage={selectSurveyLocationSetting?.detractorsReviewMessage || ''}
              />
            ) : (
              survey && <SurveyForm survey={survey} loading={loader} onSubmit={handleSurveyFormSubmit} />
            )}
          </Container>
        </Paper>
      </Container>
      {error && <Redirect to={{ pathname: '/404' }} />}
      <SurveyFormLoader loading={feedbackSaving || loading} />
    </Box>
  );
}

export default PublishedSurvey;
