import { makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
}));

export default function QuestionFieldTitle({
  id,
  title,
  required,
}: {
  id: number;
  title: string;
  required?: boolean;
}): ReactElement {
  const classes: any = useStyles();
  let questionTitle = title;
  if (required === true) {
    questionTitle += ' *';
  }
  return (
    <Typography className={classes.title} style={{ display: 'flex', flexDirection: 'row' }} variant="h5" component="h5">
      <Typography className={classes.title} component="span" color="primary">
        {id}
      </Typography>
      <Typography className={classes.title} variant="body1" component="span">
        .&nbsp;
      </Typography>
      <Typography style={{whiteSpace: 'pre-line'}} className={`${classes.title} questionTitle`} variant="body1" component="span">
        {questionTitle}
      </Typography>
    </Typography>
  );
}
