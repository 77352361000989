import { makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createTheme';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    textAlign: 'left',
    color: theme.palette.grey[900],
    fontSize: '9px',
    '&&.left': {
      paddingLeft: theme.spacing(2),
    },
    '&&.right': {
      paddingLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  },
}));

function Footer({
  status,
  dateTime,
  userName,
  direction,
}: {
  status: string;
  dateTime: string;
  userName: string;
  direction: string;
}): React.ReactElement {
  const classes: any = useStyles();
  const formattedDateTime =  moment.utc(dateTime).local().format('M-D h:mm a');
  return (
    <Typography className={`${classes.root} ${direction}`} component="div" variant="caption">
      {status} - {formattedDateTime} {userName && <span style={{ float: 'right' }}>&nbsp;{userName}</span>}
    </Typography>
  );
}

export default Footer;
