import { ReactElement, useEffect, useRef, useState } from 'react';
import useStyles from '../styles/customers-hub-table';
import {
  Box,
  Chip,
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Pagination } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app';
import { SmallCircularProgress } from 'common/components/loaders';
import AppSnackbar from 'components/snackbar/snackbar';
import { CustomerReviewHubDto } from '../model/customer-review-hub';
import { formatPhoneNumber } from 'common/utils';
import { format } from 'date-fns';
import EmailLink from 'common/components/buttons/email-link';
import CustomerReviewHubAPI from '../api/customers-review-hub-api';
import { fetchSelectedCustomer } from 'components/customer/components/Customers/customersSlice';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): 1 | -1 | 0 {
  if (orderBy != 'id') {
    if (b[orderBy] < a[orderBy] || (b[orderBy] == null && a[orderBy])) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || (a[orderBy] == null && b[orderBy])) {
      return 1;
    }
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | boolean | string | Date | null },
  b: { [key in Key]: number | boolean | string | Date | null },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let searchString = '';
export default function CustomerReviewHubTable({
  customerSearchText,
  setIsReviewHubEnabled,
}: {
  customerSearchText: string;
  setIsReviewHubEnabled: any;
}): ReactElement {
  const classes: any = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof CustomerReviewHubDto>('id');
  // const selected = useAppSelector((state) => state.customers.selectedCustomers);
  const customerDetaillsUpdated = useAppSelector((state) => state.customers.customerDetaillsUpdated);
  const dispatch = useAppDispatch();
  // const [currentCustomerId, setCurrentCustomerId] = React.useState<number | null>(null)

  const [showSnackBar] = AppSnackbar.useSnackBar();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  const [rows, setRows] = useState<any>([]);

  const [count, setCount] = useState<number>(0);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);

  const [isLoading, SetIsLoading] = useState<any>(1);
  const [apiLoader, setApiLoader] = useState<boolean>(false);
  const debouncedSearchCustomer = useRef(
    debounce(() => {
      fetchCustomersForReviewHub();
    }, 1000),
  ).current;

  useEffect(() => {
    searchString = customerSearchText;

    if (isLoading == 1) SetIsLoading(0);
    if (isLoading == 0) debouncedSearchCustomer();
  }, [customerSearchText, debouncedSearchCustomer]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CustomerReviewHubDto): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.checked) {
  //     dispatch(SetSelectedCustomers(rows));
  //     dispatch(SetAllSelectedCustomers(true));
  //   } else {
  //     dispatch(SetSelectedCustomers([]));
  //     dispatch(SetAllSelectedCustomers(false));
  //   }
  // };

  // const handleSelectVisibleClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (event.target.checked) {
  //     dispatch(SetSelectedCustomers(rows));
  //     return;
  //   }
  //   dispatch(SetSelectedCustomers([]));
  // };

  // const handleClick = (event: React.MouseEvent<unknown>, customer: any): void => {
  //   dispatch(SetSelectedCustomers([customer]));
  // };

  // const handleSelectCustomer = (customer: any): void => {
  //   dispatch(fetchSelectedCustomer(customer.id));
  // };

  // const toggleOptIn = (customer: any): void => {
  //   dispatch(saveCustomerToggle(customer));
  // };

  const fetchCustomersForReviewHub = async (): Promise<void> => {
    try {
      setApiLoader(true);
      const customersReviewHubResp = await CustomerReviewHubAPI.getCustomersForReviewHub(
        dealerId,
        currentPage,
        pageSize,
        searchString,
      );
      if (customersReviewHubResp) {
        setIsReviewHubEnabled(customersReviewHubResp.isReviewHubEnabled);
        setRows(customersReviewHubResp.customers);
        setCurrentPage(customersReviewHubResp.pageNumber);
        setCount(customersReviewHubResp.count);
      }
    } catch (error: any) {
      //showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
    setApiLoader(false);
  };

  useEffect(() => {
    fetchCustomersForReviewHub();
  }, [currentPage]);

  useEffect(() => {
    if (customerDetaillsUpdated) {
      fetchCustomersForReviewHub();
    }
  }, [customerDetaillsUpdated]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setCurrentPage(newPage);
  };

  // const selectedCustomerId = useAppSelector((state) => {
  //   if (state.customers.selectedCustomer) {
  //     return state.customers.selectedCustomer.crmCustomerId;
  //   }
  //   return -1;
  // }, shallowEqual);
  // const isSelected = (id: number): boolean => !!selected.find((x: any) => x.id === id);

  const emptyRows = pageSize - rows?.length;

  // useEffect(() => {
  //   if (error) {
  //     dispatch(showSnackbar({ type: 'error', message: 'Failed to fetch customers' }));
  //   }
  // }, [error, dispatch]);

  interface HeadCell {
    disablePadding: boolean;
    id: keyof CustomerReviewHubDto;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    // {
    //   id: 'organizationName',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Organization',
    // },
    {
      id: 'cellPhone',
      numeric: false,
      disablePadding: false,
      label: 'Cell Phone',
    },
    {
      id: 'emailAddress',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'originationSource',
      numeric: false,
      disablePadding: false,
      label: 'Origination Source',
    },
    {
      id: 'customerNumber',
      numeric: false,
      disablePadding: false,
      label: 'Customer Number',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
    },
    {
      id: 'customerStatus',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
  ];

  interface EnhancedTableProps {
    classes: any;
    // numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CustomerReviewHubDto) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    // onSelectVisibleClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps): ReactElement {
    // const { classes, onSelectAllClick, onSelectVisibleClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    //   props;
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler =
      (property: keyof CustomerReviewHubDto) =>
      (event: React.MouseEvent<unknown>): void => {
        onRequestSort(event, property);
      };
    let tableHeads = [...headCells];
    // const AllCustomerSelected = useAppSelector((state) => state.customers.AllCustomersSelected);
    const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
    if (dmsTypeId !== 9) {
      tableHeads = tableHeads.filter((x) => x.id != 'customerNumber');
    }

    return (
      <TableHead style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
        <TableRow>
          {/* <TableCell padding="checkbox">
              <FormControl>
                <InputLabel />
                <Select style={{ padding: '0px !important', margin: '0px' }} variant="outlined">
                  <MenuItem dense>
                    <Checkbox
                      size="small"
                      checked={AllCustomerSelected}
                      style={{ padding: '0px', margin: '0px' }}
                      onChange={onSelectAllClick}
                    />{' '}
                    Select All
                  </MenuItem>
                  <MenuItem dense>
                    <Checkbox
                      style={{ padding: '0px', margin: '0px' }}
                      size="small"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectVisibleClick}
                    />{' '}
                    Select Visible
                  </MenuItem>
                </Select>
              </FormControl>
            </TableCell> */}
          {tableHeads.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              style={{ whiteSpace: 'nowrap', overflow: 'elipses' }}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box>{/* <CustomerTableToolbar validPhoneNumbers={0} numSelected={selected.length} /> */}</Box>
        <TableContainer style={{ maxHeight: '65vh' }}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onSelectVisibleClick={handleSelectVisibleClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            {!apiLoader && (
              <TableBody>
                {rows?.length > 0 ? (
                  stableSort(rows, getComparator(order, orderBy)).map((row: any, index) => {
                    // const isItemSelected = isSelected(row.id);
                    // const isRowSelected = selectedCustomerId === row.id;
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      // <TableRow hover className={classes.tableRow} tabIndex={-1} key={row.id} selected={isRowSelected}>
                      <TableRow hover className={classes.tableRow} tabIndex={-1} key={row.id}>
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              className="customercheckbox"
                              onClick={(event) => handleClick(event, row)}
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId, id: 'customerCheckbox_' + index }}
                            />
                          </TableCell> */}
                        {
                          <TableCell
                            onClick={() => {
                              if (row.id) {
                                dispatch(fetchSelectedCustomer(row.id));
                              }
                            }}
                            component="th"
                            id={labelId}
                            className={classes.nameCell}
                            scope="row">
                            {row.name && row.name.length > 30 ? row.name.slice(0, 30) + '...' : row.name}
                            {row.isOrganization && row.ccOrganizationName && (
                              <Chip
                                label={row.ccOrganizationName}
                                variant="outlined"
                                size="small"
                                style={{ height: '20px', marginLeft: '5px' }}
                              />
                            )}
                          </TableCell>
                        }
                        {/* {dmsTypeId != 1 && (
                            <TableCell align="left">{row.isOrganization ? row.organizationName : ''}</TableCell>
                          )}
                        */}
                        <TableCell align="left" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          {/* {row.cellPhoneVerificationStatus &&
                            row.cellPhoneVerificationStatus === PhoneVerificationStatusEnum.Verified ? (
                              <CheckCircleIcon className={classes.successIcon + ' successIcon'} />
                            ) : (
                              ''
                            )}
                            {row.cellPhoneVerificationStatus &&
                            row.cellPhoneVerificationStatus === PhoneVerificationStatusEnum.Failed ? (
                              <CancelIcon className={classes.errorIcon + ' errorIcon'} />
                            ) : (
                              ''
                            )} */}
                          {row.cellPhone ? formatPhoneNumber(row.cellPhone) : 'N/A'}
                        </TableCell>
                        <TableCell align="left">{row.emailAddress && <EmailLink email={row.emailAddress} />}</TableCell>
                        <TableCell align="left">
                          {row.isFacebookCustomer ? 'Facebook' : row.originationSource}
                        </TableCell>
                        {dmsTypeId === 9 && <TableCell align="left">{row.customerNumber}</TableCell>}
                        <TableCell align="left">
                          {row.createdAt ? format(new Date(row.createdAt), 'M/d/yyyy') : ''}{' '}
                        </TableCell>
                        <TableCell>{row.customerStatus}</TableCell>
                        {/* <TableCell align="left">
                            <Switch
                              checked={row.optIn === 1}
                              onChange={() => toggleOptIn(row)}
                              size="small"
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      align="center"
                      size="medium"
                      style={{ textAlign: 'center', fontSize: '15px', color: 'grey' }}>
                      No Customers Found
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell style={{ textAlign: 'center' }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
            {apiLoader && (
              <TableBody>
                <TableRow style={{ height: 30 * 15, width: '100%' }}>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    <SmallCircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box textAlign="right" m={1}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(count / pageSize)}
            page={currentPage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </div>
  );
}
