import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SmallCircularProgress } from 'common/components/loaders';
import UsageHistory from 'components/setting/models/usage-history';
import moment from 'moment';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  isLoading: boolean;
  usageHistory: UsageHistory[];
  isError: boolean;
  errorMessage: string;
}

const CreditHistoryTable: React.FC<Props> = ({ isLoading, usageHistory, isError, errorMessage }) => {
  const convertToLocal = (utcTime: string): string => {
    const date = moment.utc(utcTime).local().format('MMMM Do YYYY hh:mm A');
    return date;
  };

  const Content = usageHistory.map((row: UsageHistory) => (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        {' '}
        {convertToLocal(row.dateCreated)}{' '}
      </TableCell>
      <TableCell>{row.usageType}</TableCell>
      {row.creditsUsed > 0 && <TableCell style={{ color: 'green' }}>+{row.creditsUsed}</TableCell>}
      {row.creditsUsed === 0 && <TableCell>{row.creditsUsed}</TableCell>}
      {row.creditsUsed < 0 && <TableCell style={{ color: 'red' }}>{row.creditsUsed}</TableCell>}
      <TableCell>{row.creditsRemaning}</TableCell>
    </TableRow>
  ));
  const Loading = (
    <TableRow style={{ height: 10 * 5, width: '100%' }}>
      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
        <SmallCircularProgress />
      </TableCell>
    </TableRow>
  );
  const Error = (
    <TableRow style={{ height: 10 * 5, width: '100%' }}>
      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
        <Typography variant="h5">{errorMessage}</Typography>
      </TableCell>
    </TableRow>
  );
  let body = null;
  if (isLoading === false && isError === false && usageHistory.length > 0) {
    body = Content;
  } else if (isLoading) {
    body = Loading;
  } else if (isError) {
    body = Error;
  }
  return (
    <TableContainer>
      <Table stickyHeader style={{ height: '500px', overflow: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell id="dateHeader">Date</TableCell>
            <TableCell id="usageTypeHeader">Usage Type</TableCell>
            <TableCell id="creditsUsedHeader">Credit Used</TableCell>
            <TableCell id="creditsRemainingHeader">Credit Remaining</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditHistoryTable;
