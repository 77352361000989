import React from 'react';

export const TabPanel: React.FC<{
  children?: React.ReactNode;
  index: any;
  value: any;
}> = ({ children, value, index }) => {
  const isCurrentTabPanel = index === value;
  if (isCurrentTabPanel) {
    return <>{children}</>;
  }
  return null;
};

export default TabPanel;
