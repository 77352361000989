import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StarIcon from '@material-ui/icons/Star';
import React, { ReactElement, useState } from 'react';
import { useAppSelector } from 'app';

import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';

import useLocations from 'common/hooks/useLocations';

const useStyles = makeStyles(() => ({
  smallIconBtn: {
    padding: '8px',
  },
}));

export function SocialLinkWidget({
  disabled,
  onClick,
  disableAllLocations
}: {
  disabled: boolean;
  onClick: (shortenUrl: string) => void;
  disableAllLocations?: boolean;
}): ReactElement {
  const classes: any = useStyles();
  const [socialAnchorEl, setSocialAnchorEl] = React.useState(null);
  const openSocial = Boolean(socialAnchorEl);
  const idSocial = openSocial ? 'social-popover' : undefined;
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const { loading: loadingLocations, locations, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);
  const [selectedLocationId, setSelectedLocationId] = useState<number | undefined>(
    defaultLocationOption?.dealerLocationId,
  );
  const myLocationOptions = disableAllLocations
    ? locationOptions?.filter((x) => x.dealerLocationId != 0)
    : locationOptions;
  React.useEffect(() => {
    if (disableAllLocations) {
      setSelectedLocationId(myLocationOptions?.find((x)=>x.dealerLocationId === dealerId)?.dealerLocationId);
    } else {
      setSelectedLocationId(defaultLocationOption?.dealerLocationId);
    }
  }, [defaultLocationOption]);

  function handleSocialClose(): void {
    setSocialAnchorEl(null);
  }

  function handleSocialClick(event: any): void {
    setSocialAnchorEl(event.currentTarget);
  }

  async function handleShortenUrl(popOverUrl: string | undefined): Promise<void> {
    if (popOverUrl) {
      const shortenedUrl = await BroadCastAPI.shortenUrl(popOverUrl);
      const url = shortenedUrl;
      onClick(url);
      handleSocialClose();
    }
  }

  function handleChange(event: any): void {
    setSelectedLocationId(event.target.value);
  }
  const selectedLocation = locations?.find((location) => location.dealerId === selectedLocationId);

  return (
    <>
      <Tooltip title="Add Review URL">
        <IconButton
          style={{ padding: 0 }}
          id="addReviewUrlBtn"
          size="small"
          onClick={handleSocialClick}
          disabled={disabled}>
          <StarIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={idSocial}
        open={openSocial}
        anchorEl={socialAnchorEl}
        onClose={handleSocialClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container>
          <List
            dense
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Grid container>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <Typography variant="h5">Review URLs</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      labelId="select-location-google-review-label"
                      id="select-location-widget"
                      disabled={loadingLocations}
                      onChange={(e: any): void => handleChange(e)}
                      value={selectedLocationId}>
                      {myLocationOptions?.map((location) => (
                        <MenuItem
                          key={location.dealerLocationId}
                          value={location.dealerLocationId}
                          id={`item_${location.dealerLocationId}`}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </ListSubheader>
            }>
            {selectedLocation ? (
              <>
                <ListItem style={{ display: selectedLocation.googleLink ? 'flex' : 'none' }}>
                  <ListItemText primary="Google Link" />
                  <IconButton
                    id="googleLinkBtn"
                    onClick={(): Promise<void> => handleShortenUrl(selectedLocation.googleLink)}
                    className={classes.smallIconBtn}>
                    <AddCircleIcon />
                  </IconButton>
                </ListItem>
                <ListItem style={{ display: selectedLocation.facebookLink ? 'flex' : 'none' }}>
                  <ListItemText primary="Facebook Link" />
                  <IconButton
                    id="facebookLinkBtn"
                    onClick={(): Promise<void> => handleShortenUrl(selectedLocation.facebookLink)}
                    className={classes.smallIconBtn}>
                    <AddCircleIcon />
                  </IconButton>
                </ListItem>
              </>
            ) : (
              <ListItem>
                <ListItemText>No review URL</ListItemText>
              </ListItem>
            )}
          </List>
        </Grid>
      </Popover>
    </>
  );
}

export default SocialLinkWidget;
