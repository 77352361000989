import { hideTaskDrawer, rescheduleTask, rescheduleTaskList, UpdateSelectedTask } from '../taskboardSlice';
import TaskForm from './TaskForm';
import TaskboardAPI from '../taskboard-api';
import { Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Theme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Autocomplete } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useAppDispatch, useAppSelector } from 'app';
import { useFormik } from 'formik';
import React, { ReactElement, useCallback, useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import CustomerAPI from '../../../../customer/api/CustomerAPI';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TaskListObject, TaskListRescheduleRequest } from 'components/lead/models/task';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import DateTimePicker from '@mui/lab/DateTimePicker';
import useUsersList from 'components/setting/components/department/userUsersList';
import User from 'components/setting/models/user';
import { LoadingButton } from 'common/components/buttons';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { showSnackbar } from 'components/root-snackbar';


function TaskRescheduleDrawer(): ReactElement {
  const dispatch = useAppDispatch();
  const handleTaskFormSubmit = (): void => {
    if (!selectedUser && !dueDate) {
      dispatch(showSnackbar({ type: 'error', message: "Please select one to update" }));
      return;
    }
    let taskRescheduleRequest: TaskListRescheduleRequest = {
      assignedUserId: selectedUser?.crmUserId,
      dueDate: dueDate,
      taskIds: selectedTasks.map(x => x.taskId)
    }
    dispatch(rescheduleTaskList(taskRescheduleRequest));
  };
  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };



  const selectedTasks = useAppSelector((state) => state.tasks.selectedTasks);

  const currentUserId = useAppSelector((state) => state.user.crmUserId);

  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [followTask, setFollowTask] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const dealerId = useAppSelector((state) => state.user.dealerId);
  const users = useUsersList(dealerId);
  const defaultUserValue: User = {
    crmUserId: '',
    dealerId: 0,
    departments: [],
    fullName: '',
    id: '',
    isActive: false,
    isAdmin: false,
    userId: 0,
    userRoles: [],
    username: ''
  }


  const handleUserChange = (val: User) => {
    if (val && val.crmUserId !== currentUserId) {
      setFollowTask(true);
    } else {
      setFollowTask(false);
    }
    console.log(val);
    setSelectedUser(val);
  }

  React.useEffect(() => {
    let selectedCrmUserId = currentUserId;
    const currentUser = users?.filter(x => x.crmUserId === selectedCrmUserId)[0];
    if (currentUser) {
      setSelectedUser(currentUser);
    }
  }, [users]);

  return (
    <>
      <DrawerToolbar title={"Edit " + selectedTasks.length + (selectedTasks.length > 1 ? " Tasks" : " Task")} onClose={handleOnClose} />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <form noValidate autoComplete="off" >
              <Grid container>
                <Grid item xs={6}>
                  <Box ml={1}>
                    <Autocomplete
                      id="usersDropdown"
                      options={users || []}
                      onChange={(event: any, values: any): void => handleUserChange(values)}
                      getOptionLabel={(option: any) => option.username}
                      disableCloseOnSelect
                      placeholder={selectedUser?.username}
                      getOptionSelected={(option: User, value: User): boolean => {
                        return option.crmUserId === value.crmUserId;
                      }}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                          {option.username}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="users"
                          id="users"
                          variant="outlined"
                          margin="dense"
                          label="Assignment"
                          placeholder="Select User"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box ml={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Due Date"
                        value={dueDate}
                        onChange={(e: any) => { console.log(e); setDueDate(e); }}
                        renderInput={(params: any) => <TextField margin="dense" variant="outlined" {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>


              <Box mt={2}>
                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button id="cancelTaskFormBtn" variant="contained" onClick={handleOnClose}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      fullWidth
                      id="saveTaskFormBtn"
                      color="primary"
                      variant="contained"
                      size="medium"
                      onClick={(e: any) => { handleTaskFormSubmit(); }}>
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </form >
          </Grid>
        </Grid>

      </Container>
    </>
  );
}

export default TaskRescheduleDrawer;
