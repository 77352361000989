import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, TextField, Grid, IconButton, FormHelperText, CircularProgress, Divider, InputAdornment } from "@material-ui/core";
import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import { CustomerTagTitle } from 'components/setting/models/customer-tag-title';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomerSettingsApi from 'components/setting/api/customer-settings-api';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';


const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '400px',
        animation: `$slideIn 300ms ${theme.transitions.easing.easeInOut}`
    },
    '@keyframes slideIn': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
}));

function CustomerTagDrawer({ tagProps, open, onClose, fetchTags }: { tagProps?: any, open: boolean, onClose: any, fetchTags: any }) {
    const classes = useStyles();
    const [newTag, setNewTag] = useState('');
    const [addingTag, setAddingTag] = useState(false);
    const [formValuesChanged, setFormValuesChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tagError, setTagError] = useState<boolean>(false);

    const initialValues: CustomerTagTitle = {
        id: tagProps?.id || null,
        dealerId: tagProps?.dealerId || null,
        title: tagProps?.title || '',
        customerTagOptions: tagProps?.customerTagOptions || []
    };

    const formik = useFormik({
        initialValues,
        validate: (values) => {
            const errors: Partial<any> = {};
            if (!values.title) {
                errors.title = 'Title is required';
            }
            let tagErr: boolean = false;
            values.customerTagOptions.forEach((tagOption: any) => {
                if (tagOption.tag.length == 0) {
                    tagErr = true
                }
            })
            setTagError(tagErr)
            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                if (tagProps == null) {
                    await CustomerSettingsApi.insertCustomerTagSetting(values);
                } else {
                    await CustomerSettingsApi.updateCustomerTags(values);
                }
                onClose(false);
                fetchTags();
            } catch (error) {
                console.error('Error saving customer tags:', error);
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        const initialFormValues = JSON.stringify(initialValues);
        const currentFormValues = JSON.stringify(formik.values);
        setFormValuesChanged(initialFormValues !== currentFormValues);
    }, [initialValues, formik.values]);

    const handleAddTagOption = () => {
        setAddingTag(true);
    };

    const handleNewTagChange = (e: any) => {
        setNewTag(e.target.value);
    };

    const handleNewTagSubmit = () => {
        if (newTag.trim()) {
            formik.setFieldValue('customerTagOptions', [...formik.values.customerTagOptions, { tag: newTag }]);
            setNewTag('');
            setAddingTag(false);
        }
    };

    const handleDeleteTagOption = (index: number) => {
        const newTagOptions = formik.values.customerTagOptions.filter((_: any, i: number) => i !== index);
        formik.setFieldValue('customerTagOptions', newTagOptions);
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => { onClose(false) }}
            PaperProps={{ className: classes.drawerPaper }}
        >
                <DrawerToolbar
                    title="Add Customer Tags"
                    onClose={() => { onClose(false) }}
                />
                <Box style={{ padding: '16px' }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={formik.values.title}
                                name={'title'}
                                required
                                autoFocus
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Customer Tags Title"
                                variant='outlined'
                                fullWidth
                                label="Title"
                                error={formik.touched.title && Boolean(formik.errors.title)}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <FormHelperText error>{formik.errors.title}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {formik.values.customerTagOptions.map((item: any, index: any) => (
                                <Grid container spacing={1} alignItems="center" key={index}>
                                    <Grid item xs={10}>
                                        <TextField
                                            name={`customerTagOptions[${index}].tag`}
                                            value={formik.values.customerTagOptions[index].tag}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder={`Tag ${index + 1}`}
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            label={`Tag ${index + 1}`}
                                            error={formik.values.customerTagOptions[index].tag.length == 0}
                                            helperText= {formik.values.customerTagOptions[index].tag.length == 0 && "Tag required"}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => handleDeleteTagOption(index)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        {addingTag && (
                            <Grid item xs={12}>
                            <TextField
                                value={newTag}
                                onChange={handleNewTagChange}
                                placeholder="New Tag Option"
                                variant='outlined'
                                fullWidth
                                size='small'
                                autoFocus
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleNewTagSubmit} aria-label="add">
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button variant="contained" color='primary' fullWidth onClick={handleAddTagOption} disabled={addingTag}>
                                Add New Tag
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color='primary' fullWidth type="submit" disabled={!formValuesChanged || loading || formik.errors.title !== undefined || tagError}>
                                {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Drawer>
    );
}

export default CustomerTagDrawer;
