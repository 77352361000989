import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Status from 'common/models/status';
import AuthenticationAPI from 'components/authentication/api/authentication-api';
import appLocalStorage from 'app/local-storage';
import { useAppDispatch, useAppSelector } from 'app';
interface UserPermission {
  permissionId: string;
  productFeatureId: string;
  featureName: string;
  featureDescription: string;
  permission: boolean;
}

interface PersistedUserState {
  loggedIn: boolean;
  session: string;
  status: Status;
  crmUserId: string;
  dealerId: number;
  dmsDealerId: number;
  dealerName: string;
  userFullName: string;
  authToken: string;
  refreshToken: string;
  messageCount: number;
  phoneValidationCount: number;
  Id: string;
  dmsTypeId: number;
  dmsType: string;
  isHosted: boolean;
  roleId: string;
  roleName: string;
  isUserAdmin: boolean;
  userPermissions: Array<UserPermission>;
  isTextToPayAllow: boolean;
}

const initialState: PersistedUserState = {
  loggedIn: false,
  status: Status.idle,
  session: '',
  dealerId: 0,
  dmsDealerId: 0,
  dealerName: '',
  userFullName: '',
  authToken: '',
  refreshToken: '',
  crmUserId: "0",
  messageCount: 0,
  dmsType: "",
  dmsTypeId: 0,
  phoneValidationCount: 0,
  Id: '',
  isHosted: false,
  roleId: '',
  roleName: '',
  isUserAdmin: false,
  userPermissions: new Array<UserPermission>(),
  isTextToPayAllow: false
};

const loginUser = createAsyncThunk(
  'user/login',
  async ({ dealerId, username, password, token }: { dealerId: string; username: string; password: string, token: string }) => {
    if (token != null && token != '') {
      try {
        //alert('Token');
        let response = await AuthenticationAPI.authenticateUser(dealerId, username, password, token);
        return await response;
      }
      catch (error) {
        //alert('Invalid Token');
        appLocalStorage.remove();
        // dispatch(logoutUser());
    
        let href = window.location.href.replaceAll(window.location.search, '');
        if (!href.endsWith('/')) href += '/';
        href += 'login';
        localStorage.clear();
        window.location.href = href;
        return await "";
      }

    }
    let response = await AuthenticationAPI.authenticateUser(dealerId, username, password, token);
    // if(token!=null && token!='')   alert(response);
    //return await AuthenticationAPI.authenticateUser(dealerId, username, password, token);
    return await response;
  },
);

const refreshTokenUser = createAsyncThunk('user/refreshToken', async ({ refreshToken }: { refreshToken: string }) => {
  if (refreshToken != null && refreshToken != '') {
    let response = await AuthenticationAPI.refreshTokenUser(refreshToken);
    return response;
  }
});

const persistedUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser() {
      return initialState;
    },
    updateUserCredits(state, action: PayloadAction<number>) {
      state.messageCount = action.payload;
    },
    updateStatus(state, action: PayloadAction<Status>) {
      state.status = action.payload;
    },
    setIsAllowTextToPay(state,action:PayloadAction<boolean>){
      state.isTextToPayAllow= action.payload;
    }
  },
  extraReducers: {
    [loginUser.pending.type]: (state) => {
      state.status = Status.loading;
    },
    [loginUser.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.status = Status.succeeded;
        state.loggedIn = true;
        state.dealerId = action.payload.dealerId;
        state.dmsDealerId = action.payload.dmsDealerId;
        state.crmUserId = action.payload.crmUserId;
        state.dealerName = action.payload.dealerName;
        state.userFullName = action.payload.userFullName;
        state.authToken = action.payload.authToken;
        state.refreshToken = action.payload.refreshToken;
        state.messageCount = action.payload.messageCount;
        state.phoneValidationCount = action.payload.phoneValidationCount;
        state.Id = action.payload.userId;
        state.isHosted = action.payload.isHosted;
        state.roleId = action.payload.roleId;
        state.roleName = action.payload.roleName;
        state.isUserAdmin = action.payload.isUserAdmin;
        state.dmsType = action.payload.dmsType;
        state.dmsTypeId = action.payload.dmsTypeId;
        state.userPermissions = action.payload.userPermissions;
        state.isTextToPayAllow = action.payload.isTextToPayAllow;
      } else {
        state.status = Status.failed;
      }
    },
    [loginUser.rejected.type]: (state) => {
      state.status = Status.failed;
    },
    [refreshTokenUser.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.authToken = action.payload.authToken;
        state.refreshToken = action.payload.refreshToken;
      } else {
        document.title = 'Target CRM';
        localStorage.removeItem('PROJECT_X_STATE');
        return initialState;
      }
    },
    [refreshTokenUser.rejected.type]: (state) => {
      document.title = 'Target CRM';
      localStorage.removeItem('PROJECT_X_STATE');
      return initialState;
    },
  },
});

export const { logoutUser, updateUserCredits, updateStatus, setIsAllowTextToPay } = persistedUserSlice.actions;
export { loginUser, refreshTokenUser };
export default persistedUserSlice.reducer;
