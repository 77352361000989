import React from 'react';

import DepartmentAPI from 'components/setting/api/department-api';
import Department from 'components/setting/models/department';

export default function useDepartments(dmsDealerIdCrm: number, dealerLocationId: number): Department[] | undefined {
  const [departments, setDepartments] = React.useState<Department[] | undefined>();

  const fetchDepartments = React.useCallback(async (): Promise<void> => {
    console.log("called");
    try {
      // Location is not selected
      if (dealerLocationId !== undefined) {
        const response = await DepartmentAPI.getDepartments(dealerLocationId, dmsDealerIdCrm);
        setDepartments(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dealerLocationId, dmsDealerIdCrm]);

  React.useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  return departments;
}
