import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

import Fire from 'common/components/icons/fire';

export const useStyles = makeStyles({
  root: {
    paddingRight: '21px',
    textTransform: 'capitalize',
  },
  startIcon: {
    marginRight: 0,
  },
});

export default function BeamerButton(): React.ReactElement {
  const classes: any = useStyles();
  return (
    <Button
      size="small"
      className={classes.root}
      classes={{ startIcon: classes.startIcon }}
      id="target-crm-beamer-button"
      startIcon={<Fire />}>
      What&apos;s new
    </Button>
  );
}
