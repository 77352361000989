import { get, put } from 'api';
import config from 'config';

import { MessageSignature } from '../models/message-signature';

const dealerUrl = config.get('api.dealer');

const MessageSignatureAPI = {
  async getMessageSignatures(dmsDealerIdCrm: number): Promise<MessageSignature[]> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/message-signatures`;
    const { data } = await get(url);
    return data;
  },
  async updateMessageSignatures(dmsDealerIdCrm: number, messageSignatures: MessageSignature[]): Promise<void> {
    const url = `${dealerUrl}/dealers/${dmsDealerIdCrm}/message-signatures`;
    const { data } = await put(url, messageSignatures);
    return data;
  },
};

export default MessageSignatureAPI;
