import { useAppSelector } from 'app';
import UserAPI from 'components/setting/api/user-api';
import User from 'components/setting/models/user';
import React from 'react';

function useUsersList(dealerId: number, setUsersLoading?: any): User[] | undefined {
  const [users, setUsers] = React.useState<User[] | undefined>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  React.useEffect(() => {
    async function fetchUsers(): Promise<void> {
      setUsersLoading && setUsersLoading(true)
      const response = await UserAPI.getUsers(dmsDealerIdCrm, dealerId);
      setUsers(response);
      setUsersLoading && setUsersLoading(false)
    }
    fetchUsers();
  }, [dealerId, dmsDealerIdCrm]);
  return users;
}

export default useUsersList;
