import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme): any => ({
  card: {
    height: '198px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
  },
  innerContent: {
    textAlign: 'center',
    margin: 'auto',
  },
  addButton: {
    color: theme.palette.common.white,
  },
}));

export default function NewSurveyCard({ onClick }: { onClick(): void }): ReactElement {
  const classes: any = useStyles();

  return (
    <Card id="newSurveyCard" className={classes.card} onClick={onClick}>
      <CardContent className={classes.content}>
        <Box className={classes.innerContent}>
          <IconButton id="newSurveyBtn" size="medium" className={classes.addButton}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
          <Typography variant="h4" component="h4">
            New Survey
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
