import { AppBar, Box, Container, Grid, Switch, TextField, Toolbar } from '@material-ui/core';
import { PageHeader } from 'common/components/layout';
import useStyles from '../styles/customers-hub-page';
import clsx from 'clsx';
import { useState } from 'react';
import CustomerReviewHubTable from './customer-review-hub-table';
import SearchIcon from '@material-ui/icons/Search';
import CustomerReviewHubAPI from '../api/customers-review-hub-api';
import AppSnackbar from 'components/snackbar/snackbar';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomersHubFloatingBar = ({
  isReviewHubEnabled,
  setIsReviewHubEnabled,
}: {
  isReviewHubEnabled: boolean;
  setIsReviewHubEnabled: any;
}) => {
  const classes: any = useStyles();
  const [showSnackBar] = AppSnackbar.useSnackBar();

  async function handleIsReviwHubChange(enabled: boolean) {
    try {
      setIsReviewHubEnabled(enabled);
      await CustomerReviewHubAPI.toggleDealerIsReviwHubSetting(enabled);
      showSnackBar({
        type: 'success',
        message: `Review Hub ${enabled ? 'enabled' : 'disabled'} successfully`,
      });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.reviewHubBar}>
          <Switch
            checked={isReviewHubEnabled}
            onChange={({ target: { checked } }) => handleIsReviwHubChange(checked)}
          />
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            className={classes.barInfoIcon}
            titleAccess="Toggle on to review new customers for accuracy and prevent duplicates."
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const CustomersReviewHub = ({ setIsReviewHubEnabled }: { setIsReviewHubEnabled: any }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [customerSearchText, setCustomerSearchText] = useState<string>('');

  const classes: any = useStyles();
  const containerClassName = clsx(classes.container, drawerOpen && classes.containerShift);

  return (
    <Container className={containerClassName} maxWidth="lg">
      <PageHeader title="Review Hub" />
      <Grid item xs={12}>
        <TextField
          fullWidth
          style={{ backgroundColor: 'white' }}
          margin="dense"
          variant="outlined"
          name="searchText"
          id="search-text"
          placeholder="Search customers"
          value={customerSearchText}
          InputProps={{
            startAdornment: <SearchIcon color="disabled" style={{ paddingRight: '5px' }} />,
          }}
          onChange={({ target: { value } }): void => {
            setCustomerSearchText(value);
          }}
        />
      </Grid>
      <Box mt={2}>
        <CustomerReviewHubTable customerSearchText={customerSearchText} setIsReviewHubEnabled={setIsReviewHubEnabled} />
      </Box>
    </Container>
  );
};

export default function CustomersReviewHubPage() {
  const [isReviewHubEnabled, setIsReviewHubEnabled] = useState<boolean>(false);

  return (
    <>
      <CustomersHubFloatingBar isReviewHubEnabled={isReviewHubEnabled} setIsReviewHubEnabled={setIsReviewHubEnabled} />
      <CustomersReviewHub setIsReviewHubEnabled={setIsReviewHubEnabled} />
      {<CustomerRootPageDrawer />}
    </>
  );
}
