import { store } from 'app';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconSearchRounded from '@material-ui/icons/SearchRounded';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import axios from 'axios';
import React from 'react';
import { Box, Button, Container, Drawer, Grid, MenuItem, TextField, Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { size } from 'lodash';
import CustomerGroupAPI from 'components/customer-groups/api/customer-group-api';
import ConversationListPanel from 'components/messaging/components/conversation-list-panel';
import EmailLink from 'common/components/buttons/email-link';


interface Props {
  children: never[];
}

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles({
  root: {
    width: '100%',
    martin: '20px',
    backgroundColor: 'red',

  },
  defaultFont: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "small"
  },
  tableType: {
    color: 'black',
    backgroundColor: '#EAEDED',
    padding: "4px 10px",
    fontFamily: "Quicksand, sans-serif",

    // fontWeight: 'bold',
  },
  table01: {
    border: "1px solid silver",
    // marginBottom: "10px",
    borderCollapse: "collapse",
    margin: "10px",
    marginLeft: "0px",
    fontSize: "0.9em",
    fontFamily: "sans-serif",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",

    // border-collapse: collapse;
    // margin: 25px 0;
    // font-size: 0.9em;
    // font-family: sans-serif;
    // min-width: 400px;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  },
  table02: {
    // cursor: "url(hand.cur), pointer"
  },

  buttonValidatePhone: {
    margin: "2px",
    padding: "2px 5px",
    fontSize: '8px',
    backgroundColor: '#EAFAF1',
    color: 'gray',
    border: '1px solid silver',

  },
  selectedRow: {
    backgroundColor: '#F9EBEA',
  },
  myTable: {
    backgroundColor: '#F7FAF8',
    border: "0px solid red",
    '& thead tr th': {
      // backgroundColor: 'green',

      // fontWeight: "bold",
      // fontSize: "15px",
      padding: "2px 5px",
      // background:'linear-gradient(rgba(250,0,0,0.5),transparent)'
    },
    '& tbody tr:hover': {
      backgroundColor: '#DDEEF8',
    },

    '& tbody tr td': {
      // backgroundColor: 'green',
      padding: "3px 6px",
      borderBottom: "1px solid #DDE2DF",
      cursor: "url(hand.cur), pointer",
    },

  },



});

export default function CustomerInvoiceDrawer(
  { children, open, onClose, invoiceId, dealerId = 0, invoiceRow, crmCustomerId = 0, }:
    { children: never[]; open: boolean; onClose(): void; invoiceId: number; dealerId?: number; invoiceRow: any; crmCustomerId?: number }): React.ReactElement {
  const [invoiceData, SetInvoiceData] = React.useState<any>();
  const [customerData, SetCustomerData] = React.useState<any>();
  const [dealerData, SetDealerData] = React.useState<any>();
  const [invoiceListData, SetInvoiceListData] = React.useState<any>();
  const [customer, SetCustomer] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showAllInvoices, SetShowAllInvoices] = React.useState<boolean>(false);
  const [test, setTest] = React.useState<string>('test');
  const classes: any = useStyles();
  const [currentInvoiceId, SetCurrentInvoiceId] = React.useState<number>(invoiceId);
  const [searchInvoice, SetSearchInvoice] = React.useState<string>('');
  const [openNoInvoiceAlert, SetOpenNoInvoiceAlert] = React.useState(false);
  const [dealerLocations, SetDealerLocations] = React.useState<any>([]);

  React.useEffect(() => {
    if (crmCustomerId > 0) {
      if (open == false) return;
      //alert('useEffect-k3');
      DisplayAllInvoices();
      return;
    }
    if (invoiceId > 0) GetCustomerInvoice(dealerId, invoiceId);
  }, [invoiceId, open]);

  React.useEffect(() => {
    GetDealerLocations();
  }, []);

  function CloseDrawer() {
    SetShowAllInvoices(false);
    onClose();
  }

  function GetInvoiceNumber(row:any){
    let InvoiceId=row.InvoiceId;
    //if(store.getState().user.dmsTypeId!=1) return row.InvoiceNumber;
    if(store.getState().user.dmsTypeId!=1) InvoiceId = row.InvoiceNumber;
    if(row.OrderId>0){
      InvoiceId=row.InvoiceNumber;
     if(InvoiceId==0)InvoiceId=row.OrderId + '***';
    }
    //return row.InvoiceId;
    return InvoiceId;
  }

  async function GetDealerLocations() {
    //alert('GetDealerLocations');
    const data: any = await CustomerGroupAPI.GetDealerLocations();
    if (typeof (data) == 'undefined') { return ''; }
    // alert(data.length);
    SetDealerLocations(data);
    console.log(data);
  }

  function GetDealerNickName(row: any) {
    let item = dealerLocations.filter(function (item: any) { return item.DealerId === row.DealerId; });
    if (item != null && item.length > 0) {
      console.log("GetDealerNickName");
      console.log(item);
      console.log(item[0]);
      return item[0].DealerLocationNickName;
    }
    return row.DealerName;
  }



  async function GetCustomerInvoice(dealerId: number, InvoiceId: number) {
    const data: any = await CustomerGroupAPI.GetCustomerInvoice(dealerId, InvoiceId);
    SetInvoiceData(data);
    SetCustomerData(data.Customer[0]);
    SetDealerData(data.Dealer[0]);
    setIsLoading(false);
  }

  function FormatNumber(value: any) {
    return value.toFixed(2).toLocaleString();
  }

  function GetOrderHistoryData() {
    return invoiceListData.filter(filterInvoiceListData);
  }


  function filterInvoiceListData(element: any, index: any, array: any) {
    if (invoiceListData.length > 0) {
      let obj1 = invoiceListData[0];
      let keys = Object.keys(obj1);
      let i = 0;
      for (i = 0; i < keys.length; i++) {
        let cellValue = String(element[keys[i]]);
        if (cellValue.toLowerCase().includes(searchInvoice.toLowerCase())) { return true; }
      }
    }
    return false;

  }

  function GetPhoneNumber() {
    let cellNumber = '';
    let landLineNumber = '';
    if (typeof (invoiceData.CustomerPhone) == 'undefined') { return '' }
    for (let i = 0; i < invoiceData.CustomerPhone.length; i++) {
      if (invoiceData.CustomerPhone[i].PhoneNumber != '' && invoiceData.CustomerPhone[i].PhoneType == 1) { cellNumber = invoiceData.CustomerPhone[i].PhoneNumber }
      if (invoiceData.CustomerPhone[i].PhoneNumber != '' && invoiceData.CustomerPhone[i].PhoneType == 0) { landLineNumber = invoiceData.CustomerPhone[i].PhoneNumber }
    }
    if (cellNumber != '') { return 'Cell: ' + FormatPhoneNumber(cellNumber) }
    if (landLineNumber != '') { return 'Landline: ' + FormatPhoneNumber(landLineNumber) }
  }

  function GetEmail() {
    let cellNumber = '';
    let landLineNumber = '';
    if (typeof (invoiceData.CustomerEmail) == 'undefined') { return '' }
    for (let i = 0; i < invoiceData.CustomerEmail.length; i++) {
      if (invoiceData.CustomerEmail[i].Address != '') {
        return invoiceData.CustomerEmail[i].Address;
      }
    }
  }
  async function DisplayAllInvoices() {
    SetShowAllInvoices(showAllInvoices ? false : true);
    if (!showAllInvoices) {
      if (crmCustomerId > 0) {
        const data = await CustomerGroupAPI.GetCustomerInvoicesList(dealerId, crmCustomerId);
        //alert(data);
        SetInvoiceListData(data);
        //console.log(data[0].InvoiceId);
        //if (typeof (invoiceData.CustomerPhone) == 'undefined') { return '' }
        if (data == undefined || data.length == 0) {
          SetOpenNoInvoiceAlert(true);
          CloseDrawer();
          return;

        }
        //if(currentInvoiceId==0 && data !==undefined && data.length>0) {
        if (currentInvoiceId == 0 && data !== undefined && data.length > 0) {
          SetCurrentInvoiceId(data[0].InvoiceId);
          //if (invoiceId > 0) GetCustomerInvoice(dealerId, data[0].InvoiceId);
          GetCustomerInvoice(data[0].DealerId, data[0].InvoiceId);

        }
        // else{
        //   SetOpenNoInvoiceAlert(true)
        // }


      }
      else {
        const data = await CustomerGroupAPI.GetCustomerInvoicesList(dealerId, customerData.CrmCustomerId);
        SetInvoiceListData(data);

      }

      //const data = await CustomerGroupAPI.GetCustomerInvoicesList(dealerId, customerData.CrmCustomerId);

    }
  }


  function FormatPhoneNumber(phone: String) {
    let res = phone;
    if (res.length == 10) {
      res = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6, 10);
    }
    return res;
  }

  function onRowClick(row: any) {
    SetCurrentInvoiceId(row.InvoiceId);
    GetCustomerInvoice(row.DealerId, row.InvoiceId);
  }

  function GetCustomerAddress() {
    //alert(invoiceData.Customer.length);
    let data_filter: any = invoiceData.Customer.filter((x: any) => x.AddressType === 2);
    if (data_filter.length == 1) return data_filter;
    if (invoiceData.Customer.length > 0) {
      //alert('k1');
      let dataAddress: any = [];
      dataAddress.push(invoiceData.Customer[0]);
      return dataAddress;
    }
    return [];
  }

  function GetInvoiceType(){
    let invoiceType= invoiceData.HasWorkOrder ? dealerData.DmsType == 1 ? 'Repair Order' : 'Work Order' : 'Sales Order';
    if(dealerData.DmsType == 9 && invoiceData.part===undefined && invoiceData.wg===undefined && invoiceData.misc===undefined && invoiceData.labor===undefined) invoiceType='Payment';
    return invoiceType;
  }


  return (
    <Paper>
      {!isLoading && (
        <Drawer PaperProps={{ style: { width: "auto", minWidth: "500px" } }} variant="temporary" anchor="right" open={open} onClose={CloseDrawer}>
          <DrawerToolbar title="Invoice Details" onClose={onClose} />
          {/* <div style={{ position: "fixed", right: "5px", display: showAllInvoices ? "none" : "table" }}></div> */}
          <div style={{ position: "absolute", right: "90px", top: "10px" }}>
            <Button color="primary" variant="contained" href="" size="small" onClick={() => DisplayAllInvoices()}
            // classes={{
            //   root: classes.buttonValidatePhone,
            // }}
            >
              {showAllInvoices ? "Hide Invoices " : "Order History"}
            </Button>

          </div>


          <table>
            <tr>
              <td valign="top">
                {dealerData != null && (


                  <div style={{ margin: '10px' }}  >
                    <div className={classes.defaultFont} style={{ marginLeft: "5px" }}>{dealerData.DealerLocationNickName} ({dealerData.ZipPostal})</div>
                    <div style={{ display: "table" }}>
                      <Table >
                        <tr><td>
                          {GetCustomerAddress().map((row: any) => (
                            // <div>
                            //   {row.Name}
                            // </div>
                            <div style={{ padding: "5px", fontSize: "small", border: "1px solid silver", display: "block", minWidth: "200px" }}>
                              <div style={{ fontWeight: "bold" }}> {row.Name} ({row.CustomerId})
                              </div>
                              {row.Address}<br></br>
                              {row.City} {row.StateProvince} {row.zipPostal}
                              <div>{GetPhoneNumber()}</div>
                              <div>Email: <EmailLink email={GetEmail()}/></div>

                              {/* {Phone: {FormatPhoneNumber(invoiceRow.phoneNumber)} */}
                              {/* {invoiceRow.email != null && invoiceRow.email != '' && (
                            <div>
                              Email: {invoiceRow.email} <br></br>
                            </div>
                          )}  */}
                            </div>

                          ))}


                        </td>
                          <td valign="top">
                            <div style={{ marginLeft: "10px", padding: "5px", fontSize: "small", border: "1px solid silver", display: "block" }}>
                              {/* {invoiceData.HasWorkOrder ? dealerData.DmsType == 1 ? 'Repair Order' : 'Work Order' : 'Sales Order'}# {`${invoiceData.InvoiceId}`}<br></br> */}
                              {/* {GetInvoiceType()}# {`${invoiceData.InvoiceId}`}<br></br> */}
                              {GetInvoiceType()}# {`${GetInvoiceNumber(invoiceData)}`}<br></br>
                              Date {new Date(invoiceData.InvoiceDate).toLocaleDateString()}<br></br>
                              {/* Tax: ${invoiceData.InvoiceTaxes}<br></br> */}
                            </div>
                          </td>
                          {/* <div style={{ marginLeft: "10px", padding: "5px", fontSize: "x-small", border: "1px solid silver", display: "inline-block" }}>
                          {`${dealerData.Name}`}<br></br>
                          {`${dealerData.Address}`}<br></br>
                          {`${dealerData.City}`} {`${dealerData.StateOrProvince}`} {`${dealerData.ZipPostal}`}
                        </div> */}

                          {/* <td>

                      </td> */}
                        </tr>
                      </Table>
                    </div>

                    {typeof (invoiceData.part) !== 'undefined' && invoiceData.part != null && (
                      <table cellPadding="0" cellSpacing="0" className={classes.table01} >
                        <tr><td className={classes.tableType} >Parts</td></tr>
                        <tr>
                          <td>
                            <TableContainer>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Line</TableCell>
                                    <TableCell>Part#</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invoiceData.part.map((row: any) => (
                                    <TableRow >
                                      <TableCell> {row.name} ({row.code})</TableCell>
                                      <TableCell>{row.partNumber}</TableCell>
                                      <TableCell>{row.description}</TableCell>
                                      <TableCell align="right">${FormatNumber(row.price)}</TableCell>
                                      <TableCell align="right">{row.quantitySold}</TableCell>
                                    </TableRow>)
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </td>
                        </tr>
                      </table>
                    )}


                    {typeof (invoiceData.misc) !== 'undefined' && invoiceData.misc != null && (
                      <table cellPadding="0" cellSpacing="0" className={classes.table01}>
                        <tr><td className={classes.tableType} >{dealerData.DmsType == 1 ? 'Miscellaneous' : 'Non-Inventory'} </td></tr>
                        <tr>
                          <td>
                            <TableContainer>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    {/* <TableCell align="right">Retail Price</TableCell> */}

                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invoiceData.misc.map((row: any) => (
                                    <TableRow >
                                      <TableCell>{row.Description}</TableCell>
                                      <TableCell align="right">${FormatNumber(row.Price)}</TableCell>
                                      {/* <TableCell align="right">{row.RetailPrice}</TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </td>
                        </tr>
                      </table>
                    )}

                    {typeof (invoiceData.wg) !== 'undefined' && invoiceData.wg != null && (
                      <table cellPadding="0" cellSpacing="0" className={classes.table01}>
                        <tr><td className={classes.tableType} >Wholegoods</td></tr>
                        <tr>
                          <td>
                            <TableContainer>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Line</TableCell>
                                    <TableCell>Model</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Serial#</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invoiceData.wg.map((row: any) => (
                                    <TableRow >
                                      <TableCell> {row.name} ({row.code})</TableCell>
                                      <TableCell>{row.Model}</TableCell>
                                      <TableCell>{row.description}</TableCell>
                                      <TableCell>{row.serialNumber}</TableCell>
                                      <TableCell align="right">${FormatNumber(row.price)}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </td>
                        </tr>
                      </table>

                    )}

                    {typeof (invoiceData.labor) !== 'undefined' && invoiceData.labor != null && (
                      <table cellPadding="0" cellSpacing="0" className={classes.table01}>
                        <tr><td className={classes.tableType} >Labor</td></tr>
                        <tr>
                          <td>
                            <TableContainer>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell align="right">Rate</TableCell>
                                    {/* <TableCell align="right">RetailPrice</TableCell> */}
                                    <TableCell align="right">Total</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invoiceData.labor.map((row: any) => {
                                    //const isItemSelected = isSelected(row.dmsDealerIdCrm);
                                    //let laborTotal=row.total.toFixed(2).toLocaleString(2)
                                    return (
                                      <TableRow >
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.billedHours}</TableCell>
                                        <TableCell align="right">${row.rate}</TableCell>
                                        {/* <TableCell align="right">{row.retailTotal}</TableCell> */}
                                        <TableCell align="right">${FormatNumber(row.total)}</TableCell>
                                      </TableRow>
                                    );
                                  })}


                                  {/* {invoiceData.labor.map((row: any) => (
                                <TableRow >
                                  <TableCell>{row.description}</TableCell>
                                  <TableCell>{row.billedHours}</TableCell>
                                  <TableCell align="right">${row.rate}</TableCell>
                                  <TableCell align="right">${row.total}</TableCell>
                                </TableRow>
                              ))} */}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </td>
                        </tr>
                      </table>

                    )}
                  </div>
                )}
              </td>
              <td valign="top">
                <div style={{ display: showAllInvoices ? "table" : "none", marginTop: "0px", border: "solid 0px red", float: "right", marginRight: "10px" }}>
                  {typeof (invoiceListData) !== 'undefined' && invoiceListData != null && (
                    <table cellPadding="0" cellSpacing="0" className={classes.table01 + ' ' + classes.table02}>
                      <tr><td className={classes.tableType} ><div style={{ padding: "0px 0px" }}>
                        Customer Invoices ({invoiceListData.length})
                        {/* <table><tr><td>Customer Invoices ({invoiceListData.length}) </td><td><a href=''><IconSearchRounded /></a> </td></tr></table> */}

                      </div></td></tr>
                      <tr><td className={classes.tableType}>


                        <input placeholder='search' onChange={(e) => SetSearchInvoice(e.target.value)}></input>
                      </td></tr>
                      <tr>
                        <td>
                          <TableContainer style={{ display: "block", maxHeight: "80vh", overflow: "auto", border:"0px solid red", paddingLeft:"0px", marginRight:"10px" }}>
                            <Table stickyHeader size="small" className={classes.myTable} >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Invoice#</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell align="center">Date</TableCell>
                                  <TableCell>Type</TableCell>
                                  {dealerLocations.length>1 && (<TableCell>Location</TableCell>)}

                                </TableRow>
                              </TableHead>
                              <TableBody style={{ maxHeight: "90vh", overflow: "auto" }} >
                                {GetOrderHistoryData().map((row: any) => {
                                  let dealernickName = GetDealerNickName(row);
                                  let InvoiceId=row.InvoiceId;
                                  if(store.getState().user.dmsTypeId!=1)InvoiceId=row.InvoiceNumber;
                                  if(row.OrderId>0){
                                    InvoiceId=row.InvoiceNumber;
                                    if(InvoiceId==0)InvoiceId=row.OrderId + '';
                                  }
  
  
                                  return (
                                    <TableRow className={row.InvoiceId == currentInvoiceId ? classes.selectedRow : ''} onClick={() => onRowClick(row)}  >
                                      {/* <TableCell align="center">{row.InvoiceId}</TableCell> */}
                                      <TableCell align="center">{InvoiceId}</TableCell>
                                      <TableCell> {row.InvoiceStatus}</TableCell>
                                      <TableCell align="center">{new Date(row.InvoiceDate).toLocaleDateString()}</TableCell>
                                      <TableCell>{row.InvoiceType}</TableCell>
                                      {dealerLocations.length>1 && (<TableCell>{dealernickName}</TableCell>)}
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </td>
                      </tr>
                    </table>
                  )}
                </div>
              </td>
            </tr>
          </table>

        </Drawer>

      )}
      <Dialog
        open={openNoInvoiceAlert}
        //TransitionComponent={Transition}
        keepMounted
        onClose={(event) => { SetOpenNoInvoiceAlert(false) }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Order History"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No order history found for this customer.
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
      </Dialog>
    </Paper>
  );

}
