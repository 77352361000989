import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import * as Yup from "yup";
import { useFormik } from 'formik';


export default function ArchiveDialog({ openArchiveDialog, onClose, onSubmit }: 
{ 
    openArchiveDialog: boolean,
    onClose(): void,
    onSubmit(title:string):void

}) {

    //#region Formik Validations

  const validateSchema = Yup.object<any>().shape<any>({
    archiveTitle: Yup.string()
    .trim()
    .required("Please provide a title to archive this conversation.")
    .max(30,"Title can only be upto 30 characters.")
  });

  const formik = useFormik({
    initialValues: {
    archiveTitle: '',
    },
    onSubmit: values => {
       onSubmit(values.archiveTitle);
      },
    validationSchema: validateSchema,
  });

 //#endregion Formik Validations
  
  return (
    <React.Fragment>
      
            <Dialog
                open={openArchiveDialog}
                onClose={onClose}
                disableEscapeKeyDown={true}
                disablePortal={true}
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Archive</DialogTitle>
                
                    <DialogContent>
                        <DialogContentText>
                        Please add a title and confirm you want to archive this conversation?
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="archiveTitle"
                            name="archiveTitle"
                            label="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={formik.handleChange}
                            value={formik.values.archiveTitle}
                            error={!!formik.errors.archiveTitle}
                            helperText={formik.errors.archiveTitle ? formik.errors.archiveTitle : ""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant="contained" color="error" >Cancel</Button>
                        <Button type="submit" variant="contained" color="success" >Yes</Button>
                    </DialogActions>
                </form>
            </Dialog>
     
    </React.Fragment>
  );
}