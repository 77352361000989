import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function formatPhoneNumber(phoneNumberString: string): string | null {
  if (typeof phoneNumberString === 'string') {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString, 'US');
    return phoneNumber ? phoneNumber.formatNational() : null;
  }
  return null;
}

export function FormatPhoneNumberCustom(num: string){
  if(num.length <0 ) return;
  const formattedNumber = num.length > 0 ? `(${num.slice(0, 3)}${num.length > 3 ? ') '+num.slice(3, 6):''}${num.length > 6 ? '-'+num.slice(6):''}`:'';
  return formattedNumber;
}

export default formatPhoneNumber;
