import { Drawer } from '@material-ui/core';
import React, { ReactElement, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { hideCustomerDrawer, UpdateSelectedCustomer } from '../Customers/customersSlice';
import CustomerValidatePhoneDrawer from '../CustomerValidation/CustomerValidatePhoneDrawer';
import AddCustomerDrawer, { CustomerAddDrawerProps } from './CustomerAddDrawer';
import CustomerEditDrawer from './CustomerEditDrawer';
import Swal from 'sweetalert2';

const DRAWER_COMPONENTS: { [id: string]: React.FC | React.ForwardRefExoticComponent<React.RefAttributes<any>> } = {
  CUSTOMER_ADD: AddCustomerDrawer,
  CUSTOMER_EDIT: CustomerEditDrawer,
  CUSTOMER_PHONE_VALIDATE: CustomerValidatePhoneDrawer,
};


export function CustomerRootDrawer(): ReactElement {

  const childRef = useRef<CustomerAddDrawerProps>(null);
  const dispatch = useAppDispatch();
  const { drawerType, drawerProps } = useAppSelector((state) => ({
    drawerType: state.customers.drawerType,
    drawerProps: state.customers.drawerProps,
  }));
  let open = true;

  const handleClose = (): void => {
    console.log("handled on close called");
    if (childRef && childRef.current) {
      console.log(childRef.current);
      childRef.current.CompareChanges((r: boolean) => {
        if (!r) {
          console.log("nothing has changed");
          dispatch(UpdateSelectedCustomer(undefined));
          dispatch(hideCustomerDrawer());
        } else {
          console.log("something has changed");
          Swal.fire({
            // icon: 'warning',
            // icon
            // title: 'Unsaved Changes in Customer',
            showClass: {
              popup: 'animate__animated animate__fadeIn animate__faster'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOut animate__faster'
            },
            showCancelButton: true,
            width:560,
            showDenyButton: true,
            confirmButtonColor: '#3F51B5',
            cancelButtonColor: 'grey',
            denyButtonColor : "#FF1744",
            showCloseButton: true,
            reverseButtons: true,
            cancelButtonText: "Cancel",
            confirmButtonText: 'Save changes',
            denyButtonText: `Close but don't save`,
            html: '<p style="font-size:1rem;margin-top:10px;">There are unsaved changes, do you want to save your changes?</p>',
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              if (childRef && childRef.current) {
                childRef.current.SubmitForm();
              }
            } else if (result.isDenied) {
              dispatch(UpdateSelectedCustomer(undefined));
              dispatch(hideCustomerDrawer());
            }
          });

        }
      });
    }
    else {
      console.log("handle Close called");
      dispatch(UpdateSelectedCustomer(undefined));
      dispatch(hideCustomerDrawer());
    }
  };

  console.log("triggered Drawer = ", drawerType);
  if (!drawerType) open = false;
  const SpecificDrawer: React.FC | React.ForwardRefExoticComponent<React.RefAttributes<any>> = DRAWER_COMPONENTS[drawerType || 'null'];
  return (

    <Drawer
      PaperProps={{
        style: { width: "700px" },
      }}
      anchor="right"
      variant="temporary"
      open={open}
      onClose={handleClose}
      SlideProps={{ timeout: { enter: 400, exit: 400 } }}>
      {open && <SpecificDrawer ref={childRef} style={{ width: "30%" }} {...drawerProps} />}
    </Drawer>
  );
}
export default CustomerRootDrawer;
