import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton, MenuItem,
  Popover,
  Switch,
  TextField, Typography
} from '@material-ui/core';
import { CustomerEmail } from 'common/models/customer';
import React, { ReactElement, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import { CustomerEmailType } from 'components/customer/api/CustomerAPI';
import { useStyles } from './CustomerForm';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';
import EmailLink  from 'common/components/buttons/email-link';

export function CustomerEmailList({
  formik, emailTypes, dmsTypeId, loading
}: {
  formik: any;
  emailTypes: CustomerEmailType[];
  dmsTypeId: number;
  loading: boolean;
}): ReactElement {
  const [emailError, setEmailError] = useState<{ notEmail: boolean; helperText: string; }>({ notEmail: false, helperText: '' });
  const [popoverState, setPopoverState] = useState<any>({ openedPopoverId: null, anchorEl: null });
  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopoverState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  };
  const handlePopoverClose = () => {
    !emailError.notEmail &&
      setPopoverState({
        openedPopoverId: null,
        anchorEl: null,
      });
    const data = formik.values.customerEmails.length - 1;
    if (formik.values.customerEmails[data].address === '' && !formik.values.customerEmails[data].isDefault) {
      let emails = [...formik.values.customerEmails];
      emails.splice(data, 1);
      formik.setFieldValue('customerEmails', emails);
    }
  };

  const addEmailRow = (event: any) => {
    let customer = { ...formik.values };
    let emails = [...formik.values.customerEmails];

    if (dmsTypeId != 9) {
      let allEmailTypes: number[] = emailTypes.map((x: CustomerEmailType) => x.customerEmailTypeId);
      let emailType: number[] = emails.map((x: CustomerEmail) => x.emailType);
      let notAddedEmailTypes = allEmailTypes.filter((x: number) => emailType.indexOf(x) == -1);
      if (notAddedEmailTypes.length > 0) {
        const customerEmail: CustomerEmail = {
          address: '',
          contactId: customer && (customer.primaryContactId ?? 0),
          customerId: customer ? customer.id : 0,
          dealerId: customer ? customer.dealerId : 0,
          emailType: notAddedEmailTypes[0],
          lastUpdated: '',
          isPrimary: false,
          isDefault: dmsTypeId === 9 && emails?.length === 0 ? true : false,
        };
        emails.push(customerEmail);
        formik.setFieldValue('customerEmails', emails);
        const _index = emails.indexOf(customerEmail);
        handlePopoverOpen(event, _index);
      }
    } else {
      const customerEmail: CustomerEmail = {
        address: '',
        contactId: customer && (customer.primaryContactId ?? 0),
        customerId: customer ? customer.id : 0,
        dealerId: customer ? customer.dealerId : 0,
        emailType: emailTypes[0] ? emailTypes[0].customerEmailTypeId : 0,
        lastUpdated: '',
        isPrimary: false,
        isDefault: dmsTypeId === 9 && emails?.length === 0 ? true : false,
      };
      emails.push(customerEmail);
      formik.setFieldValue('customerEmails', emails);
      const _index = emails.indexOf(customerEmail);
      handlePopoverOpen(event, _index);
    }
  };

  const removeEmailRow = (index: number) => {
    let customerEmails = [...formik.values.customerEmails];
    customerEmails.splice(index, 1);
    formik.setFieldValue('customerEmails', customerEmails);
  };
  function ValidateEmail(email: any, index: number) {
    if (email.length > 0 && !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      formik.setFieldError(`customerEmails[${index}].address`, 'Invalid Email', false);
      setEmailError({ notEmail: true, helperText: 'Invalid Email' });
    } else {
      setEmailError({ notEmail: false, helperText: '' });
    }
  }
  const classes: any = useStyles();
  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <EmailIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">Email</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item style={{ marginTop: '-10px' }}>
              <Typography variant="h5">
                Opt In{' '}
                <Switch
                  checked={formik.values.optInEmail}
                  onChange={(e: any) => {
                    formik.setFieldValue('optInEmail', e.target.checked);
                  }} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <AddIcon
                  onClick={(e: any) => {
                    addEmailRow(e);
                  }}
                  style={{ cursor: 'pointer' }} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          {formik.values.customerEmails &&
            formik.values.customerEmails.map((row: CustomerEmail, index: number) => {
              // if(dmsTypeId == DmsTypeEnum.Ideal && row.contactId != 0){
              //   return;
              // }
              return (
                <>
                  <Box mt={1}>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Typography variant="body1">
                          {formik.values.customerEmails[index].address ? (
                            <EmailLink email={formik.values.customerEmails[index].address} />
                          ) : (
                            <span style={{ color: 'rgb(152, 152, 152)' }}>address@example.com</span>
                          )}
                          &nbsp; &nbsp;
                          <Chip
                            className={classes.smallChip}
                            label={!loading ?
                               emailTypes.filter(
                              (x) => formik.values.customerEmails[index].emailType === x.customerEmailTypeId
                            )[0]?.name
                            :<CircularProgress size='1rem' style={{color:'white',display:'flex'}}/>}
                            color="primary"
                            variant="filled" />
                          {/* {row.isPrimary && dmsTypeId === 9 && <>&nbsp;</>}
                            {row.isPrimary && dmsTypeId === 9 && (
                              <Chip className={classes.smallChip} label={'Primary'} color="success" variant="filled" />
                            )} */}
                          {row.isDefault && dmsTypeId === 9 && <>&nbsp;</>}
                          {row.isDefault && dmsTypeId === 9 && (
                            <Chip className={classes.smallChip} label={'Default'} color="info" variant="filled" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ marginTop: '-10px' }}
                          aria-describedby={index.toString()}
                          onClick={(e: any) => handlePopoverOpen(e, index)}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={index.toString()} onClick={(e: any) => (handlePopoverOpen(e, index))} fontSize='small' /> */}
                        {/* {((dmsTypeId === 9 && !row.isDefault && row.contactId < 0) || dmsTypeId !== 9) && ( */}
                        <ClearIcon
                          fontSize="small"
                          aria-describedby={index.toString()}
                          onClick={(e: any) => {
                            removeEmailRow(index);
                          }}
                          style={{ cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  </Box>
                  <Popover
                    id={'pop_' + index}
                    open={popoverState.openedPopoverId === index}
                    anchorEl={popoverState.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <Box p={2} style={{ width: '500px' }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            id={`customerEmails_${index}.address`}
                            name={`customerEmails[${index}].address`}
                            label="Email Address"
                            value={formik.values.customerEmails[index].address}
                            onChange={(e) => {
                              formik.setFieldValue(`customerEmails[${index}].address`, e.target.value);
                            }}
                            onChangeCapture={(e: any) => {
                              ValidateEmail(e.target.value, index);
                            }}
                            error={emailError.notEmail}
                            helperText={emailError.helperText} />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            margin="dense"
                            id={`customerEmails_${index}.emailType`}
                            name={`customerEmails[${index}].emailType`}
                            label={`Type`}
                            value={formik.values.customerEmails[index].emailType}
                            onChange={(e: any) => {
                              formik.setFieldValue(`customerEmails[${index}].emailType`, e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}>
                            {emailTypes.map((option: CustomerEmailType) => {
                              return (
                                <MenuItem 
                                disabled={
                                  formik.values.customerEmails.filter((x: any) => {
                                    return x.emailType === option.customerEmailTypeId;
                                  })?.length > 0 && dmsTypeId !== DmsTypeEnum.Aspen
                                }
                                key={option.customerEmailTypeId} value={option.customerEmailTypeId}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={3}>
                          <Box ml={1} mt={1}>
                            {dmsTypeId === 9 && (
                              <FormControlLabel
                                control={<Checkbox
                                  disabled={row.isDefault}
                                  onChange={(e) => {
                                    formik.setFieldValue(`customerEmails[${index}].isDefault`, e.target.checked);
                                    formik.values.customerEmails.forEach((e: any, i: number) => {
                                      if (i !== index) {
                                        formik.setFieldValue(`customerEmails[${i}].isDefault`, false);
                                      }
                                    });
                                  }}
                                  checked={row.isDefault}
                                  inputProps={{ 'aria-label': 'controlled' }} />}
                                label="Default" />
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={2}>
                          <Box mt={1} ml={2}>
                            <Button
                              fullWidth
                              id="saveCustomerEmailBtn"
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                handlePopoverClose();
                              }}>
                              Close
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Popover>
                </>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}
