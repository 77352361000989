import { get, post, put, destroy } from 'api';
import { store } from 'app';
import axios, { AxiosError, AxiosResponse } from 'axios';
import config from 'config';
import Roles from '../components/role/roles';

import User, { Permission, Role } from '../models/user';

const dealerUrl = config.get('api.dealer');

const UserAPI = {
  async getUsers(dmsDealerIdCrm: number, dealerId: number): Promise<User[]> {
    const url = `${dealerUrl}/users/1/500`;
    let { data } = await get(url);
    return data;
  },
  async getUsersByDepartment(departmentId : number): Promise<User[]> {
    const url = `${dealerUrl}/dealers/departments/${departmentId}/users`;
    let { data } = await get(url);
    return data;
  },
  async getRoles(dmsDealerIdCrm: number): Promise<Role[]> {
     const url = `${dealerUrl}/roles/100`;
     const { data } = await get(url);
    return data.data.map((x: any)=> ({roleId : x.id, roleName : x.name}));
  },
  async createRole(dmsDealerIdCrm : number, roleName : string, permissionIds : string[]): Promise<any>{
    const url = `${dealerUrl}/role`;
    const  response = await post<Role>(url, { dmsDealerId: dmsDealerIdCrm, Name :  roleName, permissionIds });
    return {status : response.status, data : response.data};
  },
  async updateRole(dmsDealerIdCrm : number, roleId: string, roleName : string, permissions : Permission[]): Promise<any>{
    const url = `${dealerUrl}/role/${roleId}`;
    const  response = await put<Role>(url, { roleId, dmsDealerId: dmsDealerIdCrm, Name :  roleName, permissions });
    return {status : response.status, data : response.data};
  },
  async deleteRole(dmsDealerIdCrm : number, roleId : string): Promise<any>{
    const url = `${dealerUrl}/role/${roleId}`;
    let result  = await destroy(url).then((response: AxiosResponse<any>) => {
      return response;
    })
    .catch((reason: any) => { 
      if (reason.statusCode === 424) {
        let k : any = {
          status: reason.statusCode, 
          usersCount: reason.usersCount, 
          usersInRole : reason.usersInRole
        }
        return k;
      } else { 
        return {status : 500};
      }
    });
    return result;
  },
  async updateUserRole(dmsDealerIdCrm : number, newRoleId : string, currentRoleId : string, userIds : string[]): Promise<any>{
    const url = `${dealerUrl}/user/role`;
    const updateUserRequest = { newRoleId : newRoleId, RoleId: currentRoleId,  userId : userIds };
    const  response = await put(url, updateUserRequest);
    return response;
  },
  async getPermissions(dmsDealerIdCrm : number) : Promise<Permission[]> {
    const url = `${dealerUrl}/product/features/100`;
    const { data } = await get(url);
   return data.data.map((x: any)=> ({permissionId : x.id, permissionName : x.description}));
  },
  
  async getRoleById(dmsDealerIdCrm : number, roleId : string) : Promise<Role>{
    const url = `${dealerUrl}/role/${roleId}`;
    const { data } = await get(url);
    let roles : Role[] = data.data.map((x: any)=> (
      {
        roleId : x.id, 
        roleName : x.name, 
        permissions : x.rolePermissions.filter((z : any)=>z.permission).map((y : any)=> ({permissionId : y.permissionId, permissionName : y.featureDescription})) 
      }
      ));
    return roles[0];
  },

  async updateUserDepartment(dmsDealerIdCrm: number, user: User, departmentIds: number[]): Promise<void> {
    const url = `${dealerUrl}/v2/dealers/${dmsDealerIdCrm}/users/${user.crmUserId}/departments/batchUpdate`;
    const updateUserRequest = {
      departmentIds,
      crmUserId: user.crmUserId,
    };
    const { data } = await put(url, updateUserRequest);
    return data;
  },

  async updateUserRoles(dmsDealerIdCrm: number, user: User, roleIds: string[]): Promise<void> {
    console.log("updateUserRoles called");
    const url = `${dealerUrl}/user/role`;
    const permissions = (await this.getRoleById(111111,roleIds[0])).permissions;
    const updateUserRequest = { roleId : roleIds[0], userId: [user.crmUserId],permissions };
    const { data } = await post(url, updateUserRequest);
    return data;
  },

};

export default UserAPI;
