import React from 'react';
import { useAppSelector } from 'app';
import { Redirect, Route, RouteProps } from 'react-router-dom';

function PublicRoute(props: RouteProps): React.ReactElement | null {
  const isLoggedIn = useAppSelector((state) => state.user.loggedIn);
  const { component, ...restProps } = props;
  console.log("public route triggere");
  const renderPublicRoute = (routeRenderProps: any): React.ReactElement | null => {
    if (
      isLoggedIn &&
      routeRenderProps.location.pathname.indexOf('unsubscribe') === -1 &&
      routeRenderProps.location.pathname.indexOf('videocall') === -1
    ) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: routeRenderProps.location },
          }}
        />
      );
    } else {
      if (component) {
        const Component = component;
        return <Component {...routeRenderProps} />;
      } else {
        // Route with component
        return null;
      }
    }
  };
  return <Route {...restProps} render={renderPublicRoute} />;
}

export default PublicRoute;
