import { Box, Container, Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { MessageTemplate } from 'components/broadcast/models/message-template';
import Department from 'components/setting/models/department';
import AppSnackbar from 'components/snackbar/snackbar';

import QuickMessageForm from './quick-message-form';

function EditQuickMessageDrawer({
  departments,
  open,
  quickMessageId,
  onClose,
  onSave,
}: {
  departments: Department[];
  open: boolean;
  quickMessageId: number;
  onClose(): void;
  onSave(value: any): Promise<void>;
}): ReactElement {
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [quickMessage, setQuickMessage] = React.useState<MessageTemplate | undefined>();

  React.useEffect(() => {
    async function fetchQuickMessage(): Promise<void> {
      try {
        const response = await BroadCastAPI.getMessageTemplate(quickMessageId);
        if (!response) throw new Error('Invalid response');
        setQuickMessage(response);
      } catch (error) {
        showSnackbar({ type: 'error', message: 'Unable to get quick message' });
        onClose();
        setQuickMessage(undefined);
      }
    }
    if (quickMessageId) {
      fetchQuickMessage();
    }
  }, [quickMessageId, onClose, showSnackbar]);

  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Edit Quick Message" onClose={onClose} />
      <Box mb={1} />
      <Container>
        {open && (
          <QuickMessageForm
            departments={departments}
            onCancel={onClose}
            onSubmit={(value, createMore): Promise<void> => onSave(value)}
            quickMessage={quickMessage}
          />
        )}
      </Container>
    </Drawer>
  );
}

export default EditQuickMessageDrawer;
