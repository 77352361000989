import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'app';

import UserAPI from 'components/setting/api/user-api';
import Department from 'components/setting/models/department';
import User, { Role } from 'components/setting/models/user';
import AppSnackbar from 'components/snackbar/snackbar';

import useDepartments from '../department/useDepartments';
import EditUserDepartmentDrawer from './edit-user-department-drawer';
import DepartmentFilterContext from 'components/layouts/DashboardLayout/department-filter-context';
import EditUserRoleDrawer from './edit-user-role-drawer';
import RoleListDrawer from '../role/role-list-drawer';
import EditRoleDrawer from '../role/edit-role-drawer';
import NewRoleDrawer from '../role/new-role-drawer';
import MessagingAPI from 'components/messaging/api/messaging-api';
import Backdrop from '@mui/material/Backdrop';

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const { fetchUserDepartmentSettings } = React.useContext(DepartmentFilterContext);
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const defaultDealerLocationId = useAppSelector((state) => state.user.dealerId);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);

  const departments = useDepartments(dmsDealerIdCrm, 0);
  const [selectedUser, setSelectedUser] = React.useState<User | undefined>();
  const [conversationDepartmentIds, setConversationDepartmentIds] = React.useState<number[] | undefined>();
  const [selectedRoleUser, setSelectedRoleUser] = React.useState<User | undefined>();
  const [selectedRole, setSelectedRole] = React.useState<Role | undefined>();

  const [openRoleDrawer, setOpenRoleDrawer] = React.useState<boolean>(false);
  const [reloadList, setReloadList] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const [editDepartloading, setEditDepartloading] = useState(false);

  /** Edit Role Drawer Items */
  // const [roles, setRoles] = useState<Role[] | undefined>();
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>();
  const [editRoleDrawerState, setEditRoleDrawerState] = React.useState(false);
  /** end Edit Role Drawer Items */

  /**Add Role Drawer Items */
  const [showAddRole, setShowAddRole] = useState(false);
  /**end Add Role Drawer Items  */


  async function onEditUserDepartment(user: User) {
    setEditDepartloading(true);
    const departIds =  await departmentIds(user.crmUserId)
    setConversationDepartmentIds(departIds);
    setEditDepartloading(false);
    setSelectedUser(user);
  }
  function onEditUserRole(user: User): void {
    setSelectedRoleUser(user);
    setSelectedRole(user.userRoles[0]);
  }

  const departmentIds =  async(crmUserId:string):Promise<number[]>=>{
    try
    {
      const response = await MessagingAPI.getDepartmentIds(crmUserId);
      if(response.isSuccess)
        return response.data;
    }
    catch(errror)
    {}
    
    return [];
  }

  const fetchUsers = React.useCallback(async () => {
    setLoading(true);
    const response = await UserAPI.getUsers(dmsDealerIdCrm, defaultDealerLocationId);
    response.map((x: any) => (x.roles = []));
    setUsers(response);
    setLoading(false);
  }, [defaultDealerLocationId, showSnackbar, dmsDealerIdCrm]);

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers, reloadList]);

  function openAddRoleDrawer(): void {
    setShowAddRole(true);
  }

  function closeAddRoleDrawer(): void {
    setShowAddRole(false);
    setOpenRoleDrawer(true);
  }

  async function handleUpdateDepartment(updatedDepartments: Department[]): Promise<void> {
    if (selectedUser !== undefined) {
      const departmentIds = updatedDepartments.map((department: Department) => department.departmentId);
      await UserAPI.updateUserDepartment(dmsDealerIdCrm, selectedUser, departmentIds);
      setSelectedUser(undefined);
      showSnackbar({ type: 'success', message: 'User department updated' });
      fetchUsers();
      fetchUserDepartmentSettings();
    }
  }

  async function handleUpdateRole(roleId: string): Promise<void> {
    console.log("handler called");
    if (selectedRoleUser !== undefined) {
      await UserAPI.updateUserRoles(dmsDealerIdCrm, selectedRoleUser, [roleId]);
      setSelectedUser(undefined);
      showSnackbar({ type: 'success', message: 'User Role updated' });
      fetchUsers();
    }
  }

  async function handleCreateRole(createRoleFormValue: any, createMore: boolean): Promise<void> {
    console.log("handleCreateRole Called");
    const { roleName, permissions } = createRoleFormValue;
    const permissionIds = permissions.map((permission: any) => permission.permissionId);
    const response = await UserAPI.createRole(dmsDealerIdCrm, roleName, permissionIds);
    if (response.status != 200) throw new Error('Unable to create role');
    showSnackbar({ type: 'success', message: 'Role created' });
    if (!createMore) {
      setShowAddRole(false);
    }
  }

  async function handleSaveRole(editRoleFormValue: any): Promise<void> {
    const { roleId, roleName, permissions } = editRoleFormValue;
    await UserAPI.updateRole(dmsDealerIdCrm, roleId, roleName, permissions);
    showSnackbar({ type: 'success', message: 'Role updated' });
    setEditRoleDrawerState(false);
    setSelectedRoleId(undefined);
  }

  async function openCreateDrawer() : Promise<void> {
    setOpenRoleDrawer(false);
    setShowAddRole(true);
  }

  async function openEditRoleDrawer(roleId: string): Promise<void> {
    setOpenRoleDrawer(false);
    setSelectedRoleId(roleId);
    setEditRoleDrawerState(true);
  }


  return (
    <Paper square style={{ padding: '10px' }}>
      <Box mb={1} display="flex">
        <Box flex={1} />
        <Box></Box>
        <Box mx={1}>
          <Button color="primary" variant="contained" size="small" type="submit" onClick={() => { setOpenRoleDrawer(true); }}>
            Manage Roles
          </Button>
        </Box>
      </Box>


      <Box my={1}>
        <TextField
          fullWidth
          label="Search"
          placeholder="Search username"
          margin="dense"
          size="small"
          name="userSearch"
          id="userSearch"
          variant="outlined"
          onChange={({ target: { value } }): void => setSearchText(value)}
        />
      </Box>
      <Box mb={2}>
        {loading ? (
          <Box height="500px" margin="auto" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
            <Table stickyHeader id="user-table" aria-label="Users table">
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Role</TableCell>
                  {/* <TableCell>Admin</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users
                    .filter((user) => user.username.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
                    .map((user) => (
                      <TableRow key={user.crmUserId}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>
                          <Box component="span">
                            {user.departments.length ? user.departments.map((x) => x.departmentName).join(', ') : 'N/A'}
                          </Box>
                          <Box mr={1} component="span">
                            <IconButton size="small" onClick={()=>onEditUserDepartment(user)}>
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box component="span">
                            {(user.userRoles && user.userRoles.length > 0) ? user.userRoles.map((x) => x.roleName).join(', ') : 'N/A'}
                          </Box>
                          <Box mr={1} component="span">
                            <IconButton size="small" onClick={(): void => onEditUserRole(user)}>
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                        {/* <TableCell>{user.isAdmin ? 'Yes' : 'No'}</TableCell> */}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <EditUserDepartmentDrawer
        conversationDepartmentIds={conversationDepartmentIds??[]}
        userDepartments={selectedUser?.departments}
        onUpdateDepartment={handleUpdateDepartment}
        departments={departments}
        open={!!selectedUser}
        onClose={(): void => setSelectedUser(undefined)}
      />
      <EditUserRoleDrawer
        open={!!selectedRoleUser}
        roleId={selectedRole?.roleId}
        onSave={(roleId: string) => (handleUpdateRole(roleId))}
        onClose={(): void => setSelectedRoleUser(undefined)}
      />
      <RoleListDrawer
        open={openRoleDrawer}
        reloadList={reloadList}
        setReloadList={setReloadList}
        onCreate={()=> { openCreateDrawer() }}
        onEdit={(roleId: string) => { openEditRoleDrawer(roleId) }}
        onClose={() => { setOpenRoleDrawer(false); }}
      />

      <NewRoleDrawer
        open={showAddRole}
        onCreate={handleCreateRole}
        onClose={closeAddRoleDrawer}
      />

      <EditRoleDrawer
        roleId={selectedRoleId}
        open={editRoleDrawerState}
        onSave={handleSaveRole}
        onClose={(): void => { setEditRoleDrawerState(false); setOpenRoleDrawer(true); }}
      />
       <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={editDepartloading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default Users;
