import { makeStyles } from '@material-ui/core';
import VideoSVG from 'assets/images/video.svg';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  logo: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
}));

function Video(): ReactElement {
  const classes: any = useStyles();
  return <img src={VideoSVG} alt="Video icon" className={classes.logo} />;
}

export default Video;
