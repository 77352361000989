import { Box, Button, Checkbox, MenuItem, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';

import { LocationOption } from 'common/models/location-option';
import User, { Permission, Role } from 'components/setting/models/user';
import AppSnackbar from 'components/snackbar/snackbar';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RoleForm({
  permissions,
  role,
  onCancel,
  onSubmit,
}: {
  permissions: Permission[];
  role?: Role;
  onCancel(): void;
  onSubmit(values: any, createMore: boolean): void;
}): ReactElement {
  let defaultDealerLocation = 0;

  const initialValues: {
    roleId?: string;
    roleName?: string;
    permissions: Permission[];
  } = {
    roleId: role?.roleId || '0',
    roleName: role?.roleName || '',
    permissions: role?.permissions || [],
  };
  const editMode = !!role?.roleId;
  const [showSnackbar] = AppSnackbar.useSnackBar();

  async function handleSubmit(values: any): Promise<void> {
    if (!formik.values.permissions || formik.values.permissions.length === 0) {
      showSnackbar({ type: 'error', message: 'atleast one permission must be selected' });
      return;
    }
    await onSubmit(values, false);
    formik.resetForm();
  }

  const validationSchema = Yup.object<any>().shape<any>({
    roleName: Yup.string().required('please enter a name'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSaveAndCreateNew(): Promise<void> {
    await formik.validateForm();
    if (!formik.values.permissions || formik.values.permissions.length === 0) {
      showSnackbar({ type: 'error', message: 'atleast one permission must be selected' });
      return;
    }
    if (formik.isValid) {
      try {
        formik.setSubmitting(true);
        await onSubmit(formik.values, true);
        formik.setSubmitting(false);
        formik.resetForm();
      } catch (error:any) {
        formik.setSubmitting(false);
        showSnackbar({ type: 'error', message: error });      
      }
    }
  }

  function handleUserChange(values: any[]): void {
    formik.setValues(
      {
        roleId: formik.values.roleId,
        roleName: formik.values.roleName,
        permissions: values,
      },
      false,
    );
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        autoFocus
        margin="dense"
        variant="outlined"
        name="roleName"
        id="roleName"
        label="Role Name"
        onChange={formik.handleChange}
        placeholder="Role Name"
        value={formik.values.roleName}
        error={!!formik.errors.roleName}
        helperText={formik.errors.roleName}
        required
      />
      <Autocomplete
        multiple
        id="tags-standard"
        value={formik.values.permissions}
        options={permissions}
        onChange={(event: any, values: any): void => handleUserChange(values)}
        getOptionLabel={(option: any) => (option.permissionName ? option.permissionName : '')}
        disableCloseOnSelect
        getOptionSelected={(option: Permission, value: Permission): boolean =>
          option.permissionId === value.permissionId
        }
        renderOption={(option, { selected }) => (
          <>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            {option.permissionName}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name="permissions"
            id="permissions"
            variant="outlined"
            margin="dense"
            label="Permissions"
            placeholder="Select Permissions"
          />
        )}
      />
      <Box mt={2} id="formBtn" display="flex">
        <Box flexGrow={1} />
        {editMode ? (
          <Button
            id="saveRoleBtn"
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            disabled={formik.isSubmitting}>
            Save
          </Button>
        ) : (
          <>
            <Box mx={1}>
              <Button
                id="createRoleBtn"
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                disabled={formik.isSubmitting}>
                Create
              </Button>
            </Box>
            <Box>
              <Button
                id="saveAndCreateNewRoleBtn"
                type="button"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSaveAndCreateNew}
                disabled={formik.isSubmitting}>
                Save & Create New
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}
