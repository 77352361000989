import { Container, Drawer, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement } from 'react';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';

import NewSurveyForm, { NewSurveyFormValues } from './new-survey-form';
import { SurveyConstants } from 'common/utils/shared/shared-constants';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    height: '100%',
    marginTop: theme.spacing(2),
  },
}));

export function NewSurveyDrawer({
  open,
  onClose,
  onCreate,
  selectedDealerLocationId,
  locationOptions,
}: {
  open: boolean;
  onClose(): void;
  selectedDealerLocationId?: number;
  locationOptions?: LocationOption[];
  onCreate(values: NewSurveyFormValues): Promise<void>;
}): ReactElement {
  const classes: any = useStyles();
  function handleSurveyFormSubmit(values: NewSurveyFormValues): void {
    values.startingHour = SurveyConstants.DefaultStartHour;
    values.endingHour = SurveyConstants.DefaultEndHour;
    onCreate(values);
  }

  const handleCloseDrawer = (): void => {
    onClose();
  };
  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="New Survey" onClose={handleCloseDrawer} />
      <Container className={classes.container}>
        <NewSurveyForm
          onSubmit={handleSurveyFormSubmit}
          locationOptions={locationOptions}
          selectedDealerLocationId={selectedDealerLocationId}
        />
      </Container>
    </Drawer>
  );
}

export default NewSurveyDrawer;
