import { Box, Container, Drawer, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Customer from 'common/models/customer';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import CustomerForm from 'components/customer/components/CustomerForm/CustomerForm';

import config from '../config';

const useStyles = makeStyles((theme: Theme): any => ({
  drawer: {
    // marginTop: theme.navBar.height,
    // width: `${config.CustomerDrawerWidth}px`,
    // height: (open: boolean): string => (open ? `calc(100% - ${theme.navBar.height})` : '0px'),
    // width: `${drawerWidth}px`,
    // zIndex: 1070,

  },
}));

export function CustomerEditDrawer({ customerId, onClose, variant="temporary", onSave }: { customerId: number; onClose(): void, variant?: string, onSave?(): void  }): ReactElement {
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const open = customerId !== 0;
  const classes: any = useStyles(open);
  const [hasChanges, sethasChanges] = React.useState<any>(false);
  useEffect(() => {
    async function fetchCustomer(): Promise<void> {
      //alert('fetchCustomer');
      if (customerId) {
        try {
          setLoading(true);
          const customerResponse = await CustomerAPI.getCustomer(customerId);
          console.log(customerResponse);
          if (!customerResponse.emails) {
            customerResponse.emails = [];
          }
          setCustomer(customerResponse);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          dispatch({ type: 'error', message: 'Unable to get customer' });
          console.error(error);
        }
      }
    }
    fetchCustomer();
  }, [customerId, dealerId, dispatch]);

  async function handleCustomerFormSubmit(values: Customer): Promise<void> {
    try {
      const response = await CustomerAPI.editCustomer(dealerId, values);
      sethasChanges(true);
      if (onSave != undefined) {
        onSave();
      }
      console.log(response);
      //onClose();
    } catch (error) {
      console.error(error);
      onClose();
    }
  }

  function handleClose(): void {
    onClose();
  }
  return (
    <Drawer
      PaperProps={{ style: {width:"600px"}}}
      anchor="right"
      open={open}
      className={classes.drawer}
      classes={{ paper: classes.drawer }}
      variant="temporary"
      onClose={handleClose}
      SlideProps={{ timeout: { enter: 500, exit: 500 } }}
      >
      <DrawerToolbar title="Customer Details" onClose={handleClose} />
      <Container>
        {!loading ? (
          
          <CustomerForm
            btnDisabled={false}
            customer={customer}
            disabled={false}
            onFormSubmit={handleCustomerFormSubmit}
            onClose={handleClose}
            validate
          />
        ) : (
          <Box width="100%" display="flex" justifyContent="center" mt={1}>
            <SmallCircularProgress />
          </Box>
        )}
      </Container>
    </Drawer>
  );
}

export default CustomerEditDrawer;
