import {
  Box,
  colors,
  Container,
  debounce,
  Divider,
  Fab,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ForwardIcon from '@material-ui/icons/Forward';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'app';

import { TabPanel } from 'common/components/tabs';
import { ToggleFab } from 'common/components/buttons';
import { useRouter } from 'common/hooks';
import useLocations from 'common/hooks/useLocations';

import AppSnackbar from 'components/snackbar/snackbar';
import SurveyAPI from 'components/survey/api/survey-api';

import { QuestionType } from '../question-field/question-types';
import { SurveyPreview } from '../survey-preview';
import { SurveyMessage } from './survey-message';
import { SurveyQuestions } from './survey-questions';
import { QuestionCategory } from './survey-questions/new-question-block/new-question-block';
import { SurveySettings } from './survey-settings';
import { SurveySummary } from './survey-summary';
import { SurveyThankYouPageSetting } from './survey-thank-you-page-setting';
import { Question, Survey, SurveyLocation } from 'components/survey/models';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    position: 'relative',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: `80%`,
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
  },
  actionFabButtons: {
    position: 'absolute',
    top: '0px',
    right: '-46px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  actionFab: {
    margin: theme.spacing(1, 0),
  },
  red: {
    background: colors.red[500],
  },
}));

function getHash(index: number): string {
  let hash = '';
  switch (index) {
    case 0:
      hash = '#questions';
      break;
    case 1:
      hash = '#thankYouPage';
      break;
    case 2:
      hash = '#settings';
      break;
    case 3:
      hash = '#message';
      break;
    case 4:
      hash = '#summary';
      break;
    default:
      hash = '';
  }
  return hash;
}

function useSurveyPageTab(): [number, (value: number) => void] {
  let index = 0;
  const router = useRouter();
  switch (router.location.hash) {
    case '#questions':
      index = 0;
      break;
    case '#thankYouPage':
      index = 1;
      break;
    case '#settings':
      index = 2;
      break;
    case '#message':
      index = 3;
      break;
    case '#summary':
      index = 4;
      break;
    default:
      index = 0;
  }
  const [tabIndex, setTabIndex] = useState(index);
  function handleTabChange(value: number): void {
    // eslint-disable-next-line no-undef
    window.location.hash = getHash(value);
    setTabIndex(value);
  }

  return [tabIndex, handleTabChange];
}

export function SurveyPage(): ReactElement {
  const theme = useTheme();
  const classes: any = useStyles();
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { locations, locationOptions, getLocationName } = useLocations(dmsDealerIdCrm);

  const router = useRouter();
  const { query, push: pushUrl }: any = router;

  const containerMaxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false = 'md';
  const [tabIndex, handleTabChange] = useSurveyPageTab();

  const [survey, setSurvey] = React.useState<Survey | undefined>(undefined);
  const [showSurveyPreview, setShowSurveyPreview] = React.useState(false);

  const saveSurveyApi = (s: Survey): void => {
    SurveyAPI.updateSurvey(s);
  };
  const debouncedSave = useRef(debounce((s: any) => saveSurveyApi(s), 800)).current;

  const surveyDisabled = survey ? !survey.isActive || survey.isSent : true;
  const automaticSurvey = survey ? survey.type === 'Auto' : false;
  const areFabButtonsVisible = tabIndex <= 3 && survey;

  async function handleQuestionAdd(type: QuestionType, category: QuestionCategory): Promise<void> {
    if (survey !== undefined) {
      try {
        const question: Question = {
          title: '',
          id: -1,
          surveyId: survey.id,
          type: type.toString(),
          category: category.toString(),
          isMandatory: false,
        };
        const response = await SurveyAPI.addQuestion(survey.id, question);
        if (response) {
          const newQuestions = [...survey.questions, response];
          setSurvey({ ...survey, questions: newQuestions });
        }
      } catch (error) {
        showSnackbar({ type: 'error', message: 'Unable to add question' });
      }
    }
  }
  function handleQuestionDelete(question: Question): void {
    if (survey !== undefined) {
      const newQuestions = survey.questions.filter((q) => q.id !== question.id);
      setSurvey({ ...survey, questions: newQuestions });
    }
  }
  function handleQuestionChange(event: any): void {
    const questionId = parseInt(event.target.name, 10);
    if (survey !== undefined) {
      const newQuestions = survey.questions.map((question) => {
        if (question.id !== questionId) return question;
        return { ...question, title: event.target.value };
      });
      setSurvey({ ...survey, questions: newQuestions });
    }
  }
  function handleSettingChange(event: any): void {
    if (survey !== undefined) {
      const newSurvey: Survey = { ...survey };
      switch (event.target.name) {
        case 'title':
          newSurvey.title = event.target.value;
          break;
        case 'message':
          newSurvey.message = event.target.value;
          break;
        case 'description':
          newSurvey.description = event.target.value;
          break;
        case 'isAutomaticSurvey':
          newSurvey.isAutomaticSurvey = !survey?.isAutomaticSurvey;
          break;
        case 'type': {
          if (newSurvey.type === 'Auto') {
            newSurvey.isAutomaticSurvey = true;
          } else {
            newSurvey.isAutomaticSurvey = false;
          }
          newSurvey.type = event.target.value;
          break;
        }
        case 'queryParts':
          newSurvey.scheduleSetting.queryParts = !newSurvey.scheduleSetting.queryParts;
          break;
        case 'queryEquipment':
          newSurvey.scheduleSetting.queryEquipment = !newSurvey.scheduleSetting.queryEquipment;
          break;
        case 'queryServices':
          newSurvey.scheduleSetting.queryServices = !newSurvey.scheduleSetting.queryServices;
          break;
        case 'startingHour':
          newSurvey.scheduleSetting.startingHour = event.target.value;
          break;
        case 'endingHour':
          newSurvey.scheduleSetting.endingHour = event.target.value;
          break;
        case 'dealerLocationId':
          newSurvey.dealerLocationId = event.target.value;
          newSurvey.locations = getSurveyLocations(survey, event.target.value);
          break;
        case 'useDaylightTime':
          newSurvey.scheduleSetting.useDaylightTime = !newSurvey.scheduleSetting.useDaylightTime;
          break;
        default:
          throw Error('Invalid field type');
      }
      setSurvey(newSurvey);
    }
  }
  function handleThankYouPageSettingChange(surveyLocationId: number, name: string, value: string): void {
    if (survey) {
      const newSurveyLocations = survey.locations.map((location) => {
        if (location.id !== surveyLocationId) return location;
        return { ...location, [name]: value };
      });
      setSurvey({ ...survey, locations: newSurveyLocations });
    }
  }
  function getSurveyLocations(sur: Survey, dealerLocationId: number): SurveyLocation[] {
    let surveyLocation: SurveyLocation[] = [];
    if (locations) {
      if (dealerLocationId === 0) {
        surveyLocation = locations
          .filter((x) => x.dealerId !== 0)
          .map((x) => ({
            id: 0,
            surveyId: sur.id,
            dealerLocationId: x.dealerId,
            googleReviewUrl: x.googleLink,
            facebookReviewUrl: x.facebookLink,
            isGoogleReviewActive: false,
            isFacebookReviewActive: false,
            passiveNPromotersReviewMessage: '',
            detractorsReviewMessage: '',
          }));
      } else {
        surveyLocation = locations
          .filter((x) => x.dealerId === dealerLocationId)
          .map((x) => ({
            id: 0,
            surveyId: sur.id,
            dealerLocationId: x.dealerId,
            googleReviewUrl: x.googleLink,
            facebookReviewUrl: x.facebookLink,
            isGoogleReviewActive: false,
            isFacebookReviewActive: false,
            passiveNPromotersReviewMessage: '',
            detractorsReviewMessage: '',
          }));
      }
    }
    return surveyLocation;
  }
  function handleSurveyPower(): void {
    if (survey !== undefined) {
      setSurvey({ ...survey, isAutomaticSurvey: !survey?.isAutomaticSurvey });
    }
  }
  async function handleSurveySendSave(): Promise<void> {
    if (survey) {
      router.push(`/surveys/${survey.id}/customers-group`);
    }
  }
  async function handleSurveyPreview(): Promise<void> {
    setShowSurveyPreview(true);
  }
  async function handleSurveyDuplicate(): Promise<void> {
    if (survey) {
      try {
        const response = await SurveyAPI.duplicateSurvey(survey.id);
        showSnackbar({ type: 'success', message: 'Survey duplicated' });
        router.push(`/surveys/${response.id}/designer#questions`);
      } catch (error) {
        showSnackbar({ type: 'error', message: 'Unable to duplicate survey' });
      }
    }
  }

  useEffect(() => {
    async function fetchSurvey(): Promise<void> {
      try {
        const surveyId = parseInt(query.id, 10);
        const response = await SurveyAPI.getSurvey(surveyId);
        setSurvey(response);
      } catch (error) {
        showSnackbar({ type: 'error', message: 'Unable to fetch survey against the dealer' });
        pushUrl('/404');
      }
    }
    fetchSurvey();
  }, [query.id, pushUrl]);

  useEffect(() => {
    debouncedSave(survey);
  }, [debouncedSave, survey]);

  const tabs = [
    {
      index: 0,
      label: 'Message',
      component: survey && (
        <SurveyMessage
          message={survey.message}
          disabled={survey.type === 'Auto' ? !survey.isActive : survey.isSent}
          onChange={handleSettingChange}
        />
      ),
    },
    {
      index: 1,
      label: 'Questions',
      component: survey && (
        <SurveyQuestions
          disabled={surveyDisabled}
          questions={survey.questions}
          onAdd={handleQuestionAdd}
          onDelete={handleQuestionDelete}
          onChange={handleQuestionChange}
        />
      ),
    },
    {
      index: 2,
      label: 'Thank-You Page',
      component: survey && (
        <SurveyThankYouPageSetting
          survey={survey}
          getLocationName={getLocationName}
          disabled={survey.type === 'Auto' ? !survey.isActive : survey.isSent}
          onChange={handleThankYouPageSettingChange}
        />
      ),
    },
    {
      index: 3,
      label: 'Settings',
      component: survey && (
        <SurveySettings survey={survey} locationOptions={locationOptions} onChange={handleSettingChange} />
      ),
    },
    {
      index: 4,
      label: 'Summary',
      component: <SurveySummary />,
    },
  ];

  return (
    <Box my={1} component="section">
      <Box>
        <Box py={1} pl={3}>
          <Typography variant="h4" id="surveyTitle" component="h1">
            {survey?.title}
          </Typography>
        </Box>
        <Toolbar variant="dense" style={{justifyContent:'center'}}>
          <Tabs
            value={tabIndex}
            onChange={(_, value: number): void => handleTabChange(value)}
            indicatorColor="primary"
            textColor="primary">
            {tabs.map((tab) => (
              <Tab id={`${tab.label}Tab`} key={tab.index} label={tab.label} />
            ))}
          </Tabs>
        </Toolbar>
        <Divider />
      </Box>
      <Container className={classes.container} maxWidth={mobile ? false : containerMaxWidth}>
        {tabs.map((tab) => (
          <TabPanel key={tab.index} index={tab.index} value={tabIndex}>
            {tab.component}
          </TabPanel>
        ))}
        {areFabButtonsVisible && (
          <Box className={classes.actionFabButtons}>
            {surveyDisabled && (
              <Fab
                id="duplicateSurveyBtn"
                onClick={handleSurveyDuplicate}
                title="Duplicate Survey"
                className={classes.actionFab}
                color="primary">
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <FileCopyIcon />
                  <Typography style={{ fontSize: '7.5px', marginTop: '-12px' }}>Duplicate</Typography>
                </Box>
              </Fab>
            )}
            <Fab
              id="previewSurveyBtn"
              title="Preview Survey"
              onClick={handleSurveyPreview}
              color="primary"
              className={classes.actionFab}>
              <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                <VisibilityIcon />
                <Typography style={{ fontSize: '8px', marginTop: '-12px' }}>Preview</Typography>
              </Box>
            </Fab>
            {!surveyDisabled && !automaticSurvey && (
              <Fab
                id="surveyToCustomerGroupsBtn"
                title="Continue to customer groups"
                onClick={handleSurveySendSave}
                color="primary"
                disabled={surveyDisabled}
                className={classes.actionFab}>
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <ForwardIcon />
                  <Typography style={{ fontSize: '8px', marginTop: '-10px' }}>Continue</Typography>
                </Box>
              </Fab>
            )}
            {survey?.type === 'Auto' && (
              <ToggleFab
                id="surveyToCustomerGroupsBtn"
                title={survey?.isAutomaticSurvey ? 'Turn Off Automatic Survey' : 'Turn On Automatic Survey'}
                onClick={handleSurveyPower}
                color={survey?.isAutomaticSurvey ? 'secondary' : 'primary'}
                className={classes.actionFab}>
                <Box sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <PowerSettingsNewIcon />
                  <Typography style={{ fontSize: '8px', marginTop: '-10px' }}>{survey?.isAutomaticSurvey?'Turn Off':'Turn On'}</Typography>
                </Box>  
              </ToggleFab>
            )}
          </Box>
        )}
        <SurveyPreview
          surveyId={survey?.id}
          open={showSurveyPreview}
          onClose={(): void => setShowSurveyPreview(false)}
        />
      </Container>
    </Box>
  );
}

export default SurveyPage;
