import { Box, Button, makeStyles } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import React, { ReactElement, useEffect } from 'react';

import PDFIcon from 'common/components/icons/pdf';
import TextIcon from 'common/components/icons/text';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { getMediaType, isHtmlSupportedAudio, isHtmlSupportedVideo, MediaType } from 'common/utils/media-type';

import ImageModalContext from '../image-modal-context';
import AudioIcon from 'common/components/icons/audio';
import Video from 'common/components/icons/video';
import { Media } from 'common/models/media';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';

const useMessageMediaStyle = makeStyles(() => ({
  media: {
    marginBottom: 2,
    marginRight: 8,
  },
  thumb: {
    cursor: 'pointer',
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 'auto',
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
}));

function VideoMedia({ src }: { src: string }): React.ReactElement {
  if (isHtmlSupportedVideo(src)) {
    return (
      <video controls width="250">
        <source src={src} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );
  }
  return <DownloadMedia src={src} type="Video" icon={<Video />} />;
}

function AudioMedia({ src }: { src: string }): React.ReactElement {
  if (isHtmlSupportedAudio(src)) {
    return (
      <audio controls>
        <source src={src} />
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    );
  }
  return <DownloadMedia src={src} type="Audio" icon={<AudioIcon />} />;
}

function ImageMedia({ src }: { src: string }): React.ReactElement {
  const classes: any = useMessageMediaStyle();
  const { openImageModal } = React.useContext(ImageModalContext);
  function handleOnClick(event: any): void {
    openImageModal(src);
  }
  return (
    <Box className={classes.thumb} onClick={handleOnClick}>
      <div className={classes.thumbInner}>
        <img src={src} className={classes.img} alt={src} />
      </div>
    </Box>
  );
}

function PDFMedia({ src }: { src: string }): React.ReactElement {
  return (
    <DownloadMedia
      src={src}
      type="PDF"
      icon={<DownloadForOfflineIcon style={{ width: '24px', color: 'grey', height: '24px', objectFit: 'cover' }} />}
    />
  );
}

function TextMedia({ src }: { src: string }): React.ReactElement {
  return <DownloadMedia src={src} type="Text" icon={<TextIcon />} />;
}

function DownloadMedia({
  src,
  type = '',
  icon = <AttachmentIcon />,
}: {
  src: string;
  type: string;
  icon: React.ReactElement;
}): React.ReactElement {
  // const [shortenedUrl, setShortenedUrl] = React.useState('');
  function handleOnClick(): void {
    window.open(src);
  }

  // useEffect(() => {
  //   const convertUrl = async () => {
  //     try {
  //       // Perform async operations here
  //       const url = await BroadCastAPI.shortenUrl(src);
  //       setShortenedUrl(url);
  //     } catch (error) {
  //       setShortenedUrl(`Download ${type.toLocaleLowerCase()} attachment`);
  //       console.error('Error:', error);
  //     }
  //   };
  //   convertUrl(); // Call the async function immediately inside useEffect
  // }, []);

  function extractFileName(url:string):string {
    const urlParts = url.split('/');
    let fileName = urlParts[urlParts.length - 1];
    fileName = decodeURIComponent(fileName.replace(/\+/g, ' '));
    return fileName;
  }

  return (
    <Button variant="outlined" size="small" style={{ textTransform: 'none' }} startIcon={icon} onClick={handleOnClick}>
       {extractFileName(src.toLocaleLowerCase())}
    </Button>
  );
  }

export function MessageMedia({ mediaSrc }: { mediaSrc: string | Media }): ReactElement {
  let mediaType = '';
  let url = '';
  if (typeof mediaSrc == 'string') {
    mediaType = getMediaType(mediaSrc);
    url = mediaSrc;
  } else {
    mediaType = getMediaType(mediaSrc.url);
    url = mediaSrc.url;
  }
  const mediaComponent: any = {
    [MediaType.Image]: ImageMedia,
    [MediaType.Video]: VideoMedia,
    [MediaType.Audio]: AudioMedia,
    [MediaType.Text]: TextMedia,
    [MediaType.PDF]: PDFMedia,
    [MediaType.Other]: DownloadMedia,
  };

  const MediaComponent = mediaComponent[mediaType];
  return <Box mr={2}>{MediaComponent && <MediaComponent src={url} />}</Box>;
}

export default MessageMedia;


