import React, { useEffect } from 'react';
import { Container, Link, Grid, Paper, Typography, Avatar } from '@mui/material';
import { BroadCast, BroadCastType } from 'components/broadcast/models/broadcast';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'common/components/tabs';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import { Divider } from '@material-ui/core';
import TargetCRMIcon from 'common/components/icons/targetCRM';

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    appBar: {
      position: 'relative',
      background: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-start',
      // alignItems: 'center',
      textAlign: 'justify'
    },
    fromEmail: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'justify',
    },
    headerTitleMobile: {
      display: 'flex',
      textAlign: 'justify',
    },
    subjectPreheader: {
      display: 'block',
    },
    mobileView: {},
    webView: {
      minHeight: '600px',
      overflow: 'scroll',
    },
    scrollWebView: {
      width: '100%',
      overflow: 'auto',
      display:'flex',
      height: '65vh',
      flexDirection:'column'
    },
    footer: {
      marginTop: 'auto',
      padding: '2rem',
      alignItems:'flex-end'
    },
  }),
);

const EmailBroadcastPreview = ({
  classes,
  dealerEmail,
  broadcast,
  getPreHeader,
}: {
  classes: any;
  dealerEmail: any;
  broadcast: BroadCast | undefined;
  getPreHeader: any;
}) => {
  const [value, setValue] = React.useState(0);
  const tabs = React.useMemo(
    () => [
      { index: 0, label: 'Desktop', component: DesktopView },
      { index: 1, label: 'Mobile', component: MobileView },
      { index: 2, label: 'Tablet', component: TabletView },
    ],
    [],
  );

  const handleTabChange = React.useCallback(
    (newValue: number): void => {
      setValue(newValue);
    },
    [tabs],
  );

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12} mt={2} sx={{ textAlign: '-webkit-center' }}>
          <Paper square>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              onChange={(_, newValue: any): void => handleTabChange(newValue)}>
              {tabs.map((tab: any) => (
                <Tab style={{ minWidth: '110px' }} key={tab.index} label={tab.label} />
              ))}
            </Tabs>
          </Paper>
          <Box mt={2} />
          {tabs.map((tab: any) => {
            const Component = tab.component;
            return (
              <TabPanel key={tab.index} value={value} index={tab.index}>
                <Component dealerEmail={dealerEmail} broadcast={broadcast} getPreHeader={getPreHeader} />
              </TabPanel>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

const DesktopView = ({
  dealerEmail,
  broadcast,
  getPreHeader,
}: {
  dealerEmail: any;
  broadcast: BroadCast | undefined;
  getPreHeader: any;
}) => {
  const classes: any = useStyles();

  return (
    <>
      <Paper style={{ height: '75vh', display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
        <Box sx={{ mb: '1rem', mt: '0.5rem' }}>
          <Box className={classes.fromEmail}>
            <Avatar sx={{ m: '0.8rem', width: '35px', height: '35px' }} />
            <Typography color={'#757575'}>Constellation,{dealerEmail.fromName + ' <noreply@trgt.io>'}</Typography>
          </Box>
          <Box className={classes.header} style={{marginBottom: '10px'}}>
            <Typography sx={{ color: 'grey', fontWeight: 'bold', ml: '4rem' }}>Subject:</Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.subject || '') }}
            />
          </Box>
          <Box className={classes.header}>
            <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', ml: '4rem' }}>
              Preheader:
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.preHeader || '') }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.scrollWebView}>
          <Box className={classes.header} sx={{ textAlign: 'justify', padding: '2rem' }}>
            <Typography style={{
                wordBreak: 'break-all',
                maxWidth: '100%',
                height: 'auto'
              }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.body || '') }} />
          </Box>
          <Box className={classes.footer}>
            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealerEmail.emailFooter || '') }} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

const MobileView = ({
  dealerEmail,
  broadcast,
  getPreHeader,
}: {
  dealerEmail: any;
  broadcast: BroadCast | undefined;
  getPreHeader: any;
}) => {
  const classes: any = useStyles();

  return (
    <>
      <Paper
        style={{
          height: '75vh',
          width: '373px',
          display: 'flex',
          overflow: 'auto',
          borderRadius: '20px',
          flexDirection: 'column',
        }}>
        <Box sx={{ mb: '1rem', mt: '0.5rem' }}>
          <Box className={classes.fromEmail}>
            <Avatar sx={{ m: '0.8rem', width: '35px', height: '35px' }} />
            <Typography variant="body2" color={'#757575'}>
              Constellation,{dealerEmail.fromName + ' <noreply@trgt.io>'}
            </Typography>
          </Box>
          <Box className={classes.header} style={{marginBottom: '10px'}}>
            <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', ml: '1rem' }}>
              Subject:
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.subject || '') }}
            />
          </Box>
          <Box className={classes.header}>
            <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', ml: '1rem' }}>
              Preheader:{' '}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.preHeader || '') }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.scrollWebView}>
          <Box className={classes.header} sx={{ textAlign: 'justify', padding: '2rem' }}>
            <Typography style={{
                wordBreak: 'break-all',
                maxWidth: '100%',
                height: 'auto'
              }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.body || '') }} />
          </Box>
          <Box className={classes.footer}>
            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealerEmail.emailFooter || '') }} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

const TabletView = ({
  dealerEmail,
  broadcast,
  getPreHeader,
}: {
  dealerEmail: any;
  broadcast: BroadCast | undefined;
  getPreHeader: any;
}) => {
  const classes: any = useStyles();
  return (
    <>
      <Paper
        style={{
          height: '75vh',
          width: '768px',
          display: 'flex',
          overflow: 'auto',
          borderRadius: '20px',
          flexDirection: 'column',
        }}>
        <Box sx={{ mb: '1rem', mt: '0.5rem' }}>
          <Box className={classes.fromEmail}>
            <Avatar sx={{ m: '0.8rem', width: '35px', height: '35px' }} />
            <Typography color={'#757575'}>Constellation,{dealerEmail.fromName + ' <noreply@trgt.io>'}</Typography>
          </Box>
          <Box className={classes.header} style={{marginBottom: '10px'}}>
            <Typography sx={{ color: 'grey', fontWeight: 'bold', ml: '4rem' }}>Subject:</Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.subject || '') }}
            />
          </Box>
          <Box className={classes.header}>
            <Typography variant="body1" sx={{ color: 'grey', fontWeight: 'bold', ml: '4rem' }}>
              Preheader:
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paddingLeft={'0.5rem'}
              paddingRight={'0.5rem'}
              marginTop={'2px'}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.preHeader || '') }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.scrollWebView}>
          <Box className={classes.header} sx={{ textAlign: 'justify', padding: '2rem' }}>
            <Typography style={{
                wordBreak: 'break-all',
                maxWidth: '100%',
                height: 'auto'
              }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(broadcast?.body || '') }} />
          </Box>
          <Box className={classes.footer}>
            <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dealerEmail.emailFooter || '') }} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default EmailBroadcastPreview;
