import { useState } from 'react';

import useDebouncedCallback from 'common/hooks/useDebouncedCallback';

export const useDelayedTextUpdate = (defaultValue: string, debounceUpdate: any): [string, (value: string) => void] => {
  const [value, setValue] = useState(defaultValue);
  const [debouncedcallback] = useDebouncedCallback((value) => {
    debounceUpdate(value);
  }, 1000);
  function setDelayedValue(newValue: string): void {
    setValue(newValue);
    debouncedcallback(newValue);
  }
  return [value, setDelayedValue];
};
