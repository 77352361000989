import { Box, Button, Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { Alert } from '@material-ui/lab';
import CustomerPhoneAPI from '../../api/CustomerPhoneAPI';
import { getCustomerPhoneNumberCount, getCustomerPhoneValidationCost } from './customerValidationSlice';
import { fetchDealerPhone } from 'store/dealerPhoneSlice';

interface ValidateType {
  CellPhone: boolean;
  AlternatePhone: boolean;
  Fax: boolean;
  LandLine: boolean;
}

function CustomerValidatePhone({ onClose }: { onClose(): void }): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [validatePhoneType, setValidatePhoneType] = useState<ValidateType>({ CellPhone: true, AlternatePhone: true, Fax: true, LandLine: true });
  const selectedCustomers = useAppSelector((state) => state.customers.selectedCustomers);

  const { error, customerPhoneNumbers } = useAppSelector((state) => ({
    loading: state.customerValidation.loadingValidationCost,
    error: state.customerValidation.errorLoadingValidationCost,
    perPhonePrice: state.customerValidation.validationCost ? state.customerValidation.validationCost : 0,
    customerPhoneNumbers: state.customerValidation.customerPhoneNumbers,
  }));

  const arr: any = [];
  let result = selectedCustomers.map(
    ({
      cellPhone,
      alternatePhone,
      fax,
      landline,
      cellPhoneVerificationStatus,
      alternatePhoneVerificationStatus,
      faxVerificationStatus,
      landLineVerificationStatus,
    }: any) => {
      if (cellPhone !== '' && cellPhone !== undefined && cellPhone !== null && !cellPhoneVerificationStatus) {
        arr.push({ phoneType: 'cellPhone', address: cellPhone, checked: true });
      }
      if (
        alternatePhone !== '' &&
        alternatePhone !== undefined &&
        alternatePhone !== null &&
        !alternatePhoneVerificationStatus
      ) {
        arr.push({ phoneType: 'alternatePhone', address: alternatePhone, checked: true });
      }
      if (fax !== '' && fax !== undefined && fax !== null && !faxVerificationStatus) {
        arr.push({ phoneType: 'fax', address: fax, checked: true });
      }
      if (landline !== '' && landline !== 'undefined' && landline !== null && !landLineVerificationStatus) {
        arr.push({ phoneType: 'landline', address: landline, checked: true });
      }
      return arr;
    },
  );
  result = arr;

  const allCustomers = useAppSelector((state) => state.customers.AllCustomersSelected);

  useEffect(() => {
    dispatch(fetchDealerPhone());
    if(allCustomers){
      dispatch(getCustomerPhoneNumberCount(null));
    }else{
      dispatch(getCustomerPhoneNumberCount(selectedCustomers.map(x=>x.id)));
    }
    
    // dispatch(getCustomerPhoneValidationCost(1));
  }, [dispatch, allCustomers]);

  const ValidateSelectedPhones = () => {
    const customerIds = selectedCustomers.map((x: any) => x.id);
    if (allCustomers) {
      CustomerPhoneAPI.validateAllPhoneNumbers(validatePhoneType)
        .then((resp) => {})
        .catch((error) => {});
    } else {
      CustomerPhoneAPI.valdiatePhoneNumberByCustomer(customerIds, validatePhoneType)
        .then((resp) => {})
        .catch((error) => {});
    }
    onClose();
  };

  let length = 0;
  if (allCustomers) {
    // if (validatePhoneType.LandLine) {
    //   length += customerPhoneNumbers.landLine;
    // }

    // if (validatePhoneType.CellPhone) {
    //   length += customerPhoneNumbers.cellPhone;
    // }

    // if (validatePhoneType.Fax) {
    //   length += customerPhoneNumbers.fax;
    // }

    // if (validatePhoneType.AlternatePhone) {
    //   length += customerPhoneNumbers.alternatePhone;
    // }
  } else {
    if (!validatePhoneType.LandLine) {
      result = result.filter((x: any) => x.phoneType !== 'landline');
    }

    if (!validatePhoneType.CellPhone) {
      result = result.filter((x: any) => x.phoneType !== 'cellPhone');
    }

    if (!validatePhoneType.Fax) {
      result = result.filter((x: any) => x.phoneType !== 'fax');
    }

    if (!validatePhoneType.AlternatePhone) {
      result = result.filter((x: any) => x.phoneType !== 'alternatePhone');
    }
    length = result.length;
  }

  if (error) {
    enqueueSnackbar('Unable to get validation cost', { variant: 'error' });
  }

  const dealerPhone = useAppSelector((state) => state.dealerPhone);
  let subTitle = '';
  if (length === 0) {
    subTitle = 'Please select the phone numbers that are not validated.';
  } else {
    subTitle = `You are about to validate ${length} phone numbers. Per number validation cost is 2 Credits. Your cost for all the selected phone numbers is ${
      length * 2
    } credits.`;
  }
  const message = `${dealerPhone.messageCount} Credits Left.`;
  const disableValidate = dealerPhone.messageCount / 2 < length;

  return (
    <>
      <Box mt={3}>
        <Typography variant="subtitle1">{message}</Typography>
        <Typography variant="h6">{subTitle}</Typography>
      </Box>
      <Grid item>
        <Box>
          <Typography variant="h6">Phone Numbers :</Typography>
        </Box>
        <Box mt={1}>
          {length === 1 && (
            <Grid container direction="row">
              <Grid item xs={3} />
              <Grid item xs={9}>
                <Grid item xs={12}>
                  {result.map((item: any, key: any) => {
                    if (item.address === '' || item.address === 'undefined' || item.address === null) {
                      return null;
                    }
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            value="secondary"
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label={`${item.address} (${item.phoneType})`}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box mt={1}>
          {allCustomers && (
            <Grid container direction="row">
              <Grid item xs={3} />
              <Grid item xs={9}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setValidatePhoneType({
                            ...validatePhoneType,
                            LandLine: !validatePhoneType.LandLine,
                          })
                        }
                        checked={validatePhoneType.LandLine}
                        value="secondary"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={'LandLine (' + customerPhoneNumbers[0] + ')'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setValidatePhoneType({
                            ...validatePhoneType,
                            CellPhone: !validatePhoneType.CellPhone,
                          })
                        }
                        checked={validatePhoneType.CellPhone}
                        value="secondary"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={'CellPhone (' + customerPhoneNumbers[0] + ')'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setValidatePhoneType({
                            ...validatePhoneType,
                            Fax: !validatePhoneType.Fax,
                          })
                        }
                        checked={validatePhoneType.Fax}
                        value="secondary"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={'Fax (' + customerPhoneNumbers[0] + ')'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setValidatePhoneType({
                            ...validatePhoneType,
                            AlternatePhone: !validatePhoneType.AlternatePhone,
                          })
                        }
                        checked={validatePhoneType.AlternatePhone}
                        value="secondary"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={'Alternate Phone (' + customerPhoneNumbers[0] + ')'}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        <Box mt={3}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button id="cancelValidation" variant="contained" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            {length > 0 && (
              <Grid item>
                <Button
                  id="proceed"
                  variant="contained"
                  disabled={disableValidate}
                  onClick={ValidateSelectedPhones}
                  color="primary"
                  type="submit">
                  Proceed
                </Button>
              </Grid>
            )}
          </Grid>
          {disableValidate && dealerPhone.dealerId !== 0 && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Box mt={2}>
                <Alert severity="error">
                  {dealerPhone.messageCount === 0
                    ? "You don't have enough credits."
                    : `you cannot validate more then ${dealerPhone.messageCount} Numbers`}
                </Alert>
              </Box>
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default CustomerValidatePhone;
