import { Box, Button, Checkbox, MenuItem, TextField } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';

import { LocationOption } from 'common/models/location-option';
import Department from 'components/setting/models/department';
import User from 'components/setting/models/user';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DepartmentForm({
  locations,
  dealerUsers,
  department,
  onCancel,
  onSubmit,
}: {
  locations: LocationOption[];
  dealerUsers: User[];
  department?: Department;
  onCancel(): void;
  onSubmit(values: any, createMore: boolean): void;
}): ReactElement {
  let defaultDealerLocation = 0;
  if (locations.length === 1) {
    defaultDealerLocation = locations[0].dealerLocationId;
  }
  const initialValues: {
    departmentId?: number;
    dealerLocationId?: number;
    departmentName?: string;
    users: User[];
  } = {
    departmentId: department?.departmentId || 0,
    dealerLocationId: department?.dealerLocationId || defaultDealerLocation,
    departmentName: department?.departmentName || '',
    users: department?.users || [],
  };
  const editMode = !!department?.departmentId;

  const validationSchema = Yup.object<any>().shape<any>({
    departmentName: Yup.string().required('Department name is required'),
    dealerLocationId: Yup.number()
      .notOneOf([0], 'Department location is required')
      .required('Department location is required'),
  });

  async function handleSubmit(values: any): Promise<void> {
    await onSubmit(values, false);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSaveAndCreateNew(): Promise<void> {
    await formik.validateForm();
    if (formik.isValid) {
      try {
        formik.setSubmitting(true);
        await onSubmit(formik.values, true);
        formik.setSubmitting(false);
        formik.resetForm();
      } catch (error) {
        formik.setSubmitting(false);
        console.log(error);
      }
    }
  }

  function handleUserChange(values: any[]): void {
    formik.setValues(
      {
        departmentId: formik.values.departmentId,
        departmentName: formik.values.departmentName,
        dealerLocationId: formik.values.dealerLocationId,
        users: values,
      },
      false
    );
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        autoFocus
        margin="dense"
        variant="outlined"
        name="departmentName"
        id="departmentName"
        label="Department Name"
        onChange={formik.handleChange}
        placeholder="Department Name"
        value={formik.values.departmentName}
        error={!!formik.errors.departmentName}
        helperText={formik.errors.departmentName}
        required
      />
      <TextField
        fullWidth
        select
        margin="dense"
        variant="outlined"
        name="dealerLocationId"
        disabled={editMode || locations.length === 1}
        inputProps={{ id: 'dealerLocationId' }}
        label="Location"
        onChange={formik.handleChange}
        value={formik.values.dealerLocationId}
        error={!!formik.errors.dealerLocationId}
        helperText={formik.errors.dealerLocationId}
        required>
        <MenuItem key={0} value={0}>
          Select Location
        </MenuItem>
        {locations.map((location) => (
          <MenuItem key={location.dealerLocationId} value={location.dealerLocationId}>
            {location.name}
          </MenuItem>
        ))}
      </TextField>
      <Autocomplete
        multiple
        id="tags-standard"
        value={formik.values.users}
        options={dealerUsers}
        onChange={(event: any, values: any): void => handleUserChange(values)}
        getOptionLabel={(option: any) => option.username}
        disableCloseOnSelect
        getOptionSelected={(option: User, value: User): boolean => option.crmUserId === value.crmUserId}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            {option.username}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name="users"
            id="users"
            variant="outlined"
            margin="dense"
            label="Users"
            placeholder="Select Users"
          />
        )}
      />
      <Box mt={2} id="formBtn" display="flex">
        <Box flexGrow={1} />
        {editMode ? (
          <Button
            id="saveDepartmentBtn"
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            disabled={formik.isSubmitting}>
            Save
          </Button>
        ) : (
          <>
            <Box mx={1}>
              <Button
                id="createDepartmentBtn"
                type="submit"
                color="primary"
                variant="contained"
                size="small"
                disabled={formik.isSubmitting}>
                Create
              </Button>
            </Box>
            <Box>
              <Button
                id="saveAndCreateNewDepartmentBtn"
                type="button"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSaveAndCreateNew}
                disabled={formik.isSubmitting}>
                Save & Create New
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}
