import React, { useState, useEffect, useRef } from 'react';
import {
  AudioTrackPublication,
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteVideoTrackPublication,
  Track,
  VideoTrack,
  VideoTrackPublication,
} from 'twilio-video';
import { TypeFormatFlags } from 'typescript';
import img from 'assets/images/userImage.png';
import { Box } from '@material-ui/core';
import InitialsAvatar from 'components/messaging/components/initials-avatar';

const RemoteParticipant = ({
  key,
  participant,
  videoTrackEnable,
  setVideoTrackEnable,
  audioTrackEnable,
  setAudioTrackEnable,
}: {
  key: string;
  participant: Participant;
  videoTrackEnable: boolean;
  setVideoTrackEnable: (videoStatus: boolean) => void;
  audioTrackEnable: boolean;
  setAudioTrackEnable: (audioStatus: boolean) => void;
}) => {
  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<RemoteAudioTrack[]>([]);

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  const trackpubsToTracksAudio = (trackMap: Map<Track.SID, AudioTrackPublication>) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  const trackpubsToTracksVideo = (trackMap: Map<Track.SID, VideoTrackPublication>) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
      setVideoTracks(trackpubsToTracksVideo(participant.videoTracks));   
      setAudioTracks(trackpubsToTracksAudio(participant.audioTracks));  
    const trackSubscribed = (track: any) => {
      console.log('track subscribed', track);
      if (track.kind === 'video') {
        setVideoTracks((videoTrack: any) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }
    };
    const trackUnsubscribed = (track: any) => {
      console.log('track unsubscribed', track);
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any) => videoTracks.filter((v: any) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any) => audioTracks.filter((a: any) => a !== track));
      }
    };
    const trackEnable = (a: any) => {
      let track = a.track;
      if (track) {
        console.log('track subscribed', track);
        if (track.kind === 'video') {
          setVideoTracks((videoTrack: any) => [...videoTracks, track as RemoteVideoTrack]);
        } else if (track.kind === 'audio') {
          setAudioTracks((audioTracks: any) => [...audioTracks, track as RemoteAudioTrack]);
        }
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', trackUnsubscribed);
    participant.on('trackEnabled', trackEnable);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (videoTracks) {
      videoTracks.forEach((videoTrack: any) => {
        if (videoTrack) {
          if (videoTrackEnable) {
            videoTrack.enable();
          } else {
            videoTrack.disable();
          }
        }
      });
    }
  }, [videoTrackEnable]);

  useEffect(() => {
    if (audioTracks) {
      audioTracks.forEach((audioTrack: any) => {
        if (audioTrack) {
          if (audioTrackEnable) {
            audioTrack.enable();
          } else {
            audioTrack.disable();
          }
        }
      });
    }
  }, [audioTrackEnable]);

  let userInfo = JSON.parse(participant.identity);
  return (
    <div className="participant" key={key} style={{ background: '#1F1F1F' }}>
      {videoTracks[0] && videoTracks[0].isEnabled && (
        <video style={{ maxHeight: '90vh', width: '100%', background: 'black' }} ref={videoRef} autoPlay={true} />
      )}
      {(!videoTracks[0] || !videoTracks[0].isEnabled) && (
        <Box
          style={{
            maxHeight: '90vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <InitialsAvatar
            name={userInfo.username?.replace(/[0-9]/g, '').replace('(', '').replace(')', '')}
            maxInitials={2}
            textSizeRatio={1.75}
            size="100"
            round
          />
        </Box>
      )}
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};
export default RemoteParticipant;
