import React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Accordion, AccordionDetails, AccordionSummary, Container, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import UserToggles from './UserToggles';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useAppDispatch, useAppSelector } from 'app';
import 'emoji-mart/css/emoji-mart.css';
import UserProfileAPI from './api/user-profile-api';
import { PageHeader } from 'common/components/layout';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { showSnackbar } from 'components/root-snackbar';
import { UserEmailAndChecks } from './models/userProfileData';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextLimitField from 'common/components/input-fields/TextLimitField';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backimg: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: '18.75rem',
    borderRadius: '0.75rem',
    backgroundImage: `url(http://via.placeholder.com/1920x1138?text=Cover+Image)`,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    overflow: 'hidden',
  },
}));
function UserProfileHeader() {
  return (
    <Box marginLeft={'1.5rem'}>
      <PageHeader title="User Profile" />
    </Box>
  );
}

function UserProfile() {
  const classes: any = useStyles();
  const theme = useTheme();

  const [userData, setUserData] = React.useState<any>(null);
  const { userFullName, roleName, dealerName } = useAppSelector((state) => state.user);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [notifState, setNotifState] = React.useState<{ dailySms: boolean; notiMsg: boolean ; taskNotif: boolean; leadNotif:boolean; convoNotif:boolean; detracNotif:boolean; paymentNotif:boolean }>({
    dailySms: false,
    notiMsg: false,
    taskNotif: false,
    leadNotif: false,
    convoNotif: false,
    detracNotif:false,
    paymentNotif:false
  });
  const dispatch = useAppDispatch();
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotifState({
      ...notifState,
      [event.target.name]: event.target.checked,
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email,
      userSignature: userData?.userSignature,
    },
    onSubmit: (values) => {
      const {
        dailySms = false,
        notiMsg = false,
        taskNotif = false,
        leadNotif = false,
        convoNotif = false,
        detracNotif = false,
        paymentNotif = false
      } = notifState;
      saveCrmUserData({
        ...values,
        dailySMSReportEmail: dailySms,
        notifyMessageEmail: notiMsg,
        leadAssignedNotification: leadNotif,
        taskAssignedNotification: taskNotif,
        conversationAssignedNotification: convoNotif,
        detractorNotification: detracNotif,
        paymentReceivedNotification: paymentNotif 
      });
      // alert(JSON.stringify({...values,'dailySMSReportEmail':dailySms,'notifyMessageEmail':notiMsg}, null, 2));
    },
  });

  async function getCrmUserData() {
    try {
      let response = await UserProfileAPI.getUserData();
      setUserData(response);
      if (response?.email == null) {
        setUserData((prev: any) => {
          return { ...prev, email: '' };
        });
      }
      setNotifState((prev: any) => {
        return {
          ...prev,
          dailySms: response?.dailySMSReportEmail,
          notiMsg: response?.notifyMessageEmail,
          leadNotif: response?.leadAssignedNotification,
          taskNotif: response?.taskAssignedNotification,
          convoNotif: response?.conversationAssignedNotification,
          detracNotif: response?.detractorNotification,
          userSignature: response?.userSignature,
          paymentNotif: response?.paymentReceivedNotification
        };
      });
      setLoading(false);
    } catch (e: any) {
      dispatch(showSnackbar({ type: 'error', message: 'Unable to retrieve user information!' }));
      setLoading(false);
      return;
    }
  }
  async function saveCrmUserData(crmUserEmailAndData: UserEmailAndChecks) {
    try {
      setLoading(true);
      let response = await UserProfileAPI.updateUserProfile(crmUserEmailAndData);
      setUserData(response);
      setLoading(false);
      dispatch(showSnackbar({ type: 'success', message: 'User profile settings saved successfully!' }));
    } catch (e: any) {
      setLoading(false);
      if(!crmUserEmailAndData.email){
        formik.setErrors({email:'Email is required!'})
      }
      else if(e.includes("Duplicate")){
        formik.setErrors({email:'Email already taken!'})
      }else{
        dispatch(showSnackbar({ type: 'error', message: "Sorry, something went wrong!" }));
      }
      return;
    }
  }

  React.useEffect(() => {
    getCrmUserData();
  }, []);
  return (
    <Container className={classes.container} maxWidth="md">
      <UserProfileHeader />
      <Box mb={5} mt={2}>
        <Card
          sx={{
            borderRadius: '0.75rem',
            position: 'relative',
            mx: 3,
            py: 2,
            px: 2,
          }}>
          <Grid container margin={0.5} spacing={3} alignItems="center" justifyContent={'left'} mb={2}>
            <Grid item>
              <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                <Avatar sx={{ width: 100, height: 100 }} alt="profile-image" />
              </StyledBadge>
            </Grid>
            <Grid item>
              <Box height="100%" mt={0.5} lineHeight={1}>
                <Typography variant="h5" fontWeight="medium">
                  {userFullName}
                </Typography>
                <Typography variant="button" color="text" fontWeight="regular">
                  {roleName} / {dealerName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Grid container spacing={1} padding={'1rem'}>
                  <Grid item sm={12} md={6} lg={6} xl={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      defaultValue={userFullName}
                      label="Name"
                    />
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xl={6} xs={12} height={'4rem'}>
                    <TextField
                      fullWidth
                      autoFocus
                      size="small"
                      variant="outlined"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextLimitField
                      fullWidth
                      multiline
                      rows={3}
                      label='Signature'
                      name="userSignature"
                      value={formik.values.userSignature}
                      variant="outlined"
                      characters={160}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container padding={'1rem'}>
                  <Grid item mt={1} md={12} lg={12} sm={12}>
                    <UserToggles notifState={notifState} handleToggle={handleToggle} />
                  </Grid>
                </Grid>

                <Grid container textAlign={'right'}>
                  <Grid item xs={12} p={1} md={12} lg={12} sm={12} ml={'auto'}>
                    <Button size="medium" type="submit" variant="contained">
                      SAVE
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </Card>
      </Box>
    </Container>
  );
}

export default UserProfile;
