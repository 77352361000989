import React from 'react';

import { Location } from 'common/models/location';
import { getAllLocationOption, LocationOption } from 'common/models/location-option';
import DealerAPI from 'components/authentication/api/dealer-api';


/**
 * useLocations hook returns location for the using dmsDealerIdCrm
 */
const CACHE: { [key: number]: Location[] } = {};
function useLocations(dmsDealerIdCrm: number): {
  loading: boolean;
  locations?: Location[];
  locationOptions?: LocationOption[];
  defaultLocationOption?: LocationOption;
  getLocationName(dealerLocationId: number): string | undefined;
} {
  const [locations, setLocations] = React.useState<Location[] | undefined>(undefined);
  const [defaultLocationOption, setDefaultLocationOption] = React.useState<LocationOption | undefined>({
    dealerLocationId: -1,
    name: 'Select location',
  });
  const [locationOptions, setLocationOptions] = React.useState<LocationOption[] | undefined>([
    { dealerLocationId: -1, name: 'Select location' },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function fetchDealershipLocations(): Promise<void> {
      try {
        setLoading(true);
        const response = await DealerAPI.getDealerSettings(dmsDealerIdCrm);
        setLocations(response.dealerLocationSettings);
        CACHE[dmsDealerIdCrm] = response.dealerLocationSettings;
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (CACHE[dmsDealerIdCrm]) {
      setLocations(CACHE[dmsDealerIdCrm]);
    } else {
      fetchDealershipLocations();
    }
  }, [dmsDealerIdCrm]);

  React.useEffect(() => {
    if (locations) {
      const isMultiStore = locations.length !== 1;
      let options = locations.map((location) => ({ name: location.locationName, dealerLocationId: location.dealerId }));
      let defaultOption = options[0];
      if (isMultiStore) {
        options = [getAllLocationOption(), ...options];
        defaultOption = getAllLocationOption();
      }
      setDefaultLocationOption(defaultOption);
      setLocationOptions(options);
    }
  }, [locations]);

  function getLocationName(dealerLocationId: number): string | undefined {
    return locationOptions?.find((option) => option.dealerLocationId === dealerLocationId)?.name;
  }

  React.useDebugValue({
    locations,
  });

  return { loading, locations, locationOptions, defaultLocationOption, getLocationName };
}

export default useLocations;