import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import React from 'react';

import { SmallCircularProgress } from 'common/components/loaders';
import MediaGalleryAPI from 'components/broadcast/api/mediaGallery-api';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Pagination } from '@mui/material';
export const useStyles = makeStyles(() => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  actionIcon: {
    display: 'flex',
    alignItems: 'flex-end',
    justify: 'flex-end',
  },
}));

export function ImageGallery({
  onAdd,
  disabled,
  refresh,
}: {
  onAdd: (galleryImage: GalleryImage) => void;
  disabled: boolean;
  refresh: boolean;
}): React.ReactElement {
  const classes: any = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [confirmDeleteItemId, setConfirmDeleteItemId] = React.useState<number>(0);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);
  const [totalItems, setTotalItems] = React.useState(1);
  const [filesLoading, setFilesLoading] = React.useState<boolean>(false)
  const [filesLoaded, setFilesLoaded] = React.useState<boolean>(false)

  const handleFilesExpanded = () => {
    setFilesLoaded(true);
  }

  const handlePanelChange =
    (panel: string) =>
    (event: React.ChangeEvent<{}>, newExpanded: boolean): void => {
      setExpanded(newExpanded ? panel : false);
    };

  async function fetchGalleryImages(page: number, rowsPerPage: number): Promise<void> {
    try {
      const galleryImageResponse = await MediaGalleryAPI.getImageGallery(page, rowsPerPage);
      setGalleryImages(galleryImageResponse);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangePage = async (event: unknown, newPage: number): Promise<void> => {
    let currentPage = newPage
    fetchGalleryImages(currentPage, rowsPerPage)
    setPage(currentPage)
  };

  const fetchCount = async () => {
    try {
    let count = await MediaGalleryAPI.getMediaCount();
    setTotalItems(count);
    } catch (err) {
      console.log(err)
    } 
  };

  const onPageLoad = async () => {
    setFilesLoading(true)
    await fetchCount();
    await fetchGalleryImages(page, rowsPerPage);
    setFilesLoading(false)
  }
  React.useEffect(() => {
    if (filesLoaded) {
    onPageLoad();
    }
  }, [refresh, filesLoaded]);

  async function deleteGalleryImage(galleryImage: GalleryImage): Promise<void> {
    try {
      setIsDeleting(true);
      await MediaGalleryAPI.deleteMedia(galleryImage.id);
      fetchGalleryImages(page, rowsPerPage);
      setConfirmDeleteItemId(0);
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
    }
  }

  return (
    <Accordion square expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')} onClick={handleFilesExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header" >
        <Grid container alignItems="flex-start" justifyContent="space-between" direction="row">
          <Grid item container direction="row"  alignItems="center" style={{ display: 'flex' }}>
            <Typography variant="h4" style={{ padding: '20px' }}>
              {expanded  ? <FolderOpenIcon /> : <FolderIcon />}
              &nbsp;&nbsp;My Files
            </Typography>
            {filesLoading && <CircularProgress  size={20} style={{marginTop: '5px'}}/>}         
          </Grid>
          <Grid />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ImageList cols={3} style={{ width: '100%' }}>
          {galleryImages.map((galleryImage: GalleryImage, index: number) => (
            <ImageListItem key={index}>
               {galleryImage.mimeType ==="application/pdf" ? 
               <img src="/pdf.png" alt={galleryImage.fileName} /> 
               : 
               <img src={galleryImage.mediaUrl}  alt={galleryImage.fileName} /> }
              <ImageListItemBar
                classes={{ actionIcon: classes.actionIcon }}
                title={galleryImage.editable ? 'Confirm Delete ?' : galleryImage.fileName}
                actionIcon={
                  <>
                    {galleryImage.id !== confirmDeleteItemId && (
                      <>
                        <IconButton
                          onClick={(): void => setConfirmDeleteItemId(galleryImage.id)}
                          aria-label={`Add ${galleryImage.fileName}`}
                          className={classes.icon}>
                          <DeleteIcon />
                        </IconButton>
                        {!disabled && (
                          <IconButton
                            onClick={(): void => onAdd(galleryImage)}
                            aria-label={`Add ${galleryImage.fileName}`}
                            className={classes.icon}>
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                    {galleryImage.id === confirmDeleteItemId && !isDeleting && (
                      <>
                        <IconButton
                          onClick={(): void => setConfirmDeleteItemId(0)}
                          aria-label={`Add ${galleryImage.fileName}`}
                          className={classes.icon}>
                          <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                          onClick={async (): Promise<void> => deleteGalleryImage(galleryImage)}
                          aria-label={`Add ${galleryImage.fileName}`}
                          className={classes.icon}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                    {galleryImage.id === confirmDeleteItemId && isDeleting && <SmallCircularProgress />}
                  </>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </AccordionDetails>
      <Grid item container justifyContent='flex-end' style={{ marginTop: '10px', marginBottom: '10px'}}>
        <Pagination
          className={classes.pagination}
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Accordion>
  );
}

export default ImageGallery;
