import { Box, Button, Checkbox, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import Department from 'components/setting/models/department';
import { TaskType } from 'components/lead/models/task';
import TaskboardAPI from 'components/lead/components/task-board/taskboard-api';
import { DepartmentDropdown } from 'components/layouts/DashboardLayout/department-filter-drawer';
interface TaskTypeForm {
  id?: number;
  departmentId: number;
  body: string;
}
export default function TaskTypeForm({
  taskType,
  departments,
  taskTypeDepartments,
  onCancel,
  onSubmit,
}: {
  taskTypeDepartments: Department[];
  departments: Department[];
  taskType: TaskType | null;
  onCancel(): void;
  onSubmit(values: any, createMore: boolean): void;
}): React.ReactElement {

  const [taskCategories, setTaskCategories] = React.useState([]);
  const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>(taskTypeDepartments.map(x => x.departmentId) || []);

  const getTaskCategoryList = useCallback(async (): Promise<void> => {
    let data = await TaskboardAPI.getTaskCategories();
    setTaskCategories(data);
  }, [1]);

  React.useEffect(() => {
    getTaskCategoryList();
  }, [getTaskCategoryList]);


  const initialValues = {
    id: taskType?.id || 0,
    value: taskType?.value || '',
    taskCategoryId: taskType?.taskCategoryId || null,
    departments: taskType?.departments || [],
  };



  const editMode = taskType && taskType.id != 0;
  const validationSchema = Yup.object<any>().shape<any>({
    value: Yup.string().required('Value is required'),
    taskCategoryId: Yup.number().nullable().required("Category is required"),
  });

  async function handleSubmit(values: any): Promise<void> {
    if (selectedDepartments.length == 0) {
      formik.setFieldError("departments", 'at least one department is required');
      return;
    }
    let deps = selectedDepartments.map((x: any) => ({ departmentId: x, departmentName: "" }));
    formik.setFieldValue("departments", deps);
    formik.values.departments = deps;
    console.log("Formik : ", values);
    await onSubmit(values, false);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  async function handleSaveAndCreateNew(): Promise<void> {
    await formik.validateForm();
    if (selectedDepartments.length == 0) {
      formik.setFieldError("departments", 'at least one department is required');
      return;
    }
    let deps = selectedDepartments.map((x: any) => ({ departmentId: x, departmentName: "" }));
    formik.setFieldValue("departments", deps);
    formik.values.departments = deps;
    formik.setSubmitting(true);
    await onSubmit(formik.values, true);
    formik.setSubmitting(false);
    setSelectedDepartments([]);
    formik.resetForm();
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Box>
        <Box mt={2} id="formBtn" display="flex">
          <Box flexGrow={1} />
          {editMode ? (
            <Button
              id="saveQuickMessageBtn"
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              disabled={formik.isSubmitting}>
              Save
            </Button>
          ) : (
            <>
              <Box mx={1}>
                <Button
                  id="createQuickMessageBtn"
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={formik.isSubmitting}>
                  Create
                </Button>
              </Box>
              <Box>
                <Button
                  id="saveAndCreateNewQuickMessageBtn"
                  type="button"
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleSaveAndCreateNew}
                  disabled={formik.isSubmitting}>
                  Save & Create New
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              fullWidth
              rows={3}
              autoFocus
              margin="dense"
              variant="outlined"
              name="value"
              id="value"
              label="Title"
              onChange={formik.handleChange}
              placeholder="Title"
              value={formik.values.value}
              error={!!formik.errors.value}
              helperText={formik.errors.value}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Box mt={1} ml={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="taskcategory" margin="dense" variant="outlined">Task Category</InputLabel>
                <Select
                  labelId="taskCategory"
                  id="taskCategory"
                  value={formik.values.taskCategoryId}
                  error={!!formik.errors.taskCategoryId}
                  label="Task Category"
                  margin="dense"
                  onChange={(e: any, opt: any) => {
                    formik.handleChange(e);
                    formik.setFieldValue('taskCategoryId', opt.props.value);
                  }}
                >
                  {taskCategories?.map((x: any) => (
                    <MenuItem value={x.id}>{x.value}</MenuItem>
                  ))}
                </Select>
                {!!formik.errors.taskCategoryId && <FormHelperText style={{ color: "#e53935" }}>{formik.errors.taskCategoryId}</FormHelperText>}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <DepartmentDropdown
          setSelectedDepartments={(e) => {setSelectedDepartments(e); if(e && e.length > 0) {formik.setFieldValue('departments',e )}  }}
          selectedDepartments={selectedDepartments}
          assignedDepartments={departments}
          error={!!formik.errors.departments}
          helperText={formik.errors.departments}
          placeholder="Select Departments"
        />
      </Box>
    </form>
  );
}
