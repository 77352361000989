import { Box, Container, Dialog, Grid, GridList, GridListTile, Paper, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { ReactElement, useEffect } from 'react';
import EmailBroadcastPreview from './email-broadcast-preview';
import { BroadCast, BroadCastType } from 'components/broadcast/models/broadcast';


const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    appBar: {
      position: 'relative',
      background: theme.palette.grey[100],
      color: theme.palette.common.black,
    },
    title: {
      marginLeft: theme.spacing(2),
      flexGrow: 1,
      textAlign: 'center',
    },
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
        padding: theme.spacing(2, 0),
      },
    },
    innerContainer: {
      height: '100%',
      paddingTop: theme.spacing(2),
    },
    paper: {
      height: '80%',
      width: '50vw',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: `calc(100% - ${theme.spacing(2) * 2}px)`,
      },
      '@media (max-height: 500px)': {
        height: 'fit-content',
        padding: theme.spacing(5, 0),
      },
    },
    paper2: {
      height: '80%',
      width: '80vw',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: `calc(100% - ${theme.spacing(2) * 2}px)`,
      },
      '@media (max-height: 500px)': {
        height: 'fit-content',
        padding: theme.spacing(5, 0),
      },
    },
  }),
);

export const BroadcastPreview = ({
  broadcast,
  broadcastType,
  dealerEmail,
  open,
  onClose,
}: {
  broadcast: BroadCast | undefined;
  broadcastType: BroadCastType;
  dealerEmail : any;
  open: boolean;
  onClose(): void;
}): ReactElement | null => {
  const classes: any = useStyles();
  const regX = /(<([^>]+)>)/gi;

  if (broadcast === undefined) return null;



  const GetPreHeader = (): string => {
    if (broadcast) {
      if (broadcast.preHeader) {
        return broadcast.preHeader;
      }
      let body = '';
      if (broadcast.body) {
        body = broadcast.body.replace(regX, '');
        if (body.length > 10) {
          const preheader = body.split('10')[0];
          return preheader;
        }
      }
      return body;
    }
    return '';
  };

  const EmailPreview =
    broadcast !== undefined ? (
      <EmailBroadcastPreview classes={classes} dealerEmail={dealerEmail}  broadcast={broadcast} getPreHeader={GetPreHeader}/>
    ) : null;
  const SMSandMMSPreview =
    broadcast !== undefined ? (
      <Container className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <Container className={classes.innerContainer}>
            <Grid container>
              <Typography>{broadcast.body}</Typography>
            </Grid>
            <Grid container justifyContent="flex-start">
              <GridList cols={6} style={{ width: '100%' }}>
                {broadcast.mmsMedia &&
                  broadcast.mmsMedia.map((media) => (
                    <GridListTile key={media.id} style={{ height: '150px' }}>
                      <img src={media.mediaUrl} alt={media.mediaUrl} />
                    </GridListTile>
                  ))}
              </GridList>
            </Grid>
          </Container>
        </Paper>
      </Container>
    ) : null;
  return (

    <Dialog
      fullScreen
      disableEnforceFocus
      className={classes.root}
      classes={{ paper: classes.root }}
      open={open}
      onClose={onClose}>
      <AppBar elevation={1} className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton id="goBackBtn" edge="start" color="default" onClick={onClose} aria-label="close">
            <KeyboardBackspaceIcon />
          </IconButton>
          <Box className={classes.title}>
            <Typography variant="h4" component="h4" id="broadcastPreviewTitle">
              Broadcast Preview
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      {broadcastType === 'EMAIL' ? EmailPreview : SMSandMMSPreview}
    </Dialog>
  );
};

export default BroadcastPreview;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

