import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { teal } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../app';
import { SmallCircularProgress } from '../../../../common/components/loaders';
import { showSnackbar } from '../../../../components/root-snackbar';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SubjectIcon from '@mui/icons-material/Subject';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PinDropIcon from '@mui/icons-material/PinDrop';
import moment from 'moment';
import DoneIcon from '@mui/icons-material/Done';
import ArchiveIcon from '@mui/icons-material/Archive';
import {
  fetchTasks,
  fetchTasksPage,
  SetSelectedTasks,
  SetAllSelectedTasks,
  showTaskDrawer,
  UpdateSelectedTask,
  setFilterDates,
  setAssignedUsers,
  rescheduleTaskList,
} from './taskboardSlice';
import { TaskListObject, TaskListRescheduleRequest, UserTaskStatus } from 'components/lead/models/task';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { styled } from '@material-ui/core/styles';
import { spacing } from '@material-ui/system';
import MuiButton from '@material-ui/core/Button';
import useLocations from 'common/hooks/useLocations';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateRange } from '@mui/lab/DateRangePicker';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import { DateRangePickerDayProps } from '@mui/lab/DateRangePickerDay';
import DateRangePickerDay from '@mui/lab/DateRangePickerDay';
import { pickersDayClasses } from '@mui/lab/PickersDay';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import { fetchSelectedCustomer } from 'components/customer/components/Customers/customersSlice';
import TaskContext from './tasks-context';
import { TaskArchiveDrawer } from './TaskDrawer/TaskArchiveDrawer';
import FilterListIcon from '@mui/icons-material/FilterList';
import DealEditDrawer from 'components/deals/drawer/deal-edit-drawer';
import { DealItem } from 'components/deals/model/dealsInterfaces';
import TaskRootDrawer from './TaskDrawer/TaskRootDrawer';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): 1 | -1 | 0 {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

export const SButton = styled(MuiButton)(spacing);

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | null }, b: { [key in Key]: number | string | null }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof TaskListObject;
  label: string;
  numeric: boolean;
  icon: any;
}

interface EnhancedTableProps {
  classes: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TaskListObject) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onSelectVisibleClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps): ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { locations } = useLocations(dmsDealerIdCrm);

  let headCells: HeadCell[] = [
    {
      id: 'taskSubject',
      numeric: false,
      disablePadding: true,
      label: 'Subject',
      icon: SubjectIcon,
    },
    {
      id: 'taskType',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      icon: PhoneIphoneIcon,
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: 'Customer',
      icon: PersonIcon,
    },
    {
      id: 'dealTitle',
      numeric: false,
      disablePadding: false,
      label: 'Deal',
      icon: FilterListIcon,
    },
    {
      id: 'dueDate',
      numeric: true,
      disablePadding: false,
      label: 'Due',
      icon: ScheduleIcon,
    },
    {
      id: 'assignedUserId',
      numeric: false,
      disablePadding: false,
      label: 'Assignment',
      icon: AssignmentIcon,
    },
  ];

  if (locations && locations.length > 1) {
    headCells.push({
      id: 'dealerLocationName',
      numeric: true,
      disablePadding: false,
      label: 'Location',
      icon: PinDropIcon,
    });
  }

  const { classes, onSelectAllClick, onSelectVisibleClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof TaskListObject) =>
    (event: React.MouseEvent<unknown>): void => {
      onRequestSort(event, property);
    };

  const AllTasksSelected = useAppSelector((state: any) => state.tasks.AllTasksSelected);

  return (
    <TableHead style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
      <TableRow>
        <TableCell padding="checkbox">
          <FormControl size="small" margin="dense">
            <InputLabel />
            <Select autoWidth style={{ padding: '0px', margin: '0px' }} margin="dense" variant="outlined">
              {/* <MenuItem dense>
                <Checkbox size="small" checked={AllTasksSelected} onChange={onSelectAllClick} /> Select All
              </MenuItem> */}
              <MenuItem dense>
                <Checkbox
                  size="small"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectVisibleClick}
                />{' '}
                Select Visible
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        {headCells.map((headCell: HeadCell) => {
          const Icon = headCell.icon;
          return (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <>
                <Grid container spacing={1}>
                  <Grid item>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.icon && <Icon />}
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </Grid>
                </Grid>
              </>
            </TableCell>
          );
        })}

        <TableCell key={'actions'} align="left" padding={'default'} sortDirection={false}>
          <>
            <Grid container spacing={1}>
              <Grid item>
                {/* <TableSortLabel>
                   Actions
                </TableSortLabel> */}
              </Grid>
            </Grid>
          </>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.common.black,
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: teal[500],
          },
    title: {
      flex: '1 1 100%',
      fontWeight: 'bold',
    },
    'Mui-disabled': {
      cursor: 'default',
      'pointer-events': 'none',
      color: 'grey',
    },
  }),
);

interface TaskTableToolbarProps {
  numSelected: number;
  validPhoneNumbers: number;
}

function getNextWeekDay(startDate: any, dayOfWeek: any) {
  let dayOffset = dayOfWeek > startDate.getDay() ? dayOfWeek - startDate.getDay() : dayOfWeek - startDate.getDay() + 7;

  startDate.setDate(startDate.getDate() + dayOffset);

  return startDate;
}

const TaskTableToolbar = (props: TaskTableToolbarProps): ReactElement => {
  const classes: any = useToolbarStyles();
  const { numSelected, validPhoneNumbers } = props;
  const dispatch = useAppDispatch();
  let selected = useAppSelector((state: any) => state.tasks.selectedTasks);
  const AllTasksSelected = useAppSelector((state: any) => state.tasks.AllTasksSelected);
  const taskIsArchived = useAppSelector((state: any) => state.tasks.isArchived);

  const { count } = useAppSelector(
    (state: any) => ({
      count: state.tasks.totalTasks,
    }),
    shallowEqual,
  );

  let totalSelected = numSelected;
  let phoneCount = validPhoneNumbers;
  if (AllTasksSelected) {
    totalSelected = count;
    phoneCount = 1;
  }

  const fromDate = useAppSelector((state: any) => state.tasks.fromDate);
  const toDate = useAppSelector((state: any) => state.tasks.toDate);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const renderWeekPickerDay = (date: Date, dateRangePickerDayProps: DateRangePickerDayProps<Date>) => {
    return (
      <DateRangePickerDay
        sx={{
          [`&&.${pickersDayClasses.today}`]: {
            backgroundColor: '#939afa',
            color: 'white',
            border: '#939afa',
          },
        }}
        {...dateRangePickerDayProps}
      />
    );
  };
  const selectionRange: any = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  // const handleValidatePhoneNumbers = () => {
  //   dispatch(
  //     showCustomerDrawer({
  //       drawerType: 'CUSTOMER_PHONE_VALIDATE',
  //       drawerProps: {},
  //     }),
  //   );
  // };

  const [archiveDrawer, setArchiveDrawer] = useState(false);
  const handleAssignTasks = () => {
    dispatch(UpdateSelectedTask(undefined));
  };
  const handleRescheduleTasks = () => {
    dispatch(UpdateSelectedTask(undefined));
    dispatch(showTaskDrawer({ drawerType: 'TASKLIST_RESCHEDULE', drawerProps: {} }));
  };

  const handleDoneTasks = () => {
    dispatch(UpdateSelectedTask(undefined));
    dispatch(showTaskDrawer({ drawerType: 'TASKLIST_DONE', drawerProps: {} }));
  };
  const handleUnarchiveTasks = () => {
    let taskArchiveList: TaskListRescheduleRequest = {
      taskIds: selected.map((x: any) => x.taskId),
      isArchived: false,
      taskArchiveReason: '',
    };
    dispatch(rescheduleTaskList(taskArchiveList));
    dispatch(showSnackbar({ type: 'success', message: 'Selected tasks unarchived' }));
    dispatch(SetSelectedTasks([]));
    dispatch(SetAllSelectedTasks(false));
  };
  // const onAddTaskBtn = () => dispatch(showTaskDrawer({ drawerType: 'TASK_ADD', drawerProps: {} }));
  return (
    <Toolbar
      variant="dense"
      className={clsx(classes.root, {
        [classes.highlight]: totalSelected > 0,
      })}>
      {totalSelected > 0 && (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          Selected {totalSelected} {totalSelected > 1 ? 'Tasks' : 'Task'}
        </Typography>
      )
      //  : (
      //   <Tooltip title="Add Task">
      //     <IconButton id="addTaskBtn" onClick={onAddTaskBtn} style={{ marginLeft: '-15px' }}>
      //       <AddIcon />
      //     </IconButton>
      //   </Tooltip>
      // )
      }
      {totalSelected > 0 ? (
        <>
          {!taskIsArchived && (
            <>
              <Button
                id="doneTask"
                variant="contained"
                color="primary"
                style={{ width: '15%' }}
                onClick={handleDoneTasks}
                size="small"
                startIcon={<ShortcutIcon fontSize="small" />}>
                <Typography variant="caption" noWrap>
                  Mark Done
                </Typography>
              </Button>
              <Box ml={2}>
                <Button
                  id="rescheduleTask"
                  variant="contained"
                  color="primary"
                  onClick={handleRescheduleTasks}
                  size="small"
                  startIcon={<CalendarTodayIcon fontSize="small" />}>
                  <Typography variant="caption" noWrap>
                    Edit Tasks
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          <Box ml={2}>
            <Button
              id="archiveTask"
              variant="contained"
              color="primary"
              //style={{ width: '15%' }}
              onClick={() => {
                taskIsArchived ? handleUnarchiveTasks() : setArchiveDrawer(true);
              }}
              size="small"
              startIcon={<ArchiveIcon fontSize="small" />}>
              <Typography variant="caption" noWrap>
                {taskIsArchived ? 'Unarchive' : 'Archive'}
              </Typography>
            </Button>
          </Box>
        </>
      ) : (
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Box>
              <Button variant="contained" color="primary" size="small" onClick={handleClick}>
                Date Range
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Box mt={1} ml={1}>
                      <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            let endDate = new Date();
                            endDate.setHours(23, 59, 59, 999);
                            let dateRange: DateRange<Date> = [null, endDate];
                            setValue(dateRange);
                            dispatch(setFilterDates({ toDate: endDate, fromDate: null }));
                          }}>
                          Today
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            let curr = new Date(); // get current date
                            let prev = new Date(); // get current date
                            let month = prev.getMonth();
                            let prevMonthDays = new Date(prev.getFullYear(), month - 1, 0).getDate();
                            let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                            let last = first + 6; // last day is the first day + 6
                            let time = prev.getHours() + ':' + prev.getMinutes() + ':' + prev.getSeconds();
                            let prevMonthDate = new Date(
                              `${prev.getFullYear()}` +
                                '-' +
                                `${prev.getMonth()}` +
                                '-' +
                                `${prevMonthDays + last - 6}` +
                                ' ' +
                                `${time}`,
                            );
                            let firstday = first <= 0 ? prevMonthDate : new Date(curr.setDate(first)); //.toUTCString();
                            let lastday = new Date(curr.setDate(last)); //.toUTCString();
                            let dateRange: DateRange<Date> = [firstday, lastday];
                            setValue(dateRange);
                            dispatch(setFilterDates({ toDate: lastday, fromDate: firstday }));
                          }}>
                          This Week
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            let nextSunday = getNextWeekDay(new Date(), 0);
                            let nextSaturday = getNextWeekDay(new Date(), 6);
                            nextSaturday.setDate(nextSunday.getDate() + 6);
                            let dateRange: DateRange<Date> = [nextSunday, nextSaturday];
                            setValue(dateRange);
                            dispatch(setFilterDates({ toDate: nextSaturday, fromDate: nextSunday }));
                          }}>
                          Next Week
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            let dateRange: DateRange<Date> = [null, null];
                            setValue(dateRange);
                            dispatch(setFilterDates({ toDate: null, fromDate: null }));
                          }}>
                          Reset
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        label="date range"
                        value={value}
                        onChange={(newValue) => {
                          newValue[1]?.setHours(23, 59, 59);
                          setValue(newValue);
                          dispatch(setFilterDates({ toDate: newValue[1], fromDate: newValue[0] }));
                        }}
                        renderDay={renderWeekPickerDay}
                        renderInput={(startProps: any, endProps: any) => (
                          <>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Popover>
            </Box>
          </Grid>
        </Grid>
      )}
      <TaskArchiveDrawer open={archiveDrawer} selectedTasks={selected} onClose={() => setArchiveDrawer(false)} />
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme): any =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableRow: {},
    pagination: {
      width: 'fit-content',
      marginLeft: 'auto',
    },
    successIcon: {
      color: theme.palette.success.main,
      fontSize: 12,
      paddingRight: 5,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: 12,
      paddingRight: 5,
    },
    nameCell: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    completedSubject: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
      textDecoration: 'line-through',
    },
    completedButton: {
      '&:hover': {
        backgroundColor: '#4caf50 !important',
      },
    },
    overDueChip: {
      backgroundColor: 'red',
      color: 'white',
      width:'110px'
    },
    toDoChip: {
      backgroundColor: '#4caf50',
      color: 'white',
      width:'110px'
    },
  }),
);

export default function TaskboardTable(): ReactElement {
  const classes: any = useStyles();
  const { fetchTodoOrOverdueCount } = useContext(TaskContext);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TaskListObject>('taskId');
  const selected = useAppSelector((state: any) => state.tasks.selectedTasks);
  const crmUserId = useAppSelector((state: any) => state.user.crmUserId);
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state: any) => ({
    loading: state.tasks.loading,
    error: state.tasks.error,
  }));

  const page = useAppSelector((state: any) => state.tasks.currentPage);
  const rowsPerPage = useAppSelector((state: any) => state.tasks.pageSize);
  const rows = useAppSelector((state: any) => state.tasks.tasks);
  const count = useAppSelector((state: any) => state.tasks.totalTasks);

  const searchString = useAppSelector((state: any) => state.tasks.searchString);
  const createdByUserId = useAppSelector((state: any) => state.tasks.createdByUserId);
  const assignedUserIds = useAppSelector((state: any) => state.tasks.assignedUserIds);
  const taskStatusId = useAppSelector((state: any) => state.tasks.taskStatusId);
  const taskTypeId = useAppSelector((state: any) => state.tasks.taskTypeId);
  const dealerLocationId = useAppSelector((state: any) => state.tasks.dealerId);
  const taskIsArchived = useAppSelector((state: any) => state.tasks.isArchived);
  const selectedCustomer = useAppSelector((state: any) => state.tasks.selectedCustomer);

  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { locations } = useLocations(dmsDealerIdCrm);

  const [dealEditDrawerState, setDealEditDrawerState] = useState(false);
  const [selectedEditDeal, setSelectedEditDeal] = useState<any>({});

  const fromDate = useAppSelector((state: any) => state.tasks.fromDate);
  const toDate = useAppSelector((state: any) => state.tasks.toDate);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TaskListObject): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isCustomerPermission = 0;
    let isDealPermission = 0;
    if (!userPermissions) {
        userPermissions = [];
        isCustomerPermission = 2;
        isDealPermission = 2;
    }
    if (isCustomerPermission == 0) {
        if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
            isCustomerPermission = 1;
        }
    }
    if (isDealPermission == 0) {
      if (userPermissions.find((x) => x.featureName.toLowerCase() === 'deals')) {
        isDealPermission = 1;
      }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      dispatch(SetSelectedTasks(rows));
      dispatch(SetAllSelectedTasks(true));
    } else {
      dispatch(SetSelectedTasks([]));
      dispatch(SetAllSelectedTasks(false));
    }
  };

  const handleSelectVisibleClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      dispatch(SetSelectedTasks(rows));
      return;
    }
    dispatch(SetSelectedTasks([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, task: TaskListObject): void => {
    dispatch(SetSelectedTasks([task]));
  };
  useEffect(() => {
    dispatch(setAssignedUsers([crmUserId]));
  }, [1]);

  useEffect(() => {
    dispatch(fetchTasks());
  }, [
    dispatch,
    searchString,
    createdByUserId,
    assignedUserIds,
    fromDate,
    toDate,
    taskStatusId,
    taskTypeId,
    dealerLocationId,
    taskIsArchived,
  ]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    dispatch(fetchTasksPage(newPage));
  };

  const handleSelectTask = (task: TaskListObject): void => {
    dispatch(UpdateSelectedTask(task));
  };

  const selectedTaskId = useAppSelector((state: any) => {
    if (state.tasks.selectedTask) {
      return state.tasks.selectedTask.id;
    }
    return -1;
  }, shallowEqual);
  const isSelected = (id: number): boolean => !!selected.find((x: TaskListObject) => x.taskId === id);

  const emptyRows = rowsPerPage - rows.length;

  const toggleOptIn = (customer: TaskListObject): void => {
    //dispatch(saveCustomerToggle(customer));
  };

  const onReScheduleTaskBtn = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_RESCHEDULE', drawerProps: {} }));
  };
  const onEditTask = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_EDIT', drawerProps: {} }));
  };
  const onDoneTaskBtn = (row: TaskListObject) => {
    dispatch(UpdateSelectedTask(row));
    dispatch(showTaskDrawer({ drawerType: 'TASK_DONE', drawerProps: {} }));
  };

  const openDealEditDrawer = (dealId: number, dealTitle: string) => {
    if (isDealPermission == 1) {
      const dealItem: DealItem = {
        id: dealId,
        title: dealTitle,
        selected: false,
        isArchiveTask: false,
        lostReasonId: '',
        tasksScheduled: 0,
        lostNote: '',
        dealStateId: 0,
      };
      setSelectedEditDeal(dealItem);
      setDealEditDrawerState(true); 
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ type: 'error', message: 'Failed to fetch tasks' }));
    }
  }, [error, dispatch]);

  let selectedInvalidPhoneNumbers = 0;
  useEffect(() => {
    fetchTodoOrOverdueCount();
  }, [rows]);
  let currentDate = moment.utc(new Date()).local();
  const checkForDefaultOrder = (rows: any, order:any, orderby:any )=>{
    if(orderby!=='taskId'){
       return stableSort(rows, getComparator(order, orderby))
    }
    else
    return rows;
  }
  return (
    <>
      <TaskTableToolbar validPhoneNumbers={selectedInvalidPhoneNumbers} numSelected={selected.length} />
      <TableContainer style={{ maxHeight: '65vh'}}>
        <Table className={classes.table} size="medium" stickyHeader>
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onSelectVisibleClick={handleSelectVisibleClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          {!loading && (rows.length > 0 ? (
            <TableBody>
              {
                checkForDefaultOrder(rows, order, orderBy).map((row: any, index: any) => {
                const isItemSelected = isSelected(row.taskId);
                const isRowSelected = selectedTaskId === row.taskId;
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    className={classes.tableRow}
                    tabIndex={-1}
                    key={row.taskId}
                    // style={{ background: row.taskStatusId !== UserTaskStatus.Overdue ? "#B6D7A8" : "#EA9999" }}
                    selected={isRowSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={row.taskStatusId === UserTaskStatus.Completed}
                        className="customercheckbox"
                        onClick={(event) => handleClick(event, row)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId, id: 'customerCheckbox_' + index }}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onEditTask(row);
                      }}
                      className={
                        row.taskStatusId === UserTaskStatus.Completed ? classes.completedSubject : classes.nameCell
                      }
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none">
                      {row.taskSubject}
                    </TableCell>
                    <TableCell align="left">{row.taskType} </TableCell>
                    <TableCell
                      align="left"
                      className={isCustomerPermission == 1 ? classes.nameCell : ""}
                      onClick={() => {
                        if (row.customerId && isCustomerPermission == 1) dispatch(fetchSelectedCustomer(row.customerId));
                      }}>
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={isDealPermission == 1 ? classes.nameCell : ""}
                      onClick={() => {
                        if (row.dealId) {
                          openDealEditDrawer(row.dealId, row.dealTitle);
                        }
                      }}>
                      {row.dealTitle}
                    </TableCell>
                    <TableCell align="left">
                      {row.dueDate && row.taskStatusId != UserTaskStatus.Completed && (
                        <Tooltip title={moment.utc(row.dueDate).local().format('M/D/Y h:mm a')}>
                          <Chip
                            className={
                              moment.utc(row.dueDate).local() < moment.utc(currentDate).local() &&
                              row.taskStatusId !== UserTaskStatus.Completed
                                ? classes.overDueChip
                                : classes.toDoChip
                            }
                            label={row.dueDate ? moment.utc(row.dueDate).local().format('M/D h:mm a') : ''}
                            variant="outlined"
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left">{row.assignedUsername}</TableCell>
                    {locations && locations.length > 1 && <TableCell align="left">{row.dealerLocationName}</TableCell>}
                    <TableCell align="left">
                      <Grid container>
                        <ButtonGroup size="small" aria-label="small button group">
                          {row.taskStatusId === UserTaskStatus.Completed && (
                            <Button
                              className={classes.completedButton}
                              variant="contained"
                              style={{ backgroundColor: '#4caf50', color: 'white' }}
                              size="small"
                              startIcon={<DoneIcon />}>
                              Completed
                            </Button>
                          )}
                          {!(row.taskStatusId === UserTaskStatus.Completed) && !taskIsArchived && (
                            <>
                              <SButton
                                ml={3}
                                variant="contained"
                                disabled={row.taskStatusId === UserTaskStatus.Completed}
                                size="small"
                                onClick={() => {
                                  onReScheduleTaskBtn(row);
                                }}
                                color="secondary">
                                Edit
                              </SButton>
                              <div style={{ padding: 2 }}></div>
                              <SButton
                                variant="contained"
                                disabled={row.taskStatusId === UserTaskStatus.Completed}
                                size="small"
                                onClick={() => {
                                  onDoneTaskBtn(row);
                                }}
                                color="primary">
                                Done
                              </SButton>{' '}
                            </>
                          )}
                        </ButtonGroup>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>) :(
          <TableBody>
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: 'center', padding: '40px 0',  color: 'grey' }}>
              <Typography style={{fontSize: '20px',}}>No task found</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
        )
          )}
          {loading && (
            <TableBody>
              <TableRow style={{ height: 30 * 15, width: '100%' }}>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  <SmallCircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box textAlign="right" m={1}>
        <Pagination
          className={classes.pagination}
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Box>
      {<CustomerRootPageDrawer />}
      <DealEditDrawer
        open={dealEditDrawerState}
        dealItem={selectedEditDeal}
        openedFromTasksBoard={true}
        renderDeals={() => {}}
        onClose={() => setDealEditDrawerState(false)}
      />
      {!dealEditDrawerState &&
         <TaskRootDrawer />
      }

    </>
  );
}
