import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app';
import useLocations from 'common/hooks/useLocations';
import EditRoleDrawer from './edit-role-drawer';
import NewRoleDrawer from './new-role-drawer';
import { SmallCircularProgress } from 'common/components/loaders';
import UserAPI from 'components/setting/api/user-api';
import { Permission, Role } from 'components/setting/models/user';
import { useFormik } from 'formik';
import PermissionList from './permissionList';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { useStyles } from 'components/layouts/DashboardLayout/beamer-button';
import AppSnackbar from 'components/snackbar/snackbar';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RoleListDrawer({
  open,
  reloadList,
  setReloadList,
  onCreate,
  onEdit,
  onClose,
}: {
  open: boolean;
  reloadList: boolean;
  setReloadList(val: boolean): void;
  onCreate(): void;
  onEdit(roleId: string): void;
  onClose(): void;
}): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [role, setRole] = React.useState<Role | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [roles, setRoles] = useState<Role[] | undefined>();
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>();
  const [deleteModalRoleId, setDelteModalRoleId] = useState<string | undefined>();
  const [selectedDeleteRoleId, setSelectedDeleteRoleId] = useState<string | undefined>();
  const [usersCount, setUsersCount] = useState<number | undefined>();
  const [usersInRole, setUsersInRole] = useState<string[] | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);

  let defaultDealerLocation = 0;

  const initialValues: {
    roleId?: string;
    roleName?: string;
    permissions: Permission[];
  } = {
    roleId: role?.roleId || "0",
    roleName: role?.roleName || '',
    permissions: role?.permissions || [],
  };
  const editMode = !!role?.roleId;



  async function handleSubmit(values: any): Promise<void> {
    //await handleSaveRole(values);
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleUserChange(values: any): void {
    formik.setValues(
      {
        roleId: formik.values.roleId,
        roleName: formik.values.roleName,
        permissions: values,
      },
      false,
    );

  }


  const fetchRoles = useCallback(async (): Promise<void> => {
    setLoading(true);
    const response = await UserAPI.getRoles(dmsDealerIdCrm);
    setRoles(response);
    setSelectedRoleId(undefined);
    setLoading(false);
  }, [dmsDealerIdCrm, showSnackbar]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles, open]);

  async function deleteRole(): Promise<void> {
    if (selectedRoleId != undefined) {
      let result = await UserAPI.deleteRole(dmsDealerIdCrm, selectedRoleId);
      if (result.status == 424) {
        setSelectedDeleteRoleId(selectedRoleId);
        setModalOpen(true);
        setUsersCount(result.usersCount);
        setUsersInRole(result.usersInRole);
      } else if (result.status == 200) {
        showSnackbar({ type: 'success', message: 'Role deleted' });
        setSelectedRoleId(undefined);
        fetchRoles();
      }else {
        showSnackbar({ type: 'error', message: 'Please select a role first' });
        return;
      }
    }else {
      showSnackbar({ type: 'error', message: 'Please select a role first' });
      return;
    }
  }

  function handleEditDrawerOpen(): void {
    if (!selectedRoleId) {
      showSnackbar({ type: 'error', message: 'Please select a role first' });
      return;
    }
    onEdit(selectedRoleId);
  }

  async function handleDeleteRole(): Promise<void> {
    if (deleteModalRoleId != undefined && selectedDeleteRoleId != undefined && usersInRole != undefined) {
      let result = await UserAPI.updateUserRole(dmsDealerIdCrm, deleteModalRoleId, selectedDeleteRoleId, usersInRole);
      if (result.status == 400) {
        setModalOpen(true);
      } else {
        setReloadList(!reloadList);
        setModalOpen(false);
        showSnackbar({ type: 'success', message: 'Role deleted' });
        fetchRoles();
      }
    }
  }

  return (
    <Drawer PaperProps={{ style: { width: '30%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Role List" onClose={() => { onClose(); }} />
      <Box mb={1} />
      <Container>
        {open && (
          <>
            <Box mb={1} display="flex">
              <Box flexGrow={1} />

              <Box>
                <Button onClick={onCreate} variant="contained" startIcon={<AddCircleIcon />} color="primary">
                  Create New Role
                </Button>
              </Box>

            </Box>



            <Box>
              {loading ? (
                <Box height="500px" margin="auto" display="flex" justifyContent="center">
                  <SmallCircularProgress />
                </Box>
              ) : (
                <>
                  <TextField
                    fullWidth
                    select
                    margin="dense"
                    variant="outlined"
                    name="roleId"
                    disabled={editMode || roles?.length === 0}
                    inputProps={{ id: 'roleId' }}
                    label="Role"
                    onChange={(e) => { setSelectedRoleId(e.target.value) }}
                    required>
                    <MenuItem key={0} value={0}>
                      Select Role
                    </MenuItem>
                    {roles?.map((role: Role) => (
                      <MenuItem key={role.roleId} value={role.roleId}>
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* <Table stickyHeader id="roles-table" aria-label="Roles table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roles?.map((role) => (
                        <TableRow key={role.roleId}>
                          <TableCell className={classes.roleName} onClick={(): void => onEditRole(role)}>
                            {role.roleName}
                          </TableCell>

                          <TableCell>
                            <Tooltip title="Delete role">
                              <IconButton onClick={(): Promise<void> => deleteRole(role.roleId)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> */}



                  <Box mb={1} mt={2} display="flex">
                    <Box flexGrow={1} />
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button onClick={deleteRole} color="secondary" startIcon={<DeleteIcon />} variant="contained">
                            Delete
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button onClick={() => (handleEditDrawerOpen())} variant="contained" startIcon={<EditIcon />} color="primary">
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>

              )}
            </Box>

            <Modal
              open={modalOpen}
              onClose={() => { }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <>

                <Box sx={style}>
                  <Grid container>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => { setModalOpen(false) }}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Typography variant="h5" component="h2">
                    Delete Role
                  </Typography>
                  <Typography variant="h5" component="h2">
                    This Role is assigned to {usersCount} users. If you want to delete this role, please select a new role which should be assigned to these users
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Select
                        required
                        disabled={editMode || roles?.length === 1}
                        label="Role"
                        style={{ width: "100%" }}
                        id="demo-simple-select"
                        value={deleteModalRoleId}
                        onChange={((e: any) => { setDelteModalRoleId(e.target.value) })}
                      >
                        <MenuItem key={0} value={0}>
                          Select Role
                        </MenuItem>
                        {roles?.filter(x => x.roleId != selectedDeleteRoleId).map((role: Role) => (
                          <MenuItem key={role.roleId} value={role.roleId}>
                            {role.roleName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant="contained" data-testid="handleAddWord" color="primary" onClick={() => { handleDeleteRole() }}>Delete Role</Button>
                    </Grid>

                  </Grid>



                  {/* <TextField
                  fullWidth
                  select
                  margin="dense"
                  variant="outlined"
                  name="roleId"
                  inputProps={{ id: 'roleId' }}
                  label="Role"
                  
                  // value={formik.values.selectedRoleId}
                  // error={!!formik.errors.selectedRoleId}
                  // helperText={formik.errors.selectedRoleId}
                  >
                 
                </TextField> */}


                </Box>
              </>
            </Modal>

          </>
        )}
      </Container>
    </Drawer>
  );
}
