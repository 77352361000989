import { Fab, withStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

const ToggleFab = withStyles({
  primary: {
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[800],
    },
  },
  secondary: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
})(Fab);

export default ToggleFab;
