import React from 'react';
import MaskedInput from 'react-text-mask';

export const PhoneNumberMask: React.FC<{
  inputRef: (ref: HTMLInputElement | null) => void;
}> = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    guide
    keepCharPositions
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);
