import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import notificationApi from './notification-api';

export interface Notification {
  id: number;
  guid: string;
  title: string;
  message: string;
  type: string;
  userName: string;
  isRead: boolean;
  dealerId?:string;
  eventType?: string;
  eventData?: string;
}

export interface NotificationState {
  notifications: Notification[];
  connection: any;
  currentPage: number;
  pageSize: number;
  totalNotifications: number;
  loading: boolean;
  error: string | null;
  drawerType: string | null;
  drawerProps: any | {};
  isNotificationDrawerOpen: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  connection: {},
  currentPage: 0,
  pageSize: 15,
  totalNotifications: 0,
  loading: false,
  error: null,
  drawerType: null,
  drawerProps: {},
  isNotificationDrawerOpen: false,
};

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startConnection(state, action: PayloadAction<any>) {
      state.connection = action.payload;
    },
    stopConnection(state) {
      if (state.connection) {
        state.connection.stop();
      }
    },
    getNotificationStart(state) {
      state.loading = true;
      state.error = null;
    },
    openNotificationDrawer(state) {
      state.isNotificationDrawerOpen = true;
      async (): Promise<void> => {
        const data = await notificationApi.NotificationApis.getAllNotifications();
        SetSelectedNotifications(data);
      };
    },
    setNotificationIsRead(state) {
      state.notifications.forEach((item)=>item.isRead = true);
    },
    closeNotificationDrawer(state) {
      state.isNotificationDrawerOpen = false;
    },
    getNotificationSuccess(
      state: NotificationState,
      action: PayloadAction<{
        notifications: Notification[];
        pageSize: number;
        currentPage: number;
        totalNotifications: number;
      }>,
    ) {
      const { notifications, pageSize, totalNotifications, currentPage } = action.payload;
      state.notifications = notifications;
      state.currentPage = currentPage;
      state.pageSize = pageSize;
      state.totalNotifications = totalNotifications;
      state.loading = false;
      state.error = null;
    },
    getNotificationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    SetSelectedNotifications(state: NotificationState, action: PayloadAction<Notification[]>) {
      state.notifications = action.payload;
      state.totalNotifications = state.notifications.length;
    },
    showNotificationDrawer(state, action: PayloadAction<{ drawerType: string; drawerProps: any }>) {
      state.drawerType = action.payload.drawerType;
      state.drawerProps = action.payload.drawerProps;
    },
    addNotification(state: NotificationState, action: PayloadAction<Notification>) {
      let newNotification = true;
      /*
      If Notification with same GUID already exists And if Notification Type is not Success Then, Replace it with new notification. 
      Otherwise, 
        Push New notification to list.
      */
      let notifications = state.notifications.map((notification) => {
        if (!(notification.guid === action.payload.guid)) {
          return notification;
        } else {
          newNotification = false;
          if (action.payload.type === 'InProgress' && notification.type === 'Success') {
            return notification;
          } else {
            return action.payload;
          }
        }
      });

      if (newNotification) notifications.unshift(action.payload);

      state.notifications = notifications;
    },
    removeNotification(state: NotificationState, action: PayloadAction<string>) {
      let notifications = state.notifications.map((notification) => {
        if (notification.guid !== action.payload) {
          return notification;
        } else {
          return null;
        }
      });
      if (notifications === undefined) {
        state.notifications = initialState.notifications;
      } else {
        state.notifications = notifications as Notification[];
      }
    },
  },
});

export const {
  openNotificationDrawer,
  closeNotificationDrawer,
  setNotificationIsRead,
  SetSelectedNotifications,
  addNotification,
  removeNotification,
  startConnection,
  stopConnection,
} = notification.actions;
export default notification.reducer;
