import { Box, Container, Drawer } from '@material-ui/core';
import React from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Customer from 'common/models/customer';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import CustomerForm from 'components/customer/components/CustomerForm/CustomerForm';
import AppSnackbar from 'components/snackbar/snackbar';

function CustomerDetailsDrawer({
  open,
  onClose,
  crmCustomerId,
}: {
  open: boolean;
  crmCustomerId?: number;
  onClose(isUpdated: boolean): void;
}): React.ReactElement {
  const [loading, setLoading] = React.useState(false);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [customer, setCustomer] = React.useState<Customer | undefined>(undefined);
    const [showSnackBar] = AppSnackbar.useSnackBar();
    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isCustomerPermission = 0;
    if (!userPermissions) {
        userPermissions = [];
        isCustomerPermission = 2;
    }
    if (isCustomerPermission == 0) {
        if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
            isCustomerPermission = 1;
        }
    }

  React.useEffect(() => {
    async function fetchCustomer(): Promise<void> {
      if (crmCustomerId) {
        try {
          setLoading(true);
          const customerResponse = await CustomerAPI.getCustomer(crmCustomerId);
          setCustomer(customerResponse);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          showSnackBar({ type: 'error', message: 'Unable to get customer' });
          onClose(false);
        }
      }
    }
    fetchCustomer();
  }, [crmCustomerId, dealerId, onClose, showSnackBar]);

  function handleClose(): void {
    setCustomer(undefined);
    onClose(false);
  }
  async function handleCustomerFormSubmit(values: Customer): Promise<void> {
    try {
      await CustomerAPI.editCustomer(dealerId, values);
      showSnackBar({ type: 'success', message: 'Customer updated' });
      onClose(true);
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to update customer' });
      onClose(false);
    }
  }
  return (
      <Drawer style={{ display: isCustomerPermission == 1 ? "block" : "none" }}
      PaperProps={{ style: { width: '40%' } }}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={(): void => onClose(false)}>
      <DrawerToolbar title="Edit Customer" onClose={(): void => onClose(false)} />
      <Box mb={1} />
      <Container>
        {!loading ? (
          <CustomerForm
            btnDisabled={false}
            customer={customer}
            disabled={false}
            onFormSubmit={handleCustomerFormSubmit}
            onClose={handleClose}
            validate
          />
        ) : (
          <Box width="100%" display="flex" justifyContent="center" mt={1}>
            <SmallCircularProgress />
          </Box>
        )}
      </Container>
    </Drawer>
  );
}

export default CustomerDetailsDrawer;
