import { Box, Checkbox, Drawer, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Department from 'components/setting/models/department';
import { UserDepartment } from 'components/setting/models/user-department-settings';
import { groupBy, uniqBy } from 'lodash';
import React from 'react';
import SelectedDepartmentsCard from './selected-departments-card';

type DepartmentTreeNodeType = 'all' | 'location' | 'department';

interface DepartmentTreeOption {
  type: DepartmentTreeNodeType;
  title: string;
  value: number;
  nodeId: number;
  nodes?: DepartmentTreeOption[];
}

function DepartmentFilterDrawer({
  open,
  onClose,
  selectedDepartmentIds,
  assignedDepartments,
  onSave,
}: {
  open: boolean;
  onClose(): void;
  selectedDepartmentIds: number[];
  assignedDepartments: UserDepartment[];
  onSave(selectedDepartmentIds: number[]): Promise<void>;
}): React.ReactElement {
  const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>([]);
  const [departmentOptions, setDepartmentOptions] = React.useState<DepartmentTreeOption[]>([]);
  const [departments, setDepartments] = React.useState<UserDepartment[]>([]);
  const [departmentSearchText, setDepartmentSearchText] = React.useState('');
  const [nodeIdCount, setNodeIdCount] = React.useState<number>(0);

  React.useEffect(() => {
    setDepartments(assignedDepartments);
    const departmentsPerLocation = groupBy(assignedDepartments, 'dealerLocationId');
    const locationNames: any = uniqBy(assignedDepartments, 'dealerLocationId').reduce(
      (obj, item) => ({ ...obj, [item.dealerLocationId]: item.dealerLocationName }),
      {},
    );
    let index = nodeIdCount;
    const selectAll: DepartmentTreeOption = {
      type: 'all',
      title: 'All departments',
      value: 0,
      nodeId: index++,
      nodes: [],
    };
    const departmentsTree = Object.entries(departmentsPerLocation).map(([key, value]): DepartmentTreeOption => {
      return {
        type: 'location',
        title: locationNames[key],
        value: parseInt(key, 10),
        nodeId: index++,
        nodes: value.map(
          (department: UserDepartment): DepartmentTreeOption => ({
            type: 'department',
            title: department.departmentName,
            value: department.departmentId,
            nodeId: index++,
          }),
        ),
      };
    });
    let d: DepartmentTreeOption[] = [];
    if (departmentsTree.length) {
      d = [selectAll, ...departmentsTree];
    }
    setDepartmentOptions(d);
    setNodeIdCount(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedDepartments]);

  React.useEffect(() => {
    setSelectedDepartments(selectedDepartmentIds);
  }, [selectedDepartmentIds, open]);

  function handleSelect(type: DepartmentTreeNodeType, id: number, selected: boolean): void {
    if (selected === true) {
      if (type === 'all') {
        setSelectedDepartments([]);
      } else if (type === 'location') {
        setSelectedDepartments([
          ...selectedDepartments.filter(
            (x) =>
              departments
                .filter((dep) => dep.dealerLocationId !== id)
                .map((dep) => dep.departmentId)
                .indexOf(x) !== -1,
          ),
        ]);
      } else if (type === 'department') {
        setSelectedDepartments([...selectedDepartments.filter((x) => x !== id)]);
      }
    } else if (selected === false) {
      if (type === 'all') {
        setSelectedDepartments([...departments.map((dep) => dep.departmentId)]);
      } else if (type === 'location') {
        const locationDepartmentIds = departments
          .filter((dep) => dep.dealerLocationId === id)
          .map((dep) => dep.departmentId);
        setSelectedDepartments([
          ...selectedDepartments.filter((depId) => locationDepartmentIds.indexOf(depId) === -1),
          ...locationDepartmentIds,
        ]);
      } else if (type === 'department') {
        setSelectedDepartments([...selectedDepartments, id]);
      }
    }
  }

  function removeDepartment(id: number): void {
    setSelectedDepartments([...selectedDepartments.filter((x) => x !== id)]);
  }

  async function handleSave(): Promise<void> {
    onSave(selectedDepartments);
    onClose();
  }

  const expandedIds = Array.from({ length: nodeIdCount }).map((x: any, i: number): string => i.toString());

  const selectedDepartmentGroupByLocation: { [key: string]: number[] } = {};
  let depNames: { [key: number]: string } = {};
  if (selectedDepartments.length > 0 && assignedDepartments.length > 0 && departments.length > 0) {
    depNames = departments.reduce((acc, item) => ({ ...acc, [item.departmentId]: item.departmentName }), {});
    const departmentInfo: { [key: number]: UserDepartment } = departments.reduce(
      (acc, val) => ({ ...acc, [val.departmentId]: val }),
      {},
    );
    selectedDepartments.forEach((depId) => {
      if (departmentInfo[depId]) {
        const departmentLocation = departmentInfo[depId]?.dealerLocationName;
        const locationSelectedDepartments = selectedDepartmentGroupByLocation[departmentLocation];
        if (locationSelectedDepartments) {
          selectedDepartmentGroupByLocation[departmentLocation] = [...locationSelectedDepartments, depId];
        } else {
          selectedDepartmentGroupByLocation[departmentLocation] = [depId];
        }
      }
    });
  }

  function filterTreeItem(depOption: DepartmentTreeOption): boolean {
    if (departmentSearchText !== '') {
      if (
        depOption.nodes?.find((node) => node.title.toLowerCase().indexOf(departmentSearchText.toLowerCase()) !== -1)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }
  const treeItems = departmentOptions.map((depOption) =>
    filterTreeItem(depOption) ? (
      <DepartmentTreeItem
        key={depOption.nodeId}
        departmentTreeOption={depOption}
        onSelect={handleSelect}
        nameFilter={departmentSearchText}
        selectedDepartmentIds={selectedDepartments}
        departmentIds={departments.map((dep) => dep.departmentId)}
      />
    ) : null,
  );
  return (
    <Drawer PaperProps={{ style: { width: '50%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Select Departments" onSave={handleSave} />
      <Box mx={1} position="relative" height="calc(100% - 49px)">
        <Box display="flex" flexDirection="column" height="calc(100% - 44px)">
          <Box
            my={1}
            pb={1}
            overflow="scroll"
            minHeight={Object.keys(selectedDepartmentGroupByLocation).length ? '200px' : '50px'}>
            {Object.keys(selectedDepartmentGroupByLocation).length ? (
              <Box>
                {Object.keys(selectedDepartmentGroupByLocation).map((dealerLocationName) => (
                  <SelectedDepartmentsCard
                    key={dealerLocationName}
                    dealerLocationName={dealerLocationName}
                    removeDepartment={removeDepartment}
                    departments={selectedDepartmentGroupByLocation[dealerLocationName].map((id) => ({
                      id,
                      name: depNames[id],
                    }))}
                  />
                ))}
              </Box>
            ) : (
              <Box margin="auto" textAlign="center" mt={1}>
                No departments assigned
              </Box>
            )}
          </Box>
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              size="small"
              name="departmentSearch"
              id="departmentSearch"
              label="Search"
              placeholder="Search department"
              onChange={({ target: { value } }): void => setDepartmentSearchText(value)}
            />
          </Box>
          <Box flexGrow={1} overflow="scroll">
            {departmentOptions.length && treeItems.filter((x) => x !== null).length ? (
              <TreeView expanded={expandedIds}>{treeItems}</TreeView>
            ) : (
              <Box margin="auto" textAlign="center">
                No departments
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DepartmentFilterDrawer;

function DepartmentTreeItem({
  departmentTreeOption,
  onSelect,
  nameFilter,
  selectedDepartmentIds,
  departmentIds,
}: {
  departmentTreeOption: DepartmentTreeOption;
  onSelect(type: DepartmentTreeNodeType, id: number, select: boolean): void;
  departmentIds: number[];
  nameFilter: string;
  selectedDepartmentIds: number[];
}): React.ReactElement | null {
  if (departmentTreeOption.type === 'location' && departmentTreeOption.nodes) {
    return (
      <DepartmentTreeLocationItem
        nodeId={departmentTreeOption.nodeId.toString()}
        title={departmentTreeOption.title}
        nodes={departmentTreeOption.nodes.filter(
          (node) => node.title.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1,
        )}
        selectedDepartmentIds={selectedDepartmentIds}
        dealerLocationId={departmentTreeOption.value}
        onSelect={onSelect}
      />
    );
  }
  if (departmentTreeOption.type === 'all') {
    return (
      <DepartmentTreeSelectAllItem
        nodeId={departmentTreeOption.nodeId.toString()}
        departmentIds={departmentIds}
        selectedDepartmentIds={selectedDepartmentIds}
        title={departmentTreeOption.title}
        value={departmentTreeOption.value}
        onSelect={onSelect}
      />
    );
  }
  return null;
}

function RenderLabel({
  title,
  type,
  checked,
  onClick,
}: {
  title: string;
  type: 'department' | 'location';
  checked: boolean;
  onClick(): void;
}): React.ReactElement {
  return (
    <ListItem dense component="div" disableGutters>
      <ListItemIcon>
        <Checkbox onClick={onClick} checked={checked} size="small" />
      </ListItemIcon>
      <ListItemText>
        <Typography
          variant={type === 'department' ? 'subtitle2' : 'body1'}
          style={{ fontWeight: type === 'location' ? 'bold' : 'normal' }}>
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

function DepartmentTreeLocationItem({
  nodeId,
  title,
  nodes,
  dealerLocationId,
  selectedDepartmentIds,
  onSelect,
}: {
  nodeId: string;
  title: string;
  dealerLocationId: number;
  nodes: DepartmentTreeOption[];
  selectedDepartmentIds: number[];
  onSelect(type: DepartmentTreeNodeType, id: number, select: boolean): void;
}): React.ReactElement | null {
  if (nodes.length === 0) {
    return null;
  }
  const isLocationSelected = selectedDepartmentIds.length
    ? nodes.map((dep) => dep.value).every((x) => selectedDepartmentIds.includes(x))
    : false;
  return (
    <TreeItem
      key={nodeId}
      nodeId={nodeId}
      label={
        <RenderLabel
          title={title}
          type="location"
          checked={isLocationSelected}
          onClick={(): void => onSelect('location', dealerLocationId, isLocationSelected)}
        />
      }>
      {nodes.map((depOp) => (
        <TreeItem
          key={depOp.value}
          nodeId={depOp.nodeId.toString()}
          label={
            <RenderLabel
              title={depOp.title}
              type="department"
              checked={selectedDepartmentIds.indexOf(depOp.value) !== -1}
              onClick={(): void =>
                onSelect('department', depOp.value, selectedDepartmentIds.indexOf(depOp.value) !== -1)
              }
            />
          }
        />
      ))}
    </TreeItem>
  );
}

function DepartmentTreeSelectAllItem({
  nodeId,
  title,
  value,
  selectedDepartmentIds,
  departmentIds,
  onSelect,
}: {
  nodeId: string;
  title: string;
  value: number;
  selectedDepartmentIds: number[];
  departmentIds: number[];
  onSelect(type: DepartmentTreeNodeType, id: number, select: boolean): void;
}): React.ReactElement {
  const allSelected = selectedDepartmentIds.length
    ? departmentIds.every((x) => selectedDepartmentIds.includes(x))
    : false;
  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <RenderLabel
          title={title}
          type="location"
          checked={allSelected}
          onClick={(): void => onSelect('all', value, allSelected)}
        />
      }
    />
  );
}



export function DepartmentDropdown({
  selectedDepartments,
  setSelectedDepartments,
  assignedDepartments,
  error,
  helperText,
  placeholder,
}: {
  selectedDepartments: number[];
  setSelectedDepartments(selectedDepartmentIds: number[]): void;
  assignedDepartments: Department[];
  error: any;
  helperText: any;
  placeholder: any;
}): React.ReactElement {

  const [departmentOptions, setDepartmentOptions] = React.useState<DepartmentTreeOption[]>([]);
  const [departments, setDepartments] = React.useState<UserDepartment[]>([]);
  const [departmentSearchText, setDepartmentSearchText] = React.useState('');
  const [nodeIdCount, setNodeIdCount] = React.useState<number>(0);

  React.useEffect(() => {
    setDepartments(assignedDepartments);
    const departmentsPerLocation = groupBy(assignedDepartments, 'dealerLocationId');
    const locationNames: any = uniqBy(assignedDepartments, 'dealerLocationId').reduce(
      (obj, item) => ({ ...obj, [item.dealerLocationId]: item.dealerLocationName }),
      {},
    );
    let index = nodeIdCount;
    const selectAll: DepartmentTreeOption = {
      type: 'all',
      title: 'All departments',
      value: 0,
      nodeId: index++,
      nodes: [],
    };
    const departmentsTree = Object.entries(departmentsPerLocation).map(([key, value]): DepartmentTreeOption => {
      return {
        type: 'location',
        title: locationNames[key],
        value: parseInt(key, 10),
        nodeId: index++,
        nodes: value.map(
          (department: UserDepartment): DepartmentTreeOption => ({
            type: 'department',
            title: department.departmentName,
            value: department.departmentId,
            nodeId: index++,
          }),
        ),
      };
    });
    let d: DepartmentTreeOption[] = [];
    if (departmentsTree.length) {
      d = [selectAll, ...departmentsTree];
    }
    setDepartmentOptions(d);
    setNodeIdCount(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedDepartments]);

  React.useEffect(() => {
    setSelectedDepartments(selectedDepartments);
  }, [selectedDepartments, open]);

  function handleSelect(type: DepartmentTreeNodeType, id: number, selected: boolean): void {
    if (selected === true) {
      if (type === 'all') {
        setSelectedDepartments([]);
      } else if (type === 'location') {
        setSelectedDepartments([
          ...selectedDepartments.filter(
            (x) =>
              departments
                .filter((dep) => dep.dealerLocationId !== id)
                .map((dep) => dep.departmentId)
                .indexOf(x) !== -1,
          ),
        ]);
      } else if (type === 'department') {
        setSelectedDepartments([...selectedDepartments.filter((x) => x !== id)]);
      }
    } else if (selected === false) {
      if (type === 'all') {
        setSelectedDepartments([...departments.map((dep) => dep.departmentId)]);
      } else if (type === 'location') {
        const locationDepartmentIds = departments
          .filter((dep) => dep.dealerLocationId === id)
          .map((dep) => dep.departmentId);
        setSelectedDepartments([
          ...selectedDepartments.filter((depId) => locationDepartmentIds.indexOf(depId) === -1),
          ...locationDepartmentIds,
        ]);
      } else if (type === 'department') {
        setSelectedDepartments([...selectedDepartments, id]);
      }
    }
  }

  function removeDepartment(id: number): void {
    setSelectedDepartments([...selectedDepartments.filter((x) => x !== id)]);
  }

  const expandedIds = Array.from({ length: nodeIdCount }).map((x: any, i: number): string => i.toString());

  const selectedDepartmentGroupByLocation: { [key: string]: number[] } = {};
  let depNames: { [key: number]: string } = {};
  if (selectedDepartments.length > 0 && assignedDepartments.length > 0 && departments.length > 0) {
    depNames = departments.reduce((acc, item) => ({ ...acc, [item.departmentId]: item.departmentName }), {});
    const departmentInfo: { [key: number]: UserDepartment } = departments.reduce(
      (acc, val) => ({ ...acc, [val.departmentId]: val }),
      {},
    );
    selectedDepartments.forEach((depId) => {
      if (departmentInfo[depId]) {
        const departmentLocation = departmentInfo[depId]?.dealerLocationName;
        const locationSelectedDepartments = selectedDepartmentGroupByLocation[departmentLocation];
        if (locationSelectedDepartments) {
          selectedDepartmentGroupByLocation[departmentLocation] = [...locationSelectedDepartments, depId];
        } else {
          selectedDepartmentGroupByLocation[departmentLocation] = [depId];
        }
      }
    });
  }

  function filterTreeItem(depOption: DepartmentTreeOption): boolean {
    if (departmentSearchText !== '') {
      if (
        depOption.nodes?.find((node) => node.title.toLowerCase().indexOf(departmentSearchText.toLowerCase()) !== -1)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }
  const treeItems = departmentOptions.map((depOption) =>
    filterTreeItem(depOption) ? (
      <DepartmentTreeItem
        key={depOption.nodeId}
        departmentTreeOption={depOption}
        onSelect={handleSelect}
        nameFilter={departmentSearchText}
        selectedDepartmentIds={selectedDepartments}
        departmentIds={departments.map((dep) => dep.departmentId)}
      />
    ) : null,
  );
  return (
    // <Drawer PaperProps={{ style: { width: '50%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
    //   <DrawerToolbar title="Select Departments" onSave={handleSave} />
    <Box position="relative" style={{ width: "100%" }}>
      <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
        <Box overflow="scroll" style={{ width: "100%" }}>
          {Object.keys(selectedDepartmentGroupByLocation).length ? (
            <Box>
              {Object.keys(selectedDepartmentGroupByLocation).map((dealerLocationName) => (
                <SelectedDepartmentsCard
                  key={dealerLocationName}
                  dealerLocationName={dealerLocationName}
                  removeDepartment={removeDepartment}
                  departments={selectedDepartmentGroupByLocation[dealerLocationName].map((id) => ({
                    id,
                    name: depNames[id],
                  }))}
                />
              ))}
            </Box>
          ) : (
            <Box margin="auto" textAlign="center" mt={1}>
              No departments Selected
            </Box>
          )}
        </Box>
        <Box>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            name="departmentSearch"
            id="departmentSearch"
            label="Search"
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            onChange={({ target: { value } }): void => setDepartmentSearchText(value)}
          />
        </Box>
        <Box flexGrow={1} overflow="scroll">
          {departmentOptions.length && treeItems.filter((x) => x !== null).length ? (
            <TreeView expanded={expandedIds}>{treeItems}</TreeView>
          ) : (
            <Box margin="auto" textAlign="center">
              No departments
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    // </Drawer>
  );
}
