import React, { ReactElement } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

export default function TextLimitField(props: TextFieldProps & { characters: number , helperTextAlignLeft?:boolean}): ReactElement {
  const { value, characters, helperTextAlignLeft, onChange } = props;
  function handleOnChange(event: any): void {
    if (onChange) {
      let val = event.target.value;
      if(val.length > characters){
        val = val.substring(0, characters);
      }
      event.target.value = val;
      onChange(event);
    }
  }
  const valueLength = (typeof value === 'string' && value.length) || 0;
  let helperText = `${valueLength}/${characters}`;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <TextField
      {...props}
      FormHelperTextProps={{ style: { textAlign: helperTextAlignLeft && props.helperText?  'left' : 'right' } }}
      onChange={handleOnChange}
      helperText={props.helperText ? props.helperText : helperText}
    />
  );
}
