import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

import { PhoneNumberMask } from 'common/components/input-fields';

import { OpinionScaleField } from './opinion-scale-field';

const useHandleTextWithEnter = (onChange: (value: string, goNext: boolean) => void, onNext: () => void) => {
  const handleOnChange = (event: any) => {
    //  On Enter key
    onChange(event.target.value, event.charCode === 13);
  };
  return handleOnChange;
};

function ShortTextQuestionField({ id, onChange, onNext, value, ...other }: any) {
  const handleOnChange = useHandleTextWithEnter(onChange, onNext);
  return (
    <TextField
      autoFocus
      fullWidth
      key={id}
      variant="outlined"
      margin="dense"
      defaultValue={value}
      {...other}
      onChange={handleOnChange}
    />
  );
}

function LongTextQuestionField({ id, onChange, onNext, value, ...other }: any) {
  const handleOnChange = useHandleTextWithEnter(onChange, onNext);
  return (
    <TextField
      autoFocus
      fullWidth
      variant="outlined"
      margin="dense"
      multiline
      rows={5}
      key={id}
      defaultValue={value}
      {...other}
      onChange={handleOnChange}
    />
  );
}

function EmailQuestionField({ id, onChange, onNext, value, ...other }: any) {
  const handleOnChange = useHandleTextWithEnter(onChange, onNext);
  return (
    <TextField
      autoFocus
      variant="outlined"
      margin="dense"
      key={id}
      fullWidth
      type="email"
      defaultValue={value}
      {...other}
      onChange={handleOnChange}
    />
  );
}

function PhoneNumberField({ id, onChange, onNext, value, ...other }: any) {
  const handleOnChange = useHandleTextWithEnter(onChange, onNext);
  return (
    <TextField
      autoFocus
      key={id}
      fullWidth
      type="text"
      variant="outlined"
      margin="dense"
      defaultValue={value}
      {...other}
      onChange={handleOnChange}
      InputProps={{
        inputComponent: PhoneNumberMask as any,
      }}
    />
  );
}

function DateQuestionField({ id, value, onChange, ...other }: any) {
  const handleOnChange = (input: any) => {
    onChange(input, false);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        key={id}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        value={value}
        inputVariant="outlined"
        onChange={handleOnChange}
        id="date-picker-inline"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default function QuestionField({ type, ...other }: any) {
  let SelectedQuestionField = null;
  switch (type) {
    case 'shortText':
      SelectedQuestionField = ShortTextQuestionField;
      break;
    case 'longText':
      SelectedQuestionField = LongTextQuestionField;
      break;
    case 'email':
      SelectedQuestionField = EmailQuestionField;
      break;
    case 'phoneNumber':
      SelectedQuestionField = PhoneNumberField;
      break;
    case 'nps':
      SelectedQuestionField = OpinionScaleField;
      break;
    case 'date':
      SelectedQuestionField = DateQuestionField;
      break;
    default:
      SelectedQuestionField = null;
  }
  return SelectedQuestionField ? <SelectedQuestionField {...other} /> : null;
}
