import { useAppSelector } from 'app';
import UserAPI from 'components/setting/api/user-api';
import User, { Permission, Role } from 'components/setting/models/user';
import React from 'react';
export function PermissionList(): Permission[] | undefined {
  const [permissions, setPermissions] = React.useState<Permission[] | undefined>(undefined);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  React.useEffect(() => {
    async function fetchPermissions(): Promise<void> {
      const response = await UserAPI.getPermissions(dmsDealerIdCrm);
      setPermissions(response);
    }
    fetchPermissions();
  }, [dmsDealerIdCrm]);
  return permissions;
}
export default PermissionList