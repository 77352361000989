import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app';
import { useLocation } from 'react-router-dom';
import LoadingButton from 'common/components/buttons/LoadingButton';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import MessagingAPI from 'components/messaging/api/messaging-api';
import { showSnackbar } from 'components/root-snackbar';
import Video, { createLocalTracks, LocalTrackPublication, Participant, Room, Track } from 'twilio-video';
import LocalParticipant from './LocalParticipant';
import RemoteParticipant from './RemoteParticipant';

import { CallEnd, RoomService, Videocam, VideocamOff } from '@material-ui/icons';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { Draggable } from 'react-beautiful-dnd';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    height: `calc(100% - ${theme.navBar.height + theme.spacing(2)})`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    height: '100%',
    textAlign: 'center',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    minWidth: 500,
    maxWidth: '80vw',
    maxHeight: '80vh',
    minHeight: 300,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

enum VideoCallState {
  AboutToStart,
  Started,
  Ended,
}

const StyledTextField = withStyles({
  root: {
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
})(TextField);

const StyledButton = withStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'lowercase',
  },
})(Button);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const VideoCall: React.FC = () => {
  const query = useQuery();
  const s: string = query.get('s') ?? '';
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const [callStatus, setCallStatus] = useState<VideoCallState>(VideoCallState.AboutToStart);
  const [loading, setLoading] = useState<boolean>(false);
  const [room, setRoom] = useState<Room | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [token, setToken] = useState('');

  const [enableVideo, setVideoEnable] = useState<boolean>(true);
  const [enableAudio, setAudioEnable] = useState<boolean>(true);

  const [audioTracks, setAudioTracks] = useState<MediaDeviceInfo[]>([]);
  const [videoTracks, setVideoTracks] = useState<MediaDeviceInfo[]>([]);

  const [selectedAudioTrack, setSelectedAudioTrack] = useState<string>('');
  const [selectedVideoTrack, setSelectedVideoTrack] = useState<string>('');

  const [Name, setName] = useState<string>('');
  const [userType, setUserType] = useState<string>('');

  async function GetToken() {
    setLoading(true);
    let response = await MessagingAPI.getVideoCallToken(s);
    setToken(response.token);
    setName(response.name);
    setUserType(response.type);
    setLoading(false);
  }

  async function StartCall() {
    const participantConnected = (participant: Participant) => {
      console.log('Participants : ', participants);
      setParticipants([participant]);
      // if(participants.find(p => p.sid === participant.sid)) {
      //   setParticipants(participants.map(p => p.sid === participant.sid ? participant : p));
      // }else{
      //   setParticipants([...participants, participant]);
      // }
    };
    const participantDisconnected = (participant: Participant) => {
      //remove participant from current participants
      setParticipants([]);
      // setParticipants(participants.filter(p => p.sid !== participant.sid));
    };
    let tracks = await createLocalTracks({
      audio: { deviceId: selectedAudioTrack },
      video: { deviceId: selectedVideoTrack },
    });

    Video.connect(token, { name: '', tracks: tracks }).then((room: Room) => {
      setRoom(room);
      room.localParticipant.on('trackSubscribed', (track: any) => {
        track.publish();
      });
      if (!enableAudio) {
        room.localParticipant.audioTracks.forEach((x) => x.track.disable());
      }
      if (!enableVideo) {
        room.localParticipant.videoTracks.forEach((x) => x.track.disable());
      }
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });
  }

  useEffect(() => {
    if (callStatus === VideoCallState.AboutToStart) {
      GetToken();
    } else if (callStatus === VideoCallState.Started) {
      StartCall();
    } else {
      if (room) {
        room.localParticipant.tracks.forEach((track: LocalTrackPublication) => {
          track.unpublish();
        });
        room.disconnect();
      }
    }
  }, [callStatus]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let video = devices.filter((x) => x.kind === 'videoinput');
      let audio = devices.filter((x) => x.kind === 'audioinput');
      setAudioTracks(audio);
      setVideoTracks(video);
      setSelectedVideoTrack(video[0]?.deviceId);
      setSelectedAudioTrack(audio[0]?.deviceId);
    });
  }, [1]);

  const handleDisconnect = () => {
    if (room) {
      room.localParticipant.tracks.forEach((track: LocalTrackPublication) => {
        track.unpublish();
      });
    }
    setCallStatus(VideoCallState.Ended);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const classes: any = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('xs'))

  // let getUserMedia = (navigator.getUserMedia || navigator.webkitGeUserMedia || navigator.mozGetUserMedia).bind(navigator);

  return (
    <ThemeProvider theme={darkTheme}>
      {callStatus === VideoCallState.AboutToStart && (
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Card variant="outlined">
            <CardContent>
              {loading && <CircularProgress size={100} className={classes.buttonProgress} />}
              {!loading && (
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormGroup row style={{ width: '100%' }}>
                        <TextField
                          style={{ width: '70%' }}
                          type="text"
                          variant="outlined"
                          name="username"
                          id="username"
                          value={Name}
                          disabled
                          label={userType.toLowerCase() === 'customer' ? 'Recipient' : 'Caller'}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <div style={{ width: '5%' }}></div>
                        <Button
                          style={{ width: '25%', paddingTop: '0px', paddingBottom: '0px' }}
                          disableElevation
                          id="joinBtn"
                          color="primary"
                          disabled={disabled}
                          variant="contained"
                          type="submit"
                          onClick={() => {
                            setCallStatus(VideoCallState.Started);
                          }}>
                          Join Now
                        </Button>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <br />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={4}>
                      <FormControlLabel
                        style={{ marginTop: '10px' }}
                        control={<Switch checked={enableAudio} />}
                        onClick={() => {
                          setAudioEnable(!enableAudio);
                        }}
                        color="primary"
                        label={
                          <Typography style={{ marginTop: '5px' }}>
                            {enableAudio ? <MicIcon /> : <MicOffIcon />}
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        style={{ width: 200 }}
                        id={`audioDevice`}
                        name={`audioDevice`}
                        label={`Audio Device`}
                        value={selectedAudioTrack}
                        onChange={(e: any) => {
                          setSelectedAudioTrack(e.target.value);
                        }}>
                        {audioTracks.map((option: MediaDeviceInfo) => {
                          return (
                            <MenuItem key={option.deviceId} value={option.deviceId}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={4}>
                      <FormControlLabel
                        style={{ marginTop: '10px' }}
                        control={<Switch checked={enableVideo} />}
                        onClick={() => {
                          setVideoEnable(!enableVideo);
                        }}
                        color="primary"
                        label={
                          <Typography style={{ marginTop: '5px' }}>
                            {enableVideo ? <Videocam /> : <VideocamOff />}
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        id={`videoDevice`}
                        name={`videoDevice`}
                        style={{ width: 200 }}
                        label={`Video Device`}
                        value={selectedVideoTrack}
                        onChange={(e: any) => {
                          setSelectedVideoTrack(e.target.value);
                        }}>
                        {videoTracks.map((option: MediaDeviceInfo) => {
                          return (
                            <MenuItem key={option.deviceId} value={option.deviceId}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                </div>
              )}
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
      )}
      {callStatus === VideoCallState.Started && (
        <Grid container alignItems="center" justifyContent="space-between" style={{ height: '100vh' }}>
          <Grid item xs={12}>
            {room && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    {participants.map((x: Participant) => {
                      console.log('participant : ', x);
                      return (
                        <RemoteParticipant
                          videoTrackEnable={true}
                          audioTrackEnable={true}
                          setAudioTrackEnable={() => {}}
                          setVideoTrackEnable={() => {}}
                          key={x.sid}
                          participant={x}></RemoteParticipant>
                      );
                    })}
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Box style={{ position: !sm  ? 'absolute' : 'relative' , maxWidth: '200px', bottom: sm ? "0%" : '5%', right: '2%' }}>
                      <LocalParticipant
                        setAudioTrackEnable={setAudioEnable}
                        videoTrackEnable={enableVideo}
                        setVideoTrackEnable={setVideoEnable}
                        audioTrackEnable={enableAudio}
                        participant={room.localParticipant}
                        key={room.localParticipant.sid}></LocalParticipant>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ bottom: '1%', position: 'absolute' }}
            alignItems="center">
            <Grid item>
              <Box mt={3} mr={1}>
                <Fab
                  color={enableAudio ? 'primary' : 'secondary'}
                  onClick={() => {
                    setAudioEnable(!enableAudio);
                  }}
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  title="Mute">
                  {enableAudio && <MicIcon />}
                  {!enableAudio && <MicOffIcon />}
                </Fab>
              </Box>
            </Grid>

            <Grid item>
              <Box mt={3} mr={1}>
                <Fab
                  color={enableVideo ? 'primary' : 'secondary'}
                  onClick={() => {
                    setVideoEnable(!enableVideo);
                  }}
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  title="Disable Video">
                  {enableVideo && <Videocam />}
                  {!enableVideo && <VideocamOff />}
                </Fab>
              </Box>
            </Grid>
            <Grid item>
              <Box mt={3} mr={1}>
                <Fab
                  color="secondary"
                  onClick={() => {
                    handleDisconnect();
                  }}
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  title="Disconnect">
                  <CallEndIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {callStatus === VideoCallState.Ended && (
        <Grid container justifyContent="center" alignItems="center" className={classes.grid}>
          <Grid item>
            <Box component="div" mt={1}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                      <Box mt={2}>
                        <Typography variant="h3" color="textSecondary" gutterBottom>
                          You've ended the call
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box mt={2}>
                        <Typography variant="h4" color="textSecondary" gutterBottom>
                          You may close the window.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box mt={2}>
                        <LoadingButton
                          fullWidth
                          id="login_btn"
                          color="primary"
                          disabled={disabled}
                          variant="contained"
                          size="medium"
                          type="submit"
                          onClick={() => {
                            setCallStatus(VideoCallState.Started);
                          }}>
                          Re-Join
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  );
};

export default VideoCall;
