import {
  Box,
  Container,
  Divider,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import { useRouter } from 'common/hooks';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { BroadCast, BroadCastType } from 'components/broadcast/models/broadcast';
import AppSnackBar from 'components/snackbar/snackbar';
import { fetchDealerEmail } from 'store/dealerEmailSlice';

import { BroadcastForm } from './broadcast-form';
import BroadcastPreview from './broadcast-preview';

import 'emoji-mart/css/emoji-mart.css';

const useStyles = makeStyles((theme: Theme): any => ({
  container: {
    position: 'relative',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: `80%`,
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
  },
}));

export function BroadCastPage(): ReactElement | null {
  const dispatch = useAppDispatch();
  const containerMaxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false = 'md';
  const classes: any = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const { query, push: pushUrl }: any = router;
  const { id: idParam, type: typeParam } = query;
  const [type, setType] = React.useState<BroadCastType>('SMS');
  const [broadcast, setBroadcast] = React.useState<BroadCast | undefined>(undefined);
  const [previewBroadcast, setPreviewBroadcast] = React.useState<BroadCast | undefined>(undefined);

  dispatch(fetchDealerEmail);

  const dealerEmail = useAppSelector((state) => state.dealerEmail);


  React.useEffect(() => {
    setType(typeParam);
  }, [typeParam]);

  React.useEffect(() => {
    async function fetchBroadCast(): Promise<void> {
      try {
        const idInt = parseInt(idParam, 10);
        const response = await BroadCastAPI.getBroadcast(idInt, typeParam);
        setBroadcast(response);
      } catch (error) {
        showSnackBar({ type: 'error', message: 'Unable to fetch broadcast against the dealer' });
        pushUrl('/404');
      }
    }
    fetchBroadCast();
  }, [idParam, showSnackBar, typeParam]);

  async function handleBroadcastSave(updatedBroadcast: BroadCast): Promise<void> {
    try {
      await BroadCastAPI.updateBroadcast(updatedBroadcast, type);
      showSnackBar({ type: 'success', message: 'Broadcast updated' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to save broadcast' });
    }
  }
  async function handleBroadcastDuplicate(updatedBroadcast: BroadCast): Promise<void> {
    try {
      const response = await BroadCastAPI.duplicateBroadcast(updatedBroadcast.id, type);
      showSnackBar({ type: 'success', message: 'Broadcast duplicated' });
      router.push(`/broadcasts/${response.id}?type=${type}`);
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to duplicate broadcast' });
    }
  }
  async function handleBroadcastPreview(updatedBroadcast: BroadCast): Promise<void> {
    setPreviewBroadcast(updatedBroadcast);
  }
  function handleBroadcastPreviewClose(): void {
    setPreviewBroadcast(undefined);
  }
  async function handleBroadcastSaveAndSend(updatedBroadcast: BroadCast): Promise<void> {
    try {
      await BroadCastAPI.updateBroadcast(updatedBroadcast, type);
      router.push(`/broadcasts/${type}/${broadcast?.id}/customers-group`);
      showSnackBar({ type: 'success', message: 'Broadcast updated' });
    } catch (error) {
      showSnackBar({ type: 'error', message: 'unable to update broadcast' });
    }
  }

  return (
    <Box my={1} component="section">
      <Box>
        <Box py={1} pl={3}>
          <Typography variant="h4" component="h1">
            {broadcast?.title}
          </Typography>
        </Box>
        <Toolbar variant="dense" />
        <Divider />
      </Box>
      <Container className={classes.container} maxWidth={mobile ? false : containerMaxWidth}>
        {broadcast !== undefined && (
          <BroadcastForm
            broadcast={broadcast}
            type={type}
            onDuplicate={handleBroadcastDuplicate}
            onSave={handleBroadcastSave}
            onSend={handleBroadcastSaveAndSend}
            onPreview={handleBroadcastPreview}
          />
        )}
      </Container>
      <BroadcastPreview
        open={!!previewBroadcast}
        onClose={handleBroadcastPreviewClose}
        broadcast={previewBroadcast}
        broadcastType={type}
        dealerEmail={dealerEmail}
      />
    </Box>
  );
}

export default BroadCastPage;
