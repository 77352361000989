import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CustomerApi from 'components/customer/api/CustomerAPI';
import { CustomerContactDrawer } from 'components/customer/components/CustomerDrawer/CustomerContactDrawer';
import { useAppSelector } from 'app';
import EmailLink from 'common/components/buttons/email-link';

const useStyles1 = makeStyles((theme) => ({
  paper: {},
  myChip: {
    border: '1px solid silver',
    borderRadius: '10px',
    padding: '4px 6px',
    margin: '2px 3px',
    backgroundColor: '#FBFCFC',
  },
  myTable: {
    '& th': {
      backgroundColor: '#F7F9F9',
    },
    '& td': {
      borderBottom: '1px solid #EAEDED',
    },
    '& tr:nth-child(even)': { backgroundColor: '#FBFCFC' },
  },

  selectedRow: {
    backgroundColor: '#EAF2F8',
  },
}));

function CustomerContacts({
  customer,
  open,
  defaultPhoneType,
  convPhoneIds,
  onClose,
}: {
  customer: any;
  open: boolean;
  defaultPhoneType: number;
  convPhoneIds: number[] | undefined;
  onClose(): void;
}) {
  const classes = useStyles1();
  const dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
  const [dataContact, setDataContact] = React.useState([] as any);
  const [status,setStatus]=React.useState('')
  const [loading,setLoading]=React.useState<boolean>(false)
  const [openContactDrawer, setOpenContactDrawer] = React.useState<{
    openData: boolean;
    index?: number;
    crmCustomerId?: string;
  }>({
    openData: false,
    index: -1,
    crmCustomerId: '',
  });
  useEffect(() => {
    if (openContactDrawer.openData === false) GetContacts();
  }, [openContactDrawer]);

  async function GetContacts() {
    let obj: any = new Object();
    obj.DealerId = customer.dealerId;
    obj.crmCustomerId = customer.crmCustomerId;
    const jsonString = JSON.stringify(obj);
    setLoading(true);
 
    const response = await CustomerApi.GetContacts(jsonString);
    if (response && response.length > 0) {
      setStatus('');
      setDataContact(response);
    } else {
      setStatus('No contact found...!');
    }
    setLoading(false);
  }
  function formatPhoneNumber(phoneNumberString: string) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    else return cleaned;
  }
  function GetPhoneNumber(row: any): string {
    let phoneType = row.PhoneTypeName;
    if (phoneType == null) {
      if (row.PhoneType == 0) phoneType = 'Landline';
      if (row.PhoneType == 1) phoneType = 'Cell';
      if (row.PhoneType == 2) phoneType = 'Alternate';
      if (row.PhoneType == 3) phoneType = 'Fax';
    }
    return phoneType + ': ' + formatPhoneNumber(row.PhoneNumber);
  }
  return (
    <div>
      <Drawer
        PaperProps={{ style: { width: 'auto', minWidth: '900px' } }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={onClose}>
        <Grid container>
            <Grid item xs={1}>
              <Toolbar variant="dense">
                <IconButton
                  onClick={() => {
                    setOpenContactDrawer({ openData: true, crmCustomerId: customer.crmCustomerId });
                  }}>
                  <AddIcon />{' '}
                </IconButton>
              </Toolbar>
              <Divider />
            </Grid>
          <Grid item xs={11}>
            <DrawerToolbar title="Customer Contacts" onClose={onClose} />
          </Grid>
        </Grid>
        {typeof dataContact !== 'undefined' && dataContact != null && (
          <Box style={{ margin: '10px 20px', border: '0px solid red' }}>
            <Box style={{ border: '1px solid silver' }}>
              {loading && <LinearProgress />}
              <Table size="small" className={classes.myTable}>
                <TableHead>
                  <TableRow >
                    <TableCell>Name</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    {dmsTypeId !== 1 && <TableCell>Primary</TableCell>}
                  </TableRow>
                </TableHead>
                {dataContact
                 .sort((a: any, b: any) => {
                  // First, sort by Primary in descending order (true/1 comes before false/0)
                  if (a.Primary !== b.Primary) {
                    return b.Primary - a.Primary; // Descending by Primary
                  }
                  // Then, sort by Name in descending order, or by Id if Name is the same
                  return  b.Id - a.Id; // Descending by  Id
                })
                .map((row: any) => (
                  <TableRow hover>
                    <TableCell
                      onClick={() => {
                          setOpenContactDrawer({ openData: true, index: row.Id }) 
                      }}>
                      {dmsTypeId === 1 ? (
                        <Link color="inherit" underline="hover" style={{ cursor: 'pointer' }}>
                          {row.Name}
                        </Link>
                      ) : (
                        (row.FirstName ? row.FirstName : '') + ' ' + (row.LastName ? row.LastName : '')
                        )}
                    </TableCell>
                    <TableCell>{row.JobTitle}</TableCell>
                    <TableCell>
                      {typeof row.Phones !== 'undefined' &&
                        row.Phones != null &&
                        row.Phones.map((rowPhone: any) => (
                          <span className={classes.myChip}>{GetPhoneNumber(rowPhone)} </span>
                        ))}
                    </TableCell>
                    <TableCell>
                      {typeof row.Emails !== 'undefined' &&
                        row.Emails != null &&
                        row.Emails.map((rowEmail: any) => <span className={classes.myChip}><EmailLink email={rowEmail.Address} /></span>)}
                    </TableCell>
                    {dmsTypeId !== 1 && (
                      <TableCell align="center">
                        {row.Primary == 1 && <CheckIcon color="primary"></CheckIcon>}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {status && (<Box padding={'1rem'}>
                  <Typography variant='body2'>No contacts found</Typography>
                </Box>)}
              </Table>
            </Box>
            <CustomerContactDrawer
              open={openContactDrawer.openData}
              onClose={() => setOpenContactDrawer({ openData: false })}
              editContact={dataContact.find((x: any) => x.Id === openContactDrawer.index)}
              selectedCustomers={[]}
              addCustomer={openContactDrawer.crmCustomerId}
              setNewCustomerContact={() => undefined}
              IsCustomerOrganization = {customer.IsOrganization}
              defaultPhoneType = {defaultPhoneType}
              convPhoneIds={convPhoneIds}
            />
          </Box>
        )}
      </Drawer>
    </div>
  );
}
export default CustomerContacts;


