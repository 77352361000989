import { Box, Checkbox, Drawer, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import Department from 'components/setting/models/department';
import AppSnackbar from 'components/snackbar/snackbar';

function useFocus(): any {
  const htmlRef = React.useRef<any>(undefined);
  function setFocus(): void {
    if (htmlRef !== undefined && htmlRef.current !== undefined) {
      htmlRef.current.focus();
    }
  }
  return [htmlRef, setFocus];
}
export default function EditUserDepartmentDrawer({
  conversationDepartmentIds,
  departments,
  userDepartments,
  open,
  onClose,
  onUpdateDepartment,
}: {
  conversationDepartmentIds:number[],
  departments?: Department[];
  userDepartments?: Department[];
  open: boolean;
  onClose(): void;
  onUpdateDepartment(departmentIds: Department[]): Promise<void>;
}): React.ReactElement {
  const [inputRef, setInputRef] = useFocus();
  const [selectedDepartments, setSelectedDepartments] = React.useState<Department[]>(userDepartments || []);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  
  React.useEffect(() => {
    if (userDepartments) {
      setSelectedDepartments(userDepartments);
    }
  }, [userDepartments]);

  function handleDepartmentsChange(values: any): void {

    if(canChangeDepartment(selectedDepartments,values))
    {
      setSelectedDepartments(values);
    }
    else
    {
      showSnackBar({
        type: 'error',
        message: 'Cannot unassign user from the department while assigned to a conversation. Please unassign from the conversation first.',
      });
    }
  }

  function canChangeDepartment(oldValues:any[], newValues:any[]):boolean
  {
    const newDepartIds = newValues.map(obj => obj.departmentId);
    const departmentRemoved = oldValues.filter(value => !newDepartIds.includes(value.departmentId) );
    const departmentExist = departmentRemoved.some(x=> conversationDepartmentIds.includes(x.departmentId) );
    
    return departmentExist ?false: true;
  }

  async function handleOnSave(): Promise<void> {
    return onUpdateDepartment(selectedDepartments);
  }

  return (
    <Drawer
      PaperProps={{ style: { width: '40%' } }}
      onAnimationEnd={(): any => setTimeout(setInputRef, 400)}
      variant="temporary"
      transitionDuration={{ enter: 400, exit: 400 }}
      anchor="right"
      open={open}
      onClose={onClose}>
      <DrawerToolbar title="Edit user's department" onSave={handleOnSave} />
      <Box mt={1} />
      <Box mx={1} height="100%">
        <form
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
          id="editUserDepartment">
          <Autocomplete
            multiple
            openOnFocus
            options={departments || []}
            id="departments"
            disableCloseOnSelect
            groupBy={(option: Department): string => option.dealerLocationName}
            onChange={(event: any, values: any): void => handleDepartmentsChange(values)}
            getOptionLabel={(option: any): string => option.departmentName}
            value={selectedDepartments}
            getOptionSelected={(option: Department, value: Department): boolean =>
              option.departmentId === value.departmentId
            }
            renderOption={(option, { selected }): React.ReactElement => (
              <>
                <Checkbox checked={selected} />
                {option.departmentName}
              </>
            )}
            renderInput={(params): React.ReactElement => (
              <TextField
                {...params}
                inputRef={inputRef}
                name="departments"
                id="departments"
                variant="outlined"
                margin="dense"
                label="Departments"
                placeholder="Select Departments"
              />
            )}
          />
        </form>
      </Box>
    </Drawer>
  );
}
