import { Box, Container, Drawer } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useAppSelector } from 'app';

import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import { LocationOption } from 'common/models/location-option';

import DepartmentForm from './department-form';
import useUsersList from './userUsersList';

function NewDepartmentDrawer({
  locations,
  open,
  onClose,
  onCreate,
}: {
  locations: LocationOption[];
  open: boolean;
  onClose(): void;
  onCreate(value: any, createMore: boolean): Promise<void>;
}): ReactElement {
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const users = useUsersList(dealerId);
  return (
    <Drawer PaperProps={{ style: { width: '40%' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar title="Add Department" onClose={onClose} />
      <Box mb={1} />
      <Container>
        {open && (
          <DepartmentForm locations={locations} dealerUsers={users || []} onCancel={onClose} onSubmit={onCreate} />
        )}
      </Container>
    </Drawer>
  );
}

export default NewDepartmentDrawer;
