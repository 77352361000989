import { Box, Typography } from '@material-ui/core';

import React, { ReactElement } from 'react';

export default function PageHeader({ title }: { title: string }): ReactElement {
  return (
    <Box component="header">
      <Typography variant="h4" component="h1">
        {title}
      </Typography>
    </Box>
  );
}
