import { Box, CardHeader, Divider, Grid, IconButton, Menu, MenuItem, Theme, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { amber, green, grey, indigo, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { ReactElement, useState } from 'react';

import Tag from 'common/components/tag';
import { SurveyListItem } from 'components/survey/models';

enum SurveyStatus {
  Drafted,
  Sent,
  Deactivated,
  InActive,
  Automated,
}

const useStyles = makeStyles((theme: Theme): any => ({
  card: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'visible',
    '&:hover': {
      boxShadow: theme.shadows[5],
      '& $action': {
        display: 'block',
      },
    },
  },
  action: {
    display: 'none',
    position: 'absolute',
    right: '6px',
    top: '4px',
    background: theme.palette.background.paper,
  },
  header: {
    height: '75px',
    paddingLeft: '32px',
    alignItems: 'start',
  },
  headerContent: {
    wordBreak: 'break-all',
    width: '100%',
  },
  cardContent: {
    width: '100%',
  },
  title: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
}));

function getSurveyStatus(
  surveyType: string,
  isAutomated: boolean,
  isActive: boolean,
  isSent: boolean,
  feedbackCount: number,
): SurveyStatus {
  if (isActive === false) {
    return SurveyStatus.Deactivated;
  }

  if (surveyType === 'Auto') {
    if (isAutomated) {
      return SurveyStatus.Automated;
    }
    return SurveyStatus.InActive;
  }

  if (isActive === true && (isSent || feedbackCount >= 1)) {
    return SurveyStatus.Sent;
  }

  if (isActive === true && (isSent === false || feedbackCount <= 0)) {
    return SurveyStatus.Drafted;
  }
  return SurveyStatus.Drafted;
}

function getStatusStyle(status: SurveyStatus): [string, string] {
  switch (status) {
    case SurveyStatus.Sent:
      return [green[500], 'Sent'];
    case SurveyStatus.Drafted:
      return [amber[500], 'Draft'];
    case SurveyStatus.Automated:
      return [indigo[500], 'Active'];
    case SurveyStatus.InActive:
      return [grey[500], 'InActive'];
    case SurveyStatus.Deactivated:
      return [red[500], 'Deactivated'];
    default:
      return ['white', ''];
  }
}

function getNpsColor(nps: number): string {
  if (nps < 0) {
    return red[500];
  }
  if (nps >= 0 && nps <= 30) {
    return amber[500];
  }
  if (nps > 30) {
    return green[500];
  }
  return 'black';
}

export default function SurveyCard({
  survey,
  getLocationName,
  onClick,
  onEdit,
  onPreview,
  onDeactivate,
  onActivate,
  onDuplicate,
  onResults,
}: {
  survey: SurveyListItem;
  getLocationName(dealerLocationId: number): string | undefined;
  onClick(id: number): Promise<void>;
  onEdit(id: number): Promise<void>;
  onPreview(id: number): Promise<void>;
  onDeactivate(id: number): Promise<void>;
  onActivate(id: number): Promise<void>;
  onResults(id: number): Promise<void>;
  onDuplicate(id: number): Promise<void>;
}): ReactElement {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleActionMenuClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  const closeActionMenu = (): void => setAnchorEl(null);

  function handleCardClicked(surveyStatus: SurveyStatus): void {
    closeActionMenu();
    if (surveyStatus === SurveyStatus.Drafted) {
      onClick(survey.id);
    } else {
      onResults(survey.id);
    }
  }

  function handleEdit(): void {
    closeActionMenu();
    onEdit(survey.id);
  }

  function handlePreview(): void {
    closeActionMenu();
    onPreview(survey.id);
  }

  function handleDeactivate(): void {
    closeActionMenu();
    onDeactivate(survey.id);
  }

  function handleReactivate(): void {
    closeActionMenu();
    onActivate(survey.id);
  }

  function handleResults(): void {
    closeActionMenu();
    onResults(survey.id);
  }

  function handleDuplicate(): void {
    closeActionMenu();
    onDuplicate(survey.id);
  }

  const borderStyleActive = `5px solid`;
  const surveyStatus = getSurveyStatus(
    survey.type,
    survey.isAutomaticSurvey,
    survey.isActive,
    survey.isSent,
    survey.responseCount,
  );
  const [currentBorderColor, surveyToolTip] = getStatusStyle(surveyStatus);
  const isNpsInvalid = !isNaN(survey.nps);
  const npsColor = getNpsColor(survey.nps);
  const cardAction = (
    <IconButton
      style={{ padding: '8px', borderRadius: '0px' }}
      id="surveyOptionBtn"
      className={classes.action}
      onClick={handleActionMenuClick}>
      <MoreVertIcon fontSize="small" />
    </IconButton>
  );

  const isEditable = SurveyStatus.Drafted === surveyStatus || (survey.type === 'Auto' && survey.isSent === false);
  const isAutomatedSurveyDeactivateAble = survey.type === 'Auto' && survey.isActive && !survey.isSent;
  const isDeactivateAble = isAutomatedSurveyDeactivateAble || SurveyStatus.Drafted === surveyStatus;
  return (
    <Tooltip title={surveyToolTip} arrow>
      <Card
        className={classes.card}
        onClick={(): void => handleCardClicked(surveyStatus)}
        style={{ borderBottom: borderStyleActive, borderColor: currentBorderColor }}>
        <CardHeader
          className={classes.header}
          classes={{ action: classes.action, content: classes.headerContent }}
          titleTypographyProps={{ className: classes.title }}
          title={
            <div>
              <Tag label={surveyToolTip} color={currentBorderColor} />
              <span className="surveyTitle">{survey.title}</span>
            </div>
          }
          action={cardAction}
          subheaderTypographyProps={{
            variant: 'caption',
            style: { marginTop: '3px', wordBreak: 'break-word' },
          }}
          subheader={getLocationName(survey.dealerLocationId)}
        />
        <CardContent>
          <Grid container>

          <Grid item xs={12} md={4}>
                <Box textAlign="left">
                  <Typography variant="overline" component="h3" gutterBottom>
                    SENT
                  </Typography>
                  <Typography className="surveyNps" variant="h3">
                  {survey.totalSurveySent}
                  </Typography>
                </Box>
            </Grid>


            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="overline" component="h3" gutterBottom>
                  Responses
                </Typography>
                <Typography className="surveyResponseCount" variant="h3">
                  {survey.responseCount}
                </Typography>
              </Box>
            </Grid>


            <Grid item xs={12} md={4}>
              {isNpsInvalid && (
                <Box textAlign="right">
                  <Typography variant="overline" component="h3" gutterBottom>
                    NPS
                  </Typography>
                  <Typography className="surveyNps" style={{ color: npsColor }} variant="h3">
                    {survey.nps}
                  </Typography>
                </Box>
              )}
            </Grid>

          </Grid>
        </CardContent>
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(e: any): void => e.stopPropagation()}
          onClose={closeActionMenu}>
          <MenuItem id="edit" className="surveyOptions" disabled={!isEditable} dense button onClick={handleEdit}>
            <Typography color="textSecondary">Edit</Typography>
          </MenuItem>
          <MenuItem id="preview" className="surveyOptions" dense button onClick={handlePreview}>
            <Typography color="textSecondary">Preview</Typography>
          </MenuItem>
          <MenuItem
            className="surveyOptions"
            id="results"
            dense
            button
            disabled={SurveyStatus.Drafted === surveyStatus}
            onClick={handleResults}>
            <Typography color="textSecondary">Results</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            className="surveyOptions"
            id="duplicate"
            disabled={SurveyStatus.Deactivated === surveyStatus}
            dense
            button
            onClick={handleDuplicate}>
            <Typography color="textSecondary">Duplicate</Typography>
          </MenuItem>
          {!survey.isSent && (
            <Box>
              <Divider />
              {isDeactivateAble ? (
                <MenuItem id="deactivate" className="surveyOptions" dense button onClick={handleDeactivate}>
                  <Typography color="secondary" component="span">
                    Deactivate
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem id="reactivate" className="surveyOptions" dense button onClick={handleReactivate}>
                  <Typography color="secondary" component="span">
                    Reactivate
                  </Typography>
                </MenuItem>
              )}
            </Box>
          )}
        </Menu>
      </Card>
    </Tooltip>
  );
}
