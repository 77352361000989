import React, { useEffect, useState } from 'react';
import {
    Box, Button, Divider, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Paper, Grid, IconButton, Collapse, CircularProgress, Typography
} from '@material-ui/core';
import CustomerTagDrawer from './CustomerTagDrawer';
import CustomerSettingsApi from '../../api/customer-settings-api';
import { CustomerTagTitle } from 'components/setting/models/customer-tag-title';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Customers: React.FC = () => {
    const [openTagDrawer, setOpenTagDrawer] = useState<boolean>(false);
    const [selectedTagTitle, setSelectedTagTitle] = useState<CustomerTagTitle | null | undefined>(null);
    const [customerTagTitles, setCustomerTagTitles] = useState<CustomerTagTitle[] | null | undefined>();
    const [expandedSections, setExpandedSections] = useState<{ [key: number]: boolean }>({});
    const [loading, setLoading] = useState<boolean>(true);

    const fetchCustomerTagTitles = async () => {
        setLoading(true);
        try {
            const response = await CustomerSettingsApi.getCustomerTags();
            setCustomerTagTitles(response);
        } catch (error) {
            console.error('Error fetching customer tag titles:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerTagTitles();
    }, []);

    const handleCloseDrawer = () => {
        setOpenTagDrawer(false);
        setSelectedTagTitle(null);
    };

    const handleEditClick = (e: any, title: CustomerTagTitle) => {
        e.stopPropagation();
        setSelectedTagTitle(title);
        setOpenTagDrawer(true);
    };

    const handleToggleExpand = (id: number) => {
        setExpandedSections((prevExpandedSections) => ({
            ...prevExpandedSections,
            [id]: !prevExpandedSections[id],
        }));
    };

    return (
        <Paper style={{ minHeight: '600px', overflow: 'auto', padding: '20px' }}>
            {openTagDrawer && (
                <CustomerTagDrawer
                    open={openTagDrawer}
                    onClose={handleCloseDrawer}
                    tagProps={selectedTagTitle}
                    fetchTags={fetchCustomerTagTitles}
                />
            )}
            <Grid container spacing={3} justifyContent="center" style = {{marginTop: '10px'}}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        Customer Tag Settings
                    </Typography>
                </Grid>
                {loading ? (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Grid>
                ) : customerTagTitles && customerTagTitles.length > 0 ? (
                    <Grid item xs={12} md={10} lg={8}>
                        <List
                            style={{
                                width: '100%',
                                backgroundColor: 'background.paper',
                                border: '3px solid #e0e0e0',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                            }}
                        >
                            <ListItem>
                                {customerTagTitles.length < 50 && (
                                    <Button variant="contained" color="primary" onClick={() => setOpenTagDrawer(true)}>
                                        Add Customer Tags
                                    </Button>
                                )}
                            </ListItem>
                            {customerTagTitles.map((title) => (
                                <React.Fragment key={`section-${title.id}`}>
                                    <ListSubheader onClick={() => handleToggleExpand(title.id)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {title.title}
                                            {expandedSections[title.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </div>
                                        <IconButton onClick={(e: any) => handleEditClick(e, title)} size="small">
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </ListSubheader>
                                    <Collapse in={expandedSections[title.id]} timeout="auto" unmountOnExit>
                                        {title.customerTagOptions.map((item) => (
                                            <ListItem
                                                key={`item-${title.id}-${item.tag}`}
                                                style={{ paddingLeft: '20px', borderBottom: '1px solid #e0e0e0' }}
                                            >
                                                <ListItemIcon style={{ minWidth: '24px' }}>
                                                    <FiberManualRecordIcon fontSize="small" style={{ fontSize: '10px' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item.tag} />
                                            </ListItem>
                                        ))}
                                    </Collapse>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={10} lg={8} style={{ textAlign: 'center' }}>
                        <Typography variant="body1" gutterBottom style = {{fontSize: '18px', marginBottom: '20px'}}>
                            No tag settings found
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => setOpenTagDrawer(true)}>
                            Add Customer Tags
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default Customers;
