import React, { ReactElement } from 'react';
import { Box, Typography, Grid, Container, Paper, Divider, Button } from '@material-ui/core';
import { useRouter } from 'common/hooks';

export default function NotFoundPage(): ReactElement {
  const router = useRouter();
  function handleGoBackToDashboard(): void {
    router.history.push('/');
  }
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        paddingTop: '30px',
      }}
      maxWidth="lg">
      <Paper>
        <Box mt={3} mb={1} mx={2}>
          <Typography style={{ marginBottom: '8px' }} variant="h2" component="h1">
            Page not found :(
          </Typography>
          <Typography variant="subtitle2" component="h2">
            Maybe the page you are looking for has been removed, or you typed in the wrong URL
          </Typography>
        </Box>
        <Divider />
        <Box mx={2} mb={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Button id="goBack" variant="text" color="primary" onClick={handleGoBackToDashboard}>
                Go back to dashboard
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
