import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  headerLostBtn: {
    background: '#e53935 !important',
    color: theme.palette.common.white,
    float: 'right',
    marginLeft: '5px',
  },
  headerWonBtn: {
    background: '#28a62e !important',
    color: theme.palette.common.white,
    float: 'right',
    marginLeft: '5px',
  },
  headerSaveBtn: {
    float: 'right',
  },
  footerArchiveBtn: {
    background: '#2196f3 !important',
    color: theme.palette.common.white,
  },
  editIcon: {
    cursor: 'pointer',
    marginTop: '-5px',
    marginLeft: '5px',
  },
  editInput1: {
    height: '5px',
    width: '338px',
  },
  editInput2: {
    height: '0px',
  },
  editSelect: {
    height: '0px',
    width: '140px',
  },
  clearIcon: {
    cursor: 'pointer',
    fontSize: '1.5rem !important',
  },
  drawerIcon: {
    marginTop: '-2px',
    marginRight: '5px',
  },
  iconButton: {
    background: '#1976D2',
    color: 'white',
    marginLeft: '5px',
    height: '20px',
    width: '20px',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#1976D2 !important',
      color: 'white !important',
    },
  },
  stageChip: {
    minWidth: '60px',
    minHeight: '30px',
    borderRadius: '5px',
  },
  drawerRightWindow: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#858585',
    //maxHeight: '550px',
    overflow: 'auto',
  },
  timelineContentChip: {
    marginTop: '5px !important',
    padding: '0 !important',
  },
  timelineDealCreated: {
    marginTop: '10px',
    color: '#ffffff',
  },
  clearSubIcon: {
    cursor: 'pointer',
    fontSize: '1.1rem !important',
    marginTop: '-1px',
  },
  errorMsg: {
    color: '#e53935',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '-0.06px',
    padding: '5px',
  },
  //Class for the tabs
  dealHistoryBox: {
    width: '100%',
  },
  dealTimeline: {
    padding: '0 !important',
    marginLeft: '-15px',
  },
  dealHistoryTitle: {
    borderRadius: '5px',
    marginTop: '10px',
  },
  dealHistoryChatBox: {
    border: 'solid 1px',
    padding: '8px',
    borderRadius: '15px',
    marginBottom: '10px',
    overflowWrap: 'anywhere',
    backgroundColor: '#ffffff !important',
  },
  dealHistoryNote: {
    fontSize: 'small',
    height: '36px',
    overflowY: 'auto',
  },
  taskScheduledChip: {
    fontSize: 'x-small',
    float: 'right',
  },
  taskCompletedChip: {
    background: '#28a62e !important',
    color: theme.palette.common.white,
    fontSize: 'x-small',
    float: 'right',
  },
  fieldPadding: {
    paddingBottom: '3%',
  },
  floatRight: {
    float: 'right',
  },
  paddingRight2Percentage: {
    paddingRight: '2%',
  },
  chipStyles: {
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '5px',
    height: '20px',
  },
  muiPopoverPaper: {
    '@media screen and (max-width: 1280px)': {
      width: '50%',
      maxHeight: '200px !important',
      top: '40% !important',
      left: '0 !important',
      right: '0',
      margin: '0 auto',
    },
  },
  disableGray: {
    color: '#bdbdbd',
    cursor: 'not-allowed',
  },
  fileUploaderBox: {
    width: "400px", 
    height: "150px", 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "4px dashed #555", 
    borderRadius: "20px",
    backgroundColor: "#f0f0f0"
  },
  fileUploadStatusBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
  },
  fileUploadTextBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "3px solid #555", 
    backgroundColor: "white",
    padding: "0 5px",
    borderRadius: "4px",
  },
  pdfIcon: {
    marginRight: '4px'
  },
  pdfName: {
    position: 'absolute',
    top: '-5px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '8px',
    color: 'white',
    whiteSpace: 'nowrap',
  },
  arrowBackButton: {
    color: 'white',
    backgroundColor: 'rgb(51, 51, 51)',
    fontWeight: 'bold',
    padding: '1px 5px',
    borderRadius: '1px',
    top: '5px',
    left: '10px',
    width: 'auto',
    height: '18px',
    minWidth: '0px'
  },
  newTabButton: {
    position: 'absolute',
    top: '5px',
    bottom: '10px',
    right: '10px',
    color: 'white',
    backgroundColor: 'rgb(51, 51, 51)',
    fontWeight: 'bold',
    padding: '1px 5px',
    borderRadius: '2px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
  }
}));

export default useStyles;
